import {
    StyleSheet,
    Text,
    TouchableOpacity,
    View,
    Image,
    ScrollView,
    Modal,
  } from "react-native";
  import React, { useContext, useEffect, useState } from "react";
  import {
    responsiveFontSize,
    responsiveScreenHeight,
    responsiveScreenWidth,
    responsiveWidth,
  } from "react-native-responsive-dimensions";
  import AppLoading from "expo-app-loading";
  import { useFonts } from "expo-font";
  import Purchasecard from "../Common/Purchasecard";
  import { apiServices } from "../services/apiServices";
  import AsyncStorage from "@react-native-async-storage/async-storage";
  import ErrorContext from "../context/ErrorContext";
  import Hoverable from "react-native-hoverable";
  import { TextInput } from "react-native";
  import { Color, isLargeLaptop,isTabletView, isMobileView, isOnlyTabletView, isOnlyLaptopView, isSmallLaptop } from "../Common/Constants";
  import commonStyles from "../styles/styles";
  import { responsiveHeight } from "react-native-responsive-dimensions";
  import SelectDropdown from "react-native-select-dropdown";
  import { checkUserName } from "../Common/CheckUserFunc";
  import BuyCardComponent from "../Common/BuyCardComponent";
  let totalPages
  let startIndex
  let todaysDate

  let endIndex
const BuyModal = ({onUpdateQuantity,pageName, isVisible, profileData,closeModal, data, isBulk,mCode,toAddress,refreshpageFunc,addUpdateMagazine }) => {
    let viewMember;
    let flag;
    
    const { error, dispatchError, dispatchSuccess } = useContext(ErrorContext);
    const [modalVisible, setModalVisible] = useState(false);
    const [bulkPurchase, setBulkpurchase] = useState(false);
    const [magazineLimit,setMagazineLimit] =useState([])
    const [defaultAddressText, setDefaultAddressText] = useState('');
    const [quantity,setQuantity] =useState(1)
    const [changeFlag,setChangeFlag] =useState(false)
    const [bulkParameters,setBulkParameters]= useState(null)
    const [inputValue, setInputValue] = useState('');

    const [totalAmoutState, setTotalAmountState] = useState(null);
    const [chargeAmoutState, setChargeAmountState] = useState(0);
    const [endDate, setEndDate] = useState();
    const [amount, setAmount] = useState();
    const [startEndDates, setStartEndDates] = useState({})
    const [charges,setCharges] = useState(null)
    const [minimumError ,setMinimumError]= useState("")



    // const [profileData, setProfileData] = useState({});
    const [memToken, setMemToken] = useState();
    const [selectedAddress,setSelectedAddress] =useState(toAddress)
    const [selectedFullAddress,setSelectedFullAddress] =useState(null)



    const {addToCart,getCourierChargesAPI,getQuantityList,getBulkQuantityParameters} = apiServices;

    const [memberCode, setMemberCode] = useState(null);
    const [fullAddressData, setFullAddressData] = useState(false);


    useEffect(()=>{
      setMemberCode(mCode)
      setBulkpurchase(isBulk)
      setFullAddressData(profileData)
      getQutantityListOfMagazine()
      return(()=>{
        setQuantity(1)
      })
    },[])
    useEffect(()=>{

      setBulkpurchase(isBulk)
      if(isBulk)
      {
        checkMinimumQuantityForBulk()
      }
    },[isBulk])

    useEffect(()=>{
      if(data && data.quantity){
        setQuantity(data.quantity)
        onUpdateQuantity(data.quantity)
        }else{
          setQuantity(1);
        }
      },[isVisible])

    useEffect(()=>{
      if(data && data.quantity){
      setQuantity(data.quantity)
      onUpdateQuantity(data.quantity)
      }else{
        setQuantity(1);
      }
    },[data])

    useEffect(()=>{
      if(toAddress)
       setSelectedAddress(toAddress)
    },[toAddress])
    function funcUpdateValue (){
      setTimeout(()=>{setChangeFlag(!changeFlag)},[750])
    }

    const getQutantityListOfMagazine = async()=>{
      try{
        var data = await getQuantityList(mCode)
        setMagazineLimit(data.quantity)
      }catch(error){
        dispatchError(error)
      }
    }
    const checkMinimumQuantityForBulk = async()=>{
      try {
        var data=await getBulkQuantityParameters(mCode)
        setBulkParameters(data)
        setInputValue(data.min_quantity)
      } catch (error) {
        dispatchError(error)
      }
    }


    const getDatesFromApi = async (data) => {
      try {
        var data = await postSubscriptionDates(memberCode,data);
        setStartEndDates(data);

      } catch (error) {

         dispatchError(error);

      }
    };
    const closeThisModal = ()=>{
      closeModal()
    }
    // useEffect(()=>{
    //   setQuantity(bulkParameters?.min_quantity)
    // },[bulkParameters])
    const getBulkChargesData = async () => {
      let magId = memberCode;
      let quantityData = { quantity: parseInt(quantity) };

      try {
        let data = await getCourierChargesAPI(magId, quantityData);
        setCharges(data.courier_charge); // Fixed typo here
      } catch (error) {
        dispatchError(error);
        alert(error.response.data.errors)
      }
    };



      const renderDropdownIcon = ({ isOpen }) => (
        <View
          style={{
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Image
                    style={{
                      height: 22,
                      width: 22,

                      resizeMode:'contain',

                    }}
                    source={ require( "../../assets/images/dropDown.png") }
                  />
        </View>
      );









    let [fontsLoaded] = useFonts({
      openSans: require("../../assets/fonts/OpenSans-VariableFont_wdth,wght.ttf"),
    });
    if (!fontsLoaded) {
      return <AppLoading />;
    }
  return (
          <Modal
        animationType="fade"
        onDismiss={()=>{console.log("Closed")}}

        transparent={true}
        visible={isVisible}
        onRequestClose={() => {
          Alert.alert("Modal has been closed.");
          setModalVisible(!isVisible);
        }}
      >
            <ScrollView style={{height:"100%"}} contentContainerStyle={{height:"100%"}} vertical={ isMobileView() ? true : false}>
        <View style={styles.centeredView}>
          <View style={[styles.modalView,{ paddingTop: isMobileView() && !bulkPurchase && 82, }]}>

            <View
              style={{
                flex: !isTabletView() && 1.5 ,
                borderWidth: 0,
                height: !isMobileView() && "100%",
                width: !isOnlyTabletView() && "100%",
                backgroundColor: "#f8f9fa",
                borderBottomLeftRadius: !isMobileView() && 20,
                borderTopLeftRadius: !isMobileView() && 20,
                padding: 20,
              }}
            >
                {isMobileView() &&   <View style={[{ alignItems: "center" }, commonStyles.justifyBetween, commonStyles.flexRow]}>
             <Text style={styles.modalText}>{pageName==='selectionMagazine'?(isBulk===true?'Buy Bulk':'Buy Subscription'):'Buy Subscription'}</Text>
                <TouchableOpacity
                  onPress={()=>{closeThisModal()}}
                >
                  <Image
                   style={{ width: 30, height: 30 }}
                    source={require("../../assets/images/cross.png")}
                  />
                </TouchableOpacity>
              </View> }
              <View style={{   alignItems: "flex-start" }}>
              {!isMobileView() &&  <Text style={styles.modalText}>{pageName==='selectionMagazine'?(isBulk===true?'Buy Bulk':'Buy Subscription'):'Buy Subscription'}</Text>}
              </View>
              <View style={{paddingTop: !isTabletView() ?  20 : 10,height:"100%",gap: !isMobileView() ?  0 : 5}}>

              <View style={[styles.textBoxView,{height: !isMobileView() && "15%",marginBottom: isMobileView() && 20}]}>
                <View style={{ flex:1 }}>

                  <Text
                    style={{
                      fontWeight: "600",
                      fontSize: isMobileView()  ? responsiveFontSize(2.3) : responsiveFontSize(1.6),
                      margin: 0,
                    }}
                  >
                 {profileData?.title }{profileData?.member_name}
                  </Text>
                  {/* <SelectDropdown
                  dropdownOverlayColor={"No"}
                  defaultButtonText={profileData?.title}
                    disabled

                    dropdownStyle={commonStyles.commanDropdownStyle}
                    selectedRowStyle={commonStyles.selectedRowStyle}
                    selectedRowTextStyle={commonStyles.selectedRowTextStyle}
                    rowStyle={commonStyles.commanRowStyle}
                    rowTextStyle={commonStyles.commonRowTextStyle}
                    buttonStyle={{
                      width: "100%",
                      opacity: 0.5,
                      height: isMobileView() && isTabletView()  ? responsiveScreenHeight(6.5) : "50%",
                      marginTop:10,
                      backgroundColor: Color.WHITE,
                      alignItems: "center",
                      justifyContent: "space-between",
                      borderRadius: 10,
                      borderWidth: 1,
                      borderColor: "rgba(0, 0, 0, 0.14)",
                      padding:10
                    }}
                    buttonTextStyle={{
                      color: "rgba(0, 0, 0,)",
                      fontSize: isMobileView()  ? responsiveFontSize(2.2) :  responsiveFontSize(1.1),
                      textAlign: "left",
                      margin: 0,
                      marginVertical: 0,
                      fontWeight: "600",
                    }}
                    data={["Mr.", "Mrs."]}
                    onSelect={(selectedItem, index) => {
                    }}

                    renderDropdownIcon={(props) => renderDropdownIcon(props)}
                  /> */}
                </View>
                {/* <View style={{ flex:1 }}>
                  <Text
                    style={{
                      fontWeight: "600",
                      fontSize: isMobileView()  ? responsiveFontSize(2.2) : responsiveFontSize(1.1),
                      margin: 0,
                    }}
                  >
                    Full Name
                  </Text>
                  <Text style={styles.textboxInput} ellipsizeMode="tail" numberOfLines={1}>
                    {profileData?.member_name}
                  </Text>
                </View> */}
              </View>
              <View style={styles.textBoxView}>
                <View style={{ flex:1 }}>
                  <Text
                    style={{
                      fontWeight: "600",
                      fontSize: isMobileView()  ? responsiveFontSize(2.2) : responsiveFontSize(1.1),
                      margin: 0,
                    }}
                  >
                    Mobile Number
                  </Text>
                  <Text style={styles.textboxInput}>{profileData?.mobile}</Text>
                </View>
                <View style={{ flex:1 }}>
                  <Text
                    style={{
                      fontWeight: "600",
                      fontSize: isMobileView()  ? responsiveFontSize(2.2) : responsiveFontSize(1.1),
                      margin: 0,
                    }}
                  >
                    Email
                  </Text>
                  <Text style={styles.textboxInput} ellipsizeMode="tail" numberOfLines={1}>{profileData?.email}</Text>
                </View>
                { !bulkPurchase && <View style={{ flex:1 }}>
                  <Text
                    style={{
                      fontWeight: "600",
                      fontSize: isMobileView()  ? responsiveFontSize(2.2) : responsiveFontSize(1.1),
                      margin: 0,

                    }}
                  >
                    Quantity
                  </Text>
                  <SelectDropdown
                       selectedRowStyle={commonStyles.selectedRowStyle}
                       selectedRowTextStyle={commonStyles.selectedRowTextStyle}
                  dropdownOverlayColor={"No"}
                  rowTextStyle={commonStyles.commonRowTextStyle}
                // disabled={amount?true:false}
                defaultValue={quantity}
                defaultButtonText={quantity}
                dropdownStyle={commonStyles.commanDropdownStyle}
                rowStyle={commonStyles.commanRowStyle}
                style={{width:"100%"}}
                buttonStyle={{
                  width: "100%",
                  height: isMobileView() && isTabletView()  ? responsiveScreenHeight(6.5) : "50%",
                  marginTop:10,
                  backgroundColor: Color.WHITE,
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderRadius: 10,
                  borderWidth: 1,
                  borderColor: "rgba(0, 0, 0, 0.14)",
                  padding:10,
                }}
                buttonTextStyle={{
                  color: "rgba(0, 0, 0,)",
                  fontSize: isMobileView()  ? responsiveFontSize(2.2) :   responsiveFontSize(1.1),
                  textAlign: "left",
                  margin: 0,
                  marginVertical: 0,
                  fontWeight: "600",
                }}


                renderDropdownIcon={(props) => renderDropdownIcon(props)}
                data={magazineLimit}
                    onSelect={(item) => {  setQuantity(item),onUpdateQuantity(item)}}

                  />
                </View>
}
                {bulkPurchase && (<View style={{ flex:1 }}>
                  <Text
                    style={{
                      fontWeight: "600",
                      fontSize: isMobileView()  ? responsiveFontSize(2.2) : responsiveFontSize(1.1),
                      margin: 0,
                    }}
                  >
                    Quantity
                  </Text>
                  <TextInput
                    onChangeText={(text) => {

                      setQuantity(text);
                      onUpdateQuantity(text)

                    }}
                    onBlur={()=>{ if(quantity<bulkParameters?.min_quantity) {
                      alert(`Minimum Quantity can't be less than ${bulkParameters?.min_quantity}`)
                      setMinimumError(`Can't be less than ${bulkParameters?.min_quantity}`)
                    }else{
                      setMinimumError("")
                    } }}
                    placeholderTextColor={"rgba(0, 0, 0, 0.3)"}
                    placeholder="1"
                    style={styles.textboxInput}
                  />
                  <View style={{marginTop:5}}>

                  {minimumError !== "" ? (
                    <Text
                        style={{ color: Color.RED, fontWeight: "500" }}
                      >
                        {minimumError}
                      </Text>
                    )
                    :
                    (
                      <Text style={{ fontSize:12 ,color: Color.BLACK50, fontWeight: "500" }} >Note:- Minimun Quantity is {bulkParameters?.min_quantity}</Text>
                  )
                }
                </View>

                </View>
                )}
              </View>

                     <View style={styles.textBoxView}>



              </View>
              <Text style={{ color: "#ff0000", fontWeight: "500" }}>
              {defaultAddressText}
              </Text>


              </View>

            </View>



            {/* sideB */}
            <View
              style={{
                flex: !isMobileView() && 1,
                borderWidth: 0,
                height: !isTabletView() && "100%",
                width: "100%",
                backgroundColor: "#ffffff",
                borderBottomRightRadius:20,
                borderTopRightRadius:20,
                padding: 20,

              }}
            >
        { !isMobileView() &&      <View style={[{ alignItems: "flex-end" }]}>
                <TouchableOpacity
                 onPress={()=>{closeThisModal()}}
                >
                  <Image
                   style={{ width: 30, height: 30 }}
                    source={require("../../assets/images/cross.png")}
                  />
                </TouchableOpacity>
              </View>}
              <View style={{ paddingTop: !isTabletView() && 20 }}>
                <Text
                  style={{
                    fontWeight: "600",
                    fontSize: isMobileView() ? responsiveFontSize(2.3) : responsiveFontSize(1.6),
                  }}
                >
                  Magazine Preview
                </Text>
              </View>
              <View style={{marginBottom: isMobileView() && 70}} >
                <BuyCardComponent data ={data} location={data?.dispatch_address?.full_address} amount={totalAmoutState} charges={chargeAmoutState} startDate={todaysDate} endDate={endDate} bulkPurchase={bulkPurchase}/>
         { !isMobileView() &&       <View style={[{marginTop: isMobileView() && bulkPurchase ? "40%" : "14%",gap:  10,justifyContent: isTabletView()&& "space-around ",justifyContent:'center'}, !isMobileView() && commonStyles.flexRow]}>

                  { pageName==='selectionMagazine' &&<View
                    style={{

                      borderWidth: 0,
                      flex: isMobileView() ? 1 : .9,
                      justifyContent: "center",
                    }}
                  >
                     <Hoverable style={[ {alignItems:"center",height:40} ]}>
      {({ hovered }) => (
                    <TouchableOpacity
                    disabled={!selectedAddress}
                      onPress={() => {
                        if(isBulk){
                          if(quantity>=bulkParameters.min_quantity){
                               addUpdateMagazine()
                          }else{
                            setMinimumError(`Can't be less then ${bulkParameters?.min_quantity}`)
                            return;
                          }
                        }else{
                          addUpdateMagazine()
                        }

                      }}
                      style={{
                        backgroundColor: hovered ? Color.BLUE_MEDIUM : Color.BLUE_LIGHT,
                        width:  "100%",
                        height: "100%",
                        borderRadius: 10,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Text style={{ color: hovered ? "#FFF" : Color.BLUE_MEDIUM, fontWeight: "600" }}>
                        Add to Cart
                      </Text>
                    </TouchableOpacity>
                       )}
                       </Hoverable>
                       {!selectedAddress &&
                       <Text
                        style={{ color: "red", fontWeight: "500", height: 10 ,textAlign:'center' }}
                      >
                        Please select default address first!
                      </Text>
                      }
                  </View>
                  }


                  {pageName!='selectionMagazine' &&<View
                    style={{

                      borderWidth: 0,
                      flex: isMobileView() ? 1 : .9,
                      justifyContent: "center",
                    }}
                  >
                     <Hoverable style={{alignItems:"center",height:40}}>
      {({ hovered }) => (
                    <TouchableOpacity
                      onPress={() => {

                        addUpdateMagazine()

                      }}
                      style={{
                        backgroundColor: hovered ? Color.BLUE_MEDIUM : Color.BLUE_LIGHT,
                        width:  "100%",
                        height: "100%",
                        borderRadius: 10,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Text style={{ color: hovered ? "#FFF" : Color.BLUE_MEDIUM, fontWeight: "600" }}>
                        Update
                      </Text>
                    </TouchableOpacity>
                       )}
                       </Hoverable>
                  </View>}

                </View>}
              </View>
            </View>

          </View>
          {!bulkPurchase && <View
            style={styles.messageContainer}
            >
              <Text
              style={
                [styles.messageText,{
                  fontSize:16
                }]
              }
              >
              🌟 Dhan Nirankar Ji! 🌟

              </Text>
              <Text
                 style={
                  [styles.messageText,{
                    color: Color.GREEN
                  }]
                }
              >

              Add up to 3 magazines to your cart and save! Subscribe in combo today. 📚✨
              </Text>
            </View>}

            {/* Add To Cart On Mobile View */}

        </View>
            </ScrollView>
     { isMobileView() &&       <View style={[{position:"absolute",bottom:"0%",backgroundColor:Color.WHITE, width:"100%", paddingHorizontal:15,paddingVertical:15,borderTopLeftRadius:10,borderTopRightRadius:10 ,   shadowColor: 'rgba(0, 0, 0, 0.05)',
        shadowOffset: {
          width: 0,
          height: isMobileView() ? -2 : 4,
        },
        shadowRadius: 12,}]}>

{ pageName==='selectionMagazine' &&<View
  style={{

    borderWidth: 0,
    flex: isMobileView() ? 1 : .9,
    justifyContent: "center",
  }}
>
   <Hoverable style={[ {alignItems:"center",height:40} ]}>
{({ hovered }) => (
  <TouchableOpacity
  disabled={!selectedAddress}
    onPress={() => {
      if(isBulk){
        if(quantity>=bulkParameters.min_quantity){
             addUpdateMagazine()
        }else{
          setMinimumError(`Can't be less then ${bulkParameters?.min_quantity}`)
          return;
        }
      }else{
        addUpdateMagazine()
      }

    }}
    style={{
      backgroundColor: Color.BLUE_MEDIUM ,
      width:  "100%",
      height: "100%",
      borderRadius: 10,
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <Text style={{ color: "#FFF",  fontWeight: "600" }}>
      Add to Cart
    </Text>
  </TouchableOpacity>
     )}
     </Hoverable>
     {!selectedAddress &&
     <Text
      style={{ color: "red", fontWeight: "500", height: 10 ,textAlign:'center' }}
    >
      Please select default address first!
    </Text>
    }
</View>
}


{pageName!='selectionMagazine' &&<View
  style={{

    borderWidth: 0,
    flex: isMobileView() ? 1 : .9,
    justifyContent: "center",
  }}
>
   <Hoverable style={{alignItems:"center",height:40}}>
{({ hovered }) => (
  <TouchableOpacity
    onPress={() => {

      addUpdateMagazine()

    }}
    style={{
      backgroundColor:  Color.BLUE_MEDIUM ,
      width:  "100%",
      height: "100%",
      borderRadius: 10,
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <Text style={{ color: "#FFF" , fontWeight: "600" }}>
      Update
    </Text>
  </TouchableOpacity>
     )}
     </Hoverable>
</View>}

</View>}
      </Modal>
  )
}

export default BuyModal

const styles = StyleSheet.create({
  subscribedNextPrev: {
    borderWidth: 0,
    marginHorizontal: !isMobileView() && !isTabletView() && 45 || !isMobileView() && isTabletView() && 20 || isMobileView() && 0,
    margin: !isMobileView() ? 20 : 16,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  head: { height: responsiveHeight(6), backgroundColor: "#fff" },
  text: {
    margin: 6,
    marginLeft: 15,
    fontWeight: 600,
    fontSize: isMobileView() ? responsiveFontSize(1.9)  : responsiveFontSize(1),
  },
  textHead: {
    margin: 6,
    marginLeft: 15,
    fontWeight: 500,
    fontSize: responsiveFontSize(1.1),
    color: "rgba(0, 0, 0, 0.60)",
  },
  tableRow: {
    width:'90%',
    borderWidth: 1,
    borderColor: "rgba(0, 0, 0, 0.15)",
    marginVertical: 8,
    borderRadius: 6,
    height: responsiveHeight(12),
    flexDirection: "row",
    justifyContent:"space-between"
  },
  container: { flex: 1,  backgroundColor: '#fff' },
  head: { height: responsiveHeight(8), backgroundColor: '#fff' },
  text: { margin: 6, marginLeft:15, fontWeight:600,  fontSize: isMobileView() ? responsiveFontSize(1.9)  : responsiveFontSize(1), },
  textHead: { margin: 6, marginLeft:15, fontWeight:500,  fontSize: isMobileView() ? responsiveFontSize(2) : responsiveFontSize(1.1),color:"rgba(0, 0, 0, 0.60)"},
  tableRow:{
    borderWidth:1,
    borderColor:"rgba(0, 0, 0, 0.15)",
    marginVertical:8,
    borderRadius:6,
    height: isOnlyLaptopView() ? responsiveHeight(10) : responsiveHeight(12),
    flexDirection:"row"
  },
  Linecontainer: {
    height: '100%', // Adjust the height percentage as needed
    justifyContent: 'center', // Center the line vertically within its parent
  },
  line: {
    width: 2,
    height: '100%', // Set the line's height to 100% of its parent
    backgroundColor: 'rgba(0, 0, 0, 0.15)',
  },
  membersSearchArea: {
    // flex:1,
    borderBottomWidth: 1,
    borderColor: "rgba(0, 0, 0, 0.15)",
    justifyContent: "center",
    height: responsiveHeight(14.5),
    alignItems: "center",
    // width:'100%'
  },
  membersSearchAreaContainer: {
    borderWidth: 0,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "95%",
  },
  memberContainer: {
    // flex:,
    borderWidth: 0,
    width: "100%",
    // height: "100%",
  },
  subscribedNextPrev: {
    borderWidth: 0,
    marginHorizontal: !isMobileView() && !isTabletView() && 45 || !isMobileView() && isTabletView() && 20 || isMobileView() && 0,
    margin: !isMobileView() ? 20 : 16,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  modalButtonContainer: {
    backgroundColor: "#FFF",
    position: "absolute",
    top: "110%",
    right: "-30%",
    borderRadius: 10,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.15,
    shadowRadius: 6,
    elevation: 1,
  },
  modalButton: {
    paddingVertical: 10,
    alignItems: "center",
    justifyContent: "flex-start",
    gap: 5,
    paddingHorizontal: 20,
    zIndex: 4,
    borderRadius: 5,
  },
  languageButton: {
    fontFamily: "openSans",
    fontWeight: "600",
    fontSize: 16,
    // padding: 10,
    borderRadius: 8,
    color: Color.BLUE_MEDIUM,
    // width: isMobileView() ? 100 : 120,
    textAlign: "center",
  },
  activeLanguageButton: {
    // backgroundColor: Color.BLUE_LIGHT,
    // shadowColor: 'rgba(0, 0, 0, 0.25)',

    // borderRadius: 8,
  },
  magCard: {
    width: isMobileView() && "100%" || !isMobileView() && isTabletView() && "48%" ||  isSmallLaptop() && "32%" ||  responsiveWidth(21.3),
    height: isMobileView() ? responsiveHeight(9)  :    responsiveHeight(14),
    borderRadius: 8,
    paddingVertical: 7,
    alignItems: "flex-start",
    flexDirection: "row",

  },
  selectedMagCard:{
    borderWidth:  2,
    backgroundColor: "rgba(92, 66, 218, 0.05)",
    borderColor:Color.BLUE_MEDIUM

  },
  unSelectedMagCard:{
    shadowColor: 'rgba(0, 0, 0, 0.15)',
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 1,
    shadowRadius: isMobileView() ? 5 : 10,
    backgroundColor: "#FFF"

  },
  head: { height: responsiveHeight(6), backgroundColor: '#fff' },
  text: { margin: 6, marginLeft:15, fontWeight:600,fontSize: isMobileView() ? responsiveFontSize(1.9)  : responsiveFontSize(1.1),display:"flex",gap:25 },
  textHead: { margin: 6, marginLeft:15, fontWeight:500,fontSize: isMobileView() ? responsiveFontSize(2) : responsiveFontSize(1.2),color:"rgba(0, 0, 0, 0.60)",},
  tableRow:{
    borderWidth:1,
    borderColor:"rgba(0, 0, 0, 0.15)",
    marginVertical:8,
    borderRadius:6,
    height:responsiveHeight(9),
    flexDirection:"row"
  },
  modalView: {
    // position:"relative",
    // flex: 1,
    backgroundColor: "white",
    borderRadius: !isMobileView() && 20,



    alignItems: "center",
    shadowColor: Color.BLACK,
    marginTop: isOnlyLaptopView() && 25 || isOnlyTabletView() && 20,
    marginBottom: isOnlyLaptopView() && 25 || isOnlyTabletView() && 20,

    flexDirection: isMobileView() ? "column" : "row",
    width:  isMobileView()  && "100%" ||  isOnlyTabletView() && "95%" ||isOnlyLaptopView() && "70%",
    height:  isMobileView()  && "100%" ||  isOnlyTabletView() && "95%" ||isOnlyLaptopView() && "50%",

  },
  modalViewError: {
    borderWidth: 0,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    width: responsiveWidth(30),
    height: responsiveHeight(50),
    margin: 5,
    backgroundColor: "#fff",
    borderRadius: 8,
    padding: 25,
    shadowColor: "none",


    shadowRadius: 4,

  },
  centeredView: {
    flex: 1,
    position:"relative",
    justifyContent: "center",
    alignItems: "center",
    width:'100%',
    height:'100%',
    overflow: !isMobileView() && "hidden",
    alignSelf:'center',
    backdropFilter: 'blur(20px)',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
  centeredViewError: {
    flex: 1,
    backdropFilter: 'blur(20px)',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    justifyContent: "center",
    alignItems: "center",
  },
  modalText: {

    fontFamily:"openSans",
    fontWeight: "700",
    fontSize: isMobileView() ? responsiveFontSize(2.2) : responsiveFontSize(1.5),
  },
  textBoxView: {
    width: "100%",

    margin: 2.5,
    height: isMobileView() && isTabletView()  ? "fit-content" : "30%",
    flexDirection: isMobileView() ? "column" : "row",
    justifyContent: "space-between",
    padding: 0.5,
    gap:15
  },
  textboxInput: {
    width: "100%",
    height: isMobileView() && isTabletView()  ? responsiveScreenHeight(6.5) : "50%",
    textOverflow: "ellipsis",
    textWrap: "nowrap",
    marginTop:10,
    backgroundColor: Color.WHITE,
    opacity: 0.5,
    cursor: "default",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 10,
    borderWidth: 1,
    borderColor: "rgba(0, 0, 0, 0.14)",
    color: "rgba(0, 0, 0,)",
    fontSize: isMobileView()  ? responsiveFontSize(2.2) :  responsiveFontSize(1.1),
    textAlign: "left",
    padding: 10,

    fontWeight: "600",
},
Linecontainer: {
  height: '100%', // Adjust the height percentage as needed
  justifyContent: 'center', // Center the line vertically within its parent
},
line: {
  width: 2,
  height: '100%', // Set the line's height to 100% of its parent
  backgroundColor: 'rgba(0, 0, 0, 0.15)',
},
messageContainer : {
  position: "absolute",
  backgroundColor: Color.WHITE,
  borderWidth:0,
  borderColor: Color.BLUE_MEDIUM,
  borderRadius:8,
  alignItems:"center",
  justifyContent:"center",
  // height:"20%",
  paddingBottom:12,
  paddingTop:6,
  paddingHorizontal:15,
  gap:5,
  top: 0,
  left: "50%",
  transform: [
    { translateX: "-50%" }, // Translate the view horizontally by -50% of its own width
    { translateY: "0%" }, // Translate the view vertically by -50% of its own height
  ],
  zIndex:2,
  borderTopWidth:0,
  borderTopLeftRadius:0,
  borderTopRightRadius:0,
  shadowColor: 'rgba(99, 99, 99, 0.2)',
  shadowOffset: {
    width: 0,
    height: 2,
  },
  shadowRadius: 8,
  shadowOpacity: 1,
  elevation: 5, // This is for Android shadow
  width: isMobileView() && "100%"
},
messageText:{
  textAlign:"center",
  fontWeight:"500"
}
});

