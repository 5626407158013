import React, { useState } from "react";
import { ScrollView } from "react-native";
import { TouchableOpacity } from "react-native";
import { Modal } from "react-native";
import {
  Alert,
  StyleSheet,
  Text,
  View,
  Image,
} from "react-native";
import { Color, isMobileView, isOnlyLaptopView } from "../Common/Constants";
import commonStyles from "../styles/styles";
const Payment_Success = ({route,navigation}) => {
  console.log(route.params)
    const [error, setError] = useState(null);
    const [thankYouPage, setThankYouPage] = useState(true);

    const handleReceiptPrint = ()=>{
      console.log(route.params.receipt_url)
      window.location.replace(route.params.receipt_url)

    }

    const MagazineSummary = ({imageSource,title,language,price}) =>{
      return (
        <>
                    <View style={{backgroundColor:'#FFF',aspectRatio: 3,gap:40,paddingVertical:7,alignItems:"center",borderWidth:2,borderColor:"#EAF9F1",flexDirection:'row',borderRadius:10,justifyContent:"space-between"}}>
                <View style={{flexDirection:"row"}}>

                <View>
                <Image style={{width:70,height:70}} source={imageSource}/>

                </View>
                <View style={{justifyContent:'center',borderWidth:0}}>
                <Text style={{fontSize:20,fontWeight:'600',color:'rgba(0, 0, 0, 0.8)'}}>{title}</Text>
                <Text style={{fontSize:14,fontWeight:'500',color:'rgba(0, 0, 0, 0.5)'}}>Language - {language}</Text>


                </View>
                </View>

                <View style={{justifyContent:'center',flexDirection:"row",alignItems:"center",borderWidth:0,right:15}}>
                <Text style={{fontSize:22,fontWeight:'500'}}>₹</Text>
                <Text style={{fontSize:24,fontWeight:'600'}}> {price}</Text>


                </View>

            </View>
        </>
      )
    }

  return (
    <View style={styles.container}>

        <Modal
      animationType="fade"
      transparent={true}
      visible={thankYouPage}
      onRequestClose={() => {
        Alert.alert("Modal has been closed.");
        setThankYouPage(!thankYouPage);
      }}
    >
      <View
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          width:'100%',
          height:'100%',
          alignSelf:'center',
          backdropFilter: 'blur(20px)',
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
        }}
      >
        <View
          style={{
            backgroundColor: "white",
            borderRadius: 15,
            position:"relative",
            alignItems: "center",
            shadowColor: Color.BLACK,
            padding:20,
            paddingTop:55,
            gap:  45 ,


            width: isMobileView() ? "90%" : "80%",
            backgroundColor: "#F8F9FA",
            alignItems: "center",
            borderRadius: 20,
          }}
        >



            <TouchableOpacity
              onPress={() => {
                setThankYouPage(!thankYouPage);
                navigation.navigate('Dashboard')
              }}
              style={{        borderWidth: 0,
                borderColor: Color.WHITE,
               position:"absolute",right:20,top:20}}
            >
              <Image
                style={{ width: 30, height: 30,    borderWidth: 0,
                  borderColor: Color.WHITE,

               }}
                source={require("../../assets/images/cross.png")}
              />
            </TouchableOpacity>
          <View style={{  alignItems: "center", width:"100%",gap:20 }}>
        <View style={[commonStyles.alignCenter]}>

          <Image
                 style={{ width: isMobileView() ? 100 : 200, height: isMobileView() ? 100 : 200,left: isMobileView() ? "2%" : "6%",resizeMode:"cover" }}
              source={require("../../assets/images/Payment_Success.png")}
            />
            <Text style={{ fontSize: isMobileView() ? 35 : 45, fontWeight: "700",marginTop:15 }}>
               Success !
            </Text>
            <View style={{ marginTop:10, alignItems: "center" }}>
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "600",
                  color: "rgba(0, 0, 0, 0.5)",
                }}
              >
                Payment is completed
              </Text>

              </View>
              </View>
                <View style={{flexDirection:  "row" , width: isMobileView() ? "100%" : "40%", justifyContent:"center"}}>

              <View style={[commonStyles.alignCenter]}>


            <View style={{ marginTop:15, alignItems: "center" }}>
              <Text
                style={{
                  fontSize: 16,
                  fontWeight: "600",
                  color: "rgba(0, 0, 0, 0.5)",
                }}
              >
                Total Amount
              </Text>

              </View>
              <View style={{justifyContent:'center',flexDirection:"row",alignItems:"center",borderWidth:0}}>
                <Text style={{fontSize:20,fontWeight:'600'}}>₹</Text>
                <Text style={{fontSize:22,fontWeight:'600'}}> {route.params.amount}</Text>
                {/* <Text style={{fontSize:22,fontWeight:'600'}}> {"200"}</Text> */}


                </View>
                </View>

               {!route.params.unpaidFlag && <View style={[commonStyles.alignCenter]}>

                <View style={{ marginTop:15, alignItems: "center" }}>
              <Text
                style={{
                  fontSize: 16,
                  fontWeight: "600",
                  color: "rgba(0, 0, 0, 0.5)",
                }}
              >
                Mode
              </Text>

              </View>
              <View style={{justifyContent:'center',flexDirection:"row",alignItems:"center",borderWidth:0}}>
                <Text style={{fontSize:22,fontWeight:'600'}}> {route.params.mode}</Text>
                {/* <Text style={{fontSize:22,fontWeight:'600'}}>{"Testing"}</Text> */}


                </View>
                </View>
}


               {!route.params.unpaidFlag &&  <View style={[commonStyles.alignCenter]}>

                <View style={{ marginTop:15, alignItems: "center" }}>
              <Text
                style={{
                  fontSize: 16,
                  fontWeight: "600",
                  color: "rgba(0, 0, 0, 0.5)",
                }}
              >
                Order Id
              </Text>

              </View>
              <View style={{justifyContent:'center',flexDirection:"row",alignItems:"center",borderWidth:0}}>
                <Text style={{fontSize:22,fontWeight:'600'}}> {route.params.order_id}</Text>
                {/* <Text style={{fontSize:22,fontWeight:'600'}}>{"testing"}</Text> */}


                </View>
                </View>}
                </View>


          </View>
          <View style={[ {  backgroundColor: '#000',
            borderRadius: 8,
            padding: 10,   width: isMobileView() ? "100%" : 150,justifyContent:"center" }]}>
                <TouchableOpacity
          onPress={() => {
            handleReceiptPrint()
            setThankYouPage(!thankYouPage);
          }}
          style={{
            alignSelf:"center"

          }}
        >

          <Text style={{ textTransform: 'capitalize',color:Color.WHITE,fontWeight:"500",with:"100%" }}>Print Receipt</Text>

        </TouchableOpacity>

    </View>
          <View style={[ {  backgroundColor: '#000',
            borderRadius: 8,
            padding: 10,   width: isMobileView() ? "100%" : 150,justifyContent:"center" }]}>
                <TouchableOpacity
          onPress={() => {
            navigation.navigate('Dashboard')
            setThankYouPage(!thankYouPage);
          }}
          style={{
            alignSelf:"center"

          }}
        >

          <Text style={{ textTransform: 'capitalize',color:Color.WHITE,fontWeight:"500",with:"100%" }}>Back to Dashboard</Text>

        </TouchableOpacity>

    </View>
        </View>
      </View>
    </Modal>
  </View>
  )
}

export default Payment_Success


const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  image: {
    width: 200,
    height: 200,
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
  },
  message: {
    fontSize: 16,
  },
});
