import { StyleSheet, Text, View } from 'react-native'
import React from 'react'

import Magazinepage from '../screens/Magzinepage'
import QuickViewPage from '../screens/QuickViewPage';
import { createStackNavigator } from '@react-navigation/stack';
import OnlinePayments from '../screens/OnlinePayments';
import SelectionMagazine from '../screens/SelectionMagazine';
import { Color } from '../Common/Constants';
import PrintScreen from '../screens/PrintScreen';

const MagzinepageStack = () => {
    // const { memberCode } = route.params;

    const Stack = createStackNavigator();
  return (

    <Stack.Navigator initialRouteName='Home' screenOptions={{headerShown:false,cardStyle: { backgroundColor: Color.WHITE,  gestureEnabled: true, gestureDirection: 'horizontal',flex:1 },
  }}>
    <Stack.Screen options={{ gestureEnabled: true, gestureDirection: 'horizontal',}} name="Home" component={Magazinepage} />
    <Stack.Screen options={{ gestureEnabled: true, gestureDirection: 'horizontal',}} name="SelectionMagazine" component={SelectionMagazine} />
    <Stack.Screen options={{ gestureEnabled: true, gestureDirection: 'horizontal',}} name="PrintScreen" component={PrintScreen} />
  </Stack.Navigator>
//
  )
}
  
export default MagzinepageStack

const styles = StyleSheet.create({})


