import { StyleSheet, Text, View } from "react-native";
import React, {  useContext, useEffect, useState } from "react";
import { ScrollView } from "react-native";

import {
  responsiveFontSize,
  responsiveHeight,
  responsiveWidth,
} from "react-native-responsive-dimensions";
import commonStyles from "../styles/styles";
import { FlatList } from "react-native";
import { apiServices } from "../services/apiServices";
import AsyncStorage from "@react-native-async-storage/async-storage";
import ReceiptComponent from "../Common/ReceiptComponent";
import Headerdiv from "../Common/Headerdiv";
import AppLoading from "expo-app-loading";
import { useFonts } from "expo-font";
import { TouchableOpacity } from "react-native";
import { Image } from "react-native";
import { Color, isLargeLaptop, isMobileView, isTabletView } from "../Common/Constants";
import Hoverable from "react-native-hoverable";
import { Cell, Row, Table, TableWrapper } from "react-native-table-component";
import ErrorContext from "../context/ErrorContext";
import PopupComponent from "../Common/PopupComponent";
import { formatDates,formatedDateWithMonth ,arrayReverseSort} from "../util/fomatedDate";
let totalPages
let startIndex
let endIndex
let currentPageData = []
const itemsPerPage = 4;
const BulkPurchase = ({ navigation }) => {
  const { error, dispatchError, dispatchSuccess } = useContext(ErrorContext);

  let COUNT;
  const { getBulkOrdersAPI,getBulkPrint } = apiServices;
  const [memberCode, setMemberCode] = useState(null);
  const [rreceiptDataIn, setRreceiptDataIn] = useState({});
  const [loader, setLoader] = useState(1)
  const [isTooltipVisible, setIsTooltipVisible] = useState({});
  const [isTooltipVisibleDownload, setIsTooltipVisibleDownload] = useState({});
  const tableHead = ["Date", "Magazine", "Volume", "Qty", "Paid Amount","Action"];
  const [currentPageData, setCurrentPageData] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const updateCurrentPageData = (page, data) => {
    const startIdx = (page - 1) * itemsPerPage;
    const endIdx = startIdx + itemsPerPage;
    return data.slice(startIdx, endIdx);
  };

  const formatMonth = (month)=>{
    const mnthArr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return mnthArr[+month - 1];
  }

  // Function to handle the "next" button click
  const handleNextPage = () => {
    const nextPage = Math.min(currentPage + 1, totalPages);
    setCurrentPage(nextPage);
    setCurrentPageData(updateCurrentPageData(nextPage, rreceiptDataIn)); // Update currentPageData
  };

  // Function to handle the "previous" button click
  const handlePreviousPage = () => {
    const prevPage = Math.max(currentPage - 1, 1);
    setCurrentPage(prevPage);
    setCurrentPageData(updateCurrentPageData(prevPage, rreceiptDataIn)); // Update currentPageData
  };

  // Function to handle clicking on a specific page number
  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    setCurrentPageData(updateCurrentPageData(pageNumber, rreceiptDataIn)); // Update currentPageData
  };
   var pageNumbers = [];

   // Generate an array of page numbers to display in pagination
   const getPageNumbers = () => {
    const range = 2; // Number of page numbers to show before and after the current page

    // Calculate the range of page numbers to show
    let start = Math.max(currentPage - range, 1);
    let end = Math.min(currentPage + range, totalPages);

    // Adjust the range if the current page is near the start or end
    const maxRange = 2 * range + 1;
    if (end - start < maxRange) {
      if (currentPage < totalPages / 2) {
        end = Math.min(end + maxRange - (end - start), totalPages);
      } else {
        start = Math.max(start - maxRange + (end - start), 1);
      }
    }

    // Add page numbers to the array
    for (let i = start; i <= end; i++) {
      pageNumbers.push(i);
    }

    return pageNumbers;
  };
  const DownloadPrint = async (data) => {
    try {
      let res = await getBulkPrint(memberCode,data.bulk_id);
      window.open(res.url, '_blank')
    } catch (error) {
      dispatchError(error)
    }

  // setTimeout(() => {
  //   window.location.replace(`http://mag-dev.odpay.in:3001/portal/${memberCode}/get_receipt?id=${data.id}&bulk_purchase=true`)
  //   // setThankYouPage(!thankYouPage)
  // }, 3000);
  };
  const statusPill = (data, index) => {

    return(
      <View
          style={{
            margin: 6,
             marginLeft:15,
            alignItems: "center",
            justifyContent: "center",
            flexDirection:"row",
            backgroundColor: data === "Y" ? "#EAF9F1" : "#FEEEE9",
            width:"80%",padding:5,
            borderRadius:50,

          }}
        >

          <Text style={[styles.text,{color:data=== "Y" ? "#2CC373" : "#F24D14",bottom:0,position:"relative",margin:0}]}>{data}</Text>
        </View>
    )};
    const viewButton = (data, index) => {

    return(
      <View style={{display:'flex',justifyContent:'space-between',alignItems:"center",flexDirection:"row"}}>
      <TouchableOpacity
       onMouseEnter={() => setIsTooltipVisibleDownload({index:index,state:true})}
       onMouseLeave={() => setIsTooltipVisibleDownload({index:index,state:false})}
      onPress={() => {DownloadPrint(data)}}
      style={{
        margin: 6,
         marginLeft:15,

        cursor:"pointer",
        borderRadius:50,

      }}
      >
      <Image
      source={require("../../assets/images/downloadFile.png")}
      style={{width:20,height:30}}
      resizeMode="cover"
      >

      </Image>
      {(isTooltipVisibleDownload.index == index  &&  isTooltipVisibleDownload.state == true) && (

<View
  style={{
    position: "absolute",
    left: -40,
    bottom:-37 ,
    backgroundColor: Color.BLACK,
    padding: 8,
    zIndex:100,
    borderRadius: 2,
    alignItems:"center",
    width:120
  }}

>
     <TouchableOpacity

style={{
width:"100%"
}}
>
  <Text
    style={{
      fontSize: 12,
      fontWeight: "600",
      color: Color.WHITE,
      textAlign:"center"
    }}
  >
    Download
    </Text>
</TouchableOpacity>
</View>
)}
    </TouchableOpacity>

    <TouchableOpacity
        onMouseEnter={() => setIsTooltipVisible({index:index,state:true})}
        onMouseLeave={() => setIsTooltipVisible({index:index,state:false})}
      onPress={() =>navigation.navigate("member", { screen:'TimeLine',params:{id:data.id,bulkPurchase:true,allData:false}})}

      style={{
        margin: 6,
         marginLeft:15,

        cursor:"pointer",
        borderRadius:50,

      }}
      >
            <Image
      source={require("../../assets/images/eye.png")}
      style={{width:25,height:25}}
      resizeMode="contain"
      >

      </Image>
      {(isTooltipVisible.index == index  &&  isTooltipVisible.state == true) && (

<View
  style={{
    position: "absolute",
    left: -40,
    bottom:-40 ,
    backgroundColor: Color.BLACK,
    padding: 8,
    zIndex:100,
    borderRadius: 2,
    alignItems:"center",
    width:120
  }}

>
     <TouchableOpacity

style={{
width:"100%"
}}
>
  <Text
    style={{
      fontSize: 12,
      fontWeight: "600",
      color: Color.WHITE,
      textAlign:"center"
    }}
  >
    View Timeline
    </Text>
</TouchableOpacity>
</View>
)}

    </TouchableOpacity>

    </View>

    )};
  const dateItem = (data, index) => {
    return (
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          borderWidth: 0,
          height: "100%",
          width: "100%",
          margin: 6,
          marginLeft: 15,
          gap: 15,
        }}
      >
        <Image
          style={{
            height: responsiveHeight(4.2),
            width: responsiveWidth(2.2),
            resizeMode: "contain",
          }}
          source={require("../../assets/images/cal2.png")}
        />
        <Text
          style={{
            fontFamily: "openSans",
            fontWeight: "600",
            fontSize: responsiveFontSize(1),
          }}
        >
          {data}
        </Text>
      </View>
    );
  };
  const forItem = (data, index) => {
    return (
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          borderWidth: 0,
          height: "100%",
          width: "100%",
          margin: 6,
          marginLeft: 15,
          gap: 15,
        }}
      >
        <Image
          style={{
            height: responsiveHeight(4.2),
            width: responsiveWidth(2.2),
            resizeMode: "contain",
          }}
          source={require("../../assets/images/membersIcon.png")}
        />
        <Text
          style={{
            fontFamily: "openSans",
            fontWeight: "600",
            fontSize: responsiveFontSize(1),
          }}
        >
          {data}
        </Text>
      </View>
    );
  };
  const copiesItem = (data, index) => {
    return (
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          borderWidth: 0,
          height: "100%",
          width: "100%",
          margin: 6,
          marginLeft: 15,
          gap: 15,
        }}
      >
        <Image
          style={{
            height: responsiveHeight(4.2),
            width: responsiveWidth(2.2),
            resizeMode: "contain",
          }}
          source={require("../../assets/images/type.png")}
        />
        <Text
          style={{
            fontFamily: "openSans",
            fontWeight: "600",
            fontSize: responsiveFontSize(1),
          }}
        >
          {data}
        </Text>
      </View>
    );
  };
  const amountItem = (data, index) => {
    return (
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          borderWidth: 0,
          height: "100%",
          width: "100%",
          margin: 6,
          marginLeft: 15,
          gap: 15,
        }}
      >
        <Image
          style={{
            height: responsiveHeight(4.2),
            width: responsiveWidth(2.2),
            resizeMode: "contain",
          }}
          source={require("../../assets/images/tag.png")}
        />
        <Text
          style={{
            fontFamily: "openSans",
            fontWeight: "600",
            fontSize: responsiveFontSize(1),
          }}
        >
          ₹{data}
        </Text>
      </View>
    );
  };
  const actionItem = (data, index) => {
    return (
      <Hoverable>
        {({ hovered }) => (
          <TouchableOpacity
            onPress={() => setModalVisible(true)}
            style={[
              {
                paddingVertical: 2.5,
                paddingHorizontal: 5,
                borderRadius: 100,
                margin: 6,
                marginLeft: 15,
                with: "fit-content",
              },
            ]}
          >
            <Text
              style={{
                color: Color.BLUE_MEDIUM,
                fontWeight: "600",
                fontSize: responsiveFontSize(1),
              }}
            >
              {data}
            </Text>
          </TouchableOpacity>
        )}
      </Hoverable>
    );
  };

  const getMemberCodeX = async () => {
    let getMemberCode;
    try {
      getMemberCode = await AsyncStorage.getItem("member_code");
      setMemberCode(getMemberCode);
      setLoader((prev) => {
        return prev !== 0 ? prev - 1 : 0;
      });
    } catch (error) {
      dispatchError(error);
      setLoader((prev) => {
        return prev !== 0 ? prev - 1 : 0;
      });
    }
  };
  useEffect(() => {
    getMemberCodeX();
  }, []);

  const getBulkOrders = async () => {
    let data ={"member_code":"5"}
    let getBulkOrderData = await getBulkOrdersAPI(memberCode,data);
    // console.log(getReceiptsData)
    arrayReverseSort(getBulkOrderData,'created_at')
    setRreceiptDataIn(getBulkOrderData);
    totalPages = Math.ceil(getBulkOrderData.length / itemsPerPage);
      startIndex = (currentPage - 1) * itemsPerPage;
      endIndex = Math.min(startIndex + itemsPerPage, getBulkOrderData.length);
      setCurrentPageData(getBulkOrderData.slice(startIndex, endIndex))

    };
    COUNT = rreceiptDataIn.length;

  useEffect(() => {
    if (memberCode) {
      getBulkOrders();
    }
  }, [memberCode]);

  let [fontsLoaded] = useFonts({
    openSans: require("../../assets/fonts/OpenSans-VariableFont_wdth,wght.ttf"),
  });
  if (!fontsLoaded) {
    return <AppLoading />;
  }
  const renderReceipts = ({ item }) => {
    return (
      <ReceiptComponent
        data={item}
        memberCode={memberCode}
        navigation={navigation}
      />
    );
  };

  return (
    <>
    {loader === 0 ?(<View
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: "#FFF",
        overflow: "hidden",
      }}
    >
      {/* <View style={{width:"100%",height:50,backgroundColor:'#F8F9FA',borderWidth:1}}>
        <Text>Dhan Nirankar Ji - Virat Kohli</Text>
      </View> */}
      <Headerdiv memberCode={memberCode} navigation={navigation} />
      <View
        style={{
          borderBottomWidth: 1,
          borderColor: "rgba(0, 0, 0, 0.15)",
          justifyContent: "center",
          paddingVertical: 18,
          alignItems: "center",
          width: "100%",
        }}
      >
        <View
          style={{
            borderWidth: 0,
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: isMobileView() ? "90%" : "96%",
            bottom: isMobileView() ? 3 : 0,
          }}
        >
          <Text
            style={{
              fontWeight: "600",
              fontSize: isTabletView() ? responsiveFontSize(2.6) :  responsiveFontSize(2.2),
            }}
          >
            Bulk Orders
          </Text>
          <View style={[commonStyles.gap1rem, commonStyles.flexRow]}>
          <Hoverable>
{({ hovered }) => (
        <TouchableOpacity
          style={{
            justifyContent: !isMobileView() && "space-evenly",
            gap: isMobileView() &&  10,
            paddingLeft: isMobileView() &&  10,
            alignItems: "center",
            flexDirection: "row",
            borderWidth: 2,
            borderRadius: 10,
            borderColor: Color.BLUE_MEDIUM,
            height: 40,
            width: isMobileView() ? 150 : 120,
            backgroundColor: hovered ? Color.BLUE_MEDIUM : "white",
          }}
          onPress={() => {
            navigation.navigate('Dashboard');
          }}
        >
          <Image
            style={{       height: 25,
              width: 25,
              resizeMode: "contain",
              borderWidth: 0,
              transform: [{ rotate: "180deg" }]
            }}
            source={ hovered ? require("../../assets/images/goBackWhite.png") : require("../../assets/images/goBack.png")}
          />
          <Text
            style={{
              fontFamily: "openSans",
              fontWeight: "600",
              fontSize: 15,
              color:  hovered ?  "white" : Color.BLUE_MEDIUM,
            }}
          >
            Go Back
          </Text>
        </TouchableOpacity>
             )}
             </Hoverable>
          </View>
        </View>
      </View>
      <View>
        <View style={styles.memberContainer}>
          <View
            style={[
              styles.subscribedNextPrev,
              !isMobileView() && commonStyles.pl_2,
              isMobileView() && commonStyles.width90,
              isMobileView() && commonStyles.alignSelfCenter,
            ]}
          >

          </View>
          <View
            style={[
              commonStyles.spaceLeftPrimary,
              commonStyles.spaceRightPrimary,
              {
                borderWidth: 0,
                // alignSelf: "center",
                // marginVertical: 10,
                marginBottom: 10,
                paddingRight:0
              },

            ]}
          >
                  <View style={[styles.container,

]}>
            <ScrollView contentContainerStyle={{width:"100%"}}  style={{width:"100%"}} horizontal={!isLargeLaptop() && true      }>

          <Table style={{width: isMobileView() ? "250%" : "100%"}} borderStyle={{ borderWidth: 0, borderColor: '#c8e1ff' }}>
        <Row data={tableHead}   style={styles.head} textStyle={styles.textHead} />
        {currentPageData.map((rowData, index) => (
      <TableWrapper key={index} style={styles.tableRow}>

        <Cell
          data={formatDates(rowData?.created_at)}
          textStyle={styles.text}
          style={{ flex: 1, justifyContent: 'center', alignItems: 'flex-start' }}
        />
        <Cell data={rowData?.magazine_name} textStyle={styles.text} style={{ flex: 1, alignItems: 'flex-start' }} />
        <Cell
          data={`${formatMonth(rowData?.month)}'${rowData?.year} ` }
          textStyle={styles.text}
          style={{ flex: 1, justifyContent: 'center', alignItems: 'flex-start' }}
        />
        <Cell data={rowData?.quantity} textStyle={styles.text} style={{ flex: 1, alignItems: 'flex-start' }} />
        {/* <Cell
          data={statusPill(rowData?.status, index)}
          textStyle={styles.text}
          style={{ flex: 1, justifyContent: 'center', alignItems: 'flex-start' }}
        /> */}
        <Cell data={'₹'+rowData?.amount} textStyle={styles.text} style={{ flex: 1 }} />

        <Cell
          key={index}
          data={viewButton(rowData, index)}
          textStyle={styles.text}
          style={{ flex: 1, justifyContent: 'center', alignItems: 'flex-start' }}
        />
      </TableWrapper>
      //  <FilteredRow key={index} rowData={rowData} index={index} />
    ))}
          {/* </TableWrapper> */}
        {/* ))} */}
      </Table>
      </ScrollView>
</View>
      <View style={{ flexDirection: 'row', justifyContent: 'flex-end', marginTop: 10, alignItems:'center',borderWidth:0}}>

  <TouchableOpacity style={{borderRadius:7.13,cursor:pageNumbers<1 && 'pointer' ,borderColor:'rgba(0, 0, 0, 0.15)',borderWidth:2,height:30,width:30,justifyContent:'center',alignItems:'center'}}  onPress={handlePreviousPage}>
    <Image style={{ tintColor: currentPage > 1 ? Color.BLUE_MEDIUM:'',height:20,width:20,}} source={require('../../assets/images/nextPageIcon.png')}></Image>
  </TouchableOpacity>
  {/* Page numbers */}
  {getPageNumbers().map((pageNumber) => (
    <TouchableOpacity key={pageNumber} onPress={() => handlePageClick(pageNumber)}>
      <Text
        style={{
          fontSize: 15,
          marginHorizontal: 10,
          fontWeight: pageNumber === currentPage ? 'bold' : 'normal',
          cursor: 'pointer',
        }}
      >
        {pageNumber}
      </Text>
    </TouchableOpacity>
  ))}

  <TouchableOpacity style={{borderRadius:7.13,cursor:currentPage !== totalPages &&'pointer', borderColor:'rgba(0, 0, 0, 0.15)',borderWidth:2,height:30,width:30,justifyContent:'center',alignItems:'center'}} onPress={handleNextPage}>
    <Image style={{ tintColor: currentPage === totalPages ?  '' : Color.BLUE_MEDIUM,height:20,width:20,transform:[{rotate: '180deg'}]}} source={require('../../assets/images/nextPageIcon.png')}></Image>
  </TouchableOpacity>
</View>
          </View>
        </View>
      </View>
    </View>
    ):(
        <SafeAreaView
          style={{
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: Color.WHITE,
            margin: "auto",
          }}
        >
          <ActivityIndicator size="large" />
          <Text style={{ fontSize: 15, fontWeight: "700", color: "#b4b4b4" }}>
            Please Wait ...
          </Text>
        </SafeAreaView>
      )}
            <View style={{position:"absolute",width:"100%"}}>
    {error.text2 !== '' && <PopupComponent
                style={{ zIndex: 100 }}
                hideFun={() => {
                  dispatchError("")
                  dispatchSuccess("")
                }}
                type={error.type}
                text1={error.text1}
                text2={error.text2}
              />}
              </View>
    </>
  );
};

export default BulkPurchase;

const styles = StyleSheet.create({
  body: {},
  subscribedNextPrev: {
    borderWidth: 0,

    marginHorizontal: !isMobileView() && !isTabletView() && 45 || !isMobileView() && isTabletView() && 20 || isMobileView() && 0,
    margin: !isMobileView() ? 20 : 16,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  head: { height: responsiveHeight(6), backgroundColor: "#fff" },
  text: {
    margin: 6,
    marginLeft: 15,
    fontWeight: 600,
    fontSize: isMobileView() ? 14  : responsiveFontSize(1),
  },
  textHead: {
    margin: 6,
    marginLeft: 15,
    fontWeight: 500,
    fontSize: isMobileView() ? 15 : responsiveFontSize(1.1),
    color: "rgba(0, 0, 0, 0.60)",
  },
  tableRow: {
    borderWidth: 1,
    borderColor: "rgba(0, 0, 0, 0.15)",
    marginVertical: 8,
    borderRadius: 6,
    height: responsiveHeight(12),
    flexDirection: "row",
    justifyContent:"space-between"
  },
  textStyle: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
  },
    container: { flex: 1,  backgroundColor: '#fff' },
});
