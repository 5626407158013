import {
    Image,
    StyleSheet,
    Text,
    View,
    TouchableOpacity,
    Modal,
    Pressable,
    TextInput,
  } from "react-native";
  import React, { useEffect, useState } from "react";
  import {
    responsiveFontSize,
    responsiveScreenHeight,
    responsiveScreenWidth,
  } from "react-native-responsive-dimensions";
  import SelectDropdown from "react-native-select-dropdown";
  import MagzineCards from "./MagzineCards";
import { Color, isMobileView, isOnlyLaptopView, isOnlyTabletView, isTabletView } from "./Constants";
import {API_URL} from "@env"
let formattedDate
let resultDate

const BuyCardComponent = (data) => {
  const [startDate,setStartDate] =useState()
  useEffect(()=>{
  formattedDate = formatDate(data.startDate);
  setStartDate(formattedDate)

  },[])
  function formatDate(inputDate) {
    if (!inputDate || inputDate.length < 7) {
      return ""; // Handle invalid input
    }

    const dateParts = inputDate.split('-');
    if (dateParts.length !== 3) {
      return ""; // Handle invalid input
    }

    const year = dateParts[0].slice(-2); // Get the last two digits of the year
    const month = new Date(inputDate).toLocaleString('default', { month: 'short' });
    return `${month} ${year}`;
  }

  function calculateDateAfterXMonths(startDate, monthsToAdd) {
    if(monthsToAdd){

      const startDateObj = new Date(startDate);
      startDateObj.setMonth(startDateObj.getMonth() + monthsToAdd);

      // Format the result as "yyyy-mm-dd"
      const year = startDateObj.getFullYear();
      const month = (startDateObj.getMonth() + 1).toString().padStart(2, '0');
      const day = startDateObj.getDate().toString().padStart(2, '0');

      return `${year}-${month}-${day}`;
    }else{
      return null
    }
  }
  resultDate = calculateDateAfterXMonths(data.startDate, data.endDate);
  // useEffect(()=>{
  //   if(data.endDate){

  //   }

  // },[data])

  return (
    <View style={[styles.cardCointainer]}>
    <View style={{ flexDirection: "row",alignItems:"center",borderWidth: 0,paddingVertical:15,paddingRight:15,paddingLeft:13 }}>
      <Image
        style={styles.magImage}
      source={ data.data.image ? { uri:`${API_URL}`+'assets/img/magazine_images/'+`${data.data.image}`} : require("../../assets/images/mag1.jpg")}

      />
      <View style={{ alignItems: "", borderWidth: 0,left:10 }}>
        <Text
          style={{
            fontFamily: "openSans",
            fontWeight: "600",
            fontSize: isMobileView() ? responsiveFontSize(2) : responsiveFontSize(1.3),
          }}
        >{data.data?.magazine_name}
        </Text>
        <Text
          style={{
            fontFamily: "openSans",
            fontWeight: "600",
            width: "100%",
            color: "rgba(0, 0, 0, 0.5)",
            fontSize: isMobileView() ? responsiveFontSize(1.8) : responsiveFontSize(1),
          }}
        >
          {/* Copies - 1, Language - En */}
        </Text>
      </View>
      </View>
      <View
        style={{
          borderWidth: 0,
          display: "none",
          flexDirection: "row",
          justifyContent: "space-between",
          paddingHorizontal: 15,
          width: "100%",
          gap: 10,

        }}
      >
        {/* <View style={styles.insideCardLeft}>
          <Image
            style={styles.cardImage}
            source={require("../../assets/images/cal.png")}
          />
          <View >
            <Text style={styles.datesTime}>Start From</Text>
            <Text
              style={{
                fontFamily: "openSans",
                fontSize: isMobileView() ? responsiveFontSize(1.8) : responsiveFontSize(1.1),
                color: "rgba(0, 0, 0,)",
                fontWeight: "600",
              }}
            >
             {startDate}
            </Text>
          </View>
        </View> */}
        {/* <View
          style={[
            styles.insideCardRight,
            { backgroundColor: "#eaf9f1" }
          ]}
        >
          <Image
            style={styles.cardImage}
              source={require("../../assets/images/moreMonths.png")}
          />
          <View >
            <Text style={styles.datesTime}>Ends in</Text>
            <Text
              style={{
                fontSize: isMobileView() ? responsiveFontSize(1.8) : responsiveFontSize(1.12),
                color: '#2cc373 ',
                fontWeight: "600",
              }}
            >
            {formatDate(resultDate)}
            </Text>

          </View>
        </View> */}
      </View>
      <View style={[styles.locationDate,{display:"none"}]}>
        {/* <Image
          style={styles.cardImageLoc}
          source={require("../../assets/images/location.png")}
        /> */}
        <View>
          <Text
            style={{
              fontSize: isMobileView() ? responsiveFontSize(1.8) :  responsiveFontSize(1.25),
              color: "rgba(0, 0, 0)",
              fontWeight: "600",
            }}
          >

          </Text>
          <Text
            style={{
              fontSize: isMobileView() ? responsiveFontSize(1.7) :  responsiveFontSize(0.9),
              color: "rgba(0, 0, 0,0.3)",
              fontWeight: "600",
              width:'50%'
            }}
          >
            {data.location}
          </Text>
        </View>
      </View>
      {
        !data.bulkPurchase &&
        <>
        {/* <View style={{borderColor:'rgba(243, 243, 247, 1)   ',borderTopWidth:2,alignItems:'flex-start',marginHorizontal:15,marginTop:0,marginBottom:20,gap:10,paddingTop:15}}> */}
        {/* <Text style={{color:Color.BLUE_MEDIUM,fontSize:responsiveFontSize(1.6),fontWeight:'700',paddingTop:6}}>Total : ₹{amount} </Text> */}
        {/* <Text style={{color:Color.BLUE_DARK,fontSize: isMobileView() ? responsiveFontSize(1.3) : responsiveFontSize(1),fontWeight:'500',paddingTop:2}}>Price : ₹{data.amount} </Text>
        <Text style={{color:Color.BLUE_DARK,fontSize: isMobileView() ? responsiveFontSize(1.3) : responsiveFontSize(1),fontWeight:'500',paddingTop:2}}>Charges : ₹{data.charges} </Text> */}
        {/* <Text style={{color:Color.BLUE_MEDIUM,fontSize: isMobileView() ? responsiveFontSize(2.3) : responsiveFontSize(1.6),fontWeight:'700',paddingTop:6}}>Total : ₹{data.charges?data.amount+data.charges:data.amount} </Text> */}

        {/* </View> */}

        </>

        }

  </View>
  )
}

export default BuyCardComponent

const styles = StyleSheet.create({
    textBoxView: {
      width: "100%",

      margin: 2.5,
      height: "15%",
      flexDirection: "row",
      justifyContent: "space-between",
      padding: 0.5,
    },

    centeredView: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      marginTop: 20,
    },
    modalView: {
      flex: 1,
      // margin: 20,
      backgroundColor: "white",
      borderRadius: 20,

      padding: 3,
      alignItems: "center",
      shadowColor: Color.BLACK,
      shadowOffset: {
        width: 0,
        height: 2,
      },
      flexDirection: "row",
      height: "100%",
      width: "80%",
      shadowOpacity: 0.25,
      shadowRadius: 4,
      elevation: 5,
    },
    button: {
      borderRadius: 20,
      padding: 10,
      elevation: 2,
    },
    buttonOpen: {
      backgroundColor: "#F194FF",
    },
    buttonClose: {
      backgroundColor: "#2196F3",
    },
    textStyle: {
      color: "white",
      fontWeight: "bold",
      textAlign: "center",
    },
    modalText: {
      marginBottom: 15,
      textAlign: "center",
      fontWeight: "700",
      fontSize: responsiveFontSize(1.8),
    },
    textboxInput: {
      borderWidth: 2,
      borderColor: "rgba(0, 0, 0, 0.14)",
      backgroundColor: "#FFF",
      height: "45%",
      width: "85%",
      borderRadius: 5,
      paddingLeft: 10,
    },
    cardCointainer: {
      width: "75%",
      height: "60%",
      borderWidth: 2,
      borderRadius: 20,
      shadowOpacity: 0.1,
      backgroundColor: "#FFF",
      shadowOffset: { width: 1, height: 1 },
    },
    magImage: {
      height: isTabletView() ? responsiveScreenWidth(7)  :  responsiveScreenHeight(13),
      width: responsiveScreenWidth(7),
    },
    magTitle: {
      width: "100%",
      fontFamily: "openSans",
      fontWeight: "600",
      fontSize: responsiveFontSize(1.5),
    },
    cardCointainer: {
      width: "100%",
      marginTop: 14,
      height: "fit-content",
      borderWidth: 2,
      borderColor:'rgba(234, 249, 241, 1)',
      borderRadius: 10,
      shadowOpacity: 0.1,
      backgroundColor: "#FFF",
      shadowOffset: { width: 1, height: 1 },
    },
    magImage: {
      height: isMobileView() && responsiveScreenWidth(22)  || isOnlyTabletView() && responsiveScreenWidth(10)  || isOnlyLaptopView() && responsiveScreenHeight(10),
      width: isMobileView() && responsiveScreenWidth(22)  || isOnlyTabletView() && responsiveScreenWidth(7)  || isOnlyLaptopView() && responsiveScreenWidth(4),

    },
    magTitle: {
      fontFamily: "openSans",
      fontWeight: "600",
      fontSize: responsiveFontSize(1.5),
    },
    insideCardLeft: {
      backgroundColor:'#FFF',
      borderwidth: 0,
      width:"45%",
      height: isTabletView() ? 110: 130,
      borderRadius: 10,
      padding: 15,
      shadowColor: 'rgba(0, 0, 0, 0.15)',
      shadowOffset: { width: 0, height: 0 },
      left: 2,
      shadowOpacity: 1,
      shadowRadius: 6,
      elevation: 2,
      justifyContent:'space-between'
    },
    insideCardRight: {
      backgroundColor:'#FFF',
      borderwidth: 0,
      width:"45%",
      height: isTabletView() ? 110: 130,
      justifyContent:'space-between',

      borderRadius: 10,
      padding: 15,

    },
    cardImage: {
      width: isMobileView() && responsiveScreenHeight(3.5) || isOnlyTabletView() && responsiveScreenHeight(4)  || isOnlyLaptopView() &&  responsiveScreenWidth(2),
      height: isMobileView() && responsiveScreenHeight(3.5) || isOnlyTabletView() && responsiveScreenHeight(4)  || isOnlyLaptopView() && responsiveScreenHeight(4),
    },
    cardImageLoc: {
      width: isTabletView() ? responsiveScreenHeight(4.5) :  responsiveScreenWidth(3),
      height: isTabletView() ? responsiveScreenHeight(4.5) :  responsiveScreenHeight(5),
      margin: 5,
    },
    datesTime: {
      fontSize: isMobileView() ? responsiveFontSize(1.7) : responsiveFontSize(0.9),
      color: "rgba(0, 0, 0, 0.15)",
      fontWeight: "600",
    },
    locationDate: {
      flexDirection: "row",
      alignItems: "center",
      padding: 15,
      gap: 10,
      borderWidth: 0,
    },
    startEndDate: {
      fontSize: responsiveFontSize(1.25),
      color: "rgba(0, 0, 0)",
      fontWeight: "600",
    },
  });
