import { StyleSheet, Text, View,Modal } from "react-native";
import React, { useContext, useEffect, useState } from "react";
import { ScrollView,
  SafeAreaView,

} from "react-native";
import {
  responsiveFontSize,
  responsiveHeight,
  responsiveScreenHeight,
  responsiveScreenWidth,
  responsiveWidth,
} from "react-native-responsive-dimensions";

import commonStyles from "../styles/styles";
import { FlatList } from "react-native";
import { apiServices } from "../services/apiServices";
import AsyncStorage from "@react-native-async-storage/async-storage";
import ReceiptComponent from "../Common/ReceiptComponent";
import Headerdiv from "../Common/Headerdiv";
import AppLoading from "expo-app-loading";
import { useFonts } from "expo-font";
import { TouchableOpacity } from "react-native";
import { Image } from "react-native";
import Hoverable from "react-native-hoverable";
import TransactionComponent from "../Common/TransactionComponent";
import { Color, isExtraLargeLaptop, isLargeLaptop, isMobileView, isOnlyLaptopView, isOnlyLargeLaptop, isOnlyTabletView, isSmallLaptop, isTabletView } from "../Common/Constants";
import { LinearGradient } from "expo-linear-gradient";
import { Cell, Row, Table, TableWrapper } from "react-native-table-component";
import { useFocusEffect } from "@react-navigation/native";
import ErrorContext from "../context/ErrorContext";
import {formatDates,arrayReverseSort,formatedDateWithMonth} from "../util/fomatedDate";
let totalPages
let startIndex
let endIndex
let currentPageData = []
const itemsPerPage = 4;
const QuickViewPage = ({ navigation,route }) => {
  const { error, dispatchError, dispatchSuccess } = useContext(ErrorContext);
  // console.log(route.params)
  var colors = "#F1EDFA";
  var COUNT;
  var ImagePath;
var TextColor;


var roundedMonths

  const { getTransaction,getStopMagazineSubscription,takeOverMagazines,getSelectedMagazine,getSingleMagazineReceipts } = apiServices;
  const [memberCode, setMemberCode] = useState(null);
  const [transactionDataIn, setTransactionDataIn] = useState({});
  const [magDataState, setMagData] = useState({});
  const [profileData, setProfileData] = useState({});
  const [comingSoon, setComingSoon] = useState(false);
  const [currentPageData, setCurrentPageData] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const tableHead = [ 'Date','Receipt no', 'Mode',  'Reference No.','Amount', 'Receipt Status','Action']


 const itemsPerPage = 5;
 const [status, setStatusBackground] = useState({

 })
 const formatDateTime = (dateTimeStr) => {
  const dateTime = new Date(dateTimeStr);
  const date = dateTime.toLocaleDateString();
  const time = dateTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

  return `${date}\n${time}`;
};








   const updateCurrentPageData = (page, data) => {
    const startIdx = (page - 1) * itemsPerPage;
    const endIdx = startIdx + itemsPerPage;
    return data.slice(startIdx, endIdx);
  };

  // Function to handle the "next" button click
  const handleNextPage = () => {
    const nextPage = Math.min(currentPage + 1, totalPages);
    setCurrentPage(nextPage);
    setCurrentPageData(updateCurrentPageData(nextPage, transactionDataIn)); // Update currentPageData
  };

  // Function to handle the "previous" button click
  const handlePreviousPage = () => {
    const prevPage = Math.max(currentPage - 1, 1);
    setCurrentPage(prevPage);
    setCurrentPageData(updateCurrentPageData(prevPage, transactionDataIn)); // Update currentPageData
  };

  // Function to handle clicking on a specific page number
  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    setCurrentPageData(updateCurrentPageData(pageNumber, transactionDataIn)); // Update currentPageData
  };
   var pageNumbers = [];

   // Generate an array of page numbers to display in pagination
   const getPageNumbers = () => {
    const range = 2; // Number of page numbers to show before and after the current page

    // Calculate the range of page numbers to show
    let start = Math.max(currentPage - range, 1);
    let end = Math.min(currentPage + range, totalPages);

    // Adjust the range if the current page is near the start or end
    const maxRange = 2 * range + 1;
    if (end - start < maxRange) {
      if (currentPage < totalPages / 2) {
        end = Math.min(end + maxRange - (end - start), totalPages);
      } else {
        start = Math.max(start - maxRange + (end - start), 1);
      }
    }

    // Add page numbers to the array
    for (let i = start; i <= end; i++) {
      pageNumbers.push(i);
    }

    return pageNumbers;
  };
   const statusPill = (data, index) => {

    return(
      <View
          style={{
            margin: 6,
             marginLeft:15,
            alignItems: "center",
            justifyContent: "center",
            flexDirection:"row",
            backgroundColor: data === "confirmed" ? "#EAF9F1" : "#FEEEE9",
            width:"80%",padding:5,
            borderRadius:50,

          }}
        >

          <Text style={[styles.text,{color:data=== "confirmed" ? "#2CC373" : "#F24D14",bottom:0,position:"relative",margin:0}]}>{data}</Text>
        </View>
    )};
    const viewButton = (data, index) => {

    return(
      <TouchableOpacity
      onPress={()=>{window.open(data.url)}}

          style={{
        margin: 6,
         marginLeft:15,

        cursor:"pointer",
        borderRadius:50,

      }}
      >
      <Image
      source={require("../../assets/images/downloadFile.png")}
      style={{width:20,height:30}}
      resizeMode="cover"
      >

      </Image>

    </TouchableOpacity>

    )};

  const getMemberCodeX = async () => {
    let getMemberCode;
    try {
      getMemberCode = await AsyncStorage.getItem("member_code");
      setMemberCode(getMemberCode);
      // setLoader((prev) => {
      //   return prev !== 0 ? prev - 1 : 0;
      // });
    } catch (error) {
      dispatchError(error);
      // setLoader((prev) => {
      //   return prev !== 0 ? prev - 1 : 0;
      // });
    }
  };

  useEffect(() => {
    if (memberCode) {
      getTransactionReceipts();
    }

    return(()=>{
      setMemberCode(undefined)
    })
  }, [memberCode]);

  useFocusEffect(
    React.useCallback(() => {
      setMemberCode(null);
      getMemberCodeX();
    }, [])
  );
  const magData = async ()=>{
    const mag = await getSelectedMagazine(route.params.id,route.params.memberCode)
    const pData = await AsyncStorage.getItem('profileData')
    setMagData(mag)
    setProfileData(JSON.parse(pData))
  }
  const backDeletData = async ()=>{
    await AsyncStorage.removeItem('quickData')
    await AsyncStorage.removeItem('profileData')
    navigation.navigate('Dashboard');
  }

  const getTransactionReceipts = async () => {
    let getReceiptsData = await getSingleMagazineReceipts(memberCode,route.params.id,false);
    // console.log(getReceiptsData)

    arrayReverseSort(getReceiptsData,'mr_receiptdate');

    if (getReceiptsData) {
      setTransactionDataIn(getReceiptsData);
      totalPages = Math.ceil(getReceiptsData.length / itemsPerPage);
      startIndex = (currentPage - 1) * itemsPerPage;
      endIndex = Math.min(startIndex + itemsPerPage, getReceiptsData.length);
      setCurrentPageData(getReceiptsData.slice(startIndex, endIndex))

    }
  };
  COUNT = transactionDataIn.length;
  // console.log(Object.keys(COUNT).length)

  useEffect(()=>{
    magData()
  },[])
  let address_id = {
    address_id:magDataState?.sub_member_receiving,
    id:magDataState?.id
  }
  const stopSubscription = async () =>{
    await getStopMagazineSubscription(memberCode,magDataState?.id)
    backDeletData()
  }
  const takeOver = async () =>{
    await takeOverMagazines(memberCode, address_id)
    // refreshpage()
   }



  function formatDate(dateString) {
    const date = new Date(dateString);
    const month = date.toLocaleString("en-US", { month: "long" });
    const day = date.getDate();

    return `${month} ${day}`;
  }

  const inputDate = magDataState?.sub_startdate;
  const formattedDate = formatDate(inputDate);

  function calculateMonthsLeft(givenDate, targetDate) {
    const given = new Date(givenDate);
    const target = new Date(targetDate);

    const diffInMilliseconds = target - given;
    const diffInMonths = diffInMilliseconds / (1000 * 60 * 60 * 24 * 30);
    let roundedMonths = Math.round(diffInMonths);

    if (roundedMonths === 0) {
      colors = "#feeee9";
      TextColor = '#f24d14'
      return "less than a month";
    } else if (roundedMonths === 1) {
        colors = "rgba(241, 237, 250, 1)";
        TextColor = '#f24d14'
        ImagePath = require("../../assets/images/warning.png");
        return "one month";
    } else if (roundedMonths <= 1) {
        TextColor = '#f24d14'
        colors = "#feeee9";
        ImagePath = require("../../assets/images/warning.png");
        return "Ending soon";
    } else if (roundedMonths <= 6) {
        colors = "#f1edfa";
        ImagePath = require("../../assets/images/lessMonths.png");
        TextColor = '#f24d14'
        return "Ending soon";
    } else if (roundedMonths === NaN) {
        colors = "#F1EDFA";
        TextColor = '#f24d14'
        return "No Data Availabe";
    } else {
        colors = "#eaf9f1";
        ImagePath = require("../../assets/images/moreMonths.png");
        TextColor = '#2cc373'
        return `${roundedMonths} months`;
    }
  }
  const givenDate = magDataState?.sub_startdate;
  const targetDate = magDataState?.sub_enddate;
  const monthsLeft = calculateMonthsLeft(givenDate, targetDate);






  let [fontsLoaded] = useFonts({
    openSans: require("../../assets/fonts/OpenSans-VariableFont_wdth,wght.ttf"),
  });
  if (!fontsLoaded) {
    return <AppLoading />;
  }
  const renderReceipts = ({ item }) => {
    return (
      <TransactionComponent
        data={item}
        memberCode={memberCode}
        navigation={navigation}
      />
    );
  };

  return (
    <SafeAreaView style={{ width: "100%", height: "100%" }}>
    <ScrollView
      showsVerticalScrollIndicator={false}
      contentContainerStyle={{ width: "100%" }}
      style={styles.container}
    >
      {/* <View style={{width:"100%",height:50,backgroundColor:'#F8F9FA',borderWidth:1}}>
        <Text>Dhan Nirankar Ji - Virat Kohli</Text>
      </View> */}
      <Headerdiv memberCode={memberCode} navigation={navigation} />
      <View
        style={{
          borderBottomWidth:1,
          borderColor:'rgba(0, 0, 0, 0.15)',
          justifyContent:'center',
          paddingVertical: 18,
          alignItems:'center',
          width:'100%'
        }}
      >
        <View
        style={{
          borderWidth:0,
          flexDirection: isMobileView() ? "column" : 'row',
          justifyContent:'space-between',
          alignItems: !isMobileView() && 'center',
          width: isMobileView() ? "90%" : "96%"
        }}
        >

        <Text
          style={{
            fontWeight: "600", fontSize: isMobileView() && responsiveFontSize(2.6) ||   responsiveFontSize(2.2)
          }}
        >
          Quick View
        </Text>
        <View style={[commonStyles.gap1rem,commonStyles.flexRow, !isMobileView() &&  commonStyles.flexWrap,isMobileView() && commonStyles.mt_16px]}>
        <Hoverable>
{({ hovered }) => (
        <TouchableOpacity
        onPress={() =>navigation.navigate("member", { screen:'TimeLine',params:{id:route.params.id,bulkPurchase:false,allData:false}})}
          style={{
            justifyContent: "space-evenly",
            alignItems: "center",
            flexDirection: "row",
            borderRadius: 10,
            borderColor: Color.BLUE_MEDIUM,
            height: 40,
            width: 180,
            backgroundColor: hovered ? Color.BLACK  : Color.BLUE_MEDIUM,
          }}
        >
          <Image
            style={{
              height: 25,
              width: 25,
              resizeMode: "contain",
              borderWidth: 0,
            }}
            source={ require("../../assets/images/paymentIcon.png") }
          />
          <Text
            style={{
              fontFamily: "openSans",
              fontWeight: "600",
              fontSize: 15,
              color:    "white" ,
            }}
          >
             View Timeline
          </Text>
        </TouchableOpacity>
          )}
          </Hoverable>
        <Hoverable>
{({ hovered }) => (
        <TouchableOpacity
        onPress={() =>navigation.navigate("member", { screen:'OnlinePayments',params:{name:magDataState?.magazine_name}})}
          style={{
            justifyContent: "space-evenly",
            alignItems: "center",
            flexDirection: "row",
            borderRadius: 10,
            borderColor: Color.BLUE_MEDIUM,
            height: 40,
            width: 180,
            backgroundColor: hovered ? Color.BLACK  : Color.BLUE_MEDIUM,
          }}
        >
          <Image
            style={{
              height: 25,
              width: 25,
              resizeMode: "contain",
              borderWidth: 0,
            }}
            source={ require("../../assets/images/paymentIcon.png") }
          />
          <Text
            style={{
              fontFamily: "openSans",
              fontWeight: "600",
              fontSize: 15,
              color:    "white" ,
            }}
          >
             Online Payments
          </Text>
        </TouchableOpacity>
          )}
          </Hoverable>
        <Hoverable>
{({ hovered }) => (
        <TouchableOpacity
          style={{
            justifyContent: !isMobileView() && "space-evenly",
            gap: isMobileView() &&  10,
            paddingLeft: isMobileView() &&  10,
            alignItems: "center",
            flexDirection: "row",
            borderWidth: 2,
            borderRadius: 10,
            borderColor: Color.BLUE_MEDIUM,
            height: 40,
            width: isMobileView() ? 150 : 120,
            backgroundColor: hovered ? Color.BLUE_MEDIUM : "white",
          }}
          onPress={() => {
            backDeletData()
          }}
        >
          <Image
            style={{       height: 25,
              width: 25,
              resizeMode: "contain",
              borderWidth: 0,
              transform: [{ rotate: "180deg" }]
            }}
            source={ hovered ? require("../../assets/images/goBackWhite.png") : require("../../assets/images/goBack.png")}
          />
          <Text
            style={{
              fontFamily: "openSans",
              fontWeight: "600",
              fontSize: 15,
              color:  hovered ?  "white" : Color.BLUE_MEDIUM,
            }}
          >
            Go Back
          </Text>
        </TouchableOpacity>
             )}
             </Hoverable>
        </View>

        </View>

      </View>
      <View style={{ flex: 1 ,width:"100%",justifyContent:"center",alignItems:"center" }}>
        {/* Modal Side */}
        <View style={{width: isMobileView() ? "90%" : "96%",flexDirection: isTabletView() ? "column" : "row",gap:15}}>

        <View style={{ flex: isLargeLaptop() && 0.9 || isSmallLaptop() && 1.2 }}>

            <View style={styles.modalView}>
              {/* sideB */}
              <View
            style={{
              borderWidth: 0,
              // paddingHorizontal: 30,
              paddingVertical: 15,
              flexDirection: "row",
              alignItems: "center",
              height:50,
              justifyContent: "space-between",
            }}
          >
            <Text
              style={{
                fontWeight: "400",
                fontSize: !isMobileView() ?    responsiveFontSize(1.4) :  responsiveFontSize(2.4),
              }}
            >
              Magazine
            </Text>

          </View>


                <View
                  style={{
                    flex: 1,
                    borderRadius: 20,

                  }}
                >
                          <LinearGradient
            colors={['rgb(92, 66, 218)', 'rgb(255, 0, 121)']}
          style={styles.gradient}
        >

                  <View
                  style={{
                    flex: 1,
                    backgroundColor: '#FFF',
                    borderRadius: 10,
                    padding: 15,
                    justifyContent: 'space-between',
                  }}
                  >

                  <View
                    style={{
                      borderWidth: 0,
                      borderColor: "red",
                      flexDirection: "row",
                      // paddingVertical: 15,
                      // paddingRight: 15,
                      // paddingLeft: 2,
                      alignItems:  "flex-start",
                    }}
                  >
                    <View style={{ borderWidth: 0 }}>
                      <Image
                        style={{
                          height: isMobileView() && responsiveScreenWidth(24) || !isMobileView() && isTabletView() && responsiveScreenHeight(10)  || !isMobileView() && !isTabletView() &&  80,
                          width:isMobileView() && responsiveScreenWidth(20) || !isMobileView() && isTabletView() && responsiveScreenWidth(8)   || !isMobileView() && !isTabletView() &&  62,


                          left:-8, top: isMobileView() && -3, }}
                        source={
                          magDataState?.magazine_name && magDataState?.magazine_name.includes("Sant Nirankari") ?
      require("../../assets/images/mag1.png") :
      magDataState?.magazine_name && magDataState?.magazine_name.includes("Hansti Dunia") ?
require("../../assets/images/mag2.png") :
                          magDataState?.magazine_name && magDataState?.magazine_name.includes("Ek Najar") ?
      require("../../assets/images/mag3.png") :

                      // Add more conditions as needed
                        require("../../assets/images/mag1.png")}
                        resizeMode="contain"
                      />
                    </View>

                    <View style={{ width: isMobileView() && "69%", borderWidth: 0, marginBottom: 5,top: -6 }}>
                      <Text style={{ fontSize: isMobileView() ? responsiveFontSize(2) : 26, fontWeight: "600",bottom:5 }}>
                        {magDataState?.magazine_name}
                        {/* {'Hasti Duniya'} */}
                      </Text>
                      <Text
                        style={{
                          fontSize: isMobileView() ? responsiveFontSize(1.8) : 18,
                          fontWeight: "500",
                          opacity: 0.5,
                        }}
                      >
                       {magDataState?.combo_id}
                        {/* Copies - 1, Language - Hindi, Plan - One Year */}
                      </Text>
                    </View>
                  </View>

                  <View
                    style={{
                      width: "100%",
                      borderWidth: 0,
                      // padding: 20,
                      // paddingTop: 5,
                      // margin:10,
                      alignSelf:'center',
                      flexDirection: "row",
                      justifyContent: !isMobileView() && "space-between",
                      gap: isMobileView() && 25 || isOnlyTabletView() && 16 || isOnlyLaptopView() &&  25,
                      overflowX : isMobileView() && "scroll",
                      paddingVertical:  responsiveScreenHeight(1),
                      paddingLeft: isMobileView() && 5
                    }}
                  >
                    <View
                      style={{
                        backgroundColor: Color.WHITE,
                        shadowColor: "rgba(0, 0, 0, 0.15)",
                        shadowOffset: {
                          width: 0,
                          height: 0,
                        },
                        shadowRadius: 5.22631549835205,
                        shadowOpacity: 1.4032894372940063,
                        borderRadius: 8,
                        padding: 12,
                        width: isMobileView() && "50%" || isOnlyTabletView() && "22%" || isOnlyLaptopView() && responsiveWidth(9.2)  ,
                        flex: isOnlyTabletView() && 1 || isOnlyLaptopView() && 1 ,
                        height: isMobileView() && responsiveScreenHeight(16) || isOnlyTabletView() && responsiveScreenHeight(15) ||  isOnlyLaptopView() &&   115,
                        justifyContent: "space-between",
                      }}
                    >
                      <View style={{ borderWidth: 0 }}>
                        <Image
                          style={styles.cardImage}
                          source={require("../../assets/images/cal.png")}

                        />
                      </View>
                      <View>
                        <Text
                          style={{
                            fontSize: isMobileView() ? responsiveFontSize(1.9) : 14,
                            fontWeight: "600",
                            opacity: 0.4,
                          }}
                        >
                          Start From
                        </Text>
                        <Text style={{ fontSize:  isMobileView() ? responsiveFontSize(1.9) : 16, fontWeight: "600" }}>
                        {formattedDate}
                        </Text>
                      </View>
                    </View>

                    {/* <View
                      style={{
                        backgroundColor: Color.WHITE,
                        shadowColor: "rgba(0, 0, 0, 0.15)",
                        shadowOffset: {
                          width: 0,
                          height: 0,
                        },
                        shadowRadius: 5.22631549835205,
                        shadowOpacity: 1.4032894372940063,
                        borderRadius: 8,
                        padding: 12,
                        width: isMobileView() && "50%" || isOnlyTabletView() && "22%" || isOnlyLaptopView() && responsiveWidth(9.2)  ,
                        height: isMobileView() && responsiveScreenHeight(13) || isOnlyTabletView() && responsiveScreenHeight(15) ||  isOnlyLaptopView() &&   115,
                        justifyContent: "space-between",
                      }}
                    >
                      <View style={{ borderWidth: 0 }}>
                        <Image
                          style={styles.cardImage}
                          source={require("../../assets/images/priceTag.png")}
                        />
                      </View>
                      <View>
                        <Text
                          style={{
                            fontSize: isMobileView() ? responsiveFontSize(1.9) : 14,
                            fontWeight: "600",
                            opacity: 0.4,
                          }}
                        >
                          Price
                        </Text>
                        <Text style={{ fontSize:  isMobileView() ? responsiveFontSize(1.9) : 16, fontWeight: "600" }}>
                          ₹700.00
                        </Text>
                      </View>
                    </View> */}

                    <View
                      style={{
                        backgroundColor: Color.WHITE,
                        shadowColor: "rgba(0, 0, 0, 0.15)",
                        shadowOffset: {
                          width: 0,
                          height: 0,
                        },
                        shadowRadius: 5.22631549835205,
                        shadowOpacity: 1.4032894372940063,
                        borderRadius: 8,
                        padding: 12,
                        flex: isOnlyTabletView() && 1 || isOnlyLaptopView() && 1 ,
                        width: isMobileView() && "50%" || isOnlyTabletView() && "22%" || isOnlyLaptopView() && responsiveWidth(9.2)  ,
                        height: isMobileView() && responsiveScreenHeight(16) || isOnlyTabletView() && responsiveScreenHeight(15) ||  isOnlyLaptopView() &&   115,
                        justifyContent: "space-between",
                      }}
                    >
                      <View style={{ borderWidth: 0 }}>
                        <Image
                          style={styles.cardImage}
                          source={require("../../assets/images/typeIcon.png")}
                        />
                      </View>
                      <View>
                        <Text
                          style={{
                            fontSize: 16,
                            fontWeight: "600",
                            fontSize: isMobileView() ? responsiveFontSize(1.9) : 14,
                            opacity: 0.4,
                          }}
                        >
                          Type
                        </Text>
                        <Text style={{ fontSize:  isMobileView() ? responsiveFontSize(1.9) : 16, fontWeight: "600" }}>
                          New
                        </Text>
                      </View>
                    </View>

                    <View
                      style={{
                        backgroundColor: `${colors}`,
                        shadowColor: "rgba(0, 0, 0, 0.15)",
                        shadowOffset: {
                          width: 0,
                          height: 0,
                        },
                        flex: isOnlyTabletView() && 1 || isOnlyLaptopView() && 1 ,
                        borderRadius: 8,
                        padding: 12,
                        width: isMobileView() && "50%" || isOnlyTabletView() && "22%" || isOnlyLaptopView() && responsiveWidth(9.2)  ,
                        height: isMobileView() && responsiveScreenHeight(16)|| isOnlyTabletView() && responsiveScreenHeight(15) ||  isOnlyLaptopView() &&   115,
                        justifyContent: "space-between",
                      }}
                    >
                      <View style={{ borderWidth: 0 }}>
                        <Image
                          style={styles.cardImage}
                          source={ImagePath}
                        />
                      </View>
                      <View>
                        <Text
                          style={{
                            fontSize: isMobileView() ? responsiveFontSize(1.9) : 14,
                            fontWeight: "600",
                            opacity: 0.4,
                          }}
                        >
                          Ends in
                        </Text>
                        <Text
                          style={[
                            { fontSize:  isMobileView() ? responsiveFontSize(1.9) : 16, fontWeight: "600" },
                            { color: `${TextColor}` },
                          ]}
                        >
                          {monthsLeft}
                        </Text>
                      </View>
                    </View>
                    {/* location */}
                  </View>
                  <View
                      style={{
                        flexDirection: "column",
                        borderWidth: 0,
                        // paddingHorizontal: 20,
                        justifyContent: "space-between",
                        gap: isMobileView() ? 10 : 20,
                      }}
                    >
                      <View
                        style={{
                          flex: 1,
                          borderWidth: 0,
                          flexDirection: "row",
                          alignItems: "center",
                          gap: 25,
                        }}
                      >
                        <Image
                          style={{ height: isTabletView() ? responsiveScreenHeight(5) : responsiveWidth(3.4),
                             width: isTabletView() ? responsiveScreenHeight(5) : responsiveWidth(3.6) }}
                          source={require("../../assets/images/location.png")}
                        />
                        <View>
                          <Text style={{ fontSize: isMobileView() ? responsiveFontSize(2)  : 20, fontWeight: "600" }}>
                            Address
                          </Text>
                          <TouchableOpacity
                  onMouseEnter={() => setIsTooltipVisible(true)}
                  onMouseLeave={() => setIsTooltipVisible(false)}
                  style={{
                    width:"100%"
                  }}
                >
                          <Text
                            style={{
                              fontSize: isMobileView() && responsiveFontSize(1.7) || isSmallLaptop() && responsiveFontSize(1) || 15,
                              fontWeight: "600",
                              opacity: 0.5,
                              textWrap: "wrap",
                              emptySpace: "wrap",
                              width: isMobileView() && 240 || isOnlyTabletView() && 600 || isSmallLaptop() && 430 || isOnlyLargeLaptop() && 430 || isExtraLargeLaptop() && 490,
                              whiteSpace:"normal"
                            }}
                                numberOfLines={3}
            ellipsizeMode='tail'
                          >
                                        {` ${magDataState?.address?.adr_line1}, ${magDataState?.address?.adr_line2}, ${magDataState?.address?.city_name} - ${magDataState?.address?.pincode},${magDataState?.address?.district_name} - ${magDataState?.address?.state_name} - ${magDataState?.address?.country_name}`}
                                        {`\nZone - ${magDataState?.address?.zone_name} ,Branch - ${magDataState?.address?.branch_name}` }
                          </Text>
                          </TouchableOpacity>
                        </View>
                      </View>
                      <View
                       style={{
                        flexDirection:  "row",
                        justifyContent: "space-between",
                        gap: isMobileView() && 20 || isSmallLaptop() && 20,
                        overflowX: isMobileView() && "scroll" || isSmallLaptop() && "scroll" || isOnlyLargeLaptop() && "auto",
                      }}
                      >

                      <View
                        style={{
                          flex: isMobileView() && 0 || isSmallLaptop() && 0 || isLargeLaptop() && 1 || isOnlyTabletView() && 1,
                          borderWidth: 0,
                          flexDirection: "row",
                          alignItems: "center",
                          gap: 20,
                        }}
                      >
                        <Image
                          style={{ height: isTabletView() ? responsiveScreenHeight(5) : responsiveWidth(3), width: isTabletView() ? responsiveScreenHeight(5) : responsiveWidth(3.2) }}
                          source={require("../../assets/images/membersIcon.png")}
                        />
                        <View style={{width:"max-content"}}>
                          <Text style={{ fontSize: isMobileView() ? responsiveFontSize(2) : 16, fontWeight: "600" }}>
                            {profileData?.member_name}
                          </Text>
                          <Text
                            style={{
                              fontSize: isMobileView() ? responsiveFontSize(1.8) : 13,
                              fontWeight: "600",
                              opacity: 0.5,
                            }}
                          >
                            #{profileData?.member_code}
                          </Text>
                        </View>
                      </View>
                      <View
                        style={{
                          flex: isMobileView() && 0 || isSmallLaptop() && 0 || isOnlyLargeLaptop() && 1.25 || isOnlyTabletView() && 1 || isExtraLargeLaptop() && 1 ,
                          borderWidth: 0,
                          flexDirection: "row",
                          alignItems: "center",
                          gap: 20,
                        }}
                      >
                        <Image
                          style={{ height: isTabletView() ? responsiveScreenHeight(5) : responsiveWidth(3), width: isTabletView() ? responsiveScreenHeight(5) : responsiveWidth(3.2) }}
                          source={require("../../assets/images/dispatchMode.png")}
                        />
                        <View style={{width:"max-content"}}>
                          <Text style={{ fontSize: isMobileView() ? responsiveFontSize(2) : 16, fontWeight: "600" }}>
                          Dispatch Mode
                          </Text>
                          <Text
                            style={{
                              fontSize: isMobileView() ? responsiveFontSize(1.8) : 13,
                              fontWeight: "600",
                              opacity: 0.5,
                            }}
                          >
                            {magDataState?.dispatch_mode_name}
                          </Text>
                        </View>
                      </View>
                      <View
                        style={{
                          flex: isMobileView() && 0 || isSmallLaptop() && 0 || isLargeLaptop() && 1 || isOnlyTabletView() && 1,
                          borderWidth: 0,
                          flexDirection: "row",
                          alignItems: "center",
                          gap: 20,
                        }}
                      >
                        <Image
                         style={{ height: isTabletView() ? responsiveScreenHeight(5) : responsiveWidth(3), width: isTabletView() ? responsiveScreenHeight(5) : responsiveWidth(3.2) }}
                          source={require("../../assets/images/dispatchType.png")}
                        />
                   <View style={{width:"max-content"}}>
                          <Text style={{ fontSize: isMobileView() ? responsiveFontSize(2) : 16, fontWeight: "600" }}>
                          Dispatch Type
                          </Text>
                          <Text
                            style={{
                              fontSize: isMobileView() ? responsiveFontSize(1.8) : 13,
                              fontWeight: "600",
                              opacity: 0.5,
                            }}
                          >
                            {magDataState?.dispatch_type_name}
                          </Text>
                        </View>
                      </View>
                      </View>

                    </View>
                  <View style={{}}>

                    <View
                      style={{
                        borderWidth: 0,
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: magDataState?.sub_member_receiving === null ? "center" : "space-evenly",
                        borderTopWidth: 1,
                        borderColor: "rgba(0, 0, 0, 0.15)",
                        paddingTop: isTabletView() ? 15 : 20,
                        marginTop: isTabletView() ? 10 : 15,
                        // paddingHorizontal: 90,
                        // paddingVertical: 20,
                        // top: 10,
                      }}
                    >
                    {magDataState.sub_member_receiving!=magDataState.sub_member &&
                     magDataState.sub_member_receiving && magDataState.sub_member&&
                      <Hoverable>
                        {({ hovered }) => (
                          <TouchableOpacity
                            // onPress={() => {
                            //   takeOver();

                            // }}
          onPress={() =>setComingSoon(true)}

                            style={[
                              {
                                width: isMobileView() && responsiveScreenWidth(22) || isSmallLaptop() && responsiveScreenWidth(12) || 140,
                                height: 36,
                                borderRadius: 8,
                                justifyContent: "center",
                                alignItems: "center",
                              },
                              { backgroundColor: hovered ? Color.BLUE_MEDIUM : Color.BLUE_LIGHT },
                            ]}
                          >
                            <Text
                              style={[
                                { fontWeight: "600" },
                                { color: hovered ? Color.WHITE : Color.BLUE_MEDIUM },
                              ]}
                            >
                              Take Over
                            </Text>
                          </TouchableOpacity>
                        )}
                      </Hoverable> }
{/* 
                      <Hoverable>
                        {({ hovered }) => (
                          <TouchableOpacity
                            onPress={() =>setComingSoon(true)
                            }
                            style={[
                              {
                                width: isMobileView() && responsiveScreenWidth(22) || isSmallLaptop() && responsiveScreenWidth(12) || 140,
                                height: 36,
                                borderRadius: 8,
                                justifyContent: "center",
                                alignItems: "center",
                                borderWidth:2,
                                borderColor:Color.BLUE_MEDIUM
                              },
                              { backgroundColor: hovered ? Color.BLUE_MEDIUM : "#fff" },
                            ]}
                          >
                            <Text style={{ color: hovered ? "#fff" : Color.BLUE_MEDIUM, fontWeight: "600" }}>
                              Renew
                            </Text>
                          </TouchableOpacity>
                        )}
                      </Hoverable> */}
                      {/* {[1] === null || "" ? ( */}
                      {/* {magDataState?.sub_member_receiving === null || "" ? (
                        <View></View>
                      ) : (
                        <Hoverable>
                          {({ hovered }) => (
                            <TouchableOpacity
                            onPress={() =>setComingSoon(true)}


                              style={[
                                {
                                  width: isMobileView() && responsiveScreenWidth(22) || isSmallLaptop() && responsiveScreenWidth(12) || 140,
                                  height: 36,
                                  borderRadius: 8,
                                  justifyContent: "center",
                                  alignItems: "center",
                                },
                                { backgroundColor: hovered ? Color.BLACK : Color.BLUE_MEDIUM },
                              ]}
                            >
                              <Text
                                style={{ color: Color.WHITE, fontWeight: "600" }}
                              >
                                Update
                              </Text>
                            </TouchableOpacity>
                          )}
                        </Hoverable>
                      )} */}
                    </View>
                  </View>

                  </View>

        </LinearGradient>

                </View>


            </View>
        </View>

        <ScrollView
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "#FFF",
            borderWidth: 0,
            flex: !isMobileView() && 1.1,
          }}
        >
          <View
            style={{
              borderWidth: 0,
              paddingVertical: 15,
              height:50,
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Text
              style={{
                fontWeight: "400",
                fontSize: !isMobileView() ?    responsiveFontSize(1.4) :  responsiveFontSize(2.4),
              }}
            >
              Receipts
            </Text>
            <View
              style={{
                backgroundColor: "rgba(230, 232, 234, 1)",
                paddingVertical: 8,
                paddingHorizontal: 20,
                borderRadius: 10,
              }}
            >
              <Text
                style={{
                  fontWeight:"400",
                  fontSize: isMobileView() && responsiveFontSize(1.8),
                }}
              >{COUNT}</Text>
            </View>
          </View>

         <View style={styles.container}>
          <ScrollView horizontal={isMobileView() && true || isOnlyLargeLaptop()  && true  || isSmallLaptop() && true    }>

          <Table style={{width: isMobileView() && "158%" || isOnlyLargeLaptop()  && "160%"  || isSmallLaptop() &&  "160%"  }} borderStyle={{ borderWidth: 0, borderColor: '#c8e1ff' }}>
        <Row data={tableHead} style={styles.head} flexArr={[1,1,1,1.5,1,1,1]} textStyle={styles.textHead} />
        {currentPageData.map((rowData, index) => (
      <TableWrapper key={index} style={styles.tableRow}>

        <Cell
          data={formatDates(rowData?.mr_receiptdate)}
          textStyle={styles.text}
          style={{ flex:   1, justifyContent: 'center', }}
        />
        <Cell data={rowData?.mr_receiptno} textStyle={styles.text} style={{ flex: 1, }} />
        <Cell data={rowData?.mr_paymentmode} textStyle={styles.text} style={{ flex: 1 }} />
        <Cell data={rowData?.mr_bankname} textStyle={styles.text} style={{ flex: 1.5 }} />
        <Cell data={'₹'+rowData?.mr_amount} textStyle={styles.text} style={{ flex: 1 }} />
        <Cell
          data={statusPill(rowData?.mr_status, index)}
          textStyle={styles.text}
          style={{ flex: 1, justifyContent: 'center', alignItems: 'flex-start' }}
        />
        <Cell
          key={index}
          data={viewButton(rowData, index)}
          textStyle={styles.text}
          style={{ flex: 1, justifyContent: 'center', alignItems: 'flex-start' }}
        />
      </TableWrapper>
      //  <FilteredRow key={index} rowData={rowData} index={index} />
    ))}
          {/* </TableWrapper> */}
        {/* ))} */}
      </Table>
      </ScrollView>


      </View>
      <View style={{ flexDirection: 'row', justifyContent: 'flex-end', marginTop: 10, alignItems:'center',borderWidth:0}}>

  <TouchableOpacity style={{borderRadius:7.13,cursor:pageNumbers<1 && 'pointer' ,borderColor:'rgba(0, 0, 0, 0.15)',borderWidth:2,height:30,width:30,justifyContent:'center',alignItems:'center'}}  onPress={handlePreviousPage}>
    <Image style={{ tintColor: currentPage > 1 ? Color.BLUE_MEDIUM:'',height:20,width:20,}} source={require('../../assets/images/nextPageIcon.png')}></Image>
  </TouchableOpacity>
  {/* Page numbers */}
  {getPageNumbers().map((pageNumber) => (
    <TouchableOpacity key={pageNumber} onPress={() => handlePageClick(pageNumber)}>
      <Text
        style={{
          fontSize: 15,
          marginHorizontal: 10,
          fontWeight: pageNumber === currentPage ? 'bold' : 'normal',
          cursor: 'pointer',
        }}
      >
        {pageNumber}
      </Text>
    </TouchableOpacity>
  ))}

  <TouchableOpacity style={{borderRadius:7.13,cursor:currentPage !== totalPages &&'pointer', borderColor:'rgba(0, 0, 0, 0.15)',borderWidth:2,height:30,width:30,justifyContent:'center',alignItems:'center'}} onPress={handleNextPage}>
    <Image style={{ tintColor: currentPage === totalPages ?  '' : Color.BLUE_MEDIUM,height:20,width:20,transform:[{rotate: '180deg'}]}} source={require('../../assets/images/nextPageIcon.png')}></Image>
  </TouchableOpacity>
</View>
        </ScrollView>
        </View>


      </View>
      <Modal
        animationType="fade"
        transparent={true}
        visible={comingSoon}
        onRequestClose={() => {
          Alert.alert("Modal has been closed.");
          setComingSoon(!comingSoon);
        }}
      >
            <View style={styles.centeredView}>
    <View style={styles.modalView}>


      {/* sideB */}
      <View
        style={{
          flex: 1,
          borderWidth: 0,
          height: "100%",
          width: "100%",
          backgroundColor: "#ffffff",
          borderRadius:12
        }}
      >
        <View style={{flexDirection:"row",justifyContent:"space-between",padding:20,alignItems:"center" }}>


        <View>
          <Text
            style={{
              fontWeight: "600",
              fontSize: responsiveFontSize(1.5),
            }}
          >
              Coming soon
          </Text>
        </View>
        <View style={{ alignItems: "flex-end" }}>
          <TouchableOpacity
            onPress={() => setComingSoon(!comingSoon)}
          >
            <Image
              style={{ width: 30, height: 30 }}
              source={require("../../assets/images/cross.png")}
            />
          </TouchableOpacity>
        </View>
        </View>
        {/* <View style={{  borderRadius: 16.839,
      borderWidth: 3,
      borderColor: '#EAF9F1',
      backgroundColor: '#FFF',width:'85%',height:'77%',alignSelf:'center'}}>


        </View> */}
         <View style={{alignSelf:'center',justifyContent:'center',alignItems:'center',borderWidth:0,width:'70%',height:'70%'}}>
          <Text
            style={{
              fontWeight: "600",
              fontSize: responsiveFontSize(1.5),
            }}
          >
              This feature will be availabe soon
          </Text>
        </View>


      </View>
    </View>
  </View>
      </Modal>
      </ScrollView>
        </SafeAreaView>
  );
};

export default QuickViewPage;

const styles = StyleSheet.create({
  cardImage: {
    width: isMobileView() && responsiveScreenWidth(8) || isTabletView() && !isMobileView() &&  responsiveScreenHeight(4)  || !isMobileView() && !isTabletView() && responsiveScreenWidth(2.2),
    height: isMobileView() && responsiveScreenWidth(8) || isTabletView() && !isMobileView() && responsiveScreenHeight(4)  || !isMobileView() && !isTabletView() &&  responsiveScreenHeight(4),
  },
  centeredView: {
    // flex: 1,
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    alignSelf: "center",
    backdropFilter: "blur(20px)",
    // backgroundColor: "rgba(0, 0, 0, 0.3)",
  },
  modalView: {
    flex: 1,
    borderWidth:0,

    shadowColor: Color.BLACK,
    marginBottom: !isMobileView() &&  45,
    flexDirection: "column",
    height: "70%",
    width: "100%",
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
  buttonOpen: {
    backgroundColor: "#F194FF",
  },
  buttonClose: {
    backgroundColor: "#2196F3",
  },
  textStyle: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
  },
  modalText: {
    marginBottom: 15,
    textAlign: "center",
    fontWeight: "700",
    fontSize: responsiveFontSize(1.8),
  },
  textboxInput: {
    borderWidth: 2,
    borderColor: "rgba(0, 0, 0, 0.14)",
    backgroundColor: "#FFF",
    height: "30%",
    width: "85%",
    borderRadius: 5,
    paddingLeft: 10,
  },

  magImage: {
    height: responsiveScreenHeight(13),
    width: responsiveScreenWidth(7),
  },
  magTitle: {
    width: "100%",
    fontFamily: "openSans",
    fontWeight: "600",
    fontSize: responsiveFontSize(1.4),
  },
  cardCointainer: {
    width: responsiveScreenWidth(22),
    height: responsiveScreenHeight(50),
    borderWidth: 2,
    borderColor: "rgba(234, 249, 241, 1)",
    borderRadius: 10,
    shadowOpacity: 0.1,
    margin: 20,
    backgroundColor: "#FFF",
    shadowOffset: { width: 1, height: 1 },
  },
  magImage: {
    height: responsiveScreenHeight(10),
    width: responsiveScreenWidth(6),
  },
  magTitle: {
    fontFamily: "openSans",
    fontWeight: "600",
    fontSize: responsiveFontSize(1.3),
    maxWidth: 150,
  },
  insideCardLeft: {
    backgroundColor: "#FFF",
    top: 15,
    borderwidth: 0,
    justifyContent: "space-between",
    width: responsiveScreenWidth(9),
    height: responsiveScreenHeight(16),
    borderRadius: 10,
    padding: 15,
    shadowColor: "rgba(0, 0, 0, 0.15)",
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 1,
    shadowRadius: 6,
    elevation: 2,
  },
  insideCardRight: {
    backgroundColor: "#FFF",
    top: 15,
    borderwidth: 0,
    justifyContent: "space-between",
    width: responsiveScreenWidth(9),
    height: responsiveScreenHeight(16),
    borderRadius: 10,
    padding: 15,
  },

  cardImageLoc: {
    width: responsiveScreenWidth(3),
    height: responsiveScreenHeight(5),
    margin: 5,
  },
  datesTime: {
    fontSize: responsiveFontSize(0.9),
    color: "rgba(0, 0, 0, 0.15)",
    fontWeight: "600",
  },
  locationDate: {
    flexDirection: "row",
    alignItems: "center",
    padding: 10,
    borderWidth: 0,
    gap: 10,
  },
  startEndDate: {
    fontSize: responsiveFontSize(1.25),
    color: "rgba(0, 0, 0)",
    fontWeight: "600",
    centeredView: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "100%",
      alignSelf: "center",
      backdropFilter: "blur(20px)",
      backgroundColor: "rgba(0, 0, 0, 0.3)",
    },
    modalView: {
      flex: 1,
      backgroundColor: "white",
      borderRadius: 20,

      alignItems: "center",
      shadowColor: Color.BLACK,
      marginTop: 35,
      marginBottom: 35,

      flexDirection: "row",
      height: "80%",
      width: "80%",
    },
    button: {
      borderRadius: 20,
      padding: 10,
      elevation: 2,
    },
    buttonOpen: {
      backgroundColor: "#F194FF",
    },
    buttonClose: {
      backgroundColor: "#2196F3",
    },
    textStyle: {
      color: "white",
      fontWeight: "bold",
      textAlign: "center",
    },
    modalText: {
      fontFamily: "openSans",
      fontWeight: "600",
      fontSize: responsiveFontSize(1.5),
    },
    textboxInput: {
      width: "80%",
      height: "50%",
      marginTop: 10,
      backgroundColor: Color.WHITE,
      alignItems: "center",
      justifyContent: "center",
      borderRadius: 10,
      borderWidth: 1,
      borderColor: "rgba(0, 0, 0, 0.14)",
      color: "rgba(0, 0, 0, 0.30)",
      fontSize: responsiveFontSize(1.1),
      textAlign: "left",
      padding: 10,

      fontWeight: "600",
    },
    cardCointainer: {
      width: "75%",
      height: "60%",
      borderWidth: 2,
      borderRadius: 20,
      shadowOpacity: 0.1,
      margin: 30,
      backgroundColor: "#FFF",
      shadowOffset: { width: 1, height: 1 },
    },
    magImage: {
      height: responsiveScreenHeight(13),
      width: responsiveScreenWidth(7),
    },
    magTitle: {
      width: "100%",
      fontFamily: "openSans",
      fontWeight: "600",
      fontSize: responsiveFontSize(1.4),
    },
    cardCointainer: {
      width: responsiveScreenWidth(20),
      height: responsiveScreenHeight(50),
      borderWidth: 2,
      borderColor: "rgba(234, 249, 241, 1)",
      borderRadius: 20,
      shadowOpacity: 0.1,
      margin: 30,
      backgroundColor: "#FFF",
      shadowOffset: { width: 1, height: 1 },
    },
    magImage: {
      height: responsiveScreenHeight(13),
      width: responsiveScreenWidth(7),
    },
    magTitle: {
      fontFamily: "openSans",
      fontWeight: "600",
      fontSize: responsiveFontSize(1.4),
    },
    insideCardLeft: {
      backgroundColor: "#FFF",
      top: 15,
      borderwidth: 0,
      width: responsiveScreenWidth(9),
      height: responsiveScreenHeight(16),
      borderRadius: 15,
      padding: 15,
      shadowOpacity: 0.1,
      shadowOffset: { width: 1, height: 1 },
    },
    insideCardRight: {
      backgroundColor: "#FFF",
      top: 15,
      borderwidth: 0,
      width: responsiveScreenWidth(9),
      height: responsiveScreenHeight(16),
      borderRadius: 15,
      padding: 15,
      shadowOpacity: 0.1,
      shadowOffset: { width: 1, height: 1 },
    },

    cardImageLoc: {
      width: responsiveScreenWidth(3),
      height: responsiveScreenHeight(5),
      margin: 5,
    },
    datesTime: {
      fontSize: responsiveFontSize(0.9),
      color: "rgba(0, 0, 0, 0.15)",
      fontWeight: "600",
    },
    locationDate: {
      flexDirection: "row",
      alignItems: "center",
      padding: 10,
      borderWidth: 0,
    },
    startEndDate: {
      fontSize: responsiveFontSize(1.25),
      color: "rgba(0, 0, 0)",
      fontWeight: "600",
    },
  },
  body: {},
  gradient: {
    borderRadius: 15,
    padding:8,
    height: isTabletView() ? "100%" : responsiveHeight(68),
    borderColor: 'transparent',
  },
  container: { flex: !isMobileView() &&  1,  backgroundColor: '#fff',paddingBottom : isMobileView() && "10%" },
  head: { height: !isMobileView() && responsiveHeight(8), backgroundColor: '#fff' },
  text: { margin: 6, marginLeft:15,  fontWeight:600,fontSize: isMobileView() ? responsiveFontSize(1.9)  : responsiveFontSize(1.1) },
  textHead: { margin: 6, marginLeft:15, fontWeight:500,fontSize: isMobileView() ? responsiveFontSize(2) : responsiveFontSize(1.1),color:"rgba(0, 0, 0, 0.60)"},
  tableRow:{
    borderWidth:1,
    borderColor:"rgba(0, 0, 0, 0.15)",
    marginVertical:8,
    borderRadius:6,
    height:responsiveHeight(8.5),
    flexDirection:"row"
  },

});
