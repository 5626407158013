import { StyleSheet, Text, View } from "react-native";
import React, {  useContext, useEffect, useState } from "react";
import { ScrollView } from "react-native";

import {
  responsiveFontSize,
  responsiveHeight,
  responsiveWidth,
} from "react-native-responsive-dimensions";
import commonStyles from "../styles/styles";

import AsyncStorage from "@react-native-async-storage/async-storage";
import Headerdiv from "../Common/Headerdiv";
import AppLoading from "expo-app-loading";
import { useFonts } from "expo-font";
import { TouchableOpacity } from "react-native";
import { Image } from "react-native";
import { Color, isLargeLaptop, isMobileView, isTabletView } from "../Common/Constants";
import Hoverable from "react-native-hoverable";
import ErrorContext from "../context/ErrorContext";
import PopupComponent from "../Common/PopupComponent";
import { WebView } from 'react-native-webview';
// import {Pdf} from 'react-native-pdf';
const SNMPolicy = ({ navigation }) => {
  const { error, dispatchError, dispatchSuccess } = useContext(ErrorContext);
  const [loader, setLoader] = useState(1)
  const [memberCode, setMemberCode] = useState(null);







   // Generate an array of page numbers to display in pagination



  const getMemberCodeX = async () => {
    let getMemberCode;
    try {
      getMemberCode = await AsyncStorage.getItem("member_code");
      setMemberCode(getMemberCode);
      setLoader((prev) => {
        return prev !== 0 ? prev - 1 : 0;
      });
    } catch (error) {
      dispatchError(error);
      setLoader((prev) => {
        return prev !== 0 ? prev - 1 : 0;
      });
    }
  };
  useEffect(() => {
    getMemberCodeX();
  }, []);






  let [fontsLoaded] = useFonts({
    openSans: require("../../assets/fonts/OpenSans-VariableFont_wdth,wght.ttf"),
  });
  if (!fontsLoaded) {
    return <AppLoading />;
  }


  return (
    <>
    {loader === 0 ?(<View
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: "#FFF",
        overflow: "hidden",
      }}
    >
      {/* <View style={{width:"100%",height:50,backgroundColor:'#F8F9FA',borderWidth:1}}>
        <Text>Dhan Nirankar Ji - Virat Kohli</Text>
      </View> */}
      {/* <Headerdiv memberCode={memberCode} navigation={navigation} /> */}
      {error.text2 !== '' &&<PopupComponent style={{zIndex:100}} hideFun={()=>{          dispatchError("")
          dispatchSuccess("")}} type={error.type} text1={error.text1} text2={error.text2} />}
      {/* <View
        style={{
          borderBottomWidth: 1,
          borderColor: "rgba(0, 0, 0, 0.15)",
          justifyContent: "center",
          paddingVertical: 18,
          alignItems: "center",
          width: "100%",
        }}
      >
        <View
          style={{
            borderWidth: 0,
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: isMobileView() ? "90%" : "96%",
            bottom: isMobileView() ? 3 : 0,
          }}
        >
          <Text
            style={{
              fontWeight: "600",
              fontSize: isTabletView() ? responsiveFontSize(2.6) :  responsiveFontSize(2.2),
            }}
          >
            SNM Policy
          </Text>
          <View style={[commonStyles.gap1rem, commonStyles.flexRow]}>
            <Hoverable>
              {({ hovered }) => (
                <TouchableOpacity
                  style={{
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    flexDirection: "row",
                    borderWidth: 2,
                    borderRadius: 10,
                    borderColor: Color.BLUE_MEDIUM,
                    height: 40,
                    width: 120,
                    backgroundColor: hovered ? Color.BLUE_MEDIUM : "white",
                  }}
                  onPress={() => {
                    navigation.navigate('Dashboard')
                  }}
                >
                  <Image
                    style={{
                      height: 25,
                      width: 25,
                      resizeMode: "contain",
                      borderWidth: 0,
                      transform: [{ rotate: "180deg" }],
                    }}
                    source={
                      hovered
                        ? require("../../assets/images/goBackWhite.png")
                        : require("../../assets/images/goBack.png")
                    }
                  />
                  <Text
                    style={{
                      fontFamily: "openSans",
                      fontWeight: "600",
                      fontSize: 15,
                      color: hovered ? "white" : Color.BLUE_MEDIUM,
                    }}
                  >
                    Go Back
                  </Text>
                </TouchableOpacity>
              )}
            </Hoverable>
          </View>
        </View>
      </View> */}
      <iframe src="https://www.nirankari.org/privacy-policy/" height={'100%'} width={'100%'} />
      {
        /*
        https://www.nirankari.org/privacy-policy/
        */
      }
      {/* <WebView
   source={{html: '<iframe width="100%" height="50%" src="https://www.nirankari.org/privacy-policy/" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>'}}
   style={{marginTop: 20}}
/> */}
{/* <WebView
      source={{ uri: 'https://www.nirankari.org/privacy-policy/' }}
      style={{ flex: 1 }}
    /> */}
            {/* <Pdf
            source={{ uri: 'file:///../assets/docs/SNM Privacy Policy.pdf', cache: true }}
          /> */}
    </View>):(
        <SafeAreaView
          style={{
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: Color.WHITE,
            margin: "auto",
          }}
        >
          <ActivityIndicator size="large" />
          <Text style={{ fontSize: 15, fontWeight: "700", color: "#b4b4b4" }}>
            Please Wait ...
          </Text>
        </SafeAreaView>
      )}
    </>
  );
};

export default SNMPolicy;

const styles = StyleSheet.create({
  body: {},
  subscribedNextPrev: {
    borderWidth: 0,

    marginHorizontal: !isMobileView() && !isTabletView() && 45 || !isMobileView() && isTabletView() && 20 || isMobileView() && 0,
    margin: !isMobileView() ? 20 : 16,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  head: { height: responsiveHeight(6), backgroundColor: "#fff" },
  text: {
    margin: 6,
    marginLeft: 15,
    fontWeight: 600,
    fontSize: isMobileView() ? responsiveFontSize(1.9)  : responsiveFontSize(1),
  },
  textHead: {
    margin: 6,
    marginLeft: 15,
    fontWeight: 500,
    fontSize: isMobileView() ? responsiveFontSize(2) : responsiveFontSize(1.1),
    color: "rgba(0, 0, 0, 0.60)",
  },
  tableRow: {
    borderWidth: 1,
    borderColor: "rgba(0, 0, 0, 0.15)",
    marginVertical: 8,
    borderRadius: 6,
    height: responsiveHeight(12),
    flexDirection: "row",
    justifyContent:"space-between"
  },
    container: { flex: 1,  backgroundColor: '#fff' },
});
