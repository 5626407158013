import { StyleSheet, Text, View, Image, TouchableOpacity,Modal } from "react-native";
import { Svg, Path } from 'react-native-svg';

import React, { useEffect, useState, } from "react";
import {
  responsiveHeight,
  responsiveWidth,
  responsiveFontSize,
} from "react-native-responsive-dimensions";
// import { PopoverMode } from "react-native-popover-view";
import AppLoading from "expo-app-loading";
import { useFonts } from "expo-font";
import { AuthContext } from "../context/AuthContext";
import Hoverable from "react-native-hoverable";
import { apiServices } from "../services/apiServices";
import { Color, isMobileView } from "./Constants";
import AsyncStorage from "@react-native-async-storage/async-storage";

const MemberCard = ({ member, nav,updateMemberList,patrikaFlag }) => {
  const {setMembersList} = React.useContext(AuthContext)
  const [isLinked, setIsLinked] = useState(false);
  const {memberVerification} = apiServices;
  const [unlinkSuccessful, setUnlinkSuccessful] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);


  const { updateMemberToken,setMemeberCode } = React.useContext(AuthContext);


  const isVerified = async (data)=>{
    try {
      const verificationData = await memberVerification(member?.member_code,data)
      setMembersList(verificationData)
      updateMemberList(verificationData)
      if (data=='unverified'){
        setTimeout(() => {
          setUnlinkSuccessful(false);
          setModalVisible(false);
          // Reset the text back to the original
        }, 1000);


    }

    } catch (error) {
      setModalVisible(false)


    }

  }

  const handleModelVisiablity = (visible) => {
    setModalVisible(visible);
  };
  const memberTokenSetWithCondition = async()=>{
    if(!patrikaFlag){

     await AsyncStorage.setItem('super_member_code' ,member?.member_code)
     await AsyncStorage.setItem('super_member_token',member?.token)

    }

   await updateMemberToken(member?.token)
  }




  useEffect(()=>{
    if((!member.verify_status || member.verify_status == 'unverified') ){
      setIsLinked(false);
    }else{
      setIsLinked(true)
    }
  },[member])

  return (
    <View style={[styles.cardStyle,{borderWidth: isLinked && 2, borderColor: isLinked && Color.BLUE_MEDIUM}]}>
      <View style={styles.nameImage}>
       <View style={{
         width: "90%",
         height: "100%",
         borderWidth: 0,
         flexDirection: "row",
         alignItems: "center",
         justifyContent: "flex-start",
          padding: 15,

       }}>


        <View style={[styles.TextID,{borderWidth:0,}]}>
          <Text
            style={{ fontSize: isMobileView() ? 20 : responsiveFontSize(1.3), fontWeight: "600" }}
          >
            {member?.member_name}
          </Text>
          <Text
            style={{
              fontSize: isMobileView() ? 18 : responsiveFontSize(1),
              fontWeight: "600",
              color: "rgba(0, 0, 0, 0.25)",
            }}
          >
            Member Code: {member?.member_id}
          </Text>
          <View style={{ marginTop: 13,height:30,width:"fit-content",alignItems:"center",justifyContent:"center" }}>
        <Text style={styles.date}>{member?.address?.adr_line1}</Text>
      </View>
        </View>
        </View>

      </View>


      {/* {member.verify_status=='verified' && ( */}


      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          width: "97%",
          alignSelf: "center",

          marginTop:  16,
          padding: 15,
          top: 4,
          left: -6,

          alignItems:'flex-end'
        }}
      >
        <TouchableOpacity
          onPress={
            () => {
              setTimeout(async () => {
                setMemeberCode(member?.member_code)
                await AsyncStorage.setItem('memberToken' ,member?.token)
                memberTokenSetWithCondition()
                nav.navigate("MagzinepageStack", { screen:'Home', params:{ memberCode: member?.member_code}});
              }, 1000);
            }

          }
          style={{ flexDirection: "row", alignItems: "center" }}
        >
          <Image
            style={{
              height: isMobileView() ? 25 : responsiveHeight(3),
              width:  isMobileView() ? 25 : responsiveWidth(1.9),


            }}
            resizeMode="cover"
            source={require("../../assets/images/open.png")}
          />
          <Text style={styles.proceedText}>Proceed</Text>
        </TouchableOpacity>
      </View>
        {/* //  )} */}
      {/* {(!member.verify_status || member.verify_status == 'unverified') && (
        <View style={styles.bottomLinkedSection}>
             <Text
            style={{ fontSize: responsiveFontSize(1.15), fontWeight: "600",color: "rgba(0, 0, 0, 0.40)" }}
          >
            Is this account related to you ?
          </Text>
          <View style={{display:"flex",flexDirection:"row",gap:10,marginTop:10}}>
          <TouchableOpacity style={{ borderWidth: 2,
                      borderRadius: 7,
                      backgroundColor: Color.BLUE_MEDIUM ,
                        width:responsiveWidth(4.6),
                      height:responsiveHeight(4),
                      alignItems:"center",
                      justifyContent:"center",
borderColor: Color.BLUE_MEDIUM}} onPress={()=>{
  memberTokenSetWithCondition(),

  isVerified('verified')} }>

             <Text style={{fontFamily:'openSans',color:  '#fff',fontWeight:'600',fontSize:13,textAlign:"right"}}>Yes </Text>

             </TouchableOpacity>
          <TouchableOpacity style={{ borderWidth: 2,
                      padding: 10,
                      borderRadius: 7,
                      backgroundColor: "#fff",
                      width:responsiveWidth(4.6),
                      height:responsiveHeight(4),
                      alignItems:"center",
                      justifyContent:"center",
                      // borderColor:  "#5C42DA",}} onPress={()=>{[setIsLinked(false),handleModelVisiablity(true)]} }>
                      borderColor:  Color.BLUE_MEDIUM,}} onPress={()=>{[setIsLinked(false),
                      ,handleModelVisiablity(true)]} }>
             <Text style={{fontFamily:'openSans',color:  '#5C42DA',fontWeight:'600',fontSize:13,textAlign:"right"}}>No </Text>

             </TouchableOpacity>
          </View>
        </View>

      )} */}
                  <Modal
      animationType="fade"
      transparent={true}
      visible={modalVisible}
      onRequestClose={() => {
        Alert.alert('Modal has been closed.');
        setModalVisible(false);
      }}
    >
      <View style={styles.centeredView}>
        <View style={[styles.modalView,{justifyContent: unlinkSuccessful ?  "space-evenly": "space-between" }]}>
          <Image
            style={{
              height: responsiveHeight(15),
              width: responsiveWidth(15),
              resizeMode: 'contain',
            }}
            source={unlinkSuccessful ? require('../../assets/images/unlinkPopupDone.png') : require('../../assets/images/unlinkPopupIcon.png')}
          />

          {unlinkSuccessful ? (
            <Text style={{ fontFamily: 'openSans', fontWeight: '650', fontSize: responsiveFontSize(1.5), paddingHorizontal: 9, textAlign: 'center' }}>
              Unlink Successful
            </Text>
          ) : (
            <Text style={{ fontFamily: 'openSans', fontWeight: '600', fontSize: responsiveFontSize(1.2), paddingHorizontal: 9, textAlign: 'center' }}>
              Please click the 'Unlink' button to unlink{' '}
              <Text style={{ color: '#5C42DA' }}>{member.member_name}</Text> from your mobile number
            </Text>
          )}
    { !unlinkSuccessful && (
          <View style={{ display: 'flex', flexDirection: 'row', gap: 20 }}>
            <TouchableOpacity
              style={{
                borderWidth: 2,
                borderRadius: 7,
                backgroundColor: '#fff',
                width: responsiveWidth(10),
                height: responsiveHeight(5),
                alignItems: 'center',
                justifyContent: 'center',
                borderColor: 'rgba(0, 0, 0, 0.15)',
              }}
              onPress={() => setModalVisible(false)}
            >
              <Text style={{ fontFamily: 'openSans', color: '#000', fontWeight: '600', fontSize: 15, textAlign: 'right' }}>Cancel </Text>
            </TouchableOpacity>

            <TouchableOpacity
              style={{
                borderWidth: 2,
                padding: 10,
                borderRadius: 7,
                backgroundColor: '#F24D14',
                width: responsiveWidth(10),
                height: responsiveHeight(5),
                alignItems: 'center',
                justifyContent: 'center',
                borderColor: '#F24D14',
              }}
              onPress={()=>{
                setUnlinkSuccessful(true);
                setTimeout(()=>{
                  memberTokenSetWithCondition()
                  isVerified('unverified')
                },2000)
                }}
            >
              <Text style={{ fontFamily: 'openSans', color: '#fff', fontWeight: '600', fontSize: 15, textAlign: 'right' }}>Unlink </Text>
            </TouchableOpacity>
          </View>
    )}
        </View>
      </View>
    </Modal>
                  <Modal
      animationType="fade"
      transparent={true}
      visible={unlinkSuccessful}
      onRequestClose={() => {
        Alert.alert('Modal has been closed.');
        setModalVisible(false);
      }}
    >
      <View style={styles.centeredView}>
        <View style={[styles.modalView,{justifyContent:"space-evenly" }]}>
          <Image
            style={{
              height: responsiveHeight(15),
              width: responsiveWidth(15),
              resizeMode: 'contain',
            }}
            source={require('../../assets/images/unlinkPopupDone.png')}
          />


            <Text style={{ fontFamily: 'openSans', fontWeight: '650', fontSize: responsiveFontSize(1.5), paddingHorizontal: 9, textAlign: 'center' }}>
              Unlink Successful
            </Text>
        </View>
      </View>
    </Modal>
    </View>
  );
};

export default MemberCard;

const styles = StyleSheet.create({
  cardStyle: {
    borderWidth: 0,
    width: isMobileView() ? "100%" : responsiveWidth(18),

    borderRadius: 10,
    alignItems: "flex-start",
    backgroundColor: "#FFF",
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.15,
    shadowRadius: 8,
    elevation: 1,
  },
  nameImage: {


    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",

  },

  TextID: {
    gap: 3,
  },
  proceedText: {
    padding: 7,
    fontWeight: "670",
    borderRadius: 20,
    fontFamily: "openSans",
    color: Color.BLUE_MEDIUM,
    fontSize: isMobileView() ? 18 : 16,
  },
  textIssued: {
    padding: 7,
    marginTop: 7,
    fontWeight: "600",
    borderRadius: 20,
    fontFamily: "openSans",
    color: "rgba(0, 0, 0, 0.6)",
    fontSize:  16,
    height:54,
  },
  date: {
    borderWidth: 3,
    borderColor: "rgba(0, 0, 0, 0.05)",
    paddingVertical: 5,
    paddingHorizontal: 8,
    fontWeight: "600",
    borderRadius: 20,
    fontFamily: "openSans",
    color: "#2CC373",
    minWidth: 70,
    textAlign: "center",
    fontSize: 12,
  },
  // Modal styles
  modalContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    width:'100%',
    height:'100%'
  },
  modalContent: {
    backgroundColor: "#FFF",
    padding: 25,
    borderRadius: 10,
    alignItems:'center'
  },
  modalText: {
    fontSize: 10,
    fontWeight: "600",
    marginBottom: 5,
  },
  modalButtons: {
    flexDirection: "column",
    justifyContent: "space-between",
  },
  modalButton: {
    paddingVertical: 5,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: 5,
    paddingHorizontal: 10,
    borderRadius: 5,
  },
  buttonText: {
    color: "rgba(242, 77, 20, 1)",
    fontWeight: "500",
  },
  modalButtonContainer:{
    backgroundColor: "#FFF",
    position:"absolute",
    top:"20%",
    right:0,
    borderRadius: 6,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.15,
    shadowRadius: 8,
    elevation: 1,
  },
  bottomLinkedSection:{
    backgroundColor: "#F6F7F9",
    paddingBottom: 15,
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 10,
    width: "100%",
  },
  modalView: {
    borderWidth: 0,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    width: responsiveWidth(30),
    height: responsiveHeight(50),
    margin: 5,
    backgroundColor: "#fff",
    borderRadius: 8,
    padding: 25,
    shadowColor: "none",


    shadowRadius: 4,

  },

  upperContainer: {
      backgroundColor: "#F8F9FA",
      width: "100%",
      height: responsiveHeight(10),
      justifyContent:'center',
      // alignItems:'center'
  },
  userProfileNotification:{
      borderWidth:0,
      height:'100%',
      justifyContent:'space-between',
      alignItems:'center',
      flexDirection:'row',
      padding:20
  },
  userProfileImage:{
      width:45,
      height:40,
      borderWidth:0,
      margin:20
  },
  selectMember:{
      backgroundColor: "#FFF",
      width: "100%",
      height: "12%",
      padding:35,
      justifyContent:'center',
      borderBottomWidth:2,
      borderColor:'rgba(0, 0, 0, 0.15)'
  },
  memberArea:{
      borderWidth:0,
      width:'100%',
      paddingHorizontal:20,
      alignSelf:"center",
      alignItems:'flex-start',
      height:'88%',
      justifyContent:'space-evenly',
      alignItems:'flex-start',
      flexDirection:'row'},
      centeredView: {
        flex: 1,
        backdropFilter: 'blur(20px)',
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        justifyContent: "center",
        alignItems: "center",
      },

});
