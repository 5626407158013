import { StyleSheet, Text, TouchableOpacity, View, Image } from "react-native";
import React from "react";
import Headerdiv from "./Headerdiv";
import SecondaryHeaderdiv from "./SecondaryHeaderdiv";
import {
  responsiveFontSize,
  responsiveScreenHeight,
  responsiveScreenWidth,
} from "react-native-responsive-dimensions";
import AppLoading from "expo-app-loading";
import { useFonts } from "expo-font";
import { apiServices } from "../services/apiServices";
import Hoverable from "react-native-hoverable";
var quantity = 1;
var language = "Hindi";
const Purchasecard = ({ data, refreshpage }) => {
  const { getDeleteCartItem } = apiServices;
  const deleted = async () => {
    await getDeleteCartItem(data.member_code, data.id);
    refreshpage();
  };

  return (
    <View
      style={{
        flexDirection: "row",
        width: "101%",
        height: responsiveScreenHeight(10),
        alignItems: "center",
        borderWidth: 2,
        margin: 15,
        justifyContent: "space-between",
        borderRadius: 8,
        elevation: 5,
        borderColor: "rgba(0, 0, 0, 0.15)",
        paddingRight: "4%",
      }}
    >
      <View style={{       flexDirection: "row",
          alignItems: "center",
          borderWidth: 0,
          height: "100%",
 }}>
        <Image
          style={{
            height: responsiveScreenHeight(8.5),
            width: responsiveScreenWidth(5.5),
            resizeMode: "contain",
          }}
          source={require("../../assets/images/img.png")}
        />
        <View>
          <Text
            style={{
              fontFamily: "openSans",
              fontWeight: "600",
              fontSize: responsiveFontSize(1.15),
            }}
          >
            Hansti Duniya
          </Text>
          <Text
            style={{
              fontFamily: "openSans",
              fontWeight: "600",
              color: "rgba(0, 0, 0, 0.5)",
              fontSize: responsiveFontSize(0.9),
            }}
          >
            Quantity - {data?.quantity},
          </Text>
          <Text
            style={{
              fontFamily: "openSans",
              fontWeight: "600",
              color: "rgba(0, 0, 0, 0.5)",
              fontSize: responsiveFontSize(0.9),
            }}
          >
            Language - {language}
          </Text>
        </View>
      </View>
      <View
        style={{
          flexDirection: "row",

          alignItems: "center",
          borderWidth: 0,gap:10,
          minimumWidth: 150,
          left:"-5%"
        }}
      >
        <Image
          style={{
            height: responsiveScreenHeight(4.5),
            width: responsiveScreenWidth(2.5),
            resizeMode: "contain",
          }}
          source={require("../../assets/images/membersIcon.png")}
        />
        <Text
          style={{
            fontFamily: "openSans",
            fontWeight: "600",
            fontSize: responsiveFontSize(1.15),
            borderWidth: 0,
          }}
        >
          {data.member_receiving}
        </Text>
      </View>
      <View
        style={{ flexDirection: "row", alignItems: "center", borderWidth: 0,gap:10,minimumWidth: 150,  left:"-.5%" }}
      >
        <Image
          style={{
            height: responsiveScreenHeight(4.5),
            width: responsiveScreenWidth(2.5),
            resizeMode: "contain",
          }}
          source={require("../../assets/images/type.png")}
        />
        <Text
          style={{
            fontFamily: "openSans",
            fontWeight: "600",
            fontSize: responsiveFontSize(1.15),
          }}
        >
          English
        </Text>
      </View>
      <View
        style={{ flexDirection: "row", alignItems: "center", borderWidth: 0,gap:10,minimumWidth: 150, left:"-.2%" }}
      >
        <TouchableOpacity>
          <Image
            style={{
              height: responsiveScreenHeight(4.5),
              width: responsiveScreenWidth(2.5),
              resizeMode: "contain",
            }}
            source={require("../../assets/images/tag.png")}
          />
        </TouchableOpacity>
        <Text
          style={{
            fontFamily: "openSans",
            fontWeight: "600",
            fontSize: responsiveFontSize(1.15),
          }}
        >
          {data.amount}
        </Text>
      </View>
      <View>
      <Hoverable >
{({ hovered }) => (
      <TouchableOpacity onPress={() => deleted()} style={{backgroundColor: hovered && "rgba(92, 66, 218, 0.1)",borderRadius:50}}>
        <Image
          style={{
            height: responsiveScreenHeight(4.5),
            width: responsiveScreenWidth(2.5),
            resizeMode: "contain",
          }}
          source={require("../../assets/images/remove.png")}
        />
      </TouchableOpacity>
        )}
        </Hoverable>
      </View>

    </View>
  );
};

export default Purchasecard;

const styles = StyleSheet.create({});
