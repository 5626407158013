import { StyleSheet, text2, View } from 'react-native'
import React, { useState } from 'react'
const ErrorContext = React.createContext();
export const ErrorProvider = ({children}) => {
  const [error, setError] = useState({type: '', text1: '', text2: ''});
  const isValid = err => {
    var regex = /[502]/;
    if (regex.test(err)) {
      return false;
    }
    return true;
  };
  const dispatchError = err => {
    if (typeof err === 'string') {
        if (isValid(err) ) {
            setError({type: 'error', text1: 'Error', text2: err});
          }
          return;
        }
        if(!err){
          setError({type: undefined, text1: undefined, text2: undefined});
        return
        }
    var errorMsg =
      (err && err.response && err.response.data && err.response.data.errors) ||
      (err && err.message);
    if (typeof errorMsg === 'object') {
      errorMsg = errorMsg.errors;
    }
    if (isValid(errorMsg) ) {
      setError({type: 'error', text1: 'Error', text2: errorMsg});
    }
  };
  const dispatchSuccess = msg => {
    
     setError({type: 'success', text1: 'Success', text2: msg});
  };
  return (
    <ErrorContext.Provider
      value={{
        error,
        dispatchError,
        dispatchSuccess,
      }}>
      {children}
    </ErrorContext.Provider>
  );
};

export default ErrorContext