import {
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  Image,
  FlatList,
  ScrollView,
  Modal,
} from "react-native";
import React, { useContext, useEffect, useState } from "react";
import Headerdiv from "../Common/Headerdiv";
import SecondaryHeaderdiv from "../Common/SecondaryHeaderdiv";
import {
  responsiveFontSize,
  responsiveScreenHeight,
  responsiveScreenWidth,
  responsiveWidth,
} from "react-native-responsive-dimensions";
import AppLoading from "expo-app-loading";
import { useFonts } from "expo-font";
import Purchasecard from "../Common/Purchasecard";
import { apiServices } from "../services/apiServices";
import AsyncStorage from "@react-native-async-storage/async-storage";
import ErrorContext from "../context/ErrorContext";
import PopupComponent from "../Common/PopupComponent";
import Hoverable from "react-native-hoverable";
import { SafeAreaView } from "react-native-web";
import { ActivityIndicator, Linking } from "react-native";
import { TextInput } from "react-native";
import { Color, isLargeLaptop,isTabletView, isMobileView, isOnlyTabletView, isOnlyLaptopView, isSmallLaptop } from "../Common/Constants";
import commonStyles from "../styles/styles";
import { Cell, Row, Table, TableWrapper } from "react-native-table-component";
import { responsiveHeight } from "react-native-responsive-dimensions";
import { is, tr } from "date-fns/locale";
import SelectDropdown from "react-native-select-dropdown";
import { checkUserName } from "../Common/CheckUserFunc";
import { RadioButton } from "react-native-paper";
import BuyCardComponent from "../Common/BuyCardComponent";
import BuyModal from "../Common/BuyModal";
import DataNotFound from "../Common/DataNotFound";
import { formatedDateWithMonth,getBulkDatesStartDate,getBulkDatesEndDate ,getMonth} from "../util/fomatedDate";
import { AuthContext } from "../context/AuthContext";
import DatePicker from 'react-native-modern-datepicker'
import * as moment from 'moment';
import {API_URL} from "@env"

let totalPages
let startIndex
let todaysDate

let endIndex
let currentPageData = []
const dispatchType = ['Personal Address ','Nirankari Bhawan/Branch']
const itemsPerPage = 5;
const Cartpage = ({ route, navigation }) => {
  let viewMember;
  let flag;
  const { updateCartTotal } = useContext(AuthContext);
  const [patrikaSahayak, setPatrikaSahayak] = useState();
  const { error, dispatchError, dispatchSuccess } = useContext(ErrorContext);
  const [modalVisible, setModalVisible] = useState(false);
  const [bulkPurchase, setBulkpurchase] = useState(false);
  const [magazineLimit,setMagazineLimit] =useState([1,2,3])
  const [defaultAddressText, setDefaultAddressText] = useState('');
  const [quantity,setQuantity] =useState(1)
  const [changeFlag,setChangeFlag] =useState(false)
  const [selectedDataMagazine,setSelectedDataMagazine] =useState(false)

  const [totalAmoutState, setTotalAmountState] = useState(null);
  const [chargeAmoutState, setChargeAmountState] = useState(0);
  const [endDate, setEndDate] = useState();
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [SubscriptionsData,setsubscriptionsData] =useState([])
  const [amount, setAmount] = useState();
  const [validationQuantity,setValidationQuantity] = useState(null)
  const [validationPlan,setValidationPlan] = useState(null)
  const [currencyData,setCurrencyData] = useState([])
  const [selectedCurrency, setSelectedCurrency] = useState(null)
  const [startEndDates, setStartEndDates] = useState({})
  const [bulkDatesData, setBulkDatesData] = useState({})
  const [selectedBulkDatesData,setSelectedBulkDatesUpdate] = useState({})
  const [bhawanList,setBhawanListAddress] = useState([])
  const [selectedCart,setSelectedCart] = useState(null)
  const [selectedBhawan,setSelectedBhawan] = useState(null);

  const [cartData, setCartData] = useState([]);
  const [getCartAllData, setGetCartAllData] = useState({});
  const [bulkTypeFlag,setBulkTypeFlag] = useState(false);
  const [thankYouPage, setThankYouPage] = useState(false);
  const [profileData, setProfileData] = useState({});
  const [memToken, setMemToken] = useState();
  const [redirection, setRedirection] = useState();
  const [selectedAddress,setSelectedAddress] =useState(null)
  const [selectedBranch,setSelectedBranch] = useState(null);
  const [selectedBuyMag,setSelectedBuyMag] =useState()
  const [memberAddressList, setMemberAddressList] = useState({})
  const [selectedDispatchType, setSelectedDispatchType] = useState('')
  const [dispachTypeSelection, setDispachTypeSelection] = useState('')
  const [defaultFullAddress,setDefaultFullAddress] = useState('');
  const [zoneList,setZoneList]  = useState([]);
  const [branchList,setBranchesList] =useState([])
  const [selectedZone,setSelectedZone] = useState('')
  const [branchError,setBranchError] = useState('')
  const [bhawanError,setBhawanError]= useState('')
  const [deleteCartModal,setDeleteCartModal] = useState(false)
  const [selectedCartToDelete,setSelectedCartToDelete] =useState(null);
  const [isDatePickerVisible, setDatePickerVisible] = useState(false);
  const {getCurrencyData,postSubscriptionDates,
    postAmountGet,getSubscriptionType,getAddress,
    getBranchesAPI,addToCart ,getCartData,viewProfile,
    postTransactionInitiated,getDeleteCartItem,postAddUnpainInvoicesAPI,
     updateCartAddressAPI,postccAvenueTransaction,getZoneList,getBranchList,getBulkDates,getBhawanList} = apiServices;

  const [memberCode, setMemberCode] = useState(null);
  const [transaction, setTransaction] = useState({});
  const [defaultAddressFlag, setDefaultAddressFlag] = useState(false);
  const [fullAddressData, setFullAddressData] = useState(false);
  const [loader, setLoader] = useState(3)
  const [currentPageData, setCurrentPageData] = useState([])

  const tableHead = isMobileView() ? ["Name",  "Action"] : ["Name", "Quantity", "Action"];

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const [checked, setChecked] = useState('first');
  useEffect(()=>{
    const { today, twoMonthsLater } = getTodayAndTwoMonthsLater();
    todaysDate = twoMonthsLater
    return(()=>{
      dispatchError('')
    })
  },[])

  useEffect(()=>{
    if(bulkTypeFlag){
      bulkDates()
    }
    return(()=>{
      dispatchError('')
    })
  },[bulkTypeFlag])
  const bulkDates = async()=>{
    try{
      let data = await getBulkDates(memberCode)
      setBulkDatesData(data)
      setSelectedBulkDatesUpdate(data)

    }catch(error){
      dispatchError(error)
    }
  }

  function getFormattedDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
  function getTodayAndTwoMonthsLater() {
    const today = new Date();
    const twoMonthsLater = new Date(today);
    twoMonthsLater.setMonth(today.getMonth() + 2);

    const formattedToday = getFormattedDate(today);
    const formattedTwoMonthsLater = getFormattedDate(twoMonthsLater);

    return {
      today: formattedToday,
      twoMonthsLater: formattedTwoMonthsLater,
    };
  }
  function funcUpdateValue (){
    setTimeout(()=>{setChangeFlag(!changeFlag)},[750])
  }
  const getSubscriptionTypeData = async () => {
    try {
      var subscriptionData = await getSubscriptionType(memberCode);
      setsubscriptionsData(subscriptionData);
        //setSelectedPlan(subscriptionData[0].subscription_type_id)
       // getDatesFromApi(subscriptionData[0].subscription_type_id)
      setLoader(prev => {
        return prev !== 0 ? prev - 1 : 0;
      })

    } catch (error) {
      setLoader(prev => {
        return prev !== 0 ? prev - 1 : 0;
      })
       dispatchError(error);


    }
  };
  const getAmount = async (item) => {
    try {
      let magData = {
        magazine_id:selectedBuyMag,
        subscription_type : item,
        currency : selectedCurrency
      }
      setLoader(1)
      var data = await postAmountGet(memberCode,magData);
      setAmount(data)

      setLoader(prev => {
        return prev !== 0 ? prev - 1 : 0;
      })

    } catch (error) {
      setLoader(prev => {
        return prev !== 0 ? prev - 1 : 0;
      })
      setModalVisible(!setModalVisible)
       dispatchError(error);


    }
  };
  const validationCheck = () =>{

    // if (selectedCurrency  ===null){
    //   setValidationCurrency(true)
    // }
    if (quantity  ===null){
      setValidationQuantity(true)
    }
    if(!bulkTypeFlag)
    {if (selectedPlan===undefined||selectedPlan===null){
      setValidationPlan(true)
    }}
    // if(defaultAddress===false||defaultAddress===null){
    //   setTimeout(() => {
    //     setDefaultAddressText('Please set a default address from profile page!')
    //   }, 200);
    // }
  }
  const getCurrencies = async () => {
    try {
      var data = await getCurrencyData(memberCode);
      setCurrencyData(data);
      if(data.length == 1)
      {
        setSelectedCurrency(data[0].currency_code)
      }
      setLoader(prev => {
        return prev !== 0 ? prev - 1 : 0;
      })
    } catch (error) {
      setLoader(prev => {
        return prev !== 0 ? prev - 1 : 0;
      })
       dispatchError(error);

    }
  };

  const getAddressMember = async () => {
    try {
      setDispachTypeSelection('Personal')
      setMemberAddressList([])
      var memberAddressDataGet = await getAddress(memberCode);

      const defaultAddressObj = memberAddressDataGet.find(address => address.member_default_adr === true);
      // Update the state with the default address object
      setFullAddressData(defaultAddressObj);
      for(let address of memberAddressDataGet)
      {
        if(address.member_default_adr)
        {
          setDefaultAddressFlag(address.member_default_adr)
          setSelectedAddress(address.address_id)
          setDefaultFullAddress(address)
          setSelectedBranch(address?.branch_id)
          setBranchError('')
          setSelectedZone(address.zone_id)
          break;
        }else{
          setDefaultAddressFlag(false)
        }

      }
      setMemberAddressList(memberAddressDataGet);
      setLoader(prev => {
        return prev !== 0 ? prev - 1 : 0;
      })
    } catch (error) {
      setLoader(prev => {
        return prev !== 0 ? prev - 1 : 0;
      })
       dispatchError(error);

    }
  };

  const getDatesFromApi = async (data) => {
    try {
      var data = await postSubscriptionDates(memberCode,data);

      setStartEndDates(data);


    } catch (error) {

       dispatchError(error);

    }
  };
  useEffect(()=>{
    if(startEndDates.start_date)
    {
      updateCartAddress(cartData)
    }
    return(()=>{
      dispatchError('')
    })
  },[startEndDates])
  const updateQuantity = (newQuantity) => {
    setQuantity(newQuantity);
  };
  const updateCartQuantity = async () => {
    try{
      let updateData={
        carts :[selectedCart],
        quantity :quantity,
        dispatch_to:selectedAddress,
        dispatch_type:dispachTypeSelection,
      }

        await updateCartAddressAPI(
          memberCode, updateData
        );
        setModalVisible(!modalVisible);
        refreshpage();
        // setThankYouPage(true);
        // setTimeout(() => {
        //   setThankYouPage(false);

        // }, 2000);
        }catch(error){

          // if(error.response.data.errors.includes('Want to replace them?'))
          // {
            // setErrorMessage(error.response.data.errors)
            // setCartReplacePopUp(!cartReplacePopUp);
          // }else{
          setModalVisible(!modalVisible);
        //  refreshpage();
            // setErrorMessage(error.response.data.errors)
            dispatchError(error)
          // }
        }

  };
  const closeModal = () => {
    setModalVisible(false);
    setSelectedCart(null);
  };
  const handleSearchZone = async(text)=>{

      try {
        let Data = await getZoneList(memberCode,text);
        for(let item of Data)
        {
          if(item.zone_sect_description && item.zone_sect_description)
          {
            item.zone_name +=' ('+item.zone_sect_description+')'
          }
        }
        Data.push({zone_name:'<---- Type to view more---->'})
        setZoneList(Data);
        // setBranchesList([]);
        // setSelectedBranch('')
        // setLoader((prev) => {
        //   return prev !== 0 ? prev - 1 : 0;
        // });
      } catch (error) {
        dispatchError(error);
        // setLoader((prev) => {
        //   return prev !== 0 ? prev - 1 : 0;
        // });
      }

  }
  const handleBranchSearch = async(text)=>{

      try{
        let searchData  = await getBranchList(memberCode,selectedZone,text )
        setBranchesList(searchData)
        // const transformedData = searchData.map(item => {
        //   return {
        //     id: item.branch_code,
        //     name: item.branch_name,
        //   };
        // });
        // setBranchesList(transformedData)
      }catch(error)
      {
        dispatchError(error)
      }

  }

  useEffect(()=>{
    if(selectedZone)
    {
      handleBranchSearch(undefined)
      // setSelectedBranch(null);
      //setBranchError('Branch Field is required')
      setBhawanListAddress([])
      setSelectedBhawan(null)
      setBhawanError("Bhawan field is required")
    }
  },[selectedZone])

  const postAddUnpainInvoices = async(data) =>{
    if(!bulkTypeFlag && !selectedPlan &&!startEndDates.start_date &&!startEndDates.end_date)
    {
      setValidationPlan("Please select plan first");
      return;
    }
    if(dispachTypeSelection!='Personal' && (selectedBranch==''|| selectedBranch==null))
    {
      setBranchError('Branch field is required');
      return
    }
    if(dispachTypeSelection!='Personal' && (selectedBhawan==''|| selectedBhawan==null))
    {
      setBhawanError('Bhawan field is required');
      return
    }
    const ids = data.map(item => item.id);
    let updateData = {
      "carts" : ids
    }
    try {
      const res= await postAddUnpainInvoicesAPI(memberCode,updateData)
      dispatchSuccess(res.message)
      setTimeout(() => {
        getCart();
      navigation.navigate("PaymentSuccess", {amount: res?.amount,receipt_url:res?.receipt_url,unpaidFlag:true})
      }, 1000);

    } catch (error) {
      dispatchSuccess(error)
    }

   }
  const formatDateTime = (dateTimeStr) => {
    const dateTime = new Date(dateTimeStr);
    const date = dateTime.toLocaleDateString();
    const time = dateTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

    return `${date}\n${time}`;
  };

  const checkPatrikasahayak = async() =>{

    let data=  JSON.parse(await AsyncStorage.getItem('patrika_sahayak'))

        setPatrikaSahayak(data)

    }


     const updateCurrentPageData = (page, data) => {
      const startIdx = (page - 1) * itemsPerPage;
      const endIdx = startIdx + itemsPerPage;
      return data?.slice(startIdx, endIdx);
    };

    // Function to handle the "next" button click
    const handleNextPage = () => {
      const nextPage = Math.min(currentPage + 1, totalPages);
      setCurrentPage(nextPage);
      setCurrentPageData(updateCurrentPageData(nextPage, cartData)); // Update currentPageData
    };

    // Function to handle the "previous" button click
    const handlePreviousPage = () => {
      const prevPage = Math.max(currentPage - 1, 1);
      setCurrentPage(prevPage);
      setCurrentPageData(updateCurrentPageData(prevPage, cartData)); // Update currentPageData
    };

    // Function to handle clicking on a specific page number
    const handlePageClick = (pageNumber) => {
      setCurrentPage(pageNumber);
      setCurrentPageData(updateCurrentPageData(pageNumber, cartData)); // Update currentPageData
    };
    const updateCartAddress = async(cart) =>{
      try{
      let data

      if(cart?.length>0){
        if(dispachTypeSelection!=''){
      if(dispachTypeSelection==='Personal'){
        data = {
          "carts": cart?.map((item) => item.id),
          "dispatch_to":selectedAddress,
          "dispatch_type":dispachTypeSelection,
          "member_address":fullAddressData?.address_id,
          "start_date":startEndDates.start_date,
          "end_date":startEndDates.end_date,
          "currency":selectedCurrency,
          "month":+selectedBulkDatesData.month,
          "year":+selectedBulkDatesData.year,
          "subscription_type":selectedPlan

      }

      }else{
        if(selectedBranch==''|| selectedBranch==null)
        {
          setBranchError('Branch field is required');
          return
        }
        if(selectedBhawan == '' || selectedBhawan == null)
        {
          setBhawanError('Bhawan field is required')
          return
        }
        data = {
          "carts": cart?.map((item) => item.id),
          "dispatch_to":selectedBhawan,
          "dispatch_type":dispachTypeSelection,
          "member_address":fullAddressData?.address_id,
          "start_date":startEndDates.start_date,
          "end_date":startEndDates.end_date,
          "currency":selectedCurrency,
          "month":+selectedBulkDatesData.month,
          "year":+selectedBulkDatesData.year,
          "zone":selectedZone,
          "branch":selectedBranch,
          "subscription_type":selectedPlan


      }

      }
     let response= await updateCartAddressAPI(memberCode,data,)
     setGetCartAllData(response)
      dispatchSuccess(response.message)
    }
  }
    }catch(error){
        dispatchError(error)
    }

     }

    const handleBulkCalendarChange =(selectedData)=>{
      let data = selectedData.split(' ')
      let date = { month:data[1], year: data[0]}
      setSelectedBulkDatesUpdate(date)

    }

    useEffect(()=>{
      updateCartAddress(cartData)
    },[selectedBulkDatesData])

     var pageNumbers = [];

     // Generate an array of page numbers to display in pagination
     const getPageNumbers = () => {
      const range = 2; // Number of page numbers to show before and after the current page

      // Calculate the range of page numbers to show
      let start = Math.max(currentPage - range, 1);
      let end = Math.min(currentPage + range, totalPages);

      // Adjust the range if the current page is near the start or end
      const maxRange = 2 * range + 1;
      if (end - start < maxRange) {
        if (currentPage < totalPages / 2) {
          end = Math.min(end + maxRange - (end - start), totalPages);
        } else {
          start = Math.max(start - maxRange + (end - start), 1);
        }
      }

      // Add page numbers to the array
      for (let i = start; i <= end; i++) {
        pageNumbers.push(i);
      }

      return pageNumbers;
    };

    const getBhawanAddressList = async()=>{
      try {
       let response = await getBhawanList(memberCode,selectedZone,selectedBranch)
       setBhawanListAddress(response.branch_location_list)
      } catch (error) {
        dispatchError(error)
      }
    }

    useEffect(()=>{
      if(selectedCurrency&&selectedAddress&&dispachTypeSelection){ //change this memberAddress
      updateCartAddress(cartData)
      }
      return(()=>{
        dispatchError('')
      })
    },[selectedCurrency&&selectedAddress&&dispachTypeSelection]) //change this
    // useEffect(()=>{
    //   if(selectedAddress){
    //   updateCartAddress(cartData)
    //   }
    //   return(()=>{
    //     dispatchError('')
    //   })
    // },[selectedAddress]) //change this
    useEffect(()=>{
      if(selectedCurrency){
      updateCartAddress(cartData)
      }
      return(()=>{
        dispatchError('')
      })
    },[selectedCurrency])
    useEffect(()=>{
      if(cartData && cartData.length>0){
      updateCartAddress(cartData)
      }
      return(()=>{
        dispatchError('')
      })
    },[cartData])
    useEffect(()=>{
      if(selectedBranch){
        setBhawanListAddress([])
        setSelectedBhawan(null)
        setBhawanError("Bhawan field is required")
      // updateCartAddress(cartData)
      getBhawanAddressList()
      }
      return(()=>{
        dispatchError('')
      })
    },[selectedBranch])
    useEffect(()=>{
      if(selectedBhawan){
      updateCartAddress(cartData)
      }
      return(()=>{
        dispatchError('')
      })
    },[selectedBhawan])
     //change this
    const removeFromCart = async (memCode)=>{
      await getDeleteCartItem(memCode,selectedCartToDelete)
      setLoader(1)

      setTimeout(() => {
        setLoader(prev => {
          return prev !== 0 ? prev - 1 : 0;
        })
        setDeleteCartModal(false);
        getCart();

      }, 2500);
    }
    const renderDropdownIcon = ({ isOpen }) => (
      <View
        style={{
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Image
                  style={{
                    height: 22,
                    width: 22,

                    resizeMode:'contain',

                  }}
                  source={ require( "../../assets/images/dropDown.png") }
                />
      </View>
    );




  const dateItem = (data, index) => {
    return (
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          borderWidth: 0,
          height: "100%",
          width: "100%",
          margin: 6,
          marginLeft: 15,
          gap: 15,
        }}
      >
        {/* <Image
          style={{
            height: responsiveHeight(9),
            width: isMobileView() ? responsiveWidth(20) : responsiveWidth(3),
            resizeMode: "contain",
          }}
          source={data.imgUrl}
        /> */}
        <View style={[commonStyles.flex,{alignItems:"flex-start",flex:1}]}>

        <Text
          style={{
            fontFamily: "openSans",
            fontWeight: "600",
            fontSize: isMobileView() ? responsiveFontSize(1.9)  : responsiveFontSize(1),
          flexWrap:"wrap"
          }}
        >
          {data?.magazine_name}
        </Text>
        {bulkTypeFlag && <Text
          style={{
            fontFamily: "openSans",
            fontWeight: "600",
            fontSize: isMobileView() ? responsiveFontSize(1.6)  : responsiveFontSize(0.9),
            color: "rgba(0, 0, 0, 0.5)",

          }}
        >
        Per Pcs Price -  {data?.base_price}
        </Text>}
        <Text
          style={{
            fontFamily: "openSans",
            fontWeight: "600",
            color: "rgba(0, 0, 0, 0.5)",
            fontSize: isMobileView() ? responsiveFontSize(1.6)  : responsiveFontSize(0.9),
          }}
        >
        {/* Language -  {data.language}, */}
        </Text>
        </View>

      </View>
    );
  };
  const forItem = (data, index) => {
    return (
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          borderWidth: 0,
          height: "100%",
          width: "100%",
          margin: 6,
          marginLeft: 15,
          gap: 15,
        }}
      >
        <Image
          style={{
            height: responsiveHeight(4.2),
            width: !isLargeLaptop() ? responsiveHeight(4.2) : responsiveWidth(2.2),
            resizeMode: "contain",
          }}
          source={require("../../assets/images/membersIcon.png")}
        />
        <Text
          style={{
            fontFamily: "openSans",
            fontWeight: "600",
            fontSize: isMobileView() ? responsiveFontSize(1.9)  : responsiveFontSize(1),
          }}
        >
          {data?.dispatch_type}
        </Text>
      </View>
    );
  };
  const copiesItem = (data, index) => {
    return (
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          borderWidth: 0,
          height: "100%",
          width: "100%",
          margin: 6,
          marginLeft: 15,
          gap: 15,
        }}
      >
        <Image
          style={{
            height: responsiveHeight(4.2),
            width: !isLargeLaptop() ? responsiveHeight(4.2) : responsiveWidth(2.2),
            resizeMode: "contain",
          }}
          source={require("../../assets/images/type.png")}
        />
        <Text
          style={{
            fontFamily: "openSans",
            fontWeight: "600",
            fontSize: isMobileView() ? responsiveFontSize(1.9)  : responsiveFontSize(1),
          }}
        >
          {data?.dispatch_mode}
        </Text>
      </View>
    );
  };
  const amountItem = (data, index) => {
    return (
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          borderWidth: 0,
          height: "100%",
          width: "100%",
          margin: 6,
          marginLeft: 15,
          gap: 15,
        }}
      >
        <Image
          style={{
            height: responsiveHeight(4.2),
            width: !isLargeLaptop() ? responsiveHeight(4.2) : responsiveWidth(2.2),
            resizeMode: "contain",
          }}
          source={require("../../assets/images/tag.png")}
        />
        <Text
          style={{
            fontFamily: "openSans",
            fontWeight: "600",
            fontSize: isMobileView() ? responsiveFontSize(1.9)  : responsiveFontSize(1),
          }}
        >
          {data?.quantity}
        </Text>
      </View>
    );
  };
  const actionItem = (data, index) => {
    return (
      <View style={{    flexDirection: "row",
      alignItems: "center",
      borderWidth: 0,
      // height: "100%",
      // width: "100%",
      margin: 6,
      marginLeft: 15,
      gap: 16,}}>
      <Hoverable>
        {({ hovered }) => (
          <TouchableOpacity
          onPress={() => { setSelectedCartToDelete(data?.id), setDeleteCartModal(!deleteCartModal)}}
            style={[
              {


                with: "fit-content",
              },
            ]}
            >
         <Text
              style={{
                color: Color.BLUE_MEDIUM,
                fontWeight: "600",
                 fontSize: isMobileView() ? responsiveFontSize(1.9)  : responsiveFontSize(1),
                }}
                >
              Delete
            </Text>
          </TouchableOpacity>
        )}
      </Hoverable>
      {buttonsSaprator()}
      <Hoverable>
        {({ hovered }) => (
          <TouchableOpacity
          onPress={() => {
            setSelectedDataMagazine(data)

            setBulkpurchase(data?.bulk_purchase)
            setSelectedCart(data.id);
              setModalVisible(true);
          }}
            style={[
              {

                borderRadius: 100,
                with: "fit-content",
              },
            ]}
            >
         <Text
              style={{
                color: Color.BLUE_MEDIUM,
                fontWeight: "600",
                 fontSize: isMobileView() ? responsiveFontSize(1.9)  : responsiveFontSize(1),
                }}
                >
           Edit
            </Text>
          </TouchableOpacity>
        )}
      </Hoverable>
        <BuyModal onUpdateQuantity={updateQuantity} pageName={'CartPage'} addUpdateMagazine={updateCartQuantity} toAddress={selectedAddress} mCode={memberCode} isVisible={modalVisible} closeModal={closeModal} refreshpageFunc={refreshpage} profileData={profileData} data={selectedDataMagazine} isBulk={bulkTypeFlag}/>

        </View>
    );
  };

  const buttonsSaprator = (rowData, index) => {

    return(
      <View style={styles.Linecontainer}>
      <View style={styles.line} />
    </View>

    )};
  const getMemberCodeX = async () => {
    let getMemberCode;
    try {
      getMemberCode = await AsyncStorage.getItem("member_code");
      setMemberCode(getMemberCode);
      setLoader(prev => {
        return prev !== 0 ? prev - 1 : 0;
      })
    } catch (error) {
      dispatchError(error);
      setLoader(prev => {
        return prev !== 0 ? prev - 1 : 0;
      })
    }
  };
  useEffect(() => {
    getMemberCodeX();
    checkPatrikasahayak()
    checkUserName(navigation)
    return(()=>{
      dispatchError('')
    })
  }, []);

  const getCart = async () => {
    try {
      const viewMember = await getCartData(memberCode);

      setCartData(viewMember.carts);
      setBulkpurchase(viewMember.carts?.bulk_purchase)
      updateCartAddress(viewMember.carts)
      //setGetCartAllData(viewMember);
    //  updateCartTotal(viewMember.carts?.length)
      if(viewMember.carts?.length>0)
      {
        if(viewMember.carts[0]?.bulk_purchase)
        {
          setBulkTypeFlag(true)
        }else{
          setBulkTypeFlag(false)
        }
      }
      totalPages = Math.ceil(viewMember.carts?.length / itemsPerPage);
      startIndex = (currentPage - 1) * itemsPerPage;
      endIndex = Math.min(startIndex + itemsPerPage, viewMember.carts?.length);
      setCurrentPageData(viewMember.carts?.slice(startIndex, endIndex))
      setLoader(prev => {
        return prev !== 0 ? prev - 1 : 0;
      })

    } catch (error) {
      dispatchError(error);
      setLoader(prev => {
        return prev !== 0 ? prev - 1 : 0;
      })
    }
  };
  const getMemberDetails = async () => {
    try {
      const viewMember = await viewProfile(memberCode);
      setProfileData(viewMember);
      setLoader(prev => {
        return prev !== 0 ? prev - 1 : 0;
      })
    } catch (error) {
      dispatchError(error);
      setLoader(prev => {
        return prev !== 0 ? prev - 1 : 0;
      })
    }
  };
  const refreshpage = () => {
    getCart();
  };
  // getCart()
  useEffect(() => {
    setDefaultAddressFlag(false)
    if (memberCode) {
      setTimeout(() => {
        getMemberDetails();
        getCart();
        getCurrencies();
        getAddressMember();
        getSubscriptionTypeData();

        handleSearchZone(undefined)
        // getDatesFromApi();

      }, 1000);
    }
    return(()=>{
      dispatchError('')
    })
  }, [memberCode]);

  const renderCartItems = ({ item }) => {
    return (
      <Purchasecard
        data={item}
        // index={index}
        memberCode={memberCode}
        navigation={navigation}
        refreshpage={refreshpage}
      />
    );
  };

  var totalAmountCart =cartData?.reduce((total, obj) => total + obj.amount, 0)
const amoutTrue=()=>{
  if(totalAmountCart){
    return true
  }else{
    return false
  }
}
  var TOTALMAGAZINES = cartData?.length;
  // var quantity = 1;
  var language = "Hindi";
  var postDataBody={
    firstname:`${profileData?.member_name}`,
    productinfo:"web",
    email:`${profileData?.email}`,
    amount:bulkTypeFlag ? parseInt(`${getCartAllData.magazine_amount}`):parseInt(`${getCartAllData?.total_amount}`),
    carts:cartData?.map((item)=>item.id),
  }


  const handleSubmitCart = async () => {
    let getUrlRedirection = await postTransactionInitiated(memberCode, postDataBody);
    setRedirection(getUrlRedirection)
  };
  const handlePaymentCart = async () => {
    setRedirection(undefined)
    if(!bulkTypeFlag && !selectedPlan &&!startEndDates.start_date &&!startEndDates.end_date)
    {
      setValidationPlan("Please select plan first");
      return;
    }
    if(dispachTypeSelection!='Personal' && (selectedBranch==''|| selectedBranch==null))
    {
      setBranchError('Branch field is required');
      return
    }
    if(dispachTypeSelection!='Personal' && (selectedBhawan==''|| selectedBhawan==null))
    {
      setBhawanError('Bhawan field is required');
      return
    }
    setThankYouPage(!thankYouPage)
    var postBodyForCCAvenue={
      firstname:`${profileData?.member_name}`,
      amount:parseInt(`${getCartAllData?.total_amount}`),
      carts:cartData?.map((item)=>item.id),
    }
    let querParam = 'amount='+postBodyForCCAvenue.amount;
    for(let item of postBodyForCCAvenue.carts)
    {
     querParam+='&carts[]='+item;
    }
    querParam+='&currency=inr'
    setTimeout(() => {
      window.location.replace(`${API_URL}`+'ccavenue_transaction/initiate?'+`${querParam}`)
      setThankYouPage(!thankYouPage)
    }, 3000);

    //setRedirection()
  };
  useEffect(()=>{
    if(redirection){
      setTimeout(() => {
        goToURL()
        setThankYouPage(!thankYouPage)
        getCart()
      }, 3000);
    }
    return(()=>{
      dispatchError('')
    })
  },[redirection])


  const onSelectionOfZone = (item)=>{

    if(item.zone_sect_number && item.zone_sect_number!=''){
      setSelectedZone(item.zone_code +'-'+item.zone_sect_number)
      }else{
      setSelectedZone(item.zone_code)

      }
    // setBranchError('Branch Field is Required')
    // setSelectedBranch(null);
      //setSelectedBranch('')
      setSelectedBranch(null)
      setBranchesList([])
       handleBranchSearch([])
       defaultFullAddress.branch_name=undefined
      setBranchError('Branch field is required')
  }

  const goToURL = ()=>{
    // Linking.openURL(redirection?.url)
    Linking.openURL(redirection)
  }
// Handle 2
  // const handleSubmitCart = async () => {
  //   const token = await AsyncStorage.getItem('memberToken')
  //   setMemToken(token)


  //   fetch(`https://naman-magazine-dev.odpay.in/portal/${memberCode}/payment/initiate_transaction`, {
  //     method: 'POST',

  //     redirect:'follow',
  //     headers: {
  //       'Accept':'application/json, text/plain, */*',
  //       'Authorization': `eyJhbGciOiJIUzI1NiJ9.eyJtZW1iZXJfY29kZSI6IjM3ODk1MjIiLCJtb2JpbGUiOiI3NjY3NTM5NDkzIn0.1NgONb88hkZY6Ebg4IMpWz5homjk7-cawwYoVdNV5dw`,
  //       'Content-Type': 'application/json',

  //     },
  //     body: JSON.stringify(postDataBody),
  //   })
  //   .then((response) => response.json())
  //   .then((data) => {
  //     // Handle the response from the payment gateway
  //     if (data.success) {
  //       Alert.alert('Payment Successful');
  //     } else {
  //       Alert.alert('Payment Failed');
  //     }
  //   })
  //   .catch((error) => {
  //     Alert.alert('Error', error.message);
  //   });
  // };



  let [fontsLoaded] = useFonts({
    openSans: require("../../assets/fonts/OpenSans-VariableFont_wdth,wght.ttf"),
  });
  if (!fontsLoaded) {
    return <AppLoading />;
  }
  return (
    <>
    {loader===0?(

    <ScrollView
    showsVerticalScrollIndicator={false}
    style={{ width: "100%", backgroundColor: Color.WHITE,paddingBottom:"10%"}}>
    <Headerdiv navigation={navigation} memberCode={memberCode} />

   <SecondaryHeaderdiv messageShow={"Add up to 3 magazines to your cart and save! Subscribe in combo today. 📚✨"} titleText="My Cart" />
        {
         cartData?.length>0 ? (<>
            <View
            style={[
              styles.subscribedNextPrev,
              !isMobileView() && commonStyles.pl_2,
              isMobileView() && commonStyles.width90,
              isMobileView() && commonStyles.alignSelfCenter,
            ]}
          >
            <View
              style={[
                commonStyles.flexRow,
                commonStyles.flex,
                commonStyles.justifyBetween,
                commonStyles.alignCenter,
                commonStyles.width100,
              ]}
            >
              <Text
                style={{ fontWeight: "400",fontSize: !isMobileView()
                ? responsiveFontSize(2)
                : responsiveFontSize(2.4),  }}
              >
                Magazines
              </Text>
              <View
                style={{
                  backgroundColor: "rgba(230, 232, 234, 1)",
                  paddingVertical: 8,
                  paddingHorizontal: !isMobileView() ? 20 : 14,
                  borderRadius: 10,
                }}
              >
                <Text
                  style={{
                    fontWeight: "500",
                    fontSize: !isMobileView() ?  responsiveFontSize(1) : responsiveFontSize(1.8),
                  }}
                >
                  {TOTALMAGAZINES}
                </Text>
              </View>
            </View>
          </View>

    {/* <Purchasecard data={member} /> */}
    <View
  style={[
       commonStyles.spaceLeftPrimary,
       commonStyles.spaceRightPrimary,
     !isMobileView() && {
        paddingRight:  0,
      }
      ]

  }
    >
      {/* <View
        style={{
          flexDirection: "row",
          width: "84%",
          alignSelf: "center",
          // borderWidth: 5,


        }}
      >
        <View
          style={{
            flex: 1,
            borderWidth: 0,
            width: "10%",
            justifyContent: "center",
          }}
        >
          <Text
            style={{
              fontFamily: "openSans",
              fontSize: 20,
              fontWeight: "400",
              color: "rgba(0, 0, 0, 0.6)",
            }}
          >
            Name
          </Text>
        </View>
        <View
          style={{
            flex: 1,
            borderWidth: 0,
            width: "10%",
            justifyContent: "center",

          }}
        >
          <Text
            style={{
              fontFamily: "openSans",
              fontSize: 20,
              fontWeight: "400",
              color: "rgba(0, 0, 0, 0.6)",
            }}
          >
            For
          </Text>
        </View>
        <View
          style={{
            flex: 1,
            borderWidth: 0,
            width: "10%",
            justifyContent: "center",

          }}
        >
          <Text
            style={{
              fontFamily: "openSans",
              fontSize: 20,
              fontWeight: "400",
              color: "rgba(0, 0, 0, 0.6)",
            }}
          >
            Type
          </Text>
        </View>
        <View
          style={{
            flex: 1,
            borderWidth: 0,
            width: "10%",
            justifyContent: "center",

          }}
        >
          <Text
            style={{
              fontFamily: "openSans",
              fontSize: 20,
              fontWeight: "400",
              color: "rgba(0, 0, 0, 0.6)",
            }}
          >
            Amount
          </Text>
        </View>
      </View>

      <FlatList
        keyExtractor={(item, index) => item.id}
        data={member}
        renderItem={renderCartItems}
        style={{ borderWidth: 0, paddingHorizontal: 83, }}
      /> */}

      {/* New Table */}
     {currentPageData&& <View style={[styles.container,

              ]}>
                     <ScrollView style={{width:"100%"}} contentContainerStyle={{width: isMobileView() && "100%"}} horizontal={isMobileView() && true      }>
          <Table style={{width:"100%"}} borderStyle={{ borderWidth: 0, borderColor: '#c8e1ff', }}>
        <Row data={tableHead} style={[styles.head,{paddingRight: isMobileView() && 25}]}  flexArr={isMobileView() ? [isTabletView() ? 4: 1,  isTabletView() ? 2.5 : 1] : [isTabletView() ? 5: 1, isTabletView() ? 1.8 : 1, isTabletView() ? 2.5 : 1]}  textStyle={styles.textHead} />
        {currentPageData?.map((rowData, index) => (
      <TableWrapper key={index} style={styles.tableRow}>

        <Cell
          data={dateItem(rowData,index)}
          textStyle={{flexDirection:"column",justifyContent:"center",alignItems:"flex-start"}}
          style={{ flex: isTabletView() ? 4 : 1,flexWrap:"wrap" }}
        />
{    isMobileView() ? <></> :    <Cell data={amountItem(rowData,index)} style={{ flex: isTabletView() ? 1.8 :  1, }}  textStyle={styles.text}  />}
        <Cell data={actionItem(rowData,index)} style={{ flex: isTabletView() ? 2.5 :  1, }}  textStyle={styles.text}  />

        {/* <Cell
          key={index}
          data={viewButton(rowData, index)}
          textStyle={styles.text}
          style={{ flex: 1, justifyContent: 'center', alignItems: 'flex-start' }}
        /> */}
      </TableWrapper>
      //  <FilteredRow key={index} rowData={rowData} index={index} />
    ))}
          {/* </TableWrapper> */}
        {/* ))} */}
      </Table>
      </ScrollView>
      </View>}
  <View style={{borderWidth:0,flexDirection:'row',justifyContent:'flex-end'}}>


      <View style={{ flexDirection: 'row', justifyContent: 'flex-end', marginTop: 10, alignItems:'center',borderWidth:0}}>

  <TouchableOpacity style={{borderRadius:7.13,cursor:pageNumbers<1 && 'pointer' ,borderColor:'rgba(0, 0, 0, 0.15)',borderWidth:2,height:30,width:30,justifyContent:'center',alignItems:'center'}}  onPress={handlePreviousPage}>
    <Image style={{ tintColor: currentPage > 1 ? Color.BLUE_MEDIUM:'',height:20,width:20,}} source={require('../../assets/images/nextPageIcon.png')}></Image>
  </TouchableOpacity>
  {/* Page numbers */}

  {getPageNumbers().map((pageNumber) => (
    <TouchableOpacity key={pageNumber} onPress={() => handlePageClick(pageNumber)}>
      <Text
        style={{
          fontSize: 15,
          marginHorizontal: 10,
          fontWeight: pageNumber === currentPage ? 'bold' : 'normal',
          cursor: 'pointer',
        }}
        >
        {pageNumber}
      </Text>
    </TouchableOpacity>
  ))}

  <TouchableOpacity style={{borderRadius:7.13,cursor:currentPage !== totalPages &&'pointer', borderColor:'rgba(0, 0, 0, 0.15)',borderWidth:2,height:30,width:30,justifyContent:'center',alignItems:'center'}} onPress={handleNextPage}>
    <Image style={{ tintColor: currentPage === totalPages ?  '' : Color.BLUE_MEDIUM,height:20,width:20,transform:[{rotate: '180deg'}]}} source={require('../../assets/images/nextPageIcon.png')}></Image>
  </TouchableOpacity>
</View>
  </View>


      {/* {renderCartItems()} */}
    </View>
    <View style={[commonStyles.spaceLeftPrimary,
       commonStyles.spaceRightPrimary,{paddingRight:0, marginBottom: isMobileView() && responsiveScreenHeight(7)}]}>

    <View style={[{  flexDirection:isMobileView() ? "column" : "row", marginTop: isMobileView() ? 20 : responsiveHeight(3),justifyContent :"flex-end",gap:isMobileView() ? 20 : 18  },      ]}>

    <View style={[{paddingHorizontal:20,paddingVertical:20,flex: isMobileView() ? 2 : 3,backgroundColor:"#F8F9FA",borderWidth:0,flexDirection:'column',justifyContent:"space-around", borderRadius:8, rowGap: isMobileView() && 16,},  ]}>
<View style={{marginBottom:10,}}>

    <Text
                style={{ fontWeight: "400",fontSize: !isMobileView()
                ? responsiveFontSize(2)
                : responsiveFontSize(2.4),  }}
              >
                Dispatch Address
              </Text>

  {!defaultAddressFlag &&<Text style={{ color: "#ff0000", fontWeight: "500" }}>Please set a default address from profile page!</Text> }
  </View>




<View style={[ isMobileView() ? commonStyles.Col2GridParent : {flexDirection:'row',gap:20}, isMobileView() && commonStyles.gap0, {rowGap: isMobileView() && 16},{zIndex:99}]}>

                  <View style={[ isMobileView() ? commonStyles.Col2GridItem : { flex:.9 }]}>
                  <Text
                    style={{
                      fontWeight: "600",
                      fontSize: isMobileView()  ? responsiveFontSize(2.2) : responsiveFontSize(1.1),
                      margin: 0,

                    }}
                  >
                    Currency
                  </Text>
                  <SelectDropdown
                       selectedRowStyle={commonStyles.selectedRowStyle}
                       selectedRowTextStyle={commonStyles.selectedRowTextStyle}
                  dropdownOverlayColor={"No"}
                  rowTextStyle={commonStyles.commonRowTextStyle}
                  defaultButtonText={currencyData?.length==1?currencyData[0].currency_name:"Select Currency"}
                  defaultValue={currencyData[0]?.currency_name}
                dropdownStyle={commonStyles.commanDropdownStyle}
                rowStyle={commonStyles.commanRowStyle}
                buttonStyle={{
                  width: "100%",
                  height: isMobileView() && isTabletView()  ? responsiveScreenHeight(6.5) : "50%",
                  marginTop:10,
                  backgroundColor: Color.WHITE,
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderRadius: 10,
                  borderWidth: 1,
                  borderColor: "rgba(0, 0, 0, 0.14)",
                  padding:10,
                }}
                buttonTextStyle={{
                  color: "rgba(0, 0, 0,)",
                  fontSize: isMobileView()  ? responsiveFontSize(2.2) :   responsiveFontSize(1.1),
                  textAlign: "left",
                  margin: 0,
                  marginVertical: 0,
                  fontWeight: "600",
                }}


                renderDropdownIcon={(props) => renderDropdownIcon(props)}
                data={currencyData}
                    onSelect={(item) => {setSelectedCurrency(item.currency_code)}}
                    buttonTextAfterSelection={(selectedItem)=>{return (`${selectedItem.currency_name}`)}}
                    rowTextForSelection={(item)=>{return (`${item.currency_name}`)}}


                  />
                  {/* {validationCurrency&&<Text style={{ color: "#ff0000", fontWeight: "500" }}>Currency Required !</Text>} */}
                </View>

                 {!bulkTypeFlag && <View style={[ isMobileView() ? commonStyles.Col2GridItem : { flex:.9 }]}>
                  <Text
                    style={{
                      fontWeight: "600",
                      fontSize: isMobileView()  ? responsiveFontSize(2.2) : responsiveFontSize(1.1),
                      margin: 0,

                    }}
                  >
                    Subscription Plan
                  </Text>
                  <SelectDropdown
                  disabled={!selectedCurrency}
                  dropdownOverlayColor={"No"}
                  selectedRowStyle={commonStyles.selectedRowStyle}
                  selectedRowTextStyle={commonStyles.selectedRowTextStyle}
                  rowTextStyle={commonStyles.commonRowTextStyle}
                // disabled={selectedCurrency?false:true}
                defaultButtonText={"Select Your Plan"}
                dropdownStyle={commonStyles.commanDropdownStyle}
                rowStyle={commonStyles.commanRowStyle}
                buttonStyle={{
                  width: "100%",
                  height: isMobileView() && isTabletView()  ? responsiveScreenHeight(6.5) : "50%",
                  marginTop:10,
                  backgroundColor: Color.WHITE,
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderRadius: 10,
                  borderWidth: 1,
                  borderColor: "rgba(0, 0, 0, 0.14)",
                  padding:10,
                }}
                buttonTextStyle={{
                  color: "rgba(0, 0, 0,)",
                  fontSize: isMobileView()  ? responsiveFontSize(2.2) :   responsiveFontSize(1.1),
                  textAlign: "left",
                  margin: 0,
                  marginVertical: 0,
                  fontWeight: "600",
                }}


                renderDropdownIcon={(props) => renderDropdownIcon(props)}
                data={SubscriptionsData}
                    onSelect={(item) => {setSelectedPlan(item.subscription_type_id),setValidationPlan(null),getDatesFromApi(item.subscription_type_id)}}
                    buttonTextAfterSelection={(selectedItem)=>{return `${selectedItem.subscription_type_name}`}}
                    rowTextForSelection={(selectedItem)=>{return `${selectedItem.subscription_type_name}`}}


                  />
                  {validationPlan&&<Text style={{ color: "#ff0000", fontWeight: "500" }}>Please select a plan!</Text>}

                </View>}
{bulkTypeFlag &&
                <View style={[ isMobileView() ? commonStyles.Col2GridItem : { flex:.9 },{zIndex:2}]}>
                    <Text
                    style={{
                      fontWeight: "600",
                      fontSize: isMobileView()  ? responsiveFontSize(2.2) : responsiveFontSize(1.1),
                      margin: 0,

                    }}
                  >
                   Select Month
                  </Text>
                  <TouchableOpacity onPress={()=>{setDatePickerVisible(!isDatePickerVisible)}}>
               <TextInput
      placeholderTextColor={"rgba(0, 0, 0, 0.30)"}
      placeholder="Month"

      //defaultValue={profileData?.dob}
      value={ getMonth(selectedBulkDatesData) }
      style={{
        cursor:"pointer",
        height: "100%" ,
        marginTop: 10,
        backgroundColor: Color.WHITE,
        alignItems: "center",
        justifyContent: "space-between",
        borderRadius: 10,
        borderWidth: 1,
        borderColor: "rgba(0, 0, 0, 0.14)",
        fontSize: isMobileView()
          ? responsiveFontSize(2.2)
          : responsiveFontSize(1.1),
        fontWeight: "600",

        padding: 10,
      }}></TextInput>
      <Image  source={require("../../assets/images/dropDown.png")}
      style={{width:23,height:23,position:"absolute",right:8,bottom:9}}
      resizeMode="contain" />


      </TouchableOpacity>
      {isDatePickerVisible && bulkTypeFlag && (

<View style={{position:'absolute',top:"-250%",width:'100%',zIndex:9999}}>
<DatePicker

                           mode="monthYear"
                           onCancel={() => setDatePickerVisible(false)}

                          // maximumDate={}
                          current={getBulkDatesStartDate(bulkDatesData)}
                          minimumDate={getBulkDatesStartDate(bulkDatesData)}
                          maximumDate={getBulkDatesEndDate(bulkDatesData)}

                          style={{boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}}
                           selectorStartingYear={bulkDatesData?.year}
                           onMonthYearChange={selectedDate => {handleBulkCalendarChange(selectedDate);setDatePickerVisible(false)}}
                          />
                          </View>
                          )}
                          </View>
                          }
                <View style={[ isMobileView() ? commonStyles.width100 : { flex:1.2 }]}>

                <Text
                    style={{
                      fontWeight: "600",
                      fontSize: isMobileView()  ? responsiveFontSize(2.2) : responsiveFontSize(1.1),
                      margin: 0,

                    }}
                  >
                    Address Type
                  </Text>
                <View
 style={{flexDirection: isMobileView() ? "column" : "row",
   height: isMobileView() && isTabletView()  ? responsiveScreenHeight(10) : "50%",
 marginTop:10,alignItems: isMobileView() ? "flex-start" : "center",backgroundColor:Color.WHITE,padding:8,paddingRight:16,borderRadius:10,
 overflowX:"auto",
 overflowY:"hidden",
 borderWidth: 1,
 justifyContent: isMobileView() && "center",
 borderColor: "rgba(0, 0, 0, 0.14)",
 }}
>
    <TouchableOpacity
    style={{flexDirection:"row",alignItems:"center"}}
    onPress={() =>  {setChecked('first')
    getAddressMember()}}
    >
      <RadioButton
        value="first"
        status={ checked === 'first' ? 'checked' : 'unchecked' }
        onPress={() => {setChecked('first')
        getAddressMember()}

      }
        color={Color.BLUE_MEDIUM}
        />
        <Text  style={{fontWeight:"500"}}>{dispatchType[0]}</Text>
        </TouchableOpacity>
    <TouchableOpacity
      onPress={() => {setChecked('second')
      setDispachTypeSelection('Branch Bundle')
      setSelectedBhawan(null)
      setBhawanError('Bhawan field is required')
   }
    }
    style={{flexDirection:"row",alignItems:"center"}}
    >
      <RadioButton
        value="second"
        status={ checked === 'second' ? 'checked' : 'unchecked' }
        onPress={() => {setChecked('second')
        setDispachTypeSelection('Branch Bundle')
        setSelectedBhawan(null)
        setBhawanError('Bhawan field is required')
      }}
        color={Color.BLUE_MEDIUM}
        />
        <Text style={{fontWeight:"500"}}>{dispatchType[1]}</Text>
        </TouchableOpacity>

    </View>
    </View>

</View>

<View style={{flexDirection: isMobileView() ?"column" : "row",gap: isMobileView() ? 16 : 20,width:"100%",zIndex:1}} >
{
  memberAddressList?.length>0 &&
  <>
    { dispachTypeSelection != 'Personal' ?
    (
      <>
    <View style={{ flex: !isMobileView() && .9 }}>
                  <Text
                    style={{
                      fontWeight: "600",
                      fontSize: isMobileView()  ? responsiveFontSize(2.2) : responsiveFontSize(1.1),
                      margin: 0,

                    }}
                  >
                    Zone
                  </Text>
                  <SelectDropdown search searchPlaceHolder={"Search Zone.."}
                         searchInputStyle={commonStyles.commanDropdownSearchStyle}
                         searchInputTxtStyle={{borderColor:'transparent',borderRadius:6,paddingHorizontal:10}}
        dropdownOverlayColor={"No"}
                         dropdownStyle={commonStyles.commanDropdownHightStyle(200)}
                         selectedRowStyle={commonStyles.selectedRowStyle}
                         selectedRowTextStyle={commonStyles.selectedRowTextStyle}
                         rowStyle={commonStyles.commanRowStyle}
                         rowTextStyle={commonStyles.commonRowTextStyle}
                         data={zoneList}
                         rowTextForSelection={(item) => item.zone_name}
                         buttonTextAfterSelection={(selectedItem) =>
                           selectedItem.zone_name
                         }
                         onChangeSearchInputText={(text) => handleSearchZone(text)}

                         // defaultValue=
                         defaultButtonText={
                          defaultFullAddress.zone_name?defaultFullAddress.zone_name:"Select Zone"
                         }

                         buttonStyle={{
                          //  flex:8,
                          width:"100%",
                          height: isMobileView() && isTabletView()  ? responsiveScreenHeight(6.5) : 42.6,
                           marginTop:10,
                           backgroundColor: Color.WHITE,
                           borderRadius: 10,
                             borderWidth: 1,
                           borderColor: "rgba(0, 0, 0, 0.14)",
                         }}
                        buttonTextStyle={{
                          color: "rgba(0, 0, 0,)",
                          fontSize: isMobileView()  ? responsiveFontSize(2.2) :   responsiveFontSize(1.1),
                          textAlign: "left",
                          margin: 0,
                          marginVertical: 0,
                          fontWeight: "600",
                        }}
                         renderDropdownIcon={(props) => renderDropdownIcon(props)}
                         onSelect={(selectedItem, index) => {
                          onSelectionOfZone(selectedItem)

                           // getBranchMemberList(selectedItem.zone_code);
                         }}
                       />
                  {/* {validationCurrency&&<Text style={{ color: "#ff0000", fontWeight: "500" }}>Currency Required !</Text>} */}
                </View>

                  <View style={{ flex: !isMobileView() && .9 }}>
                  <Text
                    style={{
                      fontWeight: "600",
                      fontSize: isMobileView()  ? responsiveFontSize(2.2) : responsiveFontSize(1.1),
                      margin: 0,

                    }}
                  >
                   Branch
                  </Text>
                  <SelectDropdown
                       search searchPlaceHolder={"Search Branch.."}
                                       searchInputStyle={commonStyles.commanDropdownSearchStyle}
                                       searchInputTxtStyle={{borderColor:'transparent',borderRadius:6,paddingHorizontal:10}}
                      dropdownOverlayColor={"No"}
                                       dropdownStyle={commonStyles.commanDropdownHightStyle(200)}
                                       selectedRowStyle={commonStyles.selectedRowStyle}
                                       selectedRowTextStyle={commonStyles.selectedRowTextStyle}
                                       rowStyle={commonStyles.commanRowStyle}
                                       rowTextStyle={commonStyles.commonRowTextStyle}
                       disabled={!defaultAddressFlag}

                       style={{ borderWidth: 1 }}
                       onSelect={(selectedItem) => {setSelectedBranch(selectedItem?.branch_code),setBranchError('') }}

                       buttonTextAfterSelection={(selectedItem,index) =>{return `${selectedItem?.branch_name}` }}
                       rowTextForSelection={(item) => {return `${item?.branch_name}`}}
                       defaultButtonText={
                        defaultFullAddress.branch_name?defaultFullAddress.branch_name:"Select Branch"
                       }
                       data={branchList}
                       //defaultValue={defaultFullAddress?.branch_name}
                       buttonStyle={{
                        //  flex:8,
                        width:"100%",
                        height: isMobileView() && isTabletView()  ? responsiveScreenHeight(6.5) : 42.6,
                         marginTop:10,
                         backgroundColor: Color.WHITE,
                         borderRadius: 10,
                           borderWidth: 1,
                         borderColor: "rgba(0, 0, 0, 0.14)",
                       }}
                       onChangeSearchInputText={(text) => handleBranchSearch(text)}

                       renderDropdownIcon={(props) => renderDropdownIcon(props)}
                       buttonTextStyle={{
                         color: !defaultAddressFlag?"rgba(0, 0, 0,0.1)":"rgb(0, 0, 0)",
                         fontSize: isMobileView() ? responsiveFontSize(2.2) : responsiveFontSize(1.1),
                         textAlign: "left",
                         textWrap:"inherit",
                         margin: 0,
                         marginVertical: 0,
                         fontWeight: "600",
                       }}
                     />
                  {branchError !== "" &&<Text style={{ color: "#ff0000", fontWeight: "500" }}>{branchError}</Text>}

                </View>
                <View style={{flex: !isMobileView() && 1.2}}>

                <Text
                    style={{
                      fontWeight: "600",
                      fontSize: isMobileView()  ? responsiveFontSize(2.2) : responsiveFontSize(1.1),
                      margin: 0,

                    }}
                  >
                    Bhawan Address
                  </Text>
                  <SelectDropdown
                    search searchPlaceHolder={"Search Bhawan Address.."}
                                    searchInputStyle={commonStyles.commanDropdownSearchStyle}
                                    searchInputTxtStyle={{borderColor:'transparent',borderRadius:6,paddingHorizontal:10}}
                  dropdownOverlayColor={"No"}
                                    dropdownStyle={commonStyles.commanDropdownHightStyle(200)}
                                    selectedRowStyle={commonStyles.selectedRowStyle}
                                    selectedRowTextStyle={commonStyles.selectedRowTextStyle}
                                    rowStyle={commonStyles.commanRowStyle}
                                    rowTextStyle={commonStyles.commonRowTextStyle}
                    disabled={!defaultAddressFlag}

                    style={{ borderWidth: 1 }}
                    onSelect={(selectedItem) => {setSelectedBhawan(selectedItem?.loc_location_code),setBhawanError('')}}

                    buttonTextAfterSelection={(selectedItem,index) =>{return `${selectedItem?.loc_address}, ${selectedItem?.loc_address_second},
                    ${selectedItem?.loc_city_name},${selectedItem?.loc_pin},
                     ${selectedItem?.loc_district_name}, ${selectedItem?.loc_state_name}
                     ,${selectedItem?.loc_country}`
                   } }
                    rowTextForSelection={(item) => {return  `${item?.loc_address}, ${item?.loc_address_second}, ${item?.loc_district_name}, ${item?.loc_city_name}`}}
                    defaultButtonText={'Select Bhawan Address'}
                    data={bhawanList}
                    defaultValue={selectedBhawan}
                    buttonStyle={{
                     width:"100%",
                      // flex:8,
                      height: isMobileView() && isTabletView()  ? responsiveScreenHeight(6.5) : 42.6,
                      marginTop:10,
                      backgroundColor: Color.WHITE,
                      borderRadius: 10,
                        borderWidth: 1,
                      borderColor: "rgba(0, 0, 0, 0.14)",
                    }}

                    renderDropdownIcon={(props) => renderDropdownIcon(props)}
                    buttonTextStyle={{
                      color: !defaultAddressFlag?"rgba(0, 0, 0,0.1)":"rgb(0, 0, 0)",
                      fontSize: isMobileView() ? responsiveFontSize(2.2) : responsiveFontSize(1.1),
                      textAlign: "left",
                      // textWrap:"inherit",
                      margin: 0,
                      marginVertical: 0,
                      fontWeight: "600",
                    }}

                  />
                  {bhawanError !== "" &&<Text style={{ color: "#ff0000", fontWeight: "500" }}>{bhawanError}</Text>}

    </View>
    </>
    ) : (<>
        <View style={{ flex:1,width:"100%" }}>
        <Text
                    style={{
                      fontWeight: "600",
                      fontSize: isMobileView()  ? responsiveFontSize(2.2) : responsiveFontSize(1.1),
                      margin: 0,

                    }}
                  >
                    Address
                  </Text>
                  <SelectDropdown
                    search searchPlaceHolder={"Search Address.."}
                                    searchInputStyle={{borderWidth:0.5,borderColor:"#00000024",borderRadius:6,height:35,width:"96%",alignSelf:"center",padding:0,paddingHorizontal:0}}
                                    searchInputTxtStyle={{borderColor:'transparent',borderRadius:6,paddingHorizontal:10}}
                  dropdownOverlayColor={"No"}
                                    dropdownStyle={commonStyles.commanDropdownHightStyle(150)}
                                    selectedRowStyle={commonStyles.selectedRowStyle}
                                    selectedRowTextStyle={commonStyles.selectedRowTextStyle}
                                    rowStyle={commonStyles.commanRowStyle}
                                    rowTextStyle={commonStyles.commonRowTextStyle}
                    disabled={!defaultAddressFlag}

                    style={{ borderWidth: 1 }}
                    onSelect={(selectedItem) => {setSelectedAddress(selectedItem?.address_id)}}

                    buttonTextAfterSelection={(selectedItem,index) =>{return `${selectedItem?.adr_line1}, ${selectedItem?.adr_line2},
                    ${selectedItem?.city_name},${selectedItem?.pincode},
                     ${selectedItem?.district_name}, ${selectedItem?.state_name}
                     ,${selectedItem?.country_name} , ${selectedItem?.zone_name}
                      , ${selectedItem?.branch_name}`
                   } }
                    rowTextForSelection={(item) => {return  `${item?.adr_line1}, ${item?.adr_line2}, ,${item?.district_name}, ${item?.city_name},${item?.pincode},${item.country_name},${item?.zone_name}`}}
                    defaultButtonText={`${defaultFullAddress?.adr_line1},${defaultFullAddress?.adr_line2},${defaultFullAddress?.city_name},${defaultFullAddress?.pincode},${defaultFullAddress?.state_name},${defaultFullAddress?.country_name},${defaultFullAddress?.zone_name}`}
                    data={memberAddressList}
                    defaultValue={selectedAddress}
                    buttonStyle={{
                      // flex:1,
                      width:"100%",
                      // flex:8,
                      // alignItems:"flex-start",
                      height: isMobileView() && isTabletView()  ? responsiveScreenHeight(6.5) : 42.6,
                      marginTop:10,
                      backgroundColor: Color.WHITE,
                      borderRadius: 10,
                        borderWidth: 1,
                      borderColor: "rgba(0, 0, 0, 0.14)",
                    }}

                    renderDropdownIcon={(props) => renderDropdownIcon(props)}
                    buttonTextStyle={{
                      color: !defaultAddressFlag?"rgba(0, 0, 0,0.1)":"rgb(0, 0, 0)",
                      fontSize: isMobileView() ? responsiveFontSize(2.2) : responsiveFontSize(1.1),
                      textAlign: "left",
                      // textWrap:"inherit",
                      margin: 0,
                      marginVertical: 0,
                      fontWeight: "600",
                    }}
                  />
                  {/* {validationCurrency&&<Text style={{ color: "#ff0000", fontWeight: "500" }}>Currency Required !</Text>} */}
                </View>
    </>)

    }
  </>

  }



{/* <SelectDropdown
rowTextStyle={{
  fontFamily: "openSans",
}}
style={{ borderWidth: 1 }}
onSelect={(selectedItem) => {setSelectedDispatchType(selectedItem), selectedItem=== 'Personal'? getAddressMember():getBranches()}}
defaultValue={dispatchType[0]}
buttonTextAfterSelection={(selectedItem,index) =>{return `${selectedItem}`} }
rowTextForSelection={(item)=>{ return`${item}`}}
defaultButtonText="Select Dispatch Type"
data={dispatchType}

buttonStyle={{
  flex:2,
  height:50,
  backgroundColor: Color.WHITE,
  borderRadius: 10,
  borderWidth: 1,
  borderColor: "rgba(0, 0, 0, 0.14)",
}}
dropdownStyle={{
  // height: 45,
  backgroundColor: Color.WHITE,
  borderRadius: 20,
  borderWidth: 1,
  borderColor: "rgba(0, 0, 0, 0.14)",
  fontFamily: "openSans",
}}
rowStyle={{
  padding: 5,
  alignItems: "center",
  justifyContent: "center",
}}
renderDropdownIcon={(props) => renderDropdownIcon(props)}
buttonTextStyle={{
  color: "rgb(0, 0, 0)",
  fontSize: isMobileView() ? responsiveFontSize(2.2) : responsiveFontSize(1.1),
  textAlign: "left",
  margin: 0,
  marginVertical: 0,
  fontWeight: "600",
}}
/> */}

    { false &&
    <View style={[commonStyles.width100]}>

           { dispachTypeSelection != 'Personal' ? (<>
           <View style={[commonStyles.flexRow,{gap:40}]}>
           <Text
                    style={{
                      fontWeight: "600",
                      fontSize: isMobileView()  ? responsiveFontSize(2.2) : responsiveFontSize(1.1),
                      margin: 0,
                      flex:1,

                    }}
                  >
                    Zone
                  </Text>
                  <Text
                    style={{
                      fontWeight: "600",
                      fontSize: isMobileView()  ? responsiveFontSize(2.2) : responsiveFontSize(1.1),
                      margin: 0,
                      flex:1,
                    }}
                  >
                    Branch
                  </Text>
                  <Text
                    style={{
                      fontWeight: "600",
                      fontSize: isMobileView()  ? responsiveFontSize(2.2) : responsiveFontSize(1.1),
                      margin: 0,
                      flex:1,
                    }}
                  >
                    Bhawan Address
                  </Text>

           </View>
           </>) :
            (

             <Text
                    style={{
                      fontWeight: "600",
                      fontSize: isMobileView()  ? responsiveFontSize(2.2) : responsiveFontSize(1.1),
                      margin: 0,

                    }}
                  >
                    Address
                  </Text>
           )

                }
<View style={[commonStyles.flexRow,commonStyles.gap20px]}>

                      { dispachTypeSelection != 'Personal'&& <>
                      <SelectDropdown search searchPlaceHolder={"Search Zone.."}
                         searchInputStyle={{borderWidth:0.5,borderColor:"#00000024",borderRadius:6,height:35,width:"96%",alignSelf:"center",padding:0,paddingHorizontal:0}}
                         searchInputTxtStyle={{borderColor:'transparent',borderRadius:6,paddingHorizontal:10}}
        dropdownOverlayColor={"No"}
                         dropdownStyle={commonStyles.commanDropdownHightStyle(150)}
                         selectedRowStyle={commonStyles.selectedRowStyle}
                         selectedRowTextStyle={commonStyles.selectedRowTextStyle}
                         rowStyle={commonStyles.commanRowStyle}
                         rowTextStyle={commonStyles.commonRowTextStyle}
                         data={zoneList}
                         rowTextForSelection={(item) => item.zone_name}
                         buttonTextAfterSelection={(selectedItem) =>
                           selectedItem.zone_name
                         }
                         onChangeSearchInputText={(text) => handleSearchZone(text)}

                         // defaultValue=
                         defaultButtonText={
                          defaultFullAddress.zone_name?defaultFullAddress.zone_name:"Select Zone"
                         }

                         buttonStyle={[
                             {
                  // width: 225,
                  flex:1,
                  height: isMobileView() && isTabletView()  ? responsiveScreenHeight(6.5) : 42.6,

                  marginTop:10,
                           backgroundColor: Color.WHITE,
                           justifyContent: "space-between",
                           borderRadius: 10,
                           borderWidth: 1,
                           borderColor: "rgba(0, 0, 0, 0.14)",
                           padding: 10,
                           },
                             commonStyles.inputFieldMobile,
                           ]}
                         buttonTextStyle={{
                           color: "rgba(0, 0, 0)",
                           fontSize: isMobileView()
                               ? responsiveFontSize(2.2)
                               : responsiveFontSize(1.1),
                           textAlign: "left",
                           margin: 0,
                           marginVertical: 0,
                           fontWeight: "600",
                         }}
                         renderDropdownIcon={(props) => renderDropdownIcon(props)}
                         onSelect={(selectedItem, index) => {
                          if(selectedItem.zone_sect_number && selectedItem.zone_sect_number!=''){
                            setSelectedZone(selectedItem.zone_code +'-'+selectedItem.zone_sect_number)
                            }else{
                            setSelectedZone(selectedItem.zone_code)

                            }
                           //setSelectedBranch('')
                           setBranchesList([])
                           setBranchError('Branch field is required')
                           setSelectedBranch(null)
                           // getBranchMemberList(selectedItem.zone_code);
                         }}
                       />
                      <View style={{flex:1}}>
                      <SelectDropdown
                       search searchPlaceHolder={"Search Branch.."}
                                       searchInputStyle={commonStyles.commanDropdownSearchStyle}
                                       searchInputTxtStyle={{borderColor:'transparent',borderRadius:6,paddingHorizontal:10}}
                      dropdownOverlayColor={"No"}
                                       dropdownStyle={commonStyles.commanDropdownHightStyle(200)}
                                       selectedRowStyle={commonStyles.selectedRowStyle}
                                       selectedRowTextStyle={commonStyles.selectedRowTextStyle}
                                       rowStyle={commonStyles.commanRowStyle}
                                       rowTextStyle={commonStyles.commonRowTextStyle}
                       disabled={!defaultAddressFlag}

                       style={{ borderWidth: 1 }}
                       onSelect={(selectedItem) => {setSelectedBranch(selectedItem?.branch_code),setBranchError('') }}

                       buttonTextAfterSelection={(selectedItem,index) =>{return `${selectedItem?.branch_name}` }}
                       rowTextForSelection={(item) => {return `${item?.branch_name}`}}
                       defaultButtonText={defaultFullAddress?.branch_name? defaultFullAddress?.branch_name: "Select Branch"}
                       data={branchList}
                       //defaultValue={defaultFullAddress?.branch_name}
                       buttonStyle={{
                        //  flex:8,
                        width:"100%",
                        height: isMobileView() && isTabletView()  ? responsiveScreenHeight(6.5) : 42.6,
                         marginTop:10,
                         backgroundColor: Color.WHITE,
                         borderRadius: 10,
                           borderWidth: 1,
                         borderColor: "rgba(0, 0, 0, 0.14)",
                       }}
                       onChangeSearchInputText={(text) => handleBranchSearch(text)}

                       renderDropdownIcon={(props) => renderDropdownIcon(props)}
                       buttonTextStyle={{
                         color: !defaultAddressFlag?"rgba(0, 0, 0,0.1)":"rgb(0, 0, 0)",
                         fontSize: isMobileView() ? responsiveFontSize(2.2) : responsiveFontSize(1.1),
                         textAlign: "left",
                         textWrap:"inherit",
                         margin: 0,
                         marginVertical: 0,
                         fontWeight: "600",
                       }}
                     />
                      {branchError !== "" && (
                      <Text
                        style={{ color: "red", fontWeight: "500", height: 10 }}
                      >
                        {branchError}
                      </Text>
                    )}
                      </View>
                    <SelectDropdown
                    search searchPlaceHolder={"Search Bhawan Address.."}
                                    searchInputStyle={commonStyles.commanDropdownSearchStyle}
                                    searchInputTxtStyle={{borderColor:'transparent',borderRadius:6,paddingHorizontal:10}}
                  dropdownOverlayColor={"No"}
                                    dropdownStyle={commonStyles.commanDropdownHightStyle(200)}
                                    selectedRowStyle={commonStyles.selectedRowStyle}
                                    selectedRowTextStyle={commonStyles.selectedRowTextStyle}
                                    rowStyle={commonStyles.commanRowStyle}
                                    rowTextStyle={commonStyles.commonRowTextStyle}
                    disabled={!defaultAddressFlag}

                    style={{ borderWidth: 1 }}
                    onSelect={(selectedItem) => {setSelectedBhawan(selectedItem?.loc_location_code),setBhawanError('')}}

                    buttonTextAfterSelection={(selectedItem,index) =>{return `${selectedItem?.loc_address}, ${selectedItem?.loc_address_second},
                    ${selectedItem?.loc_city_name},${selectedItem?.loc_pin},
                     ${selectedItem?.loc_district_name}, ${selectedItem?.loc_state_name}
                     ,${selectedItem?.loc_country}`
                   } }
                    rowTextForSelection={(item) => {return  `${item?.loc_address}, ${item?.loc_address_second}, ${item?.loc_district_name}, ${item?.loc_city_name}`}}
                    defaultButtonText={'Select Bhawan Address'}
                    data={bhawanList}
                    defaultValue={selectedBhawan}
                    buttonStyle={{
                      flex:1,
                      // flex:8,
                      height: isMobileView() && isTabletView()  ? responsiveScreenHeight(6.5) : 42.6,
                      marginTop:10,
                      backgroundColor: Color.WHITE,
                      borderRadius: 10,
                        borderWidth: 1,
                      borderColor: "rgba(0, 0, 0, 0.14)",
                    }}

                    renderDropdownIcon={(props) => renderDropdownIcon(props)}
                    buttonTextStyle={{
                      color: !defaultAddressFlag?"rgba(0, 0, 0,0.1)":"rgb(0, 0, 0)",
                      fontSize: isMobileView() ? responsiveFontSize(2.2) : responsiveFontSize(1.1),
                      textAlign: "left",
                      textWrap:"inherit",
                      margin: 0,
                      marginVertical: 0,
                      fontWeight: "600",
                    }}
                  />
                  {bhawanError !== "" && (
                      <Text
                        style={{ color: "red", fontWeight: "500", height: 10 }}
                      >
                        {bhawanError}
                      </Text>
                    )}

                        </>
                       }

                  { dispachTypeSelection === 'Personal'&&
                    <SelectDropdown
                    search searchPlaceHolder={"Search Address.."}
                                    searchInputStyle={commonStyles.commanDropdownSearchStyle}
                                    searchInputTxtStyle={{borderColor:'transparent',borderRadius:6,paddingHorizontal:10}}
                  dropdownOverlayColor={"No"}
                                    dropdownStyle={commonStyles.commanDropdownHightStyle(200)}
                                    selectedRowStyle={commonStyles.selectedRowStyle}
                                    selectedRowTextStyle={commonStyles.selectedRowTextStyle}
                                    rowStyle={commonStyles.commanRowStyle}
                                    rowTextStyle={commonStyles.commonRowTextStyle}
                    disabled={!defaultAddressFlag}

                    style={{ borderWidth: 1 }}
                    onSelect={(selectedItem) => {setSelectedAddress(selectedItem?.address_id)}}

                    buttonTextAfterSelection={(selectedItem,index) =>{return `${selectedItem?.adr_line1}, ${selectedItem?.adr_line2},
                    ${selectedItem?.city_name},${selectedItem?.pincode},
                     ${selectedItem?.district_name}, ${selectedItem?.state_name}
                     ,${selectedItem?.country_name} , ${selectedItem?.zone_name}
                      , ${selectedItem?.branch_name}`
                   } }
                    rowTextForSelection={(item) => {return  `${item?.adr_line1}, ${item?.adr_line2}, ${item?.district_name}, ${item?.city_name}`}}
                    defaultButtonText={`${defaultFullAddress?.adr_line1},${defaultFullAddress?.adr_line2},${defaultFullAddress?.city_name},${defaultFullAddress?.pincode},${defaultFullAddress?.state_name},${defaultFullAddress?.country_name},${defaultFullAddress?.zone_name}`}
                    data={memberAddressList}
                    defaultValue={selectedAddress}
                    buttonStyle={{
                      flex:1,
                      // flex:8,
                      height: isMobileView() && isTabletView()  ? responsiveScreenHeight(6.5) : 42.6,
                      marginTop:10,
                      backgroundColor: Color.WHITE,
                      borderRadius: 10,
                        borderWidth: 1,
                      borderColor: "rgba(0, 0, 0, 0.14)",
                    }}

                    renderDropdownIcon={(props) => renderDropdownIcon(props)}
                    buttonTextStyle={{
                      color: !defaultAddressFlag?"rgba(0, 0, 0,0.1)":"rgb(0, 0, 0)",
                      fontSize: isMobileView() ? responsiveFontSize(2.2) : responsiveFontSize(1.1),
                      textAlign: "left",
                      textWrap:"inherit",
                      margin: 0,
                      marginVertical: 0,
                      fontWeight: "600",
                    }}
                  />
                  }
</View>

    </View>
  }
      {/* {defaultAddressFlag && <Hoverable style={{alignItems:"center",   height:"100%",flex:1,}}>
{({ hovered }) => (
        <TouchableOpacity
        onPress={() => {
          {bulkTypeFlag===true?(

            (!(defaultAddressFlag===false || defaultAddressFlag===null))?

          updateCartAddress():
          validationCheck()
          ):( (!(selectedPlan===undefined||selectedPlan===null) && !(defaultAddressFlag===false || defaultAddressFlag===null))?

            updateCartAddress():
            validationCheck())}



        }}
          style={{
            backgroundColor: hovered ? Color.BLUE_MEDIUM :  Color.WHITE ,
            borderWidth: 2,
            borderColor:  hovered ? Color.BLUE_MEDIUM : Color.BLUE_MEDIUM,
            width:"100%",
            height: "100%",
            paddingHorizontal:20,
            paddingVertical:10,
            borderRadius: 10,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Text style={{ color: hovered ? Color.WHITE :  Color.BLUE_MEDIUM , fontWeight: "600" }}>
            Update
          </Text>
        </TouchableOpacity>
          )}
          </Hoverable>} */}
          </View>

</View>
<View
        style={[{
          borderWidth: 0,
          flex: !isMobileView() &&  1,
          // width: isMobileView() && "90%" || isTabletView() &&   " 96%" || isLargeLaptop() && "94%" || isSmallLaptop() && "92%",
          alignItems: "center",
          alignSelf: "end",
          height: !isMobileView() &&  "100%",
          width: isMobileView() && "100%",

          justifyContent: defaultAddressFlag ? "space-around" : "flex-end",

          gap: isMobileView() ? 20 : 16,

        },

      ]}
      >
     { cartData?.length>0 &&

     <View
        style={{
          alignItems: 'flex-end',
          backgroundColor: "#fff" ,
          backgroundColor: !bulkTypeFlag ? "#fff" : '#0b90df1a',
          padding: !bulkTypeFlag ?  0  : 12 ,
          width: "100%",
          height: !bulkTypeFlag ?  "auto" : 130,
          borderRadius: 10,
          gap: !bulkTypeFlag ? 5 : 0,
          justifyContent:"space-between"
        }}
        >
       {!bulkTypeFlag &&
        (  <View style={[commonStyles.width100,commonStyles.justifyBetween,{
          backgroundColor: '#0b90df1a',
          padding:12,
          borderTopLeftRadius:10,
          borderTopRightRadius:10,
          gap:15,
        }]}>
          <View
style={{
  justifyContent:"space-between",
  flexDirection:"row",
  width: '100%',
}}
>


        <Text
        style={{
          fontFamily: "openSans",
          fontSize: 16,
          fontWeight: "600",
          color: "rgba(0, 0, 0, 0.3)",
        }}

        > Start Date  </Text>

        <Text
        style={{
          fontFamily: "openSans",
          fontSize: 16,
          fontWeight: "600",
          color: Color.BLACK,
        }}

        >
         {formatedDateWithMonth(startEndDates?.start_date)}

        </Text>




</View>
          <View
style={{
  justifyContent:"space-between",
  flexDirection:"row",
  width: '100%',
}}
>

        <Text
        style={{
          fontFamily: "openSans",
          fontSize: 16,
          fontWeight: "600",
          color: "rgba(0, 0, 0, 0.3)",
        }}

        > End Date  </Text>
        <Text
        style={{
          fontFamily: "openSans",
          fontSize: 16,
          fontWeight: "600",
          color: Color.BLACK,
        }}

        >
             {formatedDateWithMonth(startEndDates?.end_date)}

        </Text>



</View>
          </View>)

      }

      <View style={
       [!bulkTypeFlag && {
        backgroundColor: '#0b90df1a',
        padding:12,
        gap:5,
        borderBottomLeftRadius:10,
        borderBottomRightRadius:10,

       },commonStyles.width100, bulkTypeFlag && {
        height:"100%",
        justifyContent:"space-between"
       }]}>
<View
style={{
  justifyContent:"space-between",
  flexDirection:"row",
  width: '100%',
}}
>


        {/* { !bulkTypeFlag &&
        <Text
        style={{
          fontFamily: "openSans",
          fontSize: 16,
          fontWeight: "600",
          color: Color.BLACK,
        }}

        >   ₹{getCartAllData.total_non_discount_amount}</Text>
      } */}
      { bulkTypeFlag &&<>
              <Text
              style={{
                fontFamily: "openSans",
                fontSize: 16,
                fontWeight: "600",
                color: "rgba(0, 0, 0, 0.3)",
              }}

              > Price  </Text>
        <Text
        style={{
          fontFamily: "openSans",
          fontSize: 16,
          fontWeight: "600",
          color: Color.BLACK,
        }}

        >   ₹{getCartAllData.magazine_amount}</Text>
        </>
      }


</View>



    {bulkTypeFlag &&<View
    style={{
      justifyContent:"space-between",
      flexDirection:"row",
      width: '100%',
    }}
    >
    <Text
            style={{
              fontFamily: "openSans",
              fontSize: 16,
              fontWeight: "600",
              color: "rgba(0, 0, 0, 0.3)",
            }}

            > Courier Charge  </Text>


            <Text
            style={{
              fontFamily: "openSans",
              fontSize: 16,
              fontWeight: "600",
              color: Color.BLACK,
            }}

            >   ₹{getCartAllData.courier_charge}</Text>

    </View>}


{/* { !bulkTypeFlag &&<View
style={{
  justifyContent:"space-between",
  flexDirection:"row",
  width: '100%',
}}
>

        <Text
        style={{
          fontFamily: "openSans",
          fontSize: 16,
          fontWeight: "600",
          color: "rgba(0, 0, 0, 0.3)",
        }}

        > Discount  </Text>
        <Text
        style={{
          fontFamily: "openSans",
          fontSize: 16,
          fontWeight: "600",
          color: Color.GREEN,
        }}

        >- ₹{getCartAllData.total_non_discount_amount-getCartAllData?.total_amount}</Text>
</View>
} */}

        <View
        style={{
          justifyContent:"space-between",
          flexDirection:"row",
          width: '100%',
        }}
        >

        <Text
        style={{
          fontFamily: "openSans",
          fontSize: 22,
          fontWeight: "700",
          color: "rgba(0, 0, 0, 1)",
        }}

        >Total</Text>
        <Text
        style={{
          fontFamily: "openSans",
          fontSize: 22,

          fontWeight: "700",
          color: "rgba(0, 0, 0, 1)",
        }}

        > ₹{getCartAllData?.total_amount}</Text>
        </View>
        </View>


</View>}

        {
          true?(
    <>

          {cartData?.length>0  && defaultAddressFlag && <Hoverable style={{width: isOnlyLaptopView() && "100%" || isMobileView() && "100%" || isOnlyTabletView() && "20%" ,height:40}}>
            {({ hovered }) => (
                    <TouchableOpacity
                      // disabled={amoutTrue()}
                      onPress={() => {
                        handlePaymentCart()
                      }}
                      style={{
                        backgroundColor: hovered ? Color.BLACK : Color.BLUE_MEDIUM,

                        height: "100%",
                        borderRadius: 10,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Text style={{ color: Color.WHITE, fontWeight: "700" }}>Buy All</Text>
                    </TouchableOpacity>
                      )}
                      </Hoverable> }
    {defaultAddressFlag&&patrikaSahayak && cartData?.length>0 &&  <Hoverable>
        {({ hovered }) => (
          <TouchableOpacity
          onPress={() => {
            postAddUnpainInvoices(cartData)
              // setModalVisible(!modalVisible);
          }}
            style={[
              {

                borderRadius: 100,
                with: "fit-content",
              },
            ]}
            >
         <Text
              style={{
                color: Color.BLUE_MEDIUM,
                fontWeight: "600",
                 fontSize: isMobileView() ? responsiveFontSize(1.9)  : responsiveFontSize(1),
                }}
                >
            Unpaid Purchase
            </Text>
          </TouchableOpacity>
        )}
      </Hoverable>
}
    </>
          ):(
            <></>
          )

        }

      </View>
</View>
</View>




    {/* Thank you Page */}
    <Modal
      animationType="fade"
      transparent={true}
      visible={thankYouPage}
      onRequestClose={() => {
        Alert.alert("Modal has been closed.");
        setThankYouPage(!thankYouPage);
      }}
    >
      <View
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          width:'100%',
          height: '100%',
          alignSelf:'center',
          backdropFilter: 'blur(20px)',
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
        }}
      >
        <View
          style={{
            backgroundColor: "white",
            borderRadius: 15,

            alignItems: "center",
            shadowColor: Color.BLACK,
            marginTop: 35,
            marginBottom: 35,


            width: "80%",
            height: isMobileView() ? "50%" : "90%",
            backgroundColor: "rgba(248, 249, 250, 1)",
            alignItems: "center",
            borderRadius: 20,
            justifyBetween: "space-between",
          }}
        >
          <View
            style={{
              borderWidth: 0,
              width: "100%",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <View></View>

            <TouchableOpacity
              onPress={() => {
                setThankYouPage(!thankYouPage);
              }}
            >
              <Image
                style={{ width: 30, height: 30, top: 25, right: 25 }}
                source={require("../../assets/images/cross.png")}
              />
            </TouchableOpacity>
          </View>
          <Image
              style={{ width: isMobileView() && responsiveScreenHeight(17) || isOnlyTabletView() && responsiveScreenHeight(30)  || 280, height: isMobileView() && responsiveScreenHeight(17) || isOnlyTabletView() && responsiveScreenHeight(30)  || 280 }}
              source={require("../../assets/images/thankyou.png")}
            />
          <View style={{ width: "75%", alignItems: "center" }}>
            <Text style={{ fontSize: isMobileView() ? responsiveFontSize(2.5) : 45, fontWeight: "700" }}>
              Thank you, enjoy!{" "}
            </Text>
            <View style={{ width: isMobileView() ? "80%" : "50%",marginTop:15, alignItems: "center" }}>
              <Text
                style={{
                  fontSize: isMobileView() ? responsiveFontSize(2) : 20,
                  fontWeight: "600",
                  color: "rgba(0, 0, 0, 0.5)",
                  textAlign: "center",
                }}
              >
                Redirecting to Payment Gateway
              </Text>

              <View style={{top:50}}>
              <ActivityIndicator size="large" />
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "600",
                  color: "rgba(0, 0, 0, 0.5)",
                }}
              >
                Please wait...
              </Text>

              </View>
            </View>
          </View>
        </View>
      </View>
    </Modal>
    <Modal
        animationType="fade"
        transparent={true}
        visible={deleteCartModal}
        onRequestClose={() => {
          Alert.alert("Modal has been closed.");
          setDeleteCartModal(!deleteCartModal);
        }}
      >
        <View style={styles.centeredView}>
          <View style={styles.modalViewPopup}>
            <View
              style={{
                width: "100%",
                alignItems: "flex-end",
                borderWidth: 0,
              }}
            >
              <TouchableOpacity style={[{paddingTop:13,paddingRight:13}]} onPress={() => setDeleteCartModal(!deleteCartModal)}>
                <Image
                   style={{ width: 30, height: 30 }}
                  source={require("../../assets/images/cross.png")}
                />
              </TouchableOpacity>
            </View>
            <View style={{ justifyContent:'space-around',alignItems:'center',borderWidth:0,flex:1,width:'100%',gap:25}}>
            <Text style={[styles.modalText,{marginBottom:0,width: isMobileView() && "80%" }]}> Are you sure you want to remove the magazine from the cart?</Text>

              <View style={{width:'100%',flexDirection:'row',justifyContent:'space-evenly'}}>
                <TouchableOpacity onPress={()=>{ setDeleteCartModal(!deleteCartModal)}} style={{
                borderWidth: 2,
                borderRadius: 7,
                backgroundColor: '#fff',
                width: isMobileView() ? responsiveWidth(30) : responsiveWidth(10),
                height: responsiveHeight(5),
                alignItems: 'center',
                justifyContent: 'center',
                borderColor: 'rgba(0, 0, 0, 0.15)',
                }}>
                  <Text
                  style={{color:Color.BLACKWHITE,fontWeight:'500'}}
                  >Cancel</Text>
                </TouchableOpacity>
                <TouchableOpacity onPress={()=>{removeFromCart(memberCode)}} style={{  borderWidth: 2,
                padding: 10,
                borderRadius: 7,
                backgroundColor: '#F24D14',
                width: isMobileView() ? responsiveWidth(30) : responsiveWidth(10),
                height: responsiveHeight(5),
                alignItems: 'center',
                justifyContent: 'center',
                borderColor: '#F24D14',}}>
                  <Text
                  style={{color: Color.WHITE,fontWeight:'500' }}
                  >Delete</Text>
                </TouchableOpacity>
              </View>

            </View>








          </View>
        </View>
      </Modal>

    {/* MODAL OF EDIT AREA */}

    {/* MODAL OF EDIT AREA */}
    {/* <TextInput
        style={{height:0}}
        placeholder="CVV"
      />
    <TextInput
        style={{height:0}}
        placeholder="CVV"
      />
    <TextInput
        style={{height:0}}
        placeholder="CVV"
      />
    <TextInput
        style={{height:0}}
        placeholder="CVV"
      />
    <TextInput
        style={{height:0}}
        placeholder="CVV"
      />
    <TextInput
        style={{height:0}}
        placeholder="CVV"
      />
    <TextInput
        style={{height:0}}
        placeholder="CVV"
      />
    <TextInput
        style={{height:0}}
        placeholder="CVV"
      />
    <TextInput
        style={{height:0}}
        placeholder="CVV"
      />
    <TextInput
        style={{height:0}}
        placeholder="CVV"
      />
    <TextInput
        style={{height:0}}
        placeholder="CVV"
      />
    <TextInput
        style={{height:0}}
        placeholder="CVV"
      />
    <TextInput
        style={{height:0}}
        placeholder="CVV"
      />
    <TextInput
        style={{height:0}}
        placeholder="CVV"
      />
    <TextInput
        style={{height:0}}
        placeholder="CVV"
      />
    <TextInput
        style={{height:0}}
        placeholder="CVV"
      /> */}

         </>) : (<>
          <DataNotFound height={35} width={35} fontSize={1.50} text={"Magazines Not Found In Cart"} textColor={Color.BLACK}  />

         </>)
        }

  </ScrollView>
    )
    :

    (<SafeAreaView style={{
      width:'100%',
      height:'100%',
      justifyContent:'center',
      alignItems:'center',
      backgroundColor:Color.WHITE,
      margin:'auto'
      }}>
      <ActivityIndicator size='large' />
      <Text style={{fontSize:15,fontWeight:'700',color:'#b4b4b4'}}>Please Wait ...</Text>


    </SafeAreaView>)}
    <View style={{position:"absolute",width:"100%"}}>
    {error.text2 !== '' && ( <PopupComponent
        style={{ zIndex: 100 }}
        hideFun={() => {
          dispatchError("")
          dispatchSuccess("")
        }
        }
        type={error.type}
        text1={error.text1}
        text2={error.text2}
      />
    )}
    </View>


    </> );
};

export default Cartpage;

const styles = StyleSheet.create({
  subscribedNextPrev: {
    borderWidth: 0,
    marginHorizontal: !isMobileView() && !isTabletView() && 45 || !isMobileView() && isTabletView() && 20 || isMobileView() && 0,
    margin: !isMobileView() ? 20 : 16,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  head: { height: responsiveHeight(6), backgroundColor: "#fff" },
  text: {
    margin: 6,
    marginLeft: 15,
    fontWeight: 600,
    fontSize: isMobileView() ? responsiveFontSize(1.9)  : responsiveFontSize(1),
  },
  textHead: {
    margin: 6,
    marginLeft: 15,
    fontWeight: 500,
    fontSize: responsiveFontSize(1.1),
    color: "rgba(0, 0, 0, 0.60)",
  },

  container: { flex: 1,  backgroundColor: '#fff' },
  head: { height: responsiveHeight(8), backgroundColor: '#fff' },
  text: { margin: 6, marginLeft:15, fontWeight:600,  fontSize: isMobileView() ? responsiveFontSize(1.9)  : responsiveFontSize(1)},
  textHead: { margin: 6, marginLeft:15, fontWeight:500,  fontSize: isMobileView() ? responsiveFontSize(2) : responsiveFontSize(1.1),color:"rgba(0, 0, 0, 0.60)"},
  tableRow:{
    borderWidth:1,
    borderColor:"rgba(0, 0, 0, 0.15)",
    marginVertical:8,
    borderRadius:6,
    paddingRight: isMobileView() && 25,
    height: isOnlyLaptopView() ? responsiveHeight(10) : responsiveHeight(9),
    flexDirection:"row"
  },
  Linecontainer: {
    height: '100%', // Adjust the height percentage as needed
    justifyContent: 'center', // Center the line vertically within its parent
  },
  line: {
    width: 2,
    height: '100%', // Set the line's height to 100% of its parent
    backgroundColor: 'rgba(0, 0, 0, 0.15)',
  },
  membersSearchArea: {
    // flex:1,
    borderBottomWidth: 1,
    borderColor: "rgba(0, 0, 0, 0.15)",
    justifyContent: "center",
    height: responsiveHeight(14.5),
    alignItems: "center",
    // width:'100%'
  },
  membersSearchAreaContainer: {
    borderWidth: 0,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "95%",
  },
  memberContainer: {
    // flex:,
    borderWidth: 0,
    width: "100%",
    // height: "100%",
  },
  subscribedNextPrev: {
    borderWidth: 0,
    marginHorizontal: !isMobileView() && !isTabletView() && 45 || !isMobileView() && isTabletView() && 20 || isMobileView() && 0,
    margin: !isMobileView() ? 20 : 16,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  modalButtonContainer: {
    backgroundColor: "#FFF",
    position: "absolute",
    top: "110%",
    right: "-30%",
    borderRadius: 10,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.15,
    shadowRadius: 6,
    elevation: 1,
  },
  modalViewPopup: {
    borderWidth: 0,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    width: isMobileView() ? responsiveWidth(85) : responsiveWidth(35),
    height: isMobileView() ? responsiveHeight(30) : responsiveHeight(35),
    margin: 5,
    backgroundColor: "#fff",
    borderRadius: 8,

    shadowColor: "none",


    shadowRadius: 4,

  },
  modalButton: {
    paddingVertical: 10,
    alignItems: "center",
    justifyContent: "flex-start",
    gap: 5,
    paddingHorizontal: 20,
    zIndex: 4,
    borderRadius: 5,
  },
  languageButton: {
    fontFamily: "openSans",
    fontWeight: "600",
    fontSize: 16,
    // padding: 10,
    borderRadius: 8,
    color: Color.BLUE_MEDIUM,
    // width: isMobileView() ? 100 : 120,
    textAlign: "center",
  },
  activeLanguageButton: {
    // backgroundColor: Color.BLUE_LIGHT,
    // shadowColor: 'rgba(0, 0, 0, 0.25)',

    // borderRadius: 8,
  },
  magCard: {
    width: isMobileView() && "100%" || !isMobileView() && isTabletView() && "48%" ||  isSmallLaptop() && "32%" ||  responsiveWidth(21.3),
    height: isMobileView() ? responsiveHeight(9)  :    responsiveHeight(14),
    borderRadius: 8,
    paddingVertical: 7,
    alignItems: "flex-start",
    flexDirection: "row",

  },
  selectedMagCard:{
    borderWidth:  2,
    backgroundColor: "rgba(92, 66, 218, 0.05)",
    borderColor:Color.BLUE_MEDIUM

  },
  unSelectedMagCard:{
    shadowColor: 'rgba(0, 0, 0, 0.15)',
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 1,
    shadowRadius: isMobileView() ? 5 : 10,
    backgroundColor: "#FFF"

  },
  head: { height: responsiveHeight(6), backgroundColor: '#fff' },
  text: { margin: 6, marginLeft:15, fontWeight:600,fontSize: isMobileView() ? responsiveFontSize(1.9)  : responsiveFontSize(1.1),display:"flex",gap:25 },
  textHead: { margin: 6, marginLeft:15, fontWeight:500,fontSize: isMobileView() ? responsiveFontSize(2) : responsiveFontSize(1.2),color:"rgba(0, 0, 0, 0.60)",},

  modalView: {
    // position:"relative",
    flex: 1,
    backgroundColor: "white",
    borderRadius: !isMobileView() && 20,

    alignItems: "center",
    shadowColor: Color.BLACK,
    marginTop: isOnlyLaptopView() && 25 || isOnlyTabletView() && 20,
    marginBottom: isOnlyLaptopView() && 25 || isOnlyTabletView() && 20,

    flexDirection: isMobileView() ? "column" : "row",
    width:  isMobileView()  && "100%" ||  isOnlyTabletView() && "95%" ||isOnlyLaptopView() && "70%",
    height:  isMobileView()  && "100%" ||  isOnlyTabletView() && "95%" ||isOnlyLaptopView() && "60%",

  },
  modalViewError: {
    borderWidth: 0,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    width: responsiveWidth(30),
    height: responsiveHeight(50),
    margin: 5,
    backgroundColor: "#fff",
    borderRadius: 8,
    padding: 25,
    shadowColor: "none",


    shadowRadius: 4,

  },
  centeredView: {
    flex: 1,
    position:"relative",
    justifyContent: "center",
    alignItems: "center",
    width:'100%',
    height:'100%',
    overflow: !isMobileView() && "hidden",
    alignSelf:'center',
    backdropFilter: 'blur(20px)',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
  centeredViewError: {
    flex: 1,
    backdropFilter: 'blur(20px)',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    justifyContent: "center",
    alignItems: "center",
  },
  modalText: {

    fontFamily:"openSans",
    fontWeight: "700",
    textAlign:"center",
    fontSize: isMobileView() ? responsiveFontSize(2.2) : responsiveFontSize(1.5),
  },
  textBoxView: {
    width: "100%",

    margin: 2.5,
    height: isMobileView() && isTabletView()  ? "fit-content" : "15%",
    flexDirection:"row",
    justifyContent: "space-between",
    padding: 0.5,
    gap:15
  },
  textboxInput: {
    width: "100%",
    height: isMobileView() && isTabletView()  ? responsiveScreenHeight(6.5) : "50%",
    textOverflow: "ellipsis",
    textWrap: "nowrap",
    marginTop:10,
    backgroundColor: Color.WHITE,
    opacity: 0.5,
    cursor: "default",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 10,
    borderWidth: 1,
    borderColor: "rgba(0, 0, 0, 0.14)",
    color: "rgba(0, 0, 0,)",
    fontSize: isMobileView()  ? responsiveFontSize(2.2) :  responsiveFontSize(1.1),
    textAlign: "left",
    padding: 10,

    fontWeight: "600",
},
Linecontainer: {
  height: '100%', // Adjust the height percentage as needed
  justifyContent: 'center', // Center the line vertically within its parent
},
line: {
  width: 2,
  height: '100%', // Set the line's height to 100% of its parent
  backgroundColor: 'rgba(0, 0, 0, 0.15)',
},
messageContainer : {
  position: "absolute",
  backgroundColor: Color.WHITE,
  borderWidth:0,
  borderColor: Color.BLUE_MEDIUM,
  borderRadius:8,
  alignItems:"center",
  justifyContent:"center",
  // height:"20%",
  paddingBottom:12,
  paddingTop:6,
  paddingHorizontal:15,
  gap:5,
  top: 0,
  left: "50%",
  transform: [
    { translateX: "-50%" }, // Translate the view horizontally by -50% of its own width
    { translateY: "0%" }, // Translate the view vertically by -50% of its own height
  ],
  zIndex:2,
  borderTopWidth:0,
  borderTopLeftRadius:0,
  borderTopRightRadius:0,
  shadowColor: 'rgba(99, 99, 99, 0.2)',
  shadowOffset: {
    width: 0,
    height: 2,
  },
  shadowRadius: 8,
  shadowOpacity: 1,
  elevation: 5, // This is for Android shadow
},
messageText:{
  textAlign:"center",
  fontWeight:"500"
}
});

