// import { createStackNavigator } from '@react-navigation/stack';
// import Magzinepage from '../screens/Magzinepage';
// import Profilepage from '../screens/Profilepage';
// import Cartpage from '../screens/Cartpage';
// import Helpdesk from '../screens/Helpdesk';
// import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
// import SignOutScreen from '../screens/auth/SignOutScreen';
// import { responsiveHeight, responsiveWidth } from 'react-native-responsive-dimensions';
// import { View,Image,Text } from 'react-native';
// import HomepageStack from './MagzinepageStack';
// const Tab = createBottomTabNavigator();
// import { Color, isMobileView, isTabletView } from '../Common/Constants';
// import MagzinepageStack from './MagzinepageStack';

// const Dashboard = ({navigation}) => {

//   const TabBarIcon = ({ focused, iconName, pageName }) => {

//     return (
//       <View style={{ alignItems: 'center', justifyContent: 'center',gap:4 }}>
//         <View style={{width: isMobileView() ?  responsiveWidth(12) : responsiveWidth(3),height: isMobileView() ? responsiveHeight(1.6) : responsiveHeight(2),backgroundColor:focused ? Color.BLUE_MEDIUM : Color.WHITE,borderRadius:100,position:'absolute',top:-20,left:null,right:null}}></View>
//         <Image
//           source={iconName} // Assuming iconName is the image source
//           resizeMode="contain"
//           style={{
//             width: 26,
//             height: 26,
//             tintColor: focused ? '#000' : '#b4b4b4', // Customize the icon color
//           }}
//         />
//           <Text style={{ color: focused ? '#000' : '#b4b4b4',fontWeight:"600",fontSize: isMobileView() ? 14 :  16}}>{pageName} </Text>
//       </View>
//     );
//   };



//   return (


//     <Tab.Navigator
//     initialRouteName='Magzinepage'
//       screenOptions={{headerShown:false,
//       tabBarStyle:{
//         height:  75,
//         overflow:'hidden',
//         width: !isMobileView() && !isTabletView() && "60%" || isTabletView() && !isMobileView() &&  "80%" || isMobileView() && "100%",
//         top:null,
//         right: null,
//         left: null,
//         bottom: !isMobileView() ? 16 : 0,
//         alignSelf: 'center',
//         backgroundColor: Color.WHITE,
//         position: 'absolute',
//         borderWidth:0,
//         borderTopColor:'transparent',
//         borderRadius:!isMobileView() && 100,
//         elevation: 4,
//         shadowColor: 'rgba(0, 0, 0, 0.1)',
//         shadowOffset: {
//           width: 0,
//           height: isMobileView() ? -2 : 4,
//         },
//         shadowRadius: 12,
//       },
//       tabBarLabelStyle: {
//         fontSize: 16,
//         display:'none',
//         flexDirection:'column',
//       },

//       }

//     }
//     >


// <Tab.Screen name="SignOutScreen"  options={{
//       unmountOnBlur:true,
//     tabBarLabel: 'Signout',
//     tabBarIcon: ({ focused }) => (
//       <TabBarIcon
//         iconName={focused ? require('../../assets/images/logoutSolid.png') : require('../../assets/images/logout.png') } // Replace with the actual image path
//         focused={focused}
//         pageName="Logout"
//       />
//     ),
//   }}  component={SignOutScreen} />

//     <Tab.Screen name="Magzinepage"       options={{
//     tabBarLabel: 'Magazine',
//     unmountOnBlur:true,
//     gestureEnabled: true, // Disable gesture for scrolling
//     tabBarIcon: ({ focused }) => (
//       <TabBarIcon
//         iconName={focused ? require('../../assets/images/magazineMenuSolid.png') : require('../../assets/images/magazineMenu.png') } // Replace with the actual image path
//         focused={focused}
//         pageName="Magazine"
//       />
//     ),
//   }}  component={Magzinepage} />

//     <Tab.Screen name="Profilepage"  options={{
//     tabBarLabel: 'Profilepage',
//     unmountOnBlur:true,
//     tabBarIcon: ({ focused }) => (
//       <TabBarIcon
//         iconName={focused ? require('../../assets/images/profileSolid.png') : require('../../assets/images/profile.png') } // Replace with the actual image path
//         focused={focused}
//         pageName="Profile"
//       />
//     ),
//   }}   component={Profilepage} />

//     <Tab.Screen name="Helpdesk"  options={{
//     tabBarLabel: 'Helpdesk',
//     unmountOnBlur:true,
//     tabBarIcon: ({ focused }) => (
//       <TabBarIcon
//         iconName={focused ? require('../../assets/images/helpSolid.png') : require('../../assets/images/help.png') } // Replace with the actual image path
//         focused={focused}
//         pageName="Help"
//       />
//     ),
//   }}  component={Helpdesk} />

//     <Tab.Screen name="Cartpage"  options={{
//     tabBarLabel: 'Cartpage',
//     unmountOnBlur:true,
//     tabBarIcon: ({ focused }) => (
//       <TabBarIcon
//         iconName={focused ? require('../../assets/images/cartSolid.png') : require('../../assets/images/cart.png') } // Replace with the actual image path
//         focused={focused}
//         pageName="Cart"
//       />
//     ),
//   }}  component={Cartpage} />

//    {/* <Tab.Screen name="SignOutScreen" options={{}} component={} /> */}
//   </Tab.Navigator>

//   );
// }
// export default Dashboard


// Previous Changed Flow:

import { createStackNavigator } from '@react-navigation/stack';
import Magzinepage from '../screens/Magzinepage';
import Profilepage from '../screens/Profilepage';
import Cartpage from '../screens/Cartpage';
import Helpdesk from '../screens/Helpdesk';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import SignOutScreen from '../screens/auth/SignOutScreen';
import { responsiveHeight, responsiveWidth } from 'react-native-responsive-dimensions';
import { View,Image,Text } from 'react-native';
import { useEffect, useState , useContext} from 'react';
import Badge from '../Common/BadgeCart';
import HomepageStack from './MagzinepageStack';
const Tab = createBottomTabNavigator();
import { Color, isMobileView, isTabletView } from '../Common/Constants';
import MagzinepageStack from './MagzinepageStack';
import { AuthContext } from '../context/AuthContext';
const Dashboard = ({navigation}) => {
  const value = useContext(AuthContext);
  
  const TabBarIcon = ({ focused, iconName, pageName }) => {

    return (
      <View style={{ alignItems: 'center', justifyContent: 'center',gap:4 }}>
        <View style={{width: isMobileView() ?  responsiveWidth(12) : responsiveWidth(3),height: isMobileView() ? responsiveHeight(1.6) : responsiveHeight(2),backgroundColor:focused ? Color.BLUE_MEDIUM : Color.WHITE,borderRadius:100,position:'absolute',top:-20,left:null,right:null}}></View>
        <Image
          source={iconName} // Assuming iconName is the image source
          resizeMode="contain"
          style={{
            width: 26,
            height: 26,
            tintColor: focused ? '#000' : '#b4b4b4', // Customize the icon color
          }}
        />
          <Text style={{ color: focused ? '#000' : '#b4b4b4',fontWeight:"600",fontSize: isMobileView() ? 14 :  16}}>{pageName} </Text>
      </View>
    );
  };



  return (


    <Tab.Navigator
    initialRouteName='MagzinepageStack'
      screenOptions={{headerShown:false,
      tabBarStyle:{
        height:  75,
        overflow:'hidden',
        width: !isMobileView() && !isTabletView() && "60%" || isTabletView() && !isMobileView() &&  "80%" || isMobileView() && "100%",
        top:null,
        right: null,
        left: null,
        bottom: !isMobileView() ? 16 : 0,
        alignSelf: 'center',
        backgroundColor: Color.WHITE,
        position: 'absolute',
        borderWidth:0,
        borderTopColor:'transparent',
        borderRadius:!isMobileView() && 100,
        elevation: 4,
        shadowColor: 'rgba(0, 0, 0, 0.1)',
        shadowOffset: {
          width: 0,
          height: isMobileView() ? -2 : 4,
        },
        shadowRadius: 12,
      },
      tabBarLabelStyle: {
        fontSize: 16,
        display:'none',
        flexDirection:'column',
      },

      }

    }
    >


<Tab.Screen name="SignOutScreen"  options={{
      unmountOnBlur:true,
    tabBarLabel: 'Signout',
    tabBarIcon: ({ focused }) => (
      <TabBarIcon
        iconName={focused ? require('../../assets/images/logoutSolid.png') : require('../../assets/images/logout.png') } // Replace with the actual image path
        focused={focused}
        pageName="Logout"
      />
    ),
  }}  component={SignOutScreen} />

    <Tab.Screen name="MagzinepageStack"       options={{
    tabBarLabel: 'Magazine',
    unmountOnBlur:true,
    gestureEnabled: true, // Disable gesture for scrolling
    tabBarIcon: ({ focused }) => (
      <TabBarIcon
        iconName={focused ? require('../../assets/images/magazineMenuSolid.png') : require('../../assets/images/magazineMenu.png') } // Replace with the actual image path
        focused={focused}
        pageName="Magazine"
      />
    ),
  }}  component={MagzinepageStack} />

    <Tab.Screen name="Profilepage"  options={{
    tabBarLabel: 'Profilepage',
    unmountOnBlur:true,
    tabBarIcon: ({ focused }) => (
      <TabBarIcon
        iconName={focused ? require('../../assets/images/profileSolid.png') : require('../../assets/images/profile.png') } // Replace with the actual image path
        focused={focused}
        pageName="Profile"
      />
    ),
  }}   component={Profilepage} />

    <Tab.Screen name="Helpdesk"  options={{
    tabBarLabel: 'Helpdesk',
    unmountOnBlur:true,
    tabBarIcon: ({ focused }) => (
      <TabBarIcon
        iconName={focused ? require('../../assets/images/helpSolid.png') : require('../../assets/images/help.png') } // Replace with the actual image path
        focused={focused}
        pageName="Help"
      />
    ),
  }}  component={Helpdesk} />

    <Tab.Screen name="Cartpage"  options={{
    tabBarLabel: 'Cartpage',
    unmountOnBlur:true,
    tabBarIcon: ({ focused }) => (
      // <View>
      <TabBarIcon
        iconName={focused ? require('../../assets/images/cartSolid.png') : require('../../assets/images/cart.png') } // Replace with the actual image path
        focused={focused}
        pageName="Cart"
      />

    ),
  }}  component={Cartpage} />

   {/* <Tab.Screen name="SignOutScreen" options={{}} component={} /> */}
  </Tab.Navigator>

  );
}
export default Dashboard
