import { StyleSheet, Text, View, Image,FlatList } from "react-native";
import React, { useState,useEffect, useContext, } from "react";
import {
    responsiveHeight,
    responsiveWidth,
    responsiveFontSize
  } from "react-native-responsive-dimensions";
import AppLoading from "expo-app-loading";
import { useFonts } from "expo-font";
import MemberCard from "../Common/MemberCard";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { TouchableOpacity } from "react-native";
import { Toast } from "react-native-toast-message/lib/src/Toast";
import DataNotFound from "../Common/DataNotFound";
import { Modal } from "react-native-web";
import { AuthContext } from "../context/AuthContext";
import { Value } from "react-native-reanimated";
import { Color } from "../Common/Constants";
import Hoverable from "react-native-hoverable";
import Headerdiv from "../Common/Headerdiv";
import { getFocusedRouteNameFromRoute } from "@react-navigation/native";
let USERCOUNT
let memberData = []


const Memberlist = ({navigation,route}) => {
  const { getMembersList,signOut,setMemeberCode } = useContext(AuthContext);

  const [memberList,setMemberList] = useState({})
  const [flag,setFlag] = useState(false)
  const [flagOfmember,setFlagOfmember] = useState()

  const updateMemberList =(data)=>{

    const value = [...data].sort((a, b) => {
      if (a.verify_status === "verified" && b.verify_status !== "verified") {
        return -1; // 'a' comes before 'b'
      } else if (a.verify_status !== "verified" && b.verify_status === "verified") {
        return 1; // 'b' comes before 'a'
      } else {
        return 0; // Order remains unchanged
      }
    });
    setMemberList(value)
    USERCOUNT = value.length 
  }

  // const sortedMemberList =

  const renderMemberCards = ({ item }) => {

    if (!item) {
      return null;
    }
  
  
    

      return (
        <MemberCard
        member={item}
        key={item?.member_id}
        nav={navigation}
        updateMemberList={updateMemberList}
        />
        
        );
    }
    
  useEffect(() => {
    const previousScreen = getFocusedRouteNameFromRoute(route);
  
    // Log the previous screen's name

  
    // ... rest of your component
   setTimeout(async() => {
    

    getMembersList().then(async(value)=>{

      if(value.patrika_sahayak)
      {
       await AsyncStorage.setItem('patrika_sahayak',true)
       await AsyncStorage.setItem('super_member_code' ,value?.member_code)
       await AsyncStorage.setItem('super_member_token',value?.token)
       navigation.navigate("Dashboard", { screen:'Magzinepage', params:{ memberCode: value?.member_code}});
      }
      // NEW CODE
      // setTimeout(async () => {
      //   setMemeberCode(value?.member_code)
      //  await AsyncStorage.setItem('super_member_code' ,value?.member_code)

      //   await AsyncStorage.setItem('memberToken' ,value?.token)
      //   navigation.navigate("Dashboard", { screen:'Magzinepage', params:{ memberCode: value?.member_code}});
      // }, 0);
  
      // setMemberList(value)
    })
      // if (value.length > 0 && value[0].patrika_sahayak) {
      //   setFlagOfmember(true)
      //   const data2 = [value[0]]
      //   AsyncStorage.setItem('patrika_sahayak',true)
      //   const data = [...value].sort((a, b) => {
      //     if (a.verify_status === "verified" && b.verify_status !== "verified") {
      //       return -1; // 'a' comes before 'b'
      //     } else if (a.verify_status !== "verified" && b.verify_status === "verified") {
      //       return 1; // 'b' comes before 'a'
      //     } else {
      //       return 0; // Order remains unchanged
      //     }
      //   });
      //   setMemberList(data)
      //   USERCOUNT = value.length
      // }
      
      
      // else{
      //   AsyncStorage.setItem('patrika_sahayak',false)

      // const data = [...value].sort((a, b) => {
      //   if (a.verify_status === "verified" && b.verify_status !== "verified") {
      //     return -1; // 'a' comes before 'b'
      //   } else if (a.verify_status !== "verified" && b.verify_status === "verified") {
      //     return 1; // 'b' comes before 'a'
      //   } else {
      //     return 0; // Order remains unchanged
      //   }
      // });
    
     // USERCOUNT = value.length
    },1500)

  },[flag])






  let [fontsLoaded] = useFonts({
    'openSans' : require('../../assets/fonts/OpenSans-VariableFont_wdth,wght.ttf')
  })
  if(!fontsLoaded){
    return <AppLoading/>
  }

    return (
        <View style={styles.container}>
            {/* <Headerdiv/> */}
            <Headerdiv />
            <View style={styles.selectMember}>
                <Text style={{fontFamily:'openSans',fontWeight:'600',fontSize:responsiveFontSize(2.2)}}>Select Member</Text>
                <Hoverable>
{({ hovered }) => (
                <TouchableOpacity
          onPress={() => signOut()

          }
          style={{
            justifyContent: "space-evenly",
            alignItems: "center",
            flexDirection: "row",
            borderRadius: 10,
            borderColor: Color.BLUE_MEDIUM,
            height: 40,
            width: 110,
            backgroundColor: hovered ? Color.BLACK : Color.BLUE_MEDIUM,
          }}
        >
          <Image
            style={{
              height: 25,
              width: 25,
              resizeMode: "contain",
              borderWidth: 0,
            }}
            source={  require("../../assets/images/signOutWhite.png") }
          />
          <Text
            style={{
              fontFamily: "openSans",
              fontWeight: "600",
              fontSize: 15,
              color:    "white" ,
            }}
          >
            Logout
          </Text>
        </TouchableOpacity>
             )}
             </Hoverable>
            </View>
            <View style={{ backgroundColor: "#fff", width: "100%", height: "75%" }}>
                <View style={{borderWidth:0,paddingHorizontal:35,paddingVertical:20,paddingBottom:10,flexDirection:'colmun',gap:5}}>
                   <View style={{flexDirection:'row',gap:20}}>

                    <Text style={{fontFamily:'openSans',fontWeight:'400',fontSize:responsiveFontSize(1.9)}}>
                    Registered Members</Text>

                    <View style={{backgroundColor:'rgba(230, 232, 234, 1)',paddingVertical:10,paddingHorizontal:20,borderRadius:10}}>

                        <Text>{USERCOUNT}</Text>
                    </View>
                   </View>

                    <Text style={{fontFamily:'openSans',fontStyle:"italic",fontWeight:'400',fontSize:responsiveFontSize(1)}}>
                    Linked with your mobile number</Text>
                </View>
                <View style={styles.memberArea}>
  {/* {memberList.length > 0 ? (
    <>
      {memberList.map((member) => {
        // Filter members with patrika_sahayak set to true
        if (member.patrika_sahayak) {
          return (
            <MemberCard
            member={member}
            key={member?.member_id}
            nav={navigation}
            updateMemberList={updateMemberList}
            />
            );
          }
          
        return null;
        // Return null for members without patrika_sahayak
      })}
      {flagOfmember===true?(<></>):(
        <FlatList
        numColumns={5}
        keyExtractor={(item, index) => item?.member_id.toString()} // Convert the key to a string
        data={memberList || []} // Provide a default empty array if tempMember is undefined or null
        renderItem={renderMemberCards}
        columnWrapperStyle={{ flex: 1, gap: 20 }}
        contentContainerStyle={{
          borderWidth: 0,
          padding: 15,
          alignSelf: 'stretch',
          // justifyContent: 'space-between',
          gap: 20,
        }}
      />
      )}
      
    </>
  ) : (
    <DataNotFound height={35} width={35} fontSize={1.50} text={"Members Not Available"} textColor={"#000"} />
  )} */}
  { memberList.member_id &&
              <MemberCard
            member={memberList}
            key={memberList?.member_id}
            nav={navigation}
            updateMemberList={updateMemberList}
            patrikaFlag={false}
            />
  }
</View>



            </View>
            {/* <Modal
      animationType="fade"
      transparent={true}
      visible={modalVisible}
      onRequestClose={() => {
        Alert.alert('Modal has been closed.');
        setModalVisible(false);
      }}
    >
      <View style={styles.centeredView}>
        <View style={[styles.modalView,{justifyContent: unlinkSuccessful ?  "space-evenly": "space-between" }]}>
          <Image
            style={{
              height: responsiveHeight(15),
              width: responsiveWidth(15),
              resizeMode: 'contain',
            }}
            source={unlinkSuccessful ? require('../../assets/images/unlinkPopupDone.png') : require('../../assets/images/unlinkPopupIcon.png')}
          />

          {unlinkSuccessful ? (
            <Text style={{ fontFamily: 'openSans', fontWeight: '650', fontSize: responsiveFontSize(1.5), paddingHorizontal: 9, textAlign: 'center' }}>
              Unlink Successful
            </Text>
          ) : (
            <Text style={{ fontFamily: 'openSans', fontWeight: '600', fontSize: responsiveFontSize(1.2), paddingHorizontal: 9, textAlign: 'center' }}>
              Please click the 'Unlink' button to unlink{' '}
              <Text style={{ color: '#5C42DA' }}>Rahul Sharma</Text> from your mobile number
            </Text>
          )}
    { !unlinkSuccessful && (
          <View style={{ display: 'flex', flexDirection: 'row', gap: 20 }}>
            <TouchableOpacity
              style={{
                borderWidth: 2,
                borderRadius: 7,
                backgroundColor: '#fff',
                width: responsiveWidth(10),
                height: responsiveHeight(5),
                alignItems: 'center',
                justifyContent: 'center',
                borderColor: 'rgba(0, 0, 0, 0.15)',
              }}
              onPress={() => setModalVisible(false)}
            >
              <Text style={{ fontFamily: 'openSans', color: '#000', fontWeight: '600', fontSize: 15, textAlign: 'right' }}>Cancel </Text>
            </TouchableOpacity>

            <TouchableOpacity
              style={{
                borderWidth: 2,
                padding: 10,
                borderRadius: 7,
                backgroundColor: '#F24D14',
                width: responsiveWidth(10),
                height: responsiveHeight(5),
                alignItems: 'center',
                justifyContent: 'center',
                borderColor: '#F24D14',
              }}
              onPress={handleUnlinkClick}
            >
              <Text style={{ fontFamily: 'openSans', color: '#fff', fontWeight: '600', fontSize: 15, textAlign: 'right' }}>Unlink </Text>
            </TouchableOpacity>
          </View>
    )}
        </View>
      </View>
    </Modal> */}
        </View>
    );
};

export default Memberlist;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        width: "100%",
        height: "100%",
        overflow: 'hidden',
    },
    centeredView: {
      flex: 1,
      backdropFilter: 'blur(20px)',
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
      justifyContent: "center",
      alignItems: "center",
    },
    modalView: {
      borderWidth: 0,
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-between",
      width: responsiveWidth(30),
      height: responsiveHeight(50),
      margin: 5,
      backgroundColor: "#fff",
      borderRadius: 8,
      padding: 25,
      shadowColor: "none",


      shadowRadius: 4,

    },

    upperContainer: {
        backgroundColor: "#F8F9FA",
        width: "100%",
        height: responsiveHeight(10),
        justifyContent:'center',
        // alignItems:'center'
    },
    userProfileNotification:{
        borderWidth:0,
        height:'100%',
        justifyContent:'space-between',
        alignItems:'center',
        flexDirection:'row',
        padding:20
    },
    userProfileImage:{
        width:45,
        height:40,
        borderWidth:0,
        margin:20
    },
    selectMember:{
        backgroundColor: "#FFF",
        width: "100%",
        // height: "12%",
        padding:18,
        paddingHorizontal:35,
        justifyContent:'center',
        borderBottomWidth:2,
        borderColor:'rgba(0, 0, 0, 0.15)',
        flexDirection:'row',
        justifyContent:'space-between'
    },
    memberArea:{
        borderWidth:0,
        width:'100%',
        paddingHorizontal:20,
        alignSelf:"center",
        alignItems:'flex-start',
        height:'88%',
        justifyContent:'space-evenly',
        alignItems:'flex-start',
        flexDirection:'row'}

});
