import * as moment from 'moment';
const formatDates = (date) => {//used for format in 2023-12-11 to 11-12-2023
    const d = new Date(date);
    const day = d.getDate();
    const month = d.getMonth() ;
    const year = d.getFullYear();
    let monthArr = ["Jan","Feb","Mar",'Apr','May','Jun','Jul','Aug','Sep',"Oct",'Nov','Dec']
    return `${day}/${monthArr[month]}/${year}`;
  };

 const formatedDateWithMonth = (value)=>{ //used for 2023-12-01 format in mmm'yy 

    if(value!="" &&value!=undefined && value!=null){
    const date = new Date(value);
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const year = date.getFullYear().toString().slice(-2); // Extract the last two digits of the year.
    const month = months[date.getMonth()]; // Get the abbreviated month name.
    const formattedDate = `${month}'${year}`;
    return formattedDate
    }else{
        return '';
    }
 } 

 const formatedDateWithStandardFormat = (value)=>{ //1971-02-04 or 1971/02/04 to 04-Feb-1971

// Split the date string into its individual components.
if(value){
    let  [year, month, day] = ''
    if(value.includes('-')){
      [year, month, day] = value.split('-');
    }else{
        [year, month, day] = value.split('/');
    }

// Get the month name using the `locale.getMonthName()` method.
    const monthName = new Date(year, month - 1, day).toLocaleString('default', { month: 'short' });

// Format the date as `dd-mmm-yyyy`.
    const formattedDate = `${day}-${monthName}-${year}`;
    return formattedDate;
}
 }

const arrayReverseSort=(data,sortByTest)=>{
{
    data.sort((a, b) => {
        const dateA = new Date(a[sortByTest]);
        const dateB = new Date(b[sortByTest]);
        return dateB - dateA; // Reverse order
    });
    
    

    // data.sort(function(a,b){
    // a.sortByTest = moment(new Date(a.sortByTest )).format('DD-MM-YYYY');
    // b.sortByTest = moment(new Date(b.sortByTest )).format('DD-MM-YYYY');
    // return Number(new Date(b[sortByTest])) - Number(new Date(a[sortByTest]));
    // })
  }    
}
const arraySort=(data,sortByTest)=>{
  {
      data.sort((a, b) => {
          const dateA = new Date(a[sortByTest]);
          const dateB = new Date(b[sortByTest]);
          return dateA - dateB; // Reverse order
      });
      
      
  
      // data.sort(function(a,b){
      // a.sortByTest = moment(new Date(a.sortByTest )).format('DD-MM-YYYY');
      // b.sortByTest = moment(new Date(b.sortByTest )).format('DD-MM-YYYY');
      // return Number(new Date(b[sortByTest])) - Number(new Date(a[sortByTest]));
      // })
    }    
  }

  const getBulkDatesStartDate=(data)=>{
  {
  
    const year =data.year
    const month = data.month
    const firstDayOfMonth = moment({ year, month: month - 1 }).startOf('month').format('YYYY-MM-DD');
    return firstDayOfMonth
  }
  }
  const getBulkDatesEndDate = (data)=>{
    {
    const year =data.year
    const month = data.month
    const firstDayOfMonth = moment({ year, month: month - 1 }).startOf('month');

    // Add three months to the first day
    const lastDayOfThirdMonth = moment(firstDayOfMonth).add(2, 'months').endOf('month');

    // Get the last day of the month three months ahead
    const lastDayOfMonth = moment(lastDayOfThirdMonth).endOf('month').format('YYYY-MM-DD');
    return lastDayOfMonth
  }
}
  
const getMonth=(value)=>{
  if(value){
  let monthArray = ["Jan","Feb","Mar",'Apr','May','Jun','Jul','Aug','Sep',"Oct",'Nov','Dec'];
  let month =monthArray[value.month-1]
  return `${month}`+ value.year
  }
}

const formatDOBDate = (inputDate) => { //from Thu Jun 13 2019 00:00:00 GMT+0530 (India Standard Time)change this to  13-June-2019
  const date = new Date(inputDate);
  const day = date.getDate();
  const month = date.toLocaleString('default', { month: 'short' }); // Use 'short' option for short month name
  const year = date.getFullYear();
  
  return `${day}-${month}-${year}`;
};



  export {formatDates,formatedDateWithMonth,formatedDateWithStandardFormat,arrayReverseSort,getBulkDatesStartDate,getBulkDatesEndDate,getMonth,arraySort,formatDOBDate}