import { StyleSheet, Text, View,Modal,ActivityIndicator } from "react-native";
import React, {  useContext, useEffect, useState } from "react";
import { ScrollView } from "react-native";

import {
  responsiveFontSize,
  responsiveHeight,
  responsiveWidth,
  responsiveScreenHeight
} from "react-native-responsive-dimensions";
import commonStyles from "../styles/styles";
import { FlatList } from "react-native";
import { apiServices } from "../services/apiServices";
import AsyncStorage from "@react-native-async-storage/async-storage";
import ReceiptComponent from "../Common/ReceiptComponent";
import Headerdiv from "../Common/Headerdiv";
import AppLoading from "expo-app-loading";
import { useFonts } from "expo-font";
import { TouchableOpacity } from "react-native";
import { Image } from "react-native";
import { Color, isLargeLaptop, isMobileView, isTabletView,isOnlyTabletView } from "../Common/Constants";
import Hoverable from "react-native-hoverable";
import { Cell, Row, Table, TableWrapper } from "react-native-table-component";
import ErrorContext from "../context/ErrorContext";
import { Checkbox } from 'react-native-paper';
import { Linking } from "react-native";
import {API_URL} from "@env"
import {formatDates} from "../util/fomatedDate";

let totalPages
let startIndex
let endIndex
let currentPageData = []
const itemsPerPage = 4;
const UnpaidInvoices = ({ navigation }) => {
  const { error, dispatchError, dispatchSuccess } = useContext(ErrorContext);

  let COUNT;
  const { postUnpainInvoices,postPayUnpaidInvoicesAPI,viewProfile } = apiServices;
  const [memberCode, setMemberCode] = useState(null);
  const [invoicesUnpaid, setInvoicesUnpaid] = useState([]);
  const [loader, setLoader] = useState(1)
  const [profile, setProfile] = useState()
  const [payFromTotal, setPayFromTotal] = useState()
  const [redirection, setRedirection] = useState()
  const [thankYouPage, setThankYouPage] = useState(false);
  const postPayUnpaidInvoices = async (rowData) =>{
    let unPaidData;

    if(!rowData){
      // let name  = invoicesUnpaid
      // .filter(item => checkedItems.includes(item.id))
      // .map(item => item.item_name)
      // .join(', ');
      let checkedData = invoicesUnpaid
      .filter(item => checkedItems.includes(item.id))
      .flatMap(item => item.invoice_ids);
      // unPaidData = {
      //   "firstname": `${profile.member_name}`,
      //   "amount": totalAmount,
      //   "productinfo" : name ,
      //   "email" : `${profile.email}`,
      //   "unpaid_invoices" : checkedData
      // }
      let querParam = 'amount='+totalAmount;
    for(let item of checkedData)
    {
     querParam+='&unpaid_invoices[]='+item;
    }
    querParam+='&currency=inr'
    setTimeout(() => {
      window.location.replace(`${API_URL}`+'/ccavenue_transaction/pay_unpaid_invoice?'+`${querParam}`)
      setThankYouPage(!thankYouPage)
    }, 3000);

  
    }else{
      // unPaidData = {
      //   "firstname": `${profile.member_name}`,
      //   "amount": rowData?.amount,
      //   "productinfo" : rowData?.item_name
      //   ,
      //   "email" : `${profile.email}`,
      //   "unpaid_invoices" : rowData.invoice_ids
      // }

      let querParam = 'amount='+rowData?.amount;
    for(let item of rowData.invoice_ids)
    {
     querParam+='&unpaid_invoices[]='+item;
    }
    querParam+='&currency=inr'
    setTimeout(() => {
      window.location.replace(`${API_URL}`+'/ccavenue_transaction/pay_unpaid_invoice?'+`${querParam}`)
      setThankYouPage(!thankYouPage)
    }, 3000);

     

    }

    //   let data = await postPayUnpaidInvoicesAPI(memberCode,unPaidData)

    // setRedirection(data)

    }
    const goToURL = ()=>{
   
      Linking.openURL(redirection?.url)

    }
    useEffect(()=>{
      if(redirection){
        setTimeout(() => {
          goToURL()
          // setThankYouPage(!thankYouPage)
        }, 3000);
      }
    },[redirection])


    const getProfile = async () =>{
    let data = await viewProfile(memberCode)
    setProfile(data)
   
  }
  const checkSubscriptionType= (rowData)=>{
    if(rowData?.bulk_purchase)
    {
      return 'Bulk Purchase'
    }else{
      return 'Subscription'
    }
  }

  const tableHead = ["","Sr.No","Date","Subscriber Name","Subscriber Code","Magazine/Qty","Subscription Type","Dispatch Type", "Action"];


  const [currentPageData, setCurrentPageData] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const updateCurrentPageData = (page, data) => {
    const startIdx = (page - 1) * itemsPerPage;
    const endIdx = startIdx + itemsPerPage;
    return data.slice(startIdx, endIdx);
  };

  // Function to handle the "next" button click
  const handleNextPage = () => {
    const nextPage = Math.min(currentPage + 1, totalPages);
    setCurrentPage(nextPage);
    setCurrentPageData(updateCurrentPageData(nextPage, invoicesUnpaid)); // Update currentPageData
  };

  // Function to handle the "previous" button click
  const handlePreviousPage = () => {
    const prevPage = Math.max(currentPage - 1, 1);
    setCurrentPage(prevPage);
    setCurrentPageData(updateCurrentPageData(prevPage, invoicesUnpaid)); // Update currentPageData
  };

  // Function to handle clicking on a specific page number
  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    setCurrentPageData(updateCurrentPageData(pageNumber, invoicesUnpaid)); // Update currentPageData
  };
   var pageNumbers = [];

   // Generate an array of page numbers to display in pagination
   const getPageNumbers = () => {
    const range = 2; // Number of page numbers to show before and after the current page

    // Calculate the range of page numbers to show
    let start = Math.max(currentPage - range, 1);
    let end = Math.min(currentPage + range, totalPages);

    // Adjust the range if the current page is near the start or end
    const maxRange = 2 * range + 1;
    if (end - start < maxRange) {
      if (currentPage < totalPages / 2) {
        end = Math.min(end + maxRange - (end - start), totalPages);
      } else {
        start = Math.max(start - maxRange + (end - start), 1);
      }
    }

    // Add page numbers to the array
    for (let i = start; i <= end; i++) {
      pageNumbers.push(i);
    }

    return pageNumbers;
  };
  const statusPill = (data, index) => {

    return(
      <View
          style={{
            margin: 6,
             marginLeft:15,
            alignItems: "center",
            justifyContent: "center",
            flexDirection:"row",
            backgroundColor: data === "confirmed" ? "#EAF9F1" : "#FEEEE9",
            width:"80%",padding:5,
            borderRadius:50,

          }}
        >

          <Text style={[styles.text,{color:data=== "confirmed" ? "#2CC373" : "#F24D14",bottom:0,position:"relative",margin:0}]}>{data}</Text>
        </View>
    )};
    const viewButton = (rowData, index) => {

    return(
      <TouchableOpacity
      onPress={()=>{setPayFromTotal(false),postPayUnpaidInvoices(rowData),setThankYouPage(!thankYouPage)}}

      style={{
        margin: 6,
         marginLeft:15,

        cursor:"pointer",
        borderRadius:50,

      }}
      >
           <Text style={{color:Color.BLUE_MEDIUM,padding:8,borderRadius:10,fontWeight:'600',fontSize:responsiveFontSize(1.1)}}>Pay</Text>


    </TouchableOpacity>

    )};
  const [checkedItems, setCheckedItems] = useState([]); // Initialize an empty array for checked items

    const checkBoxButton = (rowData, index ) => {
      const isChecked = checkedItems.includes(rowData.id);

      const toggleCheckbox = () => {
        if (isChecked) {
          // If already checked, remove from checkedItems array
          setCheckedItems(checkedItems.filter((id) => id !== rowData.id));
        } else {
          // If not checked, add to checkedItems array
          setCheckedItems([...checkedItems, rowData.id]);
        }
      };
    

      return (
        <Checkbox

          status={isChecked ? 'checked' : 'unchecked'}
          onPress={toggleCheckbox}
          color= {Color.BLUE_MEDIUM} // Change the color of the checkbox

        />
      );
    }
  const dateItem = (data, index) => {
    return (
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          borderWidth: 0,
          height: "100%",
          width: "100%",
          margin: 6,
          marginLeft: 15,
          gap: 15,
        }}
      >
        <Image
          style={{
            height: responsiveHeight(4.2),
            width: responsiveWidth(2.2),
            resizeMode: "contain",
          }}
          source={require("../../assets/images/cal2.png")}
        />
        <Text
          style={{
            fontFamily: "openSans",
            fontWeight: "600",
            fontSize: responsiveFontSize(1),
          }}
        >
          {data}
        </Text>
      </View>
    );
  };
  const forItem = (data, index) => {
    return (
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          borderWidth: 0,
          height: "100%",
          width: "100%",
          margin: 6,
          marginLeft: 15,
          gap: 15,
        }}
      >
        <Image
          style={{
            height: responsiveHeight(4.2),
            width: responsiveWidth(2.2),
            resizeMode: "contain",
          }}
          source={require("../../assets/images/membersIcon.png")}
        />
        <Text
          style={{
            fontFamily: "openSans",
            fontWeight: "600",
            fontSize: responsiveFontSize(1),
          }}
        >
          {data}
        </Text>
      </View>
    );
  };
  const copiesItem = (data, index) => {
    return (
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          borderWidth: 0,
          height: "100%",
          width: "100%",
          margin: 6,
          marginLeft: 15,
          gap: 15,
        }}
      >
        <Image
          style={{
            height: responsiveHeight(4.2),
            width: responsiveWidth(2.2),
            resizeMode: "contain",
          }}
          source={require("../../assets/images/type.png")}
        />
        <Text
          style={{
            fontFamily: "openSans",
            fontWeight: "600",
            fontSize: responsiveFontSize(1),
          }}
        >
          {data}
        </Text>
      </View>
    );
  };
  const amountItem = (data, index) => {
    return (
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          borderWidth: 0,
          height: "100%",
          width: "100%",
          margin: 6,
          marginLeft: 15,
          gap: 15,
        }}
      >
        <Image
          style={{
            height: responsiveHeight(4.2),
            width: responsiveWidth(2.2),
            resizeMode: "contain",
          }}
          source={require("../../assets/images/tag.png")}
        />
        <Text
          style={{
            fontFamily: "openSans",
            fontWeight: "600",
            fontSize: responsiveFontSize(1),
          }}
        >
          ₹{data}
        </Text>
      </View>
    );
  };
  const actionItem = (data, index) => {
    return (
      <Hoverable>
        {({ hovered }) => (
          <TouchableOpacity
            onPress={() => setModalVisible(true)}
            style={[
              {
                paddingVertical: 2.5,
                paddingHorizontal: 5,
                borderRadius: 100,
                margin: 6,
                marginLeft: 15,
                with: "fit-content",
              },
            ]}
          >
            <Text
              style={{
                color: Color.BLUE_MEDIUM,
                fontWeight: "600",
                fontSize: responsiveFontSize(1),
              }}
            >
              {data}
            </Text>
          </TouchableOpacity>
        )}
      </Hoverable>
    );
  };

  const getMemberCodeX = async () => {
    let getMemberCode;
    try {
      getMemberCode = await AsyncStorage.getItem("member_code");
      setMemberCode(getMemberCode);
      setLoader((prev) => {
        return prev !== 0 ? prev - 1 : 0;
      });
    } catch (error) {
      dispatchError(error);
      setLoader((prev) => {
        return prev !== 0 ? prev - 1 : 0;
      });
    }
  };
  useEffect(() => {
    getMemberCodeX();
  }, []);
  useEffect(() => {
if(memberCode){
  getProfile()
}
  }, [memberCode]);


  const [totalAmount, setTotalAmount] = useState(0);
  const [selectedInvoices, setSelectedInvoices] = useState([]);

  // Calculate total amount when invoicesUnpaid or checkedItems change
  const calculteAmount =()=>
  {
    const amount = invoicesUnpaid?.reduce((total, obj) => {
      const amountToAdd = obj?.amount === undefined ? 0 : parseFloat(obj?.amount);
      return total + amountToAdd;
    }, 0);
    setTotalAmount(amount);
  }
  useEffect(() => {
    calculteAmount();
  }, [invoicesUnpaid,]);

  // Update selectedInvoices when checkedItems changes
  useEffect(() => {
    const filteredInvoices = invoicesUnpaid.filter((invoice) => checkedItems.includes(invoice.id));
    setSelectedInvoices(filteredInvoices);

    if(filteredInvoices.length>0){
    // Calculate total amount based on selectedInvoices
    const selectedAmount = filteredInvoices?.reduce((total, obj) => {
      const amountToAdd = obj?.amount === undefined ? 0 : parseFloat(obj?.amount);
      return total + amountToAdd;
    }, 0);
    setTotalAmount(selectedAmount);
  }else{
    calculteAmount();
  }

    // Update totalAmount with the selected amount

  }, [checkedItems]);
  // useEffect(() => {

  // }, [third])


  const getUnpaidInvoices = async () => {
    let data ={"ps_code":memberCode}
    let getBulkOrderData = await postUnpainInvoices(memberCode,data);
    // console.log(getReceiptsData)
  
    let count= 0;
    for(let item of getBulkOrderData.invoices){
      item.id = count;
      count++;
    }
    setInvoicesUnpaid(getBulkOrderData.invoices);
    setTotalAmount(getBulkOrderData.totalAmount)
    totalPages = Math.ceil(getBulkOrderData.invoices.length / itemsPerPage);
      startIndex = (currentPage - 1) * itemsPerPage;
      endIndex = Math.min(startIndex + itemsPerPage, getBulkOrderData.invoices.length);
      setCurrentPageData(getBulkOrderData.invoices.slice(startIndex, endIndex))

    };
    COUNT = invoicesUnpaid?.length;

  useEffect(() => {
    if (memberCode) {
      getUnpaidInvoices();
    }
  }, [memberCode]);

  let [fontsLoaded] = useFonts({
    openSans: require("../../assets/fonts/OpenSans-VariableFont_wdth,wght.ttf"),
  });
  if (!fontsLoaded) {
    return <AppLoading />;
  }
  const renderReceipts = ({ item }) => {
    return (
      <ReceiptComponent
        data={item}
        memberCode={memberCode}
        navigation={navigation}
      />
    );
  };

  return (
    <>
    {loader === 0 ?(<View
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: "#FFF",
        overflow: "hidden",
      }}
    >
      {/* <View style={{width:"100%",height:50,backgroundColor:'#F8F9FA',borderWidth:1}}>
        <Text>Dhan Nirankar Ji - Virat Kohli</Text>
      </View> */}
      <Headerdiv memberCode={memberCode} navigation={navigation} />
      <View
        style={{
          borderBottomWidth: 1,
          borderColor: "rgba(0, 0, 0, 0.15)",
          justifyContent: "center",
          paddingVertical: 18,
          alignItems: "center",
          width: "100%",
        }}
      >
        <View
          style={{
            borderWidth: 0,
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: isMobileView() ? "90%" : "96%",
            bottom: isMobileView() ? 3 : 0,
          }}
        >
          <Text
            style={{
              fontWeight: "600",
              fontSize: isTabletView() ? responsiveFontSize(2.6) :  responsiveFontSize(2.2),
            }}
          >
            Unpaid Invoices
          </Text>
          <View style={[commonStyles.gap1rem, commonStyles.flexRow]}>
          <Hoverable>
{({ hovered }) => (
        <TouchableOpacity
          style={{
            justifyContent: !isMobileView() && "space-evenly",
            gap: isMobileView() &&  10,
            paddingLeft: isMobileView() &&  10,
            alignItems: "center",
            flexDirection: "row",
            borderWidth: 2,
            borderRadius: 10,
            borderColor: Color.BLUE_MEDIUM,
            height: 40,
            width: isMobileView() ? 150 : 120,
            backgroundColor: hovered ? Color.BLUE_MEDIUM : "white",
          }}
          onPress={() => { navigation.navigate('Dashboard');
          }}
        >
          <Image
            style={{       height: 25,
              width: 25,
              resizeMode: "contain",
              borderWidth: 0,
              transform: [{ rotate: "180deg" }]
            }}
            source={ hovered ? require("../../assets/images/goBackWhite.png") : require("../../assets/images/goBack.png")}
          />
          <Text
            style={{
              fontFamily: "openSans",
              fontWeight: "600",
              fontSize: 15,
              color:  hovered ?  "white" : Color.BLUE_MEDIUM,
            }}
          >
            Go Back
          </Text>
        </TouchableOpacity>
             )}
             </Hoverable>
          </View>
        </View>
      </View>
      <View>
        <View style={styles.memberContainer}>
          <View
            style={[
              styles.subscribedNextPrev,
              !isMobileView() && commonStyles.pl_2,
              isMobileView() && commonStyles.width90,
              isMobileView() && commonStyles.alignSelfCenter,
            ]}
          >
            <View
              style={[
                commonStyles.flexRow,
                commonStyles.flex,
                commonStyles.justifyBetween,
                commonStyles.alignCenter,
                commonStyles.width100,
              ]}
            >
              <Text
              style={{ fontWeight: "400",   fontSize: !isMobileView()
              ? responsiveFontSize(2)
              : responsiveFontSize(2.4), }}
              >
                Invoices
              </Text>
              <View
                style={{
                  backgroundColor: "rgba(230, 232, 234, 1)",
                  paddingVertical: 8,
                  paddingHorizontal: !isMobileView() ? 20 : 14,
                  borderRadius: 10,
                }}
              >
                <Text
                  style={{
                    fontWeight: "500",
                    fontSize: !isMobileView() ?  responsiveFontSize(1) : responsiveFontSize(1.8),
                  }}
                >
                  {COUNT}{" "}
                </Text>
              </View>
            </View>
          </View>
          <View
            style={[
              commonStyles.spaceLeftPrimary,
              commonStyles.spaceRightPrimary,
              {
                borderWidth: 0,
                // alignSelf: "center",
                // marginVertical: 10,
                marginBottom: 10,
                paddingRight:0
              },

            ]}
          >
                  <View style={[styles.container,

]}>
            <ScrollView contentContainerStyle={{width:"100%"}}  style={{width:"100%"}} horizontal={!isLargeLaptop() && true      }>

          <Table style={{width: isMobileView() ? "135%" : "100%"}} borderStyle={{ borderWidth: 0, borderColor: '#c8e1ff' }}>
        <Row data={tableHead}   style={styles.head} textStyle={styles.textHead} />
        {invoicesUnpaid?.map((rowData, index) => (
      <TableWrapper key={index} style={[styles.tableRow,{backgroundColor: checkedItems.includes(rowData.id) ? Color.BLUE_LIGHT : Color.WHITE,  borderWidth: checkedItems.includes(rowData.id) ? 0 : 1, }]}>
          <Cell
            key={index}
            data={checkBoxButton(rowData, index)}
            textStyle={styles.text}
            style={{ flex: 1,justifyContent: 'center', alignItems: 'center' }}
            />
            <Cell
          data={index+1}
          textStyle={styles.text}
          style={{ flex: 1, justifyContent: 'center', alignItems: 'flex-start' }}
        />
                    <Cell
          data={formatDates(rowData?.date)}
          textStyle={styles.text}
          style={{ flex: 1, justifyContent: 'center', alignItems: 'flex-start' }}
        />

        <Cell
          data={rowData?.member_name}
          textStyle={styles.text}
          style={{ flex: 1, justifyContent: 'center', alignItems: 'flex-start' }}
        />

        <Cell
          data={rowData?.member_code}
          textStyle={styles.text}
          style={{ flex: 1, justifyContent: 'center', alignItems: 'flex-start' }}
        />
        <Cell data={rowData?.item_name} textStyle={styles.text} style={{ flex: 1 }} />
        <Cell data={checkSubscriptionType(rowData)} textStyle={styles.text} style={{ flex: 1 }} />
        <Cell data={rowData?.dispatch_type} textStyle={styles.text} style={{ flex: 1 }} />

            <Cell
            key={index}
            data={viewButton(rowData, index)}
            textStyle={styles.text}
            style={{ flex: 1, justifyContent: 'center' }}
            />
      </TableWrapper>
      //  <FilteredRow key={index} rowData={rowData} index={index} />
    ))}
          {/* </TableWrapper> */}
        {/* ))} */}
      </Table>
      </ScrollView>
</View>
      <View style={{ flexDirection: 'row', justifyContent: 'flex-end', marginTop: 10, alignItems:'center',borderWidth:0}}>

  <TouchableOpacity style={{borderRadius:7.13,cursor:pageNumbers<1 && 'pointer' ,borderColor:'rgba(0, 0, 0, 0.15)',borderWidth:2,height:30,width:30,justifyContent:'center',alignItems:'center'}}  onPress={handlePreviousPage}>
    <Image style={{ tintColor: currentPage > 1 ? Color.BLUE_MEDIUM:'',height:20,width:20,}} source={require('../../assets/images/nextPageIcon.png')}></Image>
  </TouchableOpacity>
  {/* Page numbers */}
  {getPageNumbers().map((pageNumber) => (
    <TouchableOpacity key={pageNumber} onPress={() => handlePageClick(pageNumber)}>
      <Text
        style={{
          fontSize: 15,
          marginHorizontal: 10,
          fontWeight: pageNumber === currentPage ? 'bold' : 'normal',
          cursor: 'pointer',
        }}
      >
        {pageNumber}
      </Text>
    </TouchableOpacity>
  ))}

  <TouchableOpacity style={{borderRadius:7.13,cursor:currentPage !== totalPages &&'pointer', borderColor:'rgba(0, 0, 0, 0.15)',borderWidth:2,height:30,width:30,justifyContent:'center',alignItems:'center'}} onPress={handleNextPage}>
    <Image style={{ tintColor: currentPage === totalPages ?  '' : Color.BLUE_MEDIUM,height:20,width:20,transform:[{rotate: '180deg'}]}} source={require('../../assets/images/nextPageIcon.png')}></Image>
  </TouchableOpacity>
</View>
<View style={{borderWidth:0,alignItems:'flex-end',marginTop:"5%"}}>
<Hoverable>
              {({ hovered }) => (

  <TouchableOpacity onPress={()=>{setPayFromTotal(true),postPayUnpaidInvoices(undefined),setThankYouPage(!thankYouPage)}} style={{backgroundColor: hovered ? Color.BLACK : Color.BLUE_MEDIUM, minWidth: isLargeLaptop() && 210 ,width:"100%",borderRadius:10,alignItems:"center"}}>
    <Text style={{color:Color.WHITE,fontWeight:'600',fontSize:17,padding:10,paddingHorizontal:25}}>
      Proceed To Pay ₹ {totalAmount}.00
    </Text>
    </TouchableOpacity>
              )}
  </Hoverable>
</View>
          </View>
        </View>
      </View>


      <Modal
      animationType="fade"
      transparent={true}
      visible={thankYouPage}
      onRequestClose={() => {
        Alert.alert("Modal has been closed.");
        setThankYouPage(!thankYouPage);
      }}
    >
      <View
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          width:'100%',
          height: '100%',
          alignSelf:'center',
          backdropFilter: 'blur(20px)',
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
        }}
      >
        <View
          style={{
            backgroundColor: "white",
            borderRadius: 15,

            alignItems: "center",
            shadowColor: Color.BLACK,
            marginTop: 35,
            marginBottom: 35,


            width: "80%",
            height: isMobileView() ? "50%" : "90%",
            backgroundColor: "rgba(248, 249, 250, 1)",
            alignItems: "center",
            borderRadius: 20,
            justifyBetween: "space-between",
          }}
        >
          <View
            style={{
              borderWidth: 0,
              width: "100%",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <View></View>

            <TouchableOpacity
              onPress={() => {
                setThankYouPage(!thankYouPage);
              }}
            >
              <Image
                style={{ width: 30, height: 30, top: 25, right: 25 }}
                source={require("../../assets/images/cross.png")}
              />
            </TouchableOpacity>
          </View>
          <Image
              style={{ width: isMobileView() && responsiveScreenHeight(17) || isOnlyTabletView() && responsiveScreenHeight(30)  || 280, height: isMobileView() && responsiveScreenHeight(17) || isOnlyTabletView() && responsiveScreenHeight(30)  || 280 }}
              source={require("../../assets/images/thankyou.png")}
            />
          <View style={{ width: "75%", alignItems: "center" }}>
            <Text style={{ fontSize: isMobileView() ? responsiveFontSize(2.5) : 45, fontWeight: "700" }}>
              Thank you, enjoy!{" "}
            </Text>
            <View style={{ width: isMobileView() ? "80%" : "50%",marginTop:15, alignItems: "center" }}>
              <Text
                style={{
                  fontSize: isMobileView() ? responsiveFontSize(2) : 20,
                  fontWeight: "600",
                  color: "rgba(0, 0, 0, 0.5)",
                  textAlign: "center",
                }}
              >
                Redirecting to Payment Gateway
              </Text>

              <View style={{top:50}}>
              <ActivityIndicator size="large" />
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "600",
                  color: "rgba(0, 0, 0, 0.5)",
                }}
              >
                Please wait...
              </Text>

              </View>
            </View>
          </View>
        </View>
      </View>
    </Modal>
    </View>
    
    
    ):(
        <SafeAreaView
          style={{
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: Color.WHITE,
            margin: "auto",
          }}
        >
          <ActivityIndicator size="large" />
          <Text style={{ fontSize: 15, fontWeight: "700", color: "#b4b4b4" }}>
            Please Wait ...
          </Text>
        </SafeAreaView>
      )}
    </>
  );
};

export default UnpaidInvoices;

const styles = StyleSheet.create({
  body: {},
  subscribedNextPrev: {
    borderWidth: 0,

    marginHorizontal: !isMobileView() && !isTabletView() && 45 || !isMobileView() && isTabletView() && 20 || isMobileView() && 0,
    margin: !isMobileView() ? 20 : 16,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  head: { height: responsiveHeight(6), backgroundColor: "#fff" },
  text: {
    margin: 6,
    marginLeft: 15,
    fontWeight: 600,
    fontSize: isMobileView() ? responsiveFontSize(1.9)  : responsiveFontSize(1.1),
  },
  textHead: {
    margin: 6,
    marginLeft: 15,
    fontWeight: 500,
    fontSize: isMobileView() ? responsiveFontSize(2) : responsiveFontSize(1.1),
    color: "rgba(0, 0, 0, 0.60)",
  },
  tableRow: {

    borderColor:  "rgba(0, 0, 0, 0.15)",
    marginVertical: 8,
    borderRadius: 6,
    height: responsiveHeight(12),
    flexDirection: "row",
    justifyContent:"space-between"
  },
  textStyle: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
  },
    container: { flex: 1,  backgroundColor: '#fff' },
});
