import {
  StyleSheet,
  Text,
  View,
  TextInput,
  Image,
  FlatList,
  ScrollView,
  Modal,
} from "react-native";
import React, { useContext, useEffect, useState } from "react";
import Headerdiv from "../Common/Headerdiv";
import {
  responsiveFontSize,
  responsiveHeight,
  responsiveWidth,
} from "react-native-responsive-dimensions";
import { TouchableOpacity } from "react-native-gesture-handler";
import TicketsComponent from "../Common/TicketsComponent";
import { apiServices } from "../services/apiServices";
import AsyncStorage from "@react-native-async-storage/async-storage";
import ErrorContext from "../context/ErrorContext";
import PopupComponent from "../Common/PopupComponent";
import commonStyles from "../styles/styles";
import Hoverable from "react-native-hoverable";
import { SafeAreaView } from "react-native";
import { ActivityIndicator } from "react-native";
import SelectDropdown from "react-native-select-dropdown";
import { Color, isMobileView, isOnlyLaptopView, isTabletView } from "../Common/Constants";
import SecondaryHeaderdiv from "../Common/SecondaryHeaderdiv";
import { Table, Row, Rows, Cell,TableWrapper } from 'react-native-table-component';
import { log } from "react-native-reanimated";
import { checkUserName } from "../Common/CheckUserFunc";
let totalPages
let startIndex
let endIndex
let currentPageData = []
const itemsPerPage = 4;
let tableDataRender = []
const Helpdesk = ({ route, navigation }) => {

  const [currentPage, setCurrentPage] = useState(1);
  const [tickets, setTickets] = useState([]);
  const [modalData, setModalData] = useState({});
  const [profileData, setProfileData] = useState({});
  const [relatedTo, setRelatedTo] = useState("");
  const [escalationMatrix, setEscalationMatrix] = useState("");
  const [issueType, setIssueType] = useState("");
  const [description, setDescription] = useState("");
  const [issueStatus, setIssueStatus] = useState("");
  const { getIssueTickets, issueTickets, viewProfile,getMasterHelpType,viewSubscribedMagazines } = apiServices;
  const [memberCode, setMemberCode] = useState(null);
  const [helpType, sethelpType] = useState();
  const { error, dispatchError, dispatchSuccess } = useContext(ErrorContext);
  const [loader, setLoader] = useState(5)
  const [subscribedMagazines, setSubscribedMagazines] = useState({})
  const [magazine, setMagazine] = useState()
  const [currentPageData, setCurrentPageData] = useState([])
  const [viewModalVisible, setviewModalVisible] = useState(false);
  const [activeFilter, setActiveFilter] = useState(true);
 const tableHead = ['Ticket Id', 'Date & Time', 'Related To', 'Login Issue' ,'Support Status', 'Action']


const [status, setStatusBackground] = useState({

 }

 )
 const formatDateTime = (dateTimeStr) => {
  const dateTime = new Date(dateTimeStr);
  const date = dateTime.toLocaleDateString();
  const time = dateTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

  return `${date}\n${time}`;
};



 const handleFilterChange = (data) => {
   setActiveFilter(data);
 };

  const getMemberCodeX = async () => {
    let getMemberCode;
    try {
      getMemberCode = await AsyncStorage.getItem("member_code");
      setMemberCode(getMemberCode);
      setLoader(prev => {
        return prev !== 0 ? prev - 1 : 0;
      })
    } catch (error) {
      dispatchError(error);
      setLoader(prev => {
        return prev !== 0 ? prev - 1 : 0;
      })
    }
  };


  const getHelpData = async () => {
    try {
      const data = await getMasterHelpType();
      sethelpType(data);
      setLoader(prev => {
        return prev !== 0 ? prev - 1 : 0;
      })
    } catch (error) {
      dispatchError(error);
      setLoader(prev => {
        return prev !== 0 ? prev - 1 : 0;
      })
    }
  };
  const getMagazines = async () => {
    try {
      const subscribedMagazinesData = await viewSubscribedMagazines(memberCode);
      setSubscribedMagazines(subscribedMagazinesData);
      setLoader(prev => {
        return prev !== 0 ? prev - 1 : 0;
      })
    } catch (error) {
      dispatchError(error);
      setLoader(prev => {
        return prev !== 0 ? prev - 1 : 0;
      })
    }
  };

 const renderDropdownIcon = ({ isOpen }) => (
    <View
      style={{
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Image
                style={{
                  height: 22,
                  width: 22,

                  resizeMode:'contain',

                }}
                source={ require( "../../assets/images/dropDown.png") }
              />
    </View>
  );
  const getTickets = async () => {
    try {
      const ticketsData = await getIssueTickets(memberCode);
      if (ticketsData) {
        if(typeof(ticketsData)=='object')
          setTickets(ticketsData);


      }

      setLoader(prev => (prev !== 0 ? prev - 1 : 0));
    } catch (error) {
      dispatchError(error);
      setLoader(prev => (prev !== 0 ? prev - 1 : 0));
    }
  };




  const getMemberCode = async () => {
    try {
      let viewMember = await viewProfile(memberCode);
      setProfileData(viewMember);
      setLoader(prev => {
        return prev !== 0 ? prev - 1 : 0;
      })
    } catch (error) {
      dispatchError(error);
      setLoader(prev => {
        return prev !== 0 ? prev - 1 : 0;
      })
    }
  };
  const addIssueTickets = async () => {
    let data = [{
      member_code:memberCode,
      member_name:profileData?.member_name,
      member_mobile:profileData?.mobile,
      member_email:profileData?.email,
      related_to:relatedTo,
      query:description,
      status:"open",
      escalation_matrix:escalationMatrix,
      issue_type:issueType,
      subscription_id:relatedTo =='Subscription'? magazine: undefined
    }]
    try {
      setLoader(1)
      await issueTickets(memberCode,data);
      setTimeout(() => {
        setLoader(prev => {
          return prev !== 0 ? prev - 1 : 0;
        })

        getTickets();
      }, 2000);

    } catch (error) {
      setLoader(prev => {
        return prev !== 0 ? prev - 1 : 0;
      })
      dispatchError(error);
    }
  };
const getStatusValue = (status)=>{
  if(status == 'close')
  {
    return 'closed'
  }
  return status
}
  useEffect(() => {
    if (memberCode) {
      checkUserName (navigation)
      setTimeout(() => {
        getMemberCode();
        getTickets();
      }, 1000);
    }
    return(()=>{
      dispatchError('')
    })
  }, [memberCode]);

  const renderTickets = ({ item }) => {
    return (
      <TicketsComponent
        data={item}
        memberCode={memberCode}
        navigation={navigation}
      />
    );
  };

  useEffect(()=>{
    if(activeFilter){
      if(currentPage>=pageNumbers.length){
        setCurrentPage(1)
      }
      startIndex =1
      tableDataRender =tickets.filter(rowData => rowData?.status === 'open')
      totalPages = Math.ceil(tableDataRender.length / itemsPerPage);
        startIndex = (1 - 1) * itemsPerPage;
        endIndex = Math.min(startIndex + itemsPerPage, tableDataRender.length);
        setCurrentPageData(tableDataRender.slice(startIndex, endIndex))
    }
    else{
      if(currentPage>=pageNumbers.length){
        setCurrentPage(1)
      }
      startIndex =1

      tableDataRender =tickets.filter(rowData => rowData?.status === 'close' || rowData?.status ==='')
      totalPages = Math.ceil(tableDataRender.length / itemsPerPage);
      startIndex = (1 - 1) * itemsPerPage;
      endIndex = Math.min(startIndex + itemsPerPage, tableDataRender.length);
      setCurrentPageData(tableDataRender.slice(startIndex, endIndex))
    }
  },[activeFilter,tickets])

  const statusPill = (data, index) => {

  return(
    <View
        style={{
          margin: 6,
           marginLeft:15,
          alignItems: "center",
          justifyContent: "center",
          flexDirection:"row",
          backgroundColor:data.status === "open" ? "#EAF9F1" : "#FEEEE9",
          width: isMobileView() ? "60%" : "40%",padding:5,
          borderRadius:50,

        }}
      >

        <Text style={[styles.text,{color:data.status === "open" ? "#2CC373" : "#F24D14",bottom:2,position:"relative",margin:0}]}>{data.status}</Text>
      </View>
  )};
  const viewButton = (rowData, index) => {

  return(
    <TouchableOpacity onPress={() => {setviewModalVisible(true), setModalData(rowData)}}
    style={{
      margin: 6,
       marginLeft:15,

      cursor:"pointer",
      borderRadius:50,

    }}
    >
    <Text style={[styles.text,{color:Color.BLUE_MEDIUM,bottom:2,position:"relative",margin:0}]}>View</Text>

  </TouchableOpacity>

  )};

  const updateCurrentPageData = (page, data) => {
    const startIdx = (page - 1) * itemsPerPage;
    const endIdx = startIdx + itemsPerPage;
    return data.slice(startIdx, endIdx);
  };

  const handleNextPage = () => {
    const nextPage = Math.min(currentPage + 1, totalPages);
    setCurrentPage(nextPage);
    setCurrentPageData(updateCurrentPageData(nextPage, tableDataRender));
  };

  const handlePreviousPage = () => {
    const prevPage = Math.max(currentPage - 1, 1);
    setCurrentPage(prevPage);
    setCurrentPageData(updateCurrentPageData(prevPage, tableDataRender));
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    setCurrentPageData(updateCurrentPageData(pageNumber, tableDataRender));
  };

  let pageNumbers = [];
  const getPageNumbers = () => {
    const range = 2;

    let start = Math.max(currentPage - range, 1);
    let end = Math.min(currentPage + range, totalPages);


    const maxRange = 2 * range + 1;
    if (end - start < maxRange) {
      if (currentPage < totalPages / 2) {
        end = Math.min(end + maxRange - (end - start), totalPages);
      } else {
        start = Math.max(start - maxRange + (end - start), 1);
      }
    }

    for (let i = start; i <= end; i++) {
      pageNumbers.push(i);
    }

    return pageNumbers;
  };
  useEffect(() => {
    getMemberCodeX();
    return(()=>{
      dispatchError('')
    })
  }, []);
  useEffect(()=>{
    if(memberCode){
      getHelpData()
      getMagazines()

    }
    return(()=>{
      dispatchError('')
    })
  },[memberCode])
  const FilteredRow = ({ rowData, index }) => (
    <TableWrapper style={styles.tableRow}>
      <Cell
        data={'#' + rowData?.id}
        textStyle={styles.text}
        style={{ flex: 1, justifyContent: 'center',  }}
      />
      <Cell
        data={formatDateTime(rowData?.created_at)}
        textStyle={styles.text}
        style={{ flex: 1, justifyContent: 'center',  }}
      />
      <Cell
        data={rowData?.related_to}
        textStyle={styles.text}
        style={{ flex: 1, justifyContent: 'center', }}
      />
      <Cell
        data={rowData?.login_status?'Yes':'No'}
        textStyle={styles.text}
        style={{ flex: 1, justifyContent: 'center', }}
      />
      <Cell
        data={statusPill(rowData, index)}
        textStyle={styles.text}
        style={{ flex: 1, justifyContent: 'center',  }}
      />
      <Cell
        key={index}
        data={viewButton(rowData, index)}
        textStyle={styles.text}
        style={{ flex: 1, justifyContent: 'center',  }}
      />
    </TableWrapper>
  );


  return (
    <>
    {loader===0?
    (<ScrollView
      style={{
        width: "100%",
        height: "100%",
        borderWidth: 0,
        backgroundColor: "#FFF",
        paddingBottom: isMobileView() ? "25%" : "5%"
      }}
    >
      <View style={{ width: "100%" }}>
        <Headerdiv navigation={navigation} memberCode={memberCode} />


 <SecondaryHeaderdiv titleText="Helpdesk"  />

        {/* Upper area for Issue tickets */}
         <View style={[ !isTabletView()&& commonStyles.flexRow,commonStyles.justifyBetween,{marginHorizontal:responsiveWidth(5.7),marginVertical:responsiveHeight(2),gap:25}]}>
        <View
          style={[styles.memberContainer,{flex: !isTabletView() && .8}]}
        >
               <View style={[styles.subscribedNextPrev     ]}>
          <Text style={{ fontWeight: "400", fontSize:    !isMobileView() ?    responsiveFontSize(1.4) :  responsiveFontSize(2.4), }}>
          Create New Ticket
          </Text>

        </View>
          <View
            style={[{
              borderWidth: 0,
              backgroundColor: "#F8F9FA",
              width: "100%",
              alignSelf: "center",
              padding: 20,
              borderRadius: 15,
            } ]}
          >

            <View style={{ width: "100%", borderWidth: 0, gap:10 }}>
              <View
                style={{
                  justifyContent: "space-between",
                  borderWidth: 0,
                  gap: 10,
                }}
              >
                <View style={[{ height: "100%", borderWidth: 0 },commonStyles.flex1]}>
                  <Text style={styles.textBoxText}>Related to</Text>
                  <View style={[commonStyles.flexRow,commonStyles.gap1rem,{alignItems:"flex-end"}]}>

                  <SelectDropdown
     dropdownOverlayColor={"No"}
                      dropdownStyle={commonStyles.commanDropdownStyle}
                      selectedRowStyle={commonStyles.selectedRowStyle}
                      selectedRowTextStyle={commonStyles.selectedRowTextStyle}
                      rowStyle={commonStyles.commanRowStyle}
                      rowTextStyle={commonStyles.commonRowTextStyle}
                    defaultButtonText="Select Related To"

                    buttonStyle={{
                      flex:1,
                      height: 40,
                      marginTop:10,
                      backgroundColor: Color.WHITE,
                      alignItems: "center",
                      justifyContent: "space-between",
                      borderRadius: 10,
                      borderWidth: 1,
                      borderColor: "rgba(0, 0, 0, 0.14)",
                      padding:10
                    }}
                    buttonTextStyle={{
                      color: "rgba(0, 0, 0, 0.30)",
                      fontSize: isMobileView() ? responsiveFontSize(1.9) : responsiveFontSize(1.1),
                      textAlign: "left",
                      margin: 0,
                      marginVertical: 0,
                      fontWeight: "600",
                    }}
                    data={["Member", "Subscription","others"]}
                    onSelect={(item) => {setRelatedTo(item)}}

                    renderDropdownIcon={(props) => renderDropdownIcon(props)}
                  />
                  {relatedTo==="Subscription"?(
                  <SelectDropdown
     dropdownOverlayColor={"No"}
                      dropdownStyle={commonStyles.commanDropdownStyle}
                      selectedRowStyle={commonStyles.selectedRowStyle}
                      selectedRowTextStyle={commonStyles.selectedRowTextStyle}
                      rowStyle={commonStyles.commanRowStyle}
                      rowTextStyle={commonStyles.commonRowTextStyle}
                  defaultButtonText="Select Subscribed Magazine"

                  buttonStyle={{
                    flex:1,
                    height: 40,
                    backgroundColor: Color.WHITE,
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderRadius: 10,
                    borderWidth: 1,
                    borderColor: "rgba(0, 0, 0, 0.14)",

                  }}
                  buttonTextStyle={{
                    color: "rgba(0, 0, 0, 0.30)",
                    fontSize: isMobileView() ? responsiveFontSize(1.9) : responsiveFontSize(1.1),
                    textAlign: "left",
                    margin: 0,
                    marginVertical: 0,
                    fontWeight: "600",
                  }}
                  data={subscribedMagazines}
                  rowTextForSelection={(item) => item.magazine_name}
                  onSelect={(item) => {setMagazine(item.combo_id)}}
                  buttonTextAfterSelection={(item)=>item.magazine_name}

                  renderDropdownIcon={(props) => renderDropdownIcon(props)}
                />
  ):<></>
}
</View>

                </View>
                <View style={[{ height: "100%", borderWidth: 0  },commonStyles.flex1]}>
                  <Text style={styles.textBoxText}>Issue Type</Text>
                  <SelectDropdown
     dropdownOverlayColor={"No"}
                      dropdownStyle={commonStyles.commanDropdownStyle}
                      selectedRowStyle={commonStyles.selectedRowStyle}
                      selectedRowTextStyle={commonStyles.selectedRowTextStyle}
                      rowStyle={commonStyles.commanRowStyle}
                      rowTextStyle={commonStyles.commonRowTextStyle}
                    defaultButtonText="Select issue type"

                    buttonStyle={{
                      width: "100%",
                      height: 40,
                      marginTop:10,
                      backgroundColor: Color.WHITE,
                      alignItems: "center",
                      justifyContent: "space-between",
                      borderRadius: 10,
                      borderWidth: 1,
                      borderColor: "rgba(0, 0, 0, 0.14)",
                      padding:10
                    }}
                    buttonTextStyle={{
                      color: "rgba(0, 0, 0, 0.30)",
                      margin:20,
                      fontSize: isMobileView() ? responsiveFontSize(1.9) : responsiveFontSize(1.1),
                      textAlign: "left",
                      marginVertical: 0,
                      fontWeight: "600",
                    }}
                    data={helpType}
                    rowTextForSelection={(item) => item.ct_name}
                    onSelect={(item) => {setIssueType(item.ct_code)}}
                    buttonTextAfterSelection={(selectedItem)=>selectedItem.ct_name}


                    renderDropdownIcon={(props) => renderDropdownIcon(props)}
                  />


                </View>


              <View
                style={{
                  flexDirection: "row",

                  borderWidth: 0,
                }}
              >

                {/* query */}
                <View style={{ height: "100%",width:"100%", borderWidth: 0 }}>
                  <Text style={styles.textBoxText}>Description</Text>
                  <TextInput
                    onChangeText={(text) => {
                      setDescription(text);
                    }}
                    multiline
                    style={[styles.textBox, { height: relatedTo==="Subscription"?80:100, width: "100%" }]}
                  />
                </View>
              </View>
              <View style={{ flexDirection: "row", justifyContent:"flex-end",marginTop:15 }}>
          <Hoverable style={{width:"100%"}} >
      {({ hovered }) => (
              <TouchableOpacity
              onPress={() => addIssueTickets()}
                style={{
                  backgroundColor: hovered ? Color.BLACK : Color.BLUE_MEDIUM,
                  height:40,
                  width:  "100%",
                  borderRadius: 8,
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection:"row",
                  gap:10
                }}
              >
                     <Image
                         style={{ height: isTabletView() ? responsiveHeight(5) : responsiveWidth(1.5), width: isTabletView() ? responsiveHeight(5) : responsiveWidth(1.5), }}
                          source={require("../../assets/images/checkIconWhite.png")}
                        />
                <Text style={{ color: "#FFF", fontWeight: "600",fontSize:16 }}>Submit</Text>
              </TouchableOpacity>
                   )}
                   </Hoverable>
          </View>

          </View>

            </View>
          </View>

        </View>

        {/* Down area for latest Issue tickets */}

        <View
          style={[styles.memberContainer,{flex: !isMobileView() && 1.2} ]}
        >
          <View
        style={[styles.subscribedNextPrev, isMobileView() &&{marginBottom:0}  ]}
      >
        <View
        style={{
          flexDirection:"row",
          alignItems:"center",
          justifyContent: isMobileView() && "space-between",
          gap:10
        }}
        >

        <Text
          style={{

            fontWeight: "400",
            fontSize: !isMobileView() ?    responsiveFontSize(1.4) :  responsiveFontSize(2.4)
            ,}}
        >
          Latest Support History
        </Text>
        <View
          style={{
            backgroundColor: "rgba(230, 232, 234, 1)",
            paddingVertical: 5,
            paddingHorizontal: !isMobileView() ? 20 : 14,
            borderRadius: 6,
          }}
        >  <Text
        style={{
          fontWeight: "500",
          fontSize: !isMobileView() ?  responsiveFontSize(1) : responsiveFontSize(1.8),
        }}
        >{tickets?.length}</Text>
        </View>
        </View>
        <View style={[commonStyles.flexRow]}>
        <TouchableOpacity
        style={[
          styles.filterButton,
          activeFilter === true ? styles.activeFilter : styles.inactiveFilter,
        ]}
        onPress={() => handleFilterChange(true)}
      >
        <Text
          style={[
            styles.filterButtonText,
            activeFilter === true ? styles.activeFilterText : styles.inactiveFilterText,
          ]}
        >
          Open
        </Text>
      </TouchableOpacity>

      <TouchableOpacity
        style={[
          styles.filterButton,
          activeFilter === false ? styles.activeFilter : styles.inactiveFilter,
        ]}
        onPress={() => handleFilterChange(false)}
      >
        <Text
          style={[
            styles.filterButtonText,
            activeFilter === false ? styles.activeFilterText : styles.inactiveFilterText,
          ]}
        >
          Closed
        </Text>
      </TouchableOpacity>
        </View>

      </View>



{/* New Table */}

  <View style={styles.container}>
  <ScrollView horizontal={isMobileView() && true      }>

  <Table borderStyle={{ borderWidth: 0, borderColor: '#c8e1ff' }}>
    <Row data={tableHead} style={styles.head} textStyle={styles.textHead} />
    {currentPageData?.map((rowData, index) => (

       <FilteredRow key={index} rowData={rowData} index={index} />
    ))}
  </Table>
  </ScrollView>

</View>









<View style={{ flexDirection: 'row', justifyContent: 'flex-end', marginTop: 10, alignItems:'center',borderWidth:0}}>

<TouchableOpacity style={{borderRadius:7.13,cursor:pageNumbers<1 && 'pointer' ,borderColor:'rgba(0, 0, 0, 0.15)',borderWidth:2,height:30,width:30,justifyContent:'center',alignItems:'center'}}  onPress={handlePreviousPage}>
    <Image style={{ tintColor: currentPage > 1 ? Color.BLUE_MEDIUM:'',height:20,width:20,}} source={require('../../assets/images/nextPageIcon.png')}></Image>
  </TouchableOpacity>
  {/* Page numbers */}
  {getPageNumbers().map((pageNumber) => (
    <TouchableOpacity key={pageNumber} onPress={() => handlePageClick(pageNumber)}>
      <Text
        style={{
          fontSize: 15,
          marginHorizontal: 10,
          fontWeight: pageNumber === currentPage ? 'bold' : 'normal',
          cursor: 'pointer',
        }}
      >
        {pageNumber}
      </Text>
    </TouchableOpacity>
  ))}

  <TouchableOpacity style={{borderRadius:7.13,cursor:currentPage !== totalPages &&'pointer', borderColor:'rgba(0, 0, 0, 0.15)',borderWidth:2,height:30,width:30,justifyContent:'center',alignItems:'center'}} onPress={handleNextPage}>
    <Image style={{ tintColor: currentPage === totalPages ?  '' : Color.BLUE_MEDIUM,height:20,width:20,transform:[{rotate: '180deg'}]}} source={require('../../assets/images/nextPageIcon.png')}></Image>
  </TouchableOpacity>
</View>


        </View>

        </View>


        {/* View Popup */}
         <Modal
        animationType="fade"
        transparent={true}
        visible={viewModalVisible}
        onRequestClose={() => {
          Alert.alert("Modal has been closed.");
          setviewModalVisible(!viewModalVisible);
        }}
      >
        <View style={styles.centeredView}>
          <View style={[styles.modalView,{height: isMobileView() ? "100%" : "60%"}]}>
            <View
              style={{
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
                borderWidth: 0,
              }}
            >
              <Text style={styles.modalText}>Ticket Details</Text>
              <TouchableOpacity
                onPress={() => setviewModalVisible(!viewModalVisible)}
              >
                <Image
                  style={{ width: 30, height: 30 }}
                  source={require("../../assets/images/cross.png")}
                />
              </TouchableOpacity>
            </View>


            <View
              style={{
                width: "100%",
                borderWidth: 0,
                justifyContent:  !isMobileView() && "space-around",
                height: !isMobileView() && "90%",
              }}
            >
              <View
              style={{
                flexDirection: isMobileView() ? "" : "row",
                gap: !isMobileView() && 15,


              }}
              >

              <View style={[{ flex: !isMobileView() &&1, flexBasis: !isMobileView() && 0,   padding:10,gap:15,borderRadius:8 },
              isMobileView() && {
                paddingHorizontal: 0
              }
              ]}>
                <Text
                  style={{
                    fontWeight: "500",
                    fontSize: isMobileView()
                    ? responsiveFontSize(2.2)
                    : responsiveFontSize(1.1),
                    color: "rgba(0, 0, 0, 0.5)",

                  }}
                >
                  Ticket Id
                </Text>
                <View
                style={{
                  backgroundColor: "#F8F9FA",
                  padding:10,
                  borderRadius: 10,
                  height: isMobileView() && 43

                }}
                >

                <Text
                  style={{
                    fontWeight: "600",
                     fontSize: isMobileView()
                          ? responsiveFontSize(2.2)
                          : responsiveFontSize(1.1),

                  }}
                  >
                  {modalData?.id}
                </Text>
                  </View>

              </View>
              <View style={[{ flex: !isMobileView() &&1, flexBasis: !isMobileView() && 0,    padding:10,gap:15,borderRadius:8 },
              isMobileView() && {
                paddingHorizontal: 0
              }
              ]}>
                <Text
                  style={{
                    fontWeight: "500",
                     fontSize: isMobileView()
                          ? responsiveFontSize(2.2)
                          : responsiveFontSize(1.1),
                    color: "rgba(0, 0, 0, 0.5)",

                  }}
                >
                  Issue Type
                </Text>
                <View
                style={{
                  backgroundColor: "#F8F9FA",
                  padding:10,
                  borderRadius: 10,
                  height: isMobileView() && 43

                }}
                >

                <Text
                  style={{
                    fontWeight: "600",
                     fontSize: isMobileView()
                          ? responsiveFontSize(2.2)
                          : responsiveFontSize(1.1),

                  }}
                  >
                  {modalData?.issue_type}
                </Text>
                  </View>

              </View>
              <View style={[{ flex: !isMobileView() &&1, flexBasis: !isMobileView() && 0,   padding:10,gap:15,borderRadius:8 },
              isMobileView() && {
                paddingHorizontal: 0
              }
              ]}>
                <Text
                  style={{
                    fontWeight: "500",
                    fontSize: isMobileView()
                          ? responsiveFontSize(2.2)
                          : responsiveFontSize(1.1),
                    color: "rgba(0, 0, 0, 0.5)",
                    height: isMobileView() && 43
                  }}
                >
                  Related To
                </Text>
                <View
                style={{
                  backgroundColor: "#F8F9FA",
                  padding:10,
                  borderRadius: 10,
                  height: isMobileView() && 43

                }}
                >

                <Text
                  style={{
                    fontWeight: "600",
                     fontSize: isMobileView()
                          ? responsiveFontSize(2.2)
                          : responsiveFontSize(1.1),

                  }}
                  >
                  {modalData?.related_to}
                </Text>
                  </View>

              </View>
              </View>
              <View
              style={{
                flexDirection: "row",
                gap: 15,

              }}
              >

              <View style={[{ flex: 1, flexBasis: 0,   padding:10,gap:15,borderRadius:8 },
              ,
              isMobileView() && {
                paddingHorizontal: 0
              }
              ]}>
                <Text
                  style={{
                    fontWeight: "500",
                     fontSize: isMobileView()
                          ? responsiveFontSize(2.2)
                          : responsiveFontSize(1.1),
                    color: "rgba(0, 0, 0, 0.5)",

                  }}
                >
                 Date & Time
                </Text>
                <View
                style={{
                  backgroundColor: "#F8F9FA",
                  padding:10,
                  borderRadius: 10,
                  height: isMobileView() && 65

                }}
                >

                <Text
                  style={{
                    fontWeight: "600",
                     fontSize: isMobileView()
                          ? responsiveFontSize(2.2)
                          : responsiveFontSize(1.1),

                  }}
                  >
                  {formatDateTime(modalData?.created_at)}
                </Text>
                  </View>

              </View>
              <View style={[{ flex: 1, flexBasis: 0,    padding:10,gap:15,borderRadius:8 },
              ,
              isMobileView() && {
                paddingHorizontal: 0
              }
              ]}>
                <Text
                  style={{
                    fontWeight: "500",
                     fontSize: isMobileView()
                          ? responsiveFontSize(2.2)
                          : responsiveFontSize(1.1),
                    color: "rgba(0, 0, 0, 0.5)",

                  }}
                >
                  Support Status
                </Text>
                <View
                style={{
                  backgroundColor: "#F8F9FA",
                  padding:10,
                  borderRadius: 10,
                  height: isMobileView() && 65

                }}
                >

                <Text
                  style={{
                    fontWeight: "600",
                     fontSize: isMobileView()
                          ? responsiveFontSize(2.2)
                          : responsiveFontSize(1.1),

                  }}
                  >
                 {getStatusValue(modalData?.status)}
                </Text>
                  </View>

              </View>

              </View>
              <View style={[{    padding:10,gap:15,borderRadius:8 },
              ,
              isMobileView() && {
                paddingHorizontal: 0
              }
              ]}>
                <Text
                  style={{
                    fontWeight: "500",
                     fontSize: isMobileView()
                          ? responsiveFontSize(2.2)
                          : responsiveFontSize(1.1),
                    color: "rgba(0, 0, 0, 0.5)",

                  }}
                >
                  Description
                </Text>
                <View
                style={{
                  backgroundColor: "#F8F9FA",
                  padding:10,
                  borderRadius: 10,
                  height: isMobileView() && 65

                }}
                >

                <Text
                  style={{
                    fontWeight: "600",
                     fontSize: isMobileView()
                          ? responsiveFontSize(2.2)
                          : responsiveFontSize(1.1),

                  }}
                  >
                  {modalData?.query}
                   </Text>
                  </View>

              </View>
  </View>




          </View>
        </View>
      </Modal>
      </View>
    </ScrollView>):(
      <SafeAreaView style={{
        width:'100%',
        height:'100%',
        justifyContent:'center',
        alignItems:'center',
        backgroundColor:Color.WHITE,
        margin:'auto'
        }}>
        <ActivityIndicator size='large' />
        <Text style={{fontSize:15,fontWeight:'700',color:'#b4b4b4'}}>Please Wait ...</Text>


      </SafeAreaView>
    )}
  <View style={{position:"absolute",width:"100%"}}>

{error.text2 !== '' &&<PopupComponent
              style={{ zIndex: 100 }}
              hideFun={() => {
                dispatchError("")
                dispatchSuccess("")

              }}
              type={error.type}
              text1={error.text1}
              text2={error.text2}
            />}
  </View>

  </>);
};

export default Helpdesk;

const styles = StyleSheet.create({
  membersSearchArea: {
    borderBottomWidth: 1,
    borderColor: "rgba(0, 0, 0, 0.15)",
    justifyContent: "center",
    height: responsiveHeight(14.5),
    alignItems: "center",
  },
  membersSearchAreaContainer: {
    borderWidth: 0,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "95%",
  },
  memberContainer: {
    borderWidth: 0,
  },
  subscribedNextPrev: {
    borderWidth: 0,
    marginBottom:  responsiveHeight(2),
    height: !isMobileView() && responsiveHeight(4),
    gap: isMobileView() && 10,
    flexDirection: isMobileView() ? "column" : "row",
    justifyContent: "space-between",
    alignItems: !isMobileView() && "center",
  },
  textBox: {
    width: "100%",
    height: "100%",
    marginTop:10,
    backgroundColor: Color.WHITE,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 10,
    borderWidth: 1,
    borderColor: "rgba(0, 0, 0, 0.14)",
    color: "rgba(0, 0, 0, 0.30)",
    fontSize: isMobileView() ? responsiveFontSize(1.9) : responsiveFontSize(1.1),
    textAlign: "left",
    padding: 10,

    fontWeight: "600",
    outlineStyle: "none",
  },
  textBoxText: {    fontWeight: "600",
  fontSize: isMobileView()  ? responsiveFontSize(1.9) :  responsiveFontSize(1.1),
  margin: 0, },
  primaryText: { fontWeight: "700", fontSize: 16 },
  secondText: { fontWeight: "300", fontSize: 14 },
  container: { flex: 1,  backgroundColor: '#fff',top:"0%" },
  head: { height: responsiveHeight(8), backgroundColor: '#fff' },
  text: { margin: 6, marginLeft:15, fontWeight:600,fontSize:isMobileView() ? responsiveFontSize(2) : responsiveFontSize(1.1) },
  textHead: { margin: 6, marginLeft:15, fontWeight:500,fontSize:isMobileView() ? responsiveFontSize(2) : responsiveFontSize(1.1),color:"rgba(0, 0, 0, 0.60)"},
  tableRow:{
    borderWidth:1,
    borderColor:"rgba(0, 0, 0, 0.15)",
    marginVertical:8,
    borderRadius:6,
    height:responsiveHeight(8.5),
    flexDirection:"row"
  },
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    width:'100%',
    height:'100%',
    alignSelf:'center',
    backdropFilter: 'blur(20px)',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
  modalView: {
    borderWidth: 0,
    flexDirection: "column",
    alignItems: "center",
   height:'70%',
   width: isMobileView() && "100%" || isTabletView() && "90%" || isOnlyLaptopView() && '50%',
    margin: isMobileView() ? 0 : 5,
    backgroundColor: "#fff",
    borderRadius: isMobileView() ? 0 : 15,
    padding: isMobileView() ? 20 : 25,


  },
  modalText: {
    textAlign: "center",
    fontWeight: "600",
    fontSize:    isMobileView() && isTabletView()
    ? responsiveFontSize(2.5)
    : responsiveFontSize(1.5),
  },
  filterButton: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    borderRadius: 50,
    padding: 10,
    marginHorizontal: 5,
  },
  filterButtonText: {
    fontSize: 14,
    fontWeight: '500',
  },
  activeFilter: {
    backgroundColor: '#E6E8E8',
  },
  inactiveFilter: {
    backgroundColor: 'white',
  },
  activeFilterText: {
    color: 'black',
  },
  inactiveFilterText: {
    color: Color.GREY_BRIGHT,
  },

});
