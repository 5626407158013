import {
  StyleSheet,
  Text,
  View,
  Image,
  TextInput,
  TouchableOpacity,
  Modal,
  Pressable,
  Alert,
} from "react-native";
import ReCAPTCHA from 'react-google-recaptcha';
import React, { useContext, useEffect, useRef, useState } from "react";
import { Color, SIZE, isMobileView, isOnlyLaptopView, isSmallMobile, isTabletView } from "../../Common/Constants";
import AppLoading from "expo-app-loading";
import { useFonts } from "expo-font";
import SelectDropdown from "react-native-select-dropdown";
import StyledButton from "../../Common/StyledButton";
import loginAxios from "../../api/loginAxios";
import { AuthContext } from "../../context/AuthContext";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {
  responsiveFontSize,
  responsiveHeight,
  responsiveScreenHeight,
  responsiveWidth,
} from "react-native-responsive-dimensions";
import { apiServices } from "../../services/apiServices";
import axios from "axios";
import ErrorContext from "../../context/ErrorContext";
import PopupComponent from "../../Common/PopupComponent";
const APP_SECRET_KEY = "6Ld8iNMmAAAAAMsPAsMjKxjQKln33_aCJShwnaFI"
import Hoverable from "react-native-hoverable";
import { TouchableWithoutFeedback } from "react-native-gesture-handler";
import commonStyles from "../../styles/styles";
import { LinearGradient } from "expo-linear-gradient";
import { is } from "date-fns/locale";
import {SITE_KEY} from '@env'

const LoginPage = ({ navigation }) => {
  var related_to,  escalation_matrix, issue_type;
  var status= 'open'
  const { issueTicketsContactSupport,VerifyTokenGoogle} = apiServices;
  const { signIn,setMembersList } = React.useContext(AuthContext);
  const countries = ["+977", "+91"];
  const [selectedCountryCode,setSelectedCountry] = useState(countries[1])
  const [modalVisible, setModalVisible] = useState(false);
  const [OTPInput, showOTPInput] = useState(false);
  const [buttonHide, setButtonHide] = useState(true);
  const [numberLogin, setNumberLogin] = useState(true);
  const [buttonText, setButtonText] = useState("Get OTP");
  const [memberName, setMemberName] = useState('');
  const [memberEmail, setMemberEmail] = useState('');
  const [memberMobile, setMemberMobile] = useState('');
  const [feedback, setFeedback] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setError] = useState("");
  const [verificationFlag, setVerificationFlag] = useState(false);
  const [relatedTo, setRelatedTo] = useState('Member');
  const {error, dispatchError,dispatchSuccess} = useContext(ErrorContext);

  const [data, setData] = useState({
    email: "",
    password: "",
    mobile: null,
    otp: null,
  });

  const getOTP = async (mobile) => {
    try {
      const response = await loginAxios.get(
        "/portal/send_otp?mobile=" + mobile
      );
      dispatchSuccess(response.data.message)
    } catch (error) {
    }
  };
  const resendOtp = async (mobile) => {
    try {
      const response = await loginAxios.get(
        "/portal/resend_otp?mobile=" + mobile
      );

      dispatchSuccess(response.data.message)
    } catch (error) {
      dispatchError(error)
    }
  };

  const verifyOTP = async () => {
    try {
      const response = await loginAxios.post(
        "/portal/verify_otp?mobile=" + data.mobile,
        { otp: data.otp }
      );
      if(response.data.member_name==='Guest'){
       await AsyncStorage.setItem('memberName',response.data.member_name)
      }
      if(response.data.patrika_sahayak)
      {
       await AsyncStorage.setItem('patrika_sahayak',true)
       await AsyncStorage.setItem('super_member_code' ,response.data?.member_code)
       await AsyncStorage.setItem('member_code' ,response.data?.member_code)
       await AsyncStorage.setItem('super_member_token',response.data?.token)
       navigation.navigate("Dashboard", { screen:'Magzinepage', params:{ memberCode: response.data?.member_code}});
      }

      // await AsyncStorage.setItem('memberData',JSON.stringify(response.data[0]))
      // await AsyncStorage.setItem("members", JSON.stringify(response.data));
// new code
        await AsyncStorage.setItem('super_member_code' ,response.data?.member_code)
        await AsyncStorage.setItem('member_code' ,response.data?.member_code)
        await AsyncStorage.setItem('memberToken' ,response.data?.token)
        await AsyncStorage.setItem('super_member_token',response.data?.token)

        // navigation.navigate("Dashboard", { screen:'Magzinepage', params:{ memberCode: response.data?.member_code}});




      // await AsyncStorage.setItem('memberToken', response.data[0].token )
    // await AsyncStorage.setItem('login',true)
    // uncommnet this for login Dispactch

      setMembersList(response.data)
      signIn(true);
    } catch (error) {
      dispatchError(error);

    }
  };
  // uncommnet this for login Dispactch
  const checkLogin = async () => {
    try {
      const value = await AsyncStorage.getItem("login");
      if (value === true) {
        return;
      }else{
        //dispatchSuccess("Logout Successfull!!")
      }
    } catch (error) {
    }
  }
  useEffect(() => {
    checkLogin()
    return(()=>{
      dispatchError('')
    })
  }, [])

  const captchaRef = useRef(null);

  const postContactSupport = async () => {
    await issueTicketsContactSupport(
      memberName,
      memberMobile,
      memberEmail,
      relatedTo,
      feedback,
      status,
      escalation_matrix,
      issue_type
    );


    setModalVisible(!modalVisible);
    // refreshAdress();
  };
  const renderDropdownIcon = ({ isOpen }) => (
    <View
      style={{
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Image
                style={{
                  height: 22,
                  width: 22,

                  resizeMode:'contain',

                }}
                source={ require( "../../../assets/images/dropDown.png") }
              />
    </View>
  );

const verifyTokenCaptcha = async ()=>{
  let token = captchaRef.current.getValue();
  let tokenResponse = {"response":token}
  if(token){
    // const {verifyTokenG} = apiServices
    let valid_token = await VerifyTokenGoogle(tokenResponse)
    setVerificationFlag(true)
    if(valid_token.data.success ){
      postContactSupport()
    }else{
    setError("Sorry!! Token invalid");
    Alert.alert("Invalid Token")
    }
}}

const handleInputChange =(text)=>{
  const numericValue = text.replace(/[^0-9]/g, '');
  setData({ ...data, mobile: numericValue });

  if(numericValue.length === 10 && selectedCountryCode ==='+91')
  {

    getOTP(numericValue)
    showOTPInput(true),
    setButtonText("Verify")
    setButtonHide(false)
  }
}

const privacyPolicy = ()=>{
  window.open('/SNMPolicy', '_blank');

}

const [nameError, setNameError] = useState('');
const [emailError, setEmailError] = useState('');
const [mobileError, setMobileError] = useState('');



const validateInputs = () => {
  let isValid = true;

  // Validation for Name field
  if (memberName.trim() === '') {
    setNameError('Name is required');
    isValid = false;
  } else {
    setNameError('');
  }

  // Validation for Email field
  const emailPattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/;
  if (!emailPattern.test(memberEmail)) {
    setEmailError('Invalid email address');
    isValid = false;
  } else {
    setEmailError('');
  }

  // Validation for Password field
  if (memberMobile.trim() === '') {
    setMobileError('Invalid mobile number');
    isValid = false;
  } else if (memberMobile.length < 6) {
    setMobileError('Mobile number must be at least 10 characters long');
    isValid = false;
  } else {
    setMobileError('');
  }

  return isValid;
};
const checkLength= ()=>{
  if(data.mobile ==null)
  {
    return
  }
    if(data && data.mobile)
    {
      if(data.mobile.length<10 || data.mobile.length>10){
      return
      }
    }
    if(data && data.mobile)
    {
      if(data.mobile.length ===10)
        {
          getOTP(data.mobile),
          setButtonHide(false),
          showOTPInput(true),
          setButtonText("Verify")
        }
    }
}

const handleSubmit = () => {
  const isValid = validateInputs();

  if (isValid) {
    verifyTokenCaptcha()
  } else {
    alert('Please fill in the required fields correctly.');
  }
};



  let [fontsLoaded] = useFonts({
    openSans: require("../../../assets/fonts/OpenSans-VariableFont_wdth,wght.ttf"),
    ingridDarling:require("../../../assets/fonts/IngridDarling-Regular.ttf")
  });
  if (!fontsLoaded) {
    return <AppLoading />;
  }

  return (
    <View style={styles.mainContainer}>
       {!isMobileView() && !isTabletView()   && (
      <View style={styles.sectionA}>
        <Image
          style={{ width: "100%", height: "100%" }}
          source={require("../../../assets/images/Group1.png")}
        />
        <View style={styles.textContainer}>
          <View style={{ borderWidth: 0, width: "55%", height: "70%" }}>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Image
                style={{ width: 45, height: 50 }}
                source={require("../../../assets/images/Vector.png")}
              />
              <Text style={styles.introText}> Digital</Text>
            </View>
            <Text style={styles.introText}>platform</Text>
            <Text style={styles.introText}>for managing </Text>
            <Text
              style={{
                fontFamily: "openSans",
                fontSize: 60,
                fontWeight: "700",
                color: "#000",
              }}
            >
              magazines.
            </Text>

            <View
              style={{
                borderWidth: 0,
                top: 20,
                width: "70%",
                height: "30%",
                alignItems: "flex-start",
                justifyContent: "flex-start",
              }}
            >
              <Text
                style={{
                  fontFamily: "openSans",
                  fontFamily: "openSans",
                  color: "#fff",
                  fontSize: 20,
                }}
              >
                You will never know everything But you will know more
              </Text>
            </View>
          </View>
        </View>
      </View>
        )}
      <View style={[styles.sectionB,isMobileView && styles.sectionBMoblie]} >

        <View style={!isMobileView() && !isTabletView()  ? styles.logoView : styles.logoViewMoblie}>
          <Image
            style={{ height: 75,  left: !isMobileView() && !isTabletView()  ? 15 :  -10, width: 200, resizeMode: "contain" }}
            // Logo
            source={require("../../../assets/images/Logo.png")}
          />
        </View>
        <View style={ !isMobileView() && !isTabletView()  ? { top: 162, left: 70, borderWidth: 0, width: "90%" } : {paddingVertical:responsiveHeight(5)}}>
          <Text
            style={{ fontFamily: "openSans", fontWeight: "700", fontSize: isMobileView() && isTabletView()  ? responsiveFontSize(4) : 40 }}
          >
            Sign in to { isMobileView() && "\n"}Magazine Portal
          </Text>

          <View style={ !isMobileView() && !isTabletView()  ? { left: 0, borderWidth: 0, width: "90%" } : {left:0}}>
            <Text
              style={{
                fontFamily: "openSans",
                fontWeight: "300",
                fontSize: 18,
              }}
            >
              Enter the Information to login
            </Text>
          </View>
        </View>
        {numberLogin && (

        <View style={ !isMobileView() && !isTabletView()  ? { top: 190, left: 95, borderWidth: 0, width: "80%" } : { width: "100%" } }>
          <Text
            style={{ fontFamily: "openSans", fontWeight: "600", fontSize: 20 }}
          >
            Mobile Number
          </Text>
          <View
            style={{
              top: 15,
              left: 1,
              borderWidth: 1,
              alignItems: "center",
              borderColor: "rgba(0, 0, 0, 0.14)",
              borderRadius: 10,
              width: isMobileView() && isTabletView()  ? "100%" :  450,
              flexDirection: "row",
              height: 50,
              justifyContent: "space-between",
            }}
          >
            <View
              style={{
                borderEndWidth: 1,
                height: 50,
                borderColor: "rgba(0, 0, 0, 0.14)",
                justifyContent: "center",
              }}
            >
              <SelectDropdown
     dropdownOverlayColor={"No"}
                      dropdownStyle={commonStyles.commanDropdownStyle}
                      selectedRowStyle={commonStyles.selectedRowStyle}
                      selectedRowTextStyle={commonStyles.selectedRowTextStyle}
                      rowStyle={commonStyles.commanRowStyle}
                      rowTextStyle={commonStyles.commonRowTextStyle}
                defaultValue={countries[1]}

                buttonTextStyle={{ fontWeight: "600" }}
                buttonStyle={{
                  width: 76,
                  backgroundColor: "#fff",
                  borderColor: "rgba(0, 0, 0, 0.14)",
                  height: 40,
                  borderRadius: 10,

                  fontSize: 16,
                }}
                data={countries}
                onSelect={(selectedItem, index) => {
                  setSelectedCountry(selectedItem)
                }}
              />
            </View>
            <TextInput
              keyboardType="numeric"
              onChangeText={handleInputChange}
              style={{
                paddingHorizontal: 10,
                width: "98%",
                height: 50,
                borderBottomRightRadius: 10,
                borderTopRightRadius: 10,
                borderColor: "rgba(0, 0, 0, 0.14)",
                fontSize: 16,
                fontWeight: "600",
              }}
            />
          </View>
          {/* {!OTPInput && (
           <TouchableOpacity  style={{marginTop:20,marginBottom:20,width: isMobileView() ? "100%" : 450}} onPress={()=>{[setNumberLogin(false),setButtonText("Login")]} }>
             <Text style={{fontFamily:'openSans',color:  Color.BLUE_MEDIUM,fontWeight:'600',fontSize:16,textAlign:"right"}}>Patrika Login</Text>

             </TouchableOpacity>
          )} */}
          {!OTPInput && (
           <TouchableOpacity  style={{marginTop:10, opacity:0, marginBottom:10,width: isMobileView() ? "100%" : 450} }>
             <Text style={{fontFamily:'openSans',color:  Color.BLUE_MEDIUM,fontWeight:'600',fontSize:16,textAlign:"right"}}>Patrika Login</Text>

             </TouchableOpacity>
          )}
          {buttonHide && (
                           <View style={{marginTop:5}}>

              <StyledButton
              disabled={checkLength()}
                onPress={() => [
                  checkLength()
                ]}
                content={buttonText}
              />
            </View>
          )}
          {/* verify */}

          {OTPInput && (
            <View>
              <View
                style={{
                  top: 30,
                  left: 1,
                  gap: 15,
                  alignItems: "flex-start",
                  borderRadius: 7,
                  width:  isMobileView() && isTabletView()  ? "100%" : 450,
                  flexDirection: "column",
                }}
              >
                <Text
                  style={{
                    fontFamily: "openSans",
                    fontWeight: "600",
                    fontSize: 20,
                  }}
                >
                  Enter OTP
                </Text>
                <TextInput
                  keyboardType="numeric"
                  onChangeText={(text) => {
                    setData({ ...data, otp: text });
                  }}
                  style={{
                    borderWidth: 1,

                    paddingHorizontal: 10,
                    width: "98%",
                    height: 50,
                    borderColor: "rgba(0, 0, 0, 0.14)",
                    borderRadius: 10,
                    width: "100%",
                    fontSize: 16,
                    fontWeight: "600",
                  }}
                />
              </View>
              <View style={{marginTop:60}}>
              <StyledButton
                onPress={() => [verifyOTP(), setButtonText("Verify")]}
                content={buttonText}
              />
            </View>
              {/* <View
                style={{
                  top: isMobileView() && isTabletView()  ? 40 : 50,
                  left: "0%",
                  width:  isMobileView() && isTabletView()  ? "100%" : 450,
                  borderWidth: 0,
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: 10,
                  paddingHorizontal: 2,
                }}
              >
                <View
                  style={{
                    borderWidth: 1,
                    flex: 1,
                    flexDirection: "row",
                    height: 0,
                    borderColor: "#B2B2B2",
                  }}
                ></View>
                <Text style={{ color: "#B2B2B2" }}>Or Resend OTP With</Text>
                <View
                  style={{
                    borderWidth: 1,
                    flex: 1,
                    flexDirection: "row",
                    height: 0,
                    borderColor: "#B2B2B2",
                  }}
                ></View>
              </View> */}
              <View
                style={{
                  top:  16,
                  width:  isMobileView() && isTabletView()  ? "100%" : 450,
                  justifyContent: "center",
                  alignItems: "center",
                  borderWidth: 0,
                  gap: isMobileView() && isTabletView()  && 25  ,
                  flex: 1,
                  paddingHorizontal: isMobileView() && isTabletView()  ? "0" : "5%",
                  flexDirection:  "row",
                }}
              >
                {/* <TouchableOpacity
                  style={{
                    flex: 1,
                    bottom: 94,
                    borderWidth: 0,
                    width: "100%",
                    height: "50%",
                    alignItems: "center",
                  }}
                >
                  <Image
                    style={{ width: 160, height: 160, resizeMode: "contain" }}
                    source={require("../../../assets/images/whatsapp.png")}
                  />
                </TouchableOpacity> */}
                <TouchableOpacity
                  onPress={() => {
                    resendOtp(data.mobile);
                  }}
                  style={{
                    flex: 1,
                    borderWidth: 0,
                    width: "100%",
                    height: "50%",
                    alignItems: "center",
                  }}
                >
                  <Text style={{fontFamily:'openSans',color: Color.BLUE_MEDIUM, fontWeight:'600',fontSize:16}} > Resend OTP </Text>
                </TouchableOpacity>
              </View>
            </View>
          )}
        </View>
       ) }
      { !numberLogin && (

        <View style={ isMobileView() || isTabletView()  ? {} : { top: 230, left: 95, borderWidth: 0, width: "80%" }}>
          <Text
            style={{ fontFamily: "openSans", fontWeight: "600", fontSize: 20 }}
          >
            Mobile
          </Text>
          <View
            style={{
              top: 15,
              left: 1,
              borderWidth: 1,
              alignItems: "center",
              borderColor: "rgba(0, 0, 0, 0.14)",
              borderRadius: 10,
              width:  isMobileView() && isTabletView()  ? "100%" : 450,
              flexDirection: "row",
              height: 50,
              justifyContent: "space-between",
            }}
          >
            <View
              style={{
                borderEndWidth: 1,
                height: 50,
                borderColor: "rgba(0, 0, 0, 0.14)",
                justifyContent: "center",
              }}
            >
              <SelectDropdown
     dropdownOverlayColor={"No"}
                      dropdownStyle={commonStyles.commanDropdownStyle}
                      selectedRowStyle={commonStyles.selectedRowStyle}
                      selectedRowTextStyle={commonStyles.selectedRowTextStyle}
                      rowStyle={commonStyles.commanRowStyle}
                      rowTextStyle={commonStyles.commonRowTextStyle}
                defaultValue={countries[1]}

                buttonTextStyle={{ fontWeight: "600" }}
                buttonStyle={{
                  width: 76,
                  backgroundColor: "#fff",
                  borderColor: "rgba(0, 0, 0, 0.14)",
                  height: 40,
                  borderRadius: 10,

                  fontSize: 16,
                }}
                data={countries}
                onSelect={(selectedItem, index) => {
                }}
              />
            </View>
            <TextInput
              keyboardType="numeric"
              onChangeText={(text) => {
                setData({ ...data, mobile: text });
              }}
              style={{
                paddingHorizontal: 10,
                width: "98%",
                height: 50,
                borderBottomRightRadius: 10,
                borderTopRightRadius: 10,
                borderColor: "rgba(0, 0, 0, 0.14)",
                fontSize: 16,
                fontWeight: "600",
              }}
            />
          </View>


            <View>
              <View
                style={{
                  top: 30,
                  left: 1,
                  gap: 15,
                  alignItems: "flex-start",
                  borderRadius: 7,
                  width:  isMobileView() && isTabletView()  ? "100%" : 450,
                  flexDirection: "column",
                }}
              >
                <Text
                  style={{
                    fontFamily: "openSans",
                    fontWeight: "600",
                    fontSize: 20,
                  }}
                >
                  Password
                </Text>
                <TextInput
                  onChangeText={(text) => {
                    setData({ ...data, otp: text });
                  }}
                  style={{
                    borderWidth: 1,

                    paddingHorizontal: 10,
                    width: "98%",
                    height: 50,
                    borderColor: "rgba(0, 0, 0, 0.14)",
                    borderRadius: 10,
                    width: "100%",
                    fontSize: 16,
                    fontWeight: "600",
                  }}
                />
              </View>
              <TouchableOpacity style={{marginTop:40,marginBottom:20,  width:  isMobileView() && isTabletView()  ? "100%" : 450,}} onPress={()=>[setNumberLogin(true),setButtonText("Get OTP")] }>
             <Text style={{fontFamily:'openSans',color:  Color.BLUE_MEDIUM,textAlign:"right",fontWeight:'600',fontSize:16}}>Number Login</Text>

             </TouchableOpacity>
                 <View style={{marginTop:5}}>
              <StyledButton
                onPress={() => [setButtonText("Login")]}
                content={buttonText}
                />
                </View>



            </View>


        </View>
      )}
                {  isTabletView()  && true && <Hoverable>
              {({ hovered }) => (
                <TouchableOpacity onPress={() => setModalVisible(true)}>
                  <View
                    style={{
                      borderWidth: 2,
                      padding: 10,
                      borderRadius: 8,
                      marginTop:OTPInput ? 45 : 20,
                      width: isMobileView() ? "100%" : 450,
                      backgroundColor: hovered ? Color.BLUE_MEDIUM : "#fff",
                      borderColor: hovered ? "#fff" : Color.BLUE_MEDIUM,
                      justifyContent:'center',
                      alignItems:'center',
                      height: 50
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: "openSans",
                        color: hovered ? "#fff" : Color.BLUE_MEDIUM,
                        fontWeight: "600",
                        textAlign:"center",
                        fontSize:18
                      }}
                    >
                      Contact Support
                    </Text>
                  </View>
                </TouchableOpacity>
              )}
            </Hoverable>}
                {  isTabletView()  && true &&  <Hoverable>
              {({ hovered }) => (
                <TouchableOpacity  onPress={()=>{privacyPolicy()}}>
                  <View
                    style={{
                      borderWidth: 2,
                      padding: 10,
                      borderRadius: 8,
                      marginTop:20,
                      width: isMobileView() ? "100%" : 450,
                      backgroundColor: hovered ? Color.BLUE_MEDIUM : "#fff",
                      borderColor: hovered ? "#fff" : Color.BLUE_MEDIUM,
                      justifyContent:'center',
                      alignItems:'center',
                      height: 50
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: "openSans",
                        color: hovered ? "#fff" : Color.BLUE_MEDIUM,
                        fontWeight: "600",
                        textAlign:"center",
                        fontSize:18
                      }}
                    >
                      Privacy Policy
                    </Text>
                  </View>
                </TouchableOpacity>
              )}
            </Hoverable>}


        {true && (
          <View
            style={{
              borderWidth: 0,
              position: isOnlyLaptopView() && "absolute",
              paddingTop:  isTabletView()  && isSmallMobile() && !numberLogin && 50,
              bottom: isOnlyLaptopView() &&    15,
              paddingHorizontal: isTabletView()  ? 20 : 0,
              width:  !isMobileView()   && !isTabletView()  ? "95%" : "100%",
              alignSelf: "center",
              marginTop: isMobileView()   && 60,
              flexDirection: !isMobileView()  && !isTabletView()  ?  "row" : "column-reverse",
              gap:  isTabletView()  && 25,
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {/* here */}
          { !isMobileView() && !isTabletView()  &&  <Hoverable>
              {({ hovered }) => (
                <TouchableOpacity onPress={() => setModalVisible(true)}>
                  <View
                    style={{
                      borderWidth: 2,
                      padding: 10,
                      borderRadius: 7,
                      backgroundColor: hovered ? Color.BLUE_MEDIUM : "#fff",
                      borderColor: hovered ? "#fff" : Color.BLUE_MEDIUM,
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: "openSans",
                        color: hovered ? "#fff" : Color.BLUE_MEDIUM,
                        fontWeight: "600",
                      }}
                    >
                      Contact Support
                    </Text>
                  </View>
                </TouchableOpacity>
              )}
            </Hoverable>}
            { !isMobileView() && !isTabletView()  &&  <Hoverable>
              {({ hovered }) => (
                <TouchableOpacity  onPress={()=>{privacyPolicy()}}>
                  <View
                    style={{
                      borderWidth: 2,
                      padding: 10,
                      borderRadius: 7,
                      backgroundColor: hovered ? Color.BLUE_MEDIUM : "#fff",
                      borderColor: hovered ? "#fff" : Color.BLUE_MEDIUM,
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: "openSans",
                        color: hovered ? "#fff" : Color.BLUE_MEDIUM,
                        fontWeight: "600",
                      }}
                    >
                      Privacy Policy
                    </Text>
                  </View>
                </TouchableOpacity>
              )}
            </Hoverable>}
            {/* <TouchableOpacity  onPress={()=>{privacyPolicy()} }>
             <Text style={{fontFamily:'openSans',color:  Color.BLUE_MEDIUM,fontWeight:'600',fontSize:16,textAlign:"right"}}>Privacy Policy</Text>

             </TouchableOpacity> */}

            <View style={{ borderWidth: 0 }}>
              <Text style={{ fontFamily: "openSans", color: "#00000080" }}>
                @2023 All Rights Reserved
              </Text>
            </View>

            <View
              style={{
                flexDirection: "row",
                borderWidth: 0,
                width: isMobileView() && isTabletView()  ? "90%" : "30%",
                justifyContent: "space-between",
              }}
            >
              <Hoverable style={{}}>
                {({ hovered }) => (
                  <TouchableOpacity onPress={() => console.log("facbook Pressed")}>
                    <Image
                      style={[
                        { height: 40, width: 40 },
                        { transform: hovered ? [{ translateY: -10 }] : [] },
                      ]}
                      source={require("../../../assets/images/facebook.png")}
                    />
                  </TouchableOpacity>
                )}
              </Hoverable>
              <Hoverable style={{}}>
                {({ hovered }) => (
                  <TouchableOpacity>
                    <Image
                      style={[
                        { height: 40, width: 40, resizeMode: "contain" },
                        { transform: hovered ? [{ translateY: -10 }] : [] },
                      ]}
                      source={require("../../../assets/images/instagram.png")}
                    />
                  </TouchableOpacity>
                )}
              </Hoverable>

              <Hoverable style={{}}>
                {({ hovered }) => (
                  <TouchableOpacity>
                    <Image
                      style={[
                        { height: 40, width: 40 },
                        { transform: hovered ? [{ translateY: -10 }] : [] },
                      ]}
                      source={require("../../../assets/images/app.png")}
                    />
                  </TouchableOpacity>
                )}
              </Hoverable>
              <Hoverable style={{}}>
                {({ hovered }) => (
                  <TouchableOpacity>
                    <Image
                      style={[
                        { height: 40, width: 40 },
                        { transform: hovered ? [{ translateY: -10 }] : [] },
                      ]}
                      source={require("../../../assets/images/youtube.png")}
                    />
                  </TouchableOpacity>
                )}
              </Hoverable>
            </View>
          </View>
        )}
        <View style={{position:"absolute",width:"100%"}}>

              {

error.text2!='' &&
<PopupComponent hideFun={()=>{dispatchError('')}} type={error.type} text1={error.text1} text2={error.text2} />


}
</View>

      </View>

      <Modal
        animationType="fade"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {
          Alert.alert("Modal has been closed.");
          setModalVisible(!modalVisible);
        }}
      >



        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <View
              style={{
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
                borderWidth: 0,
                marginBottom:  isMobileView() && isTabletView()  && 10,
              }}
            >
              <Text style={styles.modalText}>Contact Support</Text>
              <TouchableOpacity onPress={() => setModalVisible(!modalVisible)}>
                <Image
                  style={{ width: 30, height: 30 }}
                  source={require("../../../assets/images/cross.png")}
                />
              </TouchableOpacity>
            </View>

            <View
              style={styles.textBoxView}
            >
              <View style={{ width: isMobileView() && isTabletView()  ? "100%" : "53%" }}>
                              <Text
                style={{
                  fontWeight: "600",
                  fontSize: isMobileView()  ? responsiveFontSize(2.2) : responsiveFontSize(1) ,
                  margin: 5,
                }}
              >
                Full Name
              </Text>
              <TextInput
                onChangeText={(text) => {
                  setMemberName(text);
                }}
                placeholderTextColor={"rgba(0, 0, 0, 0.3)"}
                placeholder="Full name"
                style={{
                  borderWidth: 2,
                  borderColor: "rgba(0, 0, 0, 0.14)",
                  backgroundColor: "#FFF",
                  height: isMobileView() && isTabletView()  ? responsiveScreenHeight(6.5) : "50%",
                  width: isMobileView() && isTabletView()  ? "100%" :  "90%",
                  fontSize: isMobileView()  ? responsiveFontSize(2.2) : responsiveFontSize(1) ,
                  borderRadius: 8,
                  paddingLeft: 10,

                }}
              />
              {nameError !== '' && <Text style={{color:'red',fontWeight:'500',height: 10}}>{nameError}</Text>}





              </View>
              <View style={{ width: isMobileView() && isTabletView()  ? "100%" : "52%" }}>
                              <Text
                style={{
                  fontWeight: "600",
                  fontSize: isMobileView()  ? responsiveFontSize(2.2) : responsiveFontSize(1) ,
                  margin: 5,
                }}
              >
                Category
              </Text>
              <SelectDropdown
     dropdownOverlayColor={"No"}
                      dropdownStyle={commonStyles.commanDropdownStyle}
                      selectedRowStyle={commonStyles.selectedRowStyle}
                      selectedRowTextStyle={commonStyles.selectedRowTextStyle}
                      rowStyle={commonStyles.commanRowStyle}
                      rowTextStyle={commonStyles.commonRowTextStyle}
                    defaultButtonText="Select Category"


                    placeholderTextColor={"rgba(0, 0, 0, 0.3)"}
                    buttonStyle={{
                      height: isMobileView() && isTabletView()  ? responsiveScreenHeight(6.5) : "50%",
                      width: isMobileView() && isTabletView()  ? "100%" :  "90%",

                      backgroundColor: "#fff",
                      alignItems: "center",
                      justifyContent: "space-between",
                      borderRadius: 8,
                      borderWidth: 2,
                      borderColor: "rgba(0, 0, 0, 0.14)",
                      padding:10

                    }}
                    buttonTextStyle={{
                      color: "rgba(0, 0, 0)",
                      fontSize: isMobileView() && isTabletView()  ? responsiveFontSize(2.2) :  responsiveFontSize(1),
                      textAlign: "left",
                      margin: 0,
                      marginVertical: 0,
                    }}
                    data={["Member"]}
                    onSelect={(item) => {setRelatedTo(item)}}

                    renderDropdownIcon={(props) => renderDropdownIcon(props)}
                  />
           { !isMobileView()   && !isTabletView()  &&   <Text style={  {color:'rgb(248, 249, 250)',fontWeight:'400',height: 10}}>Enter</Text>}

              </View>
            </View>

            <View style={styles.textBoxView}>
              <View style={{  width: isMobileView() && isTabletView()  ? "100%" : "53%" }}>
                <Text
                  style={{
                    fontWeight: "600",
                    fontSize: isMobileView()  ? responsiveFontSize(2.2) : responsiveFontSize(1) ,
                    margin: 5,
                  }}
                >
                  Mobile No.
                </Text>
                <TextInput
                  onChangeText={(text) => {
                    setMemberMobile(text);
                  }}
                  placeholderTextColor={"rgba(0, 0, 0, 0.3)"}
                  placeholder="Enter Number"
                  style={{
                    borderWidth: 2,
                    borderColor: "rgba(0, 0, 0, 0.14)",
                    backgroundColor: "#FFF",
                    height: isMobileView() && isTabletView()  ? responsiveScreenHeight(6.5) : "50%",
                    width: isMobileView() && isTabletView()  ? "100%" :  "90%",
                    fontSize: isMobileView()  ? responsiveFontSize(2.2) : responsiveFontSize(1) ,
                    borderRadius: 8,
                    paddingLeft: 10,
                  }}
                />
                {/* {!memberMobile ?
                <Text style={{}>Number Required!</Text> :
                <Text style={{color:'rgba(0, 0, 0, 0.60)',fontWeight:'400',height: 10}}>*Enter valid and reachable mobile number</Text>
                } */}

              {mobileError !== '' && <Text style={{color:'red',fontWeight:'500',height:10}}>{mobileError}</Text>}

              </View>
              <View style={{ width: isMobileView() && isTabletView()  ? "100%" : "52%" }}>
                <Text
                  style={{
                    fontWeight: "600",
                    fontSize: isMobileView()  ? responsiveFontSize(2.2) : responsiveFontSize(1) ,
                    margin: 5,
                  }}
                >
                  Email
                </Text>
                <TextInput
                  onChangeText={(text) => {
                    setMemberEmail(text);
                  }}
                  placeholderTextColor={"rgba(0, 0, 0, 0.3)"}
                  placeholder="Example@11.gamil.com"
                  style={{
                    borderWidth: 2,
                    borderColor: "rgba(0, 0, 0, 0.14)",
                    backgroundColor: "#FFF",
                    height: isMobileView() && isTabletView()  ? responsiveScreenHeight(6.5) : "50%",
                    width: isMobileView() && isTabletView()  ? "100%" :  "90%",
                    fontSize: isMobileView()  ? responsiveFontSize(2.2) : responsiveFontSize(1) ,
                    borderRadius: 8,
                    paddingLeft: 10,
                  }}
                />
                {/* {!memberEmail && <Text style={}>Email Required!</Text>

              } */}
              {emailError !== '' && <Text style={{color:'red',fontWeight:'500',height:10}}>{emailError}</Text>}
              </View>
            </View>

            <View
              style={{
                width: "100%",
                margin: 0,
                borderWidth: 0,
                height: isMobileView() && isTabletView()  ? "17%" : "25%",
              }}
            >
              <Text
                style={{
                  fontWeight: "600",
                  fontSize: isMobileView()  ? responsiveFontSize(2.2) : responsiveFontSize(1) ,
                  margin: 5,
                }}
              >
                Message
              </Text>
              <TextInput
                onChangeText={(text) => {
                  setFeedback(text);
                }}
                placeholderTextColor={"rgba(0, 0, 0, 0.3)"}
                // placeholder="Lorem ipsum dolor sit amet consectetur. Nisl vel amet ultrices scelerisque velit et. Rhoncus donec vitae et in nullam eleifend. Dui elementum consectetur ipsum lacus pellentesque. Phasellus elementum diam sed a purus faucibus. Eu velit phasellus."
                multiline
                style={{
                  borderWidth: 2,
                  borderColor: "rgba(0, 0, 0, 0.14)",
                  backgroundColor: "#ffffff",
                  height: "100%",
                  fontSize: isMobileView()  ? responsiveFontSize(2.2) : responsiveFontSize(1) ,
                  borderRadius: 8,
                  padding: 10,
                }}
              />
            </View>
            <View
              style={{
                width: "100%",
                borderWidth: 0,
                height: isMobileView() && isTabletView()  ? "18%" : "20%",
                flexDirection: "row",
                justifyContent: "space-between",
                paddingTop: 5,
                paddingBottom: 5,
                paddingRight:30,
              }}
            >

              <View style={{ width: "53%" }}>

                <Text
                  style={{
                    fontWeight: "600",
                    fontSize: isMobileView()  ? responsiveFontSize(2.2) : responsiveFontSize(1) ,
                    margin: 5,
                  }}
                >
                  Captcha Code
                </Text>
                {/* <LinearGradient
      style={{   width: "90%",
      borderRadius: 8,
      paddingLeft: 10,
      height: "50%"}}
      colors={['#F24D14', 'rgba(255, 65, 0, 0.54)']}
      start={{ x: 0, y: 0 }}
      end={{ x: 1, y: 0 }}
    >
            <Text
                  style={{
                    fontWeight: "500",
                    fontSize: responsiveFontSize(1.8),
                    margin: 5,
                    color:"#FFF",
                    textAlign:"center",
                    fontFamily: 'ingridDarling',
                    letterSpacing:10,


                  }}
                >
                   <Text style={{ position: 'relative', top: 2 }}>L</Text>
  <Text style={{ position: 'relative', bottom: 5 }}>C</Text>
                   <Text style={{ position: 'relative', top: 2 }}>5</Text>
  <Text style={{ position: 'relative', bottom: 5 }}>9</Text>
                   <Text style={{ position: 'relative', top: 2 }}>L</Text>
  <Text style={{ position: 'relative', bottom: 5 }}>2</Text>
                </Text>
    </LinearGradient> */}
    <ReCAPTCHA sitekey={SITE_KEY} ref={captchaRef}  />
              </View>
            </View>
            <View
              style={{
                width: "100%",
                borderWidth: 0,
                justifyContent: "center",
                alignItems:"end",
              }}
            >
              <View style={{alignItems:"start", width: isMobileView() && "100%" }}>

                       {
                    !verificationFlag?(
              <Text style={{ color: "#ff0000", fontWeight: "500" }}>Please First verify</Text>

                    ):(
                      <></>
                    )
                  }

                <Hoverable style={{height:60,
                    width: "100%",
                    position:"relative",
                    top:10
              }}>
                {({ hovered }) => (
              <TouchableOpacity
              // disabled={!memberMobile || !memberEmail || !memberName }

                // onPress={() => verifyTokenCaptcha()}
                onPress={handleSubmit}
                style={{
                  backgroundColor: hovered ? "#000" : Color.BLUE_MEDIUM,
                  height: "70%",
                  borderRadius: 10,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >


                <Text style={{ color: "#FFF", fontWeight: "700" }}>Submit</Text>
              </TouchableOpacity>
              )}
                  </Hoverable>
              </View>


            </View>
          </View>
        </View>
      </Modal>

    </View>
  );
};

export default LoginPage;

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    flexDirection: "row",
    backgroundColor: "#FFF",
    overflow: "hidden",
  },
  sectionA: { flex: 1, alignItems: "center", justifyContent: "center" },
  textContainer: {
    ...StyleSheet.absoluteFill,
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },
  introText: {
    fontFamily: "openSans",
    fontFamily: "openSans",
    fontSize: 60,
    fontWeight: "700",
    color: "#fff",
    alignItems: "flex-start",
  },
  sectionB: { flex: 1, borderWidth: 0 },
  sectionB: { flex: 1, borderWidth: 0 },
  sectionBMoblie: { padding:20},
  logoView: { position: "absolute", borderWidth: 0, top: 50, left: 70 },
  logoViewMoblie: {  position: "relative" },


  centeredView: {
    flex: 1,
    backdropFilter: 'blur(20px)',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    justifyContent: "center",
    alignItems: "center",
  },
  modalView: {
    borderWidth: 0,
    flexDirection: "column",
    alignItems: "center",
    width:  isTabletView()  ? "100%" : responsiveWidth(50),
    height:  isTabletView()  ? "100%" : responsiveHeight(90),
    overflowX: "hidden",
    margin:  isTabletView()  ? 0 : 5,
    backgroundColor: "#f8f9fa",
    borderRadius:  isTabletView()  ? 0 : 15,
    padding:  isTabletView()  ? 20 : 25,
    shadowColor: "none",


    shadowRadius: 4,

  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
  buttonOpen: {
    backgroundColor: "#F194FF",
  },
  buttonClose: {
    backgroundColor: "#2196F3",
  },
  textStyle: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
  },
  modalText: {
    marginBottom: !isMobileView() && !isTabletView()  && 15,
    textAlign: "center",
    fontWeight: "700",
    fontSize: isMobileView() && isTabletView()  ? responsiveFontSize(2.7) : responsiveFontSize(1.7),
    fontFamily: "openSans",
  },
  textBoxView: {
    width: "100%",
    borderWidth: 0,
    height: isMobileView() && isTabletView()  ? "24%" : "17%",
    flexDirection: isMobileView() && isTabletView()  ? "column" : "row",
    justifyContent: "space-between",
    paddingBottom: 5,
  },
});
