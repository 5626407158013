import React, { useState } from "react";
import { TouchableOpacity } from "react-native";
import { Modal } from "react-native";
import {
  Alert,
  StyleSheet,
  Text,
  View,
  Image,
} from "react-native";
import { Color, isMobileView } from "../Common/Constants";
import commonStyles from "../styles/styles";
const Payment_failure = ({navigation}) => {
    const [error, setError] = useState(null);
    const [thankYouPage, setThankYouPage] = useState(true);
  return (
    <View style={styles.container}>

    <Modal
  animationType="fade"
  transparent={true}
  visible={thankYouPage}
  onRequestClose={() => {
    Alert.alert("Modal has been closed.");
    setThankYouPage(!thankYouPage);
  }}
>
  <View
    style={{
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      width:'100%',
      height:'100%',
      alignSelf:'center',
      backdropFilter: 'blur(20px)',
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
    }}
  >
    <View
      style={{
        backgroundColor: "white",
        borderRadius: 15,
        position:"relative",
        alignItems: "center",
        shadowColor: Color.BLACK,
        padding:20,
        paddingTop:55,


        width: isMobileView() ? "90%" : "80%",
        backgroundColor: "#F8F9FA",
        alignItems: "center",
        borderRadius: 20,
      }}
    >



        <TouchableOpacity
          onPress={() => {
            setThankYouPage(!thankYouPage);
          }}
          style={{        borderWidth: 0,
            borderColor: Color.WHITE,
           position:"absolute",right:20,top:20}}
        >
          <Image
            style={{ width: 30, height: 30,    borderWidth: 0,
              borderColor: Color.WHITE,

           }}
            source={require("../../assets/images/cross.png")}
          />
        </TouchableOpacity>
      <View style={{  alignItems: "center", gap: isMobileView() ? 40 : 150 }}>
    <View style={[commonStyles.alignCenter]}>

      <Image
          style={{ width: isMobileView() ? 100 : 200, height: isMobileView() ? 100 : 200,left: isMobileView() ? "2%" : "6%",resizeMode:"cover" }}
          source={require("../../assets/images/Payment_Fail.png")}
        />
        <Text style={{ fontSize: isMobileView() ? 35 : 45, fontWeight: "700",marginTop:15 }}>
           Failure !
        </Text>
        <View style={{ marginTop:10, alignItems: "center" }}>
          <Text
            style={{
              fontSize: isMobileView() ? 17 :  20,
              fontWeight: "600",
              color: "rgba(0, 0, 0, 0.5)",
              textAlign:"center"
            }}
          >
            {`There has been a problem communicating with \nthe payment process`}

          </Text>


          </View>
    </View>

<View style={[commonStyles.alignCenter]}>

          <View style={{  backgroundColor: Color.BLUE_MEDIUM,
        borderRadius: 8,
        padding: 10,   width: isMobileView() ? "100%" : 150,justifyContent:"center" }}>
            <TouchableOpacity
      onPress={() => {
        navigation.navigate('Dashboard');
        setThankYouPage(!thankYouPage);
      }}
      style={{
        alignSelf:"center"

      }}
    >
      <Text style={{ textTransform: 'capitalize',color:Color.WHITE,fontWeight:"500",with:"100%" }}>Try Again</Text>
    </TouchableOpacity>
</View>
          <View style={{

        padding: 10,   width:300,justifyContent:"center",marginTop:10 }}>
            <TouchableOpacity
      onPress={() => {
        navigation.navigate('Dashboard');
        setThankYouPage(!thankYouPage);
      }}
      style={{
        alignSelf:"center"

      }}
    >
      <Text style={{ textTransform: 'capitalize',color:Color.BLUE_MEDIUM,fontSize:17, fontWeight:"500",with:"100%",textDecorationLine: 'underline' }}>Select Another Payment Method</Text>
    </TouchableOpacity>
</View>
</View>


      </View>

    </View>
  </View>
</Modal>
</View>
  )
}

export default Payment_failure


const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  image: {
    width: 200,
    height: 200,
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
  },
  message: {
    fontSize: 16,
  },
});
