import { responsiveFontSize, responsiveHeight, responsiveScreenHeight, responsiveWidth } from "react-native-responsive-dimensions";
import { Color, isMobileView, isOnlyLaptopView, isOnlyTabletView, isTabletView } from "../Common/Constants";
import Hoverable from "react-native-hoverable";

const commonStyles = {
    pl_4:{
        paddingLeft: responsiveWidth(4)
    },
    pl_3:{
        paddingLeft: responsiveWidth(3.2)
    },
    pl_2:{
        paddingLeft: responsiveWidth(2.1)
    },
    ml_6:{
        marginLeft: responsiveWidth(6.5)
    },
    ml_2:{
        marginLeft: responsiveWidth(2.7)
    },
    mt_16px:{
        marginTop: 16
    },
    gap1rem:{
        gap: 16
    },
    gap10px:{
        gap: 10
    },
    gap2rem:{
        gap: 32
    },
    flexRow:{
        flexDirection: 'row'
    },
    flexCol:{
        flexDirection: 'column'
    },
    flex:{
        display: 'flex'
    },
    alignCenter:{
        alignItems: 'center'
    },
    justifyBetween:{
        justifyContent: 'space-between'
    },
    justifyCenter:{
        justifyContent: 'center'
    },
    buttonPrimary: {
        backgroundColor: Color.BLUE_MEDIUM,
        justifyContent: "space-evenly",
        alignItems: "center",
        flexDirection: "row",
        borderRadius: 10,
        height: 40,
        width: 160,
    },
    buttonSecondary: {
        backgroundColor: Color.WHITE,
    },
    p10: {
        padding: 10,
    },
    flex1:{
        flex: 1
    },
    spaceLeftPrimary:{
        marginLeft: isTabletView() ? 20 : 45,
        paddingLeft:   responsiveWidth(2.1),
    },
    spaceRightPrimary:{
        marginRight: isTabletView() ? 20 : 45,
        paddingRight:   responsiveWidth(1.2),
    },
    mt_3:{
        marginTop: responsiveHeight(3.5)
    },
    width100:{
        width: '100%'
    },
    width50:{
        width: '50%'
    },
    flexWrap:{
        flexWrap: 'wrap'
    },
    aspectRatio1:{
        // aspectRatio: 1
    },
    width96:{
        width: '96%'
    },
    width90:{
        width: '90%'
    },
    alignSelfCenter:{
        alignSelf: 'center'
    },
    width47:{
        width: '47%'
    },
    width48Mobile:{
        width: isMobileView() && '48%'
    },
    width30Tab:{
        width: isOnlyTabletView() && '30%'
    },
    width30:{
        width:  '30%'
    },
    width43:{
        width: '43%'
    },
    width51:{
        width: '51%'
    },
    width100:{
        width: '100%'
    },
    width100Mobile:{
        width: isMobileView() && '100%'
    },
    inputLableMobile:{
        fontSize: isMobileView()  && responsiveFontSize(2.2) || isTabletView() && responsiveFontSize(1.1),
        marginTop: isMobileView()  && responsiveHeight(1.5),
    },
    inputFieldMobile:{
        height: isMobileView() && isTabletView()  && responsiveHeight(5.5),
        width: isMobileView() && isTabletView()  && "100%",
        fontSize: isMobileView()  && responsiveFontSize(2.2),
    },
    inputFieldMobileText:{
        fontSize: isMobileView()  && responsiveFontSize(2.2),
    },
    commanDropdownHightStyle: fixHight => ({
        maxHeight: isOnlyLaptopView() && responsiveScreenHeight(33),
        overflowY:"auto",
        backgroundColor: Color.WHITE,
        // height: 300,
        // width:400,
        borderRadius: 5,
        // alignItems: "start",
        justifyContent: "flex-start",
        paddingVertical:10,
        height: fixHight ,

    }),
    commanDropdownStyle: {
        maxHeight:  responsiveScreenHeight(33),
        overflowY:"auto",
        backgroundColor: Color.WHITE,
        // height: 300,
        // width:400,
        borderRadius: 5,
        // alignItems: "start",
        justifyContent: "flex-start",
        paddingVertical:10,
        height:  "auto",

    },
    commanDropdownSearchStyle:{
        borderWidth:0.5,borderColor:"#00000024",borderRadius:6,height:35,
        width: !isMobileView() && "90%",padding:0,paddingHorizontal:0,
        marginLeft: !isMobileView() && 10,
    },
    commanRowStyle:{
        // width:400,
        justifyContent: "start",
        borderBottomWidth:0,
        // padding:10,
        margin:10,
        width:"fit-content"
    },
    commonRowTextStyle:{
         fontFamily:"openSans",
         fontSize:16,
         margin:0,
         marginHorizontal:0,
         textAlign:"inherit",
         textWrap: "balance"

    },
    selectedRowStyle:{
        borderBottomWidth:2,
        borderBottomColor: Color.BLUE_MEDIUM
    },
    selectedRowTextStyle:{
        color:Color.BLUE_MEDIUM
    },
    Col2GridParent: {
        flex: 1,
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'flex-start', // if you want to fill rows left to right
        justifyContent:"space-between",
        rowGap:14,
    },
    Col2GridItem:{
        width: '48%',
    },
    formButtonTextMobile:{
        fontSize: isMobileView()
          && responsiveFontSize(2)
    },
    gap0:{
        gap:0
    }
  };

  export default commonStyles;
