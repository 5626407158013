import {
    Image,
    StyleSheet,
    Text,
    View,
    TouchableOpacity,
    Modal,
    Pressable,
    TextInput,
  } from "react-native";
  import React, { useEffect, useState } from "react";
  import {
    responsiveFontSize,
    responsiveScreenHeight,
    responsiveScreenWidth,
  } from "react-native-responsive-dimensions";
  import SelectDropdown from "react-native-select-dropdown";
  import MagzineCards from "./MagzineCards";
  import BuyCardComponent from "./BuyCardComponent";
  import { apiServices } from "../services/apiServices";
  import Hoverable from "react-native-hoverable";
  import { useFonts } from "expo-font";
  import AppLoading from "expo-app-loading";
  let amountTotal;
import { Color } from "./Constants";


  const LanguageComponent = ({ data, navigation, memberCode, memberAddress,profileData,SubscriptionsData }) => {

    const amount = 150;



    const [totalAmoutState, setTotalAmountState] = useState(amount);
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedValue, setSelectedValue] = useState("");
    const [selectedPlan, setSelectedPlan] = useState({});
    const [quantity, setQuantity] = useState(1);

    const { addToCart } = apiServices;
    const getSubscribeItem = async () => {
      let updateData= {member_code:memberCode,
        amount:amountTotal,
        magazine:data.magazine_id,
        member_address:selectedValue,
        start_date:dates.today,
        end_date:selectedPlan,
        quantity:quantity,
        oldnew:'new',

      }
      await addToCart(
        memberCode, updateData
      );
      // subscription_type,currency,dispatch_type,dispatch_mode,dispatch_to,member_address,member_receiving,start_date,end_date,oldnew,quantity )
      setModalVisible(!setModalVisible);
    };

    function getDates() {
      const today = new Date();

      // Calculate date after 1 year
      const oneYearLater = new Date(
        today.getFullYear() + 1,
        today.getMonth(),
        today.getDate()
      );

      // Calculate date after 2 years
      const twoYearsLater = new Date(
        today.getFullYear() + 2,
        today.getMonth(),
        today.getDate()
      );

      return {
        today: today.toISOString().split("T")[0], // Convert today's date to ISO format (yyyy-mm-dd)
        oneYearLater: oneYearLater.toISOString().split("T")[0], // Convert one year later date to ISO format
        twoYearsLater: twoYearsLater.toISOString().split("T")[0], // Convert two years later date to ISO format
      };
    }

    const dates = getDates();

    const renderDropdownIcon = ({ isOpen }) => (
      <View
        style={{
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Image
                  style={{
                    height: 22,
                    width: 22,

                    resizeMode:'contain',

                  }}
                  source={ require( "../../assets/images/dropDown.png") }
                />
      </View>
    );

  useEffect(()=>{
    if(parseInt(quantity)){
      amountTotal = parseInt(quantity) * amount
      setTotalAmountState(amountTotal)
    }
    else{
      setQuantity(1)
    }



  },[quantity])


    let [fontsLoaded] = useFonts({
      openSans: require("../../assets/fonts/OpenSans-VariableFont_wdth,wght.ttf"),
    });
    if (!fontsLoaded) {
      return <AppLoading/>;
    }

    return (
      <View
        style={{
          flexDirection: "row",
          width: "100%",
          height: responsiveScreenHeight(8),
          alignItems: "center",
          borderWidth: 2,
          margin: 15,
          justifyContent: "space-between",
          borderRadius: 8,
          elevation: 5,
          borderColor: "rgba(0, 0, 0, 0.15)",
        }}
      >
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            borderWidth: 0,
            height: "100%",
            width: "100%",
            flex: 1,
            left:"1.5%",
            gap: 15,
            justifyContent: "center",
          }}
        >
          <Image
            style={{
              height: responsiveScreenHeight(4),
              width: responsiveScreenWidth(2),
              resizeMode: "contain",
            }}
            source={require("../../assets/images/language.png")}
          />
          <Text
            style={{
              fontFamily: "openSans",
              fontWeight: "600",
              fontSize: responsiveFontSize(1),
            }}
          >
            English
          </Text>
        </View>
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            borderWidth: 0,
            height: "100%",
            width: "100%",
            flex: 1,
            left:".5%",
            gap: 15,
            justifyContent: "center",
          }}
        >
          <Image
            style={{
              height: responsiveScreenHeight(4),
              width: responsiveScreenWidth(2),
              resizeMode: "contain",
            }}
            source={require("../../assets/images/tag.png")}
          />
          <Text
            style={{
              fontFamily: "openSans",
              fontWeight: "600",
              fontSize: responsiveFontSize(1),
            }}
          >
            45 Rupees
          </Text>
        </View>
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            borderWidth: 0,
            height: "100%",
            width: "100%",
            flex: 1,
            left:"-.5%",
            justifyContent: "center",
          }}
        >

          <Hoverable>
  {({ hovered }) => (
          <TouchableOpacity onPress={() => setModalVisible(true)} style={[{paddingVertical:2.5,paddingHorizontal:5,borderRadius:100},{backgroundColor: hovered  ? "rgba(0, 0, 0, 0.05)" : "transparent"}]}>
            <Text style={{color:Color.BLUE_MEDIUM,fontWeight:'600',fontSize:18}}>Add To Cart</Text>
          </TouchableOpacity>
          )}
          </Hoverable>
        </View>

        {/* buy subscription modal */}
        <Modal
          animationType="fade"
          transparent={true}
          visible={modalVisible}
          onRequestClose={() => {
            Alert.alert("Modal has been closed.");
            setModalVisible(!modalVisible);
          }}
        >
          <View style={styles.centeredView}>
            <View style={styles.modalView}>
              <View
                style={{
                  flex: 2,
                  borderWidth: 0,
                  height: "100%",
                  width: "100%",
                  backgroundColor: "#f8f9fa",
                  borderBottomLeftRadius:20,
                  borderTopLeftRadius:20,
                  padding: 20,
                }}
              >
                <View style={{   alignItems: "flex-start" }}>
                  <Text style={styles.modalText}>Buy Subscription</Text>
                </View>
                <View style={{paddingTop:20,height:"100%"}}>

                <View style={styles.textBoxView}>
                  <View style={{ width: "54.5%" }}>
                    <Text
                      style={{
                        fontWeight: "600",
                        fontSize: responsiveFontSize(1.1),
                        margin: 0,
                      }}
                    >
                      Title
                    </Text>
                    <SelectDropdown
                      defaultButtonText="Mr."
                      dropdownStyle={{
                        height: 50,
                        width:"22%",
                        borderRadius: 5,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      rowStyle={{
                        width:400,

                      }}
                      buttonStyle={{
                        width: "80%",
                        height: "50%",
                        marginTop:10,
                        backgroundColor: Color.WHITE,
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderRadius: 10,
                        borderWidth: 1,
                        borderColor: "rgba(0, 0, 0, 0.14)",
                        padding:10
                      }}
                      buttonTextStyle={{
                        color: "rgba(0, 0, 0, 0.30)",
                        fontSize: responsiveFontSize(1.1),
                        textAlign: "left",
                        margin: 0,
                        marginVertical: 0,
                        fontWeight: "600",
                      }}
                      data={["Mr.", "Mrs."]}
                      onSelect={(selectedItem, index) => {
                      }}

                      renderDropdownIcon={(props) => renderDropdownIcon(props)}
                    />
                  </View>
                  <View style={{ width: "54.5%" }}>
                    <Text
                      style={{
                        fontWeight: "600",
                        fontSize: responsiveFontSize(1.1),
                        margin: 0,
                      }}
                    >
                      Full Name
                    </Text>
                    <Text style={styles.textboxInput}>
                      {profileData?.member_name}
                    </Text>
                  </View>
                </View>
                <View style={styles.textBoxView}>
                  <View style={{ width: "54.5%" }}>
                    <Text
                      style={{
                        fontWeight: "600",
                        fontSize: responsiveFontSize(1.1),
                        margin: 0,
                      }}
                    >
                      Mobile Number
                    </Text>
                    <Text style={styles.textboxInput}>{profileData?.mobile}</Text>
                  </View>
                  <View style={{ width: "54.5%" }}>
                    <Text
                      style={{
                        fontWeight: "600",
                        fontSize: responsiveFontSize(1.1),
                        margin: 0,
                      }}
                    >
                      Email
                    </Text>
                    <Text style={styles.textboxInput}>{profileData?.email}</Text>
                  </View>
                </View>
                <View style={styles.textBoxView}>
                  <View style={{ width: "54.5%" }}>
                    <Text
                      style={{
                        fontWeight: "600",
                        fontSize: responsiveFontSize(1.1),
                        margin: 0,
                      }}
                    >
                      Care Of
                    </Text>
                    <SelectDropdown
                    defaultButtonText="Select"
                    dropdownStyle={{
                      height: 50,
                      borderRadius: 5,
                    }}
                    rowStyle={{
                      justifyContent: "center",
                      alignItems: "center",
                    }}

                    buttonStyle={{
                      width: "80%",
                      height: "50%",
                      marginTop:10,
                      backgroundColor: Color.WHITE,
                      alignItems: "center",
                      justifyContent: "space-between",
                      borderRadius: 10,
                      borderWidth: 1,
                      borderColor: "rgba(0, 0, 0, 0.14)",
                      padding:10
                    }}
                    buttonTextStyle={{
                      color: "rgba(0, 0, 0, 0.30)",
                      fontSize: responsiveFontSize(1.1),
                      textAlign: "left",
                      margin: 0,
                      marginVertical: 0,
                      fontWeight: "600",
                    }}
                    data={["Mr.", "Mrs."]}
                    onSelect={(selectedItem, index) => {
                    }}

                    renderDropdownIcon={(props) => renderDropdownIcon(props)}
                    />
                  </View>
                  <View style={{ width: "54.5%" }}>
                    <Text
                      style={{
                        fontWeight: "600",
                        fontSize: responsiveFontSize(1.1),
                        margin: 0,
                      }}
                    >
                      Pin Code
                    </Text>
                    <Text
                      style={styles.textboxInput}
                    >{memberAddress?.pincode}</Text>
                  </View>
                </View>
                <View style={styles.textBoxView}>
                  <View style={{ width: "54.5%" }}>
                    <Text
                      style={{
                        fontWeight: "600",
                        fontSize: responsiveFontSize(1.1),
                        margin: 0,

                      }}
                    >
                      Subscription Plan
                    </Text>
                    <SelectDropdown
                    rowTextStyle={{    fontFamily:"openSans",
                  }}
                  defaultButtonText="Select Your Plan"
                  dropdownStyle={{
                    // height: 300,
                    // width:400,
                    borderRadius: 5,
                    alignItems: "center",
                    justifyContent: "center",

                  }}
                  rowStyle={{
                    width:400,
                    padding:5
                  }}
                  buttonStyle={{
                    width: "80%",
                    height: "50%",
                    marginTop:10,
                    backgroundColor: Color.WHITE,
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderRadius: 10,
                    borderWidth: 1,
                    borderColor: "rgba(0, 0, 0, 0.14)",
                    padding:10,
                  }}
                  buttonTextStyle={{
                    color: "rgba(0, 0, 0, 0.30)",
                    fontSize: responsiveFontSize(1.1),
                    textAlign: "left",
                    margin: 0,
                    marginVertical: 0,
                    fontWeight: "600",
                  }}


                  renderDropdownIcon={(props) => renderDropdownIcon(props)}
                  data={SubscriptionsData.map((item)=>item.subscription_type_name)}
                      onSelect={(item) => {setSelectedPlan(item)}}
                      buttonTextAfterSelection={(selectedItem)=>selectedItem}


                    />
                  </View>
                  <View style={{ width: "54.5%" }}>
                    <Text
                      style={{
                        fontWeight: "600",
                        fontSize: responsiveFontSize(1.1),
                        margin: 0,
                      }}
                    >
                      Quantity
                    </Text>
                    <TextInput
                      onChangeText={(text) => {
                        setQuantity(text);
                      }}
                      placeholderTextColor={"rgba(0, 0, 0, 0.3)"}
                      placeholder="1"
                      style={styles.textboxInput}
                    />
                  </View>
                </View>


                {/* Address */}
                <View style={{ borderWidth: 0, height: "29%", padding: 10 }}>
                  <View>
                    <Text
                      style={{
                        fontWeight: "600",
                        fontSize: responsiveFontSize(1.1),
                        margin: 0,
                        fontFamily:"openSans",

                      }}
                    >
                      Select your Address*
                    </Text>
                  </View>


            {
              memberAddress.length > 0 &&
                    <SelectDropdown
                    rowTextStyle={{    fontFamily:"openSans",

                  }}
                    style={{ borderWidth: 1 }}
                    data={memberAddress.map((item) => item.full_address)}
                    onSelect={(item) => setSelectedValue(item)}
                    buttonTextAfterSelection={(selectedItem) => selectedItem}
                    defaultButtonText="Select your Address"
                    buttonStyle={{
                      width: "100%",
                      backgroundColor: Color.WHITE,
                      borderRadius: 10,
                      marginTop: 10,
                      borderWidth: 1,
                      borderColor: "rgba(0, 0, 0, 0.14)",
                    }}
                    dropdownStyle={{
                      height:400,
                      backgroundColor: Color.WHITE,
                      borderRadius: 20,
                      borderWidth: 1,
                      borderColor: "rgba(0, 0, 0, 0.14)",
                      fontFamily:"openSans",

                    }}
                    rowStyle={{
                      padding:5,
                      alignItems:'center',
                      justifyContent:'center'

                    }}

                    renderDropdownIcon={(props) => renderDropdownIcon(props)}
                    buttonTextStyle={{
                      color: "rgba(0, 0, 0, 0.30)",
                      fontSize: responsiveFontSize(1.1),
                      textAlign: "left",
                      margin: 0,
                      marginVertical: 0,
                      fontWeight: "600",
                    }}
                  />}
                </View>

                </View>

              </View>

              {/* sideB */}
              <View
                style={{
                  flex: 1,
                  borderWidth: 0,
                  height: "100%",
                  width: "100%",
                  backgroundColor: "#ffffff",
                  borderBottomRightRadius:20,
                  borderTopRightRadius:20,
                  padding: 20,
                }}
              >
                <View style={{ alignItems: "flex-end" }}>
                  <TouchableOpacity
                    onPress={() => setModalVisible(!modalVisible)}
                  >
                    <Image
                     style={{ width: 30, height: 30 }}
                      source={require("../../assets/images/cross.png")}
                    />
                  </TouchableOpacity>
                </View>
                <View style={{ paddingTop: 20 }}>
                  <Text
                    style={{
                      fontWeight: "600",
                      fontSize: responsiveFontSize(1.6),
                    }}
                  >
                    Subscription Preview
                  </Text>
                </View>
                <View>
                  <BuyCardComponent data={data} amount={totalAmoutState}/>
                  <View style={{top:"5%"}}>
                    <View
                      style={{
                        width: "100%",
                        borderWidth: 0,

                        justifyContent: "center",

                        margin: 10,
                      }}
                    >
                       <Hoverable style={{alignItems:"center",height:40}}>
        {({ hovered }) => (
                      <TouchableOpacity
                        onPress={() => {
                          getSubscribeItem()
                        }}
                        style={{
                          backgroundColor: hovered ? Color.BLACK : Color.BLUE_MEDIUM,
                          width: "50%",
                          height: "100%",
                          borderRadius: 10,
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Text style={{ color: "#FFF", fontWeight: "600" }}>
                          Add to cart
                        </Text>
                      </TouchableOpacity>
                         )}
                         </Hoverable>
                    </View>
                    <View
                      style={{
                        width: "100%",
                        borderWidth: 0,

                        justifyContent: "center",
                        margin: 10,
                      }}
                    >
                         <Hoverable style={{alignItems:"center",height:40}}>
        {({ hovered }) => (
                      <TouchableOpacity
                        onPress={() => {
                          navigation.navigate("Cartpage", { memberCode }),
                            setModalVisible(!modalVisible);
                        }}
                        style={{
                          backgroundColor: hovered ? Color.BLUE_MEDIUM : "#FFF",
                          borderWidth: 2,
                          borderColor: Color.BLUE_MEDIUM,
                          width: "50%",
                          height: "100%",
                          borderRadius: 10,
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Text style={{ color: hovered ? Color.WHITE : Color.BLUE_MEDIUM, fontWeight: "600" }}>
                          Go to Cart
                        </Text>
                      </TouchableOpacity>
                        )}
                        </Hoverable>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </Modal>

      </View>
    );
  };

  export default LanguageComponent;

  const styles = StyleSheet.create({
    textBoxView: {
      width: "100%",

      margin: 2.5,
      height: "15%",
      flexDirection: "row",
      justifyContent: "space-between",
      padding: 0.5,
    },

    centeredView: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      width:'100%',
      height:'100%',
      alignSelf:'center',
      backdropFilter: 'blur(20px)',
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
    },
    modalView: {
      flex: 1,
      backgroundColor: "white",
      borderRadius: 20,

      alignItems: "center",
      shadowColor: Color.BLACK,
      marginTop: 35,
      marginBottom: 35,

      flexDirection: "row",
      height: "80%",
      width: "80%",
    },
    button: {
      borderRadius: 20,
      padding: 10,
      elevation: 2,
    },
    buttonOpen: {
      backgroundColor: "#F194FF",
    },
    buttonClose: {
      backgroundColor: "#2196F3",
    },
    textStyle: {
      color: "white",
      fontWeight: "bold",
      textAlign: "center",
    },
    modalText: {

      fontFamily:"openSans",
      fontWeight: "600",
      fontSize: responsiveFontSize(1.5),
    },
    textboxInput: {
        width: "80%",
        height: "50%",
        marginTop:10,
        backgroundColor: Color.WHITE,
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 10,
        borderWidth: 1,
        borderColor: "rgba(0, 0, 0, 0.14)",
        color: "rgba(0, 0, 0, 0.30)",
        fontSize: responsiveFontSize(1.1),
        textAlign: "left",
        padding: 10,

        fontWeight: "600",
    },
    cardCointainer: {
      width: "75%",
      height: "60%",
      borderWidth: 2,
      borderRadius: 20,
      shadowOpacity: 0.1,
      margin: 30,
      backgroundColor: "#FFF",
      shadowOffset: { width: 1, height: 1 },
    },
    magImage: {
      height: responsiveScreenHeight(13),
      width: responsiveScreenWidth(7),
    },
    magTitle: {
      width: "100%",
      fontFamily: "openSans",
      fontWeight: "600",
      fontSize: responsiveFontSize(1.5),
    },
    cardCointainer: {
      width: responsiveScreenWidth(20),
      height: responsiveScreenHeight(50),
      borderWidth: 2,
      borderColor: "rgba(234, 249, 241, 1)",
      borderRadius: 20,
      shadowOpacity: 0.1,
      margin: 30,
      backgroundColor: "#FFF",
      shadowOffset: { width: 1, height: 1 },
    },
    magImage: {
      height: responsiveScreenHeight(13),
      width: responsiveScreenWidth(7),
    },
    magTitle: {
      fontFamily: "openSans",
      fontWeight: "600",
      fontSize: responsiveFontSize(1.5),
    },
    insideCardLeft: {
      backgroundColor: "#FFF",
      top: 15,
      borderwidth: 0,
      width: responsiveScreenWidth(9),
      height: responsiveScreenHeight(16),
      borderRadius: 15,
      padding: 15,
      shadowOpacity: 0.1,
      shadowOffset: { width: 1, height: 1 },
    },
    insideCardRight: {
      backgroundColor: "#FFF",
      top: 15,
      borderwidth: 0,
      width: responsiveScreenWidth(9),
      height: responsiveScreenHeight(16),
      borderRadius: 15,
      padding: 15,
      shadowOpacity: 0.1,
      shadowOffset: { width: 1, height: 1 },
    },
    cardImage: {
      width: responsiveScreenWidth(3),
      height: responsiveScreenHeight(4),
    },
    cardImageLoc: {
      width: responsiveScreenWidth(3),
      height: responsiveScreenHeight(5),
      margin: 5,
    },
    datesTime: {
      fontSize: responsiveFontSize(0.9),
      color: "rgba(0, 0, 0, 0.15)",
      fontWeight: "600",
    },
    locationDate: {
      flexDirection: "row",
      alignItems: "center",
      padding: 10,
      borderWidth: 0,
    },
    startEndDate: {
      fontSize: responsiveFontSize(1.25),
      color: "rgba(0, 0, 0)",
      fontWeight: "600",
    },
  });
