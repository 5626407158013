import { useContext } from "react";
import namanApi from "../api/namanApi"
import ErrorContext from "../context/ErrorContext"
let flag = false
export const apiServices = {
    viewProfile: async (id) => {
        return new Promise(async (resolve, reject) => {
            try {
                await namanApi.get(`/portal/${id}/view`)
                .then(
                    response => {
                        const checkData = response.data
                        resolve(checkData)
                    }
                    )
                    
                } catch (error) {
                    
                    
                    
                    reject(error)
                }
        }
        )
    },
    viewOtherMagzines: async (id) => {
        return new Promise(async (resolve, reject) => {
            try {
                await namanApi.get(`/portal/${id}/magazines`)
                    .then(
                        response => {
                            const checkData = response.data
                            resolve(checkData)
                        }
                    )

            } catch (error) {
                // // console.log("🚀 ~ file: apiServices.js:34 ~ returnnewPromise ~ error:", error)
                reject(error)
            }
        }
        )
    },

    viewSubscribedMagazines: async (id) => {
        return new Promise(async (resolve, reject) => {
            try {
                await namanApi.get(`/portal/${id}/subscriptions`)
                    .then(
                        response => {
                            const checkData = response.data
                            resolve(checkData)
                        }
                    )

            } catch (error) {
                // // console.log("🚀 ~ file: apiServices.js:34 ~ returnnewPromise ~ error:", error)
                reject(error)
            }
        }
        )
    },



    getAddress: async (id) => {
        return new Promise(async (resolve, reject) => {
            try {
                await namanApi.get(`/portal/${id}/get_address`)
                    .then(
                        response => {
                            const checkData = response.data
                            // console.log("checking ",checkData)
                            resolve(checkData)
                        }
                    )

            } catch (error) {
                // // console.log("🚀 ~ file: apiServices.js:34 ~ returnnewPromise ~ error:", error)
                reject(error)
            }
        }
        )
    },

    getCountryList: async (id) => {
        return new Promise(async (resolve, reject) => {
            try {
                await namanApi.get(`/portal/${id}/countries `)
                    .then(
                        response => {
                            const checkData = response.data
                            // console.log("checking ",checkData)
                            resolve(checkData)
                        }
                    )

            } catch (error) {
                // // console.log("🚀 ~ file: apiServices.js:34 ~ returnnewPromise ~ error:", error)
                reject(error)
            }
        }
        )
    },

    getStateList: async (id,country) => {
        return new Promise(async (resolve, reject) => {
            try {
                await namanApi.get(`/portal/${id}/states?country=${country}`)
                    .then(
                        response => {
                            const checkData = response.data
                            // console.log("checking ",checkData)
                            resolve(checkData)
                        }
                    )

            } catch (error) {
                // // console.log("🚀 ~ file: apiServices.js:34 ~ returnnewPromise ~ error:", error)
                reject(error)
            }
        }
        )
    },


    getTehsilsList: async (id,district) => {
        return new Promise(async (resolve, reject) => {
            try {
                await namanApi.get(`/portal/${id}/tehsils?district=${district}`)
                    .then(
                        response => {
                            const checkData = response.data
                            // console.log("checking ",checkData)
                            resolve(checkData)
                        }
                    )

            } catch (error) {
                // // console.log("🚀 ~ file: apiServices.js:34 ~ returnnewPromise ~ error:", error)
                reject(error)
            }
        }
        )
    },
    getCityList: async (id,district) => {
        return new Promise(async (resolve, reject) => {
            try {
                await namanApi.get(`/portal/${id}/cities?district=${district}`)
                    .then(
                        response => {
                            const checkData = response.data
                            // console.log("checking ",checkData)
                            resolve(checkData)
                        }
                    )

            } catch (error) {
                // // console.log("🚀 ~ file: apiServices.js:34 ~ returnnewPromise ~ error:", error)
                reject(error)
            }
        }
        )
    },
    getPincodeList: async (id,city) => {
        return new Promise(async (resolve, reject) => {
            try {
                await namanApi.get(`/portal/${id}/pincodes?city=${city}`)
                    .then(
                        response => {
                            const checkData = response.data
                            // console.log("checking ",checkData)
                            resolve(checkData)
                        }
                    )

            } catch (error) {
                // // console.log("🚀 ~ file: apiServices.js:34 ~ returnnewPromise ~ error:", error)
                reject(error)
            }
        }
        )
    },
    getDistrictList: async (id,state) => {
        return new Promise(async (resolve, reject) => {
            try {
                await namanApi.get(`/portal/${id}/districts?state=${state}`)
                    .then(
                        response => {
                            const checkData = response.data
                            // console.log("checking ",checkData)
                            resolve(checkData)
                        }
                    )

            } catch (error) {
                // // console.log("🚀 ~ file: apiServices.js:34 ~ returnnewPromise ~ error:", error)
                reject(error)
            }
        }
        )
    },
    getBranchList: async (id,zone,enteredText) => {
        return new Promise(async (resolve, reject) => {
            try {
                if(enteredText!=undefined){
                await namanApi.get(`/portal/${id}/branches?search_zone=${zone}&search_branch=${enteredText}`)
                // await namanApi.get(`/portal/${id}/branches?`,{data})
                    .then(
                        response => {
                            const checkData = response.data
                            // console.log("checking ",checkData)
                            resolve(checkData)
                        }
                    )
                    }else{
                        await namanApi.get(`/portal/${id}/branches?search_zone=${zone}&search_branch`)
                        // await namanApi.get(`/portal/${id}/branches?`,{data})
                            .then(
                                response => {
                                    const checkData = response.data
                                    // console.log("checking ",checkData)
                                    resolve(checkData)
                                }
                            )
                    }
            } catch (error) {
                // // console.log("🚀 ~ file: apiServices.js:34 ~ returnnewPromise ~ error:", error)
                reject(error)
            }
        }
        )
    },
    getZoneList: async (id,text) => {
        return new Promise(async (resolve, reject) => {
            try {
                if(text!=undefined){
                await namanApi.get(`/portal/${id}/zones?search_zone=${text}`)
                    .then(
                        response => {
                            const checkData = response.data
                            // console.log("checking ",checkData)
                            resolve(checkData)
                        }
                    )
                }else{
                    await namanApi.get(`/portal/${id}/zones?search_zone`)
                    .then(
                        response => {
                            const checkData = response.data
                            // console.log("checking ",checkData)
                            resolve(checkData)
                        }
                    )
                }

            } catch (error) {
                // // console.log("🚀 ~ file: apiServices.js:34 ~ returnnewPromise ~ error:", error)
                reject(error)
            }
        }
        )
    },
    getBhawanList: async (id,zone,branch) => {
        return new Promise(async (resolve, reject) => {
            try {
                await namanApi.get(`/portal/${id}/get_branch_location?zone=${zone}&branch=${branch}`)
                    .then(
                        response => {
                            const checkData = response.data
                            // console.log("checking ",checkData)
                            resolve(checkData)
                        }
                    )
                

            } catch (error) {
                // // console.log("🚀 ~ file: apiServices.js:34 ~ returnnewPromise ~ error:", error)
                reject(error)
            }
        }
        )
    },
    getCartData: async (id) => {
        return new Promise(async (resolve, reject) => {
            try {
                await namanApi.get(`/portal/${id}/get_cart`)
                    .then(
                        response => {
                            const checkData = response.data
                            // console.log("checking ",checkData)
                            resolve(checkData)
                        }
                    )

            } catch (error) {
                // // console.log("🚀 ~ file: apiServices.js:34 ~ returnnewPromise ~ error:", error)
                reject(error)
            }
        }
        )
    },
    getIssueTickets: async (id) => {
        return new Promise(async (resolve, reject) => {
            try {
                await namanApi.get(`/portal/${id}/get_issue_tickets`)
                    .then(
                        response => {
                            const checkData = response.data
                            // console.log("checking ",checkData)
                            resolve(checkData)
                        }
                    )

            } catch (error) {
                // // console.log("🚀 ~ file: apiServices.js:34 ~ returnnewPromise ~ error:", error)
                reject(error)
            }
        }
        )
    },
    getDeleteCartItem: async (id,magazine_id) => {
        return new Promise(async (resolve, reject) => {
            try {
                await namanApi.get(`/portal/${id}/delete_cart/${magazine_id}`)
                    .then(
                        response => {
                            const checkData = response.data
                            // console.log("checking ",checkData)
                            resolve(checkData)
                        }
                    )

            } catch (error) {
                // // console.log("🚀 ~ file: apiServices.js:34 ~ returnnewPromise ~ error:", error)
                reject(error)
            }
        }
        )
    },
    getStopMagazineSubscription: async (id,magazine_id) => {
        return new Promise(async (resolve, reject) => {
            try {
                await namanApi.get(`/portal/${id}/stop_subscription/${magazine_id}`)
                    .then(
                        response => {
                            const checkData = response.data
                            // console.log("checking ",checkData)
                            resolve(checkData)
                        }
                    )

            } catch (error) {
                // // console.log("🚀 ~ file: apiServices.js:34 ~ returnnewPromise ~ error:", error)
                reject(error)
            }
        }
        )
    },
    getSubscriptionType: async (id) => {
        return new Promise(async (resolve, reject) => {
            try {
                await namanApi.get(`/portal/${id}/get_subscription_type`)
                    .then(
                        response => {
                            const checkData = response.data
                            // console.log("checking ",checkData)
                            resolve(checkData)
                        }
                    )

            } catch (error) {
                // // console.log("🚀 ~ file: apiServices.js:34 ~ returnnewPromise ~ error:", error)
                reject(error)
            }
        }
        )
    },
    getMasterHelpType: async () => {
        return new Promise(async (resolve, reject) => {
            try {
                await namanApi.get(`/portal/get_complaint_type`)
                    .then(
                        response => {
                            const checkData = response.data
                            // console.log("checking ",checkData)
                            resolve(checkData)
                        }
                    )

            } catch (error) {
                // // console.log("🚀 ~ file: apiServices.js:34 ~ returnnewPromise ~ error:", error)
                reject(error)
            }
        }
        )
    },
    getReceipts: async (id) => {
        return new Promise(async (resolve, reject) => {
            try {
                await namanApi.get(`/portal/${id}/get_transactions`)
                    .then(
                        response => {
                            const checkData = response.data
                            // console.log("checking ",checkData)
                            resolve(checkData)
                        }
                    )

            } catch (error) {
                // // console.log("🚀 ~ file: apiServices.js:34 ~ returnnewPromise ~ error:", error)
                reject(error)
            }
        }
        )
    },

    getSingleMagazineReceipts: async (memberId,magazineId,flag) => {
        return new Promise(async (resolve, reject) => {
            try {
                await namanApi.get(`/portal/${memberId}/get_receipts?id=${magazineId}&bulk_purchase=${flag} `)
                    .then(
                        response => {
                            const checkData = response.data
                            // console.log("checking ",checkData)
                            resolve(checkData)
                        }
                    )

            } catch (error) {
                // // console.log("🚀 ~ file: apiServices.js:34 ~ returnnewPromise ~ error:", error)
                reject(error)
            }
        }
        )
    },
    getTimeLineList: async (memberId,magazineId,flag) => {
        return new Promise(async (resolve, reject) => {
            try {
                await namanApi.get(`/portal/${memberId}/get_subscription_dispatch?id=${magazineId}&bulk_purchase=${flag}`)
                    .then(
                        response => {
                            const checkData = response.data
                            // console.log("checking ",checkData)
                            resolve(checkData)
                        }
                    )

            } catch (error) {
                // // console.log("🚀 ~ file: apiServices.js:34 ~ returnnewPromise ~ error:", error)
                reject(error)
            }
        }
        )
    },
    getTimeLineListOfAllData: async (memberId,magazineId,flag) => {
        return new Promise(async (resolve, reject) => {
            try {
                await namanApi.get(`/portal/${memberId}/get_member_dispatch`)
                    .then(
                        response => {
                            const checkData = response.data
                            // console.log("checking ",checkData)
                            resolve(checkData)
                        }
                    )

            } catch (error) {
                // // console.log("🚀 ~ file: apiServices.js:34 ~ returnnewPromise ~ error:", error)
                reject(error)
            }
        }
        )
    },
    getSingleMagazineTimelineDataApi: async (memberId,magazineId) => {
        return new Promise(async (resolve, reject) => {
            try {
                await namanApi.get(`/portal/${memberId}/get_dispatch_timeline?dispatch_detail_id=${magazineId}`)
                    .then(
                        response => {
                            const checkData = response.data
                            // console.log("checking ",checkData)
                            resolve(checkData)
                        }
                    )

            } catch (error) {
                // // console.log("🚀 ~ file: apiServices.js:34 ~ returnnewPromise ~ error:", error)
                reject(error)
            }
        }
        )
    },
    getBranchesAPI: async (id) => {
        return new Promise(async (resolve, reject) => {
            try {
                await namanApi.get(`/portal/${id}/branches`)
                    .then(
                        response => {
                            const checkData = response.data
                            // console.log("checking ",checkData)
                            resolve(checkData)
                        }
                    )

            } catch (error) {
                // // console.log("🚀 ~ file: apiServices.js:34 ~ returnnewPromise ~ error:", error)
                reject(error)
            }
        }
        )
    },
    getBulkOrdersAPI: async (id,data) => {
        return new Promise(async (resolve, reject) => {
            try {
                await namanApi.get(`/portal/${id}/get_bulk_orders`,data)
                    .then(
                        response => {
                            const checkData = response.data
                            // console.log("checking ",checkData)
                            resolve(checkData)
                        }
                    )

            } catch (error) {
                // // console.log("🚀 ~ file: apiServices.js:34 ~ returnnewPromise ~ error:", error)
                reject(error)
            }
        }
        )
    },
    getMemberDispatchOrders: async (id) => {
        return new Promise(async (resolve, reject) => {
            try {
                await namanApi.get(`/portal/${id}/member_dispatch_orders`)
                    .then(
                        response => {
                            const checkData = response.data
                            // console.log("checking ",checkData)
                            resolve(checkData)
                        }
                    )

            } catch (error) {
                // // console.log("🚀 ~ file: apiServices.js:34 ~ returnnewPromise ~ error:", error)
                reject(error)
            }
        }
        )
    },
    refreshOrderStatus:async(memberCode,refNo)=>{
        return new Promise(async (resolve, reject) => {
            try {
                await namanApi.get(`/portal/${memberCode}/update_dispatch_status?ref_no=${refNo}`)
                    .then(
                        response => {
                            const checkData = response.data
                            // console.log("checking ",checkData)
                            resolve(checkData)
                        }
                    )

            } catch (error) {
                // // console.log("🚀 ~ file: apiServices.js:34 ~ returnnewPromise ~ error:", error)
                reject(error)
            }
        }
        )
    },
    getTransaction: async (id) => {
        return new Promise(async (resolve, reject) => {
            try {
                await namanApi.get(`/portal/${id}/get_receipts`)
                    .then(
                        response => {
                            const checkData = response.data
                            // console.log("checking ",checkData)
                            resolve(checkData)
                        }
                    )

            } catch (error) {
                // // console.log("🚀 ~ file: apiServices.js:34 ~ returnnewPromise ~ error:", error)
                reject(error)
            }
        }
        )
    },
    getCurrencyData: async (id) => {
        return new Promise(async (resolve, reject) => {
            try {
                await namanApi.get(`/portal/${id}/get_currency`)
                    .then(
                        response => {
                            const checkData = response.data
                            // console.log("checking ",checkData)
                            resolve(checkData)
                        }
                    )

            } catch (error) {
                // // console.log("🚀 ~ file: apiServices.js:34 ~ returnnewPromise ~ error:", error)
                reject(error)
            }
        }
        )
    },
    getImagePath:async (name) => {
        return new Promise(async (resolve, reject) => {
            try {
                await namanApi.get(`/assets/img/magazine_images/${name}`)
                    .then(
                        response => {
                            const checkData = response.data
                            // console.log("checking ",checkData)
                            resolve(checkData)
                        }
                    )

            } catch (error) {
                // // console.log("🚀 ~ file: apiServices.js:34 ~ returnnewPromise ~ error:", error)
                reject(error)
            }
        }
        )
    },
    getQuantityList:async(id)=>{
        return new Promise(async(resolve,reject)=>{
            try{
                await namanApi.get(`/portal/${id}/get_combo_quantity`)
                .then(response=>{
                    const checkData = response.data;
                    resolve(checkData)
                })
            }catch(error){
                reject(error)
            }
        })
    },
    getBulkQuantityParameters:async(id)=>{
        return new Promise(async(resolve,reject)=>{
            try{
                await namanApi.get(`/portal/${id}/bulk_parameters`)
                .then(response=>{
                    const checkData = response.data;
                    resolve(checkData)
                })
            }catch(error){
                reject(error)
            }
        })
    },
    getBulkPrint:async(id,data)=>{
        return new Promise(async(resolve,reject)=>{
            try{
                await namanApi.get(`portal/${id}/get_receipt?id=${data}&bulk_purchase=true`)
                .then(response=>{
                    const checkData = response.data;
                    resolve(checkData)
                })
            }catch(error){
                reject(error)
            }
        })
    },
    getBulkDates:async(id)=>{
        return new Promise(async(resolve,reject)=>{
            try{
                await namanApi.get(`portal/${id}/get_bulk_dates`)
                .then(response=>{
                    const checkData = response.data;
                    resolve(checkData)
                })
            }catch(error){
                reject(error)
            }
        })
    },
    rateChartMagazineCheck:async(memberCode,magazineId)=>{
        return new Promise(async(resolve,reject)=>{
            try{
                await namanApi.get(`portal/${memberCode}/rate_chart_magazine_check?magazine_id=${magazineId}`)
                .then(response=>{
                    const checkData = response.data;
                    resolve(checkData)
                })
            }catch(error){
                reject(error)
            }
        })
    },
    getComboParameters:async(memberCode)=>{
        return new Promise(async(resolve,reject)=>{
            try{
                await namanApi.get(`portal/${memberCode}/combo_parameters`)
                .then(response=>{
                    const checkData = response.data;
                    resolve(checkData)
                })
            }catch(error){
                reject(error)
            }
        })
    },

    // POST APIS ----

    postDeleteAddress: async (id, data) => {
        return new Promise(async (resolve, reject) => {
            try {
                await namanApi.post(`/portal/${id}/delete_address`, {address_id:data})
                    .then(
                        response => {
                            const checkData = response.data
                            // console.log("checking ",checkData)
                            resolve(checkData)
                        }
                    )

            } catch (error) {
                // // console.log("🚀 ~ file: apiServices.js:34 ~ returnnewPromise ~ error:", error)
                reject(error)
            }
        }
        )
    },
    postSubscriptionDates: async (id, data) => {
        return new Promise(async (resolve, reject) => {
            try {
                await namanApi.post(`/portal/${id}//get_subscription_dates`, {subscription_type_id:data})
                    .then(
                        response => {
                            const checkData = response.data
                            // console.log("checking ",checkData)
                            resolve(checkData)
                        }
                    )

            } catch (error) {
                // // console.log("🚀 ~ file: apiServices.js:34 ~ returnnewPromise ~ error:", error)
                reject(error)
            }
        }
        )
    },
    postAmountGet: async (id, magData) => {
        return new Promise(async (resolve, reject) => {
            try {
                await namanApi.post(`/portal/${id}/get_rate_chart`, magData)
                    .then(
                        response => {
                            const checkData = response.data
                            // console.log("checking ",checkData)
                            resolve(checkData)
                        }
                    )

            } catch (error) {
                // // console.log("🚀 ~ file: apiServices.js:34 ~ returnnewPromise ~ error:", error)
                reject(error)
            }
        }
        )
    },
    updateCartAddressAPI: async (id, data) => {
        return new Promise(async (resolve, reject) => {
            try {
                await namanApi.post(`/portal/${id}/update_cart`, data)
                    .then(
                        response => {
                            const checkData = response.data
                            // console.log("checking ",checkData)
                            resolve(checkData)
                        }
                    )

            } catch (error) {
                // // console.log("🚀 ~ file: apiServices.js:34 ~ returnnewPromise ~ error:", error)
                reject(error)
            }
        }
        )
    },
    postUnpainInvoices: async (id, data) => {
        return new Promise(async (resolve, reject) => {
            try {
                await namanApi.post(`/portal/${id}/get_unpaid_invoices`, data)
                    .then(
                        response => {
                            const checkData = response.data
                            // console.log("checking ",checkData)
                            resolve(checkData)
                        }
                    )

            } catch (error) {
                // // console.log("🚀 ~ file: apiServices.js:34 ~ returnnewPromise ~ error:", error)
                reject(error)
            }
        }
        )
    },
    postAddUnpainInvoicesAPI: async (id, data) => {
        return new Promise(async (resolve, reject) => {
            try {
                await namanApi.post(`/portal/${id}/add_unpaid_invoices`, data)
                    .then(
                        response => {
                            const checkData = response.data
                            // console.log("checking ",checkData)
                            resolve(checkData)
                        }
                    )

            } catch (error) {
                // // console.log("🚀 ~ file: apiServices.js:34 ~ returnnewPromise ~ error:", error)
                reject(error)
            }
        }
        )
    },
    postPayUnpaidInvoicesAPI: async (id, unPaidData) => {
        return new Promise(async (resolve, reject) => {
            try {
                await namanApi.post(`/portal/${id}/payment/pay_unpaid_invoices`, unPaidData)
                    .then(
                        response => {
                            const checkData = response.data
                            // console.log("checking ",checkData)
                            resolve(checkData)
                        }
                    )

            } catch (error) {
                // // console.log("🚀 ~ file: apiServices.js:34 ~ returnnewPromise ~ error:", error)
                reject(error)
            }
        }
        )
    },

    getCourierChargesAPI: async (magId, quantityData) => {
        return new Promise(async (resolve, reject) => {
            try {
                await namanApi.post(`/portal/${magId}/bulk_courier_charges`, quantityData)
                    .then(
                        response => {
                            const checkData = response.data
                            // console.log("checking ",checkData)
                            resolve(checkData)
                        }
                    )

            } catch (error) {
                // // console.log("🚀 ~ file: apiServices.js:34 ~ returnnewPromise ~ error:", error)
                reject(error)
            }
        }
        )
    },
    defaultSetAddress: async (id, data) => {
        return new Promise(async (resolve, reject) => {
            try {
                await namanApi.post(`/portal/${id}/set_default_address`, {address_id:data,member_code:id })
                    .then(
                        response => {
                            const checkData = response.data
                            // console.log("checking ",checkData)
                            resolve(checkData)
                        }
                    )

            } catch (error) {
                // // console.log("🚀 ~ file: apiServices.js:34 ~ returnnewPromise ~ error:", error)
                reject(error)
            }
        }
        )
    },
    memberVerification: async (id, data) => {
        return new Promise(async (resolve, reject) => {
            try {
                await namanApi.post(`/portal/${id}/member_verification`, {status:data,member_code:id })
                    .then(
                        response => {
                            const checkData = response.data
                            // console.log("checking ",checkData)
                            resolve(checkData)
                        }
                    )

            } catch (error) {
                // // console.log("🚀 ~ file: apiServices.js:34 ~ returnnewPromise ~ error:", error)
                reject(error)
            }
        }
        )
    },
    editProfile: async (id, editData) => {
        return new Promise(async (resolve, reject) => {
            try {
                await namanApi.post(`/portal/${id}/edit`, editData)
                    .then(
                        response => {
                            const checkData = response.data
                            // console.log("checking ",checkData)
                            resolve(checkData)
                        }
                    )

            } catch (error) {
                // // console.log("🚀 ~ file: apiServices.js:34 ~ returnnewPromise ~ error:", error)
                reject(error)
            }
        }
        )
    },
    addToCart: async (id, updateData) => {
        return new Promise(async (resolve, reject) => {
            try {
                await namanApi.post(`/portal/${id}/add_subscription_cart`, updateData)
                    .then(
                        response => {
                            const checkData = response.data
                            // console.log("checking ",checkData)
                            resolve(checkData)
                        }
                    )

            } catch (error) {
                // // console.log("🚀 ~ file: apiServices.js:34 ~ returnnewPromise ~ error:", error)
                reject(error)
            }
        }
        )
    },
    refreshStatus: async (id) => {
        return new Promise(async (resolve, reject) => {
            try {
                await namanApi.get(`/ccavenue_transaction/payment_status?order_id=${id}`)
                    .then(
                        response => {
                            const checkData = response.data
                            // console.log("checking ",checkData)
                            resolve(checkData)
                        }
                    )

            } catch (error) {
                // // console.log("🚀 ~ file: apiServices.js:34 ~ returnnewPromise ~ error:", error)
                reject(error)
            }
        }
        )
    },
    replaceSubscriptionCart: async (id) => {
        return new Promise(async (resolve, reject) => {
            try {
                await namanApi.get(`/portal/${id}/remove_subscription_carts`)
                    .then(
                        response => {
                            const checkData = response.data
                            // console.log("checking ",checkData)
                            resolve(checkData)
                        }
                    )

            } catch (error) {
                // // console.log("🚀 ~ file: apiServices.js:34 ~ returnnewPromise ~ error:", error)
                reject(error)
            }
        }
        )
    },
    replaceBulkCart: async (id) => {
        return new Promise(async (resolve, reject) => {
            try {
                await namanApi.get(`/portal/${id}/remove_bulk_carts`)
                    .then(
                        response => {
                            const checkData = response.data
                            // console.log("checking ",checkData)
                            resolve(checkData)
                        }
                    )

            } catch (error) {
                // // console.log("🚀 ~ file: apiServices.js:34 ~ returnnewPromise ~ error:", error)
                reject(error)
            }
        }
        )
    },
    editAddress: async (memberCode, id,data) => {
        return new Promise(async (resolve, reject) => {
            try {
                await namanApi.post(`/portal/${memberCode}/edit_address/${id}`, { ...data })
                    .then(
                        response => {
                            const checkData = response.data
                            // console.log("checking ",checkData)
                            resolve(checkData)
                        }
                    )

            } catch (error) {
                // // console.log("🚀 ~ file: apiServices.js:34 ~ returnnewPromise ~ error:", error)
                reject(error)
            }
        }
        )
    },
    addAdress: async (member_code,addressDataNew) => {
        return new Promise(async (resolve, reject) => {
            try {
                await namanApi.post(`/portal/${member_code}/add_address`,addressDataNew)
                    .then(
                        response => {
                            const checkData = response.data
                            // console.log("checking ",checkData)
                            resolve(checkData)
                        }
                    )

            } catch (error) {
                // // console.log("🚀 ~ file: apiServices.js:34 ~ returnnewPromise ~ error:", error)
                reject(error)
            }
        }
        )
    },
  

    issueTickets: async (member_code,data) => {
        return new Promise(async (resolve, reject) => {
            try {
                await namanApi.post(`/portal/${member_code}/add_issue_tickets`,data)
                    .then(
                        response => {
                            const checkData = response.data
                            // console.log("checking ",checkData)
                            resolve(checkData)
                        }
                    )

            } catch (error) {
                // // console.log("🚀 ~ file: apiServices.js:34 ~ returnnewPromise ~ error:", error)
                reject(error)
            }
        }
        )
    },
    issueTicketsContactSupport: async (member_name, member_mobile, member_email, related_to, query, status, escalation_matrix, issue_type) => {
        return new Promise(async (resolve, reject) => {
            try {
                await namanApi.post(`/portal/add_login_issue_tickets`, { member_name, member_mobile, member_email, related_to, query, status, escalation_matrix, issue_type })
                    .then(
                        response => {
                            const checkData = response.data
                            // console.log("checking ",checkData)
                            resolve(checkData)
                        }
                    )

            } catch (error) {
                // // console.log("🚀 ~ file: apiServices.js:34 ~ returnnewPromise ~ error:", error)
                reject(error)
            }
        }
        )
    },
    subscribeItem: async (member_code, updateData) => {
        return new Promise(async (resolve, reject) => {
            try {
                await namanApi.post(`/portal/${member_code}/add_subscription`, updateData)
                    .then(
                        response => {

                            const statusCode = response.status;
                            const responseData = response.data;
                            const result = {
                                statusCode: statusCode,
                                data: responseData
                            };

                            resolve(result)
                        }
                    )

            } catch (error) {
                // // console.log("🚀 ~ file: apiServices.js:34 ~ returnnewPromise ~ error:", error)
                reject(error)
            }
        }
        )
    },
    takeOverMagazines: async (member_code, address_id) => {
        return new Promise(async (resolve, reject) => {
            try {
                await namanApi.post(`/portal/${member_code}/takeover_gifted`, address_id)
                    .then(
                        response => {

                            const statusCode = response.status;
                            const responseData = response.data;
                            const result = {
                                statusCode: statusCode,
                                data: responseData
                            };

                            resolve(result)
                        }
                    )

            } catch (error) {
                // // console.log("🚀 ~ file: apiServices.js:34 ~ returnnewPromise ~ error:", error)
                reject(error)
            }
        }
        )
    },
    addSubscribeItem: async (member_code, updateData) => {
        return new Promise(async (resolve, reject) => {
            try {
                await namanApi.post(`/portal/${member_code}/add_subscription`, updateData)
                    .then(
                        response => {

                            const statusCode = response.status;
                            const responseData = response.data;
                            const result = {
                                statusCode: statusCode,
                                data: responseData
                            };

                            resolve(result)
                        }
                    )

            } catch (error) {
                // // console.log("🚀 ~ file: apiServices.js:34 ~ returnnewPromise ~ error:", error)
                reject(error)
            }
        }
        )
    },
    VerifyTokenGoogle: async (token) => {
        return new Promise(async (resolve, reject) => {
            try {
                await namanApi.post(`/portal/verify_captcha`, token)
                    .then(
                        response => {

                            const statusCode = response.status;
                            const responseData = response.data;
                            const result = {
                                statusCode: statusCode,
                                data: responseData
                            };

                            resolve(result)
                        }
                    )

            } catch (error) {
                // // console.log("🚀 ~ file: apiServices.js:34 ~ returnnewPromise ~ error:", error)
                reject(error)
            }
        }
        )
    },
    postTransactionInitiated: async (memberCode, data) => {
        return new Promise(async (resolve, reject) => {
            try {
                await namanApi.post(`/portal/${memberCode}/payment/secure_initiate_transaction`, data)
                    .then(
                        response => {

                            const checkData = response.data
                            resolve(checkData)
                        }
                    )

            } catch (error) {
                reject(error)
            }
        }
        )
    },
    postccAvenueTransaction: async (data) => {
       let querParam = 'amount='+data.amount;
       for(let item of data.carts)
       {
        querParam+='&carts[]='+item;
       }
       querParam+='&currency=inr'
        return new Promise(async (resolve, reject) => {
            try {
                await namanApi.get('/ccavenue_transaction/initiate?'+querParam)
                    .then(
                        response => {

                            const checkData = response.data
                            resolve(checkData)
                        }
                    )

            } catch (error) {
                reject(error)
            }
        }
        )
    },


    searchPatrikaMembers: async (patrikaCode,branchId,memberName,memberCode) => {
        return new Promise(async (resolve, reject) => {
            try {
                let querParam = branchId;
                if(memberName)
                {
                    querParam+='&member_name='+memberName
                }
                if(memberCode)
                {
                    querParam+='&member_code='+memberCode
                }
                await namanApi.get(`/portal/${patrikaCode}/search_members?branch_id=${querParam}`)
                    .then(
                        response => {

                            const checkData = response.data
                            resolve(checkData)
                        }
                    )

            } catch (error) {
                reject(error)
            }
        }
        )
    },
    getSelectedMagazine: async (id,memberCode) => {
        return new Promise(async (resolve, reject) => {
            try {
                await namanApi.get(`/portal/${memberCode}/view_subscription?id=${id}`)
                    .then(
                        response => {
                            const checkData = response.data
                            // console.log("checking ",checkData)
                            resolve(checkData)
                        }
                    )
                

            } catch (error) {
                // // console.log("🚀 ~ file: apiServices.js:34 ~ returnnewPromise ~ error:", error)
                reject(error)
            }
        }
        )
    },


}
