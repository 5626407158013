import {  Image,
    StyleSheet,
    Text,
    View,} from "react-native"
import { responsiveFontSize, responsiveScreenHeight, responsiveScreenWidth } from "react-native-responsive-dimensions"


const DataNotFound = ({height,width,fontSize,text,textColor}) => {
    return (
        <View style={{flex:1,justifyContent:"center",alignItems:"center"}}>
        <Image
        style={[styles.cardImageLoc,{width:responsiveScreenWidth(width),height:responsiveScreenHeight(height)}]}
        source={require("../../assets/images/dataNotFound.png")}
      />
        <Text style={[styles.testStyle,{fontSize:responsiveFontSize(fontSize),color:textColor}]} >{text}</Text>
      </View>
    )

}

export default DataNotFound
const styles = StyleSheet.create({

    cardImageLoc: {

        resizeMode: "contain",

      },
      testStyle:{
        fontFamily:'openSans',
            fontWeight:'600',
            marginTop: 25,
      }
})
