import { Modal, TouchableOpacity, StyleSheet, Text, View } from "react-native";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { Color, isMobileView } from "../../Common/Constants";

import React from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
const SignOutScreen = ({ navigation }) => {
  const { signOut } = useContext(AuthContext); // Assuming you have a signOut function in your AuthContext
  const [confirm, setConfirm] = useState(false);
  useEffect(() => {
    setConfirm(true);
  }, [navigation]);
  // uncommnet this for login Dispactch
  const confirmLogout = async () => {
    await AsyncStorage.setItem('login',false)

  }
  return (
    <View>
      <Modal
        animationType="fade"
        transparent={true}
        visible={confirm}
        onRequestClose={() => {
          setConfirm(!confirm);
        }}
      >
        <View
          style={{
            backgroundColor: "#808080",
            opacity: 1,
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <View
            style={{
              borderRadius: 20,
              elevation: 5,
              width: isMobileView() ? "80%" : "50%",
              height: isMobileView() ? 260 : "30%",
              backgroundColor: Color.WHITE,
              justifyContent: isMobileView() && "space-between",
              alignItems: "center",
              padding: 20,
            }}
          >
            <View>
              <Text style={{ fontWeight: "bold", color: Color.BLACK, fontSize: 25 }}>
              Are you sure you want to logout? 
              </Text>
            </View>
            <View
              style={{
                width: "100%",
                flexDirection: isMobileView() ?"column" : "row",
                height: !isMobileView() && "70%",
                justifyContent: "space-evenly",
                alignItems: "center",
                padding: 20,
                gap: isMobileView() && 20
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  confirmLogout()
                  // uncommnet this for login Dispactch
                  signOut();

                }}
                style={{
                  borderRadius: 10,
                  backgroundColor: "#ff0000",
                  width: isMobileView() ? "100%" : "30%",
                  height: isMobileView() ? "60%" : "50%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Text
                  style={{ fontSize: 20, color: Color.WHITE, fontWeight: "bold" }}
                >
                  Yes
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => {
                  navigation.navigate("MagzinepageStack"), setConfirm(!confirm);
                }}
                style={{
                  elevation: 5,
                  borderRadius: 10,
                  width: isMobileView() ? "100%" : "30%",
                  height: isMobileView() ? "60%" : "50%",
                  alignItems: "center",
                  justifyContent: "center",
                  borderWidth:3,
                  borderColor:Color.BLUE_MEDIUM
                }}
              >
                <Text
                  style={{ fontSize: 20, color: Color.BLUE_MEDIUM, fontWeight: "bold" }}
                >
                  Cancel
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
    </View>
  );
};

export default SignOutScreen;

const styles = StyleSheet.create({});
