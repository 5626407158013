import { Image, StyleSheet, Text, View,Modal, TextInput,TouchableOpacity} from "react-native";
import React, { useState } from "react";
import {
  responsiveScreenWidth,
  responsiveScreenHeight,
  responsiveFontSize,
  responsiveWidth,
} from "react-native-responsive-dimensions";
import AppLoading from "expo-app-loading";
import { useFonts } from "expo-font";
import { apiServices } from "../services/apiServices";
import Hoverable from "react-native-hoverable";
import SelectDropdown from "react-native-select-dropdown";
import BuyCardComponent from "./BuyCardComponent";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {API_URL} from "@env"


var colors = "#F1EDFA";
var ImagePath;
var TextColor;
var amount = 150;
const oldnew = 'old'
import { Color, isMobileView, isTabletView } from "./Constants";
import commonStyles from "../styles/styles";
import { is } from "date-fns/locale";
import { formatedDateWithMonth } from "../util/fomatedDate";
const MagzineCards = ({ data,refreshpage,memberCode,memberAddress,profileData,SubscriptionsData,navigation }) => {
  const quickData=data
  const profileDataX=profileData
  const [selectedValue, setSelectedValue] = useState("");
  const [magazineStatus, setMagazineStatus] = useState("Active");
  const [subscriptionDuration, setSubscriptionDuration] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [mrMrs, setMrMrs] = useState('');
  const [modalVisible,setModalVisible] = useState(false)
  const [modalVisible1,setModalVisible1] = useState(false)
  const {getStopMagazineSubscription,subscribeItem,takeOverMagazines} = apiServices
  let address_id = {
    address_id:data.sub_member_receiving,
    id:data.id
  }
  let amountTotal = amount;
 const takeOver = async () =>{
  await takeOverMagazines(memberCode, address_id)
  refreshpage()
 }

  const stopSubscription = async () =>{
    await getStopMagazineSubscription(memberCode,data.id)
    refreshpage()
  }



  const getSubscribeItem = async () => {
    await subscribeItem(
      memberCode,
      updateData

    );

    }

    const navigatewithData= async ()=>{
      await AsyncStorage.setItem('quickData',JSON.stringify(quickData));
      await AsyncStorage.setItem('profileData',JSON.stringify(profileDataX));
      navigation.navigate("member", { screen:'Quickview',params:{ memberCode: profileDataX?.member_code,id:quickData.id}})

    }

    function formatDate(dateString) {
      const date = new Date(dateString);
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear().toString().substr(-2);

      return `${month}/${year}`;
    }

  const inputDate = data.sub_startdate;
  const formattedDate = formatDate(inputDate);
  var roundedMonths

  function getDates() {
    const today = new Date();

    // Calculate date after 1 year
    const oneYearLater = new Date(today.getFullYear() + 1, today.getMonth(), today.getDate());

    // Calculate date after 2 years
    const twoYearsLater = new Date(today.getFullYear() + 2, today.getMonth(), today.getDate());

    return {
      today: today.toISOString().split("T")[0], // Convert today's date to ISO format (yyyy-mm-dd)
      oneYearLater: oneYearLater.toISOString().split("T")[0], // Convert one year later date to ISO format
      twoYearsLater: twoYearsLater.toISOString().split("T")[0], // Convert two years later date to ISO format
    };
  }
  const dates = getDates();
  const renderDropdownIcon = ({ isOpen }) => (
    <View
      style={{
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Image
                style={{
                  height: 22,
                  width: 22,

                  resizeMode:'contain',

                }}
                source={ require( "../../assets/images/dropDown.png") }
              />
    </View>
  );





  function calculateMonthsLeft(givenDate, targetDate) {
    const given = new Date(givenDate);
    const target = new Date(targetDate);

    const diffInMilliseconds = target - given;
    const diffInMonths = diffInMilliseconds / (1000 * 60 * 60 * 24 * 30);
    roundedMonths = Math.round(diffInMonths);

    if (roundedMonths === 0) {
      colors = "#feeee9";
      TextColor = '#f24d14'
      return "less than a month";
    } else if (roundedMonths === 1) {
        colors = "rgba(241, 237, 250, 1)";
        TextColor = '#f24d14'
        ImagePath = require("../../assets/images/warning.png");
        return "one month";
    } else if (roundedMonths <= 1) {
        TextColor = '#f24d14'
        colors = "#feeee9";
        ImagePath = require("../../assets/images/warning.png");
        return "Ending soon";
    } else if (roundedMonths <= 6) {
        colors = "#f1edfa";
        ImagePath = require("../../assets/images/lessMonths.png");
        TextColor = '#f24d14'
        return "Ending soon";
    } else if (roundedMonths === NaN) {
        colors = "#F1EDFA";
        TextColor = '#f24d14'
        return "No Data Availabe";
    } else {
        colors = "#eaf9f1";
        ImagePath = require("../../assets/images/moreMonths.png");
        TextColor = '#2cc373'
        return `${roundedMonths} months`;
    }
  }

  const givenDate = data.sub_startdate;
  const targetDate = data.sub_enddate;
  const monthsLeft = calculateMonthsLeft(givenDate, targetDate);
  let updateData = [{member_code:memberCode,
    amount: amountTotal,
    magazine:  data.magazine_id,
    member_address: memberAddress[0]?.address_id,
    start_date: dates.today,
    end_date: subscriptionDuration,
    quantity: quantity,
    oldnew:oldnew,
    id: data.id,
    currency:data.sub_currency,
    dispatch_type:data.sub_dispatchtype,
    dispatch_mode:data.sub_dispatchmode,
    dispatch_to:data.sub_dispatchto,
    subscription_type:data.sub_subtyp,
    member_receiving:data.sub_member_receiving
  }]
  let [fontsLoaded] = useFonts({
    openSans: require("../../assets/fonts/OpenSans-Italic-VariableFont_wdth,wght.ttf"),
  });
  if (!fontsLoaded) {
    return <AppLoading />;
  }

  return (
    <>
    <View style={[styles.cardCointainer,{justifyContent:"space-between"}]}>
    <View
    style={[{justifyContent:"space-between",height:"86%",gap:8}]}
    >

      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: 10,
        }}
      >
        <View style={[{flexDirection:"row",width:"95%" },commonStyles.justifyBetween]}>
          <View style={[commonStyles.flexRow]}>

 {  data?.image &&      <Image
          style={styles.magImage}
          // source={require("../../assets/images/img.png")}
          source={{ uri:`${API_URL}`+'assets/img/magazine_images/'+`${data?.image}`}}

          resizeMode="contain"
        />}
        <View>
        <Text style={[styles.magTitle,!data?.image && {marginLeft: 20}]}>{data?.magazine_name ? data?.magazine_name : "Name Not Found" }</Text>
        {/* <Text style={styles.magSubtitle}>Language - Hindi</Text> */}
        <Text style={[styles.comboTitle,!data?.image && {marginLeft: 20}]} numberOfLines={1}>Combo ID - {data?.combo_id}</Text>
        </View>
        </View>

        <View >
        <Text style={magazineStatus === 'Active' ? styles.magStatusActive : styles.magStatusInactive  }>{magazineStatus}</Text>
        </View>
        </View>
        {/* <Hoverable style={{}}>
                {({ hovered }) => (
        <TouchableOpacity
        onPress={() => setModalVisible(true)}
        style={{backgroundColor: hovered ? "background: rgba(0, 0, 0, 0.05);" : Color.WHITE,borderRadius: 50,height:40,width:40,alignItems:"center",justifyContent:"center",marginRight:10,marginTop:0}}
        >
          <Image
            style={{
              width: responsiveScreenWidth(2.8),
              height: responsiveScreenHeight(2.8),
            }}
            source={require("../../assets/images/arrow.png")}
          />
        </TouchableOpacity>
        )}
        </Hoverable> */}
      </View>
      <View
        style={{
          borderWidth: 0,
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",

          paddingHorizontal:20
        }}
      >
        <View style={styles.insideCardLeft}>
          <Image
            style={styles.cardImage}
            source={require("../../assets/images/cal.png")}
            resizeMode="contain"
          />
          <View >
            <Text style={styles.datesTime}>Duration</Text>
            <Text
              style={{
                fontFamily: "openSans",
                fontSize: isMobileView() ? responsiveFontSize(1.9) :  17.5743,
                color: "rgba(0, 0, 0,)",
                fontWeight: "600",
              }}
            >
              {formatedDateWithMonth(data.sub_startdate)} - {formatedDateWithMonth(data.sub_enddate)}
            </Text>
          </View>
        </View>
        <View
          style={[styles.insideCardRight, { backgroundColor: `${colors}` }]}
        >
          <Image style={styles.cardImage} source={ImagePath} resizeMode="contain" />
          <View >
            <Text style={styles.datesTime}>Ends in</Text>
            <Text
              style={{
                fontSize: isMobileView() ? responsiveFontSize(1.9) : 17.5743,
                color: `${TextColor}`,
                fontWeight: "600",
              }}
            >
              {monthsLeft}
            </Text>
          </View>
        </View>
      </View>
      <View style={[styles.locationDate]}>
        <Image
          style={styles.cardImageLoc}
          source={require("../../assets/images/location.png")}
        />
        <View>


          <Text
          style={styles.startEndDate}
        >
          Address
        </Text>
        <View style={[{flexDirection:"column"}]}>
        <Text
            style={{
              fontSize: isMobileView() ? responsiveFontSize(1.7)  :13.1807,
              color: Color.GREY_BRIGHT,
              fontWeight: "600",
              maxWidth: isMobileView() && "95%" || 250.4,
            }}
            numberOfLines={2}
            ellipsizeMode='tail'
          >
            {`${data?.address?.adr_line1}, ${data?.address?.adr_line2}`}
             </Text>
          <Text
            style={{
              fontSize: isMobileView() ? responsiveFontSize(1.7)  :13.1807,
              color: Color.GREY_BRIGHT,
              fontWeight: "600",
              maxWidth: isMobileView() && "95%" || 250.4,
              whiteSpace:"normal"
            }}
            // numberOfLines={2}
            // ellipsizeMode='tail'
          >

             {`${data?.address?.city_name} - ${data?.address?.pincode}` }
            {/* {data?.sub_member_address} */}

          </Text>
          <Text
            style={{
              fontSize: isMobileView() ? responsiveFontSize(1.7)  :13.1807,
              color: Color.GREY_BRIGHT,
              fontWeight: "600",
              maxWidth: isMobileView() && "95%" || 250.4,
              whiteSpace:"normal"
            }}
            // numberOfLines={2}
            // ellipsizeMode='tail'
          >

             {`${data?.address?.district_name} - ${data?.address?.state_name} - ${data?.address?.country_name}` }
            {/* {data?.sub_member_address} */}

          </Text>
          <Text
            style={{
              fontSize: isMobileView() ? responsiveFontSize(1.7)  :13.1807,
              color: Color.GREY_BRIGHT,
              fontWeight: "600",
              maxWidth: isMobileView() && "95%" || 250.4,
              whiteSpace:"normal"
            }}
            // numberOfLines={2}
            // ellipsizeMode='tail'
          >

             {`Zone - ${data?.address?.zone_name}` }
            {/* {data?.sub_member_address} */}

          </Text>
          <Text
            style={{
              fontSize: isMobileView() ? responsiveFontSize(1.7)  :13.1807,
              color: Color.GREY_BRIGHT,
              fontWeight: "600",
              maxWidth: isMobileView() && "95%" || 250.4,
              whiteSpace:"normal"
            }}
            // numberOfLines={2}
            // ellipsizeMode='tail'
          >

             {`Branch - ${data?.address?.branch_name}` }
            {/* {data?.sub_member_address} */}

          </Text>
          </View>

        </View>
      </View>
      <View style={[styles.locationDate,{paddingTop:0}]}>
        <Image
          style={styles.cardImageLoc}
          source={require("../../assets/images/upload.png")}
        />
        <View>
          <Text style={styles.startEndDate}>Dispatch Mode</Text>
          <Text
            style={{
              fontSize: isMobileView() ? responsiveFontSize(1.7)  : responsiveFontSize(0.9),
              color: Color.GREY_BRIGHT,
              fontWeight: "600",
            }}
          >
            {data.dispatch_mode_name}
          </Text>
        </View>
      </View>
    </View>

      <View style={[commonStyles.flexRow,commonStyles.alignCenter,commonStyles.justifyBetween,styles.cardAction]}>
      <Text
            style={{
              fontSize: isMobileView() ? responsiveFontSize(1.6) : 11.7162,
              color: Color.GREY_BRIGHT,
              fontWeight: "600",
              width:"80%"
            }}
          >
            For renew ,payment received, help, address update click here
          </Text>
          <Hoverable>
{({ hovered }) => (
        <TouchableOpacity
          // onPress={() => setModalVisible(true)}
          // onPress={() => }
          onPress={() => {navigatewithData()}}
          style={{
            justifyContent: "Center",
            alignItems: "center",
            flexDirection: "row",
            borderRadius: 8,
            borderColor: Color.BLUE_MEDIUM,
            height: 32,
            width: 90,
            backgroundColor: hovered ? Color.BLUE_MEDIUM : Color.BLUE_LIGHT,
          }}
        >

          <Text
            style={{
              fontFamily: "openSans",
              fontWeight: "600",
              fontSize: 11,
              color:    hovered ? Color.WHITE : Color.BLUE_MEDIUM ,
            }}
          >
            View Details
          </Text>
        </TouchableOpacity>
          )}
          </Hoverable>
      </View>
{/* Modal */}





    </View>
    <Modal
    animationType="fade"
    transparent={true}
  visible={modalVisible}
  onRequestClose={() => {
    Alert.alert("Modal has been closed.");
    setModalVisible(!modalVisible);
  }}
>
  <View style={styles.centeredView}>
    <View style={styles.modalView}>


      {/* sideB */}
      <View
        style={{
          flex: 1,
          borderWidth: 0,
          height: "100%",
          width: "100%",
          backgroundColor: "#ffffff",
          borderRadius:12
        }}
      >
        <View style={{flexDirection:"row",justifyContent:"space-between",padding:20,alignItems:"center" }}>


        <View>
          <Text
            style={{
              fontWeight: "600",
              fontSize: responsiveFontSize(1.5),
            }}
          >
            Quick View
          </Text>
        </View>
        <View style={{ alignItems: "flex-end" }}>
          <TouchableOpacity
            onPress={() => setModalVisible(!modalVisible)}
          >
            <Image
              style={{ width: 30, height: 30 }}
              source={require("../../assets/images/cross.png")}
            />
          </TouchableOpacity>
        </View>
        </View>
        <View style={{  borderRadius: 16.839,
      borderWidth: 3,
      borderColor: '#EAF9F1',
      backgroundColor: '#FFF',width:'85%',height:'77%',alignSelf:'center'}}>
            <View style={{borderWidth:0,borderColor:'red',flexDirection:'row',paddingVertical:15,paddingRight:15,paddingLeft:2,alignItems:"flex-end"}}>
            <View style={{borderWidth:0}}>
              <Image style={{width:100,height:100}} source={require('../../assets/images/img.png')}/>
              </View>

              <View style={{borderWidth:0,marginBottom:5,}}>
              <Text style={{fontSize:29,fontWeight:'600'}}>{data.magazine_name}</Text>
              <Text style={{fontSize:17,fontWeight:'500',opacity:0.5}}>Copies {quantity}, Language {}</Text>
            </View>
            </View>

        <View style={{width:'100%',borderWidth:0,padding:20,paddingTop:5,flexDirection:'row',justifyContent:"space-between"}}>

            <View style={{backgroundColor:Color.WHITE,     shadowColor: 'rgba(0, 0, 0, 0.15)',
        shadowOffset: {
          width: 0,
          height: 0,
        },
        shadowRadius: 5.22631549835205,
        shadowOpacity: 1.4032894372940063,borderRadius:10,padding:15,width:170,height:150,justifyContent:"space-between"}}>
              <View style={{borderWidth:0,}}>
                <Image style={{width:40,height:40}} source={require('../../assets/images/cal.png')}/>
              </View>
              <View>
                <Text style={{fontSize:16,fontWeight:'600',opacity:0.4}}>Start From</Text>
                <Text style={{fontSize:20,fontWeight:'600'}}>Jan 1</Text>
              </View>
            </View>


            <View style={{backgroundColor:Color.WHITE,     shadowColor: 'rgba(0, 0, 0, 0.15)',
        shadowOffset: {
          width: 0,
          height: 0,
        },
        shadowRadius: 5.22631549835205,
        shadowOpacity: 1.4032894372940063,borderRadius:10,padding:15,width:170,height:150,justifyContent:"space-between"}}>
              <View style={{borderWidth:0,}}>
                <Image style={{width:40,height:40}} source={require('../../assets/images/priceTag.png')}/>
              </View>
              <View>
                <Text style={{fontSize:16,fontWeight:'600',opacity:0.4}}>Price</Text>
                <Text style={{fontSize:20,fontWeight:'600'}}>₹700.00</Text>
              </View>
            </View>


            <View style={{backgroundColor:Color.WHITE,     shadowColor: 'rgba(0, 0, 0, 0.15)',
        shadowOffset: {
          width: 0,
          height: 0,
        },
        shadowRadius: 5.22631549835205,
        shadowOpacity: 1.4032894372940063,borderRadius:10,padding:15,width:170,height:150,justifyContent:"space-between"}}>
              <View style={{borderWidth:0,}}>
                <Image style={{width:40,height:40}} source={require('../../assets/images/typeIcon.png')}/>
              </View>
              <View>
                <Text style={{fontSize:16,fontWeight:'600',opacity:0.4}}>Type</Text>
                <Text style={{fontSize:20,fontWeight:'600'}}>New</Text>
              </View>
            </View>


            <View style={[{borderRadius:10,padding:15,width:170,height:150,justifyContent:"space-between"},, { backgroundColor: `${colors}` }]}>
              <View style={{borderWidth:0,}}>
                <Image style={{width:40,height:40}} source={ImagePath}/>
              </View>
              <View>
                <Text style={{fontSize:16,fontWeight:'600',opacity:0.4}}>Ends in</Text>
                <Text style={[{fontSize:20,fontWeight:'600'},{color: `${TextColor}`,}]}>{monthsLeft}</Text>
              </View>
            </View>
           {/* location */}

        </View>
            <View style={{}}>
              <View style={{flexDirection:'row',borderWidth:0,paddingHorizontal:20,justifyContent:"space-between",gap:20}}>



              <View style={{flex:1,borderWidth:0,flexDirection:'row',alignItems:'center',gap:25}}>
                <Image style={{height:55,width:55}}
                  source={require("../../assets/images/location.png")}/>
                  <View>
                  <Text style={{fontSize:20,fontWeight:'600'}}>Aurangabad - 431001</Text>
                  <Text style={{fontSize:15,fontWeight:'600',opacity:0.5}}>Location Pin</Text>
                  </View>
              </View>
              <View style={{flex:1,borderWidth:0,flexDirection:'row',alignItems:'center',gap:25}}>
                <Image style={{height:55,width:55}}
                  source={require("../../assets/images/membersIcon.png")}/>
                  <View>
                  <Text style={{fontSize:20,fontWeight:'600'}}>{profileData?.member_name}</Text>
                  <Text style={{fontSize:15,fontWeight:'600',opacity:0.5}}>#{profileData?.member_code}</Text>
                  </View>
              </View>
              </View>
              <View style={{borderWidth:0, flexDirection:'row',alignItems:'center',justifyContent:'space-between',paddingHorizontal:90,paddingVertical:20,top:10}}>

              <Hoverable>
{({ hovered }) => (

      <TouchableOpacity
      onPress={() => {navigation.navigate("member", { screen:'OnlinePayments'})
            setModalVisible(!modalVisible)

    }}

      // onPress={()=>{navigation.navigate("Receiptpage")}}
       style={[{borderColor:Color.BLUE_MEDIUM,width:130,height:50,borderRadius:10,justifyContent:'center',alignItems:'center',borderWidth:2,},{backgroundColor: hovered  ? Color.BLUE_MEDIUM : Color.WHITE}]}>
          <Text style={[{fontWeight:'600'},{color: hovered  ? Color.WHITE : Color.BLUE_MEDIUM}]}>Receipts</Text>
        </TouchableOpacity>
)}
                </Hoverable>
                {/* <Hoverable>
{({ hovered }) => (
      <TouchableOpacity onPress={()=>{

        setModalVisible(!modalVisible)
        setModalVisible1(!modalVisible1)
        }} style={[{width:130,height:50,borderRadius:10,justifyContent:'center',alignItems:'center'},{backgroundColor: hovered  ? Color.BLACK : Color.BLUE_MEDIUM}]}>
          <Text style={{color:Color.WHITE,fontWeight:'600'}}>Upgrade</Text>
        </TouchableOpacity>
        )}
        </Hoverable> */}
        <Hoverable>
{({ hovered }) => (
      <TouchableOpacity onPress={()=>{stopSubscription(),setModalVisible(!modalVisible)}}
      style={[{width:130,height:50,borderRadius:10,justifyContent:'center',alignItems:'center'},{backgroundColor: hovered  ? Color.BLACK : "red"}]}
      >
          <Text style={{color:Color.WHITE,fontWeight:'600'}}>Stop</Text>
        </TouchableOpacity>
                )}
                </Hoverable>
        {data.sub_member_receiving===null|| ""? <View></View>:
      <Hoverable>
      {({ hovered }) => (
      <TouchableOpacity onPress={()=>{takeOver(),setModalVisible(!modalVisible)}}
      style={[{width:130,height:50,borderRadius:10,justifyContent:'center',alignItems:'center'},{backgroundColor: hovered  ? Color.BLACK : "blue"}]}
      >
          <Text style={{color:Color.WHITE,fontWeight:'600'}}>TakeOver</Text>
        </TouchableOpacity>
            )}
            </Hoverable>

        }


      </View>



            </View>
        </View>



      </View>
    </View>
  </View>
</Modal>

{/* Modal for upgrade */}
<Modal
        animationType="fade"
        transparent={true}
        visible={modalVisible1}
        onRequestClose={() => {
          Alert.alert("Modal has been closed.");
          setModalVisible1(!modalVisible1);
        }}
      >
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <View
              style={{
                flex: 2,
                borderWidth: 0,
                height: "100%",
                width: "100%",
                backgroundColor: "#f8f9fa",
                borderBottomLeftRadius:20,
                borderTopLeftRadius:20,
                padding: 20,
              }}
            >
              <View style={{   alignItems: "flex-start" }}>
                <Text style={styles.modalText}>Upgrade Subscription</Text>
              </View>
              <View style={{paddingTop:20,height:"100%"}}>

              <View style={styles.textBoxView}>
                <View style={{ width: "54.5%" }}>
                  <Text
                    style={{
                      fontWeight: "600",
                      fontSize: responsiveFontSize(1.1),
                      margin: 0,
                    }}
                  >
                    Title
                  </Text>
                  <SelectDropdown
                    defaultButtonText="Mr."
                    dropdownStyle={{
                      height: 50,
                      width:100,
                      borderRadius: 5,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    buttonStyle={{
                      width: "80%",
                      height: "50%",
                      marginTop:10,
                      backgroundColor: Color.WHITE,
                      alignItems: "center",
                      justifyContent: "space-between",
                      borderRadius: 10,
                      borderWidth: 1,
                      borderColor: "rgba(0, 0, 0, 0.14)",
                      padding:10
                    }}
                    buttonTextStyle={{
                      color: "rgba(0, 0, 0, 0.30)",
                      fontSize: responsiveFontSize(1.1),
                      textAlign: "left",
                      margin: 0,
                      marginVertical: 0,
                      fontWeight: "600",
                    }}
                    data={["Mr.", "Mrs."]}
                    onSelect={(selectedItem, index) => {
                      setMrMrs(selectedItem)
                    }}

                    renderDropdownIcon={(props) => renderDropdownIcon(props)}
                  />
                </View>
                <View style={{ width: "54.5%" }}>
                  <Text
                    style={{
                      fontWeight: "600",
                      fontSize: responsiveFontSize(1.1),
                      margin: 0,
                    }}
                  >
                    Full Name
                  </Text>
                  <Text style={styles.textboxInput}>
                    {profileData?.member_name}
                  </Text>
                </View>
              </View>
              <View style={styles.textBoxView}>
                <View style={{ width: "54.5%" }}>
                  <Text
                    style={{
                      fontWeight: "600",
                      fontSize: responsiveFontSize(1.1),
                      margin: 0,
                    }}
                  >
                    Mobile Number
                  </Text>
                  <Text style={styles.textboxInput}>{profileData?.mobile}</Text>
                </View>
                <View style={{ width: "54.5%" }}>
                  <Text
                    style={{
                      fontWeight: "600",
                      fontSize: responsiveFontSize(1.1),
                      margin: 0,
                    }}
                  >
                    Email
                  </Text>
                  <Text style={styles.textboxInput}>{profileData?.email}</Text>
                </View>
              </View>
              <View style={styles.textBoxView}>
                <View style={{ width: "54.5%" }}>
                  <Text
                    style={{
                      fontWeight: "600",
                      fontSize: responsiveFontSize(1.1),
                      margin: 0,
                    }}
                  >
                    Care Of
                  </Text>
                  <SelectDropdown
                  defaultButtonText="Select"
                  dropdownStyle={{
                    height: 50,
                    borderRadius: 5,
                    justifyContent: "center",
                    width:100,
                    alignItems: "center",
                  }}
                  buttonStyle={{
                    width: "80%",
                    height: "50%",
                    marginTop:10,
                    backgroundColor: Color.WHITE,
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderRadius: 10,
                    borderWidth: 1,
                    borderColor: "rgba(0, 0, 0, 0.14)",
                    padding:10
                  }}
                  buttonTextStyle={{
                    color: "rgba(0, 0, 0, 0.30)",
                    fontSize: responsiveFontSize(1.1),
                    textAlign: "left",
                    margin: 0,
                    marginVertical: 0,
                    fontWeight: "600",
                  }}
                  data={["Mr.", "Mrs."]}
                  onSelect={(selectedItem, index) => {
                  }}

                  renderDropdownIcon ={(props) => renderDropdownIcon(props)}
                  />
                </View>
                <View style={{ width: "54.5%" }}>
                  <Text
                    style={{
                      fontWeight: "600",
                      fontSize: responsiveFontSize(1.1),
                      margin: 0,
                    }}
                  >
                    Pin Code
                  </Text>
                  <Text
                    style={styles.textboxInput}
                  >{memberAddress?.pincode}</Text>
                </View>
              </View>
              <View style={styles.textBoxView}>
                <View style={{ width: "54.5%" }}>
                  <Text
                    style={{
                      fontWeight: "600",
                      fontSize: responsiveFontSize(1.1),
                      margin: 0,

                    }}
                  >
                    Subscription Plan
                  </Text>
                  <SelectDropdown
                  rowTextStyle={{    fontFamily:"openSans",
                }}
                defaultButtonText="Select Your Plan"
                dropdownStyle={{
                  height: 200,
                  width:100,
                  borderRadius: 5,
                  alignItems: "center",
                  justifyContent: "center",

                }}
                buttonStyle={{
                  width: "80%",
                  height: "50%",
                  marginTop:10,
                  backgroundColor: Color.WHITE,
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderRadius: 10,
                  borderWidth: 1,
                  borderColor: "rgba(0, 0, 0, 0.14)",
                  padding:10,
                }}
                buttonTextStyle={{
                  color: "rgba(0, 0, 0, 0.30)",
                  fontSize: responsiveFontSize(1.1),
                  textAlign: "left",
                  margin: 0,
                  marginVertical: 0,
                  fontWeight: "600",
                }}


                renderDropdownIcon={(props) => renderDropdownIcon(props)}
                data={SubscriptionsData.map((item)=>item.subscription_type_name)}
                    onSelect={(item) => {setSubscriptionDuration(item)}}
                    buttonTextAfterSelection={(selectedItem)=>selectedItem}


                  />
                </View>
                <View style={{ width: "54.5%" }}>
                  <Text
                    style={{
                      fontWeight: "600",
                      fontSize: responsiveFontSize(1.1),
                      margin: 0,
                    }}
                  >
                    Quantity
                  </Text>
                  <TextInput
                    onChangeText={(text) => {
                      setQuantity(text);
                    }}
                    placeholderTextColor={Color.GREY_BRIGHT}
                    placeholder="1"
                    style={styles.textboxInput}
                  />
                </View>
              </View>


              {/* Address */}
              <View style={{ borderWidth: 0, height: "29%", padding: 10 }}>
                <View>
                  <Text
                    style={{
                      fontWeight: "600",
                      fontSize: responsiveFontSize(1.1),
                      margin: 0,
                      fontFamily:"openSans",

                    }}
                  >
                    Select your Address*
                  </Text>
                </View>


          {
            memberAddress.length > 0 &&
                  <SelectDropdown
                  rowTextStyle={{    fontFamily:"openSans",

                }}
                  style={{ borderWidth: 1 }}
                  data={memberAddress.map((item) => item.full_address)}
                  onSelect={(item) => setSelectedValue(item)}
                  buttonTextAfterSelection={(selectedItem) => selectedItem}
                  defaultButtonText="Select your Address"
                  buttonStyle={{
                    width: "100%",
                    backgroundColor: Color.WHITE,
                    borderRadius: 10,
                    marginTop: 10,
                    borderWidth: 1,
                    borderColor: "rgba(0, 0, 0, 0.14)",
                  }}
                  dropdownStyle={{
                    width: 400,
                    backgroundColor: Color.WHITE,
                    borderRadius: 20,
                    borderWidth: 1,
                    borderColor: "rgba(0, 0, 0, 0.14)",
                    fontFamily:"openSans",

                  }}
                  renderDropdownIcon={(props) => renderDropdownIcon(props)}
                  buttonTextStyle={{
                    color: "rgba(0, 0, 0, 0.30)",
                    fontSize: responsiveFontSize(1.1),
                    textAlign: "left",
                    margin: 0,
                    marginVertical: 0,
                    fontWeight: "600",
                  }}
                />}
              </View>


              </View>

            </View>

            {/* sideB */}
            <View
              style={{
                flex: 1,
                borderWidth: 0,
                height: "100%",
                width: "100%",
                backgroundColor: "#ffffff",
                borderBottomRightRadius:20,
                borderTopRightRadius:20,
                padding: 20,
              }}
            >
              <View style={{ alignItems: "flex-end" }}>
                <TouchableOpacity
                  onPress={() => setModalVisible1(!modalVisible1)}
                >
                  <Image
                   style={{ width: 30, height: 30 }}
                    source={require("../../assets/images/cross.png")}
                  />
                </TouchableOpacity>
              </View>
              <View style={{ paddingTop: 20 }}>
                <Text
                  style={{
                    fontWeight: "600",
                    fontSize: responsiveFontSize(1.6),
                  }}
                >
                  Subscription Preview
                </Text>
              </View>
              <View>
                <BuyCardComponent data={data} />
                <View style={{top:"5%"}}>
                  <View
                    style={{
                      width: "100%",
                      borderWidth: 0,

                      justifyContent: "center",

                      margin: 10,
                    }}
                  >
                     <Hoverable style={{alignItems:"center",height:40}}>
      {({ hovered }) => (
                    <TouchableOpacity
                      onPress={() => {
                        getSubscribeItem()
                      }}
                      style={{
                        backgroundColor: hovered ? Color.BLACK : Color.BLUE_MEDIUM,
                        width: "50%",
                        height: "100%",
                        borderRadius: 10,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Text style={{ color: "#FFF", fontWeight: "600" }}>
                        Add to cart
                      </Text>
                    </TouchableOpacity>
                       )}
                       </Hoverable>
                  </View>
                  <View
                    style={{
                      width: "100%",
                      borderWidth: 0,

                      justifyContent: "center",
                      margin: 10,
                    }}
                  >
                       <Hoverable style={{alignItems:"center",height:40}}>
      {({ hovered }) => (
                    <TouchableOpacity
                      onPress={() => {
                        navigation.navigate("Cartpage", { memberCode }),
                          setModalVisible(!modalVisible);
                      }}
                      style={{
                        backgroundColor: hovered ? Color.BLUE_MEDIUM : "#FFF",
                        borderWidth: 2,
                        borderColor: Color.BLUE_MEDIUM,
                        width: "50%",
                        height: "100%",
                        borderRadius: 10,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Text style={{ color: hovered ? Color.WHITE : Color.BLUE_MEDIUM, fontWeight: "600" }}>
                        Go to Cart
                      </Text>
                    </TouchableOpacity>
                      )}
                      </Hoverable>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
      </Modal>
</>

  );
};

export default MagzineCards;

const styles = StyleSheet.create({
  magSubtitle: {
    fontFamily: "openSans",
    fontWeight: "700",
    fontSize: isMobileView() ? responsiveFontSize(1.8) : responsiveFontSize(1) ,
    color: 'rgba(0, 0, 0, 0.50)',
  },
  magStatusActive:{
    fontWeight: "700",
    fontSize:isMobileView() ? responsiveFontSize(1.7) : responsiveFontSize(1),
    color: Color.BLUE_MEDIUM,
    borderBottomWidth:2,
    borderBottomColor:Color.BLUE_MEDIUM,

  },
  magStatusInactive:{
    fontWeight: "700",
    fontSize: isMobileView() ?  responsiveFontSize(1.6) : responsiveFontSize(.9),
    color: 'rgba(0, 0, 0, 0.50)',
    borderBottomWidth:2,
    borderBottomColor:'rgba(0, 0, 0, 0.50)',
    borderStyle:"dashed"

  },









  textBoxView: {
    width: "100%",

    margin: 2.5,
    height: "15%",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: 0.5,
  },

  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    width:'100%',
    height:'100%',
    alignSelf:'center',
    backdropFilter: 'blur(20px)',
    backgroundColor: Color.GREY_BRIGHT,
  },
  modalView: {
    flex: 1,
    backgroundColor: "white",
    borderRadius: 20,


    alignItems: "center",
    shadowColor: Color.BLACK,
    marginTop: 45,
    marginBottom: 45,
    flexDirection: "row",
    height: "60%",
    width: "60%",


  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
  buttonOpen: {
    backgroundColor: "#F194FF",
  },
  buttonClose: {
    backgroundColor: "#2196F3",
  },
  textStyle: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
  },
  modalText: {
    marginBottom: 15,
    textAlign: "center",
    fontWeight: "700",
    fontSize: responsiveFontSize(1.8),
  },
  textboxInput: {
    borderWidth: 2,
    borderColor: "rgba(0, 0, 0, 0.14)",
    backgroundColor: "#FFF",
    height: "30%",
    width: "85%",
    borderRadius: 5,
    paddingLeft: 10,
  },


  cardCointainer: {
    width: isMobileView() &&  "fit-content" || isTabletView() && !isMobileView() &&  responsiveScreenWidth(39)  || !isMobileView() && !isTabletView() && 337.92,
    height: isMobileView() &&  "fit-content" ||  isTabletView() && !isMobileView() &&  responsiveScreenHeight(58)  || !isMobileView() && !isTabletView() &&  492.48,
    borderWidth: 2,
    borderColor:'rgba(234, 249, 241, 1)',
    borderRadius: 10,
    shadowOpacity: 0.1,
    margin: !isMobileView() && isTabletView() && 10 || !isMobileView() && !isTabletView() &&  10,
    backgroundColor: "#FFF",
    shadowOffset: { width: 1, height: 1 },
    marginBottom: isMobileView() ? 20 : 10,
  },
  magImage: {
    height: isMobileView() && responsiveScreenWidth(24) || !isMobileView() && isTabletView() && responsiveScreenHeight(10)  || !isMobileView() && !isTabletView() &&  80,
    width:isMobileView() && responsiveScreenWidth(26) || !isMobileView() && isTabletView() && responsiveScreenWidth(8)   || !isMobileView() && !isTabletView() &&  80,
    top: isMobileView() ? -2 : 0,
    left: isMobileView() ? -4 : 0,
  },
  magTitle: {
    fontFamily: "openSans",
    fontWeight: "600",
    fontSize: isMobileView() ? responsiveFontSize(2) : 19.0388,
    maxWidth:250  },
    comboTitle: {
      fontFamily: "openSans",
      opacity: 0.5,
      fontWeight: "600",
      fontSize: isMobileView() ? responsiveFontSize(1.8) : 17,
      maxWidth:170  },
  insideCardLeft: {
    backgroundColor:'#FFF',
    top: 5,
    borderwidth: 0,
    justifyContent:"space-between",
    width: isTabletView() ? "42%" : 138.24,
    height: !isMobileView() && isTabletView() && responsiveScreenHeight(16) || !isMobileView() && !isTabletView() &&   112.32,
    borderRadius: 10,
    padding: 13,
    shadowColor: 'rgba(0, 0, 0, 0.15)',
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 1,
    shadowRadius: 6,
    elevation: 2,
  },
  insideCardRight: {
    backgroundColor:'#FFF',
    top: 5,
    borderwidth: 0,
    justifyContent:"space-between",
    width: isTabletView() ? "42%" : 138.24,
    height: !isMobileView() && isTabletView() && responsiveScreenHeight(16) || !isMobileView() && !isTabletView() &&   112.32,
    borderRadius: 10,
    padding: 13,

  },
  cardImage: {
    width: isMobileView() && responsiveScreenWidth(8) || isTabletView() && !isMobileView() &&  responsiveScreenHeight(4)  || !isMobileView() && !isTabletView() && 38.4,
    height: isMobileView() && responsiveScreenWidth(8) || isTabletView() && !isMobileView() && responsiveScreenHeight(4)  || !isMobileView() && !isTabletView() &&  33.696,
    left: -4,

  },
  cardAction:{
    borderTopWidth:1,
    borderTopColor:'rgba(0, 0, 0, 0.15)',
    marginHorizontal: isMobileView() ? 16  :  10,
    marginBottom: isMobileView() ? 16  :  10,
    paddingTop:12
  },
  cardImageLoc: {
    width: isTabletView() ? responsiveScreenHeight(6)  : 46,
    height: isTabletView() ? responsiveScreenHeight(6) : 46,
    margin: 5,
  },
  datesTime: {
    fontSize: isMobileView() ? responsiveFontSize(1.7)  :  13.1807,
    color: "rgba(0, 0, 0, 0.15)",
    fontWeight: "600",
  },
  locationDate: {
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 10,
    borderWidth: 0,
    gap: 10,
    // height: responsiveScreenHeight(17)
  },
  startEndDate: {
    fontSize: isMobileView() ? responsiveFontSize(2)  : 18.3066,
    color: "rgba(0, 0, 0)",
    fontWeight: "600",
    centeredView: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      width:'100%',
      height:'100%',
      alignSelf:'center',
      backdropFilter: 'blur(20px)',
      backgroundColor: Color.GREY_BRIGHT,
    },
    modalView: {
      flex: 1,
      backgroundColor: "white",
      borderRadius: 20,

      alignItems: "center",
      shadowColor: Color.BLACK,
      marginTop: 35,
      marginBottom: 35,

      flexDirection: "row",
      height: "80%",
      width: "80%",
    },
    button: {
      borderRadius: 20,
      padding: 10,
      elevation: 2,
    },
    buttonOpen: {
      backgroundColor: "#F194FF",
    },
    buttonClose: {
      backgroundColor: "#2196F3",
    },
    textStyle: {
      color: "white",
      fontWeight: "bold",
      textAlign: "center",
    },
    modalText: {

      fontFamily:"openSans",
      fontWeight: "600",
      fontSize: responsiveFontSize(1.5),
    },
    textboxInput: {
        width: "80%",
        height: "50%",
        marginTop:10,
        backgroundColor: Color.WHITE,
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 10,
        borderWidth: 1,
        borderColor: "rgba(0, 0, 0, 0.14)",
        color: "rgba(0, 0, 0, 0.30)",
        fontSize: responsiveFontSize(1.1),
        textAlign: "left",
        padding: 10,

        fontWeight: "600",
    },

    // cardImage: {
    //   width: responsiveScreenWidth(3),
    //   height: responsiveScreenHeight(4),
    // },




  },
});
