import {
  Image,
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Modal,
  TextInput,
  ScrollView,
  TouchableWithoutFeedback,
  Alert
} from "react-native";
import React, { useContext, useEffect, useRef, useState } from "react";
import Headerdiv from "../Common/Headerdiv";
import {
  responsiveFontSize,
  responsiveHeight,
  responsiveScreenFontSize,
  responsiveScreenHeight,
  responsiveScreenWidth,
  responsiveWidth,
} from "react-native-responsive-dimensions";

import DatePicker from 'react-native-modern-datepicker'
import AppLoading from "expo-app-loading";
import { useFonts } from "expo-font";
import SecondaryHeaderdiv from "../Common/SecondaryHeaderdiv";
import Profilecard from "../Common/Profilecard";
import Addresscomponent from "../Common/Addresscomponent";
import { apiServices } from "../services/apiServices";
import SelectDropdown from "react-native-select-dropdown";
import AsyncStorage from "@react-native-async-storage/async-storage";
import PopupComponent from "../Common/PopupComponent";
import ErrorContext from "../context/ErrorContext";
import Hoverable from "react-native-hoverable";
import { ActivityIndicator } from "react-native";
import { SafeAreaView } from "react-native";
import { Color, isLargeLaptop, isMobileView, isOnlyLaptopView, isOnlyTabletView, isSmallLaptop, isTabletView } from "../Common/Constants";
import { FlatList } from "react-native";
import commonStyles from "../styles/styles";
import MultiSelect from "react-native-multiple-select";
import { formatedDateWithStandardFormat,formatDOBDate } from "../util/fomatedDate";
import DateTimePicker from 'react-native-ui-datepicker';
var memberAddressDataGet;


const Profilepage = ({ route, navigation }) => {
  const currentDate = new Date();
  const datePickerRef = useRef(null);
  const formattedCurrentDate = currentDate.toISOString().split('T')[0];
  const { error, dispatchError, dispatchSuccess } = useContext(ErrorContext);
  //modal show
  const [modalVisible, setModalVisible] = useState(false);
  const [profileModalVisible, setProfileModalVisible] = useState(false);

  const [profileData, setProfileData] = useState({});
  const [memberAddress, setMemberAddress] = useState(["abc"]);

  //adding new data
  const [newStreet, setNewStreet] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  // const [newCountry, setNewCountry] = useState("");
  // const [newCity, setNewCity] = useState("");
  const [newZipcode, setNewZipcode] = useState("");

  //dropdown menu states
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [selectedBranch, setSelectedBranch] = useState("");
  const [selectedZone, setSelectedZone] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [branchesListItem, setBranchesListItem] = useState([]);

  const [selectedCareOfType,setSelectedCareOfType] = useState("");
  const [selectedCareOfName,setSelectedCareOfName] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedAlternateNumber,setSelectedAlternateNumber] = useState("");
  const [selectedTitle,setSelectedTitle] = useState('')
  const [selectedItems, setSelectedItems] = useState([]);

  // rendering data in new address
  const [stateList, setStateList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [pincodeList, setPincodeList] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [zoneList, setZoneList] = useState([]);
  const [branchesList, setBranchesList] = useState([]);
  const [memberCode, setMemberCode] = useState(null);
  const [loader, setLoader] = useState(4);
  const [disabledButton,setDisableButton] = useState(false)
  const [selectedGender, setSelectedGender] = useState(null);
  const [memberData, setMemberData] = useState({});
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [enabledSwitchIndex, setEnabledSwitchIndex] = useState(0); // Initialize with the index of the initially enabled switch
  const [selectedName, setName] = useState("");
  const [selectedEmail, setSelectedEmail] = useState("");
  const [selectedPhone, setSelectedPhone] = useState("");

  const [typeErrorAddress, setTypeErrorAddress] = useState("");
  const [countryError, setCountryError] = useState("");
  const [stateError, setStateError] = useState("");
  const [cityError, setCityError] = useState("");
  const [pincodeError, setPincodeError] = useState("");
  const [districtError, setDistrictError] = useState("");
  const [addressLine1Error, setaddressLine1Error] = useState("");
  const [addressLine2Error,setaddressLine2Error] = useState("")
  const [zoneError,setZoneError] = useState("");
  const [branchError,setBranchError] = useState("");
  const [selectedDOBDate, setSelectedDOBDate] = useState('');
  const [isDatePickerVisible, setDatePickerVisible] = useState(false);
  //personal details
  const [coNameError, setCoNameError] = useState("");
  const [coTypeError, setCoTypeError] = useState("");
  const [genderError, setgenderError] = useState("");
  const [alternateNumberError, setAlternateNumberError] = useState("");
  const [titleError, setTitleError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [nameError,setNameError] = useState("")
  const [addressLengthFlag,setAddressLengthFlag]  = useState(false)
  const [dobCalendar,setDOBCalendar] = useState('')
  const { viewProfile, getAddress, editProfile, addAdress } = apiServices;
  const {
    getCountryList,
    getCityList,
    getStateList,
    getDistrictList,
    getPincodeList,
    getBranchList,
    getZoneList,
    defaultSetAddress
  } = apiServices;




  const validateInputs = () => {
    let isValid = true;

    // Validation for Name field
    if (selectedType === "") {
      setTypeErrorAddress("Type is required");
      isValid = false;
    } else {
      setTypeErrorAddress("");
    }
    if (selectedCountry === "") {
      setCountryError("Country is required");
      isValid = false;
    } else {
      setCountryError("");
    }
    if (selectedState === "") {
      setStateError("State is required");
      isValid = false;
    } else {
      setStateError("");
    }
    if(selectedBranch === "")
    {
      setBranchError("Branch is required");
      isValid = false;
    }else{
      setBranchError("")
    }
    if(selectedZone === "")
    {
      setZoneError("Zone is required");
      isValid = false;
    }else{
      setZoneError("")
    }
    // if (selectedTehsil === "") {
    //   setTehsilError("Tehsil is required");
    //   isValid = false;
    // } else {
    //   setStateError("");
    // }
    if (selectedCity === "") {
      setCityError("City is required");
      isValid = false;
    } else {
      setStateError("");
    }
    if (newZipcode === "") {
      setPincodeError("Pincode is required");
      isValid = false;
    } else {
      setStateError("");
    }
    if (selectedDistrict === "") {
      setDistrictError("District is required");
      isValid = false;
    } else {
      setStateError("");
    }
    let addressLine1Trim = addressLine1.trim()
    if (addressLine1Trim === "") {
      setaddressLine1Error("Flat, House no., Building, Company, Apartment is required");
      isValid = false;
    } else {
      setStateError("");
    }
    let addressLine2Trim = addressLine1.trim()
    if (addressLine2Trim === "") {
      setaddressLine2Error("Area, Street, Sector, Village is required");
      isValid = false;
    } else {
      setStateError("");
    }

    return isValid;
  };
  const handleDateChange = (date) => {
    setDateOfBirth(date);
  };

  const validatePersonalInputs = ()=>{
    let isValid = true
    let coNameErrorTrim=selectedCareOfName.trim()
    if (coNameErrorTrim === "" || selectedCareOfName ==null ) {
      setCoNameError("Field is required");
      isValid = false;
    } else {
      setCoNameError("");
    }
    if (selectedCareOfType === "" || selectedCareOfType ==null) {
      setCoTypeError("Field is required");
      isValid = false;
    } else {
      setCoTypeError("");
    }
    if (selectedGender === "" || selectedGender ==null) {
      setgenderError("Field is required");
      isValid = false;
    } else {
      setgenderError("");
    }
    if (alternateNumberError !="") {
      if(selectedAlternateNumber === profileData?.mobile){
      setAlternateNumberError("Please use different number");
      }else if(selectedAlternateNumber.length!=10){
        setAlternateNumberError("Please add 10 digit number")
      }
      isValid = false;
    } else {
      setAlternateNumberError("");
    }
    if (selectedTitle === "" || selectedTitle ==null) {
      setTitleError("Field is required");
      isValid = false;
    } else {
      setTitleError("");
    }
    if(emailError!='')
    {
      isValid = false;
    }else{
      setEmailError('')
    }


    if(coNameError!='')
    {
      isValid = false;
    }
    if(alternateNumberError!='')
    {
      isValid = false;
    }

    // if (selectedEmail === "" || selectedEmail ==null) {
    //   setEmailError("Field is required");
    //   isValid = false;
    // } else {
    //   setEmailError("");
    // }
    if (selectedName === "" || selectedName ==null) {
      setNameError("Field is required");
      isValid = false;
    } else if(selectedName === "Guest")
    {
      setNameError("Name can't be Guest");
      isValid = false;
    }
    if(nameError!='')
    {
      isValid = false
    }

    return isValid
  }

  const handleSubmit = () => {
    const isValid = validateInputs();

    if (isValid) {
      addMemberAddress();
    } else {
      alert("Please fill in the required fields correctly.");
    }
  };



  const handleSwitchToggle = (index) => {
    setEnabledSwitchIndex(index);
  };

  const getMemberCodeX = async () => {
    let getMemberCode;
    try {
      getMemberCode = await AsyncStorage.getItem("member_code");
      setLoader((prev) => {
        return prev !== 0 ? prev - 1 : 0;
      });
    } catch (error) {
      dispatchError(error);
      setLoader((prev) => {
        return prev !== 0 ? prev - 1 : 0;
      });
    }
    setMemberCode(getMemberCode);
  };
  useEffect(() => {
    getMemberCodeX();
    return(()=>{
      dispatchError('')
    })
  }, []);

  const getMemberCode = async () => {
    try {
      let viewMember = await viewProfile(memberCode);
      setProfileData(viewMember);
      setLoader((prev) => {
        return prev !== 0 ? prev - 1 : 0;
      });
      if(viewMember?.member_name== 'Guest'
      ||viewMember?.member_name==null
      ||viewMember?.member_name==""
      || viewMember?.co_name==""
      || viewMember?.co_name==null
      || viewMember?.co_type==""
      || viewMember?.co_type==null
      // || viewMember?.email ==""
      // || viewMember?.email ==null
      // ||viewMember?.phone==""
      // ||viewMember?.phone == null
      ||viewMember?.title ==""
      ||viewMember?.title == null
      ||viewMember?.gender == null
      ||viewMember?.gender==""
      )
      {
        setTimeout(() => {
          setProfileModalVisible(true)
          setDisableButton(true)
          setProfileModalVisible(!profileModalVisible)
        }, 1700);
        dispatchError("Please complete profile first");
      }else{
        setDisableButton(false)
      }
      setSelectedEmail(viewMember?.email)
      setSelectedCareOfName(viewMember?.co_name)
      setSelectedAlternateNumber(viewMember?.phone)
      setSelectedGender(viewMember?.gender)
      setSelectedCareOfType(viewMember?.co_type)
      setName(viewMember?.member_name)
      setSelectedTitle(viewMember?.title)
      setDOBCalendar(viewMember?.dob)
      setSelectedDOBDate(formatedDateWithStandardFormat(viewMember?.dob))
    } catch (error) {
      dispatchError(error);
      setLoader((prev) => {
        return prev !== 0 ? prev - 1 : 0;
      });
    }
  };

  const getAddressMember = async () => {
    try {
      memberAddressDataGet = await getAddress(memberCode);
      setMemberAddress((prevMemberAddress) => [
        ...prevMemberAddress,
        ...memberAddressDataGet,
      ]);
      if(memberAddressDataGet.length==1 && !memberAddressDataGet[0].member_default_adr)
      {
          await defaultSetAddress(memberCode, memberAddressDataGet[0].address_id,);
          refreshAdressData();
      }
      setLoader((prev) => {
        return prev !== 0 ? prev - 1 : 0;
      });
      if(memberAddressDataGet.length <1)
      {
        dispatchError("Please add your address to proceed. ")
      }
    } catch (error) {
      dispatchError(error);
      setLoader((prev) => {
        return prev !== 0 ? prev - 1 : 0;
      });
    }
  };
const setEmail = (text)=>{

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if(text!=''){
  if (emailRegex.test(text)) {
    setEmailError('')
    setSelectedEmail(text)
  }else{
    setEmailError('Enter a valid email')
  }
}else{
  setSelectedEmail(text)
  setEmailError('')
}
}

  // const handlerRefreshAdress = () => {
  //   setMemberAddress([])
  //   getAddressMember();
  //   getMemberCode();
  // };
  const profileRefreshData = () => {
    getMemberCode();
  };
  const refreshAdressData = () => {
    setMemberAddress(["abc"])
    getAddressMember();
  };
  const onSelectedItemsChange = (selectedItems) => {
    // Set Selected Items

    setSelectedBranch(selectedItems[0])

    setSelectedItems(selectedItems);
  };
  const getCountryMememberList = async () => {
    try {
      let memberCountryList = await getCountryList(memberCode);
      if(typeof(memberCountryList)=='object'){
      setCountryList(memberCountryList);
      }
      setLoader((prev) => {
        return prev !== 0 ? prev - 1 : 0;
      });
    } catch (error) {
      dispatchError(error);
      setLoader((prev) => {
        return prev !== 0 ? prev - 1 : 0;
      });
    }
  };

  const getCityMemberList = async (district) => {
    try {
      setCityList([]);
      let memberCityList = await getCityList(memberCode,district);
      setCityList(memberCityList);
      setLoader((prev) => {
        return prev !== 0 ? prev - 1 : 0;
      });
    } catch (error) {
      dispatchError(error);
      setLoader((prev) => {
        return prev !== 0 ? prev - 1 : 0;
      });
    }
  };

  const getStateMemberList = async (country) => {
    try {
      setStateList([]);
      let memberStateList = await getStateList(memberCode,country);

      setStateList(memberStateList);
      setLoader((prev) => {
        return prev !== 0 ? prev - 1 : 0;
      });
    } catch (error) {
      dispatchError(error);
      setLoader((prev) => {
        return prev !== 0 ? prev - 1 : 0;
      });
    }
  };
  // const getTehsilMemberList = async (district) => {
  //   try {
  //     setTehsilList([])
  //     let memberTehsilList = await getTehsilsList(memberCode,district);

  //     setTehsilList(memberTehsilList);
  //     setLoader((prev) => {
  //       return prev !== 0 ? prev - 1 : 0;
  //     });
  //   } catch (error) {
  //     dispatchError(error);
  //     setLoader((prev) => {
  //       return prev !== 0 ? prev - 1 : 0;
  //     });
  //   }
  // };
  const getPincodeMemberList = async (city) => {
    try {
      setPincodeList([]);
      let memberPincodeList = await getPincodeList(memberCode,city);

      setPincodeList(memberPincodeList);
      setLoader((prev) => {
        return prev !== 0 ? prev - 1 : 0;
      });
    } catch (error) {
      dispatchError(error);
      setLoader((prev) => {
        return prev !== 0 ? prev - 1 : 0;
      });
    }
  };
  const getDistrictMemberList = async (state) => {
    try {
      setDistrictList([])
      setCityList([])
      let memberDistrictList = await getDistrictList(memberCode,state);
      setDistrictList(memberDistrictList);
      setLoader((prev) => {
        return prev !== 0 ? prev - 1 : 0;
      });
    } catch (error) {
      dispatchError(error);
      setLoader((prev) => {
        return prev !== 0 ? prev - 1 : 0;
      });
    }
  };
  const handleDateSelected = (date) => {
    // Format the selected date to "dd/mm/yyyy"
    const formattedDate = formatDOBDate(date);
    setSelectedDOBDate(formattedDate);
    setDatePickerVisible(false);
  };



  // const getZoneMemberList = async () => {
  //   try {
  //     let Data = await getZoneList(memberCode);
  //     setZoneList(Data);
  //     setLoader((prev) => {
  //       return prev !== 0 ? prev - 1 : 0;
  //     });
  //   } catch (error) {
  //     dispatchError(error);
  //     setLoader((prev) => {
  //       return prev !== 0 ? prev - 1 : 0;
  //     });
  //   }
  // };
  // const getBranchMemberList = async (data) => {
  //   const Data = await getBranchList(memberCode, data);
  //   setBranchesList(Data);
  //   const transformedData = Data.map(item => {
  //     return {
  //       id: item.branch_id,
  //       name: item.branch_name,
  //     };
  //   });
  //   setBranchesListItem(transformedData)
  // };
  const handleSearchZone = async(text)=>{

      try {
        let Data = await getZoneList(memberCode,text);
        for(let item of Data)
        {
          if(item.zone_sect_description && item.zone_sect_description)
          {
            item.zone_name +=' ('+item.zone_sect_description+')'
          }
        }
        Data.push({zone_name:'<---- Type to view more---->'})
        setZoneList(Data);

        // setLoader((prev) => {
        //   return prev !== 0 ? prev - 1 : 0;
        // });
      } catch (error) {
        dispatchError(error);
        // setLoader((prev) => {
        //   return prev !== 0 ? prev - 1 : 0;
        // });
      }

  }
  const handleBranchSearch = async(text)=>{

      try{

        let searchData  = await getBranchList(memberCode,selectedZone,text )
        setBranchesList(searchData);
        const transformedData = searchData.map(item => {
          return {
            id: item.branch_id,
            name: item.branch_name,
          };
        });
        setBranchesListItem(transformedData)
      }catch(error)
      {
        dispatchError(error)
      }

  }
  useEffect(()=>{
    if(selectedZone)
    {
      handleBranchSearch(undefined)
    }
  },[selectedZone])
  const handleAlternateNumber =(text)=>{

    var regex = /^[0-9]+$/;
    if(text!='' && text!=null){
    if(!regex.test(text))
    {
      setAlternateNumberError('Only number is allowed');
      return
    }
    text!=profileData?.mobile ? (setSelectedAlternateNumber(text),setAlternateNumberError("")):setAlternateNumberError("Please use different number")
    if(text.length!=10)
    {
      setAlternateNumberError("Please add 10 digit number")
    }
  }else{
    setSelectedAlternateNumber('')
    setAlternateNumberError('')
  }
  }

  const handleCareOfName = (text)=>{
    const regex = /^[a-zA-Z\s]+$/;
    if(!regex.test(text))
    {
     setCoNameError("Only Alphabets allowed")
      return;
    }else{
      setCoNameError('')
    setSelectedCareOfName(text);
    }
  }
const hanldeName = (text)=>{
  const regex = /^[a-zA-Z\s]+$/;
    if(!regex.test(text))
    {
     setNameError("Only Alphabets allowed")
      return;
    }else{
      setNameError('')
      setName(text);
    }

}

  let addressDataNew = [
    {
      address_type: `${selectedType}`,
      member_code: `${memberCode}`,
      pincode: `${newZipcode}`,
      country_id: `${selectedCountry}`,
      state_id: `${selectedState}`,
      district_id: `${selectedDistrict}`,
      // tehsil_id: `${selectedTehsil}`,
      city_id: `${selectedCity}`,
      adr_line1:`${addressLine1}`,
      adr_line2: `${addressLine2}`,
      zone_id:`${selectedZone}`,
      branch_id:`${selectedBranch}`
    },
  ];
  const handleTouchablePress = (event) => {
    const datePickerTouchable = datePickerRef.current;
    if (datePickerTouchable && !datePickerTouchable.contains(event.target)) {
      // Clicked outside of the DatePicker, close it
      setDatePickerVisible(false)
    }
  };
  const renderAddressCard = (item, index) => {
    if (!index) {
      return <AddAddressCard />;
    }

    const addressIndex = index - 1;
    return (
      <Addresscomponent
        memberAddress={item}
        memberCode={memberCode}
        key={item.address_id}
        index={addressIndex}
        refreshAdress={refreshAdressData}

      />
    );
  };
  const AddAddressCard = () => {
    return (
      <View
        style={{
          borderWidth: 2,
          margin: !isMobileView() && 10,
          marginTop: 0,
          borderColor: "#c2c2c8",
          borderStyle: "dashed",

          width: isMobileView() && "100%" || isOnlyTabletView() && "47%" || isOnlyLaptopView() && responsiveWidth(25),
          height: isMobileView() ? responsiveHeight(30) : responsiveHeight(42),
          borderRadius: 8,
          padding: 20,
          paddingHorizontal: 30,
          backgroundColor: Color.WHITE,
        }}
      >
        <TouchableOpacity
          onPress={() => setModalVisible(true)}
          style={[
            commonStyles.alignCenter,
            commonStyles.gap2rem,
            { justifyContent: "center", height: "100%" },
          ]}
        >
          <Image
            style={{
              height: isMobileView()
                ? responsiveScreenHeight(11)
                : responsiveScreenHeight(12),
              width: isMobileView()
                ? responsiveScreenHeight(11)
                : responsiveScreenWidth(6),
              borderWidth: 0,
            }}
            resizeMode="contain"
            source={require("../../assets/images/address.png")}
          />
          <Text
            style={{
              fontWeight: "500",
              fontSize: isMobileView()
                ? responsiveFontSize(2.4)
                : responsiveFontSize(1.5),
              color: "#C2C2C8",
            }}
          >
            Add Address
          </Text>
        </TouchableOpacity>
      </View>
    );
  };

  const editMemeberProfile = async () => {
    // if((selectedEmail =="" || selectedName=="" || selectedName=="Guest"
    // || selectedCareOfType==""|| selectedCareOfName=="" ||
    // selectedTitle == "" || selectedGender=="" || selectedAlternateNumber=="" )  )
    // {

    //   return;
    // }
    const valid = validatePersonalInputs()
    if(valid){
    let editData ={
      email:selectedEmail,
      gender:selectedGender,
      member_name:selectedName,
      member_code:memberCode,
      co_type:selectedCareOfType,
      co_name:selectedCareOfName,
      phone:selectedAlternateNumber,
      title:selectedTitle,
      dob:selectedDOBDate,
      mobile_isd:"91"
    }
    try {
      let data = await editProfile(memberCode, editData);
      setProfileModalVisible(!profileModalVisible);
      profileRefreshData();
      let dataString = JSON.stringify(data)
      if(memberAddress.length <1 || (memberAddress.length ==1 &&memberAddress[0] =='abc'))
      {
        setModalVisible(true);
        setAddressLengthFlag(true)
      }
      //await AsyncStorage.setItem('members',dataString)
      dispatchSuccess('Profile Updated Successfully')
    } catch (error) {
      dispatchError(error);
    }
  }else{
      alert("Please fill in the required fields correctly.");
      setProfileModalVisible(true);
      return;
  }
  };
  const addMemberAddress = async () => {
    try {
      let data = await addAdress(memberCode, addressDataNew);
      setMemberData(
        JSON.stringify(
        memberData.address = data)
      );
      // await AsyncStorage.setItem()
      setModalVisible(!modalVisible);
      // handlerRefreshAdress();
      setSelectedCountry("");
      setSelectedState("");
      setSelectedType("");
      setSelectedDistrict("");
      setSelectedZone("");
      setSelectedBranch("");
      setNewZipcode("");
      setSelectedCity("");
      setAddressLine1("")
      setAddressLine2("")
      refreshAdressData();
      dispatchSuccess('Address added successfully')

    } catch (error) {
      dispatchError(error);
    }
  };
  const getMemberData=async()=>{
    let data = JSON.parse(await AsyncStorage.getItem('members'))
    setMemberData(data)
  }

  useEffect(() => {
    if (memberCode) {
      getMemberData()
      getMemberCode();
      getAddressMember();
      handleSearchZone(undefined)
      // getBranchMemberList()
      getCountryMememberList();
      //getCityMemberList();
     // getStateMemberList();
     // getTehsilMemberList();
     // getPincodeMemberList();
      // getBranchMemberList();
      //getZoneMemberList();

    }

    setName(profileData?.member_name);
    setSelectedEmail(profileData?.email);
    setSelectedPhone(profileData?.mobile);
    return(()=>{
      dispatchError('')
    })
  }, [memberCode]);

  let [fontsLoaded] = useFonts({
    openSans: require("../../assets/fonts/OpenSans-VariableFont_wdth,wght.ttf"),
  });
  if (!fontsLoaded) {
    return <AppLoading />;
  }
  const renderDropdownIcon = ({ isOpen }) => (
    <View
      style={{
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Image
        style={{
          height: 22,
          width: 22,

          resizeMode: "contain",
        }}
        source={require("../../assets/images/dropDown.png")}
      />
    </View>
  );
  return (
    <>
      {loader === 0 ? (
        <ScrollView
          showsVerticalScrollIndicator={false}
          style={styles.container}
        >
          <Headerdiv memberCode={memberCode} navigation={navigation} />
          <SecondaryHeaderdiv titleText="My Profile" />

          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              width: isMobileView() && "90%" || isOnlyTabletView() && "85%" || isOnlyLaptopView() && "80%",

              alignSelf: "center",
              padding: isMobileView() && 20 || isOnlyLaptopView() && 35 || isOnlyTabletView() && 25,
              paddingHorizontal: isMobileView() && 0,
              paddingBottom: 10,
            }}
          >
            <Text
              style={{
                fontFamily: "openSans",
                fontWeight: "400",
                fontSize: !isMobileView()
                  ? responsiveFontSize(2)
                  : responsiveFontSize(2.4),
              }}
            >
              Personal Information
            </Text>
            <Hoverable>
              {({ hovered }) => (
                <TouchableOpacity
                  onPress={() =>{
                    setCoNameError(''),
                    setCoTypeError('');
                    setgenderError('');
                    setNameError('');
                    setAlternateNumberError('');
                    setEmailError('');
                    setTitleError('');
                    setSelectedCareOfName(profileData?.co_name)
                    setProfileModalVisible(!profileModalVisible)
                  }
                  }
                  style={{
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    flexDirection: "row",
                    borderWidth: 2,
                    borderRadius: 25,
                    borderColor: Color.BLUE_MEDIUM,
                    height: isMobileView() ? responsiveScreenHeight(5) : 45,
                    width: isMobileView() ? responsiveScreenWidth(23) : 100,
                    backgroundColor: hovered ? Color.BLUE_MEDIUM : "white",
                  }}
                >
                  <Image
                    style={{
                      height: isMobileView() ? responsiveHeight(3) : 30,
                      width: isMobileView() ? responsiveHeight(3) : 30,
                      resizeMode: "contain",
                      borderWidth: 0,
                    }}
                    source={
                      hovered
                        ? require("../../assets/images/editWhite.png")
                        : require("../../assets/images/pencil.png")
                    }
                  />
                  <Text
                    style={{
                      fontFamily: "openSans",
                      fontWeight: "600",
                      fontSize: isMobileView() ? responsiveFontSize(2) : 18,
                      color: hovered ? "white" : Color.BLUE_MEDIUM,
                    }}
                  >
                    Edit
                  </Text>
                </TouchableOpacity>
              )}
            </Hoverable>
          </View>
          <Profilecard profileData = {profileData} />
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              width: isMobileView() && "90%" || isOnlyTabletView() && "85%" || isOnlyLaptopView() && "80%",
              alignSelf: "center",
              padding: isMobileView() && 20 || isOnlyLaptopView() && 35 || isOnlyTabletView() && 25,
              paddingHorizontal: isMobileView() && 0,
              paddingBottom: 10,
            }}
          >
            <Text
              style={{
                fontFamily: "openSans",
                fontWeight: "400",
                fontSize: !isMobileView()
                  ? responsiveFontSize(2)
                  : responsiveFontSize(2.4),
              }}
            >
              Contact Information
            </Text>
            {/* <Hoverable>
{({ hovered }) => (
        <TouchableOpacity
          onPress={() => setProfileModalVisible(!profileModalVisible)}
          style={{
            justifyContent: "space-evenly",
            alignItems: "center",
            flexDirection: "row",
            borderWidth: 2,
            borderRadius: 25,
            borderColor: Color.BLUE_MEDIUM,
            height: 45,
            width: 100,
            backgroundColor: hovered ? Color.BLUE_MEDIUM : "white",
          }}
        >
          <Image
            style={{
              height: 30,
              width: 30,
              resizeMode: "contain",
              borderWidth: 0,
            }}
            source={ hovered ? require("../../assets/images/editWhite.png") : require("../../assets/images/pencil.png")}
          />
          <Text
            style={{
              fontFamily: "openSans",
              fontWeight: "600",
              fontSize: 18,
              color:  hovered ?  "white" : Color.BLUE_MEDIUM,
            }}
          >
            Edit
          </Text>
        </TouchableOpacity>
          )}
          </Hoverable> */}
          </View>
          <Profilecard
            profileData = {profileData}
            // email={profileData?.email}
            // phoneNumber={profileData?.mobile}
            // alternateNumber={"788877887"}
             contactCard={true}
            // careOfName={profileData?.co_name}
          />

          {/* after blue */}
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              width: isMobileView() && "90%" || isOnlyTabletView() && "85%" || isOnlyLaptopView() && "80%",
              alignSelf: "center",
              padding: isMobileView() && 20 || isOnlyLaptopView() && 35 || isOnlyTabletView() && 25,
              paddingHorizontal: isMobileView() && 0,
            }}
          >
            <Text
              style={{
                fontFamily: "openSans",
                fontWeight: "400",
                fontSize: !isMobileView()
                  ? responsiveFontSize(2)
                  : responsiveFontSize(2.4),
              }}
            >
              Address
            </Text>
          </View>
          <View
            style={{
              alignItems: "center",
              gap: 20,
              flexDirection: "row",
              width: isMobileView() && "90%" || isOnlyTabletView() && "85%" || isOnlyLaptopView() && "80%",
              alignSelf: "center",
            }}
          >
            <FlatList
              data={memberAddress}
              style={{
              overflow:"visible",

              }}
              keyExtractor={(item, index) => item.address_id}
              renderItem={({ item, index }) => renderAddressCard(item, index)}
              columnWrapperStyle={
                !isMobileView() && {
                  justifyContent: "space-between",
                  marginTop: 5,

                }
              }
              numColumns={isMobileView() && 1 || isOnlyTabletView() && 2 || isOnlyLaptopView() && 3}
            />
          </View>

          <Modal
            animationType="fade"
            transparent={true}
            visible={modalVisible}
            onRequestClose={() => {
              Alert.alert("Modal has been closed.");
              setModalVisible(!modalVisible);
            }}
          >
            <View style={styles.centeredView}>
              <View
                style={
                  isMobileView() ? styles.modalViewMobile : styles.modalView
                }
              >
                <View
                  style={{
                    flexDirection: "row",
                    width: "100%",
                    justifyContent: "space-between",
                    borderWidth: 0,
                  }}
                >
                  <Text style={styles.modalText}>Add new address</Text>
                  <TouchableOpacity
                  //disabled={addressLengthFlag}
                    onPress={() => setModalVisible(!modalVisible)}
                  >
                    <Image
                      style={{ width: 30, height: 30 }}
                      source={require("../../assets/images/cross.png")}
                    />
                  </TouchableOpacity>
                </View>

      {
      isMobileView() ? (
        <View style={[commonStyles.Col2GridParent,{rowGap:0,flex:"inherit"}]}>

                    <View style={[[ isMobileView() && commonStyles.width48Mobile, !isMobileView() && commonStyles.width30]]}>
                      <Text
                        style={[
                          {
                            fontWeight: "600",
                            fontSize: responsiveFontSize(1.1),
                          },
                          commonStyles.inputLableMobile,
                        ]}
                      >
                        Type of Address<Text style={{color:'red'}} >*</Text>
                      </Text>
                       <SelectDropdown
     dropdownOverlayColor={"No"}
                      dropdownStyle={commonStyles.commanDropdownStyle}
                      selectedRowStyle={commonStyles.selectedRowStyle}
                      selectedRowTextStyle={commonStyles.selectedRowTextStyle}
                      rowStyle={commonStyles.commanRowStyle}
                      rowTextStyle={commonStyles.commonRowTextStyle}
                        data={["Home", "Office", "Other"]}
                        rowTextForSelection={(item) => item}
                        buttonTextAfterSelection={(selectedItem) =>
                          selectedItem
                        }
                        defaultButtonText={
                          "Select address type"
                        }
                        // defaultValue=
                        // defaultButtonText={
                        //   countryList.find(
                        //     (item) => item.country_id === memberAddress.country_id
                        //   )?.country_name || "Select a country"
                        // }

                        buttonStyle={[
                          {
                            width: 225,
                            height: "55%",
                            marginTop: 10,
                            backgroundColor: Color.WHITE,
                            alignItems: "center",
                            justifyContent: "space-between",
                            borderRadius: 10,
                            borderWidth: 1,
                            borderColor: "rgba(0, 0, 0, 0.14)",
                            padding: 10,
                          },
                          commonStyles.inputFieldMobile,
                        ]}
                        buttonTextStyle={{
                          color: "rgba(0, 0, 0)",
                          fontSize: isMobileView()
                            ? responsiveFontSize(2.2)
                            : responsiveFontSize(1.1),
                          textAlign: "left",
                          margin: 0,
                          marginVertical: 0,
                          fontWeight: "600",
                        }}
                        renderDropdownIcon={(props) =>
                          renderDropdownIcon(props)
                        }
                        onSelect={(selectedItem, index) => {
                          setSelectedType(selectedItem);
                        }}
                      />
                      {typeErrorAddress !== "" && (
                        <Text
                          style={{
                            color: "red",
                            fontWeight: "500",
                            height: 10,
                          }}
                        >
                          {typeErrorAddress}
                        </Text>
                      )}
                    </View>
                    <View style={[[ isMobileView() && commonStyles.width48Mobile, !isMobileView() && commonStyles.width30]]}>
                      <Text
                        style={[
                          {
                            fontWeight: "600",
                            fontSize: responsiveFontSize(1.1),
                          },
                          commonStyles.inputLableMobile,
                        ]}
                      >
                        Country<Text style={{color:'red'}} >*</Text>
                      </Text>
                       <SelectDropdown search searchPlaceHolder={"Search Country.."}
                      searchInputStyle={commonStyles.commanDropdownSearchStyle}
                      searchInputTxtStyle={{borderColor:'transparent',borderRadius:6,paddingHorizontal:10}}

     dropdownOverlayColor={"No"}
                      dropdownStyle={commonStyles.commanDropdownStyle}
                      selectedRowStyle={commonStyles.selectedRowStyle}
                      selectedRowTextStyle={commonStyles.selectedRowTextStyle}
                      rowStyle={commonStyles.commanRowStyle}
                      rowTextStyle={commonStyles.commonRowTextStyle}
                        data={countryList}
                        rowTextForSelection={(item) => item.country_name}
                        buttonTextAfterSelection={(selectedItem) =>
                          selectedItem.country_name
                        }
                        // defaultValue=
                        defaultButtonText={
                          countryList?.find(
                            (item) =>
                              item.country_id === memberAddress.country_id
                          )?.country_name || "Select a country"
                        }

                        buttonStyle={[
                          {
                          width: 225,
                          height: "50%",
                          marginTop: 10,
                          backgroundColor: Color.WHITE,
                          alignItems: "center",
                          justifyContent: "space-between",
                          borderRadius: 10,
                          borderWidth: 1,
                          borderColor: "rgba(0, 0, 0, 0.14)",
                          padding: 10,
                        },
                          commonStyles.inputFieldMobile,
                        ]}
                        buttonTextStyle={{
                          color: "rgba(0, 0, 0)",
                          fontSize: isMobileView()
                            ? responsiveFontSize(2.2)
                            : responsiveFontSize(1.1),
                          textAlign: "left",
                          margin: 0,
                          marginVertical: 0,
                          fontWeight: "600",
                        }}
                        renderDropdownIcon={(props) =>
                          renderDropdownIcon(props)
                        }
                        onSelect={(selectedItem, index) => {
                          setSelectedCountry(selectedItem.country_id);
                          getStateMemberList(selectedItem.country_name)
                          setCountryError('')
                          setSelectedState('')
                          setSelectedDistrict('')
                          setSelectedCity('')
                          setNewZipcode('');
                          setDistrictList([])
                          setCityList([]);
                          setPincodeList([])
                        }}
                      />
                      {countryError !== "" && (
                        <Text
                          style={{
                            color: "red",
                            fontWeight: "500",
                            height: 10,
                          }}
                        >
                          {countryError}
                        </Text>
                      )}
                    </View>
                    <View style={[[ isMobileView() && commonStyles.width48Mobile, !isMobileView() && commonStyles.width30]]} >
                      <Text
                        style={[
                          {
                            fontWeight: "600",
                            fontSize: responsiveFontSize(1.1),
                            color:stateList.length===0?'#b2b2b2':'#000'
                          },
                          commonStyles.inputLableMobile,
                        ]}
                      >
                        State<Text style={{color:'red'}} >*</Text>
                      </Text>
                       <SelectDropdown search searchPlaceHolder={"Search State.."}
                      searchInputStyle={commonStyles.commanDropdownSearchStyle}
                      searchInputTxtStyle={{borderColor:'transparent',borderRadius:6,paddingHorizontal:10}}

     dropdownOverlayColor={"No"}
                      dropdownStyle={commonStyles.commanDropdownStyle}
                      selectedRowStyle={commonStyles.selectedRowStyle}
                      selectedRowTextStyle={commonStyles.selectedRowTextStyle}
                      rowStyle={commonStyles.commanRowStyle}
                      rowTextStyle={commonStyles.commonRowTextStyle}

                      disabled={stateList.length===0?true:false}
                        data={stateList}
                        rowTextForSelection={(item) => item.state_name}
                        buttonTextAfterSelection={(selectedItem) =>
                          selectedItem.state_name
                        }
                        defaultButtonText={
                          cityList.find(
                            (item) => item.state_id === memberAddress.state_id
                          )?.state_name || "Select a State"
                        }
                        onSelect={(selectedItem, index) => {
                          setSelectedState(selectedItem.state_id);
                          getDistrictMemberList(selectedItem.state_name);
                          setStateError('')
                          setSelectedDistrict('')
                          setSelectedCity('')
                          setNewZipcode('');
                          setDistrictList([])
                          setCityList([]);
                          setPincodeList([])
                        }}

                        buttonStyle={[
                          {
                          width: 225,
                          height: "50%",
                          marginTop: 10,
                          backgroundColor: Color.WHITE,
                          alignItems: "center",
                          justifyContent: "space-between",
                          borderRadius: 10,
                          borderWidth: 1,
                          borderColor: "rgba(0, 0, 0, 0.14)",
                          padding: 10,
                        },
                          commonStyles.inputFieldMobile,
                        ]}
                        buttonTextStyle={{
                          color: "rgba(0, 0, 0)",
                          fontSize: isMobileView()
                            ? responsiveFontSize(2.2)
                            : responsiveFontSize(1.1),
                          textAlign: "left",
                          margin: 0,
                          marginVertical: 0,
                          fontWeight: "600",
                        }}
                        renderDropdownIcon={(props) =>
                          renderDropdownIcon(props)
                        }
                      />
                      {stateError !== "" && (
                        <Text
                          style={{
                            color: "red",
                            fontWeight: "500",
                            height: 10,
                          }}
                        >
                          {stateError}
                        </Text>
                      )}
                    </View>





                  <View style={[[ isMobileView() && commonStyles.width48Mobile, !isMobileView() && commonStyles.width30]]} >
                    <Text
                      style={[
                        {
                          fontWeight: "600",
                          fontSize: responsiveFontSize(1.1),
                          color:districtList.length===0?'#b2b2b2':'#000'
                        },
                        commonStyles.inputLableMobile,
                      ]}
                    >
                      District<Text style={{color:'red'}} >*</Text>
                    </Text>
                     <SelectDropdown search searchPlaceHolder={"Search District.."}
                      searchInputStyle={commonStyles.commanDropdownSearchStyle}
                      searchInputTxtStyle={{borderColor:'transparent',borderRadius:6,paddingHorizontal:10}}
     dropdownOverlayColor={"No"}
                      dropdownStyle={commonStyles.commanDropdownStyle}
                      selectedRowStyle={commonStyles.selectedRowStyle}
                      selectedRowTextStyle={commonStyles.selectedRowTextStyle}
                      rowStyle={commonStyles.commanRowStyle}
                      rowTextStyle={commonStyles.commonRowTextStyle}
                     disabled={districtList.length===0?true:false}
                      data={districtList}
                      rowTextForSelection={(item) => item.district_name}
                      buttonTextAfterSelection={(selectedItem) =>
                        selectedItem.district_name
                      }
                      defaultButtonText={
                        districtList.find(
                          (item) => item.state_id === memberAddress.district_id
                        )?.district_name || "Select a District"
                      }
                      onSelect={(selectedItem, index) => {
                        setSelectedDistrict(selectedItem.district_id);
                        //getTehsilMemberList(selectedItem.district_name);
                        getCityMemberList(selectedItem.district_name)
                        setSelectedCity('')
                        setNewZipcode('')
                        setCityList([]);
                        setPincodeList([])
                        setDistrictError('')
                      }}

                      buttonStyle={[
                          {
                          width: 225,
                          height: "50%",
                          marginTop: 10,
                          backgroundColor: Color.WHITE,
                          alignItems: "center",
                          justifyContent: "space-between",
                          borderRadius: 10,
                          borderWidth: 1,
                          borderColor: "rgba(0, 0, 0, 0.14)",
                          padding: 10,
                        },
                          commonStyles.inputFieldMobile,
                        ]}
                      buttonTextStyle={{
                        color: "rgba(0, 0, 0)",
                        fontSize: isMobileView()
                            ? responsiveFontSize(2.2)
                            : responsiveFontSize(1.1),
                        textAlign: "left",
                        margin: 0,
                        marginVertical: 0,
                        fontWeight: "600",
                      }}
                      renderDropdownIcon={(props) => renderDropdownIcon(props)}
                    />
                    {districtError !== "" && (
                      <Text
                        style={{ color: "red", fontWeight: "500", height: 10 }}
                      >
                        {districtError}
                      </Text>
                    )}
                  </View>
                {/* { isMobileView() && <View style={[[ isMobileView() && commonStyles.width48Mobile]]}>
                    <Text
                      style={[
                        {
                          fontWeight: "600",
                          fontSize: responsiveFontSize(1.1),
                          color:tehsilList.length===0?'#b2b2b2':'#000'
                        },
                        commonStyles.inputLableMobile,
                      ]}
                    >
                      Tehsil*
                    </Text>
                     <SelectDropdown search searchPlaceHolder={"Search Tehsil.."}
                      searchInputStyle={commonStyles.commanDropdownSearchStyle}
                      searchInputTxtStyle={{borderColor:'transparent',borderRadius:6,paddingHorizontal:10}}
     dropdownOverlayColor={"No"}
                      dropdownStyle={commonStyles.commanDropdownStyle}
                      selectedRowStyle={commonStyles.selectedRowStyle}
                      selectedRowTextStyle={commonStyles.selectedRowTextStyle}
                      rowStyle={commonStyles.commanRowStyle}
                      rowTextStyle={commonStyles.commonRowTextStyle}
                    disabled={tehsilList.length===0?true:false}
                      data={tehsilList}
                      rowTextForSelection={(item) => item.tehsil_name}
                      buttonTextAfterSelection={(selectedItem) =>
                        selectedItem.tehsil_name
                      }
                      // defaultValue=
                      defaultButtonText={
                        countryList.find(
                          (item) =>
                            item.country_id === memberAddress.district_id
                        )?.tehsil_name || "Select a tehsil"
                      }

                      buttonStyle={[
                          {
                          width: 225,
                          height: "50%",
                          marginTop: 10,
                          backgroundColor: Color.WHITE,
                          alignItems: "center",
                          justifyContent: "space-between",
                          borderRadius: 10,
                          borderWidth: 1,
                          borderColor: "rgba(0, 0, 0, 0.14)",
                          padding: 10,
                        },
                          commonStyles.inputFieldMobile,
                        ]}
                      buttonTextStyle={{
                        color: "rgba(0, 0, 0, 0.30)",
                        fontSize: isMobileView()
                            ? responsiveFontSize(2.2)
                            : responsiveFontSize(1.1),
                        textAlign: "left",
                        margin: 0,
                        marginVertical: 0,
                        fontWeight: "600",
                      }}
                      renderDropdownIcon={(props) => renderDropdownIcon(props)}
                      onSelect={(selectedItem, index) => {
                        setSelectedTehsil(selectedItem.tehsil_id);

                      }}
                    />
                    {tehsilError !== "" && (
                      <Text
                        style={{ color: "red", fontWeight: "500", height: 10 }}
                      >
                        {tehsilError}
                      </Text>
                    )}
                  </View>}
            {!isMobileView() &&      <View style={[ commonStyles.width30]}  >
                    <Text
                      style={[
                        {
                          fontWeight: "600",
                          fontSize: responsiveFontSize(1.1),
                          color:tehsilList.length===0?'#b2b2b2':'#000'
                        },
                        commonStyles.inputLableMobile,
                      ]}
                    >
                      Tehsil
                    </Text>
                     <SelectDropdown search searchPlaceHolder={"Search Tehsil.."}
                      searchInputStyle={commonStyles.commanDropdownSearchStyle}
                      searchInputTxtStyle={{borderColor:'transparent',borderRadius:6,paddingHorizontal:10}}
     dropdownOverlayColor={"No"}
                      dropdownStyle={commonStyles.commanDropdownStyle}
                      selectedRowStyle={commonStyles.selectedRowStyle}
                      selectedRowTextStyle={commonStyles.selectedRowTextStyle}
                      rowStyle={commonStyles.commanRowStyle}
                      rowTextStyle={commonStyles.commonRowTextStyle}
                      disabled={tehsilList.length===0?true:false}
                      data={tehsilList}
                      rowTextForSelection={(item) => item.tehsil_name}
                      buttonTextAfterSelection={(selectedItem) =>
                        selectedItem.tehsil_name
                      }
                      // defaultValue=
                      defaultButtonText={
                        countryList.find(
                          (item) =>
                            item.country_id === memberAddress.district_id
                        )?.tehsil_name || "Select a tehsil"
                      }

                      buttonStyle={[
                          {
                          width: 225,
                          height: "50%",
                          marginTop: 10,
                          backgroundColor: Color.WHITE,
                          alignItems: "center",
                          justifyContent: "space-between",
                          borderRadius: 10,
                          borderWidth: 1,
                          borderColor: "rgba(0, 0, 0, 0.14)",
                          padding: 10,
                        },
                          commonStyles.inputFieldMobile,
                        ]}
                      buttonTextStyle={{
                        color: "rgba(0, 0, 0, 0.30)",
                        fontSize: isMobileView()
                            ? responsiveFontSize(2.2)
                            : responsiveFontSize(1.1),
                        textAlign: "left",
                        margin: 0,
                        marginVertical: 0,
                        fontWeight: "600",
                      }}
                      renderDropdownIcon={(props) => renderDropdownIcon(props)}
                      onSelect={(selectedItem, index) => {
                        setSelectedTehsil(selectedItem.tehsil_id);
                        getCityMemberList(selectedItem.tehsil_name)

                      }}
                    />
                    {tehsilError !== "" && (
                      <Text
                        style={{ color: "red", fontWeight: "500", height: 10 }}
                      >
                        {tehsilError}
                      </Text>
                    )}
                  </View>} */}

                  <View style={[[ isMobileView() && commonStyles.width48Mobile, !isMobileView() && commonStyles.width30]]}>
                    <Text
                      style={[
                        {
                          fontWeight: "600",
                          fontSize: responsiveFontSize(1.1),
                          color:cityList.length===0?'#b2b2b2':'#000'
                        },
                        commonStyles.inputLableMobile,
                      ]}
                    >
                      City<Text style={{color:'red'}} >*</Text>
                    </Text>
                     <SelectDropdown search searchPlaceHolder={"Search City.."}
                      searchInputStyle={commonStyles.commanDropdownSearchStyle}
                      searchInputTxtStyle={{borderColor:'transparent',borderRadius:6,paddingHorizontal:10}}
     dropdownOverlayColor={"No"}
                      dropdownStyle={commonStyles.commanDropdownStyle}
                      selectedRowStyle={commonStyles.selectedRowStyle}
                      selectedRowTextStyle={commonStyles.selectedRowTextStyle}
                      rowStyle={commonStyles.commanRowStyle}
                      rowTextStyle={commonStyles.commonRowTextStyle}
                    disabled={cityList.length===0?true:false}
                      data={cityList}
                      rowTextForSelection={(item) => item.city_name}
                      buttonTextAfterSelection={(selectedItem) =>
                        selectedItem.city_name
                      }
                      defaultButtonText={
                        cityList.find(
                          (item) => item.city_id === memberAddress.city_id
                        )?.city_name || "Select a city"
                      }
                      onSelect={(selectedItem, index) => {
                        setSelectedCity(selectedItem.city_id);
                        getPincodeMemberList(selectedItem.city_name);
                        setCityError('')
                        setNewZipcode('')
                        setPincodeList([])

                      }}

                      buttonStyle={[
                          {
                          width: 225,
                          height: "50%",
                          marginTop: 10,
                          backgroundColor: Color.WHITE,
                          alignItems: "center",
                          justifyContent: "space-between",
                          borderRadius: 10,
                          borderWidth: 1,
                          borderColor: "rgba(0, 0, 0, 0.14)",
                          padding: 10,
                        },
                          commonStyles.inputFieldMobile,
                        ]}
                      buttonTextStyle={{
                        color: "rgba(0, 0, 0)",
                        fontSize: isMobileView()
                            ? responsiveFontSize(2.2)
                            : responsiveFontSize(1.1),
                        textAlign: "left",
                        margin: 0,
                        marginVertical: 0,
                        fontWeight: "600",
                      }}
                      renderDropdownIcon={(props) => renderDropdownIcon(props)}
                    />
                    {cityError !== "" && (
                      <Text
                        style={{ color: "red", fontWeight: "500", height: 10 }}
                      >
                        {cityError}
                      </Text>
                    )}
                  </View>
                  <View style={[[ isMobileView() && commonStyles.width48Mobile, !isMobileView() && commonStyles.width30]]}>
                    <Text
                      style={[
                        {
                          fontWeight: "600",
                          fontSize: responsiveFontSize(1.1),
                          color:pincodeList.length===0?'#b2b2b2':'#000'
                        },
                        commonStyles.inputLableMobile,
                      ]}
                    >
                      Pin Code<Text style={{color:'red'}} >*</Text>
                    </Text>
                       <SelectDropdown search searchPlaceHolder={"Search Pin Code.."}
                      searchInputStyle={commonStyles.commanDropdownSearchStyle}
                      searchInputTxtStyle={{borderColor:'transparent',borderRadius:6,paddingHorizontal:10}}
     dropdownOverlayColor={"No"}
                      dropdownStyle={commonStyles.commanDropdownStyle}
                      selectedRowStyle={commonStyles.selectedRowStyle}
                      selectedRowTextStyle={commonStyles.selectedRowTextStyle}
                      rowStyle={commonStyles.commanRowStyle}
                      rowTextStyle={commonStyles.commonRowTextStyle}
                      disabled={pincodeList.length===0?true:false}
                        data={pincodeList}
                        rowTextForSelection={(item) => item.pincode}
                        buttonTextAfterSelection={(selectedItem) =>
                          selectedItem.pincode
                        }
                        defaultButtonText={
                          cityList.find(
                            (item) => item.pincode_id === memberAddress.pincode_id
                          )?.pincode || "Select Pincode"
                        }
                        onSelect={(selectedItem, index) => {
                          setNewZipcode(selectedItem.pincode);
                          setPincodeError('')
                        }}

                        buttonStyle={[
                          {
                          width: 225,
                          height: "50%",
                          marginTop: 10,
                          backgroundColor: Color.WHITE,
                          alignItems: "center",
                          justifyContent: "space-between",
                          borderRadius: 10,
                          borderWidth: 1,
                          borderColor: "rgba(0, 0, 0, 0.14)",
                          padding: 10,
                        },
                          commonStyles.inputFieldMobile,
                        ]}
                        buttonTextStyle={{
                          color: "rgba(0, 0, 0)",
                          fontSize: isMobileView()
                            ? responsiveFontSize(2.2)
                            : responsiveFontSize(1.1),
                          textAlign: "left",
                          margin: 0,
                          marginVertical: 0,
                          fontWeight: "600",
                        }}
                        renderDropdownIcon={(props) =>
                          renderDropdownIcon(props)
                        }
                      />
                    {pincodeError !== "" && (
                      <Text
                        style={{ color: "red", fontWeight: "500", height: 10 }}
                      >
                        {pincodeError}
                      </Text>
                    )}
                  </View>

        </View>
      ):(
        <>
              <View
                  style={{
                    width: "100%",
                    margin: 0,
                    borderWidth: 0,
                  }}
                >
                  <View
                    style={[
                       commonStyles.flexRow,
                      commonStyles.justifyBetween,
                      isMobileView() && commonStyles.flexWrap
                    ]}
                  >
                    <View style={[[ isMobileView() && commonStyles.width48Mobile, !isMobileView() && commonStyles.width30]]}>
                      <Text
                        style={[
                          {
                            fontWeight: "600",
                            fontSize: responsiveFontSize(1.1),
                          },
                          commonStyles.inputLableMobile,
                        ]}
                      >
                        Type of Address<Text style={{color:'red'}} >*</Text>
                      </Text>
                       <SelectDropdown
     dropdownOverlayColor={"No"}
                      dropdownStyle={commonStyles.commanDropdownStyle}
                      selectedRowStyle={commonStyles.selectedRowStyle}
                      selectedRowTextStyle={commonStyles.selectedRowTextStyle}
                      rowStyle={commonStyles.commanRowStyle}
                      rowTextStyle={commonStyles.commonRowTextStyle}
                        data={["Home", "Office", "Other"]}
                        rowTextForSelection={(item) => item}
                        buttonTextAfterSelection={(selectedItem) =>
                          selectedItem
                        }
                        defaultButtonText={
                          "Select address type"
                        }
                        // defaultValue=
                        // defaultButtonText={
                        //   countryList.find(
                        //     (item) => item.country_id === memberAddress.country_id
                        //   )?.country_name || "Select a country"
                        // }

                        buttonStyle={[
                          {
                            width: 225,
                            height: "55%",
                            marginTop: 10,
                            backgroundColor: Color.WHITE,
                            alignItems: "center",
                            justifyContent: "space-between",
                            borderRadius: 10,
                            borderWidth: 1,
                            borderColor: "rgba(0, 0, 0, 0.14)",
                            padding: 10,
                          },
                          commonStyles.inputFieldMobile,
                        ]}
                        buttonTextStyle={{
                          color: "rgba(0, 0, 0)",
                          fontSize: isMobileView()
                            ? responsiveFontSize(2.2)
                            : responsiveFontSize(1.1),
                          textAlign: "left",
                          margin: 0,
                          marginVertical: 0,
                          fontWeight: "600",
                        }}
                        renderDropdownIcon={(props) =>
                          renderDropdownIcon(props)
                        }
                        onSelect={(selectedItem, index) => {
                          setSelectedType(selectedItem);
                        }}
                      />
                      {typeErrorAddress !== "" && (
                        <Text
                          style={{
                            color: "red",
                            fontWeight: "500",
                            height: 10,
                          }}
                        >
                          {typeErrorAddress}
                        </Text>
                      )}
                    </View>
                    <View style={[[ isMobileView() && commonStyles.width48Mobile, !isMobileView() && commonStyles.width30]]}>
                      <Text
                        style={[
                          {
                            fontWeight: "600",
                            fontSize: responsiveFontSize(1.1),
                          },
                          commonStyles.inputLableMobile,
                        ]}
                      >
                        Country<Text style={{color:'red'}} >*</Text>
                      </Text>
                       <SelectDropdown search searchPlaceHolder={"Search Country.."}
                      searchInputStyle={commonStyles.commanDropdownSearchStyle}
                      searchInputTxtStyle={{borderColor:'transparent',borderRadius:6,paddingHorizontal:10}}

     dropdownOverlayColor={"No"}
                      dropdownStyle={commonStyles.commanDropdownStyle}
                      selectedRowStyle={commonStyles.selectedRowStyle}
                      selectedRowTextStyle={commonStyles.selectedRowTextStyle}
                      rowStyle={commonStyles.commanRowStyle}
                      rowTextStyle={commonStyles.commonRowTextStyle}
                        data={countryList}
                        rowTextForSelection={(item) => item.country_name}
                        buttonTextAfterSelection={(selectedItem) =>
                          selectedItem.country_name
                        }
                        // defaultValue=
                        defaultButtonText={
                          countryList?.find(
                            (item) =>
                              item.country_id === memberAddress.country_id
                          )?.country_name || "Select a country"
                        }

                        buttonStyle={[
                          {
                          width: 225,
                          height: "50%",
                          marginTop: 10,
                          backgroundColor: Color.WHITE,
                          alignItems: "center",
                          justifyContent: "space-between",
                          borderRadius: 10,
                          borderWidth: 1,
                          borderColor: "rgba(0, 0, 0, 0.14)",
                          padding: 10,
                        },
                          commonStyles.inputFieldMobile,
                        ]}
                        buttonTextStyle={{
                          color: "rgba(0, 0, 0)",
                          fontSize: isMobileView()
                            ? responsiveFontSize(2.2)
                            : responsiveFontSize(1.1),
                          textAlign: "left",
                          margin: 0,
                          marginVertical: 0,
                          fontWeight: "600",
                        }}
                        renderDropdownIcon={(props) =>
                          renderDropdownIcon(props)
                        }
                        onSelect={(selectedItem, index) => {
                          setSelectedCountry(selectedItem.country_id);
                          getStateMemberList(selectedItem.country_name)
                          setCountryError('')
                          setSelectedState('')
                          setSelectedDistrict('')
                          setSelectedCity('')
                          setNewZipcode('');
                          setDistrictList([])
                          setCityList([]);
                          setPincodeList([])
                        }}
                      />
                      {countryError !== "" && (
                        <Text
                          style={{
                            color: "red",
                            fontWeight: "500",
                            height: 10,
                          }}
                        >
                          {countryError}
                        </Text>
                      )}
                    </View>
                    <View style={[[ isMobileView() && commonStyles.width48Mobile, !isMobileView() && commonStyles.width30]]} >
                      <Text
                        style={[
                          {
                            fontWeight: "600",
                            fontSize: responsiveFontSize(1.1),
                            color:stateList.length===0?'#b2b2b2':'#000'
                          },
                          commonStyles.inputLableMobile,
                        ]}
                      >
                        State<Text style={{color:'red'}} >*</Text>
                      </Text>
                       <SelectDropdown search searchPlaceHolder={"Search State.."}
                      searchInputStyle={commonStyles.commanDropdownSearchStyle}
                      searchInputTxtStyle={{borderColor:'transparent',borderRadius:6,paddingHorizontal:10}}

     dropdownOverlayColor={"No"}
                      dropdownStyle={commonStyles.commanDropdownStyle}
                      selectedRowStyle={commonStyles.selectedRowStyle}
                      selectedRowTextStyle={commonStyles.selectedRowTextStyle}
                      rowStyle={commonStyles.commanRowStyle}
                      rowTextStyle={commonStyles.commonRowTextStyle}

                      disabled={stateList.length===0?true:false}
                        data={stateList}
                        rowTextForSelection={(item) => item.state_name}
                        buttonTextAfterSelection={(selectedItem) =>
                          selectedItem.state_name
                        }
                        defaultButtonText={
                          cityList.find(
                            (item) => item.state_id === memberAddress.state_id
                          )?.state_name || "Select a State"
                        }
                        onSelect={(selectedItem, index) => {
                          setSelectedState(selectedItem.state_id);
                          getDistrictMemberList(selectedItem.state_name);
                          setStateError('')
                          setSelectedDistrict('')
                          setSelectedCity('')
                          setNewZipcode('');
                          setDistrictList([])
                          setCityList([]);
                          setPincodeList([])
                        }}

                        buttonStyle={[
                          {
                          width: 225,
                          height: "50%",
                          marginTop: 10,
                          backgroundColor: Color.WHITE,
                          alignItems: "center",
                          justifyContent: "space-between",
                          borderRadius: 10,
                          borderWidth: 1,
                          borderColor: "rgba(0, 0, 0, 0.14)",
                          padding: 10,
                        },
                          commonStyles.inputFieldMobile,
                        ]}
                        buttonTextStyle={{
                          color: "rgba(0, 0, 0)",
                          fontSize: isMobileView()
                            ? responsiveFontSize(2.2)
                            : responsiveFontSize(1.1),
                          textAlign: "left",
                          margin: 0,
                          marginVertical: 0,
                          fontWeight: "600",
                        }}
                        renderDropdownIcon={(props) =>
                          renderDropdownIcon(props)
                        }
                      />
                      {stateError !== "" && (
                        <Text
                          style={{
                            color: "red",
                            fontWeight: "500",
                            height: 10,
                          }}
                        >
                          {stateError}
                        </Text>
                      )}
                    </View>



                  </View>
                </View>

                <View
                  style={[
                  commonStyles.flexRow,
                    commonStyles.justifyBetween,
                    { width: "100%" },
                    isMobileView() && commonStyles.flexWrap
                  ]}
                >
                  <View style={[[ isMobileView() && commonStyles.width48Mobile, !isMobileView() && commonStyles.width30]]} >
                    <Text
                      style={[
                        {
                          fontWeight: "600",
                          fontSize: responsiveFontSize(1.1),
                          color:districtList.length===0?'#b2b2b2':'#000'
                        },
                        commonStyles.inputLableMobile,
                      ]}
                    >
                      District<Text style={{color:'red'}} >*</Text>
                    </Text>
                     <SelectDropdown search searchPlaceHolder={"Search District.."}
                      searchInputStyle={commonStyles.commanDropdownSearchStyle}
                      searchInputTxtStyle={{borderColor:'transparent',borderRadius:6,paddingHorizontal:10}}
     dropdownOverlayColor={"No"}
                      dropdownStyle={commonStyles.commanDropdownStyle}
                      selectedRowStyle={commonStyles.selectedRowStyle}
                      selectedRowTextStyle={commonStyles.selectedRowTextStyle}
                      rowStyle={commonStyles.commanRowStyle}
                      rowTextStyle={commonStyles.commonRowTextStyle}
                     disabled={districtList.length===0?true:false}
                      data={districtList}
                      rowTextForSelection={(item) => item.district_name}
                      buttonTextAfterSelection={(selectedItem) =>
                        selectedItem.district_name
                      }
                      defaultButtonText={
                        districtList.find(
                          (item) => item.state_id === memberAddress.district_id
                        )?.district_name || "Select a District"
                      }
                      onSelect={(selectedItem, index) => {
                        setSelectedDistrict(selectedItem.district_id);
                        //getTehsilMemberList(selectedItem.district_name);
                        getCityMemberList(selectedItem.district_name)
                        setSelectedCity('')
                        setNewZipcode('')
                        setCityList([]);
                        setPincodeList([])
                        setDistrictError('')
                      }}

                      buttonStyle={[
                          {
                          width: 225,
                          height: "50%",
                          marginTop: 10,
                          backgroundColor: Color.WHITE,
                          alignItems: "center",
                          justifyContent: "space-between",
                          borderRadius: 10,
                          borderWidth: 1,
                          borderColor: "rgba(0, 0, 0, 0.14)",
                          padding: 10,
                        },
                          commonStyles.inputFieldMobile,
                        ]}
                      buttonTextStyle={{
                        color: "rgba(0, 0, 0)",
                        fontSize: isMobileView()
                            ? responsiveFontSize(2.2)
                            : responsiveFontSize(1.1),
                        textAlign: "left",
                        margin: 0,
                        marginVertical: 0,
                        fontWeight: "600",
                      }}
                      renderDropdownIcon={(props) => renderDropdownIcon(props)}
                    />
                    {districtError !== "" && (
                      <Text
                        style={{ color: "red", fontWeight: "500", height: 10 }}
                      >
                        {districtError}
                      </Text>
                    )}
                  </View>
                {/* { isMobileView() && <View style={[[ isMobileView() && commonStyles.width48Mobile]]}>
                    <Text
                      style={[
                        {
                          fontWeight: "600",
                          fontSize: responsiveFontSize(1.1),
                          color:tehsilList.length===0?'#b2b2b2':'#000'
                        },
                        commonStyles.inputLableMobile,
                      ]}
                    >
                      Tehsil*
                    </Text>
                     <SelectDropdown search searchPlaceHolder={"Search Tehsil.."}
                      searchInputStyle={commonStyles.commanDropdownSearchStyle}
                      searchInputTxtStyle={{borderColor:'transparent',borderRadius:6,paddingHorizontal:10}}
     dropdownOverlayColor={"No"}
                      dropdownStyle={commonStyles.commanDropdownStyle}
                      selectedRowStyle={commonStyles.selectedRowStyle}
                      selectedRowTextStyle={commonStyles.selectedRowTextStyle}
                      rowStyle={commonStyles.commanRowStyle}
                      rowTextStyle={commonStyles.commonRowTextStyle}
                    disabled={tehsilList.length===0?true:false}
                      data={tehsilList}
                      rowTextForSelection={(item) => item.tehsil_name}
                      buttonTextAfterSelection={(selectedItem) =>
                        selectedItem.tehsil_name
                      }
                      // defaultValue=
                      defaultButtonText={
                        countryList.find(
                          (item) =>
                            item.country_id === memberAddress.district_id
                        )?.tehsil_name || "Select a tehsil"
                      }

                      buttonStyle={[
                          {
                          width: 225,
                          height: "50%",
                          marginTop: 10,
                          backgroundColor: Color.WHITE,
                          alignItems: "center",
                          justifyContent: "space-between",
                          borderRadius: 10,
                          borderWidth: 1,
                          borderColor: "rgba(0, 0, 0, 0.14)",
                          padding: 10,
                        },
                          commonStyles.inputFieldMobile,
                        ]}
                      buttonTextStyle={{
                        color: "rgba(0, 0, 0, 0.30)",
                        fontSize: isMobileView()
                            ? responsiveFontSize(2.2)
                            : responsiveFontSize(1.1),
                        textAlign: "left",
                        margin: 0,
                        marginVertical: 0,
                        fontWeight: "600",
                      }}
                      renderDropdownIcon={(props) => renderDropdownIcon(props)}
                      onSelect={(selectedItem, index) => {
                        setSelectedTehsil(selectedItem.tehsil_id);

                      }}
                    />
                    {tehsilError !== "" && (
                      <Text
                        style={{ color: "red", fontWeight: "500", height: 10 }}
                      >
                        {tehsilError}
                      </Text>
                    )}
                  </View>}
            {!isMobileView() &&      <View style={[ commonStyles.width30]}  >
                    <Text
                      style={[
                        {
                          fontWeight: "600",
                          fontSize: responsiveFontSize(1.1),
                          color:tehsilList.length===0?'#b2b2b2':'#000'
                        },
                        commonStyles.inputLableMobile,
                      ]}
                    >
                      Tehsil
                    </Text>
                     <SelectDropdown search searchPlaceHolder={"Search Tehsil.."}
                      searchInputStyle={commonStyles.commanDropdownSearchStyle}
                      searchInputTxtStyle={{borderColor:'transparent',borderRadius:6,paddingHorizontal:10}}
     dropdownOverlayColor={"No"}
                      dropdownStyle={commonStyles.commanDropdownStyle}
                      selectedRowStyle={commonStyles.selectedRowStyle}
                      selectedRowTextStyle={commonStyles.selectedRowTextStyle}
                      rowStyle={commonStyles.commanRowStyle}
                      rowTextStyle={commonStyles.commonRowTextStyle}
                      disabled={tehsilList.length===0?true:false}
                      data={tehsilList}
                      rowTextForSelection={(item) => item.tehsil_name}
                      buttonTextAfterSelection={(selectedItem) =>
                        selectedItem.tehsil_name
                      }
                      // defaultValue=
                      defaultButtonText={
                        countryList.find(
                          (item) =>
                            item.country_id === memberAddress.district_id
                        )?.tehsil_name || "Select a tehsil"
                      }

                      buttonStyle={[
                          {
                          width: 225,
                          height: "50%",
                          marginTop: 10,
                          backgroundColor: Color.WHITE,
                          alignItems: "center",
                          justifyContent: "space-between",
                          borderRadius: 10,
                          borderWidth: 1,
                          borderColor: "rgba(0, 0, 0, 0.14)",
                          padding: 10,
                        },
                          commonStyles.inputFieldMobile,
                        ]}
                      buttonTextStyle={{
                        color: "rgba(0, 0, 0, 0.30)",
                        fontSize: isMobileView()
                            ? responsiveFontSize(2.2)
                            : responsiveFontSize(1.1),
                        textAlign: "left",
                        margin: 0,
                        marginVertical: 0,
                        fontWeight: "600",
                      }}
                      renderDropdownIcon={(props) => renderDropdownIcon(props)}
                      onSelect={(selectedItem, index) => {
                        setSelectedTehsil(selectedItem.tehsil_id);
                        getCityMemberList(selectedItem.tehsil_name)

                      }}
                    />
                    {tehsilError !== "" && (
                      <Text
                        style={{ color: "red", fontWeight: "500", height: 10 }}
                      >
                        {tehsilError}
                      </Text>
                    )}
                  </View>} */}

                  <View style={[[ isMobileView() && commonStyles.width48Mobile, !isMobileView() && commonStyles.width30]]}>
                    <Text
                      style={[
                        {
                          fontWeight: "600",
                          fontSize: responsiveFontSize(1.1),
                          color:cityList.length===0?'#b2b2b2':'#000'
                        },
                        commonStyles.inputLableMobile,
                      ]}
                    >
                      City<Text style={{color:'red'}} >*</Text>
                    </Text>
                     <SelectDropdown search searchPlaceHolder={"Search City.."}
                      searchInputStyle={commonStyles.commanDropdownSearchStyle}
                      searchInputTxtStyle={{borderColor:'transparent',borderRadius:6,paddingHorizontal:10}}
     dropdownOverlayColor={"No"}
                      dropdownStyle={commonStyles.commanDropdownStyle}
                      selectedRowStyle={commonStyles.selectedRowStyle}
                      selectedRowTextStyle={commonStyles.selectedRowTextStyle}
                      rowStyle={commonStyles.commanRowStyle}
                      rowTextStyle={commonStyles.commonRowTextStyle}
                    disabled={cityList.length===0?true:false}
                      data={cityList}
                      rowTextForSelection={(item) => item.city_name}
                      buttonTextAfterSelection={(selectedItem) =>
                        selectedItem.city_name
                      }
                      defaultButtonText={
                        cityList.find(
                          (item) => item.city_id === memberAddress.city_id
                        )?.city_name || "Select a city"
                      }
                      onSelect={(selectedItem, index) => {
                        setSelectedCity(selectedItem.city_id);
                        getPincodeMemberList(selectedItem.city_name);
                        setCityError('')
                        setNewZipcode('')
                        setPincodeList([])

                      }}

                      buttonStyle={[
                          {
                          width: 225,
                          height: "50%",
                          marginTop: 10,
                          backgroundColor: Color.WHITE,
                          alignItems: "center",
                          justifyContent: "space-between",
                          borderRadius: 10,
                          borderWidth: 1,
                          borderColor: "rgba(0, 0, 0, 0.14)",
                          padding: 10,
                        },
                          commonStyles.inputFieldMobile,
                        ]}
                      buttonTextStyle={{
                        color: "rgba(0, 0, 0)",
                        fontSize: isMobileView()
                            ? responsiveFontSize(2.2)
                            : responsiveFontSize(1.1),
                        textAlign: "left",
                        margin: 0,
                        marginVertical: 0,
                        fontWeight: "600",
                      }}
                      renderDropdownIcon={(props) => renderDropdownIcon(props)}
                    />
                    {cityError !== "" && (
                      <Text
                        style={{ color: "red", fontWeight: "500", height: 10 }}
                      >
                        {cityError}
                      </Text>
                    )}
                  </View>
                  <View style={[[ isMobileView() && commonStyles.width48Mobile, !isMobileView() && commonStyles.width30]]}>
                    <Text
                      style={[
                        {
                          fontWeight: "600",
                          fontSize: responsiveFontSize(1.1),
                          color:pincodeList.length===0?'#b2b2b2':'#000'
                        },
                        commonStyles.inputLableMobile,
                      ]}
                    >
                      Pin Code<Text style={{color:'red'}} >*</Text>
                    </Text>
                       <SelectDropdown search searchPlaceHolder={"Search Pin Code.."}
                      searchInputStyle={commonStyles.commanDropdownSearchStyle}
                      searchInputTxtStyle={{borderColor:'transparent',borderRadius:6,paddingHorizontal:10}}
     dropdownOverlayColor={"No"}
                      dropdownStyle={commonStyles.commanDropdownStyle}
                      selectedRowStyle={commonStyles.selectedRowStyle}
                      selectedRowTextStyle={commonStyles.selectedRowTextStyle}
                      rowStyle={commonStyles.commanRowStyle}
                      rowTextStyle={commonStyles.commonRowTextStyle}
                      disabled={pincodeList.length===0?true:false}
                        data={pincodeList}
                        rowTextForSelection={(item) => item.pincode}
                        buttonTextAfterSelection={(selectedItem) =>
                          selectedItem.pincode
                        }
                        defaultButtonText={
                          cityList.find(
                            (item) => item.pincode_id === memberAddress.pincode_id
                          )?.pincode || "Select Pincode"
                        }
                        onSelect={(selectedItem, index) => {
                          setNewZipcode(selectedItem.pincode);
                          setPincodeError('')
                        }}

                        buttonStyle={[
                          {
                          width: 225,
                          height: "50%",
                          marginTop: 10,
                          backgroundColor: Color.WHITE,
                          alignItems: "center",
                          justifyContent: "space-between",
                          borderRadius: 10,
                          borderWidth: 1,
                          borderColor: "rgba(0, 0, 0, 0.14)",
                          padding: 10,
                        },
                          commonStyles.inputFieldMobile,
                        ]}
                        buttonTextStyle={{
                          color: "rgba(0, 0, 0)",
                          fontSize: isMobileView()
                            ? responsiveFontSize(2.2)
                            : responsiveFontSize(1.1),
                          textAlign: "left",
                          margin: 0,
                          marginVertical: 0,
                          fontWeight: "600",
                        }}
                        renderDropdownIcon={(props) =>
                          renderDropdownIcon(props)
                        }
                      />
                    {pincodeError !== "" && (
                      <Text
                        style={{ color: "red", fontWeight: "500", height: 10 }}
                      >
                        {pincodeError}
                      </Text>
                    )}
                  </View>

                </View>
        </>
      )



}
                <View
                  style={[
                    !isMobileView() && commonStyles.flexRow,
                    { gap: !isMobileView() && 20, width: "100%" ,height: !isMobileView() && '20%'},
                  ]}
                >



                  <View style={{ flex: 1 }} >
                    <Text
                      style={[{
  fontWeight: "600",
  fontSize: responsiveFontSize(1.1),
  color:zoneList.length===0?'#b2b2b2':'#000'

},commonStyles.inputLableMobile]}
                    >
                      Zone<Text style={{color:'red'}} >*</Text>
                    </Text>
                     <SelectDropdown search searchPlaceHolder={"Search Zone.."}
                      searchInputStyle={commonStyles.commanDropdownSearchStyle}
                      searchInputTxtStyle={{borderColor:'transparent',borderRadius:6,paddingHorizontal:10}}
     dropdownOverlayColor={"No"}
                      dropdownStyle={commonStyles.commanDropdownStyle}
                      selectedRowStyle={commonStyles.selectedRowStyle}
                      selectedRowTextStyle={commonStyles.selectedRowTextStyle}
                      rowStyle={commonStyles.commanRowStyle}
                      rowTextStyle={commonStyles.commonRowTextStyle}
                      data={zoneList}
                      rowTextForSelection={(item) => item.zone_name}
                      buttonTextAfterSelection={(selectedItem) =>
                        selectedItem.zone_name
                      }
                      onChangeSearchInputText={(text) => handleSearchZone(text)}

                      // defaultValue=
                      defaultButtonText={
                        memberAddress.zone_name?memberAddress.zone_name:"Select Zone"
                      }

                      buttonStyle={[
                          {
               width: 225,
                        height: "55%",
                        marginTop: 10,
                        backgroundColor: Color.WHITE,
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderRadius: 10,
                        borderWidth: 1,
                        borderColor: "rgba(0, 0, 0, 0.14)",
                        padding: 10,
                        },
                          commonStyles.inputFieldMobile,
                        ]}
                      buttonTextStyle={{
                        color: "rgba(0, 0, 0)",
                        fontSize: isMobileView()
                            ? responsiveFontSize(2.2)
                            : responsiveFontSize(1.1),
                        textAlign: "left",
                        margin: 0,
                        marginVertical: 0,
                        fontWeight: "600",
                      }}
                      renderDropdownIcon={(props) => renderDropdownIcon(props)}
                      onSelect={(selectedItem, index) => {
                        if(index == zoneList.length - 1)
                        {
                          return ;
                        }
                        if(selectedItem.zone_sect_number && selectedItem.zone_sect_number!=''){
                          setSelectedZone(selectedItem.zone_code +'-'+selectedItem.zone_sect_number)
                          }else{
                          setSelectedZone(selectedItem.zone_code)
  
                          }
                        setSelectedBranch('')
                        setBranchesList([])
                        handleSearchZone("")
                        // getBranchMemberList(selectedItem.zone_code);
                      }}
                    />
                    {zoneError !== "" && (
                      <Text
                        style={{ color: "red", fontWeight: "500", height: 10 }}
                      >
                        {zoneError}
                      </Text>
                    )}
                  </View>

                  <View style={{ flex: 1 }}>
                    <Text
                      style={[{
  fontWeight: "600",
  fontSize: responsiveFontSize(1.1),
  color:branchesList.length===0?'#b2b2b2':'#000'

},commonStyles.inputLableMobile]}
                    >
                      Branch<Text style={{color:'red'}} >*</Text>
                    </Text>
                     <SelectDropdown
                  dropdownOverlayColor={"No"}
                  dropdownStyle={commonStyles.commanDropdownStyle}
                  selectedRowStyle={commonStyles.selectedRowStyle}
                  selectedRowTextStyle={commonStyles.selectedRowTextStyle}
                  rowStyle={commonStyles.commanRowStyle}
                  rowTextStyle={commonStyles.commonRowTextStyle}
                  searchPlaceHolder={"Search Branch.."}
                  searchInputStyle={commonStyles.commanDropdownSearchStyle}
                  searchInputTxtStyle={{borderColor:'transparent',borderRadius:6,paddingHorizontal:10}}
                    search

                      data={branchesList}
                      rowTextForSelection={(item) => item.branch_name}
                      buttonTextAfterSelection={(selectedItem) =>
                        selectedItem.branch_name
                      }
                      // defaultValue=
                      defaultButtonText={
                        memberAddress.branch_name?memberAddress.branch_name:"Select Branch Name"
                      }

                      buttonStyle={[
                          {
               width: 225,
                        height: "55%",
                        marginTop: 10,
                        backgroundColor: Color.WHITE,
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderRadius: 10,
                        borderWidth: 1,
                        borderColor: "rgba(0, 0, 0, 0.14)",
                        padding: 10,
                        },
                          commonStyles.inputFieldMobile,
                        ]}
                      buttonTextStyle={{
                        color: "rgba(0, 0, 0)",
                        fontSize: isMobileView()
                            ? responsiveFontSize(2.2)
                            : responsiveFontSize(1.1),
                        textAlign: "left",
                        margin: 0,
                        marginVertical: 0,
                        fontWeight: "600",
                      }}
                      onChangeSearchInputText={(text) => handleBranchSearch(text)}
                      renderDropdownIcon={(props) => renderDropdownIcon(props)}
                      onSelect={(selectedItem, index) => {
                        setSelectedBranch(selectedItem.branch_code);
                        handleBranchSearch("")
                      }}
                    />
                    {branchError !== "" && (
                      <Text
                        style={{ color: "red", fontWeight: "500", height: 10 }}
                      >
                        {branchError}
                      </Text>
                    )}
                  </View>
                </View>

                <View
                  style={[
                    !isMobileView() && commonStyles.flexRow,
                    { gap: !isMobileView() && 20, width: "100%" ,height: !isMobileView() && '20%'},
                  ]}
                >
                  <View style={{ flex: isMobileView() ? "initial" : 1 }}>
                    <Text
                      style={[
                        {
                          fontWeight: "600",
                          fontSize: responsiveFontSize(1.1),
                        },
                        commonStyles.inputLableMobile,
                      ]}
                    >
                      Flat, House no., Building, Company, Apartment<Text style={{color:'red'}} >*</Text>
                    </Text>
                    <TextInput
                      onChangeText={(text) => {
                        setAddressLine1(text);
                      }}
                      placeholderTextColor={"rgba(0, 0, 0, 0.3)"}
                      placeholder="Flat, House no., Building, Company, Apartment"
                      multiline
                      maxLength={70}
                      style={[{
                        outlineStyle: "none", // Remove the border
                        height: isMobileView() ? responsiveHeight(8) : "100%",
                        width: "100%",

                        marginTop: 10,
                        backgroundColor: Color.WHITE,
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderRadius: 10,
                        borderWidth: 1,
                        borderColor: "rgba(0, 0, 0, 0.14)",
                        padding: 10,
                        fontSize: isMobileView()
                            ? responsiveFontSize(2.2)
                            : responsiveFontSize(1.1),
                            fontWeight: "600",
                      }]}
                    />
                    {addressLine1Error !== "" && (
                      <Text
                        style={{ color: "red", fontWeight: "500", height: 10 }}
                      >
                        {addressLine1Error}
                      </Text>
                    )}
                  </View>
                  <View style={{flex: isMobileView() ? "initial" : 1 }}>
                    <Text
                      style={[
                        {
                          fontWeight: "600",
                          fontSize: responsiveFontSize(1.1),
                        },
                        commonStyles.inputLableMobile,
                      ]}
                    >
                       Area, Street, Sector, Village<Text style={{color:'red'}} ></Text>
                    </Text>
                    <TextInput
                      onChangeText={(text) => {
                        setAddressLine2(text);
                      }}
                      placeholderTextColor={"rgba(0, 0, 0, 0.3)"}
                      placeholder="Area, Street, Sector, Village"
                      multiline
                      style={[{
                        outlineStyle: "none", // Remove the border
                        height: isMobileView() ? responsiveHeight(7) : "100%",
                        width: "100%",
                        marginTop: 10,
                        backgroundColor: Color.WHITE,
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderRadius: 10,
                        borderWidth: 1,
                        borderColor: "rgba(0, 0, 0, 0.14)",
                        fontSize: isMobileView()
                            ? responsiveFontSize(2.2)
                            : responsiveFontSize(1.1),
                            fontWeight: "600",

                        padding: 10,
                      }]}
                    />
                    {addressLine2Error !== "" && (
                      <Text
                        style={{ color: "red", fontWeight: "500", height: 10 }}
                      >
                        {addressLine2Error}
                      </Text>
                    )}
                  </View>
                </View>

                <View
                  style={{
                    width: "100%",
                    borderWidth: 0,
                    justifyContent: "center",
                    alignItems: "flex-end",
                    top: isMobileView() && "4%",
                  }}
                >
                  <Hoverable style={[{width: isMobileView()  && "100%"}]}>
                    {({ hovered }) => (
                      <TouchableOpacity
                        onPress={() => handleSubmit()}
                        style={{
                          backgroundColor: hovered
                            ? Color.BLACK
                            : Color.BLUE_MEDIUM,
                          width: isMobileView()  ? "100%" : 100,
                          height: 40,
                          borderRadius: 10,
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Text style={[{ color: "#FFF", fontWeight: "700" },commonStyles.formButtonTextMobile]}>
                          Add
                        </Text>
                      </TouchableOpacity>
                    )}
                  </Hoverable>
                </View>
              </View>
            </View>
          </Modal>

          {/* EDit Profile */}
          <Modal
            animationType="fade"
            transparent={true}
            visible={profileModalVisible}
            onRequestClose={() => {
              Alert.alert("Modal has been closed.");
              setProfileModalVisible(!profileModalVisible);
            }}
          >
            <TouchableWithoutFeedback style={{height: isMobileView() && "100%"}} onPress={handleTouchablePress}>
            <View style={styles.centeredView}>
              <View style={[ isMobileView() ? styles.modalViewMobile : styles.modalView, !isMobileView() && { height: "65%" }, isMobileView() && commonStyles.justifyBetween]}>
               <View style={{width:"100%",zIndex:99999}}>

                <View
                  style={{
                    flexDirection: "row",
                    width: "100%",
                    justifyContent: "space-between",
                    borderWidth: 0,
                  }}
                >
                  <Text style={styles.modalText}>Edit Personal Data</Text>
                  <TouchableOpacity
                  disabled={disabledButton}
                    onPress={() => setProfileModalVisible(!profileModalVisible)}
                  >
                    <Image
                      style={{ width: 30, height: 30 }}
                      source={require("../../assets/images/cross.png")}
                    />
                  </TouchableOpacity>
                </View>

                {/* <View style={{width:'100%',margin:0,borderWidth:0,height:'30%'}}>
              <Text style={{fontWeight:'600',fontSize:responsiveFontSize(1.1),margin:5}}>Street</Text>
              <TextInput placeholderTextColor={'rgba(0, 0, 0, 0.3)'} placeholder="Lorem ipsum dolor sit amet consectetur. Nisl vel amet ultrices scelerisque velit et. Rhoncus donec vitae et in nullam eleifend. Dui elementum consectetur ipsum lacus pellentesque. Phasellus elementum diam sed a purus faucibus. Eu velit phasellus." multiline style={{borderWidth:2,borderColor:'rgba(0, 0, 0, 0.14)',backgroundColor:'#ffffff',height:'100%',borderRadius:5,padding:10}}/>
            </View> */}
      { isMobileView() ?
      (
        <>
              <View style={[commonStyles.Col2GridParent,{ marginTop: isMobileView() && 14,flex:"inherit"}]}>
                    <View style={[commonStyles.Col2GridItem]}>
  <Text
    style={{
      fontWeight: "600",
      fontSize: isMobileView()  ? responsiveFontSize(2.2) : responsiveFontSize(1.1),
      margin: 0,
    }}
  >
    Select Title<Text style={{color:'red'}} >*</Text>
  </Text>
   <SelectDropdown
dropdownOverlayColor={"No"}
      dropdownStyle={commonStyles.commanDropdownStyle}
      selectedRowStyle={commonStyles.selectedRowStyle}
      selectedRowTextStyle={commonStyles.selectedRowTextStyle}
      rowStyle={commonStyles.commanRowStyle}
      rowTextStyle={commonStyles.commonRowTextStyle}

    buttonStyle={{
      width: "100%",
      height: "55%" ,
      marginTop:10,
      backgroundColor: Color.WHITE,
      alignItems: "center",
      justifyContent: "space-between",
      borderRadius: 10,
      borderWidth: 1,
      borderColor: "rgba(0, 0, 0, 0.14)",
      padding:10
    }}
    buttonTextStyle={{
      color: "rgba(0, 0, 0,)",
      fontSize: isMobileView()  ? responsiveFontSize(2.2) :  responsiveFontSize(1.1),
      textAlign: "left",
      margin: 0,
      marginVertical: 0,
      fontWeight: "600",
    }}
    defaultValue={profileData?.title}
    data={["MR.", "MS.", "MRS."]}
    onSelect={(selectedItem, index) => {
      setSelectedTitle(selectedItem)
    }}

    renderDropdownIcon={(props) => renderDropdownIcon(props)}
  />

    {titleError !== "" && (
      <Text
        style={{ color: "red", fontWeight: "500", height: 10 }}
      >
        {titleError}
      </Text>
    )}
</View>
  <View
    style={[commonStyles.Col2GridItem]}
  >
    <Text
      style={{
        fontWeight: "600",
        fontSize: isMobileView()
          ? responsiveFontSize(2.2)
          : responsiveFontSize(1.1),
      }}
    >
      Name<Text style={{color:'red'}} >*</Text>
    </Text>
    <TextInput
      defaultValue={profileData?.member_name}
      onChangeText={(text) => {
        hanldeName(text)
      }}
      placeholderTextColor={"rgba(0, 0, 0, 0.30)"}
      placeholder="Name"
      style={{
        height: "55%" ,
        marginTop: 10,
        backgroundColor: Color.WHITE,
        alignItems: "center",
        justifyContent: "space-between",
        borderRadius: 10,
        borderWidth: 1,
        borderColor: "rgba(0, 0, 0, 0.14)",
        fontSize: isMobileView()
          ? responsiveFontSize(2.2)
          : responsiveFontSize(1.1),
        fontWeight: "600",

        padding: 10,
      }}
    />

    {nameError !== "" && (
      <Text
        style={{ color: "red", fontWeight: "500", height: 10 }}
      >
        {nameError}
      </Text>
    )}
  </View>

  <View
  ref={datePickerRef}
    style={[commonStyles.Col2GridItem,{zIndex:99, width: isDatePickerVisible ? "100%" : "48%" }]}
  >
    <Text
      style={{
        fontWeight: "600",
        fontSize: isMobileView()
          ? responsiveFontSize(2.2)
          : responsiveFontSize(1.1),
      }}
    >
      Date Of Birth
    </Text>

    <TouchableOpacity onPress={()=>{setDatePickerVisible(!isDatePickerVisible)}}>
               <TextInput
      placeholderTextColor={"rgba(0, 0, 0, 0.30)"}
      placeholder="Date Of Birth"
      //defaultValue={profileData?.dob}
      value={ selectedDOBDate}
      style={{
        height: "55%" ,
        marginTop: 10,
        backgroundColor: Color.WHITE,
        alignItems: "center",
        justifyContent: "space-between",
        borderRadius: 10,
        borderWidth: 1,
        borderColor: "rgba(0, 0, 0, 0.14)",
        fontSize: isMobileView()
          ? responsiveFontSize(2.2)
          : responsiveFontSize(1.1),
        fontWeight: "600",

        padding: 10,
      }}></TextInput>
      </TouchableOpacity>
      {isDatePickerVisible && (

        <View style={{position:'absolute',width:'100%',backgroundColor:'#F5FCFF'}}>
           {/* <DatePicker
           current={dobCalendar?dobCalendar:''}
           mode="calendar"
           onCancel={() => setDatePickerVisible(false)}
          maximumDate={formattedCurrentDate}

            onSelectedChange={(date) =>  }
          /> */}
                <DateTimePicker
        mode="single"
        date={dobCalendar?dobCalendar:new Date()}
        onChange={(params) => {handleDateSelected(params.date);  setDatePickerVisible(false)}}
      />
          </View>
      )}

    {/* {emailError !== "" && (
      <Text
        style={{ color: "red", fontWeight: "500", height: 10 }}
      >
        {emailError}
      </Text>
    )} */}
  </View>




  <View style={[commonStyles.Col2GridItem]}>
  <Text
    style={{
      fontWeight: "600",
      fontSize: isMobileView()  ? responsiveFontSize(2.2) : responsiveFontSize(1.1),
      margin: 0,
    }}
  >
    Gender<Text style={{color:'red'}} >*</Text>
  </Text>
   <SelectDropdown
      dropdownStyle={commonStyles.commanDropdownStyle}
      selectedRowStyle={commonStyles.selectedRowStyle}
      selectedRowTextStyle={commonStyles.selectedRowTextStyle}
      rowStyle={commonStyles.commanRowStyle}
      rowTextStyle={commonStyles.commonRowTextStyle}

    buttonStyle={{
      width: "100%",
      height: "55%" ,
      marginTop:10,
      backgroundColor: Color.WHITE,
      alignItems: "center",
      justifyContent: "space-between",
      borderRadius: 10,
      borderWidth: 1,
      borderColor: "rgba(0, 0, 0, 0.14)",
      padding:10
    }}
    buttonTextStyle={{
      color: "rgba(0, 0, 0,)",
      fontSize: isMobileView()  ? responsiveFontSize(2.2) :  responsiveFontSize(1.1),
      textAlign: "left",
      margin: 0,
      marginVertical: 0,
      fontWeight: "600",
    }}
    defaultValue={profileData?.gender?.charAt(0).toUpperCase() + profileData?.gender?.slice(1)}
    data={["Male", "Female", "Other"]}
    onSelect={(selectedItem, index) => {
      setSelectedGender(selectedItem)
    }}

    renderDropdownIcon={(props) => renderDropdownIcon(props)}
  />

    {genderError !== "" && (
      <Text
        style={{ color: "red", fontWeight: "500", height: 10 }}
      >
        {genderError}
      </Text>
    )}
</View>
<View style={[commonStyles.Col2GridItem]}>
  <Text
    style={{
      fontWeight: "600",
      fontSize: isMobileView()  ? responsiveFontSize(2.2) : responsiveFontSize(1.1),
      margin: 0,
    }}
  >
    Care Of Type<Text style={{color:'red'}} >*</Text>
  </Text>
   <SelectDropdown
dropdownOverlayColor={"No"}
      dropdownStyle={commonStyles.commanDropdownStyle}
      selectedRowStyle={commonStyles.selectedRowStyle}
      selectedRowTextStyle={commonStyles.selectedRowTextStyle}
      rowStyle={commonStyles.commanRowStyle}
      rowTextStyle={commonStyles.commonRowTextStyle}

    buttonStyle={{
      width: "100%",
      height: "55%" ,
      marginTop:10,
      backgroundColor: Color.WHITE,
      alignItems: "center",
      justifyContent: "space-between",
      borderRadius: 10,
      borderWidth: 1,
      borderColor: "rgba(0, 0, 0, 0.14)",
      padding:10
    }}
    buttonTextStyle={{
      color: "rgba(0, 0, 0,)",
      fontSize: isMobileView()  ? responsiveFontSize(2.2) :  responsiveFontSize(1.1),
      textAlign: "left",
      margin: 0,
      marginVertical: 0,
      fontWeight: "600",
    }}
    defaultValue={profileData?.co_type}
    data={["S/O", "D/O", "W/O","C/O"]}
    onSelect={(selectedItem, index) => {
      setSelectedCareOfType(selectedItem)
    }}

    renderDropdownIcon={(props) => renderDropdownIcon(props)}
  />

    {coTypeError !== "" && (
      <Text
        style={{ color: "red", fontWeight: "500", height: 10 }}
      >
        {coTypeError}
      </Text>
    )}
</View>
  <View
    style={[commonStyles.Col2GridItem]}
  >
    <Text
      style={{
        fontWeight: "600",
        fontSize: isMobileView()
          ? responsiveFontSize(2.2)
          : responsiveFontSize(1.1),
      }}
    >
      Care Of Name<Text style={{color:'red'}} >*</Text>
    </Text>
    <TextInput
      placeholderTextColor={"rgba(0, 0, 0, 0.30)"}
      placeholder="Care Of Name"
      defaultValue={profileData?.co_name}
      onChangeText={(text)=> handleCareOfName(text)}
      style={{
        height: "55%" ,
        marginTop: 10,
        backgroundColor: Color.WHITE,
        alignItems: "center",
        justifyContent: "space-between",
        borderRadius: 10,
        borderWidth: 1,
        borderColor: "rgba(0, 0, 0, 0.14)",
        fontSize: isMobileView()
          ? responsiveFontSize(2.2)
          : responsiveFontSize(1.1),
        fontWeight: "600",

        padding: 10,
      }}
    />
    {coNameError !== "" && (
      <Text
        style={{ color: "red", fontWeight: "500", height: 10 }}
      >
        {coNameError}
      </Text>
    )}
  </View>
                {isTooltipVisible && (
    <View
      style={{
        position: "absolute",
        left: "-20%",
        top: "-30%",
        backgroundColor: Color.BLACK,
        padding: 12,
        zIndex:100,
        borderRadius: 2,
      }}
    >
      <Text
        style={{
          textAlign: "center",
          fontSize: 14,
          fontWeight: "600",
          color: Color.WHITE,
        }}
      >
        To update mobile number please raise a ticket
      </Text>
    </View>
  )}

<View
style={[{

alignSelf: "baseline",
borderWidth: 0,
opacity: 0.5,
},commonStyles.Col2GridItem]}
>
{isMobileView() && (
<View
style={[
commonStyles.flexRow,
commonStyles.justifyBetween,
commonStyles.alignCenter,
]}
>
<Text
style={{
fontWeight: "600",
fontSize: isMobileView()
? responsiveFontSize(2.2)
: responsiveFontSize(1.1),
}}
>
Mobile No.
</Text>
<TouchableOpacity
onPress={() => navigation.navigate("Helpdesk")}
onMouseEnter={() => setIsTooltipVisible(true)}
onMouseLeave={() => setIsTooltipVisible(false)}
>
<Image
source={require("../../assets/images/info.png")}
style={{
height: 25,
width: 25,
resizeMode: "contain",
}}
></Image>
</TouchableOpacity>
</View>
)}
{!isMobileView() && (
<Text
style={{
fontWeight: "600",
fontSize: isMobileView()
? responsiveFontSize(2.2)
: responsiveFontSize(1.1),
}}
>
Mobile No.
</Text>
)}

<TextInput
disabled={true}
defaultValue={profileData?.mobile}
onChangeText={(text) => {
setSelectedPhone(text);
}}
placeholderTextColor={"rgba(0, 0, 0, 0.30)"}
placeholder="Mobile No."
style={{
  height: "55%" ,
marginTop: 10,
backgroundColor: Color.WHITE,
alignItems: "center",
justifyContent: "space-between",
borderRadius: 10,
borderWidth: 1,
borderColor: "rgba(0, 0, 0, 0.14)",
fontSize: isMobileView()
? responsiveFontSize(2.2)
: responsiveFontSize(1.1),
fontWeight: "600",

padding: 10,
}}
/>
</View>
<View
  style={[commonStyles.Col2GridItem]}
>

    <Text
      style={{
        fontWeight: "600",
        fontSize: isMobileView()
          ? responsiveFontSize(2.2)
          : responsiveFontSize(1.1),
      }}
    >
      Alternate Number
    </Text>
    <TextInput
    maxLength={10}
    keyboardType="phone-pad"
      placeholderTextColor={"rgba(0, 0, 0, 0.30)"}
      placeholder="Alternate Number"
      defaultValue={profileData?.phone}
      onChangeText={(text) => {
       handleAlternateNumber(text)
      }}
      //onChangeText={handleAlternateNumber}
      style={{
        height: "55%" ,
        marginTop: 10,
        backgroundColor: Color.WHITE,
        alignItems: "center",
        justifyContent: "space-between",
        borderRadius: 10,
        borderWidth: 1,
        borderColor: "rgba(0, 0, 0, 0.14)",
        fontSize: isMobileView()
          ? responsiveFontSize(2.2)
          : responsiveFontSize(1.1),
        fontWeight: "600",

        padding: 10,
      }}
    />
    {alternateNumberError !== "" && (
      <Text
        style={{ color: "red", fontWeight: "500", height: 10 }}
      >
        {alternateNumberError}
      </Text>
    )}
</View>
  <View
    style={[{width:"100%"}]}
  >
    <Text
      style={{
        fontWeight: "600",
        fontSize: isMobileView()
          ? responsiveFontSize(2.2)
          : responsiveFontSize(1.1),
      }}
    >
      Email
    </Text>
    <TextInput
      placeholderTextColor={"rgba(0, 0, 0, 0.30)"}
      placeholder="Email"
      defaultValue={profileData?.email}
      onChangeText={(text) => {
        setEmail(text)

      }}
      style={{
        height: "55%" ,
        marginTop: 10,
        backgroundColor: Color.WHITE,
        alignItems: "center",
        justifyContent: "space-between",
        borderRadius: 10,
        borderWidth: 1,
        borderColor: "rgba(0, 0, 0, 0.14)",
        fontSize: isMobileView()
          ? responsiveFontSize(2.2)
          : responsiveFontSize(1.1),
        fontWeight: "600",

        padding: 10,
      }}
    />
    {emailError !== "" && (
      <Text
        style={{ color: "red", fontWeight: "500", height: 10 }}
      >
        {emailError}
      </Text>
    )}
  </View>
</View>
        </>
      ):
      (

      <View style={{flexDirection:"column", gap: 12,width:"100%",zIndex:99 , marginTop:  14}}>

                <View
                  style={{
                    width: "100%",
                    position:'relative',
                    borderWidth: 0,
                    zIndex:99,
                    flexDirection: isMobileView() ? " column" : "row",
                    gap: isMobileView() && 20,

                    justifyContent: "space-between",
                  }}
                >
                                    <View style={{             width: isMobileView() && isTabletView() ? "100%" : "22%",  }}>
                  <Text
                    style={{
                      fontWeight: "600",
                      fontSize: isMobileView()  ? responsiveFontSize(2.2) : responsiveFontSize(1.1),
                      margin: 0,
                    }}
                  >
                    Select Title<Text style={{color:'red'}} >*</Text>
                  </Text>
                   <SelectDropdown
     dropdownOverlayColor={"No"}
                      dropdownStyle={commonStyles.commanDropdownStyle}
                      selectedRowStyle={commonStyles.selectedRowStyle}
                      selectedRowTextStyle={commonStyles.selectedRowTextStyle}
                      rowStyle={commonStyles.commanRowStyle}
                      rowTextStyle={commonStyles.commonRowTextStyle}

                    buttonStyle={{
                      width: "100%",
                      // height: isMobileView() && isTabletView()  ? responsiveScreenHeight(6.5) : "50%",
                      marginTop:10,
                      backgroundColor: Color.WHITE,
                      alignItems: "center",
                      justifyContent: "space-between",
                      borderRadius: 10,
                      borderWidth: 1,
                      borderColor: "rgba(0, 0, 0, 0.14)",
                      padding:10
                    }}
                    buttonTextStyle={{
                      color: "rgba(0, 0, 0,)",
                      fontSize: isMobileView()  ? responsiveFontSize(2.2) :  responsiveFontSize(1.1),
                      textAlign: "left",
                      margin: 0,
                      marginVertical: 0,
                      fontWeight: "600",
                    }}
                    defaultValue={profileData?.title}
                    data={["MR.", "MS.", "MRS."]}
                    onSelect={(selectedItem, index) => {
                      setSelectedTitle(selectedItem)
                    }}

                    renderDropdownIcon={(props) => renderDropdownIcon(props)}
                  />

                    {titleError !== "" && (
                      <Text
                        style={{ color: "red", fontWeight: "500", height: 10 }}
                      >
                        {titleError}
                      </Text>
                    )}
                </View>
                  <View
                    style={{
                      width: isMobileView() && isTabletView() ? "100%" : "37%",
                    }}
                  >
                    <Text
                      style={{
                        fontWeight: "600",
                        fontSize: isMobileView()
                          ? responsiveFontSize(2.2)
                          : responsiveFontSize(1.1),
                      }}
                    >
                      Name<Text style={{color:'red'}} >*</Text>
                    </Text>
                    <TextInput
                      defaultValue={profileData?.member_name}
                      onChangeText={(text) => {
                        hanldeName(text)
                      }}
                      placeholderTextColor={"rgba(0, 0, 0, 0.30)"}
                      placeholder="Name"
                      style={{
                        height:
                          isMobileView() && isTabletView()
                            ? responsiveScreenHeight(6.5)
                            : "100%",
                        marginTop: 10,
                        backgroundColor: Color.WHITE,
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderRadius: 10,
                        borderWidth: 1,
                        borderColor: "rgba(0, 0, 0, 0.14)",
                        fontSize: isMobileView()
                          ? responsiveFontSize(2.2)
                          : responsiveFontSize(1.1),
                        fontWeight: "600",

                        padding: 10,
                      }}
                    />

                    {nameError !== "" && (
                      <Text
                        style={{ color: "red", fontWeight: "500", height: 10 }}
                      >
                        {nameError}
                      </Text>
                    )}
                  </View>

                  <View
                  ref={datePickerRef}
                    style={{
                      width: isMobileView() && isTabletView() ? "100%" : "37%",
                    }}
                  >
                    <Text
                      style={{
                        fontWeight: "600",
                        fontSize: isMobileView()
                          ? responsiveFontSize(2.2)
                          : responsiveFontSize(1.1),
                      }}
                    >
                      Date Of Birth
                    </Text>

                    <TouchableOpacity onPress={()=>{setDatePickerVisible(!isDatePickerVisible)}}>
                               <TextInput
                      placeholderTextColor={"rgba(0, 0, 0, 0.30)"}
                      placeholder="Date Of Birth"
                      //defaultValue={profileData?.dob}
                      value={ selectedDOBDate}
                      style={{
                        height:
                          isMobileView() && isTabletView()
                            ? responsiveScreenHeight(6.5)
                            : "100%",
                        marginTop: 10,
                        backgroundColor: Color.WHITE,
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderRadius: 10,
                        borderWidth: 1,
                        borderColor: "rgba(0, 0, 0, 0.14)",
                        fontSize: isMobileView()
                          ? responsiveFontSize(2.2)
                          : responsiveFontSize(1.1),
                        fontWeight: "600",

                        padding: 10,
                      }}></TextInput>
                      </TouchableOpacity>
                      {isDatePickerVisible && (

                        <View style={{position:'absolute',width:'100%',backgroundColor:'#F5FCFF'}}>
                           {/* <DatePicker
                           current={dobCalendar?dobCalendar:''}
                           mode="calendar"
                           onCancel={() => setDatePickerVisible(false)}
                          maximumDate={formattedCurrentDate}

                            onSelectedChange={(date) => {handleDateSelected(date);  setDatePickerVisible(false)} }
                          /> */}
                          <DateTimePicker
                            mode="single"
                            date={dobCalendar?dobCalendar:new Date()}
                            onChange={(params) => {handleDateSelected(params.date);  setDatePickerVisible(false)}}
                          />
                          </View>
                      )}

                    {/* {emailError !== "" && (
                      <Text
                        style={{ color: "red", fontWeight: "500", height: 10 }}
                      >
                        {emailError}
                      </Text>
                    )} */}
                  </View>

                </View>
                <View
                  style={[

                    {flexDirection: isMobileView() ? "column" : "row", alignItems: "flex-end",  justifyContent:"space-between", width: "100%", gap: 15 },
                  ]}
                >


                  <View style={{   width: isMobileView() && isTabletView() ? "100%" : "22%",}}>
                  <Text
                    style={{
                      fontWeight: "600",
                      fontSize: isMobileView()  ? responsiveFontSize(2.2) : responsiveFontSize(1.1),
                      margin: 0,
                    }}
                  >
                    Gender<Text style={{color:'red'}} >*</Text>
                  </Text>
                   <SelectDropdown
                      dropdownStyle={commonStyles.commanDropdownStyle}
                      selectedRowStyle={commonStyles.selectedRowStyle}
                      selectedRowTextStyle={commonStyles.selectedRowTextStyle}
                      rowStyle={commonStyles.commanRowStyle}
                      rowTextStyle={commonStyles.commonRowTextStyle}

                    buttonStyle={{
                      width: "100%",
                      height: isMobileView() && isTabletView()  && responsiveScreenHeight(6.5) ,
                      marginTop:10,
                      backgroundColor: Color.WHITE,
                      alignItems: "center",
                      justifyContent: "space-between",
                      borderRadius: 10,
                      borderWidth: 1,
                      borderColor: "rgba(0, 0, 0, 0.14)",
                      padding:10
                    }}
                    buttonTextStyle={{
                      color: "rgba(0, 0, 0,)",
                      fontSize: isMobileView()  ? responsiveFontSize(2.2) :  responsiveFontSize(1.1),
                      textAlign: "left",
                      margin: 0,
                      marginVertical: 0,
                      fontWeight: "600",
                    }}
                    defaultValue={profileData?.gender?.charAt(0).toUpperCase() + profileData?.gender?.slice(1)}
                    data={["Male", "Female", "Other"]}
                    onSelect={(selectedItem, index) => {
                      setSelectedGender(selectedItem)
                    }}

                    renderDropdownIcon={(props) => renderDropdownIcon(props)}
                  />

                    {genderError !== "" && (
                      <Text
                        style={{ color: "red", fontWeight: "500", height: 10 }}
                      >
                        {genderError}
                      </Text>
                    )}
                </View>
                <View style={{width: isMobileView() && isTabletView() ? "100%" : "37%",  }}>
                  <Text
                    style={{
                      fontWeight: "600",
                      fontSize: isMobileView()  ? responsiveFontSize(2.2) : responsiveFontSize(1.1),
                      margin: 0,
                    }}
                  >
                    Care Of Type<Text style={{color:'red'}} >*</Text>
                  </Text>
                   <SelectDropdown
     dropdownOverlayColor={"No"}
                      dropdownStyle={commonStyles.commanDropdownStyle}
                      selectedRowStyle={commonStyles.selectedRowStyle}
                      selectedRowTextStyle={commonStyles.selectedRowTextStyle}
                      rowStyle={commonStyles.commanRowStyle}
                      rowTextStyle={commonStyles.commonRowTextStyle}

                    buttonStyle={{
                      width: "100%",
                      height:
                      isMobileView() && isTabletView()
                        && responsiveScreenHeight(6.5)
                    ,
                      marginTop:10,
                      backgroundColor: Color.WHITE,
                      alignItems: "center",
                      justifyContent: "space-between",
                      borderRadius: 10,
                      borderWidth: 1,
                      borderColor: "rgba(0, 0, 0, 0.14)",
                      padding:10
                    }}
                    buttonTextStyle={{
                      color: "rgba(0, 0, 0,)",
                      fontSize: isMobileView()  ? responsiveFontSize(2.2) :  responsiveFontSize(1.1),
                      textAlign: "left",
                      margin: 0,
                      marginVertical: 0,
                      fontWeight: "600",
                    }}
                    defaultValue={profileData?.co_type}
                    data={["S/O", "D/O", "W/O","C/O"]}
                    onSelect={(selectedItem, index) => {
                      setSelectedCareOfType(selectedItem)
                    }}

                    renderDropdownIcon={(props) => renderDropdownIcon(props)}
                  />

                    {coTypeError !== "" && (
                      <Text
                        style={{ color: "red", fontWeight: "500", height: 10 }}
                      >
                        {coTypeError}
                      </Text>
                    )}
                </View>
                  <View
                    style={{
                      width: isMobileView() && isTabletView() ? "100%" : "37%",
                    }}
                  >
                    <Text
                      style={{
                        fontWeight: "600",
                        fontSize: isMobileView()
                          ? responsiveFontSize(2.2)
                          : responsiveFontSize(1.1),
                      }}
                    >
                      Care Of Name<Text style={{color:'red'}} >*</Text>
                    </Text>
                    <TextInput
                      placeholderTextColor={"rgba(0, 0, 0, 0.30)"}
                      placeholder="Care Of Name"
                      defaultValue={profileData?.co_name}
                      onChangeText={(text)=> handleCareOfName(text)}
                      style={{
                        height:
                          isMobileView() && isTabletView()
                          && responsiveScreenHeight(6.5)
                           ,
                        marginTop: 10,
                        backgroundColor: Color.WHITE,
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderRadius: 10,
                        borderWidth: 1,
                        borderColor: "rgba(0, 0, 0, 0.14)",
                        fontSize: isMobileView()
                          ? responsiveFontSize(2.2)
                          : responsiveFontSize(1.1),
                        fontWeight: "600",

                        padding: 10,
                      }}
                    />
                    {coNameError !== "" && (
                      <Text
                        style={{ color: "red", fontWeight: "500", height: 10 }}
                      >
                        {coNameError}
                      </Text>
                    )}
                  </View>


                </View>



                <View
                  style={{
                    width: "100%",

                    borderWidth: 0,

                    flexDirection: isMobileView() ? " column" : "row",
                    // gap:  20,
                    justifyContent: "space-between",
                  }}
                >
                                {isTooltipVisible && (
                    <View
                      style={{
                        position: "absolute",
                        left: "0%",
                        top: "-16%",
                        backgroundColor: Color.BLACK,
                        padding: 12,
                        zIndex:100,
                        borderRadius: 2,
                      }}
                    >
                      <Text
                        style={{
                          textAlign: "center",
                          fontSize: 14,
                          fontWeight: "600",
                          color: Color.WHITE,
                        }}
                      >
                        To update mobile number please raise a ticket
                      </Text>
                    </View>
                  )}
                  <View style={{    width: isMobileView() && isTabletView() ? "100%" : "3%", alignSelf:"flex-end"}}>

{!isMobileView() && (
<TouchableOpacity
  onPress={() => navigation.navigate("Helpdesk")}
  onMouseEnter={() => setIsTooltipVisible(true)}
  onMouseLeave={() => setIsTooltipVisible(false)}
>
  <Image
    source={require("../../assets/images/info.png")}
    style={{
      height: 25,
      width: 25,
      bottom: 10,
      resizeMode: "contain",
    }}
  ></Image>
</TouchableOpacity>
)}


</View>
<View
style={{

  width: isMobileView() && isTabletView() ? "100%" : "17%",
  alignSelf: "baseline",
  borderWidth: 0,
  opacity: 0.5,
}}
>
{isMobileView() && (
  <View
    style={[
      commonStyles.flexRow,
      commonStyles.justifyBetween,
      commonStyles.alignCenter,
    ]}
  >
    <Text
      style={{
        fontWeight: "600",
        fontSize: isMobileView()
          ? responsiveFontSize(2.2)
          : responsiveFontSize(1.1),
      }}
    >
      Mobile No.
    </Text>
    <TouchableOpacity
      onPress={() => navigation.navigate("Helpdesk")}
      onMouseEnter={() => setIsTooltipVisible(true)}
      onMouseLeave={() => setIsTooltipVisible(false)}
    >
      <Image
        source={require("../../assets/images/info.png")}
        style={{
          height: 25,
          width: 25,
          resizeMode: "contain",
        }}
      ></Image>
    </TouchableOpacity>
  </View>
)}
{!isMobileView() && (
  <Text
    style={{
      fontWeight: "600",
      fontSize: isMobileView()
        ? responsiveFontSize(2.2)
        : responsiveFontSize(1.1),
    }}
  >
    Mobile No.
  </Text>
)}

<TextInput
  disabled={true}
  defaultValue={profileData?.mobile}
  onChangeText={(text) => {
    setSelectedPhone(text);
  }}
  placeholderTextColor={"rgba(0, 0, 0, 0.30)"}
  placeholder="Mobile No."
  style={{
    height:
      isMobileView() && isTabletView()
        ? responsiveScreenHeight(6.5)
        : "100%",
    marginTop: 10,
    backgroundColor: Color.WHITE,
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: 10,
    borderWidth: 1,
    borderColor: "rgba(0, 0, 0, 0.14)",
    fontSize: isMobileView()
      ? responsiveFontSize(2.2)
      : 15.7,
    fontWeight: "600",

    padding: 10,
  }}
/>
</View>
<View
                  style={{
                    width: isMobileView() && isTabletView() ? "100%" : "37%",


                  }}
                >

                    <Text
                      style={{
                        fontWeight: "600",
                        fontSize: isMobileView()
                          ? responsiveFontSize(2.2)
                          : responsiveFontSize(1.1),
                      }}
                    >
                      Alternate Number
                    </Text>
                    <TextInput
                    maxLength={10}
                    keyboardType="phone-pad"
                      placeholderTextColor={"rgba(0, 0, 0, 0.30)"}
                      placeholder="Alternate Number"
                      defaultValue={profileData?.phone}
                      onChangeText={(text) => {
                       handleAlternateNumber(text)
                      }}
                      //onChangeText={handleAlternateNumber}
                      style={{
                        height:
                          isMobileView() && isTabletView()
                            ? responsiveScreenHeight(6.5)
                            : "100%",
                        marginTop: 10,
                        backgroundColor: Color.WHITE,
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderRadius: 10,
                        borderWidth: 1,
                        borderColor: "rgba(0, 0, 0, 0.14)",
                        fontSize: isMobileView()
                          ? responsiveFontSize(2.2)
                          : responsiveFontSize(1.1),
                        fontWeight: "600",

                        padding: 10,
                      }}
                    />
                    {alternateNumberError !== "" && (
                      <Text
                        style={{ color: "red", fontWeight: "500", height: 10 }}
                      >
                        {alternateNumberError}
                      </Text>
                    )}
                </View>
                  <View
                    style={{
                      width: isMobileView() && isTabletView() ? "100%" : "37%",
                    }}
                  >
                    <Text
                      style={{
                        fontWeight: "600",
                        fontSize: isMobileView()
                          ? responsiveFontSize(2.2)
                          : responsiveFontSize(1.1),
                      }}
                    >
                      Email
                    </Text>
                    <TextInput
                      placeholderTextColor={"rgba(0, 0, 0, 0.30)"}
                      placeholder="Email"
                      defaultValue={profileData?.email}
                      onChangeText={(text) => {
                        setEmail(text)

                      }}
                      style={{
                        height:
                          isMobileView() && isTabletView()
                            ? responsiveScreenHeight(6.5)
                            : "100%",
                        marginTop: 10,
                        backgroundColor: Color.WHITE,
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderRadius: 10,
                        borderWidth: 1,
                        borderColor: "rgba(0, 0, 0, 0.14)",
                        fontSize: isMobileView()
                          ? responsiveFontSize(2.2)
                          : responsiveFontSize(1.1),
                        fontWeight: "600",

                        padding: 10,
                      }}
                    />
                    {emailError !== "" && (
                      <Text
                        style={{ color: "red", fontWeight: "500", height: 10 }}
                      >
                        {emailError}
                      </Text>
                    )}
                  </View>






                </View>



            </View>
      )

          }
               </View>




                <View
                  style={{
                    width: "100%",
                    borderWidth: 0,
                    // height: isMobileView() ? "10%" : "15%",
                    justifyContent: "center",
                    marginTop:  15,
                  }}
                >
                  <Hoverable
                    style={{
                      height: 40,
                      alignItems: "flex-end",
                      justifyContent: isMobileView() && "flex-end",
                    }}
                  >
                    {({ hovered }) => (
                      <TouchableOpacity
                        onPress={() => {
                          editMemeberProfile();
                        }}
                        style={{
                          backgroundColor: hovered
                            ? Color.BLACK
                            : Color.BLUE_MEDIUM,
                          width: isMobileView() ? "100%" : "20%",
                          height: "100%",
                          borderRadius: 10,
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Text style={[{ color: "#FFF", fontWeight: "700",    }, commonStyles.formButtonTextMobile]}>
                          Update
                        </Text>
                      </TouchableOpacity>
                    )}
                  </Hoverable>
                </View>
              </View>

            </View>
            </TouchableWithoutFeedback>

          </Modal>
        </ScrollView>
      ) : (
        <SafeAreaView
          style={{
            // top:0,
            // left:0,
            // right:0,
            width: "100%",
            margin: "auto",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: Color.WHITE,
          }}
        >
          <ActivityIndicator size="large" />
          <Text style={{ fontSize: 15, fontWeight: "700", color: "#b4b4b4" }}>
            Please Wait ...
          </Text>
        </SafeAreaView>
      )}
<View style={{position:"absolute",width:"100%"}}>

{error.text2 !== '' &&  <PopupComponent
            hideFun={() => {
              dispatchError("")
              dispatchSuccess("")
            }}
            type={error.type}
            text1={error.text1}
            text2={error.text2}
          />}
</View>

    </>
  );
};

export default Profilepage;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#FFF",
    width: "100%",
    height: "100%",
    paddingBottom: isMobileView() ? "25%" : "10%",
  },
  headingText: {
    color: "rgba(255, 255, 255, 0.8)",
    fontWeight: "600",
    fontSize: responsiveScreenFontSize(1.1),
    fontFamily: "openSans",
  },
  subHeadingText: {
    color: "rgba(255, 255, 255, 1)",
    fontWeight: "600",
    fontSize: responsiveScreenFontSize(1.1),
    fontFamily: "openSans",
  },
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    alignSelf: "center",
    backdropFilter: "blur(20px)",
    backgroundColor: "rgba(0, 0, 0, 0.3)",
  },
  modalView: {
    borderWidth: 0,
    justifyContent: "space-between",
    flexDirection: "column",
    alignItems: "center",
    height: isMobileView() ? "60%" : isSmallLaptop() ? '80%' : "70%",
    width: isTabletView() ? "90%" : isSmallLaptop() ? '70%' : "55%",
    margin: isTabletView() ? 0 : 5,
    backgroundColor: "#f8f9fa",
    borderRadius: isTabletView() ? 10 : 15,
    padding: isTabletView() ? 20 : 25,
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
  buttonOpen: {
    backgroundColor: "#F194FF",
  },
  buttonClose: {
    backgroundColor: "#2196F3",
  },
  textStyle: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
  },
  modalText: {
    textAlign: "center",
    fontWeight: "600",
    fontSize:
      isMobileView() && isTabletView()
        ? responsiveFontSize(2.5)
        : responsiveFontSize(1.5),
    fontFamily: "openSans",
  },
  modalViewMobile: {
    borderWidth: 0,
    flexDirection: "column",
    alignItems: "center",
    width: isTabletView() ? "100%" : responsiveWidth(50),
    height: isTabletView() ? "100%" : responsiveHeight(90),
    overflowX: "hidden",
    margin: isTabletView() ? 0 : 5,
    backgroundColor: "#f8f9fa",
    borderRadius: isTabletView() ? 0 : 15,
    padding: isTabletView() ? 20 : 25,
    shadowColor: "none",

    shadowRadius: 4,
  },
});
