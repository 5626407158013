import { StyleSheet, Text, View, Modal } from "react-native";
import React, { useEffect, useState } from "react";
import {
  responsiveFontSize,
  responsiveHeight,
  responsiveScreenHeight,
  responsiveScreenWidth,
  responsiveWidth,
} from "react-native-responsive-dimensions";

import commonStyles from "../styles/styles";
import { TouchableOpacity } from "react-native";
import { Image } from "react-native";
import Hoverable from "react-native-hoverable";
import {
  Color,
  isLargeLaptop,
  isMobileView,
  isOnlyLaptopView,
  isOnlyTabletView,
  isSmallLaptop,
  isTabletView,
} from "../Common/Constants";
import { Cell, Row, Table, TableWrapper } from "react-native-table-component";
import { formatDates } from "../util/fomatedDate";
import * as Print from "expo-print";
// we need to design THIS HTML if we want it in mobile phones 
//** Do Not Delete this HTML JUST MODIFY THIS FOR MOBILE**
const html = `
<html>
  <head>
    <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=no" />
  </head>
  <body style="text-align: center;">
    <h1 style="font-size: 50px; font-family: Helvetica Neue; font-weight: normal;">
      Hello Expo!
    </h1>
    <img
      src="https://d30j33t1r58ioz.cloudfront.net/static/guides/sdk.png"
      style="width: 90vw;" />
  </body>
</html>
`;
const tableHead = [
  "Date",
  "Reciept no",
  "Mode",
  "Bank name ",
  "Amount",
  "Receipt Status",
  "Action",
];
const PrintScreen = ({ route }) => {
  const [routeData, setRouteData] = useState(null);

  const [selectedPrinter, setSelectedPrinter] = useState();
  const print = async () => {
    // On iOS/android prints the given html. On web prints the HTML from the current page.
    try {
        await Print.printAsync({
            html,
            printerUrl: selectedPrinter?.url, // iOS only
          });
          navigation.navigate("Quickview");

    } catch (error) {
        console.log(error)
        
    }
  };
  useEffect(() => {
    
    setRouteData(route.params.data);
  }, []);
  useEffect(() => {
    if(routeData){
        print()
    }
  }, [routeData]);
  const statusPill = (data, index) => {
    return (
      <View
        style={{
          margin: 6,
          marginLeft: 15,
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "row",
          backgroundColor: data === "confirmed" ? "#EAF9F1" : "#FEEEE9",
          width: "80%",
          padding: 5,
          borderRadius: 50,
        }}
      >
        <Text
          style={[
            styles.text,
            {
              color: data === "confirmed" ? "#2CC373" : "#F24D14",
              bottom: 0,
              position: "relative",
              margin: 0,
            },
          ]}
        >
          {data}
        </Text>
      </View>
    );
  };
  const viewButton = (data, index) => {
    return (
      <TouchableOpacity
        onPress={() => {
          print();
        }}
        style={{
          margin: 6,
          marginLeft: 15,

          cursor: "pointer",
          borderRadius: 50,
        }}
      >
        <Image
          source={require("../../assets/images/downloadFile.png")}
          style={{ width: 20, height: 30 }}
          resizeMode="cover"
        ></Image>
      </TouchableOpacity>
    );
  };
  return (
    <View>
      <View
        style={{
          borderBottomWidth: 1,
          borderColor: "rgba(0, 0, 0, 0.15)",
          justifyContent: "center",
          paddingVertical: 18,
          alignItems: "center",
          width: "100%",
        }}
      >
        <View
          style={{
            borderWidth: 0,
            flexDirection: isMobileView() ? "column" : "row",
            justifyContent: "space-between",
            alignItems: !isMobileView() && "center",
            width: isMobileView() ? "90%" : "96%",
          }}
        >
          <Text
            style={{
              fontWeight: "600",
              fontSize:
                (isMobileView() && responsiveFontSize(2.6)) ||
                responsiveFontSize(2.2),
            }}
          >
            Reciept
          </Text>

          <View
            style={[
              commonStyles.gap1rem,
              commonStyles.flexRow,
              commonStyles.flexWrap,
              isMobileView() && commonStyles.mt_16px,
            ]}
          >
        

          </View>
        </View>
      </View>
      <Table
        style={{ width: isMobileView() && "158%" }}
        borderStyle={{ borderWidth: 0, borderColor: "#c8e1ff" }}
      >
        <Row data={tableHead} style={styles.head} textStyle={styles.textHead} />
        <TableWrapper style={styles.tableRow}>
          <Cell
            data={formatDates(routeData?.mr_receiptdate)}
            textStyle={styles.text}
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: !isMobileView() && "flex-end",
            }}
          />
          <Cell
            data={routeData?.mr_receiptno}
            textStyle={styles.text}
            style={{ flex: 1, alignItems: !isMobileView() && "flex-end" }}
          />
          <Cell
            data={routeData?.mr_paymentmode}
            textStyle={styles.text}
            style={{ flex: 1 }}
          />
          <Cell
            data={routeData?.mr_bankname}
            textStyle={styles.text}
            style={{ flex: 1 }}
          />
          <Cell
            data={"₹" + routeData?.mr_amount}
            textStyle={styles.text}
            style={{ flex: 1 }}
          />
          <Cell
            data={statusPill(routeData?.mr_status)}
            textStyle={styles.text}
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "flex-start",
            }}
          />
          <Cell
            data={viewButton(routeData)}
            textStyle={styles.text}
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "flex-start",
            }}
          />
        </TableWrapper>
      </Table>
    </View>
  );
};

export default PrintScreen;

const styles = StyleSheet.create({
  cardImage: {
    width:
      (isMobileView() && responsiveScreenWidth(8)) ||
      (isTabletView() && !isMobileView() && responsiveScreenHeight(4)) ||
      (!isMobileView() && !isTabletView() && responsiveScreenWidth(2.2)),
    height:
      (isMobileView() && responsiveScreenWidth(8)) ||
      (isTabletView() && !isMobileView() && responsiveScreenHeight(4)) ||
      (!isMobileView() && !isTabletView() && responsiveScreenHeight(4)),
  },
  centeredView: {
    // flex: 1,
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    alignSelf: "center",
    backdropFilter: "blur(20px)",
    // backgroundColor: "rgba(0, 0, 0, 0.3)",
  },
  modalView: {
    flex: 1,
    borderWidth: 0,

    shadowColor: Color.BLACK,
    marginBottom: !isMobileView() && 45,
    flexDirection: "column",
    height: "70%",
    width: "100%",
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
  buttonOpen: {
    backgroundColor: "#F194FF",
  },
  buttonClose: {
    backgroundColor: "#2196F3",
  },
  textStyle: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
  },
  modalText: {
    marginBottom: 15,
    textAlign: "center",
    fontWeight: "700",
    fontSize: responsiveFontSize(1.8),
  },
  textboxInput: {
    borderWidth: 2,
    borderColor: "rgba(0, 0, 0, 0.14)",
    backgroundColor: "#FFF",
    height: "30%",
    width: "85%",
    borderRadius: 5,
    paddingLeft: 10,
  },

  magImage: {
    height: responsiveScreenHeight(13),
    width: responsiveScreenWidth(7),
  },
  magTitle: {
    width: "100%",
    fontFamily: "openSans",
    fontWeight: "600",
    fontSize: responsiveFontSize(1.4),
  },
  cardCointainer: {
    width: responsiveScreenWidth(22),
    height: responsiveScreenHeight(50),
    borderWidth: 2,
    borderColor: "rgba(234, 249, 241, 1)",
    borderRadius: 10,
    shadowOpacity: 0.1,
    margin: 20,
    backgroundColor: "#FFF",
    shadowOffset: { width: 1, height: 1 },
  },
  magImage: {
    height: responsiveScreenHeight(10),
    width: responsiveScreenWidth(6),
  },
  magTitle: {
    fontFamily: "openSans",
    fontWeight: "600",
    fontSize: responsiveFontSize(1.3),
    maxWidth: 150,
  },
  insideCardLeft: {
    backgroundColor: "#FFF",
    top: 15,
    borderwidth: 0,
    justifyContent: "space-between",
    width: responsiveScreenWidth(9),
    height: responsiveScreenHeight(16),
    borderRadius: 10,
    padding: 15,
    shadowColor: "rgba(0, 0, 0, 0.15)",
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 1,
    shadowRadius: 6,
    elevation: 2,
  },
  insideCardRight: {
    backgroundColor: "#FFF",
    top: 15,
    borderwidth: 0,
    justifyContent: "space-between",
    width: responsiveScreenWidth(9),
    height: responsiveScreenHeight(16),
    borderRadius: 10,
    padding: 15,
  },

  cardImageLoc: {
    width: responsiveScreenWidth(3),
    height: responsiveScreenHeight(5),
    margin: 5,
  },
  datesTime: {
    fontSize: responsiveFontSize(0.9),
    color: "rgba(0, 0, 0, 0.15)",
    fontWeight: "600",
  },
  locationDate: {
    flexDirection: "row",
    alignItems: "center",
    padding: 10,
    borderWidth: 0,
    gap: 10,
  },
  startEndDate: {
    fontSize: responsiveFontSize(1.25),
    color: "rgba(0, 0, 0)",
    fontWeight: "600",
    centeredView: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "100%",
      alignSelf: "center",
      backdropFilter: "blur(20px)",
      backgroundColor: "rgba(0, 0, 0, 0.3)",
    },
    modalView: {
      flex: 1,
      backgroundColor: "white",
      borderRadius: 20,

      alignItems: "center",
      shadowColor: Color.BLACK,
      marginTop: 35,
      marginBottom: 35,

      flexDirection: "row",
      height: "80%",
      width: "80%",
    },
    button: {
      borderRadius: 20,
      padding: 10,
      elevation: 2,
    },
    buttonOpen: {
      backgroundColor: "#F194FF",
    },
    buttonClose: {
      backgroundColor: "#2196F3",
    },
    textStyle: {
      color: "white",
      fontWeight: "bold",
      textAlign: "center",
    },
    modalText: {
      fontFamily: "openSans",
      fontWeight: "600",
      fontSize: responsiveFontSize(1.5),
    },
    textboxInput: {
      width: "80%",
      height: "50%",
      marginTop: 10,
      backgroundColor: Color.WHITE,
      alignItems: "center",
      justifyContent: "center",
      borderRadius: 10,
      borderWidth: 1,
      borderColor: "rgba(0, 0, 0, 0.14)",
      color: "rgba(0, 0, 0, 0.30)",
      fontSize: responsiveFontSize(1.1),
      textAlign: "left",
      padding: 10,

      fontWeight: "600",
    },
    cardCointainer: {
      width: "75%",
      height: "60%",
      borderWidth: 2,
      borderRadius: 20,
      shadowOpacity: 0.1,
      margin: 30,
      backgroundColor: "#FFF",
      shadowOffset: { width: 1, height: 1 },
    },
    magImage: {
      height: responsiveScreenHeight(13),
      width: responsiveScreenWidth(7),
    },
    magTitle: {
      width: "100%",
      fontFamily: "openSans",
      fontWeight: "600",
      fontSize: responsiveFontSize(1.4),
    },
    cardCointainer: {
      width: responsiveScreenWidth(20),
      height: responsiveScreenHeight(50),
      borderWidth: 2,
      borderColor: "rgba(234, 249, 241, 1)",
      borderRadius: 20,
      shadowOpacity: 0.1,
      margin: 30,
      backgroundColor: "#FFF",
      shadowOffset: { width: 1, height: 1 },
    },
    magImage: {
      height: responsiveScreenHeight(13),
      width: responsiveScreenWidth(7),
    },
    magTitle: {
      fontFamily: "openSans",
      fontWeight: "600",
      fontSize: responsiveFontSize(1.4),
    },
    insideCardLeft: {
      backgroundColor: "#FFF",
      top: 15,
      borderwidth: 0,
      width: responsiveScreenWidth(9),
      height: responsiveScreenHeight(16),
      borderRadius: 15,
      padding: 15,
      shadowOpacity: 0.1,
      shadowOffset: { width: 1, height: 1 },
    },
    insideCardRight: {
      backgroundColor: "#FFF",
      top: 15,
      borderwidth: 0,
      width: responsiveScreenWidth(9),
      height: responsiveScreenHeight(16),
      borderRadius: 15,
      padding: 15,
      shadowOpacity: 0.1,
      shadowOffset: { width: 1, height: 1 },
    },

    cardImageLoc: {
      width: responsiveScreenWidth(3),
      height: responsiveScreenHeight(5),
      margin: 5,
    },
    datesTime: {
      fontSize: responsiveFontSize(0.9),
      color: "rgba(0, 0, 0, 0.15)",
      fontWeight: "600",
    },
    locationDate: {
      flexDirection: "row",
      alignItems: "center",
      padding: 10,
      borderWidth: 0,
    },
    startEndDate: {
      fontSize: responsiveFontSize(1.25),
      color: "rgba(0, 0, 0)",
      fontWeight: "600",
    },
  },
  body: {},
  gradient: {
    borderRadius: 15,
    padding: 8,
    height: isTabletView() ? "100%" : responsiveHeight(68),
    borderColor: "transparent",
  },
  container: { flex: !isMobileView() && 1, backgroundColor: "#fff" },
  head: {
    height: !isMobileView() && responsiveHeight(8),
    backgroundColor: "#fff",
  },
  text: {
    margin: 6,
    marginLeft: 15,
    fontWeight: 600,
    fontSize: isMobileView()
      ? responsiveFontSize(1.9)
      : responsiveFontSize(1.1),
  },
  textHead: {
    margin: 6,
    marginLeft: 15,
    fontWeight: 500,
    fontSize: isMobileView() ? responsiveFontSize(2) : responsiveFontSize(1.1),
    color: "rgba(0, 0, 0, 0.60)",
  },
  tableRow: {
    borderWidth: 1,
    borderColor: "rgba(0, 0, 0, 0.15)",
    marginVertical: 8,
    borderRadius: 6,
    height: responsiveHeight(8.5),
    flexDirection: "row",
  },
});
