import 'react-native-gesture-handler';
import { Button, StyleSheet, Text, View, Dimensions, ActivityIndicator } from 'react-native';
import React, { useEffect, useState } from 'react'
import { NavigationContainer } from '@react-navigation/native';
import RootStackScreen from './src/Routes/RootStackScreen';
import { AuthContext } from './src/context/AuthContext';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { createStackNavigator } from '@react-navigation/stack';
import MemberStackScreen from './src/Routes/MemberStackScreen';
import Dashboard from './src/Routes/MainStackScreen';
import { ErrorProvider } from './src/context/ErrorContext';
import Payment_Success from './src/screens/Payment_Success';
import Payment_failure from './src/screens/Payment_failure';
import SNMPolicy from './src/screens/SNMPolicyPage';


const App = () => {
const [cartCount,setCartCount]  =useState(0)
  const flag = true
  const initiailLoginState = {
    isLoading: true,
    memberToken: null,
    authenticated: null,
    memberName: null,
    cartCount:null
  }

  const loginReducer = (prevState, action) => {
    switch (action.type) {
      case 'login':
        return {
          ...prevState,
          memberName: null,
          memberToken: null,
          isLoading: false,
          authenticated: action.token
        }
      case 'logout':
        return {
          ...prevState,
          memberName: null,
          memberToken: null,
          isLoading: false,
          authenticated: null
        }
      case 'retriveToken':
        return {
          ...prevState,
          memberName: null,
          memberToken: null,
          isLoading: false,
          authenticated: action.token

        }

      case 'updateToken':
        return {
          ...prevState,
          memberName: null,
          memberToken: action.token,
          isLoading: false,
        }

        case 'updateCartTotal':
          return {
          ...prevState,
          cartCount:action.token
        }
     
    }
  }

  const [loginState, dispatch] = React.useReducer(loginReducer, initiailLoginState)

  const authContext = React.useMemo(() => ({
    signIn: async (flag) => {
      try {
        await AsyncStorage.setItem('authenticationToken', flag)
      } catch (error) {

      }
      dispatch({
        type: 'login',
        token: flag
      })
    },
    signOut: async () => {
      try {
        await AsyncStorage.clear()

      } catch (error) {

      }
      dispatch({
        type: 'logout',
      })
    },
    updateMemberToken: async (token) => {
      try {
        await AsyncStorage.setItem('memberToken', token)
      } catch (error) {

      }
      dispatch({
        type: 'updateToken',
        token: token
      })
    },

    updateCartTotal: async (total) => {
      try {
        await AsyncStorage.setItem('cartTotal', total.toString());
      } catch (error) {
        // Handle error
      }
  
      dispatch({
        type: 'updateCartTotal',
        token: parseFloat(total),
      });
    },
  
    getCartTotal: async () => {
      const cartTotal = await AsyncStorage.getItem('cartTotal');
      return cartTotal ? parseFloat(cartTotal) : 0;
    },
    setMemeberCode: async (member_code) =>{
      // setMemeberCodeValue(member_code)
      try {
        await AsyncStorage.setItem('member_code' ,member_code)
      } catch (error) {

      }

    },
    setMembersList: async (memberList) =>{
      // setMemeberCodeValue(member_code)
      try {
        await AsyncStorage.setItem('members' ,JSON.stringify(memberList))
        
      } catch (error) {

      }
     

    },
    getMembersList: async() =>{
      const memberList = JSON.parse( await AsyncStorage.getItem('members'))
      return memberList
    },
    retriveMemberCode: ()=>{
      return memberCode;

    },

  }))

  useEffect(() => {
    setTimeout(async () => {
      let authenticated = null
      try {
        authenticated = await AsyncStorage.getItem('authenticationToken')
      } catch (error) {

      }
      dispatch({
        type: 'retriveToken',
        token: authenticated

      })
    }, 1000);



  }, [])

  const linking = {
    prefixes: [
      /* your linking prefixes */

    ],
    config: {
      // Memberlist:'Memberlist',
      // Profilepage:'Profilepage'

    },
  };

  if (loginState.isLoading) {
    return (
      <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>

        <ActivityIndicator size="large" />
        <Text style={{fontSize:15,fontWeight:'700',color:'#b4b4b4'}}>Please Wait ...</Text>
      </View>
    );
  }

  const FinalStack = createStackNavigator();

  const FinalRootStackScreen = ({ isLoggedIn }) => (
    <FinalStack.Navigator>
      {/* Conditionally render the login or main stack */}
      {isLoggedIn ? (
        <>
        <FinalStack.Screen
          screenOptions={{headerShown:false,}}
            name="Dashboard"
            component={Dashboard}
            options={({ navigation }) => ({
              headerShown: false,
            })}
          />
        <FinalStack.Screen
            screenOptions={{headerShown:false,}}
            name="member"
            component={MemberStackScreen}
            options={{ headerShown: false }}
          />
          
          
          <FinalStack.Screen
          screenOptions={{headerShown:false,}}
            name="PaymentSuccess"
            component={Payment_Success}
            options={({ navigation }) => ({
              headerShown: false,
            })}
          />
          <FinalStack.Screen
          screenOptions={{headerShown:false,}}
            name="PaymentFailure"
            component={Payment_failure}
            options={({ navigation }) => ({
              headerShown: false,
            })}
          />

        </>
      ) : (
        <FinalStack.Screen name="Auth" component={RootStackScreen} options={{ headerShown: false }} />
      )}
       <FinalStack.Screen
          screenOptions={{headerShown:false,}}
            name="SNMPolicy"
            component={SNMPolicy}
            options={({ navigation }) => ({
              headerShown: false,
            })}
          />
    </FinalStack.Navigator>

  )
  return (
    <ErrorProvider>

    <AuthContext.Provider value={authContext}>

      <NavigationContainer linking={linking}>
        {/* {loginState.authenticated != null ? (

          // <MainStackScreen />
          <MemberStackScreen/>
          
          
          ) :
          <RootStackScreen />
        } */}
      <FinalRootStackScreen isLoggedIn={loginState.authenticated != null ? true: false}/>
      {/* <Receiptpage/> */}
      </NavigationContainer>
    </AuthContext.Provider>
        </ErrorProvider>
    // <Helpdesk/>
  )
}

export default App
let ScreenHeight = Dimensions.get('window').height;
let ScreenWidth = Dimensions.get('window').width;

const styles = StyleSheet.create({
  container: {
    // flex: 1,
    height: ScreenHeight,
    width: ScreenWidth,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F5FCFF'
  },
  header: {
    padding: 20
  },
  title: {
    fontWeight: 'bold',
    fontSize: '1.5rem',
    marginVertical: '1em',
    textAlign: 'center'
  },
  text: {
    lineHeight: '1.5em',
    fontSize: '1.125rem',
    marginVertical: '1em',
    textAlign: 'center'
  },
  link: {
    color: '#1B95E0'
  },
  code: {
    // fontFamily: 'monospace, monospace'
  }
});
