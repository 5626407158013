import { Dimensions } from "react-native";
import { log } from "react-native-reanimated";

export const SIZE ={width:Dimensions.get('window').width, height: Dimensions.get('window').height}
export const isMobileView = () => {
    const screenWidth = Dimensions.get('window').width;
    // Change the threshold (600) according to your desired breakpoint for mobile view
    return screenWidth <= 600;
  };
export const isSmallMobile = () => {
    const screenWidth = Dimensions.get('window').height;
    // Change the threshold (600) according to your desired breakpoint for mobile view

    return screenWidth <= 800;
  };
export const isTabletView = () => {
    const screenWidth = Dimensions.get('window').width;
    // Change the threshold (600) according to your desired breakpoint for mobile view
    return screenWidth <= 1000;
  };
export const isOnlyTabletView = () => {
    const screenWidth = Dimensions.get('window').width;
    // Change the threshold (600) according to your desired breakpoint for mobile view
    return screenWidth <= 1000 && screenWidth >= 600;
  };
export const isSmallLaptop = () => {
    const screenWidth = Dimensions.get('window').width;
    // Change the threshold (600) according to your desired breakpoint for mobile view
    return screenWidth <= 1200 && screenWidth >= 1000;
  };
export const isOnlyLaptopView = () => {
    const screenWidth = Dimensions.get('window').width;
    // Change the threshold (600) according to your desired breakpoint for mobile view
    return  screenWidth >= 1000;

}
export const isLargeLaptop = () => {
    const screenWidth = Dimensions.get('window').width;
    // Change the threshold (600) according to your desired breakpoint for mobile view
    return  screenWidth >= 1200;

}
export const isOnlyLargeLaptop = () => {
    const screenWidth = Dimensions.get('window').width;
    // Change the threshold (600) according to your desired breakpoint for mobile view
    return  screenWidth >= 1200 && screenWidth <= 1400;

}
export const isExtraLargeLaptop = () => {
    const screenWidth = Dimensions.get('window').width;
    // Change the threshold (600) according to your desired breakpoint for mobile view
    return  screenWidth >= 1400;

}
/* fonts */
export const FontFamily = {
  ralewaySemiBold: "Raleway-SemiBold",
  interRegular: "Inter-Regular",
};
/* font sizes */
export const FontSize = {
  size_smi: 13,
  size_2xs: 11,
};
/* Colors */
export const Color = {
  darkslateblue: "#172c54",
  BLACK: '#000000',
  GREEN: '#0bcd2a',
  BLACK15: 'rgba(0, 0, 0, 0.15)',
  BLACK50: 'rgba(0, 0, 0, 0.5)',
	WHITE: '#FFFFFF',
	BLUE_DARK: '#204A64',
	BLUE_LIGHT  : 'rgba(11, 144, 223, 0.15)',
	BLUE_MEDIUM: '#0B90DF',
	GREY_DARK: '#1A1A1A',
	GREY_MEDIUM: '#363636',
	GREY_BRIGHT: '#BBBCBC',
	GREY_LIGHT: '#FAFAFA',
  RED:"#F24D14",
};


/* Paddings */
export const Padding = {
  p_3xs: 10,
  p_4xs: 9,
  p_2xs: 11,
  p_8xs: 5,
  p_12xs_8: 1,
};
