import { createStackNavigator } from '@react-navigation/stack';
import LoginPage from '../screens/auth/Loginpage';

const RootStack = createStackNavigator();


const RootStackScreen = ({navigation}) => {
  return (
    <RootStack.Navigator screenOptions={{headerShown:false}}>
      <RootStack.Screen  name="LoginPage" component={LoginPage} />
      {/* <Stack.Screen name="Profile" component={Profile} />
      <Stack.Screen name="Settings" component={Settings} /> */} 
    </RootStack.Navigator>
  );
}
export default RootStackScreen