import React, { useEffect, useState, useRef, useContext } from "react";
import {
  StyleSheet,
  Text,
  View,
  Image,
  TextInput,
  FlatList,
  TouchableOpacity,
  ScrollView,
  SafeAreaView,
  ActivityIndicator,
  Modal,
  Linking
} from "react-native";
import Headerdiv from "../Common/Headerdiv";
import {
  responsiveHeight,
  responsiveWidth,
  responsiveFontSize,
} from "react-native-responsive-dimensions";
import AppLoading from "expo-app-loading";
import { useFonts } from "expo-font";
import MagzineCards from "../Common/MagzineCards";
import MagzineFlatCard from "../Common/MagzineFlatCard";
import { apiServices } from "../services/apiServices";
import AsyncStorage from "@react-native-async-storage/async-storage";
import ErrorContext from "../context/ErrorContext";
import PopupComponent from "../Common/PopupComponent";
import commonStyles from "../styles/styles";
import Hoverable from "react-native-hoverable";
import DataNotFound from "../Common/DataNotFound";
import { Color, isMobileView, isOnlyLaptopView, isTabletView } from "../Common/Constants";
import BuyMoreCard from "../Common/BuyMoreCard";
import { is } from "date-fns/locale";
import { AuthContext } from "../context/AuthContext";
import { StackActions } from "@react-navigation/native";
import { checkUserName } from "../Common/CheckUserFunc";

//import * as OpenAnything from 'react-native-openanything';
var magazineData;
var subscribedMagazinesData;
let superMember;
let flag = false;
const Magzinepage = ({ route, navigation }) => {
  // const { memberCode } = route.params;
  const { signOut } = useContext(AuthContext); // Assuming you have a signOut function in your AuthContext

  const [memberCode,setMemberCode] =useState(null)
  const [memberSuperCode,setMemberSuperCode] =useState(null)
  const [SubscriptionsData,setsubscriptionsData] =useState([])
  const [isclicked, setIsClicked] = useState(false);
  const [comingSoon, setComingSoon] = useState(false);
  const [patrikaSahayak, setPatrikaSahayak] = useState();

  const handleToggleImage = () => {
    setIsClicked(!isclicked);
  };
  const [selectedButton, setSelectedButton] = useState(0);

  const handleButtonPress = (buttonIndex) => {
    setSelectedButton(buttonIndex);
  };
  const getMemberCodeX = async() =>{
    let getMemberCode
    try {
        getMemberCode = await AsyncStorage.getItem('member_code')

        superMember = await AsyncStorage.getItem('super_member_code')
        setMemberCode(getMemberCode)
        setMemberSuperCode(superMember)
         setLoader(prev => {
            return prev !== 0 ? prev - 1 : 0;
          })
      } catch (error) {
        dispatchError(error);
        setLoader(prev => {
            return prev !== 0 ? prev - 1 : 0;
          })
      }
    }
  const checkPatrikasahayak = async() =>{

    let data=  JSON.parse(await AsyncStorage.getItem('patrika_sahayak'))

        setPatrikaSahayak(data)

    }
    useEffect(()=>{
      getMemberCodeX()
      checkPatrikasahayak()
      checkUserName(navigation)

      return(()=>{
        dispatchError('')
      })



  },[])
    useEffect(()=>{
      if(patrikaSahayak){


      }

  },[patrikaSahayak])

  const chnageDataMember = async() =>{
    let superMemberCode =  await AsyncStorage.getItem('super_member_code')
    let superMemberToken = await AsyncStorage.getItem('super_member_token')
    await AsyncStorage.setItem('member_code' ,superMemberCode)
    await AsyncStorage.setItem('memberToken' ,superMemberToken)
    navigation.dispatch(StackActions.replace('Dashboard'));

  }



  const { viewOtherMagzines, viewSubscribedMagazines,getAddress,viewProfile,getSubscriptionType} = apiServices;
  const [viewMagazineData, setViewMagazineData] = React.useState([]);
  const [subscribedMagazines, setSubscribedMagazines] = React.useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [itemHeight, setItemHeight] = useState(0);
  const [prevImage, setPrevImage] = useState(require('../../assets/images/prev.png'))
  const [memberAddress, setMemberAddress] = useState({})
  const [profileData, setProfileData] = useState("");
  const [loader, setLoader] = useState(5)
  const {error, dispatchError,dispatchSuccess} = useContext(ErrorContext);

  let targetIndex
  let flatRef = useRef(null);





  const getAddressMember = async () => {
    try {
      var memberAddressDataGet = await getAddress(memberCode);
      if(typeof(memberAddressDataGet)=='object'){
      setMemberAddress(memberAddressDataGet);
      }
      setLoader(prev => {
        return prev !== 0 ? prev - 1 : 0;
      })
    } catch (error) {
      setLoader(prev => {
        return prev !== 0 ? prev - 1 : 0;
      })
       dispatchError(error);

    }
  };
  const getSubscriptionTypeData = async () => {
    try {
      var subscriptionData = await getSubscriptionType(memberCode);
      if(typeof(subscribedMagazinesData)=='object'){
      setsubscriptionsData(subscriptionData);
      }
      setLoader(prev => {
        return prev !== 0 ? prev - 1 : 0;
      })

    } catch (error) {
      setLoader(prev => {
        return prev !== 0 ? prev - 1 : 0;
      })
       dispatchError(error);


    }
  };





  const getMagazine = async () => {
    try {
      magazineData = await viewOtherMagzines(memberCode);
      subscribedMagazinesData = await viewSubscribedMagazines(memberCode);
      setViewMagazineData(magazineData);
      setSubscribedMagazines(subscribedMagazinesData);
       setLoader(prev => {
            return prev !== 0 ? prev - 1 : 0;
          })



    } catch (error) {
      setLoader(prev => {
            return prev !== 0 ? prev - 1 : 0;
          })
      dispatchError(error);
    }
  };

  const handleLayout = (event) => {
    const { height } = event.nativeEvent.layout;
    setItemHeight(height);
  };

  const scrollToIndex = (step) => {
    if (flatRef.current) {
      if (currentIndex + step == 0) {
        flag = !flag;
      }

      if (
        currentIndex + step >= 0 &&
        currentIndex + step <= viewMagazineData.length
      ) {
        targetIndex = currentIndex + step;
        flatRef.current.scrollToIndex({ index: targetIndex, animated: true });
        setCurrentIndex(targetIndex);
      }
    }
  };

  const refreshpage = async ()=>{
    try {
      subscribedMagazinesData = await viewSubscribedMagazines(memberCode);
      setSubscribedMagazines(subscribedMagazinesData);


    } catch (error) {
      dispatchError(error);
    }

  }
  const renderItem = ({ item }) => {
    return (
      <View onLayout={handleLayout}>
        <MagzineCards data={item} refreshpage={refreshpage} memberCode={memberCode} memberAddress={memberAddress} profileData={profileData} SubscriptionsData={SubscriptionsData} navigation={navigation}  />

      </View>
    );
  };
  const renderItemFlat = ({ item }) => {
    return <MagzineFlatCard data={item} memberCode={memberCode} navigation={navigation} memberAddress={memberAddress} profileData={profileData} SubscriptionsData={SubscriptionsData}/>;
  };

  const getMemberCode = async () => {
    try {

      let viewMember = await viewProfile(memberCode);
      setProfileData(viewMember);
      setLoader(prev => {
        return prev !== 0 ? prev - 1 : 0;
      })


    } catch (error) {
      setLoader(prev => {
        return prev !== 0 ? prev - 1 : 0;
      })
      dispatchError(error);

    }
  };
const getPrivacyPolicy = ()=>{
  window.open(`https://www.nirankari.org/privacy-policy/`);
}

  useEffect(() => {
    setCurrentIndex(0);
    return(()=>{
      dispatchError('')
    })
  }, [flag]);

  useEffect(() => {

    // Log the previous screen's name
    if (memberCode) {
      getMagazine();
      getMemberCode();
      getAddressMember();
      getSubscriptionTypeData()

    }
    return(()=>{
      dispatchError('')
    })
  }, [memberCode]);

  let [fontsLoaded] = useFonts({
    openSans: require("../../assets/fonts/OpenSans-VariableFont_wdth,wght.ttf"),
  });
  if (!fontsLoaded) {
    return <AppLoading />;
  }
  return (
   <>
    {
      loader===0?(

    <SafeAreaView style={{width:'100%',height:"100%",}}>


    <ScrollView  showsVerticalScrollIndicator={false} contentContainerStyle={{ width: '100%' }} style={[styles.container,{zIndex:16}]}>

      {/* Main Area */}
      <View  >

      <Headerdiv navigation={navigation}  memberCode={memberCode}/>

      <View style={styles.membersSearchArea}>
        <View style={[styles.membersSearchAreaContainer]}>
        <View style={{flexDirection:'row',borderWidth:0,width:200,justifyContent: !isTabletView() && 'space-between',alignItems:'center', gap: isTabletView() && 16}}>
          <Text
            style={{ fontWeight: "600", fontSize: isTabletView() ? responsiveFontSize(2.6) :  responsiveFontSize(2.2) }}
            >
            Magazines
          </Text>
        </View>
        <View style={[ commonStyles.gap1rem,commonStyles.flexRow, !isTabletView() &&  commonStyles.justifyBetween,commonStyles.flexWrap, isTabletView() &&  commonStyles.justifyCenter, isTabletView() && commonStyles.mt_16px]}>
        <Hoverable

        style={[isTabletView() && commonStyles.aspectRatio1, isMobileView() && {width:"100%"}]}
        >
{({ hovered }) => (
        <TouchableOpacity
          onPress={() => navigation.navigate("SelectionMagazine")}
          // onPress={() => navigation.navigate("member", { screen:'SelectionMagazine'})}
          style={{
            justifyContent: isMobileView() ? "center" : "space-evenly",
            alignItems: "center",
            gap: isMobileView() && 12,
            flexDirection: "row",
            borderWidth: 2,
            borderRadius: 10,
            borderColor: Color.BLUE_MEDIUM,
            height: 40,
            width: isMobileView() ? "100%" : 250,
            backgroundColor: hovered ? Color.BLUE_MEDIUM : "white",
          }}
        >
          <Image
            style={{
              height: 25,
              width: 25,
              resizeMode: "contain",
              borderWidth: 0,
            }}
            source={ hovered ? require("../../assets/images/whiteAdd.png") : require("../../assets/images/add.png")}
          />
          <Text
            style={{
              fontFamily: "openSans",
              fontWeight: "600",
              fontSize: 15,
              color:  hovered ?  "white" : Color.BLUE_MEDIUM,
            }}
          >
            New Subscription/Bulk Order
          </Text>
        </TouchableOpacity>
          )}
          </Hoverable>
          <View style={[ isMobileView() && commonStyles.Col2GridParent, isOnlyLaptopView() && {flexDirection:"row",gap:16} ]} >

        <Hoverable
         style={[isTabletView() && commonStyles.aspectRatio1, isMobileView() && commonStyles.Col2GridItem ]}
        >
{({ hovered }) => (
        <TouchableOpacity
        onPress={() => navigation.navigate("member", { screen:'BulkPurchase'})}
          style={{
            justifyContent: "space-evenly",
            alignItems: "center",
            flexDirection: "row",
            borderRadius: 10,
            borderColor: Color.BLUE_MEDIUM,
            height: 40,
            width: isMobileView() ? "100%" : 160,
            backgroundColor: hovered ? Color.BLACK  : Color.BLUE_MEDIUM,
          }}
        >
          <Image
            style={{
              height: 25,
              width: 25,
              resizeMode: "contain",
              borderWidth: 0,
            }}
            source={ require("../../assets/images/multiAdd.png") }
          />
          <Text
            style={{
              fontFamily: "openSans",
              fontWeight: "600",
              fontSize: 15,
              color:    "white" ,
            }}
          >
             My Bulk Orders
          </Text>
        </TouchableOpacity>
          )}
          </Hoverable>
        {     patrikaSahayak
 &&(
        <> {parseInt(memberCode) !== parseInt(memberSuperCode)?
          (<Hoverable
          style={[isTabletView() && commonStyles.aspectRatio1,isMobileView() && commonStyles.Col2GridItem ]}
         >
   {({ hovered }) => (
         <TouchableOpacity
           onPress={() =>{chnageDataMember()}}
           style={{
             justifyContent: "space-evenly",
             alignItems: "center",
             flexDirection: "row",
             borderRadius: 10,
             borderColor: Color.BLUE_MEDIUM,
             height: 40,
             width: isMobileView() ? "100%" : 160,
             backgroundColor: hovered ? Color.BLACK : Color.BLUE_MEDIUM,
           }}
         >
           <Image
             style={{
               height: 25,
               width: 25,
               resizeMode: "contain",
               borderWidth: 0,
             }}
             source={  require("../../assets/images/whiteBook.png") }
           />
           <Text
             style={{
               fontFamily: "openSans",
               fontWeight: "600",
               fontSize: 15,
               color:    "white" ,
             }}
           >
             Go to Profile
           </Text>
         </TouchableOpacity>
           )}
           </Hoverable>):
          (          <Hoverable
          style={[isTabletView() && commonStyles.aspectRatio1, isMobileView() && commonStyles.Col2GridItem]}
         >
 {({ hovered }) => (
         <TouchableOpacity
           onPress={() =>navigation.navigate('member', {screen:'PatrikaSahayak'})}
           style={{
             justifyContent: "space-evenly",
             alignItems: "center",
             flexDirection: "row",
             borderRadius: 10,
             borderColor: Color.BLUE_MEDIUM,
             height: 40,
             width: isMobileView() ? "100%" : 160,
             backgroundColor: hovered ? Color.BLACK : Color.BLUE_MEDIUM,
           }}
         >
           <Image
             style={{
               height: 25,
               width: 25,
               resizeMode: "contain",
               borderWidth: 0,
             }}
             source={  require("../../assets/images/whiteBook.png") }
           />
           <Text
             style={{
               fontFamily: "openSans",
               fontWeight: "600",
               fontSize: 15,
               color:    "white" ,
             }}
           >
             Patrika Sahayak
           </Text>
         </TouchableOpacity>
           )}
           </Hoverable>)}

        <Hoverable
         style={[isTabletView() && commonStyles.aspectRatio1 , isMobileView() && commonStyles.Col2GridItem ]}
        >
{({ hovered }) => (
        <TouchableOpacity
        onPress={() =>navigation.navigate('member', {screen:'UnpaidInvoices'})}

          style={{
            justifyContent: "space-evenly",
            alignItems: "center",
            flexDirection: "row",
            borderRadius: 10,
            borderColor: Color.BLUE_MEDIUM,
            height: 40,
            width: isMobileView() ? "100%" : 160,
            backgroundColor: hovered ? Color.BLACK : Color.BLUE_MEDIUM,
          }}
        >
          <Image
            style={{
              height: 25,
              width: 25,
              resizeMode: "contain",
              borderWidth: 0,
            }}
            source={  require("../../assets/images/whiteBook.png") }
          />
          <Text
            style={{
              fontFamily: "openSans",
              fontWeight: "600",
              fontSize: 15,
              color:    "white" ,
            }}
          >
            Unpaid Invoices
          </Text>
        </TouchableOpacity>
          )}
          </Hoverable>
        </>)}


        <Hoverable
         style={[isTabletView() && commonStyles.aspectRatio1]}
        >
{({ hovered }) => (
        <TouchableOpacity
        onPress={() => navigation.navigate("member", { screen:'DispatchOrders'})}
          style={{
            justifyContent: "space-evenly",
            alignItems: "center",
            flexDirection: "row",
            borderRadius: 10,
            borderColor: Color.BLUE_MEDIUM,
            height: 40,
            width: 160,
            backgroundColor: hovered ? Color.BLACK  : Color.BLUE_MEDIUM,
          }}
        >
          <Image
            style={{
              height: 25,
              width: 25,
              resizeMode: "contain",
              borderWidth: 0,
            }}
            source={ require("../../assets/images/multiAdd.png") }
          />
          <Text
            style={{
              fontFamily: "openSans",
              fontWeight: "600",
              fontSize: 15,
              color:    "white" ,
            }}
          >
             Track My Orders
          </Text>
        </TouchableOpacity>
          )}
          </Hoverable>



          {/* <Hoverable>
{({ hovered }) => (
        <TouchableOpacity
          style={{
            justifyContent: "space-evenly",
            alignItems: "center",
            flexDirection: "row",
            borderWidth: 2,
            borderRadius: 10,
            borderColor: Color.BLUE_MEDIUM,
            height: 40,
            width: 120,
            backgroundColor: hovered ? Color.BLUE_MEDIUM : "white",
          }}
          onPress={() => navigation.navigate("member", { screen:'Memberlist'})}>
          <Image
            style={{       height: 25,
              width: 25,
              resizeMode: "contain",
              borderWidth: 0,
              transform: [{ rotate: "180deg" }]
            }}
            source={ hovered ? require("../../assets/images/goBackWhite.png") : require("../../assets/images/goBack.png")}
          />
          <Text
            style={{
              fontFamily: "openSans",
              fontWeight: "600",
              fontSize: 15,
              color:  hovered ?  "white" : Color.BLUE_MEDIUM,
            }}
          >
            Go Back
          </Text>
        </TouchableOpacity>
             )}
             </Hoverable> */}
        <Hoverable
         style={[isTabletView() && commonStyles.aspectRatio1,isMobileView() && commonStyles.Col2GridItem]}
        >
{({ hovered }) => (
        <TouchableOpacity
          onPress={() => navigation.navigate('SignOutScreen')

          }
          style={{
            justifyContent: !isTabletView() &&  "space-evenly",
            gap: isTabletView() &&  10,
            paddingLeft: isTabletView() &&  15,

            alignItems: "center",
            flexDirection: "row",
            borderRadius: 10,
            borderColor: Color.BLUE_MEDIUM,
            height: 40,
            width: isTabletView() ? "100%" : 110,
            backgroundColor: hovered ? Color.BLACK : Color.BLUE_MEDIUM,
          }}
        >
          <Image
            style={{
              height: 25,
              width: 25,
              resizeMode: "contain",
              borderWidth: 0,
            }}
            source={  require("../../assets/images/signOutWhite.png") }
          />
          <Text
            style={{
              fontFamily: "openSans",
              fontWeight: "600",
              fontSize: 15,
              color:    "white" ,
            }}
          >
            Logout
          </Text>
        </TouchableOpacity>
          )}
          </Hoverable>
          </View>

        </View>
        </View>
      </View>
      <View style={styles.memberContainer}>
        <View style={[styles.subscribedNextPrev, !isMobileView() && commonStyles.pl_2, isMobileView() && commonStyles.width90, isMobileView() && commonStyles.alignSelfCenter        ]}>
          <View style={[commonStyles.flexRow,commonStyles.flex,commonStyles.gap1rem,commonStyles.alignCenter]}>

          <Text style={{ fontWeight: "400", fontSize:   !isMobileView() ?    responsiveFontSize(2) :  responsiveFontSize(2.4) }}>
            Subscribed
          </Text>
          <View style={{backgroundColor:'rgba(230, 232, 234, 1)',paddingVertical:8,paddingHorizontal: !isMobileView() ? 20 : 14,borderRadius:10}}>

<Text   style={{
                        fontWeight: "500",
                        fontSize: !isMobileView() ?  responsiveFontSize(1) : responsiveFontSize(1.8),
                      }} >{subscribedMagazines.length} </Text>
</View>
          </View>
         {!isMobileView() && <View style={{ flexDirection: "row" }}>

         <TouchableOpacity  onPress={()=>{ window.open('/SNMPolicy', '_blank')} }>
             <Text style={{fontFamily:'openSans',color:  Color.BLUE_MEDIUM,fontWeight:'600',fontSize:16,textAlign:"right"}}>Privacy Policy</Text>

             </TouchableOpacity>

            <TouchableOpacity onPress={() => scrollToIndex(-4)}>
              <Image
                style={{
                  // transform:[{rotate:'90deg'}],
                  height: responsiveHeight(2),
                  width: responsiveWidth(1.3),
                  borderWidth: 0,
                  marginHorizontal: 5,
                  borderRadius: 100,
                  padding: 15,
                  resizeMode:'contain',

                }}
                source={currentIndex==0? require('../../assets/images/prev.png') : require('../../assets/images/previousActive.png') }
              />
            </TouchableOpacity>
            <TouchableOpacity onPress={() => scrollToIndex(4)}>
              <Image
                style={{
                  height: responsiveHeight(2),
                  width: responsiveWidth(1.3),
                  borderWidth: 0,
                  marginHorizontal: 5,
                  borderRadius: 100,
                  padding: 15,
                  resizeMode:'contain',
                  backgroundColor: "rgba(248, 249, 250, 1)",
                }}
                source={require( "../../assets/images/next.png" )}
              />
            </TouchableOpacity>
          </View>}
        </View>

        <View style={[{ backgroundColor: !isMobileView() && Color.BLUE_MEDIUM,borderRadius:30,padding:10, paddingHorizontal: isMobileView() ? 20 : 15 ,width:'100%',height: isMobileView() && "100%" || isTabletView() && "59%" || !isMobileView() && !isTabletView() && "70%", marginBottom: isMobileView() && 20 }, !isMobileView() && commonStyles.ml_2]}>
          {/* <MagzineCards/> */}
          {subscribedMagazines.length > 0 ? (
            <>
            <FlatList
            keyExtractor={(item, index) => item.magazine_id + '_' + index}
            ref={flatRef}
            showsHorizontalScrollIndicator={false}
            horizontal={!isMobileView()}
            scrollEnabled={true}
            data={subscribedMagazines}
            renderItem={renderItem}
            style={{ borderWidth: 0,paddingRight: !isMobileView() && 40, }}
            getItemLayout={(_, index) => ({
              length: itemHeight,
              offset: itemHeight * index,
              index,
            })}
            numColumns={ 1}
            // columnWrapperStyle={
            //  isMobileView() ? { justifyContent: 'space-between' } : null
            // }

            ListFooterComponent={<BuyMoreCard navigation={navigation} />}
          />
</>

          ) : (
            <DataNotFound height={35} width={35} fontSize={1.50} text={"Subscribed Magazines Not Found"} textColor={Color.WHITE}  />
        )}
        </View>
        {/* <View
          style={{
            flexDirection: "row",
            marginLeft: 80,
            marginVertical: 50,
            marginRight:40,
            alignItems: "center",
            justifyContent: "space-between",
            zIndex:2
          }}
        >
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Text
              style={{ fontWeight: "400", fontSize: responsiveFontSize(2) }}
            >
              Other Magazines
            </Text>

          <TouchableOpacity onPress={handleToggleImage} style={[{paddingVertical:10,paddingHorizontal:10,marginLeft:15,borderRadius:100},]}>
            <Image
              style={{
                height: responsiveHeight(3),
                width: responsiveWidth(1.5),
                borderWidth: 0,

              }}
              resizeMethod="contain"
              source={require("../../assets/images/filter.png")}
            />
                      </TouchableOpacity>

{isclicked && (
     <View style={styles.modalButtonContainer}>
     <View
                style={styles.modalButton}
                // onPress={handleDelete}
              >
                <TouchableOpacity >
                <Text style={{fontWeight:500 , color:Color.BLUE_MEDIUM,fontSize:16}} >Price Low to High</Text>
                </TouchableOpacity>
                <TouchableOpacity>
                <Text style={{fontWeight:500 , color:" rgba(0, 0, 0, 0.71)",fontSize:16}}  >Price Hight to Low</Text>
                </TouchableOpacity>
              </View>
     </View>
      )}
          </View>

          <View
            style={{
              flexDirection: "row",
              gap: 10,
            }}
          >
                  <Hoverable>
{({ hovered }) => (
                  <TouchableOpacity
        style={[
          styles.button,
          selectedButton === 0 && styles.selectedButton,
        ]}
        onPress={() => handleButtonPress(0)}
      >
        <Text style={[styles.languageButton, selectedButton === 0 || hovered ?  styles.activeLanguageButton : null]}>All</Text>
      </TouchableOpacity>
  )}
  </Hoverable>

  <Hoverable>
{({ hovered }) => (
      <TouchableOpacity
        style={[
          styles.button,
          selectedButton === 1 && styles.selectedButton,
        ]}
        onPress={() => handleButtonPress(1)}
      >
        <Text style={[styles.languageButton, selectedButton === 1 || hovered ?  styles.activeLanguageButton : null]}>Hindi</Text>
      </TouchableOpacity>
 )}
 </Hoverable>
<Hoverable>
{({ hovered }) => (
      <TouchableOpacity
        style={[
          styles.button,
          selectedButton === 2 && styles.selectedButton,
        ]}
        onPress={() => handleButtonPress(2)}
      >
        <Text style={[styles.languageButton, selectedButton === 2 || hovered ?  styles.activeLanguageButton : null]}>English</Text>
      </TouchableOpacity>
   )}
   </Hoverable>

          </View>
        </View>
        <View style={{ width: "93%", borderWidth: 0, alignSelf: "center",marginLeft:32 }}>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              marginHorizontal: 50,
              borderWidth:0,
              alignItems:'center',
              justifyContent:'center'
            }}
          >
            <View style={{flex:1,width:'100%'}}>
            <Text
              style={{
                fontFamily: "openSans",
                fontSize: 20,
                fontWeight: "400",
                borderWidth:0,
                flex:1,
                // justifyContent:'center',
                // alignSelf:'center',
                color: "rgba(0, 0, 0, 0.6)",
              }}
            >
              Title
            </Text>

            </View>
            <View style={{flex:1,width:'100%'}}>
            <Text
              style={{
                fontFamily: "openSans",
                fontSize: 20,
                fontWeight: "400",
                borderWidth:0,
                flex:1,
                // justifyContent:'center',
                // alignSelf:'center',
                color: "rgba(0, 0, 0, 0.6)",
              }}
            >
              Subscription Cost - all
            </Text>

            </View>
            <View style={{flex:1,width:'100%'}}>
            <Text
              style={{
                fontFamily: "openSans",
                fontSize: 20,
                fontWeight: "400",
                borderWidth:0,
                flex:1,
                // justifyContent:'center',
                // alignSelf:'center',
                color: "rgba(0, 0, 0, 0.6)",
              }}
            >
              Language
            </Text>

            </View>
            <View style={{flex:1,width:'100%'}}>
            <Text
              style={{
                fontFamily: "openSans",
                fontSize: 20,
                fontWeight: "400",
                borderWidth:0,
                flex:1,
                // justifyContent:'center',
                // alignSelf:'center',
                color: "rgba(0, 0, 0, 0.6)",
              }}
            >
              Actions
            </Text>

            </View>
          </View>
          {!subscribedMagazines.length > 0 ? (
            <FlatList
              keyExtractor={(item, index) => item.magazine_id}
              data={viewMagazineData}
              renderItem={renderItemFlat}
              style={{ borderWidth: 0, padding: 15, right: 20 }}
            />
          ) : (
            <View>
              <DataNotFound height={35} width={35} fontSize={1.50} text={"Other Magazines Not Available"} textColor={Color.BLACK} />
            </View>
          )}
        </View> */}
      </View>

      </View>

    </ScrollView>

    </SafeAreaView>

    ):(

      <SafeAreaView style={{
        width:'100%',
        height:'100%',
        justifyContent:'center',
        alignItems:'center',
        backgroundColor:Color.WHITE,
        margin:'auto'
        }}>
        <ActivityIndicator size='large' />
        <Text style={{fontSize:15,fontWeight:'700',color:'#b4b4b4'}}>Please Wait ...</Text>


      </SafeAreaView>
      )
  }
   <Modal
        animationType="fade"
        transparent={true}
        visible={comingSoon}
        onRequestClose={() => {
          Alert.alert("Modal has been closed.");
          setComingSoon(!comingSoon);
        }}
      >
            <View style={styles.centeredView}>
    <View style={styles.modalView}>


      {/* sideB */}
      <View
        style={{
          flex: 1,
          borderWidth: 0,
          height: "100%",
          width: "100%",
          backgroundColor: "#ffffff",
          borderRadius:12
        }}
      >
        <View style={{flexDirection:"row",justifyContent:"space-between",padding:20,alignItems:"center" }}>


        <View>
          <Text
            style={{
              fontWeight: "600",
              fontSize: responsiveFontSize(1.5),
            }}
          >
              Coming soon
          </Text>
        </View>
        <View style={{ alignItems: "flex-end" }}>
          <TouchableOpacity
            onPress={() => setComingSoon(!comingSoon)}
          >
            <Image
              style={{ width: 30, height: 30 }}
              source={require("../../assets/images/cross.png")}
            />
          </TouchableOpacity>
        </View>
        </View>
        {/* <View style={{  borderRadius: 16.839,
      borderWidth: 3,
      borderColor: '#EAF9F1',
      backgroundColor: '#FFF',width:'85%',height:'77%',alignSelf:'center'}}>


        </View> */}
         <View style={{alignSelf:'center',justifyContent:'center',alignItems:'center',borderWidth:0,width:'70%',height:'70%'}}>
          <Text
            style={{
              fontWeight: "600",
              fontSize: responsiveFontSize(1.5),
            }}
          >
              This feature will be availabe soon
          </Text>
        </View>


      </View>
    </View>
  </View>
      </Modal>
      <View style={{position:"absolute",width:"100%"}}>
      {error.text2 !== '' &&<PopupComponent style={{zIndex:100}} hideFun={()=>{   error.text2='',dispatchError("")
          dispatchSuccess("")}} type={error.type} text1={error.text1} text2={error.text2} />}
          </View>
  </>
  );
};

export default Magzinepage;

const styles = StyleSheet.create({
  container: {
    borderWidth:0,
    backgroundColor: "#FFF",
    width: "100%",
    height: "100%",
    flexDirection:'row',
    // transform:[{rotate: '270deg'}],

  },
  membersSearchArea: {
    // flex:1,
    borderBottomWidth: 1,
    borderColor: "rgba(0, 0, 0, 0.15)",
    justifyContent: "center",
    paddingVertical: 18,
    alignItems: "center",
    // width:'100%'
  },
  membersSearchAreaContainer: {
    borderWidth: 0,
    flexDirection: !isTabletView() &&  "row",
    justifyContent: "space-between",
    alignItems: !isTabletView() && "center",
    width: isTabletView() ? "90%" : "96%",
  },
  memberContainer: {
    // flex:,
    borderWidth: 0,
    width:'100%',
    height: !isMobileView() && '100%' ,
  },
  subscribedNextPrev: {
    borderWidth: 0,
    marginHorizontal: !isMobileView() ? 45 : 0,
    margin: !isMobileView() ? 20 : 16,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  modalButtonContainer:{
    backgroundColor: "#FFF",
    position:"absolute",
    top:"110%",
    right:"-30%",
    borderRadius: 10,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.15,
    shadowRadius: 6,
    elevation: 1,
  },
  modalButton: {
    paddingVertical: 10,
    alignItems: "center",
    justifyContent: "flex-start",
    gap: 5,
    paddingHorizontal: 20,
    zIndex:4,
    borderRadius: 5,
  },
  languageButton: {
    fontFamily: "openSans",
    fontWeight: "400",
    fontSize: 15,
    padding: 10,
    borderRadius: 10,
    width: 80,
    textAlign:"center"
  },
  activeLanguageButton: {
    backgroundColor: "rgba(230, 232, 234, 1)",
    borderRadius: 10,
    fontWeight: "600",
  },
  centeredView: {
    justifyContent: "center",
    alignItems: "center",
    width:'100%',
    height:'100%',
    alignSelf:'center',
    backdropFilter: 'blur(20px)',
    backgroundColor: Color.GREY_BRIGHT,
  },
  modalView: {
    flex: 1,
    backgroundColor: "white",
    borderRadius: 20,

    justifyContent:'center',
    alignItems: "center",
    shadowColor: Color.BLACK,
    marginTop: 45,
    marginBottom: 45,
    flexDirection: "row",
    height: "60%",
    width: "40%",


  },
});
