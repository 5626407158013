import { StyleSheet, Text, View } from 'react-native'
import React from 'react'
import { createStackNavigator } from '@react-navigation/stack';

import Memberlist from '../screens/Memberlist';

const MainStack = createStackNavigator();
import { Color } from '../Common/Constants';
import SelectionMagazine from '../screens/SelectionMagazine';
import QuickViewPage from '../screens/QuickViewPage';
import OnlinePayments from '../screens/OnlinePayments';
import BulkPurchase from '../screens/BulkPurchase';
import PatrikaSahayak from '../screens/PatrikaSahayak';
import UnpaidInvoices from '../screens/UnpaidInvoices';
import DispatchOrders from '../screens/DispatchOrdersList';
import TimeLine from '../screens/TimeLine';


const MemberStackScreen = ({navigation}) => {
  return (
    <MainStack.Navigator initialRouteName='Memberlist' screenOptions={{headerShown:false,    cardStyle: { backgroundColor: Color.WHITE },
  }}>
        <MainStack.Screen name="Memberlist" component={Memberlist} />
        <MainStack.Screen options={{ gestureEnabled: true, gestureDirection: 'horizontal', }} name="Quickview" component={QuickViewPage} />

        {/* <MainStack.Screen options={{ gestureEnabled: true, gestureDirection: 'horizontal',}} name="SelectionMagazine" component={SelectionMagazine} /> */}
        <MainStack.Screen options={{ gestureEnabled: true, gestureDirection: 'horizontal',}} name="SelectionMagazine" component={SelectionMagazine} />

        <MainStack.Screen name="OnlinePayments" component={OnlinePayments} />
        <MainStack.Screen name="TimeLine" component={TimeLine} />
        <MainStack.Screen name="BulkPurchase" component={BulkPurchase} />
        <MainStack.Screen name="DispatchOrders" component={DispatchOrders} />
        <MainStack.Screen name="PatrikaSahayak" component={PatrikaSahayak} />
        <MainStack.Screen name="UnpaidInvoices" component={UnpaidInvoices} />

    </MainStack.Navigator>
  )
}

export default MemberStackScreen

const styles = StyleSheet.create({

})
