import { Image, StyleSheet, Text, View,TouchableOpacity} from "react-native";
import React, { useState } from "react";
import {
  responsiveScreenWidth,
  responsiveScreenHeight,
  responsiveFontSize,
} from "react-native-responsive-dimensions";
import AppLoading from "expo-app-loading";
import { useFonts } from "expo-font";


import { Color, isMobileView, isTabletView } from "./Constants";
import commonStyles from "../styles/styles";

const BuyMoreCard = ({navigation}) => {

  let [fontsLoaded] = useFonts({
    openSans: require("../../assets/fonts/OpenSans-Italic-VariableFont_wdth,wght.ttf"),
  });
  if (!fontsLoaded) {
    return <AppLoading />;
  }

  return (
    <>
    <View style={styles.cardCointainer}>
    <TouchableOpacity onPress={()=>navigation.navigate('SelectionMagazine')} style={[commonStyles.alignCenter,commonStyles.gap2rem,{justifyContent:"center",height:"100%"}]}>
    <Image
              style={{
                height: isMobileView() ? responsiveScreenHeight(13)  : 120,
                width: isMobileView() ? responsiveScreenHeight(13)  : 122,
                borderWidth: 0,


              }}
              resizeMode="contain"
              source={ isMobileView() ? require("../../assets/images/buyMoreBlue.png")    : require("../../assets/images/buyMore.png")}
            />
                 <Text
              style={{ fontWeight: "500", fontSize: isMobileView() ? responsiveFontSize(3.8)  : 32.2196,color: isMobileView() ? Color.BLUE_MEDIUM  : Color.WHITE }}
            >
             Buy More
            </Text>
</TouchableOpacity>





    </View>

</>

  );
};

export default BuyMoreCard;

const styles = StyleSheet.create({

    cardCointainer: {
      width: isMobileView() &&  "100%" || isTabletView() && !isMobileView() &&  responsiveScreenWidth(39)  || !isMobileView() && !isTabletView() && 337.92,
      height: isMobileView() &&  responsiveScreenHeight(52) ||  isTabletView() && !isMobileView() &&  responsiveScreenHeight(58)  || !isMobileView() && !isTabletView() &&  492.48,
        borderWidth: 2,
        borderColor:  isMobileView() ? Color.BLUE_MEDIUM :  'rgba(234, 249, 241, 1)',
        borderRadius: 10,
        shadowOpacity: 0.1,
        margin: !isMobileView() && isTabletView() && 10 || !isMobileView() && !isTabletView() &&  10,
        backgroundColor: isMobileView() ? Color.BLUE_LIGHT : 'rgba(255, 255, 255, 0.3)',
        borderStyle:"dashed",
        shadowOffset: { width: 1, height: 1 },
      },
});
