import { StyleSheet, Text, View, Image, TouchableOpacity, ImageBackground } from "react-native";
import React ,{useEffect} from "react";
import {
    responsiveHeight,
    responsiveWidth,
    responsiveFontSize
  } from "react-native-responsive-dimensions";
  import { Color, Padding, FontFamily, FontSize, isMobileView, isTabletView, isSmallLaptop } from "./Constants";
import AppLoading from "expo-app-loading";
import { useFonts } from "expo-font";
import { apiServices } from "../services/apiServices";
import commonStyles from "../styles/styles";

const Headerdiv = ({navigation,memberCode}) => {

    const {viewProfile} = apiServices
    const [profileData, setProfileData] = React.useState([])
    let [fontsLoaded] = useFonts({
        'openSans' : require('../../assets/fonts/OpenSans-Italic-VariableFont_wdth,wght.ttf')
      })
      const getMemberCode = async () => {
        let viewMember = await viewProfile(memberCode)
        setProfileData(viewMember)
      }
      // useEffect(() => {
      //   getMemberCode()
      // },[])

      if(!fontsLoaded){
        return <AppLoading/>
      }


  return (
    <View style={[styles.wwwnirankariorgByHtmltod, isMobileView() && {shadowOpacity:0} ]}>
    <View style={styles.divpage}>
      <ImageBackground
        style={styles.sectionIcon}
        resizeMode="cover"
        source={require("../../assets/images/section@3x.png")}
      >
        <View style={styles.divelementorRow}>
          <View style={styles.divelementorColumn}>
            <Image
              style={styles.linkLogopng}
              contentFit="cover"
              source={require("../../assets/images/link--logopng@3x.png")}
            />
          </View>

        </View>
      </ImageBackground>
    </View>
  </View>
  )
}

export default Headerdiv

const styles = StyleSheet.create({
  liveFlexBox: {
    textAlign: "left",
    color: Color.darkslateblue,
  },
  iiconPosition: {
    paddingRight: Padding.p_8xs,
    marginTop: -6.3,
    flexDirection: "row",
    paddingBottom: 1,
  },
  itemSpaceBlock: {
    paddingBottom: Padding.p_2xs,
    paddingTop: Padding.p_4xs,
    alignItems: "center",
    flexDirection: "row",
  },
  linkLogopng: {
    width: 300,
    height: 59,
    maxWidth: 435,
    overflow: "hidden",
  },
  divelementorColumn: {
    width: 435,
    paddingTop: Padding.p_8xs,
    paddingLeft: responsiveWidth(1.7),
    paddingBottom: Padding.p_8xs,
    minHeight: 1,
    flexDirection: "row",
  },
  theMission: {
    top: 9,
    left: 10,
    fontFamily: FontFamily.ralewaySemiBold,
    fontWeight: "600",
    textTransform: "uppercase",
    lineHeight: 24,
    fontSize: FontSize.size_smi,
    color: Color.darkslateblue,

  },
  symbol: {
    fontSize: FontSize.size_2xs,
    lineHeight: 11,
    fontFamily: FontFamily.interRegular,
  },
  iicon: {
    left: 99,
  },
  itemLink: {
    width: 125,
    height: 44,
  },
  iicon1: {
    left: 85,
  },
  itemLink1: {
    width: 111,
    height: 44,
  },
  iicon2: {
    left: 57,
  },
  itemLink2: {
    width: 83,
    height: 44,
  },
  iicon3: {
    left: 74,
  },
  itemLink3: {
    width: 100,
    height: 44,
  },
  live: {
    fontFamily: FontFamily.ralewaySemiBold,
    fontWeight: "600",
    textTransform: "uppercase",
    lineHeight: 24,
    fontSize: FontSize.size_smi,
    color: Color.darkslateblue,
  },
  itemLink4: {
    width: 48,
    paddingLeft: Padding.p_3xs,
    paddingBottom: Padding.p_2xs,
    paddingTop: Padding.p_4xs,
    paddingRight: 10,
  },
  itemLink5: {
    width: 89,
    paddingRight: 9,
    paddingLeft: Padding.p_3xs,
    paddingBottom: Padding.p_2xs,
    paddingTop: Padding.p_4xs,
  },
  itemLink6: {
    width: 81,
    paddingHorizontal: Padding.p_3xs,
  },
  list: {
    bottom: -53,
    paddingRight: 429,
    flexWrap: "wrap",
    flexDirection: "row",

  },
  divekitMegamenuMainMenu: {
    backgroundColor: "rgba(255, 255, 255, 0)",
    height: 35,
    width: 1065,
  },
  divelementorWidgetWrap: {
    paddingHorizontal: 0,
    paddingVertical: 17,
    alignItems: "center",
    flexWrap: "wrap",
    width: 1065,
    flexDirection: "row",
  },
  divelementorRow: {
    flexDirection: "row",
  },
  sectionIcon: {
    width: isMobileView() ? responsiveWidth(350) :  responsiveWidth(102) ,
    left: isMobileView() ? -responsiveWidth(2) :  -responsiveWidth(1),
    top: isMobileView() ? -responsiveWidth(1) :  responsiveWidth(0),

    flexDirection: "row",
    overflow: "hidden",
    paddingBottom: 1,
  },
  divpage: {
    paddingBottom: 1,

  },
  wwwnirankariorgByHtmltod: {
    backgroundColor: Color.WHITE,
    width: "100%",
    height: !isMobileView() && isTabletView() && 62 || isSmallLaptop() && 64 || 70,
    shadowColor: '#000',
    top: !isMobileView() && isTabletView() && -4 || isSmallLaptop() && -4 || 0,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.3,
    shadowRadius: 4,
  },
});
