import { StyleSheet, Text, View,Image } from "react-native";
import React, { useState } from "react";
import { responsiveFontSize, responsiveScreenHeight, responsiveScreenWidth } from "react-native-responsive-dimensions";

const TicketsComponent = ({ data }) => {

  const originalDate = data.created_at
  const dateObject = new Date(originalDate);

  const day = dateObject.getDate();
  const month = dateObject.getMonth() + 1; // Months are zero-based, so we add 1
  const year = dateObject.getFullYear();

  const formattedDate = `${day}/${month}/${year}`;

  const [status, setStatusBackground] = useState({
   backgroundColor : data.status === "open" ? "#EAF9F1" : "#FEEEE9",
    textColor : data.status === "open" ? "#2CC373" : "#F24D14"
  }
  )

  return (
    <View
      style={{
        flexDirection: "row",
        width: "100%",
        height: responsiveScreenHeight(9),
        alignItems: "center",
        borderWidth: 2,
        margin: 15,
        justifyContent: "space-between",
        borderRadius: 8,
        elevation: 5,
        borderColor: "rgba(0, 0, 0, 0.15)",
        paddingRight: "4%",
      }}
    >
      <View
        style={{
          flexDirection: "row",

          alignItems: "center",
          borderWidth: 0,gap:10,
          minimumWidth: 150,
          left:"1.5%"
        }}
      >
              <Image
          style={{
            height: responsiveScreenHeight(4.5),
            width: responsiveScreenWidth(2.5),
            resizeMode: "contain",
          }}
          source={require("../../assets/images/support.png")}
        />
        <Text style={styles.primaryText}>SR# {data.id}</Text>
      </View>
      <View
        style={{
          flexDirection: "row",

          alignItems: "center",
          borderWidth: 0,gap:10,
          minimumWidth: 150,
          left:"-4.5%"
        }}
      >
                   <Image
          style={{
            height: responsiveScreenHeight(4.5),
            width: responsiveScreenWidth(2.5),
            resizeMode: "contain",
          }}
          source={require("../../assets/images/type.png")}
        />
        <Text style={styles.primaryText}>{data.query}</Text>
      </View>
      <View
        style={{
          flexDirection: "row",

          alignItems: "center",
          borderWidth: 0,gap:10,
          minimumWidth: 150,
          left:"-9.5%"

        }}
      >
                        <Image
          style={{
            height: responsiveScreenHeight(4.5),
            width: responsiveScreenWidth(2.5),
            resizeMode: "contain",
          }}
          source={require("../../assets/images/cal2.png")}
        />
        <Text style={styles.primaryText}>{formattedDate}</Text>
      </View>
      <View
        style={{
          flexDirection: "row",

          alignItems: "center",
          borderWidth: 0,gap:10,
          minimumWidth: 150,
          left:"-19%",
          padding:5,
          paddingHorizontal:15,
          borderRadius:50,
          backgroundColor:status.backgroundColor,

        }}
      >

        <Text style={[styles.primaryText,{color:status.textColor}]}>{data.status}</Text>
      </View>
    </View>
  );
};

export default TicketsComponent;

const styles = StyleSheet.create({
  textBox: {
    borderWidth: 3,
    borderColor: "rgba(200, 249, 250, 1)",
    height: 35,
    padding: 5,
  },
  textBoxText: { fontSize: 12 },
  primaryText: {     fontFamily: "openSans",
  fontWeight: "600",
  fontSize: responsiveFontSize(1.15),
  borderWidth: 0,},
  secondText: { fontWeight: "bold", opacity: 0.3, fontSize: 14 },
});
