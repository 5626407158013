import { StyleSheet, Text, View,Modal,SafeAreaView,ActivityIndicator } from "react-native";
import React, {  useContext, useEffect, useState } from "react";
import { ScrollView } from "react-native";

import {
  responsiveFontSize,
  responsiveHeight,
  responsiveWidth,
} from "react-native-responsive-dimensions";
import commonStyles from "../styles/styles";
import { FlatList } from "react-native";
import { apiServices } from "../services/apiServices";
import AsyncStorage from "@react-native-async-storage/async-storage";
import ReceiptComponent from "../Common/ReceiptComponent";
import Headerdiv from "../Common/Headerdiv";
import AppLoading from "expo-app-loading";
import { useFonts } from "expo-font";
import { TouchableOpacity } from "react-native";
import { Image } from "react-native";
import { Color, isLargeLaptop,isTabletView, isMobileView, isOnlyTabletView, isOnlyLaptopView, isSmallLaptop } from "../Common/Constants";
import Hoverable from "react-native-hoverable";
import { Cell, Row, Table, TableWrapper } from "react-native-table-component";
import ErrorContext from "../context/ErrorContext";
import PopupComponent from "../Common/PopupComponent";
import Timeline from "react-native-timeline-flatlist";
import { formatDates,arraySort } from "../util/fomatedDate";
let totalPages
let startIndex
let endIndex
let currentPageData = []
const itemsPerPage = 4;
const TimeLine = ({ navigation,route }) => {
  const { error, dispatchError, dispatchSuccess } = useContext(ErrorContext);

  let COUNT;
  const { getTimeLineList,getSingleMagazineTimelineDataApi } = apiServices;
  const [memberCode, setMemberCode] = useState(null);
  const [timeLineData, setTimeLineData] = useState({});
  const [loader, setLoader] = useState(1)

  const tableHead = ["Sr No.", "Combo Id", "Magazine/Qty", "Magazine Volume","Order Status","AWR No","Action"];

  const [viewTimelineModal,setViewTimelineModal] = useState(false)
  const [singleMagazineTimeLineData,setSingleMagazineTimeLineData]= useState([])
  const [currentPageData, setCurrentPageData] = useState([])
  const [selectedTimeLineData,setSelectedTimeLineData] = useState({})
  const [loading,setLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const updateCurrentPageData = (page, data) => {
    const startIdx = (page - 1) * itemsPerPage;
    const endIdx = startIdx + itemsPerPage;
    return data.slice(startIdx, endIdx);
  };

  // Function to handle the "next" button click
  const handleNextPage = () => {
    const nextPage = Math.min(currentPage + 1, totalPages);
    setCurrentPage(nextPage);
    setCurrentPageData(updateCurrentPageData(nextPage, timeLineData)); // Update currentPageData
  };

  // Function to handle the "previous" button click
  const handlePreviousPage = () => {
    const prevPage = Math.max(currentPage - 1, 1);
    setCurrentPage(prevPage);
    setCurrentPageData(updateCurrentPageData(prevPage, timeLineData)); // Update currentPageData
  };

  // Function to handle clicking on a specific page number
  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    setCurrentPageData(updateCurrentPageData(pageNumber, timeLineData)); // Update currentPageData
  };
   var pageNumbers = [];

   // Generate an array of page numbers to display in pagination
   const getPageNumbers = () => {
    const range = 2; // Number of page numbers to show before and after the current page

    // Calculate the range of page numbers to show
    let start = Math.max(currentPage - range, 1);
    let end = Math.min(currentPage + range, totalPages);

    // Adjust the range if the current page is near the start or end
    const maxRange = 2 * range + 1;
    if (end - start < maxRange) {
      if (currentPage < totalPages / 2) {
        end = Math.min(end + maxRange - (end - start), totalPages);
      } else {
        start = Math.max(start - maxRange + (end - start), 1);
      }
    }

    // Add page numbers to the array
    for (let i = start; i <= end; i++) {
      pageNumbers.push(i);
    }

    return pageNumbers;
  };
  const statusPill = (data, index) => {

    return(
      <View
          style={{
            margin: 6,
             marginLeft:15,
            alignItems: "center",
            justifyContent: "center",
            flexDirection:"row",
            backgroundColor: data === "Success" ? "#EAF9F1" : "#FEEEE9",
            width:"80%",padding:5,
            borderRadius:50,

          }}
        >

          <Text style={[styles.text,{color:data=== "Success" ? "#2CC373" : "#F24D14",bottom:0,position:"relative",margin:0}]}>{data}</Text>
        </View>
    )};
    const refreshDispatchTimeline=()=>{
      setLoading(true)
      getSingleMagazineTimelineData(selectedTimeLineData);

    }
    const getSingleMagazineTimelineData =async (data)=>{
      try {
        const res =await getSingleMagazineTimelineDataApi(memberCode,data.dispatch_detail_id);
        setSelectedTimeLineData(data)
        arraySort(res?.dispatch_timeline,'created_at');
        let arr =[]
        for(let item of res?.dispatch_timeline)
        {
          const date = new Date(item?.created_at);

          // Get hours and minutes
          const hours = date.getHours().toString().padStart(2, '0'); // Convert to string and pad with leading zero if needed
          const minutes = date.getMinutes().toString().padStart(2, '0'); // Convert to string and pad with leading zero if needed

          // Combine hours and minutes
          const time = hours + ':' + minutes;
            if(item.update_type=='AWR')
            {
              arr.push({time:`${formatDates(item.created_at)}`+' ('+`${time}`+')',title:item.status,updateType:item.update_type,awrNo:item.awr_no})//description:item.awr_no
            }
            if(item.update_type =='Dispatch Id')
            {
              arr.push({time:`${formatDates(item.created_at)}`+' ('+`${time}`+')',title:item.status,updateType:item.update_type,awrNo:item.item.dispatch_id})//description:item.dispatch_id
            }
        }

        setSingleMagazineTimeLineData(arr);
        setViewTimelineModal(true)
        setLoading(false)
        // setTimeout(() => {
        //   dispatchSuccess("Status Refreshed")
        // }, 1500);
      } catch (error) {

          dispatchError(error)
      }

    }
    const viewButton = (data, index) => {

    return(
      <TouchableOpacity
      style={[
        {
          paddingVertical: 2.5,
          paddingHorizontal: 5,
          borderRadius: 100,
          margin: 6,
          marginLeft: 15,
          with: "fit-content",
        },
      ]}
      onPress={()=>getSingleMagazineTimelineData(data)}
      >
            <Text
              style={{
                color: Color.BLUE_MEDIUM,
                fontWeight: "600",
                 fontSize: isMobileView() ? responsiveFontSize(1.9)  : responsiveFontSize(1),
                }}
                >
               View Timeline
            </Text>

      {/* <Image
      source={require("../../assets/images/downloadFile.png")}
      style={{width:20,height:30}}
      resizeMode="cover"
      >

      </Image> */}

    </TouchableOpacity>

    )};
  const dateItem = (data, index) => {
    return (
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          borderWidth: 0,
          height: "100%",
          width: "100%",
          margin: 6,
          marginLeft: 15,
          gap: 15,
        }}
      >
        <Image
          style={{
            height: responsiveHeight(4.2),
            width: responsiveWidth(2.2),
            resizeMode: "contain",
          }}
          source={require("../../assets/images/cal2.png")}
        />
        <Text
          style={{
            fontFamily: "openSans",
            fontWeight: "600",
            fontSize: responsiveFontSize(1),
          }}
        >
          {data}
        </Text>
      </View>
    );
  };
  const forItem = (data, index) => {
    return (
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          borderWidth: 0,
          height: "100%",
          width: "100%",
          margin: 6,
          marginLeft: 15,
          gap: 15,
        }}
      >
        <Image
          style={{
            height: responsiveHeight(4.2),
            width: responsiveWidth(2.2),
            resizeMode: "contain",
          }}
          source={require("../../assets/images/membersIcon.png")}
        />
        <Text
          style={{
            fontFamily: "openSans",
            fontWeight: "600",
            fontSize: responsiveFontSize(1),
          }}
        >
          {data}
        </Text>
      </View>
    );
  };
  const copiesItem = (data, index) => {
    return (
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          borderWidth: 0,
          height: "100%",
          width: "100%",
          margin: 6,
          marginLeft: 15,
          gap: 15,
        }}
      >
        <Image
          style={{
            height: responsiveHeight(4.2),
            width: responsiveWidth(2.2),
            resizeMode: "contain",
          }}
          source={require("../../assets/images/type.png")}
        />
        <Text
          style={{
            fontFamily: "openSans",
            fontWeight: "600",
            fontSize: responsiveFontSize(1),
          }}
        >
          {data}
        </Text>
      </View>
    );
  };
  const amountItem = (data, index) => {
    return (
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          borderWidth: 0,
          height: "100%",
          width: "100%",
          margin: 6,
          marginLeft: 15,
          gap: 15,
        }}
      >
        <Image
          style={{
            height: responsiveHeight(4.2),
            width: responsiveWidth(2.2),
            resizeMode: "contain",
          }}
          source={require("../../assets/images/tag.png")}
        />
        <Text
          style={{
            fontFamily: "openSans",
            fontWeight: "600",
            fontSize: responsiveFontSize(1),
          }}
        >
          ₹{data}
        </Text>
      </View>
    );
  };
  const actionItem = (data, index) => {
    return (
      <Hoverable>
        {({ hovered }) => (
          <TouchableOpacity
            onPress={() => setModalVisible(true)}
            style={[
              {
                paddingVertical: 2.5,
                paddingHorizontal: 5,
                borderRadius: 100,
                margin: 6,
                marginLeft: 15,
                with: "fit-content",
              },
            ]}
          >
            <Text
              style={{
                color: Color.BLUE_MEDIUM,
                fontWeight: "600",
                fontSize: responsiveFontSize(1),
              }}
            >
              {data}
            </Text>
          </TouchableOpacity>
        )}
      </Hoverable>
    );
  };

  const getMemberCodeX = async () => {
    let getMemberCode;
    try {
      getMemberCode = await AsyncStorage.getItem("member_code");
      setMemberCode(getMemberCode);
      setLoader((prev) => {
        return prev !== 0 ? prev - 1 : 0;
      });
    } catch (error) {
      dispatchError(error);
      setLoader((prev) => {
        return prev !== 0 ? prev - 1 : 0;
      });
    }
  };
  useEffect(() => {
    getMemberCodeX();
  }, []);

  const getMagazineTimeLineList = async () => {
    let getTimeLineData = await getTimeLineList(memberCode,route.params.id,route.params.bulkPurchase);
    if(typeof(getTimeLineData)=='object'){
      setTimeLineData(getTimeLineData.dispatch);
      totalPages = Math.ceil(getTimeLineData?.dispatch?.length / itemsPerPage);
        startIndex = (currentPage - 1) * itemsPerPage;
        endIndex = Math.min(startIndex + itemsPerPage, getTimeLineData?.dispatch?.length);
        setCurrentPageData(getTimeLineData?.dispatch?.slice(startIndex, endIndex))
    }
    // console.log(getReceiptsData)
   

    };

    COUNT = timeLineData.length;

  useEffect(() => {
    if (memberCode) {
        getMagazineTimeLineList();
    }
  }, [memberCode]);

  let [fontsLoaded] = useFonts({
    openSans: require("../../assets/fonts/OpenSans-VariableFont_wdth,wght.ttf"),
  });
  if (!fontsLoaded) {
    return <AppLoading />;
  }
  const renderReceipts = ({ item }) => {
    return (
      <ReceiptComponent
        data={item}
        memberCode={memberCode}
        navigation={navigation}
      />
    );
  };



  return (
    <>
    {loader === 0 ?(<View
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: "#FFF",
        overflow: "hidden",
      }}
    >
      {/* <View style={{width:"100%",height:50,backgroundColor:'#F8F9FA',borderWidth:1}}>
        <Text>Dhan Nirankar Ji - Virat Kohli</Text>
      </View> */}
      <Headerdiv memberCode={memberCode} navigation={navigation} />

      <View
        style={{
          borderBottomWidth: 1,
          borderColor: "rgba(0, 0, 0, 0.15)",
          justifyContent: "center",
          paddingVertical: 18,
          alignItems: "center",
          width: "100%",
        }}
      >
        <View
          style={{
            borderWidth: 0,
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: isMobileView() ? "90%" : "96%",
            bottom: isMobileView() ? 3 : 0,
          }}
        >
          <Text
            style={{
              fontWeight: "600",
              fontSize: isTabletView() ? responsiveFontSize(2.6) :  responsiveFontSize(2.2),
            }}
          >
            Magazine Timeline
          </Text>
          <View style={[commonStyles.gap1rem, commonStyles.flexRow]}>
            <Hoverable>
              {({ hovered }) => (
                <TouchableOpacity
                  style={{
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    flexDirection: "row",
                    borderWidth: 2,
                    borderRadius: 10,
                    borderColor: Color.BLUE_MEDIUM,
                    height: 40,
                    width: 120,
                    backgroundColor: hovered ? Color.BLUE_MEDIUM : "white",
                  }}
                  onPress={() => {
                    navigation.goBack();
                  }}
                >
                  <Image
                    style={{
                      height: 25,
                      width: 25,
                      resizeMode: "contain",
                      borderWidth: 0,
                      transform: [{ rotate: "180deg" }],
                    }}
                    source={
                      hovered
                        ? require("../../assets/images/goBackWhite.png")
                        : require("../../assets/images/goBack.png")
                    }
                  />
                  <Text
                    style={{
                      fontFamily: "openSans",
                      fontWeight: "600",
                      fontSize: 15,
                      color: hovered ? "white" : Color.BLUE_MEDIUM,
                    }}
                  >
                    Go Back
                  </Text>
                </TouchableOpacity>
              )}
            </Hoverable>
          </View>
        </View>
      </View>
      <View>
        <View style={styles.memberContainer}>
          <View
            style={[
              styles.subscribedNextPrev,
              !isMobileView() && commonStyles.pl_2,
              isMobileView() && commonStyles.width90,
              isMobileView() && commonStyles.alignSelfCenter,
            ]}
          >
            <View
              style={[
                commonStyles.flexRow,
                commonStyles.flex,
                commonStyles.justifyBetween,
                commonStyles.alignCenter,
                commonStyles.width100,
              ]}
            >
              {/* <Text
              style={{ fontWeight: "400",   fontSize: !isMobileView()
              ? responsiveFontSize(2)
              : responsiveFontSize(2.4), }}
              >
                Hansti Duniya - Hindi
              </Text> */}
              <View
                style={{
                  backgroundColor: "rgba(230, 232, 234, 1)",
                  paddingVertical: 8,
                  paddingHorizontal: !isMobileView() ? 20 : 14,
                  borderRadius: 10,
                }}
              >
                <Text
                  style={{
                    fontWeight: "500",
                    fontSize: !isMobileView() ?  responsiveFontSize(1) : responsiveFontSize(1.8),
                  }}
                >
                  {COUNT}{" "}
                </Text>
              </View>
            </View>
          </View>
          <View
            style={[
              commonStyles.spaceLeftPrimary,
              commonStyles.spaceRightPrimary,
              {
                borderWidth: 0,
                // alignSelf: "center",
                // marginVertical: 10,
                marginBottom: 10,
                paddingRight:0
              },

            ]}
          >
                  <View style={[styles.container,

]}>
            <ScrollView contentContainerStyle={{width:"100%"}}  style={{width:"100%"}} horizontal={!isLargeLaptop() && true      }>

          <Table style={{width: isMobileView() ? "135%" : "100%"}} borderStyle={{ borderWidth: 0, borderColor: '#c8e1ff' }}>
        <Row data={tableHead}   style={styles.head} textStyle={styles.textHead} />
        {currentPageData.map((rowData, index) => (
      <TableWrapper key={index} style={styles.tableRow}>
        <Cell
          data={index+1}
          textStyle={styles.text}
          style={{ flex: 1, justifyContent: 'center', alignItems: 'flex-start' }}
        />
        <Cell
          data={rowData?.combo_id}
          textStyle={styles.text}
          style={{ flex: 1, justifyContent: 'center', alignItems: 'flex-start' }}
        />
        <Cell data={rowData?.magazine_name+'/'+rowData?.quantity} textStyle={styles.text} style={{ flex: 1, alignItems: 'flex-start' }} />
        <Cell data={rowData?.month+'/'+rowData?.year} textStyle={styles.text} style={{ flex: 1, alignItems: 'flex-start' }} />
        <Cell
        //   data={statusPill(rowData?.status, index)}
          data={rowData?.status}
          textStyle={styles.text}
          style={{ flex: 1, justifyContent: 'center', alignItems: 'flex-start' }}
        />
        <Cell data={rowData?.ref_no} textStyle={styles.text} style={{ flex: 1 }} />
        <Cell
        key={index}
        data={viewButton(rowData, index)}
        textStyle={styles.text}
        style={{ flex: 1, justifyContent: 'center',  }}
      />

      </TableWrapper>
      //  <FilteredRow key={index} rowData={rowData} index={index} />
    ))}
          {/* </TableWrapper> */}
        {/* ))} */}
      </Table>
      </ScrollView>
</View>
      <View style={{ flexDirection: 'row', justifyContent: 'flex-end', marginTop: 10, alignItems:'center',borderWidth:0}}>

  <TouchableOpacity style={{borderRadius:7.13,cursor:pageNumbers<1 && 'pointer' ,borderColor:'rgba(0, 0, 0, 0.15)',borderWidth:2,height:30,width:30,justifyContent:'center',alignItems:'center'}}  onPress={handlePreviousPage}>
    <Image style={{ tintColor: currentPage > 1 ? Color.BLUE_MEDIUM:'',height:20,width:20,}} source={require('../../assets/images/nextPageIcon.png')}></Image>
  </TouchableOpacity>
  {/* Page numbers */}
  {getPageNumbers().map((pageNumber) => (
    <TouchableOpacity key={pageNumber} onPress={() => handlePageClick(pageNumber)}>
      <Text
        style={{
          fontSize: 15,
          marginHorizontal: 10,
          fontWeight: pageNumber === currentPage ? 'bold' : 'normal',
          cursor: 'pointer',
        }}
      >
        {pageNumber}
      </Text>
    </TouchableOpacity>
  ))}

  <TouchableOpacity style={{borderRadius:7.13,cursor:currentPage !== totalPages &&'pointer', borderColor:'rgba(0, 0, 0, 0.15)',borderWidth:2,height:30,width:30,justifyContent:'center',alignItems:'center'}} onPress={handleNextPage}>
    <Image style={{ tintColor: currentPage === totalPages ?  '' : Color.BLUE_MEDIUM,height:20,width:20,transform:[{rotate: '180deg'}]}} source={require('../../assets/images/nextPageIcon.png')}></Image>
  </TouchableOpacity>
</View>
          </View>
        </View>
      </View>
    </View>):(
        <SafeAreaView
          style={{
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: Color.WHITE,
            margin: "auto",
          }}
        >
          <ActivityIndicator size="large" />
          <Text style={{ fontSize: 15, fontWeight: "700", color: "#b4b4b4" }}>
            Please Wait ...
          </Text>
        </SafeAreaView>
      )}

<View style={{position:"absolute",width:"100%"}}>
{error.text2 !== '' &&<PopupComponent style={{zIndex:100}} hideFun={()=>{          dispatchError("")
          dispatchSuccess("")}} type={error.type} text1={error.text1} text2={error.text2} />}

</View>
<Modal
        animationType="fade"
        transparent={true}
        visible={viewTimelineModal}
        onRequestClose={() => {
          Alert.alert("Modal has been closed.");
          setViewTimelineModal(!viewTimelineModal);
        }}
      >
        <View style={styles.centeredView}>
          <View style={styles.modalViewPopup}>
            <View
              style={{
                width: "100%",
                alignItems: "flex-end",
                borderWidth: 0,
              }}
            >
              <TouchableOpacity style={[{paddingTop:13,paddingRight:13}]} onPress={() => setViewTimelineModal(!viewTimelineModal)}>
                <Image
                   style={{ width: 30, height: 30 }}
                  source={require("../../assets/images/cross.png")}
                />
              </TouchableOpacity>
            </View>
           {!loading ? <View style={{ justifyContent:'space-around',alignItems:'center',borderWidth:0,flex:1,width:'100%',gap:25}}>
            <Text style={[styles.modalText,{marginBottom:0,width: isMobileView() && "80%" }]}>Magazine Timeline</Text>
            {singleMagazineTimeLineData.length>0 && <Text style={[styles.modalText,{marginBottom:0,width: isMobileView() && "80%" }]}>{singleMagazineTimeLineData[0]?.updateType} ({singleMagazineTimeLineData[0]?.awrNo})</Text>}
              <Timeline
                   circleSize={20}
                   circleColor={Color.BLUE_MEDIUM}
                   circleStyle={{backgroundColor:"white",borderColor:Color.BLUE_MEDIUM,borderWidth:4}}
                   lineColor={"#007AFF"}
                   eventContainerStyle={{borderColor:Color.BLUE_MEDIUM,height:50}}
                   lineWidth={2}
                   timeContainerStyle={{width:150}}
                   timeStyle={{textAlign: 'center', backgroundColor:Color.BLUE_LIGHT, color:Color.BLUE_MEDIUM, padding:5,paddingLeft:10,paddingRight:10, borderRadius:13,fontWeight:"500"}}
                   descriptionStyle={{color:'rgba(0, 0, 0, 0.9)'}}
                   titleStyle={{ fontFamily: "openSans",}}

                   options={{
                     style:{paddingTop:5,width:"120%",flex:1},
                   }}
              data={singleMagazineTimeLineData} />
              <View style={{width:'100%',flexDirection:'row',justifyContent:'center',gap:40}}>
              <TouchableOpacity onPress={()=>{ refreshDispatchTimeline()}} style={{
                borderWidth: 2,
                borderRadius: 7,
                backgroundColor: '#fff',
                width: isMobileView() ? responsiveWidth(30) : responsiveWidth(10),
                height: responsiveHeight(5),
                alignItems: 'center',
                justifyContent: 'center',
                borderColor: 'rgba(0, 0, 0, 0.15)',
                }}>
                  <Text
                  style={{color:Color.BLACKWHITE,fontWeight:'500'}}
                  >Refresh</Text>
                </TouchableOpacity>
                <TouchableOpacity onPress={()=>{ setViewTimelineModal(!viewTimelineModal)}} style={{
                borderWidth: 0,
                borderRadius: 7,
                backgroundColor: '#F24D14',
                width: isMobileView() ? responsiveWidth(30) : responsiveWidth(10),
                height: responsiveHeight(5),
                alignItems: 'center',
                justifyContent: 'center',
                borderColor: 'rgba(0, 0, 0, 0.15)',
                }}>
                  <Text
                  style={{color:Color.WHITE,fontWeight:'500'}}
                  >Close</Text>
                </TouchableOpacity>

              </View>

            </View>
            :
            <SafeAreaView
            style={{
              width: "100%",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: Color.WHITE,
              margin: "auto",
            }}
          >
            <ActivityIndicator size="large" />
            <Text style={{ fontSize: 15, fontWeight: "700", color: "#b4b4b4" }}>
              Please Wait ...
            </Text>
          </SafeAreaView>
            }








          </View>
        </View>
      </Modal>
    </>
  );
};

export default TimeLine;

const styles = StyleSheet.create({
  body: {},
  subscribedNextPrev: {
    borderWidth: 0,

    marginHorizontal: !isMobileView() && !isTabletView() && 45 || !isMobileView() && isTabletView() && 20 || isMobileView() && 0,
    margin: !isMobileView() ? 20 : 16,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  head: { height: responsiveHeight(6), backgroundColor: "#fff" },
  text: {
    margin: 6,
    marginLeft: 15,
    fontWeight: 600,
    fontSize: isMobileView() ? responsiveFontSize(1.9)  : responsiveFontSize(1),
  },
  textHead: {
    margin: 6,
    marginLeft: 15,
    fontWeight: 500,
    fontSize: isMobileView() ? responsiveFontSize(2) : responsiveFontSize(1.1),
    color: "rgba(0, 0, 0, 0.60)",
  },
  tableRow: {
    borderWidth: 1,
    borderColor: "rgba(0, 0, 0, 0.15)",
    marginVertical: 8,
    borderRadius: 6,
    height: responsiveHeight(12),
    flexDirection: "row",
    justifyContent:"space-between"
  },
    container: { flex: 1,  backgroundColor: '#fff' },
    modalText: {

        fontFamily:"openSans",
        fontWeight: "700",
        fontSize: isMobileView() ? responsiveFontSize(2.2) : responsiveFontSize(1.5),
      },
      modalButtonContainer: {
        backgroundColor: "#FFF",
        position: "absolute",
        top: "110%",
        right: "-30%",
        borderRadius: 10,
        shadowColor: "#000",
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.15,
        shadowRadius: 6,
        elevation: 1,
      },
      modalViewPopup: {
        borderWidth: 0,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        width: isMobileView() ? "100%" : responsiveWidth(55),
        height: isMobileView() ? "100%" : responsiveHeight(80),
        paddingBottom:25,
        margin: 5,
        backgroundColor: "#fff",
        borderRadius: 8,

        shadowColor: "none",


        shadowRadius: 4,

      },
      modalButton: {
        paddingVertical: 10,
        alignItems: "center",
        justifyContent: "flex-start",
        gap: 5,
        paddingHorizontal: 20,
        zIndex: 4,
        borderRadius: 5,
      },
      modalView: {
        // position:"relative",
        flex: 1,
        backgroundColor: "white",
        borderRadius: !isMobileView() && 20,

        alignItems: "center",
        shadowColor: Color.BLACK,
        marginTop: isOnlyLaptopView() && 25 || isOnlyTabletView() && 20,
        marginBottom: isOnlyLaptopView() && 25 || isOnlyTabletView() && 20,

        flexDirection: isMobileView() ? "column" : "row",
        width:  isMobileView()  && "100%" ||  isOnlyTabletView() && "95%" ||isOnlyLaptopView() && "70%",
        height:  isMobileView()  && "100%" ||  isOnlyTabletView() && "95%" ||isOnlyLaptopView() && "60%",

      },
      modalViewError: {
        borderWidth: 0,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        width: responsiveWidth(30),
        height: responsiveHeight(50),
        margin: 5,
        backgroundColor: "#fff",
        borderRadius: 8,
        padding: 25,
        shadowColor: "none",


        shadowRadius: 4,

      },
      centeredView: {
        flex: 1,
        position:"relative",
        justifyContent: "center",
        alignItems: "center",
        width:'100%',
        height:'100%',
        overflow: !isMobileView() && "hidden",
        alignSelf:'center',
        backdropFilter: 'blur(20px)',
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
      },
      centeredViewError: {
        flex: 1,
        backdropFilter: 'blur(20px)',
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        justifyContent: "center",
        alignItems: "center",
      },
});
