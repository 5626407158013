import React from "react";
import { StyleSheet, Text, View, Image, TextInput, FlatList,TouchableOpacity } from "react-native";
import {
    responsiveHeight,
    responsiveWidth,
    responsiveFontSize
  } from "react-native-responsive-dimensions";
import AppLoading from "expo-app-loading";
import { useFonts } from "expo-font";
import commonStyles from "../styles/styles";
import { useNavigation } from "@react-navigation/native";
import Hoverable from "react-native-hoverable";
import { Color, isMobileView, isTabletView } from "./Constants";

const SecondaryHeaderdiv = (props) => {
    const {titleText,name,screen,backButton,navigation,messageShow} = props
    let [fontsLoaded] = useFonts({
        'openSans' : require('../../assets/fonts/OpenSans-VariableFont_wdth,wght.ttf')
      })
      if(!fontsLoaded){
        return <AppLoading/>

      }
      // const navigation = useNavigation();

      const handleBackPress = () => {
        // Navigate back to the previous screen
        navigation.navigate(screen);
      };
  return (

    <View style={styles.membersSearchArea}>
    <View style={[styles.membersSearchAreaContainer]}>
    <View style={{flexDirection:'row',borderWidth:0,alignItems:'center'}}>

    {/* { backButton &&    <TouchableOpacity onPress={handleBackPress}>
            <Image style={{height:'60%',width:'60%',top:2,borderWidth:0,padding:10,resizeMode:'contain'}} source={require('../../assets/images/back.png')}/>
          </TouchableOpacity>} */}
        <Text style={{fontWeight:'600',fontSize: isTabletView() ? responsiveFontSize(2.6) :  responsiveFontSize(2.2) ,position:"relative"}}>{titleText}</Text>
        </View>
       {titleText=='Magazine' && <View style={[commonStyles.gap1rem, commonStyles.flexRow]}>
            <Hoverable>
              {({ hovered }) => (
                <TouchableOpacity
                  style={{
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    flexDirection: "row",
                    borderWidth: 2,
                    borderRadius: 10,
                    borderColor: Color.BLUE_MEDIUM,
                    height: 40,
                    width: 120,
                    backgroundColor: hovered ? Color.BLUE_MEDIUM : "white",
                  }}
                  onPress={() => {
                    navigation.goBack();
                  }}
                >
                  <Image
                    style={{
                      height: 25,
                      width: 25,
                      resizeMode: "contain",
                      borderWidth: 0,
                      transform: [{ rotate: "180deg" }],
                    }}
                    source={
                      hovered
                        ? require("../../assets/images/goBackWhite.png")
                        : require("../../assets/images/goBack.png")
                    }
                  />
                  <Text
                    style={{
                      fontFamily: "openSans",
                      fontWeight: "600",
                      fontSize: 15,
                      color: hovered ? "white" : Color.BLUE_MEDIUM,
                    }}
                  >
                    Go Back
                  </Text>
                </TouchableOpacity>
              )}
            </Hoverable>
          </View>}
          {
            messageShow && <View style={[commonStyles.gap1rem, commonStyles.flexRow,{
              flexDirection: 'row',
              width: isMobileView() ? "100%" : '37%',
              borderWidth: 0,
              borderColor: Color.BLUE_MEDIUM,
              paddingVertical: 12,
              paddingHorizontal:15 ,
              borderRadius: 10,
              left: '3%',
              shadowColor: 'rgba(99, 99, 99, 0.2)',
              shadowOffset: {
                width: 0,
                height: 2,
              },
              shadowRadius: 8,
              shadowOpacity: 1,
              elevation: 5, // This is for Android shadow
              flexDirection:"row",
              alignItems:"center"
              }]}>

                  <View

                  style={
                    {
                      alignItems: isMobileView() ? 'flex-start' : "center",
                      justifyContent:"center",
                      flexDirection:"column",
                      gap:5
                    }
                  }>

                  <Text
              style={
                [styles.messageText,{
                  fontSize:16
                }]
              }
              >
              🌟 Dhan Nirankar Ji! 🌟

              </Text>
              <Text
                 style={
                  [styles.messageText,{
                    color: Color.GREEN,
                    width: isMobileView() && "52%"
                  }]
                }
              >

              Add up to 3 magazines to your cart and save! Subscribe in combo today. 📚✨
              </Text>
              </View>

               </View>
          }
    </View>
  </View>
  )
}

export default SecondaryHeaderdiv

const styles = StyleSheet.create({
    container: {
        flex: 1,
        width: "100%",
        height: "100%",
    },
    membersSearchArea:{
        // flex:1,
        borderBottomWidth:1,
        borderColor:'rgba(0, 0, 0, 0.15)',
        justifyContent:'center',
        paddingVertical: 18,
        alignItems:'center',
        width:'100%'
    },
    membersSearchAreaContainer:{
        borderWidth:0,
        flexDirection:'row',
        justifyContent:'space-between',
        alignItems:'center',
        width: isMobileView() ? "90%" : "96%",
        bottom: isMobileView() ? 3 : 0,

    },
    memberContainer:{
        // flex:,
        borderWidth:0
    },
    subscribedNextPrev:{
        borderWidth:0,
        marginHorizontal:50,
        margin:20,
        flexDirection:"row",
        justifyContent:'space-between',
        alignItems:'center'
    },
    messageText:{
      textAlign: isMobileView() ? "left" : "center",
      fontWeight:"500"
    }
})
