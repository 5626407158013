import { StyleSheet, Text, View, Image,FlatList,TextInput, ScrollView } from "react-native";
import React, { useState,useEffect, useContext, useRef, } from "react";
import {
    responsiveHeight,
    responsiveWidth,
    responsiveFontSize
  } from "react-native-responsive-dimensions";
import AppLoading from "expo-app-loading";
import { useFonts } from "expo-font";
import MemberCard from "../Common/MemberCard";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { TouchableOpacity } from "react-native";
import { Toast } from "react-native-toast-message/lib/src/Toast";
import DataNotFound from "../Common/DataNotFound";
import { Modal } from "react-native-web";
import { AuthContext } from "../context/AuthContext";
import { Value } from "react-native-reanimated";
import { Color, isLargeLaptop, isMobileView } from "../Common/Constants";
import Hoverable from "react-native-hoverable";
import Headerdiv from "../Common/Headerdiv";
import commonStyles from "../styles/styles";
import { apiServices } from "../services/apiServices";
import ErrorContext from "../context/ErrorContext";
let USERCOUNT
let memberData = []
import { Cell, Row, Table, TableWrapper } from "react-native-table-component";


const PatrikaSahayak = ({navigation}) => {
  const { getMembersList,signOut,updateMemberToken ,setMemeberCode } = useContext(AuthContext);
  const changeTokenFlagRef = useRef(false);
  const [member,setMember] = useState({})
  const [memberSearchedName,setMemberSearchName] = useState('')
  const [memberSearchedCode,setMemberSearchCode] = useState('')
  const [scode,setScode] = useState(null)
  const [mcode,setMcode] = useState(null)
  const [searchedMemberList,setSearchedMembers] = useState([])
  const [tokenFlag,setTokenFlag] = useState(true)
  const {error, dispatchError,dispatchSuccess} = useContext(ErrorContext);
  const [flag,setFlag] = useState(false)
  const [functionDataCounter,setFunctionDataCounter] = useState(1)
  const {searchPatrikaMembers,viewProfile} = apiServices
  const tableHead = ["Subscriber Code", "Subscriber Name", "Address",  "Action"];
  // const updateMemberList =(data)=>{
  //   const value = [...data].sort((a, b) => {
  //     if (a.verify_status === "verified" && b.verify_status !== "verified") {
  //       return -1; // 'a' comes before 'b'
  //     } else if (a.verify_status !== "verified" && b.verify_status === "verified") {
  //       return 1; // 'b' comes before 'a'
  //     } else {
  //       return 0; // Order remains unchanged
  //     }
  //   });
  //   setMemberList(value)
  //   USERCOUNT = value.length
  // }

  const  searchMember= async()=>{

      try {

          let searchedMembers = await searchPatrikaMembers(member.member_code ,member.branch,memberSearchedName,memberSearchedCode);
          if(typeof(searchedMembers)=='object'){
          setSearchedMembers(searchedMembers)
          }else{
            setSearchedMembers([])
          }
          console.log(searchedMembers)

      } catch (error) {
        dispatchError(error);
      }

}

  // const sortedMemberList =

    const renderMemberCards = ({ item }) => {

        if (!item) {
          return null;
        }

        return (
          <MemberCard
            member={item}
            key={item?.member_id}
            nav={navigation}
            //updateMemberList={updateMemberList}
            patrikaFlag={true}
          />
        );
      };
      // const changeMemberToken=async()=>{
      //     let token = AsyncStorage.getItem('super_member_token')
      //     updateMemberToken(token)
      // }
      const updateToken= async()=> {
        let token = await AsyncStorage.getItem('super_member_token');
        await AsyncStorage.setItem('memberToken', token)
        setFunctionDataCounter(functionDataCounter+1)

      }
      const viewMember = async()=>{
        try {
          let memberCode = await AsyncStorage.getItem('super_member_code');
          let chekingData = await AsyncStorage.getItem('member_code');
          setScode(memberCode)
          setMcode(chekingData)
        const value =  await viewProfile(memberCode);
          if(typeof(value)=='object')
             setMember(value)
        let searchedMembers = await searchPatrikaMembers(value.member_code ,value.branch,memberSearchedName,memberSearchedCode);
        if(typeof(searchedMembers)=='object')
            setSearchedMembers(searchedMembers)
        } catch (error) {
          dispatchError(error);
        }
      }
      const memberTokenSetWithCondition = async(rowData)=>{     
        //  await AsyncStorage.setItem('super_member_code' ,rowData?.member_code)
        //  await AsyncStorage.setItem('super_member_token',rowData?.token) 
       await updateMemberToken(rowData?.token)
      }
      useEffect(() => {
          viewMember()
        // updateToken();
      }, []);
      useEffect(() => {
        if(scode){
          if (scode===mcode){


            return;
          }
          else{
            navigation.navigate('Dashboard');

            dispatchError("You are not authorized to access this page")

          }
        }
        return(()=>{
          dispatchError('')
        })
      }, [scode,mcode]);







  let [fontsLoaded] = useFonts({
    'openSans' : require('../../assets/fonts/OpenSans-VariableFont_wdth,wght.ttf')
  })
  if(!fontsLoaded){
    return <AppLoading/>
  }
  const viewButton = (rowData, index) => {

    return(
      <TouchableOpacity 
        onPress={() => {
            setTimeout(async () => {
              setMemeberCode(rowData?.member_code)
              await AsyncStorage.setItem('memberToken' ,rowData?.token)
              memberTokenSetWithCondition(rowData)
              navigation.navigate("MagzinepageStack", { screen:'Home', params:{ memberCode: rowData?.member_code}});
            }, 1000);
          }}      
      style={{
        margin: 6,
         marginLeft:15,

        cursor:"pointer",
        borderRadius:50,

      }}
      >
      <Text style={[styles.text,{color:Color.BLUE_MEDIUM,bottom:2,position:"relative",margin:0}]}>Proceed</Text>

    </TouchableOpacity>

    )};




    return (
        <View style={styles.container}>
            {/* <Headerdiv/> */}
            <Headerdiv />
            <View style={styles.selectMember}>
                <Text style={{fontFamily:'openSans',fontWeight:'600',fontSize:responsiveFontSize(2.2)}}>Patrika Sahayak</Text>
                <Hoverable>
{({ hovered }) => (
        <TouchableOpacity
          style={{
            justifyContent: !isMobileView() && "space-evenly",
            gap: isMobileView() &&  10,
            paddingLeft: isMobileView() &&  10,
            alignItems: "center",
            flexDirection: "row",
            borderWidth: 2,
            borderRadius: 10,
            borderColor: Color.BLUE_MEDIUM,
            height: 40,
            width:  120,
            backgroundColor: hovered ? Color.BLUE_MEDIUM : "white",
          }}
          onPress={() => { navigation.navigate('Dashboard');
          }}
        >
          <Image
            style={{       height: 25,
              width: 25,
              resizeMode: "contain",
              borderWidth: 0,
              transform: [{ rotate: "180deg" }]
            }}
            source={ hovered ? require("../../assets/images/goBackWhite.png") : require("../../assets/images/goBack.png")}
          />
          <Text
            style={{
              fontFamily: "openSans",
              fontWeight: "600",
              fontSize: 15,
              color:  hovered ?  "white" : Color.BLUE_MEDIUM,
            }}
          >
            Go Back
          </Text>
        </TouchableOpacity>
             )}
             </Hoverable>
                {/* <Hoverable>
{({ hovered }) => (
                <TouchableOpacity
          onPress={() => signOut()

          }
          style={{
            justifyContent: "space-evenly",
            alignItems: "center",
            flexDirection: "row",
            borderRadius: 10,
            borderColor: Color.BLUE_MEDIUM,
            height: 40,
            width: 110,
            backgroundColor: hovered ? Color.BLACK : Color.BLUE_MEDIUM,
          }}
        >
          <Image
            style={{
              height: 25,
              width: 25,
              resizeMode: "contain",
              borderWidth: 0,
            }}
            source={  require("../../assets/images/signOutWhite.png") }
          />
          <Text
            style={{
              fontFamily: "openSans",
              fontWeight: "600",
              fontSize: 15,
              color:    "white" ,
            }}
          >
            Logout
          </Text>
        </TouchableOpacity>
             )}
             </Hoverable> */}
            </View>
            <View style={{ backgroundColor: "#fff", width: "100%", height: "75%" }}>
                <View style={{borderWidth:0,paddingHorizontal:35,paddingVertical:20,paddingBottom:10,flexDirection:'colmun',gap:5}}>
                   <View style={{flexDirection:'row',gap:20}}>

                    <Text style={{fontFamily:'openSans',fontWeight:'400',fontSize: isMobileView() ? 18 : responsiveFontSize(1.9)}}>
                    Registered Members</Text>

                    {/* <View style={{backgroundColor:'rgba(230, 232, 234, 1)',paddingVertical:10,paddingHorizontal:20,borderRadius:10}}>

                        <Text>{USERCOUNT}</Text>
                    </View> */}
                   </View>

                    <Text style={{fontFamily:'openSans',fontStyle:"italic",fontWeight:'400',fontSize:  isMobileView() ? 14 : responsiveFontSize(1)}}>
                    Linked with your branch</Text>

<View style={[ !isMobileView() ? commonStyles.flexRow : commonStyles.flexCol,commonStyles.alignCenter, !isMobileView() ? commonStyles.gap10px : commonStyles.gap1rem,commonStyles.mt_16px,commonStyles.justifyCenter]}>

<View style={[commonStyles.flexRow,commonStyles.alignCenter,{
    outlineStyle: "none", // Remove the border
    width:  isMobileView() ? "100%" : 300,
    height:  !isMobileView() && "100%",
    gap: isMobileView() && 7,
    backgroundColor: Color.GREY_LIGHT,
    alignItems: "center",
    borderRadius: 10,
    borderWidth:2,
    borderColor: 'rgba(0,0,0,0.05)',
    padding:10
  }]}>
             <Image   style={{
              height: isMobileView() ? 20 : responsiveHeight(3),
              width: isMobileView() ? 20 : responsiveWidth(3),
              resizeMode: 'contain',
            }}
            source={require('../../assets/images/searchicon.png') }/>
                    <TextInput
  onChangeText={(text) => {
    setMemberSearchName(text)
  }}

  placeholderTextColor={"rgba(0, 0, 0, 0.30)"}
  placeholder="Search Member With Name"
  style={[{
    outlineStyle: "none", // Remove the border
    height:  !isMobileView() && "100%",
    width: "100%",
    borderWidth:0,

    alignItems: "center",
    justifyContent: "space-between",
    fontWeight:"600",
    fontSize: isMobileView()  && responsiveFontSize(2.2),

  }]}
/>
</View>

<View style={[commonStyles.flexRow,commonStyles.alignCenter,{
    outlineStyle: "none", // Remove the border
    width: isMobileView() ? "100%" :  160,
    height:  !isMobileView() && "100%",
    borderWidth:2,
    borderColor: 'rgba(0,0,0,0.05)',
    backgroundColor: Color.GREY_LIGHT,
    alignItems: "center",
    borderRadius: 10,

    padding:10
  }]}>
<TextInput
  onChangeText={(text) => {
    setMemberSearchCode(text)
  }}
  placeholderTextColor={"rgba(0, 0, 0, 0.30)"}
  placeholder="Enter Member Code"
  style={[{
    outlineStyle: "none", // Remove the border
    height: "100%",
    width: "100%",
    borderWidth:0,

    alignItems: "center",
    justifyContent: "space-between",
    fontWeight:"600",
    fontSize: isMobileView()  && responsiveFontSize(2.2),
  }]}
/>
</View>
<Hoverable style={{width: isMobileView() && "100%"}}>
{({ hovered }) => (
                <TouchableOpacity
          onPress={() => searchMember()

          }
          style={{
            justifyContent: "space-evenly",
            alignItems: "center",
            flexDirection: "row",
            borderRadius: 10,
            borderColor: Color.BLUE_MEDIUM,
            height: 45,
            width: isMobileView() ? "100%" : 160,
            backgroundColor: hovered ? Color.BLACK : Color.BLUE_MEDIUM,
          }}
        >


          <Text
            style={{
              fontFamily: "openSans",
              fontWeight: "600",
              fontSize: isMobileView() ? 16 : 15,
              color:    "white" ,
            }}
          >
            Search
          </Text>
        </TouchableOpacity>
             )}
             </Hoverable>
</View>

                </View>
                        <View style={[
              commonStyles.spaceLeftPrimary,
              commonStyles.spaceRightPrimary,
              {
                borderWidth: 0,
                // alignSelf: "center",
                // marginVertical: 10,
                marginBottom: 10,
                marginTop:20,
                paddingRight:0
              },

            ]}>

                            {
                            searchedMemberList && searchedMemberList?.length > 0?
                            // <FlatList
                            // numColumns={5}
                            // keyExtractor={(item, index) => item?.member_id.toString()} // Convert the key to a string
                            // data={searchedMemberList || []} // Provide a default empty array if memberList is undefined or null
                            // renderItem={renderMemberCards}
                            // columnWrapperStyle={{ flex: 1,gap: 20,     flexDirection: isMobileView() && "column", alignCenter:"center" }}
                            // contentContainerStyle={{
                            //     borderWidth: 0,
                            //     padding: 15,
                            //     alignSelf: 'stretch',

                            //     // justifyContent: 'space-between',
                            //     gap: 20,
                            //   }}

                            // />
<ScrollView contentContainerStyle={{width:"100%"}}  style={{width:"100%"}} horizontal={!isLargeLaptop() && true      }>

          <Table style={{width: isMobileView() ? "135%" : "100%"}} borderStyle={{ borderWidth: 0, borderColor: '#c8e1ff' }}>

        <Row data={tableHead}   flexArr={[1,1,1.5,1]} style={styles.head} textStyle={styles.textHead} />
        {searchedMemberList &&searchedMemberList.length>0 && searchedMemberList?.map((member, index) => (
      <TableWrapper key={index} style={styles.tableRow}>

        <Cell
          data={member?.member_id}
          textStyle={styles.text}
          style={{ flex: 1, justifyContent: 'center', alignItems: 'flex-start' }}
        />
        <Cell
          data={member?.member_name}
          textStyle={styles.text}
          style={{ flex: 1, justifyContent: 'center', alignItems: 'flex-start' }}
        />
        <Cell
          data={`${member?.address?.adr_line1},${member?.address?.adr_line2},${member?.address?.district_name},${member?.address?.state_name},${member?.address?.pincode}`}
          textStyle={styles.text}
          style={{ flex: 1.5, justifyContent: 'center', alignItems: 'flex-start' }}
        />
         <Cell
        key={index}
        data={viewButton(member, index)}
        textStyle={styles.text}
        style={{ flex: 1, justifyContent: 'center',  }}
      />

      </TableWrapper>
      //  <FilteredRow key={index} rowData={rowData} index={index} />
    ))}
          {/* </TableWrapper> */}
        {/* ))} */}
      </Table>
      </ScrollView>

                        :

                            <DataNotFound height={35} width={35} fontSize={1.50} text={"Members Not Available"} textColor={"#000"} />
                        }
                        </View>


            </View>
            {/* <Modal
      animationType="fade"
      transparent={true}
      visible={modalVisible}
      onRequestClose={() => {
        Alert.alert('Modal has been closed.');
        setModalVisible(false);
      }}
    >
      <View style={styles.centeredView}>
        <View style={[styles.modalView,{justifyContent: unlinkSuccessful ?  "space-evenly": "space-between" }]}>
          <Image
            style={{
              height: responsiveHeight(15),
              width: responsiveWidth(15),
              resizeMode: 'contain',
            }}
            source={unlinkSuccessful ? require('../../assets/images/unlinkPopupDone.png') : require('../../assets/images/unlinkPopupIcon.png')}
          />

          {unlinkSuccessful ? (
            <Text style={{ fontFamily: 'openSans', fontWeight: '650', fontSize: responsiveFontSize(1.5), paddingHorizontal: 9, textAlign: 'center' }}>
              Unlink Successful
            </Text>
          ) : (
            <Text style={{ fontFamily: 'openSans', fontWeight: '600', fontSize: responsiveFontSize(1.2), paddingHorizontal: 9, textAlign: 'center' }}>
              Please click the 'Unlink' button to unlink{' '}
              <Text style={{ color: '#5C42DA' }}>Rahul Sharma</Text> from your mobile number
            </Text>
          )}
    { !unlinkSuccessful && (
          <View style={{ display: 'flex', flexDirection: 'row', gap: 20 }}>
            <TouchableOpacity
              style={{
                borderWidth: 2,
                borderRadius: 7,
                backgroundColor: '#fff',
                width: responsiveWidth(10),
                height: responsiveHeight(5),
                alignItems: 'center',
                justifyContent: 'center',
                borderColor: 'rgba(0, 0, 0, 0.15)',
              }}
              onPress={() => setModalVisible(false)}
            >
              <Text style={{ fontFamily: 'openSans', color: '#000', fontWeight: '600', fontSize: 15, textAlign: 'right' }}>Cancel </Text>
            </TouchableOpacity>

            <TouchableOpacity
              style={{
                borderWidth: 2,
                padding: 10,
                borderRadius: 7,
                backgroundColor: '#F24D14',
                width: responsiveWidth(10),
                height: responsiveHeight(5),
                alignItems: 'center',
                justifyContent: 'center',
                borderColor: '#F24D14',
              }}
              onPress={handleUnlinkClick}
            >
              <Text style={{ fontFamily: 'openSans', color: '#fff', fontWeight: '600', fontSize: 15, textAlign: 'right' }}>Unlink </Text>
            </TouchableOpacity>
          </View>
    )}
        </View>
      </View>
    </Modal> */}
        </View>
    );
};

export default PatrikaSahayak;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        width: "100%",
        height: "100%",
        overflow: 'hidden',
    },
    centeredView: {
      flex: 1,
      backdropFilter: 'blur(20px)',
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
      justifyContent: "center",
      alignItems: "center",
    },
    modalView: {
      borderWidth: 0,
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-between",
      width: responsiveWidth(30),
      height: responsiveHeight(50),
      margin: 5,
      backgroundColor: "#fff",
      borderRadius: 8,
      padding: 25,
      shadowColor: "none",


      shadowRadius: 4,

    },

    upperContainer: {
        backgroundColor: "#F8F9FA",
        width: "100%",
        height: responsiveHeight(10),
        justifyContent:'center',
        // alignItems:'center'
    },
    userProfileNotification:{
        borderWidth:0,
        height:'100%',
        justifyContent:'space-between',
        alignItems:'center',
        flexDirection:'row',
        padding:20
    },
    userProfileImage:{
        width:45,
        height:40,
        borderWidth:0,
        margin:20
    },
    selectMember:{
        backgroundColor: "#FFF",
        alignItems:"center",
        width: "100%",
        // height: "12%",
        padding:18,
        paddingHorizontal:35,
        justifyContent:'center',
        borderBottomWidth:2,
        borderColor:'rgba(0, 0, 0, 0.15)',
        flexDirection:'row',
        justifyContent:'space-between'
    },
    memberArea:{
        borderWidth:0,
        width:'100%',
        paddingHorizontal:20,
        alignSelf:"center",
        alignItems:'flex-start',
        height:'88%',
        justifyContent:'space-evenly',
        alignItems:'flex-start',
        flexDirection:'row'},
        tableRow: {
          borderWidth: 1,
          borderColor: "rgba(0, 0, 0, 0.15)",
          marginVertical: 8,
          borderRadius: 6,
          height: responsiveHeight(12),
          flexDirection: "row",
          justifyContent:"space-between"
        },
        text: { margin: 6, marginLeft:15,textWrap:"wrap", fontWeight:600,fontSize:isMobileView() ? responsiveFontSize(2) : responsiveFontSize(1.1) },
        textHead: {
          margin: 6,
          marginLeft: 15,
          fontWeight: 500,
          fontSize: isMobileView() ? responsiveFontSize(2) : responsiveFontSize(1.1),
          color: "rgba(0, 0, 0, 0.60)",
        },
});
