import React, { useEffect, useState, useRef, useContext } from "react";
import {
  StyleSheet,
  Text,
  View,
  Image,
  TextInput,
  FlatList,
  TouchableOpacity,
  ScrollView,
  SafeAreaView,
  ActivityIndicator,
  Modal,
  Alert,
} from "react-native";
import Headerdiv from "../Common/Headerdiv";
import {
  responsiveHeight,
  responsiveWidth,
  responsiveFontSize,
  responsiveScreenHeight,
} from "react-native-responsive-dimensions";
import AppLoading from "expo-app-loading";
import { useFonts } from "expo-font";
import MagzineCards from "../Common/MagzineCards";
import MagzineFlatCard from "../Common/MagzineFlatCard";
import { apiServices } from "../services/apiServices";
import AsyncStorage from "@react-native-async-storage/async-storage";
import ErrorContext from "../context/ErrorContext";
import PopupComponent from "../Common/PopupComponent";
import commonStyles from "../styles/styles";
import Hoverable from "react-native-hoverable";
import DataNotFound from "../Common/DataNotFound";
import LanguageComponent from "../Common/LanguageComponent";
import { Color, isMobileView, isOnlyLaptopView, isOnlyTabletView, isSmallLaptop, isTabletView } from "../Common/Constants";
import SecondaryHeaderdiv from "../Common/SecondaryHeaderdiv";
import { Cell, Row, Table, TableWrapper } from "react-native-table-component";
import { is } from "date-fns/locale";
import SelectDropdown from "react-native-select-dropdown";
import BuyCardComponent from "../Common/BuyCardComponent";
import BuyModal from "../Common/BuyModal";
import InsetShadow from 'react-native-inset-shadow'
import { AuthContext } from "../context/AuthContext";
import {API_URL} from "@env"
// BuyCardComponent
let tableData
var magazineData;
var subscribedMagazinesData;
let flag = false;
let amountTotal;
let chargesData;
let todaysDate
let calculatedEndMonth
const SelectionMagazine = ({ route, navigation }) => {
  // const { memberCode } = route.params;
  // For modal
  const { updateCartTotal } = useContext(AuthContext);
  const [quantity,setQuantity] =useState(1)
  const [selectedBuyMag,setSelectedBuyMag] =useState()
  const [selectedAddress,setSelectedAddress] =useState(null)
  const [selectedFullAddress,setSelectedFullAddress] =useState(null)
  const [totalAmoutState, setTotalAmountState] = useState(null);
  const [chargeAmoutState, setChargeAmountState] = useState(0);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [amount, setAmount] = useState();
  const [endDate, setEndDate] = useState();
  const [thankYouPage, setThankYouPage] = useState(false);
  const [cartReplacePopUp, setCartReplacePopUp] = useState(false);
  const [erroMessage,setErrorMessage] = useState(null)
  const [validationCurrency,setValidationCurrency] = useState(null)
  const [validationQuantity,setValidationQuantity] = useState(null)
  const [validationPlan,setValidationPlan] = useState(null)


  const [memberCode,setMemberCode] =useState(null)
  const [changeFlag,setChangeFlag] =useState(false)
  const [SubscriptionsData,setsubscriptionsData] =useState([])
  const [magazineLimit,setMagazineLimit] =useState([])
  const [endDateData,setEndDateData] =useState('')
  const [isclicked, setIsClicked] = useState(false);
  const [selectedMag, setSelectedMag] = useState(false);
  const [bulkPurchase, setBulkpurchase] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [defaultAddress, setDefaultAddress] = useState(false);
  const [defaultAddressText, setDefaultAddressText] = useState('');


  const { viewOtherMagzines, viewSubscribedMagazines,getAddress,viewProfile,
    getSubscriptionType,addToCart,postAmountGet,getCourierChargesAPI
    ,getCurrencyData,postAddUnpainInvoicesAPI,replaceBulkCart,replaceSubscriptionCart,
    getQuantityList,rateChartMagazineCheck,getComboParameters} = apiServices;
  const [viewMagazineData, setViewMagazineData] = React.useState([]);
  const [subscribedMagazines, setSubscribedMagazines] = React.useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [itemHeight, setItemHeight] = useState(0);
  const [prevImage, setPrevImage] = useState(require('../../assets/images/prev.png'))
  const [memberAddress, setMemberAddress] = useState({})
  const [profileData, setProfileData] = useState("");
  const [loader, setLoader] = useState(7)
  const [selectedCurrency, setSelectedCurrency] = useState(null)
  const [magazineFilteredData, setMagazineFilteredData] = useState([])
  const [currencyData,setCurrencyData] = useState([])
  const [lengthMagazine,setLengthMagazine] = useState()
  const [charges,setCharges] = useState(null)
  const [lengthOfDataMagazines,setLengthOfDataMagazines] = useState([])
  const [comboParameters,setComboParameters] = useState({})
  const {error, dispatchError,dispatchSuccess} = useContext(ErrorContext);

  const closeModal = () => {
    setModalVisible(false);
    setBulkpurchase(!bulkPurchase)
    updateQuantity(1);
  };
  const updateQuantity = (newQuantity) => {
    setQuantity(newQuantity);
  };
  const handleToggleImage = () => {
    setIsClicked(!isclicked);
  };
  const [selectedButton, setSelectedButton] = useState();

  const handleButtonPress = (buttonIndex) => {
    setSelectedButton(buttonIndex);
  };

  const getMemberCodeX = async() =>{
    let getMemberCode
    try {
        getMemberCode = await AsyncStorage.getItem('member_code')
        setMemberCode(getMemberCode)
        getComboParameterMagazine(getMemberCode)
         setLoader(prev => {
            return prev !== 0 ? prev - 1 : 0;
          })
      } catch (error) {
        dispatchError(error);
        setLoader(prev => {
            return prev !== 0 ? prev - 1 : 0;
          })
      }
    }

   const getComboParameterMagazine = async(memberCode)=>{
        try {
          let comboPameterData = await getComboParameters(memberCode);
          setComboParameters(comboPameterData)
        } catch (error) {
          dispatchError(error)
        }
    }
    useEffect(()=>{
    getMemberCodeX()
  },[])

  const getQuantityListForSubscribeMagazine = async()=>{
    try{
      const quantityList = await getQuantityList(memberCode);
      setMagazineLimit(quantityList.quantity)
      setLoader(prev => {
        return prev !== 0 ? prev - 1 : 0;
      })
    }catch(error)
    {
      dispatchError(error)
      setLoader(prev => {
        return prev !== 0 ? prev - 1 : 0;
      })
    }
  }
  // useEffect(()=>{
  //   if(parseInt(amount?.amount) && parseInt(quantity)){
  //     amountTotal = (parseInt(quantity) * amount?.amount)
  //     setTotalAmountState(amountTotal)
  //   }
  //   else{
  //     setQuantity(1)
  //   }



  // },[quantity,amount,charges])
  // useEffect(() => {
  //   if (charges !== null) { // Check for null
  //     if(quantity<50){
  //       setCharges(0)

  //     }
  //       setChargeAmountState(parseInt(charges)); // Parse the charges to ensure it's a number
  //   }else{
  //     setCharges(0)
  //   }

  // }, [charges,changeFlag,quantity]);

  // useEffect(() => {
  //   if (amount) { // Check for null
  //     setEndDate(parseInt(endDateData))
  //   }else{
  //     setEndDate(null)

  //   }

  // }, [amount]);

  function getFormattedDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  function getTodayAndTwoMonthsLater() {
    const today = new Date();
    const twoMonthsLater = new Date(today);
    twoMonthsLater.setMonth(today.getMonth() + 2);

    const formattedToday = getFormattedDate(today);
    const formattedTwoMonthsLater = getFormattedDate(twoMonthsLater);

    return {
      today: formattedToday,
      twoMonthsLater: formattedTwoMonthsLater,
    };
  }

  useEffect(()=>{
    const { today, twoMonthsLater } = getTodayAndTwoMonthsLater();
    todaysDate = twoMonthsLater

  },[])
  // Usage
  // console.log('Today:', today);




  let targetIndex
  let flatRef = useRef(null);
  const tableHead = [  'Magazine - Language', 'Action']

 const [currentPage, setCurrentPage] = useState(1);
 const itemsPerPage = 5;

function ImagePathFunc(rowData){
  return(

      <Image
      source={ { uri:`${API_URL}`+'assets/img/magazine_images/'+`${rowData.image}`}}
      // source={rowData.image_url!==""?{uri:`${rowData.image_url}`}:'../../assets/images/ImageNotFound.png'}
        style={[!isMobileView() ? {width:'55%',height:'95%'} : {width:60,height:60,  resizeMode: "contain",}]}
      >
      </Image>

  )

}


 function calculateDateFromMonths(months,todaysDate) {
  // Starting date
  const startDate = new Date(todaysDate);

  // Calculate the new date
  startDate.setMonth(startDate.getMonth() + months);

  // Format the date as 'YYYY-MM-DD'
  const year = startDate.getFullYear();
  const month = String(startDate.getMonth() + 1).padStart(2, '0'); // Adding 1 to month since it's 0-based
  const day = String(startDate.getDate()).padStart(2, '0');

  const formattedDate = `${year}-${month}-${day}`;

  return formattedDate;
}

// Example usage:
// useEffect(()=>{
//   if(endDateData){

//     calculatedEndMonth = calculateDateFromMonths(endDateData,todaysDate);

//   }
// },[endDateData])


const validationCheck = () =>{

  // if (selectedCurrency  ===null){
  //   setValidationCurrency(true)
  // }
  if (quantity  ===null){
    setValidationQuantity(true)
  }
  // if (selectedPlan===undefined||selectedPlan===null){
  //   setValidationPlan(true)
  // }
  if(defaultAddress===false||defaultAddress===null){
    setTimeout(() => {
      setDefaultAddressText('Please set a default address from profile page!')
    }, 200);
  }
}

const getSubscribeItem = async () => {
  try{
    let updateData
    if(bulkPurchase){
      updateData= {member_code:memberCode,
        amount:totalAmoutState+charges,
        magazine:selectedBuyMag.magazine_id,
        member_address:selectedAddress,
        // start_date:`${todaysDate}`,
        // end_date:calculatedEndMonth,
        quantity:parseInt(quantity),
        oldnew:'new',
        bulk_purchase:bulkPurchase,
        currency : selectedCurrency,
        dispatch_mode:'Courier',
        dispatch_type:'Personal',
        dispatch_to:selectedAddress
      }

    }else{
      updateData= {member_code:memberCode,
        amount:totalAmoutState+charges,
        magazine:selectedBuyMag.magazine_id,
        member_address:selectedAddress,
        // start_date:`${todaysDate}`,
        // end_date:calculatedEndMonth,
        quantity:parseInt(quantity),
        oldnew:'new',
        currency : selectedCurrency,
        dispatch_mode:'Courier',
        dispatch_type:'Personal',
        dispatch_to:selectedAddress

      }

        // subscription_type,currency,dispatch_type,dispatch_mode,dispatch_to,member_address,member_receiving,start_date,end_date,oldnew,quantity )

      }
     let response= await addToCart(
        memberCode, updateData
      );
     // updateCartTotal(response.carts?.length)
      setModalVisible(!modalVisible);
      dispatchSuccess("Magazine added to cart successfully!!")
      // setThankYouPage(true);
      // setTimeout(() => {
      //   setThankYouPage(false);

      // }, 2000);
      }catch(error){

        if(error.response.data.errors.includes('Want to replace them?'))
        {
          setModalVisible(!modalVisible);
          setErrorMessage(error.response.data.errors)
          setCartReplacePopUp(!cartReplacePopUp);
        }else{
        setModalVisible(!modalVisible);
          setErrorMessage(error.response.data.errors)
          dispatchError(error)
        }
      }
};
  const replaceItems = async()=>{
    try{
      if(bulkPurchase)
      {
        let response = await replaceSubscriptionCart(memberCode)
        setCartReplacePopUp(false);
        setModalVisible(!modalVisible);
        //setThankYouPage(!thankYouPage)
        dispatchSuccess(response.message)
      }else{
        await replaceBulkCart(memberCode)
        setCartReplacePopUp(false);
        setModalVisible(!modalVisible);
        //setThankYouPage(!thankYouPage)
        dispatchSuccess(response.message)
      }

    }catch(error)
    {

    }
  }

 const postAddUnpainInvoices = async() =>{
  let updateData = {
    "carts" : [selectedBuyMag.magazine_id]
  }
  await postAddUnpainInvoicesAPI(memberCode,updateData)
 }
 const totalPages = Math.ceil(tableData?.length / itemsPerPage);

 const startIndex = (currentPage - 1) * itemsPerPage;
   const endIndex = startIndex + itemsPerPage;
   const currentPageData = tableData?.slice(startIndex, endIndex);


   // Function to handle the "next" button click
   const handleNextPage = () => {
     setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
   };

   // Function to handle the "previous" button click
   const handlePreviousPage = () => {
     setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
   };

   // Function to handle clicking on a specific page number
   const handlePageClick = (pageNumber) => {
     setCurrentPage(pageNumber);
   };
   var pageNumbers = [];

   // Generate an array of page numbers to display in pagination
   const getPageNumbers = () => {
     const range = 1; // Number of page numbers to show before and after the current page

     // Calculate the range of page numbers to show
     let start = Math.max(currentPage - range, 1);
     let end = Math.min(currentPage + range, totalPages);

     // Adjust the range if the current page is near the start or end
     const maxRange = 2 * range + 1;
     if (end - start < maxRange) {
       if (currentPage < totalPages / 2) {
         end = Math.min(end + maxRange - (end - start), totalPages);
       } else {
         start = Math.max(start - maxRange + (end - start), 1);
       }
     }

     // Add page numbers to the array
     for (let i = start; i <= end; i++) {
       pageNumbers.push(i);
     }

     return pageNumbers;
   }

   const renderDropdownIcon = ({ isOpen }) => (
    <View
      style={{
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Image
                style={{
                  height: 22,
                  width: 22,

                  resizeMode:'contain',

                }}
                source={ require( "../../assets/images/dropDown.png") }
              />
    </View>
  );
   const languageItem = (data, index) => {

    return(
      <View
      style={{
        flexDirection: "row",
        alignItems: "center",
        borderWidth: 0,
        height: "100%",
        width: "100%",
        margin: 6,
        marginLeft:15,
        gap: 15,

      }}
    >
          { ! isTabletView() &&
      <Image
        style={{
          height: responsiveHeight(4.2),
          width: responsiveWidth(2.2),
          resizeMode: "contain",
        }}
        source={require("../../assets/images/language.png")}
      />
   }
      <Text
        style={{
          fontFamily: "openSans",
          fontWeight: "600",
          fontSize: isMobileView() ? responsiveFontSize(1.9)  : responsiveFontSize(1.2),
        }}
      >
        English
      </Text>
    </View>
    )};
   const subscriptionItem = (data, index) => {

    return(
      <View
      style={{
        flexDirection: "row",
        alignItems: "center",
        borderWidth: 0,
        height: "100%",
        width: "100%",
        margin: 6,
        marginLeft:15,
        gap: 15,

      }}
    >
    { ! isTabletView() &&  <Image
        style={{
          height: responsiveHeight(4.2),
          width: responsiveWidth(2.2),
          resizeMode: "contain",
        }}
        source={require("../../assets/images/tag.png")}
      />}
      <Text
        style={{
          fontFamily: "openSans",
          fontWeight: "600",
          fontSize: isMobileView() ? responsiveFontSize(1.9)  : responsiveFontSize(1.2),
        }}
      >
        45 Rupees
      </Text>
    </View>
    )};

    const actionItem = (data, index) => {



    return(
      <Hoverable>
  {({ hovered }) => (
          <TouchableOpacity onPress={() =>
            // getSubscribeItem()
            setModalVisible(!modalVisible)
          } style={[{paddingVertical:2.5,paddingHorizontal:5,borderRadius:100,
            margin: 6,
            marginLeft:15,
            with:"fit-content",
          }]}>
            <Text style={{color:Color.BLUE_MEDIUM,fontWeight:'600',fontSize:  isMobileView() ? responsiveFontSize(1.9)  : responsiveFontSize(1.2)  }}>Add To Cart</Text>
          </TouchableOpacity>
          )}
          </Hoverable>

    )};





  const getAddressMember = async () => {
    try {
      var memberAddressDataGet = await getAddress(memberCode);
      setMemberAddress(memberAddressDataGet);
      for(let address of memberAddressDataGet)
      {
        if(address.member_default_adr)
        {
          setDefaultAddress(address.address_id)
          setSelectedAddress(address.address_id)
        }
      }
      setLoader(prev => {
        return prev !== 0 ? prev - 1 : 0;
      })
    } catch (error) {
      setLoader(prev => {
        return prev !== 0 ? prev - 1 : 0;
      })
       dispatchError(error);

    }
  };
  const getCurrencies = async () => {
    try {
      var data = await getCurrencyData(memberCode);
      setCurrencyData(data);

        setSelectedCurrency(data[0].currency_code)

      setLoader(prev => {
        return prev !== 0 ? prev - 1 : 0;
      })
    } catch (error) {
      setLoader(prev => {
        return prev !== 0 ? prev - 1 : 0;
      })
       dispatchError(error);

    }
  };
  const getSubscriptionTypeData = async () => {
    try {
      var subscriptionData = await getSubscriptionType(memberCode);
      setsubscriptionsData(subscriptionData);
      // if(subscriptionData.length==1)
      // {
        setSelectedPlan(subscriptionData[0].subscription_type_id)
     // }
      setLoader(prev => {
        return prev !== 0 ? prev - 1 : 0;
      })

    } catch (error) {
      setLoader(prev => {
        return prev !== 0 ? prev - 1 : 0;
      })
       dispatchError(error);


    }
  };
  const getAmount = async (item) => {
    try {
      let magData = {
        magazine_id:selectedBuyMag.magazine_id,
        subscription_type : item,
        currency : selectedCurrency
      }
      setLoader(1)
      var data = await postAmountGet(memberCode,magData);
      setAmount(data)

      setLoader(prev => {
        return prev !== 0 ? prev - 1 : 0;
      })

    } catch (error) {
      setLoader(prev => {
        return prev !== 0 ? prev - 1 : 0;
      })
      setModalVisible(!setModalVisible)
       dispatchError(error);


    }
  };

  const onPressBulkBuyButton= async(rowData)=>{
    try {
     
      const rateChartFlag = await rateChartMagazineCheck(memberCode,rowData.magazine_id)
      if(rateChartFlag.success){
      setSelectedBuyMag(rowData),setBulkpurchase(true),setModalVisible(!modalVisible)
      }else{
        dispatchError(rateChartFlag.msg)
      }
    } catch (error) {
      dispatchError(error)
    }
    
  }




  const getMagazine = async () => {
    try {
      magazineData = await viewOtherMagzines(memberCode);
      tableData = magazineData
      subscribedMagazinesData = await viewSubscribedMagazines(memberCode);
      if(typeof(subscribedMagazinesData)=='object'){
      setViewMagazineData(magazineData);
      }
      setSubscribedMagazines(subscribedMagazinesData);
       setLoader(prev => {
            return prev !== 0 ? prev - 1 : 0;
          })



    } catch (error) {
      setLoader(prev => {
            return prev !== 0 ? prev - 1 : 0;
          })
      dispatchError(error);
    }
  };

  const getBulkChargesData = async () => {
    let magId = memberCode;
    let quantityData = { quantity: parseInt(quantity) };

    try {
      setLoader(1);
      let data = await getCourierChargesAPI(magId, quantityData);
      setCharges(data.courier_charge); // Fixed typo here
      setLoader((prev) => (prev !== 0 ? prev - 1 : 0));
    } catch (error) {
      setLoader((prev) => (prev !== 0 ? prev - 1 : 0));
      dispatchError(error);
    }
  };

  const handleLayout = (event) => {
    const { height } = event.nativeEvent.layout;
    setItemHeight(height);
  };

  const scrollToIndex = (step) => {
    if (flatRef.current) {
      if (currentIndex + step == 0) {
        flag = !flag;
      }

      if (
        currentIndex + step >= 0 &&
        currentIndex + step <= viewMagazineData.length
      ) {
        targetIndex = currentIndex + step;
        flatRef.current.scrollToIndex({ index: targetIndex, animated: true });
        setCurrentIndex(targetIndex);
      }
    }
  };

  const refreshpage = async ()=>{
    try {
      subscribedMagazinesData = await viewSubscribedMagazines(memberCode);
      setSubscribedMagazines(subscribedMagazinesData);


    } catch (error) {
      dispatchError(error);
    }

  }
  const renderItem = ({ item }) => {
    return (
      <View onLayout={handleLayout}>
        <MagzineCards data={item} refreshpage={refreshpage} memberCode={memberCode} memberAddress={memberAddress} profileData={profileData} SubscriptionsData={SubscriptionsData}  />
      </View>
    );
  };
  const renderItemFlat = ({ item }) => {
    return <LanguageComponent data={item} memberCode={memberCode} navigation={navigation} memberAddress={memberAddress} profileData={profileData} SubscriptionsData={SubscriptionsData}/>;
  };

  const getMemberCode = async () => {
    try {
      let viewMember = await viewProfile(memberCode);
      setProfileData(viewMember);
      setLoader(prev => {
        return prev !== 0 ? prev - 1 : 0;
      })


    } catch (error) {
      setLoader(prev => {
        return prev !== 0 ? prev - 1 : 0;
      })
      dispatchError(error);

    }
  };
  const singlePackButton = (rowData, index) => {
    return(
      <Hoverable>
      {({ hovered }) => (
        <TouchableOpacity
          style={[
            styles.button,
            selectedButton === 0 && styles.selectedButton,
          ]}
          onPress={() => {setSelectedBuyMag(rowData),setModalVisible(!modalVisible),setBulkpurchase(false)}}
        >
          <Text
            style={[
              styles.languageButton,
              selectedButton === 0 || hovered
                ? styles.activeLanguageButton
                : null
            ]}
          >
            Add to cart
          </Text>
        </TouchableOpacity>
      )}
    </Hoverable>

    )};
  const bulkBuyButton = (rowData, index) => {

    return(
      <Hoverable>
      {({ hovered }) => (
        <TouchableOpacity
          style={[
            styles.button,
            // selectedButton === 1 && styles.selectedButton,
          ]}
          onPress={() => {onPressBulkBuyButton(rowData)}}
        >
          <Text
            style={[
              styles.languageButton,
              selectedButton || hovered
                ? styles.activeLanguageButton
                : null,
            ]}
          >
            Bulk Buy
          </Text>
        </TouchableOpacity>
      )}
    </Hoverable>

    )};
  const buttonsSaprator = (rowData, index) => {

    return(
      <View style={styles.Linecontainer}>
      <View style={styles.line} />
    </View>

    )};

    function funcUpdateValue (){
      setTimeout(()=>{setChangeFlag(!changeFlag)},[750])
    }

  useEffect(() => {
    setCurrentIndex(0);

  }, [flag]);

  useEffect(() => {
    setDefaultAddress(false)

    if (memberCode) {
      getMagazine();
      getMemberCode();
      getAddressMember();
      getCurrencies();
      getSubscriptionTypeData()
      getQuantityListForSubscribeMagazine();
    }
  }, [memberCode]);
  useEffect(() => {
    let filteredData1 = [];
    let filteredData2 = [];
    let filteredData3 = [];
    let filteredData4 = [];
    let magLength = [];

    const lowerCaseMagazineNames = viewMagazineData.map((magazine) =>
      magazine?.magazine_name.toLowerCase()
    );


      filteredData1 = viewMagazineData.filter((magazine, index) =>
        lowerCaseMagazineNames[index].startsWith("sant")
        );
      filteredData2 = viewMagazineData.filter((magazine, index) =>
        lowerCaseMagazineNames[index].startsWith("hansti")
      );

      filteredData3 = viewMagazineData.filter((magazine, index) =>
        lowerCaseMagazineNames[index].startsWith("ek nazar")
      );

      filteredData4 = viewMagazineData.filter((magazine, index) =>
        lowerCaseMagazineNames[index].includes("test")
      );
      setLengthOfDataMagazines([
        filteredData1.length,
        filteredData2.length,
        filteredData3.length,
        filteredData4.length])


    // Update the filtered data state

  },[viewMagazineData])
  useEffect(() => {
    let filteredData = [];

    const lowerCaseMagazineNames = viewMagazineData.map((magazine) =>
      magazine?.magazine_name.toLowerCase()
    );

    if (selectedMag === 0) {
      filteredData = viewMagazineData.filter((magazine, index) =>
        lowerCaseMagazineNames[index].startsWith("sant")
        );
        setLengthMagazine(filteredData.length)
    }
    if (selectedMag === 1) {
      filteredData = viewMagazineData.filter((magazine, index) =>
        lowerCaseMagazineNames[index].startsWith("hansti")
      );
      setLengthMagazine(filteredData.length)

    }
    if (selectedMag === 2) {
      filteredData = viewMagazineData.filter((magazine, index) =>
        lowerCaseMagazineNames[index].startsWith("ek nazar")
      );
      setLengthMagazine(filteredData.length)

    }
    if (selectedMag === 3) {
      filteredData = viewMagazineData.filter((magazine, index) =>
        lowerCaseMagazineNames[index].includes("test")
      );
      setLengthMagazine(filteredData.length)

    }

    // Update the filtered data state
    setMagazineFilteredData(filteredData);
  }, [selectedMag, viewMagazineData]);




  let [fontsLoaded] = useFonts({
    openSans: require("../../assets/fonts/OpenSans-VariableFont_wdth,wght.ttf"),
  });
  if (!fontsLoaded) {
    return <AppLoading />;
  }


  return (
    <>
      {loader === 0 ? (
        <SafeAreaView style={{ width: "100%", height: "100%" ,flex:1}}>
          <ScrollView
            showsVerticalScrollIndicator={false}
            contentContainerStyle={{ width: "100%" }}

            style={styles.container}
          >

            {/* Main Area */}
            <View>
              <Headerdiv navigation={navigation} memberCode={memberCode} />

               {/* <SecondaryHeaderdiv
                titleText="Magazine"
                backButton={true}
                screen={"Magzinepage"}
                navigation={navigation}
              />  */}
                     <View
        style={{
          borderBottomWidth:1,
          borderColor:'rgba(0, 0, 0, 0.15)',
          justifyContent:'center',
          paddingVertical: 18,
          alignItems:'center',
          width:'100%'
        }}
      >
        <View
        style={{
          borderWidth:0,
          flexDirection: isMobileView() ? "column" : 'row',
          justifyContent:'space-between',
          alignItems: !isMobileView() && 'center',
          width: isMobileView() ? "90%" : "96%"
        }}
        >
          <View style={{display:"flex",flexDirection: isMobileView() && "column" || "row",gap: isMobileView() && 10 || 20,alignItems: isMobileView() && "flex-start" || "center",}}>

        <Text
          style={{
            fontWeight: "600", fontSize: isMobileView() && responsiveFontSize(2.6) ||   responsiveFontSize(2.2)
          }}
        >
          Magazines
        </Text>
        <View
          style={{
              backgroundColor:"rgb(248, 249, 250)",
              paddingHorizontal:10,
              paddingVertical:6,
              borderRadius:10,
              marginTop:5
            ,
            flexDirection:   "row",gap:8,alignItems:"center"
          }}
        >
          <Image
source={require("../../assets/images/info.png")}
style={{
height: 22,
width: 22,
resizeMode: "contain",
}}
></Image>
<Text
 style={{
  fontWeight: "500",
  fontSize: isMobileView() && responsiveFontSize(1.8) ||   responsiveFontSize(1.1)
}}
>
          Max. {comboParameters.magazine_limit} magazine(s) can be ordered per transaction
</Text>
        </View>
        </View>


        <View style={[ !isMobileView() && commonStyles.gap1rem,commonStyles.flexRow,commonStyles.flexWrap,isMobileView() && commonStyles.mt_16px,isMobileView() && commonStyles.Col2GridParent]}>

                  <Hoverable style={[{alignItems:"center",height:40, },isMobileView() && commonStyles.Col2GridItem]}>
      {({ hovered }) => (
                    <TouchableOpacity
                      onPress={() => {
                        navigation.navigate("Dashboard", { screen:'Cartpage' })
                      }}
                      style={{
                        backgroundColor: hovered ? Color.BLUE_MEDIUM : "#FFF",
                        borderWidth: 2,
                        borderColor: Color.BLUE_MEDIUM,
                        width:  "100%",
                        height: "100%",
                        borderRadius: 10,
                        paddingLeft:6,
                        paddingRight:6,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Text style={{ color: hovered ? Color.WHITE : Color.BLUE_MEDIUM, fontWeight: "600" }}>
                        Go To Cart
                      </Text>
                    </TouchableOpacity>
                      )}
                      </Hoverable>

        <Hoverable style={[isMobileView() && commonStyles.Col2GridItem]}>
{({ hovered }) => (
        <TouchableOpacity
          style={{
            justifyContent: !isMobileView() ? "space-evenly" : "center",
            gap: isMobileView() &&  10,

            alignItems: "center",
            flexDirection: "row",
            borderWidth: 2,
            borderRadius: 10,
            borderColor: Color.BLUE_MEDIUM,
            height: 40,
            width: isMobileView() ? "100%" : 120,
            backgroundColor: hovered ? Color.BLUE_MEDIUM : "white",
          }}
          onPress={() => {
            navigation.goBack();
            // navigation.navigate('Dashboard');
          }}
        >
          <Image
            style={{       height: 25,
              width: 25,
              resizeMode: "contain",
              borderWidth: 0,
              transform: [{ rotate: "180deg" }]
            }}
            source={ hovered ? require("../../assets/images/goBackWhite.png") : require("../../assets/images/goBack.png")}
          />
          <Text
            style={{
              fontFamily: "openSans",
              fontWeight: "600",
              fontSize: 15,
              color:  hovered ?  "white" : Color.BLUE_MEDIUM,
            }}
          >
            Go Back
          </Text>
        </TouchableOpacity>
             )}
             </Hoverable>

        </View>

        </View>

      </View>
              <View style={styles.memberContainer}>
                <View
                  style={[
                    styles.subscribedNextPrev,
                    !isMobileView() && commonStyles.pl_2,
                    isMobileView() && commonStyles.width90,
                    isMobileView() && commonStyles.alignSelfCenter,
                  ]}
                >
                  <Text
                    style={{
                      fontWeight: "400",
                      fontSize: !isMobileView()
                        ? responsiveFontSize(2)
                        : responsiveFontSize(2.4),
                    }}
                  >
                    Select Magazine
                  </Text>
                </View>


                <View
                  style={[
                    { flexDirection: "row", flexWrap: "wrap" },
                    !isMobileView() && commonStyles.spaceLeftPrimary,
                    !isMobileView() && commonStyles.spaceRightPrimary,
                    !isTabletView() && commonStyles.justifyBetween,
                    isMobileView() && commonStyles.width90,
                    isMobileView() && commonStyles.alignSelfCenter,
                    isMobileView && commonStyles.gap1rem,
                  ]}
                >
                  {/* Magazine Componenet 0 */}
                  <TouchableOpacity
                    onPress={() => {
                      setSelectedMag(false)
                    }}
                    style={[
                      styles.magCard,
                      styles.allMagCard,
                      {
                        overflow:"hidden"
                      }

                    ]}
                  >
                    <View style={{ borderWidth: 0 }}>
                      <Image
                        style={{
                          width: isMobileView() ? 75 : 85,
                          height: isMobileView() && 60 || !isMobileView() && isTabletView() && 70 || !isMobileView() && !isTabletView() && responsiveHeight(9),
                          left: !isMobileView() && -5,
                          borderWidth: 0,
                          borderRadius: 10,
                          resizeMode: "contain",
                        }}
                        source={require("../../assets/images/allMagzines.png")}
                      />
                    </View>
                    <View style={{ borderWidth: 0, marginTop: 4 }}>
                      <Text
                        style={{
                          color: "#fff",
                          fontWeight: "600",
                          fontSize:  18,
                        }}
                      >
                        All Magazines
                      </Text>
                      <Text
                        style={{
                          color: "rgba(255, 255, 255, 0.73)",
                          fontWeight: "600",
                          fontSize:  13,
                        }}
                      >
                        Available in {tableData?.length} Languages
                      </Text>
                    </View>

                    <View
                      style={{
                        borderWidth: 0,
                        height: 100,
                        justifyContent: "flex-end",
                        position: "absolute",
                        right: -6,
                        bottom: -6,
                      }}
                    >
                      {selectedMag === false && (
                        <View
                        style={{backgroundColor:"#fff",
                        padding: 13,
                        borderRadius: 28
                      }}
                        >

                        <Image
                          style={{
                            width: 20,
                            height: 20,
                            resizeMode: "contain",
                          }}
                          source={require("../../assets/images/checkIconWIthoutBorder.png")}
                          />
                          </View>
                      )}
                    </View>
                  </TouchableOpacity>
                  {/*  Magazine Componenet 1 */}
                  <TouchableOpacity
                    onPress={() => {
                      setSelectedMag(0)
                    }}
                    style={[
                      styles.magCard,
                      selectedMag === 0
                        ? styles.selectedMagCard
                        : styles.unSelectedMagCard,
                    ]}
                  >
                    <View style={{ borderWidth: 0 }}>
                      <Image
                        style={{
                          width: isMobileView() ? 75 : 85,
                          height: isMobileView() && 60 || !isMobileView() && isTabletView() && 70 || !isMobileView() && !isTabletView() &&  responsiveHeight(8),
                          left: -5,
                          borderWidth: 0,
                          borderRadius: 10,
                          resizeMode: "contain",
                        }}
                        source={require("../../assets/images/mag1.jpg")}
                      />
                    </View>
                    <View style={{ borderWidth: 0, marginTop: 4 }}>
                      <Text
                        style={{
                          color: "#000",
                          fontWeight: "600",
                          fontSize:  18,
                        }}
                      >
                        Sant Nirankari
                      </Text>
                      <Text
                        style={{
                          color: "rgba(0, 0, 0, 0.50)",
                          fontWeight: "600",
                          fontSize:  13,
                        }}
                      >
                        Available in {lengthOfDataMagazines[0]} Languages
                      </Text>
                    </View>
                    <View
                      style={{
                        borderWidth: 0,
                        height: 100,
                        justifyContent: "flex-end",
                        position: "absolute",
                        right: 10,
                        bottom: 10,
                      }}
                    >
                      {selectedMag === 0 && (
                        <Image
                          style={{
                            width: 30,
                            height: 30,
                            resizeMode: "contain",
                          }}
                          source={require("../../assets/images/checkIcon.png")}
                        />
                      )}
                    </View>
                  </TouchableOpacity>
                  {/* Magazine Componenet 2 */}
                  <TouchableOpacity
                    onPress={() => {
                      setSelectedMag(1)
                    }}
                    style={[
                      styles.magCard,
                      selectedMag === 1
                        ? styles.selectedMagCard
                        : styles.unSelectedMagCard,
                    ]}
                  >
                    <View style={{ borderWidth: 0 }}>
                      <Image
                        style={{
                          width: isMobileView() ? 75 : 85,
                          height: isMobileView() && 60 || !isMobileView() && isTabletView() && 70 || !isMobileView() && !isTabletView() &&  responsiveHeight(8),
                          left: -5,
                          borderWidth: 0,
                          borderRadius: 10,
                          resizeMode: "contain",
                        }}
                        source={require("../../assets/images/mag2.png")}
                      />
                    </View>
                    <View style={{ borderWidth: 0, marginTop: 4 }}>
                      <Text
                        style={{
                          color: "#000",
                          fontWeight: "600",
                          fontSize:  18,
                        }}
                      >
                        Hansti Duniya
                      </Text>
                      <Text
                        style={{
                          color: "rgba(0, 0, 0, 0.50)",
                          fontWeight: "600",
                          fontSize:  13,
                        }}
                      >
                        Available in {lengthOfDataMagazines[1]} Languages
                      </Text>
                    </View>
                    <View
                      style={{
                        borderWidth: 0,
                        height: 100,
                        justifyContent: "flex-end",
                        position: "absolute",
                        right: 10,
                        bottom: 10,
                      }}
                    >
                      {selectedMag === 1 && (
                        <Image
                          style={{
                            width: 30,
                            height: 30,
                            resizeMode: "contain",
                          }}
                          source={require("../../assets/images/checkIcon.png")}
                        />
                      )}
                    </View>
                  </TouchableOpacity>
                  {/* Magazine Componenet 3 */}
                  <TouchableOpacity
                    onPress={() => {
                      setSelectedMag(2)
                    }}
                    style={[
                      styles.magCard,
                      selectedMag === 2
                        ? styles.selectedMagCard
                        : styles.unSelectedMagCard,
                    ]}
                  >
                    <View style={{ borderWidth: 0 }}>
                      <Image
                        style={{
                          width: isMobileView() ? 75 : 85,
                          height: isMobileView() && 60 || !isMobileView() && isTabletView() && 70 || !isMobileView() && !isTabletView() &&  responsiveHeight(8),
                          left: -5,
                          borderWidth: 0,
                          borderRadius: 10,
                          resizeMode: "contain",
                        }}
                        source={require("../../assets/images/mag3.png")}
                      />
                    </View>
                    <View style={{ borderWidth: 0, marginTop: 4 }}>
                      <Text
                        style={{
                          color: "#000",
                          fontWeight: "600",
                          fontSize:  18,
                        }}
                      >
                        Ek Nazar
                      </Text>
                      <Text
                        style={{
                          color: "rgba(0, 0, 0, 0.50)",
                          fontWeight: "600",
                          fontSize:  13,
                        }}
                      >
                        Available in {lengthOfDataMagazines[2]} Languages
                      </Text>
                    </View>
                    <View
                      style={{
                        borderWidth: 0,
                        height: 100,
                        justifyContent: "flex-end",
                        position: "absolute",
                        right: 10,
                        bottom: 10,
                      }}
                    >
                      {selectedMag === 2 && (
                        <Image
                          style={{
                            width: 30,
                            height: 30,
                            resizeMode: "contain",
                          }}
                          source={require("../../assets/images/checkIcon.png")}
                        />
                      )}
                    </View>
                  </TouchableOpacity>
                  {/* Magazine Componenet 4 */}


                </View>

                <View
                  style={[
                    {
                      flexDirection: "row",

                      alignItems: "center",
                      justifyContent: "space-between",
                      zIndex: 2,
                    },
                  !isMobileView() &&  commonStyles.spaceLeftPrimary,
                  !isMobileView() &&  commonStyles.spaceRightPrimary,
                  !isMobileView() ?  commonStyles.mt_3 :  commonStyles.mt_16px,
                  isMobileView() &&  commonStyles.width90,
                  isMobileView() &&  commonStyles.alignSelfCenter,
                  ]}
                >
                  <View
                    style={{
                      flexDirection: !isMobileView() && "row",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <View
                      style={[
                        commonStyles.flexRow,
                        commonStyles.gap1rem,
                        commonStyles.alignCenter,
                        isMobileView() && commonStyles.justifyBetween,
                      ]}
                    >
                      <Text
                        style={{
                          fontWeight: "400",
                          fontSize: !isMobileView()
                          ? responsiveFontSize(2)
                          : responsiveFontSize(2.4),
                        }}
                      >
                        Languages
                      </Text>
                      <View
                        style={{
                          backgroundColor: "rgba(230, 232, 234, 1)",
                          paddingVertical: 8,
                          paddingHorizontal: !isMobileView() ? 20 : 14,
                          borderRadius: 10,
                        }}
                      >
                        <Text
                          style={{
                            fontWeight: "500",
                            fontSize: !isMobileView() ?  responsiveFontSize(1) : responsiveFontSize(1.8),
                          }}
                        >
                          {(selectedMag===0 || selectedMag===1|| selectedMag===2 || selectedMag===3)?lengthMagazine:tableData?.length}
                        </Text>
                      </View>
                    </View>
{/*
                    <View
                      style={{
                        marginTop: 10,
                        flexDirection: "row",
                        gap: 10,
                        backgroundColor: "#F8F9FA",
                        width: "fit-content",
                        borderRadius: 12,
                        padding: 8,
                      }}
                    >
                      <Hoverable>
                        {({ hovered }) => (
                          <TouchableOpacity
                            style={[
                              styles.button,
                              selectedButton === 0 && styles.selectedButton,
                            ]}
                            onPress={() => {handleButtonPress(0),setBulkpurchase(false)}}
                          >
                            <Text
                              style={[
                                styles.languageButton,
                                selectedButton === 0 || hovered
                                  ? styles.activeLanguageButton
                                  : null,
                              ]}
                            >
                              Single Pack
                            </Text>
                          </TouchableOpacity>
                        )}
                      </Hoverable>

                      <Hoverable>
                        {({ hovered }) => (
                          <TouchableOpacity
                            style={[
                              styles.button,
                              selectedButton === 1 && styles.selectedButton,
                            ]}
                            onPress={() => {handleButtonPress(1),setBulkpurchase(true)}}
                          >
                            <Text
                              style={[
                                styles.languageButton,
                                selectedButton === 1 || hovered
                                  ? styles.activeLanguageButton
                                  : null,
                              ]}
                            >
                              Combo Pack
                            </Text>
                          </TouchableOpacity>
                        )}
                      </Hoverable>
                    </View> */}
                  </View>
                </View>
                <View
                  style={{
                    width: isTabletView() ? "90%" : "75%",
                    borderWidth: 0,
                    alignSelf: "center",
                    borderRadius:10,
                    marginVertical: 10,
                    marginBottom: responsiveHeight(14),
                  }}
                >
                      <InsetShadow
                      left={false}
                      top={false}
                      bottom={selectedMag  && lengthMagazine > 4 || !selectedMag  && (tableData?.length > 4) ? true : false}
                      shadowRadius={25}
                      shadowOpacity={0.17}
                      containerStyle={{borderRadius:10}}
                      right={false}
                      >

                 {true?(<>

                  {(selectedMag===0 || selectedMag===1|| selectedMag===2 || selectedMag===3)? (
                    <>
                    <ScrollView horizontal={ isMobileView() && true} >

                      <Table  >

                      <Row data={tableHead} style={styles.head} textStyle={styles.textHead} />
                      <View style={{height: isMobileView() ? 400 : 300}}>

<ScrollView nestedScrollEnabled={true} style={{paddingHorizontal: !isMobileView() && 15}}>
                      {
                        magazineFilteredData?.map((rowData,index)=>(
                          <TableWrapper key={index} style={styles.tableRow}>
                     <View style={{flex: isMobileView() ? 0.9 : 1,flexDirection:"row"}}>

                            <Cell
                              data={ImagePathFunc(rowData, index)}
                              style={{  flex: isMobileView() ? 0.5 : 0.2, justifyContent: 'center', alignItems: !isMobileView() && 'flex-start' }}
                              />
                            <Cell
                              data={rowData?.magazine_name}
                              textStyle={styles.text}
                              style={{ flex:   1, justifyContent: 'center', alignItems: !isMobileView() && 'flex-start' }}
                              />
                              </View>
                            {/* <Cell
                              data={rowData?.frequency}
                              textStyle={styles.text}
                              style={{ flex:   1, justifyContent: 'center', alignItems: !isMobileView() && 'flex-start' }}
                            /> */}
                            <Cell
                              data={[singlePackButton(rowData, index),buttonsSaprator(),bulkBuyButton(rowData, index)]}
                              textStyle={styles.text}
                              style={{ display:'flex',flex:   1, justifyContent: 'center', borderRadius:8,gap:10 }}
                            />


                          </TableWrapper>
                        ))
                      }

                        </ScrollView>
                       </View>

                      </Table>
                    </ScrollView>

                      </>


                  ) : (
                    <>
                    <Text>{selectedMag}</Text>

<ScrollView horizontal={ isMobileView() && true} >
                      <Table>
                      <Row data={tableHead} style={styles.head} textStyle={styles.textHead} />
                      <View style={{height: isMobileView() ? 400 : 300}}>

<ScrollView nestedScrollEnabled={true} style={{paddingHorizontal: !isMobileView() && 15}}>

                      {
                        viewMagazineData?.map((rowData,index)=>(
                          <TableWrapper key={index} style={styles.tableRow}>
                                       <View style={{flex: isMobileView() ? 0.9 : 1,flexDirection:"row"}}>


                             <Cell
                              data={ImagePathFunc(rowData, index)}
                              style={{ flex: isMobileView() ? 0.5 : 0.2, justifyContent: 'center', alignItems: !isMobileView() ? 'flex-start' : "center" }}
                            />
                            <Cell
                              data={rowData?.magazine_name}
                              textStyle={styles.text}
                              style={{ flex:   1, justifyContent: 'center', alignItems: !isMobileView() && 'flex-start' }}
                            />
                              </View>
                            {/* <Cell
                              data={rowData?.frequency}
                              textStyle={styles.text}
                              style={{ flex:   1, justifyContent: 'center', alignItems: !isMobileView() && 'flex-start' }}
                            /> */}
                            <Cell
                              data={[singlePackButton(rowData, index),buttonsSaprator(),bulkBuyButton(rowData, index)]}
                              textStyle={styles.text}
                              style={{ display:'flex',flex:   1, justifyContent: 'center', borderRadius:8,gap:10 }}
                            />


                          </TableWrapper>
                        ))
                      }
                        </ScrollView>
                       </View>
                      </Table>
                      </ScrollView>
                      </>

                  )}</>):(<View>
                    <DataNotFound
                      height={35}
                      width={35}
                      fontSize={1.5}
                      text={"Other Magazines Not Available"}
                      textColor={"#000"}
                    />
                  </View>)

}
</InsetShadow>
                </View>
              </View>
            </View>
          </ScrollView>
        </SafeAreaView>
      ) : (
        <SafeAreaView
          style={{
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: Color.WHITE,
            margin: "auto",
          }}
        >
          <ActivityIndicator size="large" />
          <Text style={{ fontSize: 15, fontWeight: "700", color: "#b4b4b4" }}>
            Please Wait ...
          </Text>
        </SafeAreaView>
      )}

      <Modal
      animationType="fade"
      transparent={true}
      visible={thankYouPage}
      onRequestClose={() => {
        Alert.alert("Modal has been closed.");
        setThankYouPage(!thankYouPage);
      }}
    >
      <View
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          width:'100%',
          height: '100%',
          alignSelf:'center',
          backdropFilter: 'blur(20px)',
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
        }}
      >
        <View
          style={{
            backgroundColor: "white",
            borderRadius: 15,

            alignItems: "center",
            shadowColor: Color.BLACK,
            marginTop: 35,
            marginBottom: 35,


            width: "80%",
            height: isMobileView() ? "50%" : "90%",
            backgroundColor: "rgba(248, 249, 250, 1)",
            alignItems: "center",
            borderRadius: 20,
            justifyBetween: "space-between",
          }}
        >
          <View
            style={{
              borderWidth: 0,
              width: "100%",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <View></View>

            <TouchableOpacity
              onPress={() => {
                setThankYouPage(!thankYouPage);
              }}
            >
              <Image
                style={{ width: 30, height: 30, top: 25, right: 25 }}
                source={require("../../assets/images/cross.png")}
              />
            </TouchableOpacity>
          </View>
          <Image
              style={{ width: isMobileView() && responsiveScreenHeight(17) || isOnlyTabletView() && responsiveScreenHeight(30)  || 280, height: isMobileView() && responsiveScreenHeight(17) || isOnlyTabletView() && responsiveScreenHeight(30)  || 280 }}
              source={require("../../assets/images/thankyou.png")}
            />
          <View style={{ width: "75%", alignItems: "center" }}>
            <Text style={{ fontSize: isMobileView() ? responsiveFontSize(2.5) : 45, fontWeight: "700" }}>
              Thank you, enjoy!{" "}
            </Text>
            <View style={{ width: isMobileView() ? "80%" : "50%",marginTop:15, alignItems: "center" }}>
              <Text
                style={{
                  fontSize: isMobileView() ? responsiveFontSize(2) : 20,
                  fontWeight: "600",
                  color: "rgba(0, 0, 0, 0.5)",
                  textAlign: "center",
                }}
              >
                Magazine Subscribed Successfully
              </Text>

              <View style={{top:50}}>
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "600",
                  color: "rgba(0, 0, 0, 0.5)",
                }}
              >
                           Purchase Successfull

              </Text>

              </View>
            </View>
          </View>
        </View>
      </View>
    </Modal>



                <Modal
      animationType="fade"
      transparent={true}
      visible={cartReplacePopUp}
      onRequestClose={() => {
        Alert.alert("Modal has been closed.");
        setCartReplacePopUp(!cartReplacePopUp);
      }}
    >
      <View style={styles.centeredViewError}>
        <View style={[styles.modalViewError,{justifyContent: "space-between" }]}>
          <Image
            style={{
              height: responsiveHeight(15),
              width: responsiveWidth(15),
              resizeMode: 'contain',
            }}
            source={ require('../../assets/images/errorIcon.png')}
          />


            <Text style={{ fontFamily: 'openSans', fontWeight: '600', fontSize: responsiveFontSize(1.2), paddingHorizontal: 9, textAlign: 'center' }}>
              {erroMessage}
            </Text>
          <View style={{ display: 'flex', flexDirection: 'row', gap: 20 }}>
            <TouchableOpacity
              style={{
                borderWidth: 2,
                borderRadius: 7,
                backgroundColor: '#fff',
                width: responsiveWidth(10),
                height: responsiveHeight(5),
                alignItems: 'center',
                justifyContent: 'center',
                borderColor: 'rgba(0, 0, 0, 0.15)',
              }}
              onPress={() => {
                setCartReplacePopUp(false)
                // getSubscribeItem()
              }}
            >
              <Text style={{ fontFamily: 'openSans', color: '#000', fontWeight: '600', fontSize: 15, textAlign: 'right' }}>Cancel </Text>
            </TouchableOpacity>

            <TouchableOpacity
              style={{
                borderWidth: 2,
                padding: 10,
                borderRadius: 7,
                backgroundColor: '#F24D14',
                width: responsiveWidth(10),
                height: responsiveHeight(5),
                alignItems: 'center',
                justifyContent: 'center',
                borderColor: '#F24D14',
              }}
              onPress={() => {
                replaceItems()
                 // getSubscribeItem()
               }}
            >
              <Text style={{ fontFamily: 'openSans', color: '#fff', fontWeight: '600', fontSize: 15, textAlign: 'right' }}>Replace </Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </Modal>
    <BuyModal onUpdateQuantity={updateQuantity} pageName={'selectionMagazine'} addUpdateMagazine={getSubscribeItem} toAddress={selectedAddress} mCode={memberCode} isVisible={modalVisible} closeModal={closeModal} refreshpageFunc={refreshpage} profileData={profileData} data={selectedBuyMag} isBulk={bulkPurchase}/>
    <View style={{position:"absolute",width:"100%"}}>
    {error.text2 !== '' && <PopupComponent
                style={{ zIndex: 100 }}
                hideFun={() => {
                  dispatchError("")
                  dispatchSuccess("")
                }}
                type={error.type}
                text1={error.text1}
                text2={error.text2}
              />}
              </View>
    </>
  );
};

export default SelectionMagazine;

const styles = StyleSheet.create({
  container: {
    borderWidth: 0,
    backgroundColor: "#FFF",
    width: "100%",
    height: "100%",
    flexDirection: "row",
    // transform:[{rotate: '270deg'}],
  },
  membersSearchArea: {
    // flex:1,
    borderBottomWidth: 1,
    borderColor: "rgba(0, 0, 0, 0.15)",
    justifyContent: "center",
    height: responsiveHeight(14.5),
    alignItems: "center",
    // width:'100%'
  },
  membersSearchAreaContainer: {
    borderWidth: 0,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "95%",
  },
  memberContainer: {
    // flex:,
    borderWidth: 0,
    width: "100%",
    // height: "100%",
  },
  subscribedNextPrev: {
    borderWidth: 0,
    marginHorizontal: !isMobileView() && !isTabletView() && 45 || !isMobileView() && isTabletView() && 20 || isMobileView() && 0,
    margin: !isMobileView() ? 20 : 16,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  modalButtonContainer: {
    backgroundColor: "#FFF",
    position: "absolute",
    top: "110%",
    right: "-30%",
    borderRadius: 10,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.15,
    shadowRadius: 6,
    elevation: 1,
  },
  modalButton: {
    paddingVertical: 10,
    alignItems: "center",
    justifyContent: "flex-start",
    gap: 5,
    paddingHorizontal: 20,
    zIndex: 4,
    borderRadius: 5,
  },
  languageButton: {
    fontFamily: "openSans",
    fontWeight: "600",
    fontSize: 16,
    // padding: 10,
    borderRadius: 8,
    color: Color.BLUE_MEDIUM,
    // width: isMobileView() ? 100 : 120,
    textAlign: "center",
  },
  activeLanguageButton: {
    // backgroundColor: Color.BLUE_LIGHT,
    // shadowColor: 'rgba(0, 0, 0, 0.25)',

    // borderRadius: 8,
  },
  magCard: {
    width: isMobileView() && "100%" || !isMobileView() && isTabletView() && "48%" ||  isSmallLaptop() && "32%" ||  responsiveWidth(21.3),
    height: isMobileView() ? responsiveHeight(9)  :    responsiveHeight(10),
    borderRadius: 8,
    paddingVertical: 7,
    alignItems: isMobileView() ? "center" : "flex-start",
    flexDirection: "row",

  },
  selectedMagCard:{
    borderWidth:  2,
    backgroundColor: "rgba(92, 66, 218, 0.05)",
    borderColor:Color.BLUE_MEDIUM

  },
  unSelectedMagCard:{
    shadowColor: 'rgba(0, 0, 0, 0.15)',
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 1,
    shadowRadius: isMobileView() ? 5 : 10,
    backgroundColor: "#FFF"

  },
  head: { height: responsiveHeight(6), backgroundColor: '#fff', marginLeft: !isMobileView() && 15, marginRight:30 },
  text: { margin: 6, marginLeft:15, fontWeight:600,fontSize: isMobileView() ? responsiveFontSize(1.9)  : responsiveFontSize(1.1),display:"flex",gap:25 },
  textHead: { margin: 6, marginLeft:15, fontWeight:500,fontSize: isMobileView() ? responsiveFontSize(2) : responsiveFontSize(1.2),color:"rgba(0, 0, 0, 0.60)",},
  tableRow:{
    borderWidth:1,
    borderColor:"rgba(0, 0, 0, 0.15)",
    marginVertical:8,
    borderRadius:6,
    height:responsiveHeight(9),
    flexDirection:"row"
  },
  modalView: {
    // position:"relative",
    flex: 1,
    backgroundColor: "white",
    borderRadius: !isMobileView() && 20,

    alignItems: "center",
    shadowColor: Color.BLACK,
    marginTop: isOnlyLaptopView() && 25 || isOnlyTabletView() && 20,
    marginBottom: isOnlyLaptopView() && 25 || isOnlyTabletView() && 20,

    flexDirection: isMobileView() ? "column" : "row",
    width:  isMobileView()  && "100%" ||  isOnlyTabletView() && "95%" ||isOnlyLaptopView() && "70%",
    height:  isMobileView()  && "100%" ||  isOnlyTabletView() && "95%" ||isOnlyLaptopView() && "60%",

  },
  modalViewError: {
    borderWidth: 0,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    width: responsiveWidth(30),
    height: responsiveHeight(50),
    margin: 5,
    backgroundColor: "#fff",
    borderRadius: 8,
    padding: 25,
    shadowColor: "none",


    shadowRadius: 4,

  },
  centeredView: {
    flex: 1,
    position:"relative",
    justifyContent: "center",
    alignItems: "center",
    width:'100%',
    height:'100%',
    overflow: !isMobileView() && "hidden",
    alignSelf:'center',
    backdropFilter: 'blur(20px)',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
  centeredViewError: {
    flex: 1,
    backdropFilter: 'blur(20px)',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    justifyContent: "center",
    alignItems: "center",
  },
  modalText: {

    fontFamily:"openSans",
    fontWeight: "700",
    fontSize: isMobileView() ? responsiveFontSize(2.2) : responsiveFontSize(1.5),
  },
  textBoxView: {
    width: "100%",

    margin: 2.5,
    height: isMobileView() && isTabletView()  ? "fit-content" : "15%",
    flexDirection:"row",
    justifyContent: "space-between",
    padding: 0.5,
    gap:15
  },
  textboxInput: {
    width: "100%",
    height: isMobileView() && isTabletView()  ? responsiveScreenHeight(6.5) : "50%",
    textOverflow: "ellipsis",
    textWrap: "nowrap",
    marginTop:10,
    backgroundColor: Color.WHITE,
    opacity: 0.5,
    cursor: "default",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 10,
    borderWidth: 1,
    borderColor: "rgba(0, 0, 0, 0.14)",
    color: "rgba(0, 0, 0,)",
    fontSize: isMobileView()  ? responsiveFontSize(2.2) :  responsiveFontSize(1.1),
    textAlign: "left",
    padding: 10,

    fontWeight: "600",
},
Linecontainer: {
  height: '100%', // Adjust the height percentage as needed
  justifyContent: 'center', // Center the line vertically within its parent
},
line: {
  width: 2,
  height: '100%', // Set the line's height to 100% of its parent
  backgroundColor: 'rgba(0, 0, 0, 0.15)',
},
messageContainer : {
  position: "absolute",
  backgroundColor: Color.WHITE,
  borderWidth:0,
  borderColor: Color.BLUE_MEDIUM,
  borderRadius:8,
  alignItems:"center",
  justifyContent:"center",
  // height:"20%",
  paddingBottom:12,
  paddingTop:6,
  paddingHorizontal:15,
  gap:5,
  top: 0,
  left: "50%",
  transform: [
    { translateX: "-50%" }, // Translate the view horizontally by -50% of its own width
    { translateY: "0%" }, // Translate the view vertically by -50% of its own height
  ],
  zIndex:2,
  borderTopWidth:0,
  borderTopLeftRadius:0,
  borderTopRightRadius:0,
  shadowColor: 'rgba(99, 99, 99, 0.2)',
  shadowOffset: {
    width: 0,
    height: 2,
  },
  shadowRadius: 8,
  shadowOpacity: 1,
  elevation: 5, // This is for Android shadow
},
messageText:{
  textAlign:"center",
  fontWeight:"500"
},
allMagCard:{
  backgroundColor:Color.BLUE_MEDIUM,
  shadowColor: 'rgba(0, 0, 0, 0.15)',
  shadowOffset: { width: 0, height: 0 },
  shadowOpacity: 1,
  shadowRadius: isMobileView() ? 5 : 10,
  // backgroundColor: "#FFF"
}
});
