import { StyleSheet, Text, View } from 'react-native'
import React from 'react'
import AsyncStorage from '@react-native-async-storage/async-storage'
import ErrorContext from "../context/ErrorContext";
import { apiServices } from '../services/apiServices';
export const checkUserName = async(navigation) =>{
  const {  getAddress,viewProfile } = apiServices;
        let memberCode = await AsyncStorage.getItem('member_code');
        let data= await viewProfile(memberCode);
 
          if(data.member_name==='Guest' || data.gender === null || data.gender === "" ){//|| data.email === null || data.email === ""
            navigation.navigate('Profilepage')

            
          }else{
       
            try {
             const memberAddressDataGet = await getAddress(data.member_code);
              if(memberAddressDataGet.length == 0 )
              {
                navigation.navigate('Profilepage') 
              }
            } catch (error) {
              dispatchError(error);

            }
          ;
        }
        }


const styles = StyleSheet.create({})