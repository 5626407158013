import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import {API_URL} from "@env"

axios.defaults.baseURL = API_URL
 //axios.defaults.baseURL = 'https://naman-magazine-dev.odpay.in/'
const namanApi = axios.create() 

namanApi.interceptors.request.use(
    async config => {
        const token = await AsyncStorage.getItem('memberToken')
        if (token) {
            config.headers.Authorization = token

        }
        return config
    }, error => {
        return Promise.reject(error)
    }

    )
export default namanApi

