import { Image, StyleSheet, Text, View, TouchableOpacity } from "react-native";
import React from "react";
import Headerdiv from "../Common/Headerdiv";
import {
  responsiveScreenFontSize,
  responsiveScreenHeight,
  responsiveScreenWidth,
  responsiveWidth,
} from "react-native-responsive-dimensions";
import AppLoading from "expo-app-loading";
import { useFonts } from "expo-font";
import { Color, isMobileView } from "./Constants";
import commonStyles from "../styles/styles";
import { formatedDateWithStandardFormat } from "../util/fomatedDate";
const Profilecard = ({profileData,contactCard}) => {
    
    let [fontsLoaded] = useFonts({
        openSans: require("../../assets/fonts/OpenSans-VariableFont_wdth,wght.ttf"),
      });
      if (!fontsLoaded) {
        return <AppLoading />;
      }

  return (
    <View
    style={{
      width: isMobileView() ? "90%" : "80%",
      height: isMobileView() ? "fit-content" : 110,
      borderWidth: 0,
      alignItems: "center",
      justifyContent: "center",
      alignSelf:'center'
    }}
  >
    <View
      style={{
        backgroundColor: Color.BLUE_MEDIUM,
        width: "100%",
        height: "100%",
        borderRadius: 20,
        paddingHorizontal: isMobileView() ? 18 : 37,
        paddingVertical: isMobileView() ? 18 : 0,
        justifyContent: "center",
      }}
    >
      <View style={{ flexDirection: "row", borderWidth: 0 }}>
        {/* <Image
          style={{
            height: 150,
            width: 150,
            resizeMode: "contain",
            borderWidth: 0,
            borderRadius: 100 / 2,
          }}
          source={require("../../assets/images/manLarge.png")}
        /> */}
        {/* <View style={{}}> */}

     { !contactCard ?    <View
          style={{
            borderWidth: 0,
            width: "100%",
            height: "100%",
            alignSelf: "center",
            flexDirection:"row",
            justifyContent:  !isMobileView() && "space-between",
            flexWrap: isMobileView() && 'wrap',
            gap: isMobileView() && 16
          }}
        >
          <View style={[ isMobileView() && commonStyles.width43]}>
            <Text style={styles.headingText}>Full Name</Text>
            <Text style={styles.subHeadingText}>{profileData?.title} {profileData?.member_name}</Text>
          </View>

          <View style={[ isMobileView() && commonStyles.width51]}>
            <Text style={styles.headingText}>Gender</Text>
            <Text style={styles.subHeadingText}>{profileData?.gender}</Text>
          </View>
          <View style={[ isMobileView() && commonStyles.width43]}>
            <Text style={styles.headingText}>Date of Birth</Text>
            <Text style={styles.subHeadingText}>{formatedDateWithStandardFormat(profileData?.dob)}</Text>
          </View>
          <View style={[ isMobileView() && commonStyles.width51]}>
            <Text style={styles.headingText}>Subscriber Code</Text>
            <Text style={styles.subHeadingText}>{profileData?.member_code}</Text>
          </View>
          {/* <View>
            <Text style={styles.headingText}>Phone</Text>
            <Text style={styles.subHeadingText}>{phoneNumber}</Text>
          </View> */}
        </View>
      :
      <View
          style={{
            borderWidth: 0,
            width: "100%",
            height: "100%",
            alignSelf: "center",
            flexDirection:"row",
            justifyContent: !isMobileView() && "space-between",
            flexWrap: isMobileView() && 'wrap',
            gap: isMobileView() && 16
          }}
        >
          <View style={[ isMobileView() && commonStyles.width100]}>
            <Text style={styles.headingText}>Email</Text>
            <Text style={styles.subHeadingText}>{profileData?.email}</Text>
          </View>



          <View style={[ isMobileView() && commonStyles.width47]}>
            <Text style={styles.headingText}>Care of Name</Text>
            <Text style={styles.subHeadingText}>{profileData?.co_type} - {profileData?.co_name}</Text>
          </View>
          <View style={[ isMobileView() && commonStyles.width47]}>
            <Text style={styles.headingText}>Phone</Text>
            <Text style={styles.subHeadingText}>{profileData.mobile}</Text>
          </View>
          <View style={[ isMobileView() && commonStyles.width47]}>
            <Text style={styles.headingText}>Alternate Phone</Text>
            <Text style={styles.subHeadingText}>{profileData.phone}</Text>
          </View>

        </View>
      }


        {/* </View> */}
      </View>
    </View>
  </View>
  )
}

export default Profilecard

const styles = StyleSheet.create({
    headingText: {
        color: "rgba(255, 255, 255, 0.8)",
        fontWeight: "600",
        fontSize: isMobileView() ?  responsiveScreenFontSize(1.8) : responsiveScreenFontSize(1.1),
        fontFamily:'openSans'
    },
      subHeadingText: {
        color: "rgba(255, 255, 255, 1)",
        fontWeight: "600",
        fontSize: isMobileView() ?  responsiveScreenFontSize(1.8) : responsiveScreenFontSize(1.1),
        fontFamily:'openSans'
      },

})
