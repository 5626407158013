import { StyleSheet, Text, View,Image, TouchableOpacity } from "react-native";
import React, { useState } from "react";
import { responsiveFontSize, responsiveScreenHeight, responsiveScreenWidth } from "react-native-responsive-dimensions";
import { format } from 'date-fns';

const TransactionComponent = ({ data }) => {

  const originalDate = data.created_at
  const date = new Date(originalDate);
  const formattedDate = format(date, "d MMM yyyy - h a");
//   const day = dateObject.getDate();
//   const month = dateObject.getMonth() + 1; // Months are zero-based, so we add 1
//   const year = dateObject.getFullYear();

//   const formattedDate = `${day}/${month}/${year}`;

  const [status, setStatusBackground] = useState({
   backgroundColor : data.status === "open" ? "#EAF9F1" : "#FEEEE9",
    textColor : data.status === "open" ? "#2CC373" : "#F24D14"
  }
  )

  return (
    <View
      style={{
        flexDirection: "row",
        width: "100%",
        height: responsiveScreenHeight(9),
        alignItems: "stretch",
        borderWidth: 2,
        margin: 15,
        justifyContent: "space-evenly",
        borderRadius: 8,
        elevation: 5,
        borderColor: "rgba(0, 0, 0, 0.15)",
        // paddingRight: "4%",
      }}
    >
        <View style={{borderWidth:0,flex:1.3,justifyContent:'center',alignItems:'center'}}>
        <Text style={styles.primaryText}>{formattedDate}</Text>

        </View>
        <View style={{borderWidth:0,flex:1,justifyContent:'center',alignItems:'center'}}>
        <Text style={styles.primaryText}>{data.user_name}--</Text>

        </View>
        <View style={{borderWidth:0,flex:1,justifyContent:'center',alignItems:'center'}}>
        <Text style={styles.primaryText}>{'copies'}</Text>

        </View>
        <View style={{borderWidth:0,flex:1,justifyContent:'center',alignItems:'center'}}>
        <Text style={styles.primaryText}>{data.mr_amount}</Text>

        </View>
        <View style={{borderWidth:0,flex:1,justifyContent:'center',alignItems:'center'}}>
        <Text style={styles.primaryText}>{data.mr_status}</Text>

        </View>
        <View style={{borderWidth:0,flex:1,justifyContent:'center',alignItems:'center'}}>
        <TouchableOpacity>
            <Image style={{resizeMode:'contain',width:30,height:30,borderWidth:0}} 
            source={require('../../assets/images/downloadFile.png')}/>
        </TouchableOpacity>
            

        </View>



    </View>
  );
};

export default TransactionComponent;

const styles = StyleSheet.create({
  textBox: {
    borderWidth: 3,
    borderColor: "rgba(200, 249, 250, 1)",
    height: 35,
    padding: 5,
  },
  textBoxText: { fontSize: 12 },
  primaryText: {     fontFamily: "openSans",
  fontWeight: "600",
  fontSize: responsiveFontSize(1.15),
  borderWidth: 0,},
  secondText: { fontWeight: "bold", opacity: 0.3, fontSize: 14 },
});
