import {
  Image,
  StyleSheet,
  Text,
  View,
  Modal,
  TouchableOpacity,
  TextInput,
} from "react-native";
import React, { useState, useEffect,useContext } from "react";
import Headerdiv from "../Common/Headerdiv";
import {
  responsiveFontSize,
  responsiveHeight,
  responsiveScreenFontSize,
  responsiveScreenWidth,
  responsiveWidth,
} from "react-native-responsive-dimensions";
import AppLoading from "expo-app-loading";
import { useFonts } from "expo-font";
import SelectDropdown from "react-native-select-dropdown";
import { apiServices } from "../services/apiServices";
import Hoverable from "react-native-hoverable";
import { Color, isMobileView, isOnlyLaptopView, isOnlyTabletView, isTabletView } from "./Constants";
import { Switch } from "react-native-switch";
import commonStyles from "../styles/styles";
import ErrorContext from "../context/ErrorContext";
import MultiSelect from 'react-native-multiple-select';
const Addresscomponent = ({
  memberAddress,
  index,
  memberCode,
  refreshAdress,

}) => {
  const { error, dispatchError, dispatchSuccess } = useContext(ErrorContext);

  const [stateList, setStateList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [pincodeList, setPincodeList] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [zoneList, setZoneList] = useState([]);
  const [zoneDataList,setZoneDataList] = useState([]);
  // const [tehsilList, setTehsilList] = useState([]);

  const [selectedCountry, setSelectedCountry] = useState(memberAddress.country_id?memberAddress.country_id:"");
  // const [selectedTehsil, setSelectedTehsil] = useState(memberAddress.tehsil_id?memberAddress.tehsil_id:"");
  const [selectedDistrict, setSelectedDistrict] = useState(memberAddress.district_id?memberAddress.district_id:"");
  const [selectedType, setSelectedType] = useState(memberAddress.address_type?memberAddress.address_type:"");
  const [selectedBranch, setSelectedBranch] = useState(memberAddress.branch_id?memberAddress.branch_id:"");
  const [selectedZone, setSelectedZone] = useState(memberAddress.zone_id?memberAddress.zone_id:"");
  const [selectedState, setSelectedState] = useState(memberAddress.state_id?memberAddress.state_id:"");
  const [newZipcode, setNewZipcode] = useState(memberAddress.pincode?memberAddress.pincode:"");
  const [addressLine1, setAddressLine1] = useState(memberAddress.adr_line1?memberAddress.adr_line1:"");
  const [addressLine2, setAddressLine2] = useState(memberAddress.adr_line2?memberAddress.adr_line2:"");
  const [selectedCity, setSelectedCity] = useState(memberAddress.city_id?memberAddress.city_id:"");


  const [selectedCountryName, setSelectedCountryName] = useState(memberAddress.country_name?memberAddress.country_name:"");
  const [selectedStateName, setSelectedStateName] = useState(memberAddress.state_name?memberAddress.state_name:"");
  // const [selectedTehsil, setSelectedTehsil] = useState(memberAddress.tehsil_id?memberAddress.tehsil_id:"");
  const [selectedDistrictName, setSelectedDistrictName] = useState(memberAddress.district_name?memberAddress.district_name:"");
  const [selectedCityName, setSelectedCityName] = useState(memberAddress.city_name?memberAddress.city_name:"");
  const [newZipcodeName, setNewZipcodeName] = useState(memberAddress.pincode?memberAddress.pincode:"");
  const [branchName, setBranchName] = useState(memberAddress.branch_name?memberAddress.branch_name:"");


  const [branchesList, setBranchesList] = useState([]);
  const [branchesListItem, setBranchesListItem] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [fullAdress, setFullAddress] = useState("");
  const [pincode, setPincode] = useState("");
  const [toggleValue, setToggleValue] = useState(memberAddress.member_default_adr);
  const [deleteAddressComponent, setDeleteAddressComponent] = useState(false);

  const [typeErrorAddress, setTypeErrorAddress] = useState("");
  const [countryError, setCountryError] = useState("");
  const [stateError, setStateError] = useState("");
  // const [tehsilError, setTehsilError] = useState("");
  const [cityError, setCityError] = useState("");
  const [pincodeError, setPincodeError] = useState("");
  const [districtError, setDistrictError] = useState("");
  const [branchError,setBranchError] = useState("");
  const [zoneError,setZoneError] = useState("");
  const [addressLine1Error, setaddressLine1Error] = useState("");
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [TooltipHeight, setTooltripHeight] = useState("");

  let memberAdd = memberAddress.full_address

  const {
    postDeleteAddress,
    defaultSetAddress,
    editAddress,
    getCountryList,
    getCityList,
    getStateList,
    // getTehsilsList,
    getDistrictList,
    getPincodeList,
    getBranchList,
    getZoneList,
  } = apiServices;

  // useEffect(() => {
  //   if(selectedCountry){
  //     getStateMemberList(memberAddress.country_name)
  //   }
  //   if(selectedState){
  //     getDistrictMemberList(memberAddress.state_name)
  //   }
  //   if(selectedDistrict){
  //     getTehsilMemberList(memberAddress.district_name)
  //   }
  //   if(selectedTehsil){
  //     getCityMemberList(memberAddress.tehsil_name)
  //   }
  //   if(memberAddress.city_name){
  //     getPincodeMemberList(memberAddress.city_name)
  //   }
  //   if(selectedZone){
  //     getBranchMemberList(memberCode, memberAddress.zone_id)
  //   }


  // }, [])
  useEffect(()=>{
    if(selectedZone)
    {
      handleBranchSearch(undefined)
    }
  },[selectedZone])
  const onFocusGetAPI = () =>{

  }

  const onSelectedItemsChange = (selectedItems) => {
    // Set Selected Items

    setSelectedBranch(selectedItems[0])
    setSelectedItems(selectedItems);
  };
  const [modalVisible, setModalVisible] = useState(false);
  const validateInputs = () => {
    let isValid = true;

    // Validation for Name field
    if (selectedType === "") {
      setTypeErrorAddress("Type is required");
      isValid = false;
    } else {
      setTypeErrorAddress("");
    }
    if (selectedCountry === "") {
      setCountryError("Country is required");
      isValid = false;
    } else {
      setCountryError("");
    }
    if (selectedState === "") {
      setStateError("State is required");
      isValid = false;
    } else {
      setStateError("");
    }
    // if (selectedTehsil === "") {
    //   setTehsilError("Tehsil is required");
    //   isValid = false;
    // } else {
    //   setStateError("");
    // }
    if (selectedCity === "") {
      setCityError("City is required");
      isValid = false;
    } else {
      setCityError("");
    }
    if (newZipcode === "") {
      setPincodeError("Pincode is required");
      isValid = false;
    } else {
      setPincodeError("");
    }
    if (selectedDistrict === "") {
      setDistrictError("District is required");
      isValid = false;
    } else {
      setDistrictError("");
    }
    if (addressLine1 === "") {
      setaddressLine1Error("Street is required");
      isValid = false;
    } else {
      setaddressLine1Error("");
    }
    if(selectedZone === ""){
      setZoneError("Zone is required")
      isValid = false;
    }else{
      setZoneError('');
    }
    if(selectedBranch === "")
    {
      setBranchError("Branch is required");
      isValid = false;
    }else{
      setBranchError('');
    }

    return isValid;
  };
  let addressDataNew =
    {
      address_type: `${selectedType}`,
      member_code: `${memberCode}`,
      pincode: `${newZipcode}`,
      country_id: `${selectedCountry}`,
      state_id: `${selectedState}`,
      district_id: `${selectedDistrict}`,
      // tehsil_id: `${selectedTehsil}`,
      city_id: `${selectedCity}`,
      adr_line1:`${addressLine1}`,
      adr_line2: `${addressLine2}`,
      zone_id:`${selectedZone}`,
      branch_id:`${selectedBranch}`,
    }

  const renderDropdownIcon = ({ isOpen }) => (
    <View
      style={{
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Image
                style={{
                  height: 22,
                  width: 22,

                  resizeMode:'contain',

                }}
                source={ require( "../../assets/images/dropDown.png") }
              />
    </View>
  );

  const handleSubmit = () => {
    const isValid = validateInputs();

    if (isValid) {
      editMemberAddress();
    } else {
      alert("Please fill in the required fields correctly.");
    }
  };


  const editMemberAddress = async () => {
    try{
    await editAddress(memberCode, memberAddress.address_id,addressDataNew);
    setModalVisible(!modalVisible);
    refreshAdress();
    dispatchSuccess("Address has been updated successfully")
    }catch(error)
    {
      dispatchError(error)
    }
  };
  const deleteAddressApi = async () => {
    try{
    await postDeleteAddress(memberCode, memberAddress.address_id,);
    setDeleteAddressComponent(false);
    refreshAdress();
    dispatchSuccess("Thank you, the address has been deleted successfully.")
  }catch(error)
  {
    setDeleteAddressComponent(false);
    dispatchError(error)
  }
  };
  const setDefaultAddress = async () => {
    await defaultSetAddress(memberCode, memberAddress.address_id,);
    refreshAdress();
  };
  const getCountryMememberList = async () => {
    try {
      let memberCountryList = await getCountryList(memberCode);
      setCountryList(memberCountryList);

    } catch (error) {
      dispatchError(error);

    }
  };

  const getCityMemberList = async (district) => {
    try {
      setCityList([]);
      let memberCityList = await getCityList(memberCode,district);
      setCityList(memberCityList);

    } catch (error) {
      dispatchError(error);

    }
  };

  const getStateMemberList = async (country) => {
    try {
      setStateList([]);
      let memberStateList = await getStateList(memberCode,country);

      setStateList(memberStateList);

    } catch (error) {
      dispatchError(error);

    }
  };
  // const getTehsilMemberList = async (district) => {
  //   try {
  //     setTehsilList([])
  //     let memberTehsilList = await getTehsilsList(memberCode,district);

  //     setTehsilList(memberTehsilList);

  //   } catch (error) {
  //     dispatchError(error);

  //   }
  // };
  const getPincodeMemberList = async (city) => {
    try {
      setPincodeList([]);
      let memberPincodeList = await getPincodeList(memberCode,city);

      setPincodeList(memberPincodeList);

    } catch (error) {
      dispatchError(error);

    }
  };
  const getDistrictMemberList = async (state) => {
    try {
      setDistrictList([])
      let memberDistrictList = await getDistrictList(memberCode,state);
      setDistrictList(memberDistrictList);

    } catch (error) {
      dispatchError(error);

    }
  };

  // const getZoneMemberList = async () => {
  //   try {
  //     let Data = await getZoneList(memberCode);
  //     setZoneList(Data);

  //   } catch (error) {
  //     dispatchError(error);

  //   }
  // };
  // const getBranchMemberList = async (data) => {
  //   const Data = await getBranchList(memberCode, data);
  //   setBranchesList(Data);
  //   const transformedData = Data.map(item => {
  //     return {
  //       id: item.branch_id,
  //       name: item.branch_name,
  //     };
  //   });
  //   setBranchesListItem(transformedData)
  // };
  const handleSearchZone = async(text)=>{

      try {
        let Data = await getZoneList(memberCode,text);
        for(let item of Data)
        {
          if(item.zone_sect_description && item.zone_sect_description)
          {
            item.zone_name +=' ('+item.zone_sect_description+')'
          }
        }
        setZoneDataList(Data)
        //setBranchesList([])

        // setLoader((prev) => {
        //   return prev !== 0 ? prev - 1 : 0;
        // });
      } catch (error) {
        dispatchError(error);
        // setLoader((prev) => {
        //   return prev !== 0 ? prev - 1 : 0;
        // });
      }
    
  }
  useEffect(()=>{
   if(zoneDataList){
    let arr =zoneDataList
        arr.push({zone_name:'<---- Type to view more---->'})
    setZoneList(zoneDataList);
   }
  },[zoneDataList])
  const handleBranchSearch = async(text)=>{
  
      try{
        let searchData  = await getBranchList(memberCode,selectedZone,text )
        setBranchesList(searchData);
        const transformedData = searchData.map(item => {
          return {
            id: item.branch_id,
            name: item.branch_name,
          };
        });
        setBranchesListItem(transformedData)
      }catch(error)
      {
        dispatchError(error)
      }
    
  }
  useEffect(()=>{
    if(branchesList){
    setBranchesList(branchesList)
    }
  },[modalVisible])
  

  useEffect(() => {

    getCountryMememberList(memberCode)
    // getZoneMemberList();
    setPincode(memberAddress.pincode)
    setFullAddress(memberAddress.full_address)
    setSelectedCountry(memberAddress.country_id)
    setSelectedCity(memberAddress.city_id)
    if(memberAddress.country_name)
    {
      getStateMemberList(memberAddress.country_name)
    }
    if(memberAddress.state_name)
    {
      getDistrictMemberList(memberAddress.state_name)
    }
    if(memberAddress.district_name)
    {
      getCityMemberList(memberAddress.district_name)
    }
    if(memberAddress.city_name)
    {
      getPincodeMemberList(memberAddress.city_name)
    }
    if(memberAddress?.zone_name){
    handleSearchZone("")
    setSelectedZone(memberAddress?.zone_id)
    }


    if(memberAddress?.branch_name)
      {
        handleBranchSearch("")
      }
  }, []);


  let [fontsLoaded] = useFonts({
    openSans: require("../../assets/fonts/OpenSans-VariableFont_wdth,wght.ttf"),
  });
  if (!fontsLoaded) {
    return <AppLoading />;
  }


  const handleToggle = (value) => {
    if(value){
    setToggleValue((prevValue) => !prevValue);
   // if(toggleValue===false){
      setDefaultAddress();
    }else{
      dispatchError("Please select other address to mark as default")
    }
   // }
  };


  return (
    <View
      style={{
        position:"relative",
        borderWidth: 2,
        margin: !isMobileView() && 10,
        marginTop: isMobileView() && 15 || isOnlyTabletView() && 0 || isOnlyLaptopView() &&  index > 1 && 15,

        borderColor: toggleValue ? Color.BLUE_MEDIUM  : "#c2c2c8",
        width: isMobileView() && "100%" || isOnlyTabletView() && "47%" || isOnlyLaptopView() && responsiveWidth(25),
        height: isMobileView() ? "fit-content" :  responsiveHeight(42),
        borderRadius: 8,
        padding: 15,
        paddingHorizontal: 10,
        elevation: 8, // Controls the shadow depth
        backgroundColor:  toggleValue ? Color.BLUE_LIGHT  : Color.WHITE,
        justifyContent:"space-between"

      }}
    >
         <View style={{alignItems: "flex-end",justifyContent:'flex-end',flexDirection:"row",gap:14  }}>

<Hoverable>
{({ hovered }) => (
  <TouchableOpacity
    onPress={() => setModalVisible(true)}
    style={{
      justifyContent: "space-evenly",
      alignItems: "flex-end",
      flexDirection: "row",
      // borderWidth: 2,
      borderRadius: 25,
      height: 20,
      gap:5,
    }}
  >
    <Image
      style={{
        height: 22,
        width: 22,
        resizeMode: "contain",
        borderWidth: 0,
      }}
      source={require("../../assets/images/editBlack.png")}
    />
    <Text
      style={{
        fontFamily: "openSans",
        fontWeight: "600",
        fontSize: 16,
        color: "#000",
      }}
    >
      Edit
    </Text>
  </TouchableOpacity>
          )}
          </Hoverable>
<Hoverable>
{({ hovered }) => (
  <TouchableOpacity
    onPress={() => setDeleteAddressComponent(true)}
    style={{
      justifyContent: "space-evenly",
      alignItems: "flex-end",
      flexDirection: "row",
      // borderWidth: 2,
      borderRadius: 25,
      gap:5,
      height: 20,
    }}
  >
    <Image
      style={{
        height: 20,
        width: 22,
        resizeMode: "contain",
        borderWidth: 0,
      }}
      source={require("../../assets/images/binBlack.png")}
    />
    <Text
      style={{
        fontFamily: "openSans",
        fontWeight: "600",
        fontSize: 16,
        color: "#000",
      }}
    >
      Delete
    </Text>
  </TouchableOpacity>
          )}
          </Hoverable>
</View>
      <Text
        style={{
          fontFamily: "openSans",
          fontWeight: "600",
          fontSize:     !isMobileView() ? responsiveFontSize(1.5) : responsiveFontSize(2.4),
          color: "rgba(0, 0, 0, 1)",
          textTransform: "capitalize",
        }}
      >
        {memberAddress.address_type}
      </Text>
      <View style={{gap:20}}>
        <Text
               style={{
                fontFamily: "openSans",
                fontWeight: "400",
                fontSize: !isMobileView() ? responsiveFontSize(1.1) : responsiveFontSize(1.8),
                color: "rgba(0, 0, 0, 0.9)",
                height:responsiveHeight(30)
              }}
        >

          <TouchableOpacity
                  onMouseEnter={() => setIsTooltipVisible(true)}
                  onMouseLeave={() => setIsTooltipVisible(false)}
                  style={{
                    width:"100%"
                  }}
                >
        <Text
               style={{
                 fontFamily: "openSans",
                 fontWeight: "400",
                 fontSize: !isMobileView() ? responsiveFontSize(1.1) : responsiveFontSize(1.8),
                 color: "rgba(0, 0, 0, 0.9)",
                }}
              numberOfLines={2}
              ellipsizeMode='tail'
              >

  {memberAddress.adr_line1}{"\n"}
  {memberAddress.adr_line2}{"\n"}
  </Text>
          </TouchableOpacity>
  {memberAddress.city_name} - {memberAddress.pincode}{"\n"}
  {memberAddress.district_name}{"\n"}
  {memberAddress.state_name}{"\n"}
  {memberAddress.country_name}{"\n"}
  <Text
               style={{
                fontFamily: "openSans",
                fontWeight: "400",
                fontSize: !isMobileView() ? responsiveFontSize(1.1) : responsiveFontSize(1.8),
                color: "rgba(0, 0, 0, 0.9)",
                maxWidth:"100%",
              }}
              numberOfLines={1}
        >
  Zone - {memberAddress.zone_name}{"\n"}
  </Text>
  <Text
               style={{
                fontFamily: "openSans",
                fontWeight: "400",
                fontSize: !isMobileView() ? responsiveFontSize(1.1) : responsiveFontSize(1.8),
                color: "rgba(0, 0, 0, 0.9)",
                maxWidth:"72%",
              }}
              numberOfLines={2}
              ellipsizeMode='tail'
        >
  Branch - {memberAddress.branch_name}
        </Text>

        </Text>

        <View style={{alignItems:"flex-end",  cursor: 'pointer',
            bottom: 0,
            position: "absolute",
            right: 0
      }}>

        <Switch
        value={toggleValue}
        onValueChange={handleToggle}
        disabled={false}
        activeText={'Default'}
        inActiveText={'Default'}
        backgroundActive={Color.BLUE_MEDIUM}
        backgroundInactive={'#E6E8EA'}
        circleActiveColor={'white'}
        circleInActiveColor={'white'}
        innerCircleStyle={{ width:20,height:20   }} // style for inner animated circle for what you (may) be rendering inside the circle
        outerCircleStyle={{left:0 }} // style for outer animated circle
        activeTextStyle={{ color: 'white',fontWeight:'500' }}
        inactiveTextStyle={{ color: 'rgba(0, 0, 0, 0.4)',fontWeight:'500' }}
        circleBorderWidth={0}
        switchWidthMultiplier={2.85}

      />
        </View>



        </View>

        {/* {isTooltipVisible && (

<View
  style={{
    position: "absolute",
    left: "0%",
    top: -TooltipHeight,
    backgroundColor: Color.BLACK,
    padding: 12,
    zIndex:100,
    borderRadius: 2,
    width:"100%"
  }}
  onLayout={(event) => { setTooltripHeight(event.nativeEvent.layout.height)  }}
>
     <TouchableOpacity
onMouseEnter={() => setIsTooltipVisible(true)}
onMouseLeave={() => setIsTooltipVisible(false)}
style={{
width:"100%"
}}
>
  <Text
    style={{
      fontSize: 14,
      fontWeight: "600",
      color: Color.WHITE,
    }}
  >
    Address :- {"\n"}
{memberAddress.adr_line1}{"\n"}
{memberAddress.adr_line2}{"\n"}
    </Text>
</TouchableOpacity>
</View>
)} */}

      <Modal
        animationType="fade"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {
          Alert.alert("Modal has been closed.");
          setModalVisible(!modalVisible);
        }}
      >
        <View style={styles.centeredView}>
          <View style={ isMobileView() ? styles.modalViewMobile : styles.modalView}>
          <View
              style={{
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
                borderWidth: 0,
              }}
            >
              <Text style={styles.modalText}>Edit Address</Text>
              <TouchableOpacity onPress={() => setModalVisible(!modalVisible)}>
                <Image
            style={{ width: 30, height: 30 }}
                  source={require("../../assets/images/cross.png")}
                />
              </TouchableOpacity>
            </View>
            {
      isMobileView() ? (

        <View style={[commonStyles.Col2GridParent,{rowGap:0,flex:"inherit"}]}>

                    <View style={[[ isMobileView() && commonStyles.width48Mobile, !isMobileView() && commonStyles.width30]]}>
                      <Text
                        style={[
                          {
                            fontWeight: "600",
                            fontSize: responsiveFontSize(1.1),
                          },
                          commonStyles.inputLableMobile,
                        ]}
                      >
                        Type of Address<Text style={{color:'red'}} >*</Text>
                      </Text>
                      <SelectDropdown
                           dropdownOverlayColor={"No"}
                           dropdownStyle={commonStyles.commanDropdownStyle}
                           selectedRowStyle={commonStyles.selectedRowStyle}
                           selectedRowTextStyle={commonStyles.selectedRowTextStyle}
                           rowStyle={commonStyles.commanRowStyle}
                           rowTextStyle={commonStyles.commonRowTextStyle}
                        data={["Home", "Office", "Other"]}
                        rowTextForSelection={(item) => item}
                        buttonTextAfterSelection={(selectedItem) =>
                          selectedItem
                        }
                        defaultButtonText={
                          memberAddress.address_type?memberAddress.address_type: "Select address type"
                        }
                        // defaultValue=
                        // defaultButtonText={
                        //   countryList.find(
                        //     (item) => item.country_id === memberAddress.country_id
                        //   )?.country_name || "Select a country"
                        // }


                        buttonStyle={[
                          {
                            width: 225,
                            height: "55%",
                            marginTop: 10,
                            backgroundColor: Color.WHITE,
                            alignItems: "center",
                            justifyContent: "space-between",
                            borderRadius: 10,
                            borderWidth: 1,
                            borderColor: "rgba(0, 0, 0, 0.14)",
                            padding: 10,
                          },
                          commonStyles.inputFieldMobile,
                        ]}
                        buttonTextStyle={{
                          color: "rgba(0, 0, 0)",
                          fontSize: isMobileView()
                            ? responsiveFontSize(2.2)
                            : responsiveFontSize(1.1),
                          textAlign: "left",
                          margin: 0,
                          marginVertical: 0,
                          fontWeight: "600",
                        }}
                        renderDropdownIcon={(props) =>
                          renderDropdownIcon(props)
                        }
                        onSelect={(selectedItem, index) => {
                          setSelectedType(selectedItem);
                        }}
                      />
                      {typeErrorAddress !== "" && (
                        <Text
                          style={{
                            color: "red",
                            fontWeight: "500",
                            height: 10,
                          }}
                        >
                          {typeErrorAddress}
                        </Text>
                      )}
                    </View>
                    <View style={[[ isMobileView() && commonStyles.width48Mobile, !isMobileView() && commonStyles.width30]]}>
                      <Text
                        style={[
                          {
                            fontWeight: "600",
                            fontSize: responsiveFontSize(1.1),
                          },
                          commonStyles.inputLableMobile,
                        ]}
                      >
                        Country<Text style={{color:'red'}} >*</Text>
                      </Text>
                       <SelectDropdown search searchPlaceHolder={"Search Country.."}
                      searchInputStyle={commonStyles.commanDropdownSearchStyle}
                      searchInputTxtStyle={{borderColor:'transparent',borderRadius:6,paddingHorizontal:10}}
     dropdownOverlayColor={"No"}
                      dropdownStyle={commonStyles.commanDropdownStyle}
                      selectedRowStyle={commonStyles.selectedRowStyle}
                      selectedRowTextStyle={commonStyles.selectedRowTextStyle}
                      rowStyle={commonStyles.commanRowStyle}
                      rowTextStyle={commonStyles.commonRowTextStyle}
                        data={countryList}
                        rowTextForSelection={(item) => item.country_name}
                        buttonTextAfterSelection={(selectedItem) =>
                          selectedItem.country_name
                        }
                        // defaultValue=
                        defaultButtonText={
                          selectedCountryName?selectedCountryName: "Select a country"
                        }

                        buttonStyle={[
                          {
                          width: 225,
                          height: "50%",
                          marginTop: 10,
                          backgroundColor: Color.WHITE,
                          alignItems: "center",
                          justifyContent: "space-between",
                          borderRadius: 10,
                          borderWidth: 1,
                          borderColor: "rgba(0, 0, 0, 0.14)",
                          padding: 10,
                        },
                          commonStyles.inputFieldMobile,
                        ]}
                        buttonTextStyle={{
                          color: "rgba(0, 0, 0)",
                          fontSize: isMobileView()
                            ? responsiveFontSize(2.2)
                            : responsiveFontSize(1.1),
                          textAlign: "left",
                          margin: 0,
                          marginVertical: 0,
                          fontWeight: "600",
                        }}
                        renderDropdownIcon={(props) =>
                          renderDropdownIcon(props)
                        }
                        onSelect={(selectedItem, index) => {
                          setSelectedCountry(selectedItem.country_id);
                          setCountryError('')
                          setSelectedStateName('')
                          setSelectedState('')
                          setSelectedDistrict('')
                          setSelectedDistrictName('')
                          setSelectedCityName('')
                          setSelectedCity('')
                          setNewZipcode('')
                          setNewZipcodeName('')
                          getStateMemberList(selectedItem.country_name)
                        }}
                      />
                      {countryError !== "" && (
                        <Text
                          style={{
                            color: "red",
                            fontWeight: "500",
                            height: 10,
                          }}
                        >
                          {countryError}
                        </Text>
                      )}
                    </View>
                    <View style={[[ isMobileView() && commonStyles.width48Mobile, !isMobileView() && commonStyles.width30]]} >
                      <Text
                        style={[
                          {
                            fontWeight: "600",
                            fontSize: responsiveFontSize(1.1),
                          },
                          commonStyles.inputLableMobile,
                        ]}
                      >
                        State<Text style={{color:'red'}} >*</Text>
                      </Text>
                       <SelectDropdown search searchPlaceHolder={"Search State.."}
                      searchInputStyle={commonStyles.commanDropdownSearchStyle}
                      searchInputTxtStyle={{borderColor:'transparent',borderRadius:6,paddingHorizontal:10}}
     dropdownOverlayColor={"No"}
                      dropdownStyle={commonStyles.commanDropdownStyle}
                      selectedRowStyle={commonStyles.selectedRowStyle}
                      selectedRowTextStyle={commonStyles.selectedRowTextStyle}
                      rowStyle={commonStyles.commanRowStyle}
                      rowTextStyle={commonStyles.commonRowTextStyle}
                      // onBlur={{}}
                       // onFocus={()=>{}}
                        data={stateList}
                        rowTextForSelection={(item) => item.state_name}
                        buttonTextAfterSelection={(selectedItem) =>
                          selectedItem.state_name
                        }
                        defaultButtonText={
                          selectedStateName?selectedStateName: "Select a State"
                        }
                        onSelect={(selectedItem, index) => {
                          setSelectedState(selectedItem.state_id);
                          setStateError('')
                          setSelectedDistrictName('');
                          setSelectedDistrict('')
                          setSelectedDistrictName('')
                          setSelectedCityName('')
                          setSelectedCity('')
                          setNewZipcode('')
                          setNewZipcodeName('')
                          setPincodeList([]);
                          setCityList([])
                          getDistrictMemberList(selectedItem.state_name);
                        }}

                        buttonStyle={[
                          {
                          width: 225,
                          height: "50%",
                          marginTop: 10,
                          backgroundColor: Color.WHITE,
                          alignItems: "center",
                          justifyContent: "space-between",
                          borderRadius: 10,
                          borderWidth: 1,
                          borderColor: "rgba(0, 0, 0, 0.14)",
                          padding: 10,
                        },
                          commonStyles.inputFieldMobile,
                        ]}
                        buttonTextStyle={{
                          color: "rgba(0, 0, 0)",
                          fontSize: isMobileView()
                            ? responsiveFontSize(2.2)
                            : responsiveFontSize(1.1),
                          textAlign: "left",
                          margin: 0,
                          marginVertical: 0,
                          fontWeight: "600",
                        }}
                        renderDropdownIcon={(props) =>
                          renderDropdownIcon(props)
                        }
                      />
                      {stateError !== "" && (
                        <Text
                          style={{
                            color: "red",
                            fontWeight: "500",
                            height: 10,
                          }}
                        >
                          {stateError}
                        </Text>
                      )}
                    </View>






                  <View style={[[ isMobileView() && commonStyles.width48Mobile, !isMobileView() && commonStyles.width30]]} >
                    <Text
                      style={[
                        {
                          fontWeight: "600",
                          fontSize: responsiveFontSize(1.1),
                        },
                        commonStyles.inputLableMobile,
                      ]}
                    >
                      District<Text style={{color:'red'}} >*</Text>
                    </Text>
                     <SelectDropdown search searchPlaceHolder={"Search District.."}
                      searchInputStyle={commonStyles.commanDropdownSearchStyle}
                      searchInputTxtStyle={{borderColor:'transparent',borderRadius:6,paddingHorizontal:10}}
     dropdownOverlayColor={"No"}
                      dropdownStyle={commonStyles.commanDropdownStyle}
                      selectedRowStyle={commonStyles.selectedRowStyle}
                      selectedRowTextStyle={commonStyles.selectedRowTextStyle}
                      rowStyle={commonStyles.commanRowStyle}
                      rowTextStyle={commonStyles.commonRowTextStyle}

                      onFocus={()=>{setSelectedDistrictName('')}}
                      data={districtList}
                      rowTextForSelection={(item) => item.district_name}
                      buttonTextAfterSelection={(selectedItem) =>
                        selectedItem.district_name
                      }
                      defaultButtonText={
                        selectedDistrictName?  selectedDistrictName:"Select a District"
                      }
                      onSelect={(selectedItem, index) => {
                        setSelectedDistrict(selectedItem.district_id);
                        setDistrictError('')
                        setSelectedCityName('');
                        setSelectedCity('');
                        setSelectedCityName('')
                        setNewZipcode('')
                        setNewZipcodeName('')
                        setPincodeList([]);
                      //  getTehsilMemberList(selectedItem.district_name);
                        getCityMemberList(selectedItem.district_name)
                      }}

                      buttonStyle={[
                          {
                          width: 225,
                          height: "50%",
                          marginTop: 10,
                          backgroundColor: Color.WHITE,
                          alignItems: "center",
                          justifyContent: "space-between",
                          borderRadius: 10,
                          borderWidth: 1,
                          borderColor: "rgba(0, 0, 0, 0.14)",
                          padding: 10,
                        },
                          commonStyles.inputFieldMobile,
                        ]}
                      buttonTextStyle={{
                        color: "rgba(0, 0, 0)",
                        fontSize: isMobileView()
                            ? responsiveFontSize(2.2)
                            : responsiveFontSize(1.1),
                        textAlign: "left",
                        margin: 0,
                        marginVertical: 0,
                        fontWeight: "600",
                      }}
                      renderDropdownIcon={(props) => renderDropdownIcon(props)}
                    />
                    {districtError !== "" && (
                      <Text
                        style={{ color: "red", fontWeight: "500", height: 10 }}
                      >
                        {districtError}
                      </Text>
                    )}
                  </View>
                {/* { isMobileView() && <View style={[[ isMobileView() && commonStyles.width48Mobile]]}>
                    <Text
                      style={[
                        {
                          fontWeight: "600",
                          fontSize: responsiveFontSize(1.1),
                        },
                        commonStyles.inputLableMobile,
                      ]}
                    >
                      Tehsil
                    </Text>
                     <SelectDropdown search searchPlaceHolder={"Search Tehsil.."}
                      searchInputStyle={commonStyles.commanDropdownSearchStyle}
                      searchInputTxtStyle={{borderColor:'transparent',borderRadius:6,paddingHorizontal:10}}
     dropdownOverlayColor={"No"}
                      dropdownStyle={commonStyles.commanDropdownStyle}
                      selectedRowStyle={commonStyles.selectedRowStyle}
                      selectedRowTextStyle={commonStyles.selectedRowTextStyle}
                      rowStyle={commonStyles.commanRowStyle}
                      rowTextStyle={commonStyles.commonRowTextStyle}
                    onFocus={()=>{selectedDistrict&&getTehsilMemberList(memberAddress.district_name)}}

                      data={tehsilList}
                      rowTextForSelection={(item) => item.tehsil_name}
                      buttonTextAfterSelection={(selectedItem) =>
                        selectedItem.tehsil_name
                      }
                      // defaultValue=
                      defaultButtonText={
                        memberAddress.tehsil_name?memberAddress.tehsil_name: "Select a tehsil"
                      }

                      buttonStyle={[
                          {
                          width: 225,
                          height: "50%",
                          marginTop: 10,
                          backgroundColor: Color.WHITE,
                          alignItems: "center",
                          justifyContent: "space-between",
                          borderRadius: 10,
                          borderWidth: 1,
                          borderColor: "rgba(0, 0, 0, 0.14)",
                          padding: 10,
                        },
                          commonStyles.inputFieldMobile,
                        ]}
                      buttonTextStyle={{
                        color: "rgba(0, 0, 0, 0.30)",
                        fontSize: isMobileView()
                            ? responsiveFontSize(2.2)
                            : responsiveFontSize(1.1),
                        textAlign: "left",
                        margin: 0,
                        marginVertical: 0,
                        fontWeight: "600",
                      }}
                      renderDropdownIcon={(props) => renderDropdownIcon(props)}
                      onSelect={(selectedItem, index) => {
                        setSelectedTehsil(selectedItem.tehsil_id);

                      }}
                    />
                    {tehsilError !== "" && (
                      <Text
                        style={{ color: "red", fontWeight: "500", height: 10 }}
                      >
                        {tehsilError}
                      </Text>
                    )}
                  </View>}
            {!isMobileView() &&      <View style={[ commonStyles.width30]}  >
                    <Text
                      style={[
                        {
                          fontWeight: "600",
                          fontSize: responsiveFontSize(1.1),
                        },
                        commonStyles.inputLableMobile,
                      ]}
                    >
                      Tehsil
                    </Text>
                     <SelectDropdown search searchPlaceHolder={"Search Tehsil.."}
                      searchInputStyle={commonStyles.commanDropdownSearchStyle}
                      searchInputTxtStyle={{borderColor:'transparent',borderRadius:6,paddingHorizontal:10}}
     dropdownOverlayColor={"No"}
                      dropdownStyle={commonStyles.commanDropdownStyle}
                      selectedRowStyle={commonStyles.selectedRowStyle}
                      selectedRowTextStyle={commonStyles.selectedRowTextStyle}
                      rowStyle={commonStyles.commanRowStyle}
                      rowTextStyle={commonStyles.commonRowTextStyle}
                                                            onFocus={()=>{selectedDistrict&&getTehsilMemberList(memberAddress.district_name)}}


                      data={tehsilList}
                      rowTextForSelection={(item) => item.tehsil_name}
                      buttonTextAfterSelection={(selectedItem) =>
                        selectedItem.tehsil_name
                      }
                      // defaultValue=
                      defaultButtonText={
                        memberAddress.tehsil_name?memberAddress.tehsil_name:"Select a tehsil"
                      }

                      buttonStyle={[
                          {
                          width: 225,
                          height: "50%",
                          marginTop: 10,
                          backgroundColor: Color.WHITE,
                          alignItems: "center",
                          justifyContent: "space-between",
                          borderRadius: 10,
                          borderWidth: 1,
                          borderColor: "rgba(0, 0, 0, 0.14)",
                          padding: 10,
                        },
                          commonStyles.inputFieldMobile,
                        ]}
                      buttonTextStyle={{
                        color: "rgba(0, 0, 0, 0.30)",
                        fontSize: isMobileView()
                            ? responsiveFontSize(2.2)
                            : responsiveFontSize(1.1),
                        textAlign: "left",
                        margin: 0,
                        marginVertical: 0,
                        fontWeight: "600",
                      }}
                      renderDropdownIcon={(props) => renderDropdownIcon(props)}
                      onSelect={(selectedItem, index) => {
                        setSelectedTehsil(selectedItem.tehsil_id);
                        getCityMemberList(selectedItem.tehsil_name)

                      }}
                    />
                    {tehsilError !== "" && (
                      <Text
                        style={{ color: "red", fontWeight: "500", height: 10 }}
                      >
                        {tehsilError}
                      </Text>
                    )}
                  </View>} */}

                  <View style={[[ isMobileView() && commonStyles.width48Mobile, !isMobileView() && commonStyles.width30]]}>
                    <Text
                      style={[
                        {
                          fontWeight: "600",
                          fontSize: responsiveFontSize(1.1),
                        },
                        commonStyles.inputLableMobile,
                      ]}
                    >
                      City<Text style={{color:'red'}} >*</Text>
                    </Text>
                     <SelectDropdown search searchPlaceHolder={"Search City.."}
                      searchInputStyle={commonStyles.commanDropdownSearchStyle}
                      searchInputTxtStyle={{borderColor:'transparent',borderRadius:6,paddingHorizontal:10}}
     dropdownOverlayColor={"No"}
                      dropdownStyle={commonStyles.commanDropdownStyle}
                      selectedRowStyle={commonStyles.selectedRowStyle}
                      selectedRowTextStyle={commonStyles.selectedRowTextStyle}
                      rowStyle={commonStyles.commanRowStyle}
                      rowTextStyle={commonStyles.commonRowTextStyle}
                      //onFocus={()=>{memberAddress.district_name&&getCityMemberList(memberAddress.district_name)}}

                      data={cityList}
                      rowTextForSelection={(item) => item.city_name}
                      buttonTextAfterSelection={(selectedItem) =>
                        selectedItem.city_name
                      }
                      defaultButtonText={
                        selectedCityName?selectedCityName: "Select a city"
                      }
                      onSelect={(selectedItem, index) => {
                        setSelectedCity(selectedItem.city_id);
                        getPincodeMemberList(selectedItem.city_name);
                        setCityError('');
                        setNewZipcodeName('');
                        setNewZipcode('');
                        setNewZipcodeName('')

                      }}

                      buttonStyle={[
                          {
                          width: 225,
                          height: "50%",
                          marginTop: 10,
                          backgroundColor: Color.WHITE,
                          alignItems: "center",
                          justifyContent: "space-between",
                          borderRadius: 10,
                          borderWidth: 1,
                          borderColor: "rgba(0, 0, 0, 0.14)",
                          padding: 10,
                        },
                          commonStyles.inputFieldMobile,
                        ]}
                      buttonTextStyle={{
                        color: "rgba(0, 0, 0)",
                        fontSize: isMobileView()
                            ? responsiveFontSize(2.2)
                            : responsiveFontSize(1.1),
                        textAlign: "left",
                        margin: 0,
                        marginVertical: 0,
                        fontWeight: "600",
                      }}
                      renderDropdownIcon={(props) => renderDropdownIcon(props)}
                    />
                    {cityError !== "" && (
                      <Text
                        style={{ color: "red", fontWeight: "500", height: 10 }}
                      >
                        {cityError}
                      </Text>
                    )}
                  </View>
                  <View style={[[ isMobileView() && commonStyles.width48Mobile, !isMobileView() && commonStyles.width30]]}>
                    <Text
                      style={[
                        {
                          fontWeight: "600",
                          fontSize: responsiveFontSize(1.1),
                        },
                        commonStyles.inputLableMobile,
                      ]}
                    >
                      Pin Code<Text style={{color:'red'}} >*</Text>
                    </Text>
                       <SelectDropdown search searchPlaceHolder={"Search Pin Code.."}
                      searchInputStyle={commonStyles.commanDropdownSearchStyle}
                      searchInputTxtStyle={{borderColor:'transparent',borderRadius:6,paddingHorizontal:10}}
     dropdownOverlayColor={"No"}
                      dropdownStyle={commonStyles.commanDropdownStyle}
                      selectedRowStyle={commonStyles.selectedRowStyle}
                      selectedRowTextStyle={commonStyles.selectedRowTextStyle}
                      rowStyle={commonStyles.commanRowStyle}
                      rowTextStyle={commonStyles.commonRowTextStyle}
                       //onFocus={()=>{selectedCity&&getPincodeMemberList(memberAddress.city_name)}}

                        data={pincodeList}
                        rowTextForSelection={(item) => item.pincode}
                        buttonTextAfterSelection={(selectedItem) =>
                          selectedItem.pincode
                        }
                        defaultButtonText={
                          newZipcodeName?newZipcodeName:"Select Pincode"
                        }
                        onSelect={(selectedItem, index) => {
                          setNewZipcode(selectedItem.pincode);
                          setPincodeError('')
                        }}

                        buttonStyle={[
                          {
                          width: 225,
                          height: "50%",
                          marginTop: 10,
                          backgroundColor: Color.WHITE,
                          alignItems: "center",
                          justifyContent: "space-between",
                          borderRadius: 10,
                          borderWidth: 1,
                          borderColor: "rgba(0, 0, 0, 0.14)",
                          padding: 10,
                        },
                          commonStyles.inputFieldMobile,
                        ]}
                        buttonTextStyle={{
                          color: "rgba(0, 0, 0)",
                          fontSize: isMobileView()
                            ? responsiveFontSize(2.2)
                            : responsiveFontSize(1.1),
                          textAlign: "left",
                          margin: 0,
                          marginVertical: 0,
                          fontWeight: "600",
                        }}
                        renderDropdownIcon={(props) =>
                          renderDropdownIcon(props)
                        }
                      />
                    {pincodeError !== "" && (
                      <Text
                        style={{ color: "red", fontWeight: "500", height: 10 }}
                      >
                        {pincodeError}
                      </Text>
                    )}
                  </View>

      </View>

      ) : (<>

                <View
                  style={{
                    width: "100%",
                    margin: 0,
                    borderWidth: 0,
                  }}
                >
                  <View
                    style={[
                       commonStyles.flexRow,
                      commonStyles.justifyBetween,
                      isMobileView() && commonStyles.flexWrap
                    ]}
                  >
                    <View style={[[ isMobileView() && commonStyles.width48Mobile, !isMobileView() && commonStyles.width30]]}>
                      <Text
                        style={[
                          {
                            fontWeight: "600",
                            fontSize: responsiveFontSize(1.1),
                          },
                          commonStyles.inputLableMobile,
                        ]}
                      >
                        Type of Address<Text style={{color:'red'}} >*</Text>
                      </Text>
                      <SelectDropdown
                           dropdownOverlayColor={"No"}
                           dropdownStyle={commonStyles.commanDropdownStyle}
                           selectedRowStyle={commonStyles.selectedRowStyle}
                           selectedRowTextStyle={commonStyles.selectedRowTextStyle}
                           rowStyle={commonStyles.commanRowStyle}
                           rowTextStyle={commonStyles.commonRowTextStyle}
                        data={["Home", "Office", "Other"]}
                        rowTextForSelection={(item) => item}
                        buttonTextAfterSelection={(selectedItem) =>
                          selectedItem
                        }
                        defaultButtonText={
                          memberAddress.address_type?memberAddress.address_type: "Select address type"
                        }
                        // defaultValue=
                        // defaultButtonText={
                        //   countryList.find(
                        //     (item) => item.country_id === memberAddress.country_id
                        //   )?.country_name || "Select a country"
                        // }


                        buttonStyle={[
                          {
                            width: 225,
                            height: "55%",
                            marginTop: 10,
                            backgroundColor: Color.WHITE,
                            alignItems: "center",
                            justifyContent: "space-between",
                            borderRadius: 10,
                            borderWidth: 1,
                            borderColor: "rgba(0, 0, 0, 0.14)",
                            padding: 10,
                          },
                          commonStyles.inputFieldMobile,
                        ]}
                        buttonTextStyle={{
                          color: "rgba(0, 0, 0)",
                          fontSize: isMobileView()
                            ? responsiveFontSize(2.2)
                            : responsiveFontSize(1.1),
                          textAlign: "left",
                          margin: 0,
                          marginVertical: 0,
                          fontWeight: "600",
                        }}
                        renderDropdownIcon={(props) =>
                          renderDropdownIcon(props)
                        }
                        onSelect={(selectedItem, index) => {
                          setSelectedType(selectedItem);
                        }}
                      />
                      {typeErrorAddress !== "" && (
                        <Text
                          style={{
                            color: "red",
                            fontWeight: "500",
                            height: 10,
                          }}
                        >
                          {typeErrorAddress}
                        </Text>
                      )}
                    </View>
                    <View style={[[ isMobileView() && commonStyles.width48Mobile, !isMobileView() && commonStyles.width30]]}>
                      <Text
                        style={[
                          {
                            fontWeight: "600",
                            fontSize: responsiveFontSize(1.1),
                          },
                          commonStyles.inputLableMobile,
                        ]}
                      >
                        Country<Text style={{color:'red'}} >*</Text>
                      </Text>
                       <SelectDropdown search searchPlaceHolder={"Search Country.."}
                      searchInputStyle={commonStyles.commanDropdownSearchStyle}
                      searchInputTxtStyle={{borderColor:'transparent',borderRadius:6,paddingHorizontal:10}}
     dropdownOverlayColor={"No"}
                      dropdownStyle={commonStyles.commanDropdownStyle}
                      selectedRowStyle={commonStyles.selectedRowStyle}
                      selectedRowTextStyle={commonStyles.selectedRowTextStyle}
                      rowStyle={commonStyles.commanRowStyle}
                      rowTextStyle={commonStyles.commonRowTextStyle}
                        data={countryList}
                        rowTextForSelection={(item) => item.country_name}
                        buttonTextAfterSelection={(selectedItem) =>
                          selectedItem.country_name
                        }
                        // defaultValue=
                        defaultButtonText={
                          selectedCountryName?selectedCountryName: "Select a country"
                        }

                        buttonStyle={[
                          {
                          width: 225,
                          height: "50%",
                          marginTop: 10,
                          backgroundColor: Color.WHITE,
                          alignItems: "center",
                          justifyContent: "space-between",
                          borderRadius: 10,
                          borderWidth: 1,
                          borderColor: "rgba(0, 0, 0, 0.14)",
                          padding: 10,
                        },
                          commonStyles.inputFieldMobile,
                        ]}
                        buttonTextStyle={{
                          color: "rgba(0, 0, 0)",
                          fontSize: isMobileView()
                            ? responsiveFontSize(2.2)
                            : responsiveFontSize(1.1),
                          textAlign: "left",
                          margin: 0,
                          marginVertical: 0,
                          fontWeight: "600",
                        }}
                        renderDropdownIcon={(props) =>
                          renderDropdownIcon(props)
                        }
                        onSelect={(selectedItem, index) => {
                          setSelectedCountry(selectedItem.country_id);
                          setCountryError('')
                          setSelectedStateName('')
                          setSelectedState('')
                          setSelectedDistrict('')
                          setSelectedDistrictName('')
                          setSelectedCityName('')
                          setSelectedCity('')
                          setNewZipcode('')
                          setNewZipcodeName('')
                          getStateMemberList(selectedItem.country_name)
                        }}
                      />
                      {countryError !== "" && (
                        <Text
                          style={{
                            color: "red",
                            fontWeight: "500",
                            height: 10,
                          }}
                        >
                          {countryError}
                        </Text>
                      )}
                    </View>
                    <View style={[[ isMobileView() && commonStyles.width48Mobile, !isMobileView() && commonStyles.width30]]} >
                      <Text
                        style={[
                          {
                            fontWeight: "600",
                            fontSize: responsiveFontSize(1.1),
                          },
                          commonStyles.inputLableMobile,
                        ]}
                      >
                        State<Text style={{color:'red'}} >*</Text>
                      </Text>
                       <SelectDropdown search searchPlaceHolder={"Search State.."}
                      searchInputStyle={commonStyles.commanDropdownSearchStyle}
                      searchInputTxtStyle={{borderColor:'transparent',borderRadius:6,paddingHorizontal:10}}
     dropdownOverlayColor={"No"}
                      dropdownStyle={commonStyles.commanDropdownStyle}
                      selectedRowStyle={commonStyles.selectedRowStyle}
                      selectedRowTextStyle={commonStyles.selectedRowTextStyle}
                      rowStyle={commonStyles.commanRowStyle}
                      rowTextStyle={commonStyles.commonRowTextStyle}
                      // onBlur={{}}
                       // onFocus={()=>{}}
                        data={stateList}
                        rowTextForSelection={(item) => item.state_name}
                        buttonTextAfterSelection={(selectedItem) =>
                          selectedItem.state_name
                        }
                        defaultButtonText={
                          selectedStateName?selectedStateName: "Select a State"
                        }
                        onSelect={(selectedItem, index) => {
                          setSelectedState(selectedItem.state_id);
                          setStateError('')
                          setSelectedDistrictName('');
                          setSelectedDistrict('')
                          setSelectedDistrictName('')
                          setSelectedCityName('')
                          setSelectedCity('')
                          setNewZipcode('')
                          setNewZipcodeName('')
                          setPincodeList([]);
                          setCityList([])
                          getDistrictMemberList(selectedItem.state_name);
                        }}

                        buttonStyle={[
                          {
                          width: 225,
                          height: "50%",
                          marginTop: 10,
                          backgroundColor: Color.WHITE,
                          alignItems: "center",
                          justifyContent: "space-between",
                          borderRadius: 10,
                          borderWidth: 1,
                          borderColor: "rgba(0, 0, 0, 0.14)",
                          padding: 10,
                        },
                          commonStyles.inputFieldMobile,
                        ]}
                        buttonTextStyle={{
                          color: "rgba(0, 0, 0)",
                          fontSize: isMobileView()
                            ? responsiveFontSize(2.2)
                            : responsiveFontSize(1.1),
                          textAlign: "left",
                          margin: 0,
                          marginVertical: 0,
                          fontWeight: "600",
                        }}
                        renderDropdownIcon={(props) =>
                          renderDropdownIcon(props)
                        }
                      />
                      {stateError !== "" && (
                        <Text
                          style={{
                            color: "red",
                            fontWeight: "500",
                            height: 10,
                          }}
                        >
                          {stateError}
                        </Text>
                      )}
                    </View>



                  </View>
                </View>

                <View
                  style={[
                  commonStyles.flexRow,
                    commonStyles.justifyBetween,
                    { width: "100%" },
                    isMobileView() && commonStyles.flexWrap
                  ]}
                >
                  <View style={[[ isMobileView() && commonStyles.width48Mobile, !isMobileView() && commonStyles.width30]]} >
                    <Text
                      style={[
                        {
                          fontWeight: "600",
                          fontSize: responsiveFontSize(1.1),
                        },
                        commonStyles.inputLableMobile,
                      ]}
                    >
                      District<Text style={{color:'red'}} >*</Text>
                    </Text>
                     <SelectDropdown search searchPlaceHolder={"Search District.."}
                      searchInputStyle={commonStyles.commanDropdownSearchStyle}
                      searchInputTxtStyle={{borderColor:'transparent',borderRadius:6,paddingHorizontal:10}}
     dropdownOverlayColor={"No"}
                      dropdownStyle={commonStyles.commanDropdownStyle}
                      selectedRowStyle={commonStyles.selectedRowStyle}
                      selectedRowTextStyle={commonStyles.selectedRowTextStyle}
                      rowStyle={commonStyles.commanRowStyle}
                      rowTextStyle={commonStyles.commonRowTextStyle}

                      onFocus={()=>{setSelectedDistrictName('')}}
                      data={districtList}
                      rowTextForSelection={(item) => item.district_name}
                      buttonTextAfterSelection={(selectedItem) =>
                        selectedItem.district_name
                      }
                      defaultButtonText={
                        selectedDistrictName?  selectedDistrictName:"Select a District"
                      }
                      onSelect={(selectedItem, index) => {
                        setSelectedDistrict(selectedItem.district_id);
                        setDistrictError('')
                        setSelectedCityName('');
                        setSelectedCity('');
                        setSelectedCityName('')
                        setNewZipcode('')
                        setNewZipcodeName('')
                        setPincodeList([]);
                      //  getTehsilMemberList(selectedItem.district_name);
                        getCityMemberList(selectedItem.district_name)
                      }}

                      buttonStyle={[
                          {
                          width: 225,
                          height: "50%",
                          marginTop: 10,
                          backgroundColor: Color.WHITE,
                          alignItems: "center",
                          justifyContent: "space-between",
                          borderRadius: 10,
                          borderWidth: 1,
                          borderColor: "rgba(0, 0, 0, 0.14)",
                          padding: 10,
                        },
                          commonStyles.inputFieldMobile,
                        ]}
                      buttonTextStyle={{
                        color: "rgba(0, 0, 0)",
                        fontSize: isMobileView()
                            ? responsiveFontSize(2.2)
                            : responsiveFontSize(1.1),
                        textAlign: "left",
                        margin: 0,
                        marginVertical: 0,
                        fontWeight: "600",
                      }}
                      renderDropdownIcon={(props) => renderDropdownIcon(props)}
                    />
                    {districtError !== "" && (
                      <Text
                        style={{ color: "red", fontWeight: "500", height: 10 }}
                      >
                        {districtError}
                      </Text>
                    )}
                  </View>
                {/* { isMobileView() && <View style={[[ isMobileView() && commonStyles.width48Mobile]]}>
                    <Text
                      style={[
                        {
                          fontWeight: "600",
                          fontSize: responsiveFontSize(1.1),
                        },
                        commonStyles.inputLableMobile,
                      ]}
                    >
                      Tehsil
                    </Text>
                     <SelectDropdown search searchPlaceHolder={"Search Tehsil.."}
                      searchInputStyle={commonStyles.commanDropdownSearchStyle}
                      searchInputTxtStyle={{borderColor:'transparent',borderRadius:6,paddingHorizontal:10}}
     dropdownOverlayColor={"No"}
                      dropdownStyle={commonStyles.commanDropdownStyle}
                      selectedRowStyle={commonStyles.selectedRowStyle}
                      selectedRowTextStyle={commonStyles.selectedRowTextStyle}
                      rowStyle={commonStyles.commanRowStyle}
                      rowTextStyle={commonStyles.commonRowTextStyle}
                    onFocus={()=>{selectedDistrict&&getTehsilMemberList(memberAddress.district_name)}}

                      data={tehsilList}
                      rowTextForSelection={(item) => item.tehsil_name}
                      buttonTextAfterSelection={(selectedItem) =>
                        selectedItem.tehsil_name
                      }
                      // defaultValue=
                      defaultButtonText={
                        memberAddress.tehsil_name?memberAddress.tehsil_name: "Select a tehsil"
                      }

                      buttonStyle={[
                          {
                          width: 225,
                          height: "50%",
                          marginTop: 10,
                          backgroundColor: Color.WHITE,
                          alignItems: "center",
                          justifyContent: "space-between",
                          borderRadius: 10,
                          borderWidth: 1,
                          borderColor: "rgba(0, 0, 0, 0.14)",
                          padding: 10,
                        },
                          commonStyles.inputFieldMobile,
                        ]}
                      buttonTextStyle={{
                        color: "rgba(0, 0, 0, 0.30)",
                        fontSize: isMobileView()
                            ? responsiveFontSize(2.2)
                            : responsiveFontSize(1.1),
                        textAlign: "left",
                        margin: 0,
                        marginVertical: 0,
                        fontWeight: "600",
                      }}
                      renderDropdownIcon={(props) => renderDropdownIcon(props)}
                      onSelect={(selectedItem, index) => {
                        setSelectedTehsil(selectedItem.tehsil_id);

                      }}
                    />
                    {tehsilError !== "" && (
                      <Text
                        style={{ color: "red", fontWeight: "500", height: 10 }}
                      >
                        {tehsilError}
                      </Text>
                    )}
                  </View>}
            {!isMobileView() &&      <View style={[ commonStyles.width30]}  >
                    <Text
                      style={[
                        {
                          fontWeight: "600",
                          fontSize: responsiveFontSize(1.1),
                        },
                        commonStyles.inputLableMobile,
                      ]}
                    >
                      Tehsil
                    </Text>
                     <SelectDropdown search searchPlaceHolder={"Search Tehsil.."}
                      searchInputStyle={commonStyles.commanDropdownSearchStyle}
                      searchInputTxtStyle={{borderColor:'transparent',borderRadius:6,paddingHorizontal:10}}
     dropdownOverlayColor={"No"}
                      dropdownStyle={commonStyles.commanDropdownStyle}
                      selectedRowStyle={commonStyles.selectedRowStyle}
                      selectedRowTextStyle={commonStyles.selectedRowTextStyle}
                      rowStyle={commonStyles.commanRowStyle}
                      rowTextStyle={commonStyles.commonRowTextStyle}
                                                            onFocus={()=>{selectedDistrict&&getTehsilMemberList(memberAddress.district_name)}}


                      data={tehsilList}
                      rowTextForSelection={(item) => item.tehsil_name}
                      buttonTextAfterSelection={(selectedItem) =>
                        selectedItem.tehsil_name
                      }
                      // defaultValue=
                      defaultButtonText={
                        memberAddress.tehsil_name?memberAddress.tehsil_name:"Select a tehsil"
                      }

                      buttonStyle={[
                          {
                          width: 225,
                          height: "50%",
                          marginTop: 10,
                          backgroundColor: Color.WHITE,
                          alignItems: "center",
                          justifyContent: "space-between",
                          borderRadius: 10,
                          borderWidth: 1,
                          borderColor: "rgba(0, 0, 0, 0.14)",
                          padding: 10,
                        },
                          commonStyles.inputFieldMobile,
                        ]}
                      buttonTextStyle={{
                        color: "rgba(0, 0, 0, 0.30)",
                        fontSize: isMobileView()
                            ? responsiveFontSize(2.2)
                            : responsiveFontSize(1.1),
                        textAlign: "left",
                        margin: 0,
                        marginVertical: 0,
                        fontWeight: "600",
                      }}
                      renderDropdownIcon={(props) => renderDropdownIcon(props)}
                      onSelect={(selectedItem, index) => {
                        setSelectedTehsil(selectedItem.tehsil_id);
                        getCityMemberList(selectedItem.tehsil_name)

                      }}
                    />
                    {tehsilError !== "" && (
                      <Text
                        style={{ color: "red", fontWeight: "500", height: 10 }}
                      >
                        {tehsilError}
                      </Text>
                    )}
                  </View>} */}

                  <View style={[[ isMobileView() && commonStyles.width48Mobile, !isMobileView() && commonStyles.width30]]}>
                    <Text
                      style={[
                        {
                          fontWeight: "600",
                          fontSize: responsiveFontSize(1.1),
                        },
                        commonStyles.inputLableMobile,
                      ]}
                    >
                      City<Text style={{color:'red'}} >*</Text>
                    </Text>
                     <SelectDropdown search searchPlaceHolder={"Search City.."}
                      searchInputStyle={commonStyles.commanDropdownSearchStyle}
                      searchInputTxtStyle={{borderColor:'transparent',borderRadius:6,paddingHorizontal:10}}
     dropdownOverlayColor={"No"}
                      dropdownStyle={commonStyles.commanDropdownStyle}
                      selectedRowStyle={commonStyles.selectedRowStyle}
                      selectedRowTextStyle={commonStyles.selectedRowTextStyle}
                      rowStyle={commonStyles.commanRowStyle}
                      rowTextStyle={commonStyles.commonRowTextStyle}
                      //onFocus={()=>{memberAddress.district_name&&getCityMemberList(memberAddress.district_name)}}

                      data={cityList}
                      rowTextForSelection={(item) => item.city_name}
                      buttonTextAfterSelection={(selectedItem) =>
                        selectedItem.city_name
                      }
                      defaultButtonText={
                        selectedCityName?selectedCityName: "Select a city"
                      }
                      onSelect={(selectedItem, index) => {
                        setSelectedCity(selectedItem.city_id);
                        getPincodeMemberList(selectedItem.city_name);
                        setCityError('');
                        setNewZipcodeName('');
                        setNewZipcode('');
                        setNewZipcodeName('')

                      }}

                      buttonStyle={[
                          {
                          width: 225,
                          height: "50%",
                          marginTop: 10,
                          backgroundColor: Color.WHITE,
                          alignItems: "center",
                          justifyContent: "space-between",
                          borderRadius: 10,
                          borderWidth: 1,
                          borderColor: "rgba(0, 0, 0, 0.14)",
                          padding: 10,
                        },
                          commonStyles.inputFieldMobile,
                        ]}
                      buttonTextStyle={{
                        color: "rgba(0, 0, 0)",
                        fontSize: isMobileView()
                            ? responsiveFontSize(2.2)
                            : responsiveFontSize(1.1),
                        textAlign: "left",
                        margin: 0,
                        marginVertical: 0,
                        fontWeight: "600",
                      }}
                      renderDropdownIcon={(props) => renderDropdownIcon(props)}
                    />
                    {cityError !== "" && (
                      <Text
                        style={{ color: "red", fontWeight: "500", height: 10 }}
                      >
                        {cityError}
                      </Text>
                    )}
                  </View>
                  <View style={[[ isMobileView() && commonStyles.width48Mobile, !isMobileView() && commonStyles.width30]]}>
                    <Text
                      style={[
                        {
                          fontWeight: "600",
                          fontSize: responsiveFontSize(1.1),
                        },
                        commonStyles.inputLableMobile,
                      ]}
                    >
                      Pin Code<Text style={{color:'red'}} >*</Text>
                    </Text>
                       <SelectDropdown search searchPlaceHolder={"Search Pin Code.."}
                      searchInputStyle={commonStyles.commanDropdownSearchStyle}
                      searchInputTxtStyle={{borderColor:'transparent',borderRadius:6,paddingHorizontal:10}}
     dropdownOverlayColor={"No"}
                      dropdownStyle={commonStyles.commanDropdownStyle}
                      selectedRowStyle={commonStyles.selectedRowStyle}
                      selectedRowTextStyle={commonStyles.selectedRowTextStyle}
                      rowStyle={commonStyles.commanRowStyle}
                      rowTextStyle={commonStyles.commonRowTextStyle}
                       //onFocus={()=>{selectedCity&&getPincodeMemberList(memberAddress.city_name)}}

                        data={pincodeList}
                        rowTextForSelection={(item) => item.pincode}
                        buttonTextAfterSelection={(selectedItem) =>
                          selectedItem.pincode
                        }
                        defaultButtonText={
                          newZipcodeName?newZipcodeName:"Select Pincode"
                        }
                        onSelect={(selectedItem, index) => {
                          setNewZipcode(selectedItem.pincode);
                          setPincodeError('')
                        }}

                        buttonStyle={[
                          {
                          width: 225,
                          height: "50%",
                          marginTop: 10,
                          backgroundColor: Color.WHITE,
                          alignItems: "center",
                          justifyContent: "space-between",
                          borderRadius: 10,
                          borderWidth: 1,
                          borderColor: "rgba(0, 0, 0, 0.14)",
                          padding: 10,
                        },
                          commonStyles.inputFieldMobile,
                        ]}
                        buttonTextStyle={{
                          color: "rgba(0, 0, 0)",
                          fontSize: isMobileView()
                            ? responsiveFontSize(2.2)
                            : responsiveFontSize(1.1),
                          textAlign: "left",
                          margin: 0,
                          marginVertical: 0,
                          fontWeight: "600",
                        }}
                        renderDropdownIcon={(props) =>
                          renderDropdownIcon(props)
                        }
                      />
                    {pincodeError !== "" && (
                      <Text
                        style={{ color: "red", fontWeight: "500", height: 10 }}
                      >
                        {pincodeError}
                      </Text>
                    )}
                  </View>
                </View>
      </> ) }

                <View
                  style={[
                    !isMobileView() && commonStyles.flexRow,
                    { gap: !isMobileView() && 20, width: "100%",height: !isMobileView() && '20%' },

                  ]}
                >



                  <View style={{ flex: 1 }} >
                    <Text
                      style={[{
  fontWeight: "600",
  fontSize: responsiveFontSize(1.1),

},commonStyles.inputLableMobile]}
                    >
                      Zone<Text style={{color:'red'}} >*</Text>
                    </Text>
                     <SelectDropdown search searchPlaceHolder={"Search Zone.."}
                      searchInputStyle={commonStyles.commanDropdownSearchStyle}
                      searchInputTxtStyle={{borderColor:'transparent',borderRadius:6,paddingHorizontal:10}}
     dropdownOverlayColor={"No"}
                      dropdownStyle={commonStyles.commanDropdownStyle}
                      selectedRowStyle={commonStyles.selectedRowStyle}
                      selectedRowTextStyle={commonStyles.selectedRowTextStyle}
                      rowStyle={commonStyles.commanRowStyle}
                      rowTextStyle={commonStyles.commonRowTextStyle}
                      //onFocus={()=>{newZipcode&&getZoneMemberList(memberCode)}}
                      data={zoneList}
                      rowTextForSelection={(item) => item.zone_name}
                      buttonTextAfterSelection={(selectedItem) =>
                        selectedItem.zone_name
                      }

                      // defaultValue=
                      defaultButtonText={
                        memberAddress.zone_name?memberAddress.zone_name:"Select Zone"
                      }

                      buttonStyle={[
                          {
               width: 225,
                        height: "55%",
                        marginTop: 10,
                        backgroundColor: Color.WHITE,
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderRadius: 10,
                        borderWidth: 1,
                        borderColor: "rgba(0, 0, 0, 0.14)",
                        padding: 10,
                        },
                          commonStyles.inputFieldMobile,
                        ]}
                      buttonTextStyle={{
                        color: "rgba(0, 0, 0)",
                        fontSize: isMobileView()
                            ? responsiveFontSize(2.2)
                            : responsiveFontSize(1.1),
                        textAlign: "left",
                        margin: 0,
                        marginVertical: 0,
                        fontWeight: "600",
                      }}
                      renderDropdownIcon={(props) => renderDropdownIcon(props)}
                      onSelect={(selectedItem, index) => {
                        if(index == zoneList.length - 1)
                        {
                          return ;
                        }
                        if(selectedItem.zone_sect_number && selectedItem.zone_sect_number!=''){
                        setSelectedZone(selectedItem.zone_code +'-'+selectedItem.zone_sect_number)
                        }else{
                        setSelectedZone(selectedItem.zone_code)

                        }
                        setSelectedBranch('')
                        setBranchName('');
                        setBranchesList([])
                        handleSearchZone("")
                        //getBranchMemberList(selectedItem.zone_id);
                      }}
                      onChangeSearchInputText={(text) => handleSearchZone(text)}
                    />
                      {zoneError !== "" && (
                      <Text
                        style={{ color: "red", fontWeight: "500", height: 10 }}
                      >
                        {zoneError}
                      </Text>
                    )}
                  </View>
                  <View style={{ flex: 1 }}>
                    <Text
                      style={[{
  fontWeight: "600",
  fontSize: responsiveFontSize(1.1),

},commonStyles.inputLableMobile]}
                    >
                      Branch<Text style={{color:'red'}} >*</Text>
                    </Text>
                     <SelectDropdown search
                    //onFocus={()=>{selectedZone&&getBranchMemberList(memberCode, memberAddress.zone_id)}}

                      data={branchesList}
                      rowTextForSelection={(item) => item.branch_name}
                      buttonTextAfterSelection={(selectedItem) =>
                        selectedItem.branch_name
                      }
                      // defaultValue=
                      defaultButtonText={
                        branchName?branchName:"Select Branch Name"
                      }
                      dropdownOverlayColor={"No"}
                      dropdownStyle={commonStyles.commanDropdownStyle}
                      selectedRowStyle={commonStyles.selectedRowStyle}
                      selectedRowTextStyle={commonStyles.selectedRowTextStyle}
                      rowStyle={commonStyles.commanRowStyle}
                      rowTextStyle={commonStyles.commonRowTextStyle}
                      searchPlaceHolder={"Search Branch.."}
                      searchInputStyle={commonStyles.commanDropdownSearchStyle}
                      searchInputTxtStyle={{borderColor:'transparent',borderRadius:6,paddingHorizontal:10}}
                      // renderSearchInputLeftIcon={true}
                      buttonStyle={[
                          {
               width: 225,
                        height: "55%",
                        marginTop: 10,
                        backgroundColor: Color.WHITE,
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderRadius: 10,
                        borderWidth: 1,
                        borderColor: "rgba(0, 0, 0, 0.14)",
                        padding: 10,
                        },
                          commonStyles.inputFieldMobile,
                        ]}
                      buttonTextStyle={{
                        color: "rgba(0, 0, 0)",
                        fontSize: isMobileView()
                            ? responsiveFontSize(2.2)
                            : responsiveFontSize(1.1),
                        textAlign: "left",
                        margin: 0,
                        marginVertical: 0,
                        fontWeight: "600",
                      }}

                      onChangeSearchInputText={(text) => handleBranchSearch(text)}
                      renderDropdownIcon={(props) => renderDropdownIcon(props)}
                      onSelect={(selectedItem, index) => {
                        setSelectedBranch(selectedItem.branch_code);
                        setZoneError('');
                        handleBranchSearch('')
                      }}
                    />
                    {/* <MultiSelect
                    single
                    hideTags
                    items={branchesListItem}
                    uniqueKey="id"
                    onSelectedItemsChange={onSelectedItemsChange}
                    selectedItems={selectedItems}
                    selectText="Pick Items"
                    searchInputPlaceholderText="Search Items..."
                    onChangeInput={(text) => console.log(text)}
                    tagRemoveIconColor="#CCC"
                    tagBorderColor="#CCC"
                    tagTextColor="#CCC"
                    selectedItemTextColor="#CCC"
                    selectedItemIconColor="#CCC"
                    itemTextColor="#000"
                    displayKey="name"
                    searchInputStyle={{color: '#CCC'}}
                    submitButtonColor="#48d22b"
                    submitButtonText="Submit"/> */}
                  </View>
                  {branchError !== "" && (
                      <Text
                        style={{ color: "red", fontWeight: "500", height: 10 }}
                      >
                        {branchError}
                      </Text>
                    )}
                </View>

                <View
                  style={[
                    !isMobileView() && commonStyles.flexRow,
                    { gap: !isMobileView() && 20, width: "100%" ,height: !isMobileView() && '20%'},
                  ]}
                >
                  <View style={{ flex:  isMobileView() ? "initial" : 1}}>
                    <Text
                      style={[
                        {
                          fontWeight: "600",
                          fontSize: responsiveFontSize(1.1),
                        },
                        commonStyles.inputLableMobile,
                      ]}
                    >
                      Flat, House no., Building, Company, Apartment<Text style={{color:'red'}} >*</Text>
                    </Text>
                    <TextInput
                      onChangeText={(text) => {
                        setAddressLine1(text);
                      }}
                      placeholderTextColor={"rgba(0, 0, 0, 0.3)"}
                      placeholder="Flat, House no., Building, Company, Apartment"
                      multiline
                      defaultValue={memberAddress?.adr_line1}
                      maxLength={70}
                      style={[{
                        outlineStyle: "none", // Remove the border
                        height: isMobileView() ? responsiveHeight(8) : "100%",
                        width: "100%",

                        marginTop: 10,
                        backgroundColor: Color.WHITE,
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderRadius: 10,
                        borderWidth: 1,
                        borderColor: "rgba(0, 0, 0, 0.14)",
                        padding: 10,
                        fontSize: isMobileView()
                            ? responsiveFontSize(2.2)
                            : responsiveFontSize(1.1),
                            fontWeight: "600",
                      }]}
                    />
                    {addressLine1Error !== "" && (
                      <Text
                        style={{ color: "red", fontWeight: "500", height: 10 }}
                      >
                        {addressLine1Error}
                      </Text>
                    )}
                  </View>
                  <View style={{ flex:  isMobileView() ? "initial" : 1 }}>
                    <Text
                      style={[
                        {
                          fontWeight: "600",
                          fontSize: responsiveFontSize(1.1),
                        },
                        commonStyles.inputLableMobile,
                      ]}
                    >
                       Area, Street, Sector, Village<Text style={{color:'red'}} ></Text>
                    </Text>
                    <TextInput
                      onChangeText={(text) => {
                        setAddressLine2(text);
                      }}
                      defaultValue={memberAddress?.adr_line2}
                      placeholderTextColor={"rgba(0, 0, 0, 0.3)"}
                      placeholder="Area, Street, Sector, Village"
                      multiline
                      style={[{
                        outlineStyle: "none", // Remove the border
                        height: isMobileView() ? responsiveHeight(8) : "100%",
                        width: "100%",
                        marginTop: 10,
                        backgroundColor: Color.WHITE,
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderRadius: 10,
                        borderWidth: 1,
                        borderColor: "rgba(0, 0, 0, 0.14)",
                        fontSize: isMobileView()
                            ? responsiveFontSize(2.2)
                            : responsiveFontSize(1.1),
                            fontWeight: "600",

                        padding: 10,
                      }]}
                    />
                  </View>
                </View>




            <View
             style={{
              width: "100%",
              borderWidth: 0,
              justifyContent: "center",
              alignItems:"flex-end",
              marginTop:15,
              top: isMobileView() && "1%",
            }}
            >
                      <Hoverable style={[{width: isMobileView()  && "100%"}]} >
      {({ hovered }) => (
              <TouchableOpacity
                onPress={() => handleSubmit()}
                style={{
                  backgroundColor: hovered ? Color.BLACK : Color.BLUE_MEDIUM,
                  width: isMobileView()  ? "100%" : 100,
                  height: 40,
                  borderRadius: 10,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Text style={[{ color: "#FFF", fontWeight: "700" },commonStyles.formButtonTextMobile]}>Update</Text>
              </TouchableOpacity>
                    )}
                    </Hoverable>
            </View>
          </View>
        </View>
      </Modal>
      {/* Delete Modal */}
      <Modal
        animationType="fade"
        transparent={true}
        visible={deleteAddressComponent}
        onRequestClose={() => {
          Alert.alert("Modal has been closed.");
          setDeleteAddressComponent(!deleteAddressComponent);
        }}
      >
        <View style={styles.centeredView}>
          <View style={styles.modalViewPopup}>
            <View
              style={{
                width: "100%",
                alignItems: "flex-end",
                borderWidth: 0,
              }}
            >
              <TouchableOpacity style={[{paddingTop:13,paddingRight:13}]} onPress={() => setDeleteAddressComponent(!deleteAddressComponent)}>
                <Image
                   style={{ width: 30, height: 30 }}
                  source={require("../../assets/images/cross.png")}
                />
              </TouchableOpacity>
            </View>
            <View style={{ justifyContent:'space-around',alignItems:'center',borderWidth:0,flex:1,width:'100%',gap:25}}>
            <Text style={[styles.modalText,{marginBottom:0,width: isMobileView() && "80%" }]}>Are you sure you want to delete the address?</Text>

              <View style={{width:'100%',flexDirection:'row',justifyContent:'space-evenly'}}>
                <TouchableOpacity onPress={()=>{ setDeleteAddressComponent(!deleteAddressComponent)}} style={{
                borderWidth: 2,
                borderRadius: 7,
                backgroundColor: '#fff',
                width: isMobileView() ? responsiveWidth(30) : responsiveWidth(10),
                height: responsiveHeight(5),
                alignItems: 'center',
                justifyContent: 'center',
                borderColor: 'rgba(0, 0, 0, 0.15)',
                }}>
                  <Text
                  style={{color:Color.BLACKWHITE,fontWeight:'500'}}
                  >Cancel</Text>
                </TouchableOpacity>
                <TouchableOpacity onPress={()=>{deleteAddressApi()}} style={{  borderWidth: 2,
                padding: 10,
                borderRadius: 7,
                backgroundColor: '#F24D14',
                width: isMobileView() ? responsiveWidth(30) : responsiveWidth(10),
                height: responsiveHeight(5),
                alignItems: 'center',
                justifyContent: 'center',
                borderColor: '#F24D14',}}>
                  <Text
                  style={{color: Color.WHITE,fontWeight:'500' }}
                  >Delete</Text>
                </TouchableOpacity>
              </View>

            </View>








          </View>
        </View>
      </Modal>
    </View>
  );
};

export default Addresscomponent;

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    width:'100%',
    height:'100%',
    alignSelf:'center',
    backdropFilter: 'blur(20px)',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
  modalView: {
    borderWidth: 0,
    justifyContent: "space-between",
    flexDirection: "column",
    alignItems: "center",
    height: isMobileView() ? "30%" : "70%",
    width: isTabletView() ? "90%" : "50%",
    margin: isMobileView() ? 0 : 5,
    backgroundColor: "#f8f9fa",
    borderRadius: isMobileView() ? 10 : 15,
    padding: isMobileView() ? 20 : 25,


  },
  modalViewPopup: {
    borderWidth: 0,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    width: isMobileView() ? responsiveWidth(85) : responsiveWidth(30),
    height: isMobileView() ? responsiveHeight(30) : responsiveHeight(35),
    margin: 5,
    backgroundColor: "#fff",
    borderRadius: 8,

    shadowColor: "none",


    shadowRadius: 4,

  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
  buttonOpen: {
    backgroundColor: "#F194FF",
  },
  buttonClose: {
    backgroundColor: "#2196F3",
  },
  textStyle: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
  },
  modalText: {
    textAlign: "center",
    fontWeight: "600",
    fontSize:
    isMobileView() && isTabletView()
      ? responsiveFontSize(2.5)
      : responsiveFontSize(1.5),
    fontFamily: "openSans",
  },
  modalViewMobile: {
    borderWidth: 0,
    flexDirection: "column",
    alignItems: "center",
    width: isTabletView() ? "100%" : responsiveWidth(50),
    height: isTabletView() ? "100%" : responsiveHeight(90),
    overflowX: "hidden",
    margin: isTabletView() ? 0 : 5,
    backgroundColor: "#f8f9fa",
    borderRadius: isTabletView() ? 0 : 15,
    padding: isTabletView() ? 20 : 25,
    shadowColor: "none",

    shadowRadius: 4,
  },
});
