import { Button, StyleSheet, Text, View } from "react-native";
import React, { useEffect, useState } from "react";
import { TouchableOpacity } from "react-native";
import { Toast } from "react-native-toast-message/lib/src/Toast";
import { Color, isMobileView, isOnlyLaptopView } from '../Common/Constants';
import { responsiveScreenWidth } from "react-native-responsive-dimensions";


const PopupComponent = ({ type, text1, text2,hideFun}) => {

  const toastConfig = {
    success: (internalState) => (
      <View style={{ height: 60, width: "fit-content",gap:15,justifyContent:'space-between',paddingHorizontal:15 ,borderRadius:14,alignItems:'center', backgroundColor: "#D6F7DB", zIndex:1000,flexDirection:"row", borderWidth:1, borderColor:Color.GREEN }}>
          <View
          style={{height:"65%",width:4,backgroundColor:Color.GREEN,borderRadius:40}}
          >


          </View>

          <View>

        <Text style={{color:Color.GREEN,fontWeight:'500',fonntSize:14}}>{" Success"}</Text>
        <Text style={{color:"rgba(0,0,0,0.8)",fontWeight:'700',fonntSize:20, width: isMobileView() && responsiveScreenWidth(70) }}>{internalState.text2}</Text>
          </View>
        </View>
    ),
    error: (internalState) => (
      <View style={{ height: isOnlyLaptopView() && 60 ,paddingVertical: isMobileView() && 15, width: isMobileView() && "80%",gap:15,justifyContent:'space-between',paddingHorizontal:15 ,borderRadius:14,alignItems:'center', backgroundColor: "#FDE7DF", zIndex:100,flexDirection:"row", borderWidth:1, borderColor:Color.RED }}>
      <View
      style={{height: isMobileView() ? 45 : "65%",width:4,backgroundColor:Color.RED,borderRadius:40}}
      >


      </View>

      <View style={{flex:1}}>

    <Text style={{color:Color.RED,fontWeight:'500',fonntSize:14}}>{"! Error"}</Text>
    <Text  style={{color:"rgba(0,0,0,0.8)",fontWeight:'700',fonntSize:20}}>{internalState.text2}</Text>
      </View>
    </View>
        ),
    //     anyType: () => {},
    // info: () => {},
  };
  const showToast = () => {
    Toast.show({
      type: type,
      position: "top",
      text1: text1,
      text2: text2,
      visibilityTime: 2500,
      onShow: () => {},
      onHide: () => {
        {if (type) {

            hideFun(false)
        }}
      },

    }, );
  };
  const [show, setShow] = useState(true);
  useEffect(() => {
    const Timer = setTimeout(() => {
        if(type){
        setShow(true)
        showToast();
    }

    }, 500);
    // Toast.show()
    return () => {
      clearTimeout(Timer);
    };
  }, [type, text1, text2]);


  return(
      <View>


<Toast config={toastConfig}  />
{/* <Button
      title='Show toast'
      onPress={()=>{
        Toast.show({
            type: type,
            position: "bottom",
            text1: text1,
            text2: text2,
            visibilityTime: 2000,
            onShow: () => {},
            onHide: () => {},

          })
      }

      }
    /> */}


        {/* {showToast()} */}
    </View>

)
//   <Toast ref={(ref)=>{Toast.setRef(ref)}}/>
};

export default PopupComponent;

const styles = StyleSheet.create({});
