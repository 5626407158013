import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import React from 'react'
import Hoverable from 'react-native-hoverable';
import { Color, isMobileView } from "./Constants";
import { responsiveHeight, responsiveScreenHeight } from 'react-native-responsive-dimensions';


const StyledButton = (props) => {
    const {content,onPress,} = props;

  return (
    <Hoverable


  >
  {({ hovered }) => (
<TouchableOpacity   onPress={onPress} style={{backgroundColor: hovered ? Color.BLACK : Color.BLUE_MEDIUM,left:1,borderWidth:0, alignItems:'center',borderRadius:8,width: isMobileView() ? "100%" : 450,flexDirection:'row',height: 50,justifyContent:"center"}}>
              <Text style={{fontFamily:'openSans',color: hovered ? Color.WHITE : Color.WHITE,fontWeight:'600',fontSize:18}}>{content}</Text>
            </TouchableOpacity>
            )}
            </Hoverable>
  )
}

export default StyledButton

const styles = StyleSheet.create({})
